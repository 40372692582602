// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#progressInput {
  margin: 20px auto;
  width: 30%; }

.circle-background,
.circle-progress {
  fill: none; }

.circle-background {
  stroke: #ddd; }

.circle-dashes {
  stroke: #fff;
  fill: none; }

.circle-progress {
  stroke: #50a5f1; }
`, "",{"version":3,"sources":["webpack://./src/pages/jobstracker/CircularProgressBar.scss"],"names":[],"mappings":"AACE;EACE,iBAAiB;EACjB,UAAU,EAAA;;AAGZ;;EAEE,UAAU,EAAA;;AAGZ;EACE,YAAY,EAAA;;AAGd;EACE,YAAY;EACZ,UAAU,EAAA;;AAGZ;EACE,eAAe,EAAA","sourcesContent":["  \n  #progressInput {\n    margin: 20px auto;\n    width: 30%;\n  }\n  \n  .circle-background,\n  .circle-progress {\n    fill: none;\n  }\n  \n  .circle-background {\n    stroke: #ddd;\n  }\n  \n  .circle-dashes {\n    stroke: #fff;\n    fill: none;\n  }\n  \n  .circle-progress {\n    stroke: #50a5f1;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
