import { Checkbox } from "@mui/material";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { isValidEmail } from "common/utils";
import AdressAutoComplete from "components/AddressAutoComplete/AddressAutoComplete";
import cuid from "cuid";
import MuiPhoneNumber from "mui-phone-number";
import JobLeavingGuard from "pages/jobs/jobCreation/JobLeavingGuard";
import RouteLeavingGuard from "pages/jobs/jobCreation/RouteLeavingGuard";
import React, { Fragment, useEffect, useState } from "react";
import { Highlighter, Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  addClientToDb,
  fetchClientsFromDb,
  updateJobDraftInDb,
  deleteChecklistSubCollection,
  deleteDraftJobInDb,
} from "services";
import {
  fetchClients,
  fetchClientsError,
  fetchClientsSuccess,
} from "store/actions";
import "../../../common/styles.css";
import { DRAFTS_DB_NAME } from "common/constants";
export default function ClientDetails({
  newJob,
  setNewJob,
  next,
  setDirty,
  isDirty,
  setIsWizardExited,
  isWizardExited,
  isCloned,
  collectionName,
}) {
  const { currentUserProfile } = useSelector(state => state.profile);
  const { clients } = useSelector(state => state.person);
  const dispatch = useDispatch();
  const history = useHistory();

  const [clientId, setClientId] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [contactMobile, setContactMobile] = useState("");
  const [email, setEmail] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [jobAddress, setJobAddress] = useState("");
  const [isClientAndJobAddressSame, setIsClientAndJobAddressSame] = useState(
    newJob.client.isClientAndJobAddressSame || false
  );
  const [mobileDialCode, setMobileDialCode] = useState("");

  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("email");
  const [isNext, setIsNext] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [jobName, setJobName] = useState("");
  const [customJobNumber, setCustomJobNumber] = useState("");
  const [postCode, setPostCode] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isAllClientDetailsFilled, setIsAllClientDetailsFilled] =
    useState(false);
  useFirestoreCollection({
    query: () => fetchClientsFromDb(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchClients()),
    onSuccess: docs => dispatch(fetchClientsSuccess(docs)),
    onError: error => dispatch(fetchClientsError(error)),
    deps: [dispatch],
  });

  const isAllFieldsEmpty = value => {
    if (
      // name?.trim() ||
      email?.trim() ||
      // postCode ||
      mobile?.replace(`+${mobileDialCode}`, "")?.length >= 1 ||
      clientAddress?.trim()
    ) {
      if (
        // name?.trim() != "" &&
        clientAddress?.trim() &&
        email?.trim() &&
        isValidEmail(email) &&
        // postCode &&
        jobAddress?.trim() &&
        mobile &&
        mobile?.replace(`+${mobileDialCode}`, "")?.length != 1
      ) {
        setIsAllClientDetailsFilled(false);
        return;
      }
      setIsAllClientDetailsFilled(true);
      return;
    }
    setIsAllClientDetailsFilled(false);
    // if (!jobAddress?.trim() || !contactMobile) {
    if (!jobAddress?.trim()) {
      setIsAllClientDetailsFilled(true);
    }
    return;
  };

  useEffect(() => {
    setOptions(clients);
  }, [clients]);

  useEffect(() => {
    if (!isClientAndJobAddressSame && clientAddress !== jobAddress) {
      setJobAddress(jobAddress || "");
      return;
    }
    setJobAddress(isClientAndJobAddressSame ? clientAddress : "");
  }, [isClientAndJobAddressSame, clientAddress]);

  useEffect(() => {
    setName(newJob.client.displayName);
    setMobile(newJob.client.mobile);
    setMobileDialCode(newJob?.client?.mobileDialCode);
    setEmail(newJob.client.email);
    setClientAddress(newJob.client.address);
    setIsClientAndJobAddressSame(
      newJob.client.isClientAndJobAddressSame || false
    );
    setJobAddress(newJob.jobAddress || "");
    setClientId(newJob.client.clientId);
    setJobName(newJob.jobName || "");
    setCustomJobNumber(newJob.customjobno || "");
    const isAnyDataSaved =
      !!newJob.client.displayName ||
      !!newJob.client.mobile ||
      !!newJob.client.email ||
      !!newJob.client.address ||
      newJob.client.isClientAndJobAddressSame;
    setIsSaved(isAnyDataSaved && !isCloned);
  }, [
    newJob.client.displayName,
    newJob.client.address,
    newJob.client.email,
    newJob.client.mobile,
    newJob.client.isClientAndJobAddressSame,
    newJob.client.clientId,
    newJob.jobAddress,
    newJob.jobName,
    newJob.customjobno,
  ]);

  useEffect(() => {
    isAllFieldsEmpty();
    setIsNext(
      mobile && mobile !== "+" && mobileDialCode != undefined
        ? mobile?.replace(`+${mobileDialCode}`, "")?.length <= 10 &&
            mobile?.replace(`+${mobileDialCode}`, "")?.length > 4
        : mobile?.replace(`+`, "")?.length <= 16 &&
            mobile?.replace(`+`, "")?.length > 4 &&
            clientAddress?.trim() &&
            email &&
            isValidEmail(email) &&
            jobAddress?.trim()
    );
    if (mobileDialCode != undefined) {
      if (
        mobile?.replace(`+${mobileDialCode}`, "")?.length <= 10 &&
        mobile?.replace(`+${mobileDialCode}`, "")?.length > 4
      ) {
        setError(false);
      } else {
        setError(true);
      }
    } else {
      if (
        mobile?.replace(`+`, "")?.length <= 16 &&
        mobile?.replace(`+`, "")?.length > 4
      ) {
        setError(false);
      } else {
        setError(true);
      }
    }
  }, [name, clientAddress, email, mobile, jobAddress, mobileDialCode]);

  const onSave = (newId = null) => {
    setIsSaved(true);
    const newClient = {
      displayName: name,
      address: clientAddress,
      email,
      mobile: mobile === "+" ? "" : mobile,
      clientId: clientId || newId,
      isClientAndJobAddressSame: isClientAndJobAddressSame,
      mobileDialCode: mobileDialCode ?? "00",
    };
    setNewJob({
      ...newJob,
      ...(collectionName = { DRAFTS_DB_NAME } && { status: "draft" }),
      client: newClient,
      jobAddress,
      jobName,
      customjobno: customJobNumber,
      postCode
    });
    updateJobDraftInDb(
      {
        ...newJob,
        ...(collectionName = { DRAFTS_DB_NAME } && { status: "draft" }),
        client: newClient,
        jobAddress,
        jobName,
        customjobno: customJobNumber,
        postCode
      },
      collectionName
    );
    setDirty(false);
  };

  const isUniqueClientEmail = clientEmail => {
    if (!clientEmail) {
      return true;
    }
    const existingClients = clients?.filter(existingClient => {
      return (
        existingClient?.email?.toLowerCase() ===
        clientEmail?.trim()?.toLowerCase()
      );
    });
    return existingClients.length <= 0;
  };

  const onNext = () => {
    const newId = cuid();
    if (!clientId) {
      if (!isUniqueClientEmail(email)) {
        setShowErrorMessage(true);
        return;
      }
      addClientToDb(currentUserProfile.orgAccessId, {
        id: cuid(),
        name,
        address: clientAddress,
        email,
        phone: mobile,
        type: "client",
      });
    }
    setClientId(newId);
    onSave(newId);
    next();
  };
  const isAnyDataSaved =
    !!name || !!mobile || !!email || !!clientAddress || !!jobAddress;

  const [error, setError] = useState(false);
  const [errorContactMobile, setErrorContactMobile] = useState(false);
  const handlePhoneNumberChange = (value, dialCode, type) => {
    if (
      value?.replace(`+${dialCode}`, "")?.length <= 10 &&
      value?.replace(`+${dialCode}`, "")?.length > 4
    ) {
      type === "contact" ? setErrorContactMobile(false) : setError(false);
    } else {
      type === "contact" ? setErrorContactMobile(true) : setError(true);
    }
    setDirty(true);
    if (type == "contact") {
      setContactMobile(value);
    } else {
      setMobile(value);
    }
    setMobileDialCode(dialCode);
  };
  const filteredOptions = clients?.filter(option => {
    const query = searchQuery?.toLowerCase();
    return (
      option?.name?.toLowerCase()?.includes(query) ||
      (option?.phone && option?.phone?.includes(searchQuery)) ||
      option?.email?.toLowerCase()?.includes(query)
    );
  });
  return (
    <>
      <RouteLeavingGuard
        when={isDirty || (!isSaved && isCloned)}
        // Navigate function
        navigate={(path, isDiscard) => {
          if (isCloned && isDiscard) {
            deleteChecklistSubCollection(newJob.id, collectionName);
            deleteDraftJobInDb(newJob.id, collectionName);
          }
          history.push(path);
        }}
        shouldBlockNavigation={() => {
          return isDirty || (!isSaved && isCloned);
        }}
        setWizardExited={setIsWizardExited}
        saveJob={onSave}
      />
      <JobLeavingGuard
        when={!isDirty && !isWizardExited && isAnyDataSaved && isSaved}
        navigate={path => {
          return history.push(path);
        }}
        shouldBlockNavigation={() => {
          return !isDirty && !isWizardExited && isAnyDataSaved && isSaved;
        }}
      />
      <Container>
        <Card>
          <CardBody style={{ margin: "20px" }}>
            <Row>
              <Alert color="primary">
                <i className="mdi mdi-bullseye-arrow me-2"></i>
                You can search for the Client using their{" "}
                <span>
                  <b>email</b>
                </span>{" "}
                or{" "}
                <span>
                  <b>mobile</b>
                </span>{" "}
                if they are registered in the platform{" "}
                <span style={{ color: "red" }}>
                  <b>OR</b>
                </span>{" "}
                add their details manually below
              </Alert>{" "}
            </Row>
            <Row>
              <Col lg="6" md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="basicpill-job-custom-no">
                    Custom Job no.
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-job-custom-no"
                    value={customJobNumber || ""}
                    onChange={e => {
                      setDirty(true);
                      setCustomJobNumber(e.target.value || "");
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="basicpill-firstname-input1">
                    Client Name
                  </Label>
                  <Typeahead
                    id="client-search"
                    labelKey={option => `${option.name}`}
                    clearButton
                    renderMenuItemChildren={(option, { text }, index) => (
                      <Fragment>
                        <Highlighter search={text}>{option.email}</Highlighter>
                        <div>
                          <small>
                            <Highlighter search={text}>
                              {option.name}
                            </Highlighter>
                          </small>
                        </div>
                        <div>
                          <small>
                            <Highlighter search={text}>
                              {option.phone || ""}
                            </Highlighter>
                          </small>
                        </div>
                      </Fragment>
                    )}
                    onChange={selected => {
                      setDirty(true);
                      if (selected.length) {
                        setName(selected[0].name);
                        console.log(selected[0].name);
                        setMobileDialCode(selected[0].phoneDialCode);
                        handlePhoneNumberChange(
                          selected[0].phone,
                          selected[0].phoneDialCode
                        );
                        setEmail(selected[0].email);
                        setClientAddress(selected[0].address);
                        setClientId(selected[0].id);
                      }
                    }}
                    onInputChange={(text, e) => {
                      setSearchQuery(text);
                    }}
                    options={filteredOptions}
                    placeholder="Enter client name..."
                  />
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="basicpill-job-custom-no">
                    Post Code
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-job-custom-no"
                    value={postCode || ""}
                    onChange={e => {
                      setDirty(true);
                      setPostCode(e.target.value || "");
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg="6" md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="basicpill-job-name">Job Name</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-job-name"
                    value={jobName || ""}
                    onChange={e => {
                      setDirty(true);
                      setJobName(e.target.value || "");
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="basicpill-email">Client Email</Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="basicpill-email"
                    value={email || ""}
                    onChange={e => {
                      setDirty(true);
                      setShowErrorMessage(false);
                      setEmail(e.target.value);
                    }}
                  />
                  {!email && !isValidEmail(email) && (
                    <small className="requiredError">{}</small>
                  )}
                  {showErrorMessage && (
                    <p className="requiredError">Email already exists</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col lg="6" md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="basicpill-phoneno">
                    Contact Phone Number
                  </Label>
                  <MuiPhoneNumber
                    className="clientDetailsPhoneNumber form-control"
                    name="phone"
                    label="Phone"
                    defaultCountry={"au"}
                    variant="outlined"
                    value={contactMobile || ""}
                    size="small"
                    fullWidth
                    onChange={(text, country) => {
                      handlePhoneNumberChange(
                        text,
                        country?.dialCode,
                        "contact"
                      );
                    }}
                    autoFormat={false}
                  />

                  {!(
                    !contactMobile ||
                    contactMobile === "+" ||
                    contactMobile?.length < 4
                  ) &&
                    errorContactMobile && (
                      <small className="requiredError">
                        {"*Invalid Phone number"}
                      </small>
                    )}
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="basicpill-phoneno">Client Phone Number</Label>
                  <MuiPhoneNumber
                    className="clientDetailsPhoneNumber form-control"
                    name="phone"
                    label="Phone"
                    defaultCountry={"au"}
                    variant="outlined"
                    value={mobile || ""}
                    size="small"
                    fullWidth
                    onChange={(text, country) => {
                      handlePhoneNumberChange(
                        text,
                        country?.dialCode,
                        "client"
                      );
                    }}
                    autoFormat={false}
                  />

                  {!(!mobile || mobile === "+" || mobile?.length < 4) &&
                    error && (
                      <small className="requiredError">
                        {"*Invalid Phone number"}
                      </small>
                    )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6">
                <FormGroup className="mb-3">
                  <AdressAutoComplete
                    className={"form-control"}
                    labelName={null}
                    labelComponent={
                      <>
                        <span>Site Address</span>
                        <Checkbox
                          id="same-as-client-address"
                          checked={isClientAndJobAddressSame}
                          onChange={event => {
                            setDirty(true);
                            setIsClientAndJobAddressSame(event.target.checked);
                          }}
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                        />
                        <Label for="same-as-client-address">
                          same as Client Address
                        </Label>
                      </>
                    }
                    address={jobAddress || ""}
                    setAddress={setJobAddress}
                    label={true}
                    buttons={false}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup className="mb-3">
                  <AdressAutoComplete
                    labelName="Client Address"
                    address={clientAddress ?? ""}
                    setAddress={text => {
                      setDirty(true);
                      setClientAddress(text);
                    }}
                    label={true}
                    buttons={false}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row className="clearfix" style={{ marginBottom: "20px" }}>
          <div className="text-end">
            <button
              disabled={isAllClientDetailsFilled}
              className="btn btn-primary w-md mtb-2"
              onClick={() => onSave()}
              type="button"
            >
              Save
            </button>
            <button
              disabled={isAllClientDetailsFilled}
              className="btn btn-primary w-md ml-20"
              onClick={() => onNext()}
            >
              Next
            </button>
          </div>
        </Row>
      </Container>
    </>
  );
}
