import { Switch } from "@mui/material";
import { ACTIVE, INACTIVE } from "common/constants";
import MuiPhoneNumber from "mui-phone-number";
import { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import "../../../common/styles.css";
import { handleOrganisation } from "./organisationHandler";
import {
  getOrganizationNameErrorMessage,
  getStartingJobNumberErrorMessage,
  getDocumentNumberErrorMessage,
  phoneNumberHandler,
  getPhoneNumberErrorMessage,
  getorgIdentityCodeErrorMessage,
} from "./FormHandler";
import { AddressSuggestion } from "./addressSuggestion";

export default function CreateNewOrg({
  showCreateNewOrg,
  setShowCreateNewOrg,
  isEditOrg,
  setIsEditOrg,
  organisation,
  setOrg,
  allOrgs,
}) {
  const [address, setAddress] = useState(organisation?.address || "");
  const [isValidForm, setIsValidForm] = useState(isEditOrg ? true : false);
  const [orgError, setOrgNameError] = useState(false);
  const[orgIdentityCode, setOrgIdentityCode] = useState(organisation?.orgIdentityCode || "");
  const [orgIdentityCodeError, setorgIdentityCodeError] = useState(false);

  const [error, setError] = useState(false);
  useEffect(() => {
    setIsValidForm(
      organisation?.name &&
        organisation?.phone &&
        organisation?.phone !== "+" &&
        organisation?.phone?.replace(`+${organisation?.countryCode}`, "")
          ?.length <= 10 &&
        organisation?.phone?.replace(`+${organisation?.countryCode}`, "")
          ?.length > 4 &&
        address &&
        address !== "" &&
        organisation.startingJobNumber &&
        organisation?.startingJobNumber >= 0 &&
        organisation?.startingJobNumber < 1000000 &&
        organisation.documentPreferenceNumber &&
        organisation?.documentPreferenceNumber >= 0 &&
        organisation?.documentPreferenceNumber < 1000000 &&
        orgIdentityCode &&
        orgIdentityCode?.length <=5
    );
  }, [organisation, address,orgIdentityCode]);
  const handlePhoneNumberChange = phoneNumberHandler(
    setError,
    setOrg,
    organisation
  );
  return (
    <Modal
      isOpen={showCreateNewOrg}
      toggle={() => setShowCreateNewOrg(!showCreateNewOrg)}
    >
      <ModalHeader
        toggle={() => setShowCreateNewOrg(!showCreateNewOrg)}
        tag="h4"
      >
        {isEditOrg ? "Edit Organisation" : " Add Organisation"}
      </ModalHeader>
      <ModalBody>
        <Row form>
          <Col xs={12}>
            {isEditOrg && (
              <div className="mb-3">
                <Label>Company/Organisation Unique Code</Label>
                <Input
                  name="id"
                  type="text"
                  value={organisation?.id || ""}
                  disabled
                />
              </div>
            )}
            <div className="mb-3">
              <Label>Organisation Name</Label>
              <Input
                name="name"
                type="text"
                value={organisation?.name || ""}
                onChange={e => {
                  setOrg({
                    ...organisation,
                    name: e.target.value,
                  });
                  setOrgNameError(false);
                }}
              />
              {getOrganizationNameErrorMessage(
                organisation?.name,
                orgError
              ) && (
                <small className="requiredError">
                  {getOrganizationNameErrorMessage(
                    organisation?.name,
                    orgError
                  )}
                </small>
              )}
            </div>
            <div className="mb-3">
              <Label>Business/Company Number</Label>
              <Input
                name="abn"
                type="text"
                value={organisation?.abn || ""}
                onChange={e => {
                  setOrg({
                    ...organisation,
                    abn: e.target.value,
                  });
                }}
              />
            </div>
            <div className="mb-3">
              <Label>Mobile</Label>
              <MuiPhoneNumber
                name="phone"
                label="Mobile"
                autoFocus={false}
                autoFormat={false}
                defaultCountry={"au"}
                variant="outlined"
                value={organisation?.phone || ""}
                size="small"
                fullWidth
                onChange={(text, country) =>
                  handlePhoneNumberChange(text, country?.dialCode)
                }
                error={error}
              />
              {getPhoneNumberErrorMessage(organisation?.phone, error) && (
                <p className="requiredError">
                  {getPhoneNumberErrorMessage(organisation?.phone, error)}
                </p>
              )}
            </div>
            {<AddressSuggestion address={address} setAddress={setAddress} />}
            <div className="mb-3">
              <Label>Company/Organisation Identification Code</Label>
              <Input
                name="startingorgIdentityCode"
                type="text"
                value={orgIdentityCode}
                disabled={organisation?.orgIdentityCode}
                onChange={e => {
                  setOrgIdentityCode(e.target.value);
                  setorgIdentityCodeError(false);
                }}
              />
              {getorgIdentityCodeErrorMessage(orgIdentityCode, orgIdentityCodeError) && (
                <small className="requiredError">
                  {getorgIdentityCodeErrorMessage(orgIdentityCode, orgIdentityCodeError)}
                </small>
              )}
            </div>
            <div className="mb-3">
              <Label>Job Number Start Preference</Label>
              <Input
                name="startingJobNumber"
                type="number"
                value={organisation?.startingJobNumber || ""}
                disabled={isEditOrg}
                onChange={e => {
                  setOrg({
                    ...organisation,
                    startingJobNumber: e.target.value,
                  });
                }}
              />
              {getStartingJobNumberErrorMessage(
                organisation?.startingJobNumber
              ) && (
                <small className="requiredError">
                  {getStartingJobNumberErrorMessage(
                    organisation?.startingJobNumber
                  )}
                </small>
              )}
            </div>
            <div className="mb-3">
              <Label>Document Number Preference </Label>
              <Input
                name="documentPreferenceNumber"
                type="number"
                value={organisation?.documentPreferenceNumber || ""}
                disabled={isEditOrg}
                onChange={e => {
                  setOrg({
                    ...organisation,
                    documentPreferenceNumber: e.target.value,
                  });
                }}
              />
              {getDocumentNumberErrorMessage(
                organisation?.documentPreferenceNumber
              ) && (
                <small className="requiredError">
                  {getDocumentNumberErrorMessage(
                    organisation?.documentPreferenceNumber
                  )}
                </small>
              )}
            </div>
            {isEditOrg && (
              <div className="mb-3">
                <Label className="status-label">Status</Label>
                <Switch
                  defaultChecked={organisation?.status === ACTIVE}
                  color="primary"
                  onChange={e => {
                    setOrg({
                      ...organisation,
                      status: e.target.checked ? ACTIVE : INACTIVE,
                    });
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-success save-user"
                disabled={!isValidForm}
                onClick={() => {
                  handleOrganisation(
                    allOrgs,
                    setOrgNameError,
                    setorgIdentityCodeError,
                    organisation,
                    address,
                    isEditOrg,
                    setIsEditOrg,
                    setShowCreateNewOrg,
                    orgIdentityCode
                  );
                }}
              >
                Save
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
