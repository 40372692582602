import React, { useEffect } from "react";

const ScrollLock = ({ isEnabled }) => {
  useEffect(() => {
    if (isEnabled) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isEnabled]);

  return null;
};

export default ScrollLock;
