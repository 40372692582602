import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CloseButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TabContent, TabPane } from "reactstrap";
import { UPDATE_CATEGORY_STEP } from "../../jobCreation/utils/jobWizard";
import { fetchJobFromDbById } from "services";
import "../../jobCreation/jobCategory.css";
import useFirestoreDoc from "common/hooks/useFirestoreDoc";
import JobCategory from './JobCategory';
import BuildingClass from './BuildingClass';

const EditJobCategory = ({ isOpen, closeModal, jobId,collectionName }) => {
  Modal.setAppElement("#root");
  const [activeTab, setActiveTab] = useState(UPDATE_CATEGORY_STEP.JOBCATEGORY);
  const [isDirty, setDirty] = useState(false);
  const [isWizardExited, setIsWizardExited] = useState(false);
  const [job, setJob] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [jobType, setJobType] = useState(job?.jobType);
  const [jobCategory, setJobCategory] = useState({
    belowground: job?.belowground?.jobCategory ?? "none",
    external: job?.external?.jobCategory ?? "none",
    internal: job?.internal?.jobCategory ?? "none",
  });
  const deviceHeight = window.innerHeight; 
const modalMaxHeight = Math.floor(deviceHeight * 0.8);
  useFirestoreDoc({
    query: () => fetchJobFromDbById(collectionName, jobId),
    onStart: () => () => setLoading(true),
    onSuccess: job => {
      setJob(job);
      setLoading(false);
    },
    onError: error => setError(error),
    deps: [isOpen, jobId],
  });

  useEffect(() => {
    setJobCategory({
      belowground: job?.belowground?.jobCategory ?? "none",
      external: job?.external?.jobCategory ?? "none",
      internal: job?.internal?.jobCategory ?? "none",
    });
    setJobType(job?.jobType);
  }, [job, isOpen]);

  const onPrev = () => {
    if (activeTab >= UPDATE_CATEGORY_STEP.JOBCATEGORY) {
      let newTab = activeTab - 1;
      setActiveTab(newTab);
    }
  };

  const onNext = () => {
    if (activeTab <= UPDATE_CATEGORY_STEP.BUILDINGCLASS) {
      let newTab = activeTab + 1;
      setActiveTab(newTab);
    }
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{ content: { overflow: "auto", maxHeight: `${modalMaxHeight}px` } }}>
      <div>
        <CloseButton onClick={closeModal} style={{ float: "right" }}>
          <CloseIcon />
        </CloseButton>
        { 
        (job !== null && jobType !== null)  &&
        <div
          className="content clearfix"
          style={{ margin: "0px -8px", padding: "0px" }}
        >
          <TabContent activeTab={activeTab} className="body">
            <TabPane tabId={UPDATE_CATEGORY_STEP.JOBCATEGORY}>
              {activeTab === UPDATE_CATEGORY_STEP.JOBCATEGORY && (
                <>
                <JobCategory
                 job={job}
                 setJob={setJob}
                 next={onNext}
                 setDirty={setDirty}
                 isDirty={isDirty}
                 setIsWizardExited={setIsWizardExited}
                 isWizardExited={isWizardExited}
                 collectionName={collectionName}
                 jobCategory={jobCategory}
                 setJobCategory={setJobCategory}
                 jobType={jobType}
                 setJobType={setJobType}
                 closeModal={closeModal}
                />
                </>
              )}
            </TabPane>
            <TabPane tabId={UPDATE_CATEGORY_STEP.BUILDINGCLASS}>
              {activeTab === UPDATE_CATEGORY_STEP.BUILDINGCLASS && (
                <>
                 <BuildingClass
                  job={job}
                  prev={onPrev}
                  setDirty={setDirty}
                  isDirty={isDirty}
                  setIsWizardExited={setIsWizardExited}
                  isWizardExited={isWizardExited}
                  collectionName={collectionName}
                  jobCategory={jobCategory}
                  jobType={jobType}
                  closeModal={closeModal}
                />
                </>
              )}
            </TabPane>
          </TabContent>
        </div>
        }
      </div>
    </Modal>
  );
};

export default EditJobCategory;
