import { Row } from "reactstrap";
import { LinkActiveJobs } from "./LinkActiveJobs";
import {
  LinkJobContextProvider,
  useLinkJobContext,
} from "context/LinkjobContext";
import { updateJobDraftInDb } from "services";
import RouteLeavingGuard from "../RouteLeavingGuard";
import JobLeavingGuard from "../JobLeavingGuard";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
export default function LinkJobToInspection(props) {
  const { jobs } = useSelector(state => state.job);
  if (props.newJob.linkedJobs) {
    localStorage.setItem(
      "checkedJobs",
      JSON.stringify(props.newJob.linkedJobs)
    );
  }

  const history = useHistory();
  const onSave = checkedItems => {
    props.setNewJob({ ...props.newJob, linkedJobs: checkedItems });
    updateJobDraftInDb(
      { ...props.newJob, linkedJobs: checkedItems },
      props.collectionName
    );
    props.setDirty(false);
  };

  const onNext = checkedItems => {
    onSave(checkedItems);
    props.next();
  };
  const onPrev = checkedItems => {
    props.setNewJob({ ...props.newJob, linkedJobs: checkedItems });
    updateJobDraftInDb(
      { ...props.newJob, linkedJobs: checkedItems },
      props.collectionName
    );
    props.prev();
  };

  const PageNavigationButtons = props => {
    const { checkedItems } = useLinkJobContext();
    return (
      <Row
        className="clearfix"
        style={{ marginBottom: "20px", marginRight: "0px" }}
      >
        <div className="text-end">
          <button
            className="btn btn-primary w-md"
            onClick={() => onPrev(checkedItems)}
            type="button"
          >
            Previous
          </button>
          <button
            // disabled={!isNext}
            className="btn btn-primary w-md mtb-2 ml-20"
            onClick={() => onSave(checkedItems)}
            type="button"
          >
            Save
          </button>
          <button
            // disabled={!isNext}
            className="btn btn-primary w-md ml-20"
            type="button"
            onClick={() => onNext(checkedItems)}
          >
            Next
          </button>
        </div>
      </Row>
    );
  };
  return (
    <>
      <RouteLeavingGuard
        when={props.isDirty}
        // Navigate function
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return props.isDirty;
        }}
        setWizardExited={props.setIsWizardExited}
        saveJob={onSave}
      />
      <JobLeavingGuard
        when={!props.isDirty && !props.isWizardExited}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return !props.isDirty && !props.isWizardExited;
        }}
      />
      <LinkJobContextProvider>
        <LinkActiveJobs />
        <PageNavigationButtons props={props} />
      </LinkJobContextProvider>
    </>
  );
}
