import firebase from "../config/firebase";
const db = firebase.firestore();

export function fetchListItemsFromDb(orgId) {
  let dbRef = db.collection(`orgs/${orgId}/listitem`);
  return dbRef.where("orgId", "==", orgId);
}
export function fetchInspectionListItemsFromDb(orgId) {
  let dbRef = db.collection(`orgs/${orgId}/inspectionlistitem`);
  return dbRef.where("orgId", "==", orgId);
}
export function fetchDefaultInspectionListItemsFromDb(orgId) {
  let dbRef = db.collection(`orgs/${orgId}/inspectionlistitem`);
  return dbRef.where("locked", "==", true);
}

export function fetchDefaultListItemsFromDb(orgId) {
  let dbRef = db.collection(`orgs/${orgId}/listitem`);
  return dbRef.where("locked", "==", true);
}

export function addNewListItemToDB(listItem, orgId) {
  const user = firebase.auth().currentUser;
  return db.collection(`orgs/${orgId}/listitem`).add({
    ...listItem,
    contributorIds: [user.uid],
    orgId,
    date: firebase.firestore.FieldValue.serverTimestamp(),
  });
}
export function addNewInspectionListItemToDB(listItem, orgId) {
  const user = firebase.auth().currentUser;
  return db.collection(`orgs/${orgId}/inspectionListitem`).add({
    ...listItem,
    contributorIds: [user.uid],
    orgId,
    date: firebase.firestore.FieldValue.serverTimestamp(),
  });
}

export async function deleteListItemInDb(id, orgId) {
  return db.collection(`orgs/${orgId}/listitem`).doc(id).delete();
}

export async function addNewChecklistToDB(checklist, orgId) {
  const user = firebase.auth().currentUser;
  return db
    .collection(`orgs/${orgId}/checklists`)
    .doc(checklist.id)
    .set({
      ...checklist,
      contributorIds: [user.uid],
      orgId,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    });
}
export async function addNewInspectionToDB(inspection, orgId) {
  const user = firebase.auth().currentUser;
  return db
    .collection(`orgs/${orgId}/inspections`)
    .doc(inspection.id)
    .set({
      ...inspection,
      contributorIds: [user.uid],
      orgId,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    });
}
export async function addNewJobReportTemplateToDB(inspection, orgId) {
  const user = firebase.auth().currentUser;
  return db
    .collection(`orgs/${orgId}/jobReportTemplates`)
    .doc(inspection.id)
    .set({
      ...inspection,
      contributorIds: [user.uid],
      orgId,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    });
}
export function fetchChecklistsFromDB(orgId) {
  let dbRef = db.collection(`orgs/${orgId}/checklists`);
  return dbRef.where("orgId", "==", orgId);
}
export function fetchInspectionChecklistsFromDB(orgId) {
  let dbRef = db.collection(`orgs/${orgId}/inspections`);
  return dbRef.where("orgId", "==", orgId);
}
export function fetchJobReportTemplatesChecklistsFromDB(orgId) {
  let dbRef = db.collection(`orgs/${orgId}/jobReportTemplates`);
  return dbRef.where("orgId", "==", orgId);
}
export function updateChecklistInDb(item, orgId) {
  return db.collection(`orgs/${orgId}/checklists`).doc(item.id).update(item);
}
export function updateInspectionListInDb(item, orgId) {
  return db.collection(`orgs/${orgId}/inspections`).doc(item.id).update(item);
}
export function updateJobReportTemplatesListInDb(item, id,orgId) {
  return db.collection(`orgs/${orgId}/jobReportTemplates`).doc(id).update(item);
}
export function getChecklistOfSameName(checklistName, orgId) {
  return db
    .collection(`orgs/${orgId}/checklists`)
    .where("title", "==", checklistName)
    .get();
}
export function getChecklist(orgId) {
  return db.collection(`orgs/${orgId}/checklists`).orderBy("title").get();
}
export function getInspectionTemplate(orgId) {
  return db.collection(`orgs/${orgId}/inspections`).orderBy("templateName").get();
}
export function getJobReportTemplates(orgId) {
  return db.collection(`orgs/${orgId}/jobReportTemplates`).orderBy("templateName").get();
}
export async function deleteMultipleSelectedChecklist(checklistIds, orgId) {
  const batch = db.batch();
  checklistIds.forEach(id => {
    const ref = db.collection(`orgs/${orgId}/checklists`).doc(id);
    batch.delete(ref);
  });
  return batch.commit();
}
export async function deleteMultipleSelectedInspectionChecklist(checklistIds, orgId) {
  const batch = db.batch();
  checklistIds.forEach(id => {
    const ref = db.collection(`orgs/${orgId}/inspections`).doc(id);
    batch.delete(ref);
  });
  return batch.commit();
}
export async function deleteMultipleSelectedJobReportTemplatesChecklist(checklistIds, orgId) {
  const batch = db.batch();
  checklistIds.forEach(id => {
    const ref = db.collection(`orgs/${orgId}/jobReportTemplates`).doc(id);
    batch.delete(ref);
  });
  return batch.commit();
}
export async function deleteSelectedJobReportTemplate(templateId, orgId) {
   return db.collection(`orgs/${orgId}/jobReportTemplates`).doc(templateId).delete();
}