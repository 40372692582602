// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Grid {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(
      0deg,
      transparent,
      transparent calc(var(--grid-size) - 1px),
      #ddd calc(var(--grid-size) - 1px),
      #ddd var(--grid-size)
    ),
    repeating-linear-gradient(
      -90deg,
      transparent,
      transparent calc(var(--grid-size) - 1px),
      #ddd calc(var(--grid-size) - 1px),
      #ddd var(--grid-size)
    );
  background-size: var(--grid-size) var(--grid-size);
  z-index: -1;
  pointer-events: none;
}

.RangeSlider {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/dnd/grid.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ;;;;;;;;;;;;;KAaG;EACH,kDAAkD;EAClD,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;AACd","sourcesContent":[".Grid {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-image: repeating-linear-gradient(\n      0deg,\n      transparent,\n      transparent calc(var(--grid-size) - 1px),\n      #ddd calc(var(--grid-size) - 1px),\n      #ddd var(--grid-size)\n    ),\n    repeating-linear-gradient(\n      -90deg,\n      transparent,\n      transparent calc(var(--grid-size) - 1px),\n      #ddd calc(var(--grid-size) - 1px),\n      #ddd var(--grid-size)\n    );\n  background-size: var(--grid-size) var(--grid-size);\n  z-index: -1;\n  pointer-events: none;\n}\n\n.RangeSlider {\n  position: fixed;\n  right: 20px;\n  bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
