function ProgressBar(props) {
    const { percentage } = props;
  
    const containerStyles = {
      height: 20,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: 50,
      position: 'relative'
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${percentage}%`,
      borderRadius: 'inherit',
      backgroundColor:'#50a5f1',
    }
  
    const labelStyles = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      fontWeight: 'bold'
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}></div>
        <span style={labelStyles}>{`${percentage}%`}</span>
      </div>
    );
  }
  

  export default ProgressBar;