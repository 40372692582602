import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { updateUserInDb } from "services";

export function NotesPermission({ setNotesPermission,label }) {
  const { currentUserProfile } = useSelector(state => state.profile);

  const onConfirm = () => {
    if (currentUserProfile?.disableNotes) {
      updateUserInDb({
        ...currentUserProfile,
        disableNotes:false,
      });
      setNotesPermission(false);
      return;
    }
    updateUserInDb({
      ...currentUserProfile,
      disableNotes:true
    });
    setNotesPermission(false);
    return;
  };
  const boldStyle = {
    fontWeight: "bold",
  };
  const onCancel = () => {
    setNotesPermission(false);
  };
  return (
    <>
      {currentUserProfile?.disableNotes ? (
        <SweetAlert
          title={"Are you sure you want to enable notes saving popup?"}
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={onConfirm}
          onCancel={onCancel}
        ></SweetAlert>
      ) : (
        <SweetAlert
          title={"Are you sure you want to disable notes saving popup for future notes?"}
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={onConfirm}
          onCancel={onCancel}
        > 
       {label}
          </SweetAlert>
      )}
    </>
  );
}
