import { StyleSheet } from "@react-pdf/renderer";

export function FooterStyleSheet() {
  return StyleSheet.create({
    footer: {
      position: "absolute",
      fontSize: 14,
      bottom: 0,
      marginTop: 15,
      marginBottom: 10,
      left: 15,
      right: 10,
      textAlign: "right",
      color: "black",
    },
    logo: {
      width: 45,
      height: 45,
      alignSelf: "flex-start",
    },
    watermark: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      opacity: 0.1,
      color: "gray",
      width: 300,
      height: 300,
    },
    horizontalLine: {
      borderBottom: 2,
      borderBottomColor: 'linear-gradient(to right, #ffcc00, #ff6600)'
    },
  });
}
