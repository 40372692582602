import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import RouteLeavingGuard from "pages/jobs/jobCreation/RouteLeavingGuard";
import JobLeavingGuard from "pages/jobs/jobCreation/JobLeavingGuard";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Form, Row } from "reactstrap";
import { updateJobDraftInDb } from "services";
import belowLogo from "./../../../assets/images/icons8/icons8-below.png";
import externalLogo from "./../../../assets/images/icons8/icons8-external.png";
import newHomeLogo from "./../../../assets/images/icons8/icons8-home-new.png";
import internalLogo from "./../../../assets/images/icons8/icons8-internal.png";
import rectifyLogo from "./../../../assets/images/icons8/icons8-rectify.png";
import renovateLogo from "./../../../assets/images/icons8/icons8-renovate.png";
import "./jobCategory.css";
import { COMMERCIAL_CATEGORY, INFRASTRUCTURE_CATEGORY, RESIDENTIAL_CATEGORY } from "common/constants";

export default function JobCategory({
  newJob,
  setNewJob,
  next,
  prev,
  isDirty,
  setDirty,
  setIsWizardExited,
  isWizardExited,
  collectionName
}) {
  const history = useHistory();
  const [isNext, setIsNext] = useState(false);
  const [jobCategory, setJobCategory] = useState(newJob.jobCategory);
  const [jobType, setJobType] = useState(newJob.jobType);

  useEffect(() => {
    const isValidInternalSelection = jobType.internal
      ? jobCategory.internal !== "none"
      : true;
    const isValidExternalSelection = jobType.external
      ? jobCategory.external !== "none"
      : true;
    const isValidBelowgroundSelection = jobType.belowground
      ? jobCategory.belowground !== "none"
      : true;

    const atleastOneJobCategorySelected =
      jobType.internal || jobType.external || jobType.belowground;

    setIsNext(
      atleastOneJobCategorySelected &&
        isValidInternalSelection &&
        isValidExternalSelection &&
        isValidBelowgroundSelection
    );
  }, [jobCategory, jobType, newJob.jobCategory, newJob.jobTyp]);

  const onSave = () => {
    setDirty(false);
    setNewJob({ ...newJob, jobCategory: jobCategory, jobType: jobType });
    updateJobDraftInDb({
      ...newJob,
      jobCategory: jobCategory,
      jobType: jobType,
    },collectionName);
  };

  const onNext = () => {
    onSave();
    next();
  };

  const JobTypeButton = ({ label,logoSrc, onClick, jobTypeName }) => {
    const checked = jobType[jobTypeName.toLowerCase()];
    return (
      <div className={`jobTypeButton ${checked ? "jobTypeButtonChecked" : ""}`}>
        <img src={logoSrc} />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={onClick}
              className="checked"
            />
          }
          label={label}
          className="fontStyle"
        />
      </div>
    );
  };

  const JobCategoryButton = ({
    categoryName,
    logoSrc,
    jobTypeName,
    jobCategoryType,
    disabled,
  }) => {
    const onClick = e => {
      setJobCategory({
        ...jobCategory,
        [jobTypeName?.toLowerCase()]: jobCategoryType,
      });
      setDirty(true);
    };

    const checked = jobCategory[jobTypeName?.toLowerCase()] === jobCategoryType;

    return (
      <div
        className={`jobCategoryButton ${checked ? "jobCategorySelected" : ""}`}
      >
        <FormControlLabel
          control={
            <div>
              <Radio
                checked={checked}
                onChange={onClick}
                size="small"
                disabled={disabled}
                className= "checked"
              />
              <img
                src={logoSrc}
                alt="Header Avatar"
                style={{ marginRight: "10px", width: "28px", height: "28px" }}
              />
            </div>
          }
          label={categoryName}
          className="fontStyle"
        />
      </div>
    );
  };

  const JobCategoriesTypes = ({ jobTypeName, hide }) => {
    return (
      <div className={hide ? "blur" : ""}>
        <RadioGroup>
          <JobCategoryButton
            categoryName={"New construction"}
            logoSrc={newHomeLogo}
            jobTypeName={jobTypeName}
            jobCategoryType={"new"}
            disabled={hide}
          />
          <JobCategoryButton
            categoryName={"Renovation"}
            logoSrc={renovateLogo}
            jobTypeName={jobTypeName}
            jobCategoryType={"renovation"}
            disabled={hide}
          />
          <JobCategoryButton
            categoryName={"Remediation"}
            logoSrc={rectifyLogo}
            jobTypeName={jobTypeName}
            jobCategoryType={"Remediation"}
            disabled={hide}
          />
        </RadioGroup>
      </div>
    );
  };

  const InternalJobType = () => {
    return (
      <div className="jobTypeContainer">
        <JobTypeButton
        label={RESIDENTIAL_CATEGORY}
          jobTypeName={"Internal"}
          logoSrc={internalLogo}
          onClick={e => {
            setJobType({ ...jobType, internal: !jobType.internal });
            if (jobType.internal) {
              setJobCategory({ ...jobCategory, internal: "none" });
            }
            setDirty(true);
          }}
        />

        <JobCategoriesTypes
          jobCategory={jobCategory.internal}
          jobTypeName={"internal"}
          hide={!jobType.internal}
        />
      </div>
    );
  };

  const ExternalJobType = () => {
    return (
      <div className="jobTypeContainer">
        <JobTypeButton
        label={COMMERCIAL_CATEGORY}
          jobTypeName={"External"}
          logoSrc={externalLogo}
          onClick={e => {
            setJobType({ ...jobType, external: !jobType.external });
            if (jobType.external) {
              setJobCategory({ ...jobCategory, external: "none" });
            }
            setDirty(true);
          }}
        />

        <JobCategoriesTypes
          jobCategory={jobCategory.external}
          jobTypeName={"external"}
          hide={!jobType.external}
        />
      </div>
    );
  };

  const BelowGroundJobType = () => {
    return (
      <div className="jobTypeContainer">
        <JobTypeButton
        label={INFRASTRUCTURE_CATEGORY}
          jobTypeName={"Belowground"}
          logoSrc={belowLogo}
          onClick={e => {
            setJobType({
              ...jobType,
              belowground: !jobType.belowground,
            });
            if (jobType.belowground) {
              setJobCategory({ ...jobCategory, belowground: "none" });
            }
            setDirty(true);
          }}
        />

        <JobCategoriesTypes
          jobCategory={jobCategory.belowground}
          jobTypeName={"belowground"}
          hide={!jobType.belowground}
        />
      </div>
    );
  };

  const PageNavigationButtons = () => {
    return (
      <Row className="clearfix mt-3" style={{ marginBottom: "20px" }}>
        <div className="text-end">
          <button
            className="btn btn-primary w-md"
            onClick={() => prev()}
            type="button"
          >
            Previous
          </button>

          <button
            disabled={!isNext}
            className="btn btn-primary w-md mtb-2 ml-20"
            onClick={() => onSave()}
            type="button"
          >
            Save
          </button>

          <button
            disabled={!isNext}
            className="btn btn-primary w-md ml-20"
            type="button"
            onClick={() => onNext()}
          >
            Next
          </button>
        </div>
      </Row>
    );
  };

  return (
    <>
      <RouteLeavingGuard
        when={isDirty}
        // Navigate function
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return isDirty;
        }}
        setWizardExited={setIsWizardExited}
        saveJob={onSave}
      />
      <JobLeavingGuard
        when={!isDirty && !isWizardExited}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return !isDirty && !isWizardExited;
        }}
      />
      <Form>
        <Card>
          <CardBody style={{ margin: "20px" }}>
            <Typography className="fontStyle">
              Select the appropriate job category
            </Typography>

            <div className="typesContainer">
              <InternalJobType />
              <ExternalJobType />
              <BelowGroundJobType />
            </div>
          </CardBody>
        </Card>

        <PageNavigationButtons />
      </Form>
    </>
  );
}
