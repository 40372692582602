import React from 'react'
import { Row } from 'reactstrap'

function PrevNextUI({onClick,disablePrev,disableNext}) {
  return (
  <>
     <Row className="clearfix" style={{ marginBottom: "20px" }}>
      <div className="text-center">
        <button
          className="btn btn-primary w-md mtb-2"
          name="prev"
          onClick={onClick}
          type="button"
          disabled={disablePrev}
        >
          Go to First Page
        </button>
        <button
          className="btn btn-primary  w-md ml-20 mtb-2"
          type="button"
          name="next"
          onClick={onClick}
          disabled={disableNext}
        >
          Next
        </button>
      </div>
    </Row>
  </>
  )
}

export default PrevNextUI