// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sui-search-box__submit {
  background: none; }

.sui-layout-sidebar-toggle {
  color: red;
  border: 1px solid red; }

.sui-result__title,
.sui-result__title-link {
  color: red; }

.sui-facet-view-more {
  color: red; }

.sui-search-box__autocomplete-container div:hover {
  background-color: #50a5f1; }

.sui-search-box__autocomplete-container div {
  margin: 0 12px;
  font-size: 0.9em;
  padding: 4px 12px;
  border-radius: 4px;
  color: #555;
  cursor: default; }

.sui-search-box__submit:hover {
  background: none;
  background-color: transparent; }

.sui-search-box__autocomplete-container:empty {
  display: none; }

.text-wrap {
  overflow: break-word;
  white-space: normal;
  text-overflow: ellipsis; }
`, "",{"version":3,"sources":["webpack://./src/searchuicomponents/customStyles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAA;;AAGlB;EACE,UAAU;EACV,qBAAqB,EAAA;;AAGvB;;EAEE,UAAU,EAAA;;AAGZ;EACE,UAAU,EAAA;;AAGZ;EACE,yBAAyB,EAAA;;AAE3B;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,eAAe,EAAA;;AAGjB;EACE,gBAAgB;EAChB,6BAA6B,EAAA;;AAE/B;EACE,aAAa,EAAA;;AAEf;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB,EAAA","sourcesContent":[".sui-search-box__submit {\n  background: none;\n}\n\n.sui-layout-sidebar-toggle {\n  color: red;\n  border: 1px solid red;\n}\n\n.sui-result__title,\n.sui-result__title-link {\n  color: red;\n}\n\n.sui-facet-view-more {\n  color: red;\n}\n\n.sui-search-box__autocomplete-container div:hover {\n  background-color: #50a5f1;\n}\n.sui-search-box__autocomplete-container div {\n  margin: 0 12px;\n  font-size: 0.9em;\n  padding: 4px 12px;\n  border-radius: 4px;\n  color: #555;\n  cursor: default;\n}\n\n.sui-search-box__submit:hover {\n  background: none;\n  background-color: transparent;\n}\n.sui-search-box__autocomplete-container:empty {\n  display: none;\n}\n.text-wrap {\n  overflow: break-word;\n  white-space: normal;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
