import crossMark from "assets/images/icons8/icons8-gray-cross.png";
import dragReorder from "assets/images/icons8/icons8-scroll.png";
import classnames from "classnames";
import {
  getItemStyle,
  getListContainerStyle,
  Reorder,
} from "common/checklistUtils/dndStyleUtil";
import cuid from "cuid";
import RouteLeavingGuard from "pages/jobs/jobCreation/RouteLeavingGuard";
import JobLeavingGuard from "pages/jobs/jobCreation/JobLeavingGuard";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
import YouTube from "react-youtube";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Label,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { updateJobDraftInDb } from "services";
import "./FloorContainer.scss";
import FloorUnit from "./FloorUnit";
import RoomUnit from "./RoomUnit";
import React from "react";
import { COMMERCIAL_CATEGORY, FLOOR, INFRASTRUCTURE_CATEGORY, IS_FLOOR_CLONING_ENABLED, RESIDENTIAL_CATEGORY } from "common/constants";
import ClearAllConfirmationPrompt from "components/Common/DeleteConfirmationPrompt";
import { getIcon } from "common/data/rooms";
import { Add } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';

export default function CommandContainer(props) {
  const history = useHistory();
  const { setIsWizardExited, isWizardExited,collectionName } = props;
  const [activeTab, setactiveTab] = useState("0");

  const [hasInternal, setHasInternal] = useState(props.newJob.jobType.internal);
  const [hasExternal, setHasExternal] = useState(props.newJob.jobType.external);
  const [hasBelow, setHasBelow] = useState(props.newJob.jobType.belowground);

  const [itemsInternal, setItemsInternal] = useState([]);
  const [itemsExternal, setItemsExternal] = useState([]);
  const [itemsBelow, setItemsBelow] = useState([]);

  const [isValidInternal, setIsValidInternal] = useState(false);
  const [isValidExternal, setIsValidExternal] = useState(false);
  const [isValidBelowGround, setIsValidBelowGround] = useState(false);

  const [floorsInternalOnly, setFloorsInternalOnly] = useState([]);
  const [floorsExternalOnly, setFloorsExternalOnly] = useState([]);
  const [floorsBelowOnly, setFloorsBelowOnly] = useState([]);

  const [roomsInternalOnly, setRoomsInternalOnly] = useState([]);
  const [roomsExternalOnly, setRoomsExternalOnly] = useState([]);
  const [roomsBelowOnly, setRoomsBelowOnly] = useState([]);

  const [switchParent] = useState(props.switchParent || false);
  const [isNext, setIsNext] = useState(false);

  const [showHelp, setShowHelp] = useState(false);
  const INTERNAL_TAB = "1";
  const EXTERNAL_TAB = "2";
  const BELOW_GROUND_TAB = "3";
  const [clearAllConfirmationPrompt, setClearAllConfirmationPrompt] = useState(false);

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  useEffect(() => {
    props.validateParticipants(props.newJob.participants)
  }, [props.newJob.participants]);
  
  useEffect(() => {
    setHasBelow(props.newJob.jobType.belowground);
    setHasExternal(props.newJob.jobType.external);
    setHasInternal(props.newJob.jobType.internal);
  }, [props.newJob.jobType]);

  useEffect(() => {
    if (props.newJob.jobType.internal) {
      toggle(INTERNAL_TAB);
    } else if (props.newJob.jobType.external) {
      toggle(EXTERNAL_TAB);
    } else if (props.newJob.jobType.belowground) {
      toggle(BELOW_GROUND_TAB);
    }
  }, [props.newJob.jobType]);

  useEffect(() => {
    setItemsInternal(props.newJob.rooms.internal);
    setItemsExternal(props.newJob.rooms.external);
    setItemsBelow(props.newJob.rooms.belowground);
  }, [props.newJob.rooms]);


  const isValidSelection = items => {
    return items.reduce((isValid, floor) => {
      return isValid && floor.subItems.length ? true : false;
    }, true);
  };

  useEffect(() => {
    const isValidInternal = hasInternal
      ? itemsInternal.length && isValidSelection(itemsInternal)
      : true;
    setIsValidInternal(isValidInternal);

    const isValidExternal = hasExternal
      ? itemsExternal.length && isValidSelection(itemsExternal)
      : true;
    setIsValidExternal(isValidExternal);

    const isValidBelowGround = hasBelow
      ? itemsBelow.length && isValidSelection(itemsBelow)
      : true;
    setIsValidBelowGround(isValidBelowGround);

    setIsNext(isValidInternal && isValidExternal && isValidBelowGround);
  }, [itemsInternal, itemsExternal, itemsBelow]);

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const onSave = () => {
    const newItems = {
      internal: itemsInternal,
      external: itemsExternal,
      belowground: itemsBelow,
    };
    props.setNewJob({ ...props.newJob, rooms: newItems });
    updateJobDraftInDb({ ...props.newJob, rooms: newItems },collectionName);
    props.setDirty(false);
  };

  const onNext = () => {
    onSave();
    props.next();
  };

  const onPrev = () => {
    const newItems = {
      internal: itemsInternal,
      external: itemsExternal,
      belowground: itemsBelow,
    };
    props.setNewJob({ ...props.newJob, rooms: newItems });
    updateJobDraftInDb({ ...props.newJob, rooms: newItems },collectionName);
    props.prev();
  };

  const onRemoveFloorInternal = id => {
    setItemsInternal([...itemsInternal.filter(item => item.id !== id)]);
    props.setDirty(true);
  };

  const onRemoveFloorExternal = id => {
    setItemsExternal([...itemsExternal.filter(item => item.id !== id)]);
    props.setDirty(true);
  };

  const onRemoveFloorBelow = id => {
    setItemsBelow([...itemsBelow.filter(item => item.id !== id)]);
    props.setDirty(true);
  };

  const onRemoveRoomInternal = id => {
    setItemsInternal([
      ...itemsInternal.map(item => {
        return {
          ...item,
          subItems: item.subItems.filter(subItem => subItem.id !== id),
        };
      }),
    ]);
    props.setDirty(true);
  };

  const onRemoveRoomExternal = id => {
    setItemsExternal([
      ...itemsExternal.map(item => {
        return {
          ...item,
          subItems: item.subItems.filter(subItem => subItem.id !== id),
        };
      }),
    ]);
    props.setDirty(true);
  };

  const onRemoveRoomBelow = id => {
    setItemsBelow([
      ...itemsBelow.map(item => {
        return {
          ...item,
          subItems: item.subItems.filter(subItem => subItem.id !== id),
        };
      }),
    ]);
    props.setDirty(true);
  };

  const onDragEndInternal = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (result.type === "droppableItem") {
      // if source droppableid is droppable
      if (
        result.source.droppableId === result.destination.droppableId &&
        result.source.droppableId === "floorsonly"
      ) {
        const newFloors = Reorder(floorsInternalOnly, sourceIndex, destIndex);
        setFloorsInternalOnly(newFloors);
      } else if (result.source.droppableId === result.destination.droppableId) {
        const itemsNew = Reorder(itemsInternal, sourceIndex, destIndex);
        setItemsInternal(itemsNew);
        props.setDirty(true);
      } else {
        const newItem = {
          ...floorsInternalOnly[sourceIndex],
          id: cuid(),
          subItems: [],
        };
        let newItems = [...itemsInternal];
        newItems.splice(destIndex, 0, newItem);
        setItemsInternal(newItems);
        props.setDirty(true);
      }
    } else if (result.type === "droppableSubItem") {
      if (
        result.source.droppableId === result.destination.droppableId &&
        result.source.droppableId === "roomsonly"
      ) {
        const newRooms = Reorder(roomsInternalOnly, sourceIndex, destIndex);
        setRoomsInternalOnly(newRooms);
      } else {
        const itemSubItemMap = itemsInternal.reduce((acc, item) => {
          acc[item.id] = item.subItems;
          return acc;
        }, {});

        const sourceParentId = result.source.droppableId;
        const destParentId = result.destination.droppableId;

        const destSubItems = itemSubItemMap[destParentId];
        let newItems = [...itemsInternal];

        if (result.source.droppableId === "roomsonly") {
          const newRoom = {
            ...roomsInternalOnly[sourceIndex],
            id: cuid(),
          };
          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, newRoom);
          newItems = newItems.map(item => {
            if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setItemsInternal(newItems);
          props.setDirty(true);
          return;
        }

        const sourceSubItems = itemSubItemMap[sourceParentId];

        /** In this case subItems are reOrdered inside same Parent */
        if (sourceParentId === destParentId) {
          const reorderedSubItems = Reorder(
            sourceSubItems,
            sourceIndex,
            destIndex
          );
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = reorderedSubItems;
            }
            return item;
          });
          setItemsInternal(newItems);
          props.setDirty(true);
        } else {
          let newSourceSubItems = [...sourceSubItems];
          const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, draggedItem);
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = newSourceSubItems;
            } else if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setItemsInternal(newItems);
          props.setDirty(true);
        }
      }
    }
  };

  const onDragEndExternal = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (result.type === "droppableItem") {
      // if source droppableid is droppable
      if (
        result.source.droppableId === result.destination.droppableId &&
        result.source.droppableId === "floorsonly"
      ) {
        const newFloors = Reorder(floorsExternalOnly, sourceIndex, destIndex);
        setFloorsExternalOnly(newFloors);
      } else if (result.source.droppableId === result.destination.droppableId) {
        const itemsNew = Reorder(itemsExternal, sourceIndex, destIndex);
        setItemsExternal(itemsNew);
        props.setDirty(true);
      } else {
        const newItem = {
          ...floorsExternalOnly[sourceIndex],
          id: cuid(),
          subItems: [],
        };
        let newItems = [...itemsExternal];
        newItems.splice(destIndex, 0, newItem);
        setItemsExternal(newItems);
        props.setDirty(true);
      }
    } else if (result.type === "droppableSubItem") {
      if (
        result.source.droppableId === result.destination.droppableId &&
        result.source.droppableId === "roomsonly"
      ) {
        const newRooms = Reorder(roomsExternalOnly, sourceIndex, destIndex);
        setRoomsExternalOnly(newRooms);
      } else {
        const itemSubItemMap = itemsExternal.reduce((acc, item) => {
          acc[item.id] = item.subItems;
          return acc;
        }, {});

        const sourceParentId = result.source.droppableId;
        const destParentId = result.destination.droppableId;

        const destSubItems = itemSubItemMap[destParentId];
        let newItems = [...itemsExternal];

        if (result.source.droppableId === "roomsonly") {
          const newRoom = {
            ...roomsExternalOnly[sourceIndex],
            id: cuid(),
          };
          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, newRoom);
          newItems = newItems.map(item => {
            if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setItemsExternal(newItems);
          props.setDirty(true);
          return;
        }

        const sourceSubItems = itemSubItemMap[sourceParentId];

        /** In this case subItems are reOrdered inside same Parent */
        if (sourceParentId === destParentId) {
          const reorderedSubItems = Reorder(
            sourceSubItems,
            sourceIndex,
            destIndex
          );
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = reorderedSubItems;
            }
            return item;
          });
          setItemsExternal(newItems);
          props.setDirty(true);
        } else {
          let newSourceSubItems = [...sourceSubItems];
          const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, draggedItem);
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = newSourceSubItems;
            } else if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setItemsExternal(newItems);
          props.setDirty(true);
        }
      }
    }
  };

  const onDragEndBelow = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (result.type === "droppableItem") {
      // if source droppableid is droppable
      if (
        result.source.droppableId === result.destination.droppableId &&
        result.source.droppableId === "floorsonly"
      ) {
        const newFloors = Reorder(floorsBelowOnly, sourceIndex, destIndex);
        setFloorsBelowOnly(newFloors);
      } else if (result.source.droppableId === result.destination.droppableId) {
        const itemsNew = Reorder(itemsBelow, sourceIndex, destIndex);
        setItemsBelow(itemsNew);
        props.setDirty(true);
      } else {
        const newItem = {
          ...floorsBelowOnly[sourceIndex],
          id: cuid(),
          subItems: [],
        };
        let newItems = [...itemsBelow];
        newItems.splice(destIndex, 0, newItem);
        setItemsBelow(newItems);
        props.setDirty(true);
      }
    } else if (result.type === "droppableSubItem") {
      if (
        result.source.droppableId === result.destination.droppableId &&
        result.source.droppableId === "roomsonly"
      ) {
        const newRooms = Reorder(roomsBelowOnly, sourceIndex, destIndex);
        setRoomsBelowOnly(newRooms);
      } else {
        const itemSubItemMap = itemsBelow.reduce((acc, item) => {
          acc[item.id] = item.subItems;
          return acc;
        }, {});

        const sourceParentId = result.source.droppableId;
        const destParentId = result.destination.droppableId;

        const destSubItems = itemSubItemMap[destParentId];
        let newItems = [...itemsBelow];

        if (result.source.droppableId === "roomsonly") {
          const newRoom = {
            ...roomsBelowOnly[sourceIndex],
            id: cuid(),
          };
          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, newRoom);
          newItems = newItems.map(item => {
            if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setItemsBelow(newItems);
          props.setDirty(true);
          return;
        }

        const sourceSubItems = itemSubItemMap[sourceParentId];

        /** In this case subItems are reOrdered inside same Parent */
        if (sourceParentId === destParentId) {
          const reorderedSubItems = Reorder(
            sourceSubItems,
            sourceIndex,
            destIndex
          );
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = reorderedSubItems;
            }
            return item;
          });
          setItemsBelow(newItems);
          props.setDirty(true);
        } else {
          let newSourceSubItems = [...sourceSubItems];
          const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, draggedItem);
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = newSourceSubItems;
            } else if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setItemsBelow(newItems);
          props.setDirty(true);
        }
      }
    }
  };

  const onChagneTextFloorInternal = (text, id) => {
    const newItems = itemsInternal.map(item => {
      if (item.id === id) {
        const newItem = { ...item, content: text };
        return newItem;
      }
      return item;
    });
    setItemsInternal(newItems);
    props.setDirty(true);
  };

  const onChagneTextFloorExternal = (text, id) => {
    const newItems = itemsExternal.map(item => {
      if (item.id === id) {
        const newItem = { ...item, content: text };
        return newItem;
      }
      return item;
    });
    setItemsExternal(newItems);
    props.setDirty(true);
  };

  const onChagneTextFloorBelow = (text, id) => {
    const newItems = itemsBelow.map(item => {
      if (item.id === id) {
        const newItem = { ...item, content: text };
        return newItem;
      }
      return item;
    });
    setItemsBelow(newItems);
    props.setDirty(true);
  };

  const onChagneTextRoomInternal = (text, id, parent) => {
    const newItems = itemsInternal.map(item => {
      if (item.id === parent) {
        const newItem = {
          ...item,
          subItems: item.subItems.map(sub => {
            if (sub.id === id) {
              const newSub = { ...sub, content: text };
              return newSub;
            }
            return sub;
          }),
        };
        return newItem;
      }
      return item;
    });
    setItemsInternal(newItems);
    props.setDirty(true);
  };

  const onChagneTextRoomExternal = (text, id, parent) => {
    const newItems = itemsExternal.map(item => {
      if (item.id === parent) {
        const newItem = {
          ...item,
          subItems: item.subItems.map(sub => {
            if (sub.id === id) {
              const newSub = { ...sub, content: text };
              return newSub;
            }
            return sub;
          }),
        };
        return newItem;
      }
      return item;
    });
    setItemsExternal(newItems);
    props.setDirty(true);
  };

  const onChagneTextRoomBelow = (text, id, parent) => {
    const newItems = itemsBelow.map(item => {
      if (item.id === parent) {
        const newItem = {
          ...item,
          subItems: item.subItems.map(sub => {
            if (sub.id === id) {
              const newSub = { ...sub, content: text };
              return newSub;
            }
            return sub;
          }),
        };
        return newItem;
      }
      return item;
    });
    setItemsBelow(newItems);
    props.setDirty(true);
  };

  const clearAllFloors = () => {
    switch (activeTab) {
      case INTERNAL_TAB:
        setItemsInternal([]);
        break;
      case EXTERNAL_TAB:
        setItemsExternal([]);
        break;
      case BELOW_GROUND_TAB:
        setItemsBelow([]);
        break;
    }
     props.setDirty(true);
  };

  const onAddFloorLevel = (items, setItems) => {
    const newFloorLevel = {
      content: "New Floor Level",
      id: cuid(),
      type: FLOOR,
      subItems: [],
    };
    setItems([...items, newFloorLevel]);
    toast.success("Floor Level added successfully", {
      autoClose: 2000,
    });
    props.setDirty(true);
  }

  const onAddArea = (items, floorId, setItems) => {
    const newArea = {
      content: "New Area",
      id: cuid(),
      type: "area",
    };
    const newItems = items.map(item => {
      if (item.id === floorId) {
        const newSubItems = [...item.subItems, newArea];
        item.subItems = newSubItems;
      }
      return item;
    });
    setItems(newItems);
    props.setDirty(true);
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  const { isDirty } = props;
  return (
    <>
      <RouteLeavingGuard
        when={isDirty}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return isDirty;
        }}
        setWizardExited={setIsWizardExited}
        saveJob={onSave}
      />
      <JobLeavingGuard
        when={!isDirty && !isWizardExited}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return !isDirty && !isWizardExited;
        }}
      />
      <Card>
        <CardBody style={{ margin: "20px" }}>
          <Modal isOpen={showHelp} style={{ maxWidth: "fit-content" }}>
            <div className="modal-header">
              <button
                type="button"
                onClick={() => setShowHelp(false)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <YouTube videoId="0SnRI9PlFBY" opts={opts} />
          </Modal>
          {/* <Modal isOpen={showAddRoomModal} size="sm">
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Add Rooms
              </h5>
              <button
                type="button"
                onClick={() => setShowAddRoomModal(false)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {internalRoomsData.map(item => {
                return (
                  <p>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={item.id}
                    />{" "}
                    {item.content}
                  </p>
                );
              })}
              <div>
                <input type="checkbox" className="form-check-input" />{" "}
                <input></input>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setShowAddRoomModal(false);
                }}
                className="btn btn-primary float-left"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal> */}
          <Button
            className="help-button"
            color="link"
            onClick={() => setShowHelp(true)}
          >
            {"Help"}
          </Button>
          <Nav tabs>
            {hasInternal && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === INTERNAL_TAB,
                  })}
                  onClick={() => {
                    toggle(INTERNAL_TAB);
                  }}
                >
                  {!isValidInternal && <span className="yellow-dot" />}
                 {RESIDENTIAL_CATEGORY} 
                </NavLink>
              </NavItem>
            )}
            {hasExternal && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === EXTERNAL_TAB,
                  })}
                  onClick={() => {
                    toggle(EXTERNAL_TAB);
                  }}
                >
                  {!isValidExternal && <span className="yellow-dot" />}
                 {COMMERCIAL_CATEGORY} 
                </NavLink>
              </NavItem>
            )}
            {hasBelow && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === BELOW_GROUND_TAB,
                  })}
                  onClick={() => {
                    toggle(BELOW_GROUND_TAB);
                  }}
                >
                  {!isValidBelowGround && <span className="yellow-dot" />}
                {INFRASTRUCTURE_CATEGORY} 
                </NavLink>
              </NavItem>
            )}
          </Nav>

          <TabContent activeTab={activeTab} className="p-3 text-muted" style={{width: "85%"}}>
            <TabPane tabId={INTERNAL_TAB}>
              <Row>
                <DragDropContext onDragEnd={onDragEndInternal}>
                  <Label className="builder-label">{"Floor and Area(s)"}</Label>
                  <div className="justify-content-between d-flex">
                    <Button
                      disabled={!itemsInternal.length > 0}
                      color="link"
                      className="room-type-btn"
                      onClick={() => setClearAllConfirmationPrompt(true)}
                    >
                      <i className="mdi"></i>
                      {"Clear All"}
                    </Button>

                    <Label
                      className="builder-actions__label mt-1 mb-1"
                      onClick={() =>
                        onAddFloorLevel(itemsInternal, setItemsInternal)
                      }
                    >
                      <Add fontSize="small" />
                      <span className="text-with-icon">Add Floor Level</span>
                    </Label>
                  </div>

                  <Droppable droppableId="droppable" type="droppableItem">
                    {(provided, snapshot) => (
                      <div
                        className="dropzone dropzone-floor mb-2 container-height"
                        ref={provided.innerRef}
                        style={getListContainerStyle(snapshot.isDraggingOver)}
                      >
                        {itemsInternal.length <= 0 && (
                          <div className="dz-message dz-floor-message mt-2 justify-content-center">
                            <h4>
                              Add Floors and Areas to configure your work area(s).
                            </h4>
                          </div>
                        )}
                        <div className="scrollable-floors-areas">
                          {activeTab === INTERNAL_TAB &&
                            itemsInternal.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={`${item.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div>
                                    <div
                                      className="overflow-hidden"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <FloorComponent
                                        item={item}
                                        onChagneTextFloor={
                                          onChagneTextFloorInternal
                                        }
                                        provided={provided}
                                        onRemoveFloor={onRemoveFloorInternal}
                                        items={itemsInternal}
                                        index={index}
                                        onClone={setItemsInternal}
                                      />

                                      <RoomUnit
                                        subItems={item.subItems}
                                        type={item.id}
                                        onDelete={onRemoveRoomInternal}
                                        onChange={onChagneTextRoomInternal}
                                        allowed={
                                          switchParent
                                            ? "droppableSubItem"
                                            : item.id
                                        }
                                      />
                                      <div>
                                        <Label
                                          className="builder-actions__label float-sm-end mb-0"
                                          onClick={() =>
                                            onAddArea(
                                              itemsInternal,
                                              item.id,
                                              setItemsInternal
                                            )
                                          }
                                        >
                                          <Add fontSize="small" />
                                          <span className="text-with-icon">
                                            Add Area
                                          </span>
                                        </Label>
                                      </div>
                                    </div>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Row>
            </TabPane>
            <TabPane tabId={EXTERNAL_TAB}>
              <Row>
                <DragDropContext onDragEnd={onDragEndExternal}>
                  <Label className="builder-label">{"Floor and Area(s)"}</Label>
                  <div className="justify-content-between d-flex">
                    <Button
                      disabled={!itemsExternal.length > 0}
                      color="link"
                      className="room-type-btn"
                      onClick={() => setClearAllConfirmationPrompt(true)}
                    >
                      <i className="mdi"></i>
                      {"Clear All"}
                    </Button>
                    <Label
                      className="builder-actions__label mt-1 mb-1"
                      onClick={() =>
                        onAddFloorLevel(itemsExternal, setItemsExternal)
                      }
                    >
                      <Add fontSize="small" />
                      <span className="text-with-icon">Add Floor Level</span>
                    </Label>
                  </div>
                  <Droppable droppableId="droppable" type="droppableItem">
                    {(provided, snapshot) => (
                      <div
                        className="dropzone dropzone-floor mb-2 container-height"
                        ref={provided.innerRef}
                        style={getListContainerStyle(snapshot.isDraggingOver)}
                      >
                        {itemsExternal.length <= 0 && (
                          <div className="dz-message dz-floor-message mt-2 justify-content-center">
                            <h4>
                              Add Floors and Areas to configure your work area(s).
                            </h4>
                          </div>
                        )}
                        <div className="scrollable-floors-areas">
                          {activeTab === EXTERNAL_TAB &&
                            itemsExternal.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={`${item.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div>
                                    <div
                                      className="overflow-hidden"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <FloorComponent
                                        item={item}
                                        onChagneTextFloor={
                                          onChagneTextFloorExternal
                                        }
                                        provided={provided}
                                        onRemoveFloor={onRemoveFloorExternal}
                                        items={itemsExternal}
                                        index={index}
                                        onClone={setItemsExternal}
                                      />

                                      <RoomUnit
                                        subItems={item.subItems}
                                        type={item.id}
                                        onDelete={onRemoveRoomExternal}
                                        onChange={onChagneTextRoomExternal}
                                        allowed={
                                          switchParent
                                            ? "droppableSubItem"
                                            : item.id
                                        }
                                      />
                                      <div>
                                        <Label
                                          className="builder-actions__label float-sm-end mb-0"
                                          onClick={() =>
                                            onAddArea(
                                              itemsExternal,
                                              item.id,
                                              setItemsExternal
                                            )
                                          }
                                        >
                                          <Add fontSize="small" />
                                          <span className="text-with-icon">
                                            Add Area
                                          </span>
                                        </Label>
                                      </div>
                                    </div>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Row>
            </TabPane>
            <TabPane tabId={BELOW_GROUND_TAB}>
              <Row>
                <DragDropContext onDragEnd={onDragEndBelow}>
                  {/* <Col lg="6" md="6" sm="12" xs="12"> */}
                  <Label className="builder-label">{"Floor and Area(s)"}</Label>
                  <div className="justify-content-between d-flex">
                    <Button
                      disabled={!itemsBelow.length > 0}
                      color="link"
                      className="room-type-btn"
                      onClick={() => setClearAllConfirmationPrompt(true)}
                    >
                      <i className="mdi"></i>
                      {"Clear All"}
                    </Button>
                    <Label
                      className="builder-actions__label mt-1 mb-1"
                      onClick={() => onAddFloorLevel(itemsBelow, setItemsBelow)}
                    >
                      <Add fontSize="small" />
                      <span className="text-with-icon">Add Floor Level</span>
                    </Label>
                  </div>
                  <Droppable droppableId="droppable" type="droppableItem">
                    {(provided, snapshot) => (
                      <div
                        className="dropzone dropzone-floor mb-2 container-height"
                        ref={provided.innerRef}
                        style={getListContainerStyle(snapshot.isDraggingOver)}
                      >
                        {itemsBelow.length <= 0 && (
                          <div className="dz-message dz-floor-message mt-2 justify-content-center">
                            <h4>
                              Add Floors and Areas to configure your work area(s).
                            </h4>
                          </div>
                        )}
                        <div className="scrollable-floors-areas">
                          {activeTab === BELOW_GROUND_TAB &&
                            itemsBelow.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={`${item.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div>
                                    <div
                                      className="overflow-hidden"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <FloorComponent
                                        item={item}
                                        onChagneTextFloor={
                                          onChagneTextFloorBelow
                                        }
                                        provided={provided}
                                        onRemoveFloor={onRemoveFloorBelow}
                                        items={itemsBelow}
                                        index={index}
                                        onClone={setItemsBelow}
                                      />

                                      <RoomUnit
                                        subItems={item.subItems}
                                        setDirty={props.setDirty}
                                        type={item.id}
                                        onDelete={onRemoveRoomBelow}
                                        onChange={onChagneTextRoomBelow}
                                        allowed={
                                          switchParent
                                            ? "droppableSubItem"
                                            : item.id
                                        }
                                      />

                                      <div>
                                        <Label
                                          className="builder-actions__label float-sm-end mb-0"
                                          onClick={() =>
                                            onAddArea(
                                              itemsBelow,
                                              item.id,
                                              setItemsBelow
                                            )
                                          }
                                        >
                                          <Add fontSize="small" />
                                          <span className="text-with-icon">
                                            Add Area
                                          </span>
                                        </Label>
                                      </div>
                                    </div>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Row>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>

      <Row className="clearfix" style={{ marginBottom: "20px" }}>
        <div className="text-end">
          <button
            className="btn btn-primary w-md mtb-2"
            onClick={() => onPrev()}
            type="button"
          >
            Previous
          </button>
          <button
            disabled={!isNext}
            className="btn btn-primary w-md mtb-2 ml-20"
            onClick={() => onSave()}
            type="button"
          >
            Save
          </button>
          <button
            disabled={!isNext}
            className="btn btn-primary w-md ml-20 mtb-2"
            type="button"
            onClick={() => onNext()}
          >
            Next
          </button>
        </div>
      </Row>

      {clearAllConfirmationPrompt && (
        <ClearAllConfirmationPrompt
          onCancel={() => setClearAllConfirmationPrompt(false)}
          onConfirm={() => {
            clearAllFloors();
            setClearAllConfirmationPrompt(false);
          }}
          title={`Are you sure you want to clear all the Floors & Areas?`}
          confirmBtnText={"Clear All"}
        />
      )}
    </>
  );
}
function FloorComponent({ item, onChagneTextFloor, provided, onRemoveFloor, items,index, onClone }) {
  const [floorName, setFloorName] = useState(item.content);
  const [newFloorName,setNewFloorName] = useState(floorName);
  const [showCloneDialog, setShowCloneDialog] = useState(false);
  const [startingNumber, setStartingNumber] = useState("");
  const [cloneCount, setCloneCount] = useState("");
  const [cloneCountError, setCloneCountError] = useState(false);
  const [startingNumberError, setStartingNumberError] = useState(false);
  const [floorNameError, setFloorNameError] = useState(false);
  const [startCloning, setStartCloning] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (startCloning) {
      setTimeout(() => { addFloors()}, 2000)
    }
  }, [startCloning]);

  const addFloors = () => {
    if (cloneCount > 0) {
     
      const clonedFloors = [];
      for (let i = 1; i <= cloneCount; i++) {
        const clonedSubItems = item.subItems.map(s => {
          return {
            ...s,
            id: cuid()
          };
        });
        const content = `${newFloorName} ${parseInt(startingNumber) + i - 1}`;
        clonedFloors.push({
            ...item,
            id: cuid(),
            subItems: clonedSubItems,
            content: content,
        });
      }
      onClone([...items.slice(0, index+1), ...clonedFloors, ...items.slice(index+1)]);
      handleCloneDialogClose();
      setNewFloorName(floorName)
    }
  }

  const handleStartingNumberChange = event => {
    const input = event.target.value;
    handleValueChange(input, setStartingNumber, setStartingNumberError);
  };

  const handleCloneCountChange = event => {
    const input = event.target.value;
    handleValueChange(input, setCloneCount, setCloneCountError);
  };
  const handleFloorNameChange = event => {
    const input = event.target.value;
    handleFloorNameValueChange(input, setNewFloorName, setFloorNameError);
  };

  const handleFloorNameValueChange =(value,setValue,setError) =>{
      if(value?.length>0){
        setValue(value)
        setError(false)
      }
      else{
        setValue("")
        setError(true);
      }

  }

  const handleValueChange = (value, setValue, setError) => {
    if (value === "" || /^\d+$/.test(value)) {
      setValue(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleCloneDialogClose = () => {
    setShowCloneDialog(false);
    setStartingNumber("");
    setCloneCount("");
    setLoading(false);
    setStartCloning(false);
    setStartingNumberError(false);
    setFloorNameError(false);
    setCloneCountError(false);
  }

  return (
    <React.Fragment>
      <FloorUnit>
        <div className="display-unit">
          <span>
            <img
              onClick={() => {
                onRemoveFloor(item.id);
              }}
              src={crossMark}
              style={{
                height: "14px",
                padding: "2px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
          </span>
          <span>
            <img
              src={getIcon(item.type)}
              style={{
                height: "25px",
                marginRight: "5px",
                marginTop: "5px",
                transform: "translate(0, -5px)",
              }}
            />
          </span>

          <TextField
            multiline={true}
            variant="standard"
            id="standard-textarea"
            className="floor-container-name"
            placeholder="Add floor name"
            error={Boolean(floorName)}
            helperText={floorName ? "" : "*Required"}
            InputProps={{ disableUnderline: true }}
            value={floorName}
            onChange={e => {
              setFloorName(e.target.value);
            }}
            onBlur={() => {
              if (floorName) {
                onChagneTextFloor(floorName.trim(), item.id);
              } else {
                setFloorName(item.content);
              }
            }}
          />
          {IS_FLOOR_CLONING_ENABLED && (
            <button
              disabled={!item.subItems.length > 0}
              className="btn btn-primary w-md mr-8 ml-8"
              onClick={() => {
                setShowCloneDialog(true)
                const clonedFloorName=floorName.replace(/\d+$/g, '');
                setNewFloorName(clonedFloorName)
              }}
              type="button"
            >
              Clone
            </button>
          )}
          <span {...provided.dragHandleProps}>
            <img
              src={dragReorder}
              style={{
                height: "26px",
                width: "10px",
                marginTop: "2px",
                transform: "translate(0, -5px)",
              }}
            />
          </span>
        </div>
      </FloorUnit>

      {showCloneDialog && (
        <div>
          <Dialog open={showCloneDialog} onClose={handleCloneDialogClose}>
            <DialogTitle>Clone Floor Level</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To clone the floor level, please enter the number of floors you
                want to clone and the starting number of the floor/apartment.
              </DialogContentText>
              <TextField
                autoFocus
                label="How many Floors/Apartments do you want to add?"
                value={cloneCount}
                onChange={handleCloneCountChange}
                error={Boolean(cloneCountError)}
                margin="normal"
                helperText={cloneCountError ? "Enter a valid number" : ""}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                  endAdornment: (
                    <span
                      style={{ visibility: cloneCount ? "visible" : "hidden" }}
                    >
                      <IconButton
                        onClick={() => setCloneCount("")}
                        onMouseDown={event => event.preventDefault()}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </span>
                  ),
                }}
                fullWidth
              />

              <TextField
                label="Enter the floor name"
                value={newFloorName}
                onChange={handleFloorNameChange}
                error={Boolean(floorNameError)}
                margin="normal"
                helperText={floorNameError ? "Enter the floor Name" : ""}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                  endAdornment: (
                    <span
                      style={{
                        visibility: newFloorName ? "visible" : "hidden",
                      }}
                    >
                      <IconButton
                        onClick={() => setNewFloorName("")}
                        onMouseDown={event => event.preventDefault()}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </span>
                  ),
                }}
                fullWidth
              />
              <TextField
                label="Enter the starting Floor/Apartment number"
                value={startingNumber}
                onChange={handleStartingNumberChange}
                error={Boolean(startingNumberError)}
                margin="normal"
                helperText={startingNumberError ? "Enter a valid number" : ""}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                  endAdornment: (
                    <span
                      style={{
                        visibility: startingNumber ? "visible" : "hidden",
                      }}
                    >
                      <IconButton
                        onClick={() => setStartingNumber("")}
                        onMouseDown={event => event.preventDefault()}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </span>
                  ),
                }}
                fullWidth
              />
            </DialogContent>
            {loading && (
              <Alert color="primary" className="mb-2">
                <h5 className="alert-heading mb-2">
                  Adding Floors/Apartments is in progress, Please wait..
                </h5>
              </Alert>
            )}
            <DialogActions className="mb-3 px-4">
              <button
                type="button"
                disabled={loading}
                onClick={() => setShowCloneDialog(false)}
                className="btn btn-light w-md float-left"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                color="primary"
                onClick={() => {
                  setStartCloning(true);
                  setLoading(true);
                }}
                className="btn btn-primary w-md float-left"
                data-dismiss="modal"
                disabled={
                  loading ||
                  cloneCountError ||
                  startingNumberError ||
                  floorNameError || 
                  !(cloneCount > 0 && startingNumber > 0 && newFloorName?.length>0)
                }
              >
                Clone
              </button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </React.Fragment>
  );
}
