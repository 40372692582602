import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { cloneJob } from "pages/jobs/utils";
import { ACTIVE_JOBS_DB_NAME, INSPECTION_DB_NAME } from "common/constants";

import {
  moveJobToArchived,
  moveJobToDeleted,
  updateInspectionJobStatus,
} from "services";
import { removeChatNotificationsOfTheJob } from "services/jobService";
import { fetchSingleDraftSuccess, setIsCloning } from "store/actions";

const ActiveMenu = ({ setSearchTerm, job, setJobUpdate,collectionName }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pathName = collectionName === INSPECTION_DB_NAME? "newinspectionjob" : "newjob";
  const handleClone = async () => {
    dispatch(setIsCloning(true));
    const clonedJob = await cloneJob(job, collectionName);
    dispatch(setIsCloning(false));
    dispatch(fetchSingleDraftSuccess(clonedJob));
    history.push(`/${pathName}/${clonedJob.id}?type=cloned`);
    toast.success("Successfully cloned the job", {
      autoClose: 3000,
    });
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle href="#" className="card-drop" tag="i">
        <i className="mdi mdi-dots-horizontal font-size-18" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-start">
        <DropdownItem
          href="#"
          onClick={() => {
            {
              job?.status
                ? updateInspectionJobStatus(job?.id, "archived")
                : moveJobToArchived(job);
            }
            setJobUpdate("archive");
            setSearchTerm("", { shouldClearFilters: false });
            removeChatNotificationsOfTheJob(job.id);
          }}
        >
          <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
          Archive
        </DropdownItem>
        <DropdownItem href="#" onClick={handleClone}>
          <i className="mdi mdi-content-copy font-size-16 text-success me-1" />{" "}
          Clone
        </DropdownItem>
        <DropdownItem
          href="#"
          onClick={() => {
            {
              job?.status
                ? updateInspectionJobStatus(job?.id, "deleted")
                : moveJobToDeleted(job);
            }
            setJobUpdate("delete");
            setSearchTerm("", { shouldClearFilters: false });
            removeChatNotificationsOfTheJob(job.id);
          }}
        >
          <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
          Delete
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ActiveMenu;