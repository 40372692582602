// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-modal-ui-1{
    margin-top: 18px;
    margin-bottom: 20px;
}
.notification-modal-ui{
    margin-top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/TopbarDropdown/NotificationStyles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB","sourcesContent":[".notification-modal-ui-1{\n    margin-top: 18px;\n    margin-bottom: 20px;\n}\n.notification-modal-ui{\n    margin-top: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
