export const tableStyle = {
  display: "table",
  width: "auto",
  border: "none",
};

export const tableRowStyle = {
  flexDirection: "row",
};

export const firstTableColHeaderStyle = {
  width: "20%",
  border: "none",
};
export const topBorderStyle = {
  flexDirection: "row",
  border: "none",
};
export const tableColHeaderStyle = {
  width: "20%",
  border: "none",
};

export const firstTableColStyle = {
  width: "20%",
  border: "none",
};

export const tableColStyle = {
  width: "20%",
  border: "none",
};

export const tableCellHeaderStyle = {
  textAlign: "left",
  marginTop: 4,
  marginBottom: 4,
  fontSize: 14,
  fontWeight: "bold",
  border: "none",
  color: "#072535",
};

export const tableCellStyle = {
  textAlign: "left",
  marginTop: 5,
  marginBottom: 5,
  fontSize: 14,
  border: "none",
};

export const checklistTableCellHeaderStyle = {
  textAlign: "left",
  marginTop: 4,
  marginBottom: 4,
  fontSize: 14,
  fontWeight: "bold",
  border: "none",
  color: "#072535",
};

export const checklistTableCellStyle = {
  textAlign: "left",
  marginTop: 5,
  marginBottom: 5,
  fontSize: 16,
  border: "none",
};
