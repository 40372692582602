import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSubscriptionsFromDb } from "services/subscriptionsService";
import {
  fetchAllSubscriptions,
  fetchAllSubscriptionsError,
  fetchAllSubscriptionsSuccess,
} from "store/subscription/actions";
const { isValideSubscription } = require("common/utils");
export const organisationStatus = currentUserProfile => {
  const { subscriptions } = useSelector(state => state.subscriptions);
  const dispatch = useDispatch();
   useFirestoreCollection({
     query: () => fetchSubscriptionsFromDb(),
     onStart: () => dispatch(fetchAllSubscriptions()),
     onSuccess: docs => dispatch(fetchAllSubscriptionsSuccess(docs)),
     onError: error => dispatch(fetchAllSubscriptionsError(error)),
     deps: [dispatch],
   });
  const userSubscription = subscriptions?.find(
    subscription => subscription?.orgId === currentUserProfile?.orgAccessId
  );
  if (!userSubscription) return false;
  const isOrganisationActive = isValideSubscription(userSubscription);
  return isOrganisationActive;
};
export default organisationStatus;
