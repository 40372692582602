import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, UncontrolledTooltip } from "reactstrap";
import CircleIcon from "@mui/icons-material/Circle";
import {
  INTERNAL,
  EXTERNAL,
  BELOW_GROUND,
  AVATAR_URL,
} from "../../common/constants";
import Category from "../jobs/jobTypes/Category";
import Progress from "pages/jobstracker/CircularProgressBar";
import { totalPercentage } from "pages/jobs/jobTypes/active/percentageCalculation";

export const JobsListView = ({
  result,
  notification,
  job,
  resultSearchTerm,
}) => {
  const {
    internal: isInternal,
    external: isExternal,
    belowground: isBelowground,
    report: isReport,
    other: isOther,
  } = JSON.parse(result.jobtype.raw);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    const calculateTotalPercentage = async () => {
      const result = await totalPercentage(job);
      setPercentage(result);
    };
    calculateTotalPercentage();
  }, [job]);
  return (
    <React.Fragment>
      <tbody>
        <tr>
          <td>
            {notification !== undefined ? (
              <CircleIcon
                className="mr-4 text-wrap font-size-14"
                style={{ fill: "#50A5F1", display: "inline-block" }}
              />
            ) : null}
            <h5
              className="text-wrap font-size-14"
              style={{
                textIndent: notification !== undefined ? "15px" : "15px",
                display: "inline-block",
              }}
            >
              <Link
                to={{
                  pathname: `/active/${result.id.raw}`,
                  state: {
                    pageNo: location?.search,
                    query: resultSearchTerm,
                    type: "dashboard",
                  },
                }}
                className="text-dark"
              >
                {`${result?.orgidentitycode?.raw || ""} ${result.jobno.raw}`}
              </Link>
            </h5>
          </td>
          <td>
            <h5
              className="text-wrap font-size-14"
              style={{
                textIndent: notification !== undefined ? "15px" : "0px",
                display: "inline-block",
                maxWidth: "8rem",
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              <Link
                to={{
                  pathname: `/active/${result.id.raw}`,
                  state: {
                    pageNo: location?.search,
                    query: resultSearchTerm,
                    type: "dashboard",
                  },
                }}
                className="text-dark"
              >
                {job?.customjobno}
              </Link>
            </h5>
          </td>
          <td>
            <h5
              className="text-wrap font-size-14"
              style={{
                maxWidth: "8rem",
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              <Link
                to={{
                  pathname: `/active/${result.id.raw}`,
                  state: {
                    pageNo: location?.search,
                    query: resultSearchTerm,
                    type: "dashboard",
                  },
                }}
                className="text-dark"
              >
                {result.jobname?.raw}
              </Link>
            </h5>
          </td>
          <td>
            <h5 className="text-wrap font-size-14">
              <Link
                to={{
                  pathname: `/active/${result.id.raw}`,
                  state: {
                    pageNo: location?.search,
                    query: resultSearchTerm,
                    type: "dashboard",
                  },
                }}
                className="text-dark"
              >
                {result.client__displayname.raw}
              </Link>
            </h5>
          </td>
          <td>
            <h5 className="text-wrap font-size-14">
              <Link
                to={{
                  pathname: `/active/${result.id.raw}`,
                  state: {
                    pageNo: location?.search,
                    query: resultSearchTerm,
                    type: "dashboard",
                  },
                }}
                className="text-dark"
              >
                {result.jobaddress.raw}
              </Link>
            </h5>
          </td>

          <td>
            <h5 className="text-wrap font-size-14">
              {isInternal && <Category type={INTERNAL} job={job} isList />}
              {isExternal && <Category type={EXTERNAL} job={job} isList />}
              {isBelowground && (
                <Category type={BELOW_GROUND} job={job} isList />
              )}
              {isReport && <Badge className={"bg-secondary"}>{"Report"}</Badge>}
              {isOther && <Badge className={"bg-danger"}>{"Other"}</Badge>}
            </h5>
          </td>
          <td>
            <div className="avatar-group">
              {map(result.participants.raw, (member, index) => {
                const { displayName, id } = JSON.parse(member);
                return (
                  <div className="avatar-group-item" key={JSON.parse(index)}>
                    <div
                      className="team-member d-inline-block"
                      id={"member" + id}
                    >
                      <img
                        src={
                          JSON.parse(member).photoURL ||
                          `${AVATAR_URL}&name=${displayName}`
                        }
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={"member" + id}
                      >
                        {displayName}
                      </UncontrolledTooltip>
                    </div>
                  </div>
                );
              })}
            </div>
          </td>

          <td>
            <Progress percentage={percentage} />
          </td>
        </tr>
      </tbody>
    </React.Fragment>
  );
};
