import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
// action
import {
  emailVerification,
  registerInFirebase,
} from "../../services/authService";

// Redux
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import images
import profileImg from "assets/images/profile-img.png";
import logoImg from "../../assets/images/logo-color.png";
import { Field, Form } from "@availity/form";
import "@availity/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import { getDownloadURL } from "common/utils";
import { TERMS_AND_CONDITIONS_URL_PATH } from "common/constants";
import PDFModal from "../../pages/jobs/jobDetails/rooms/PdfModal";
export default function Register(props) {
  const {
    match: { params },
    history,
  } = props;
  const { registerError, authenticated, loading } = useSelector(
    state => state.auth
  );
  const [openTermsAndConditions, setOpenTermsAndConditions] =useState(false);
  const [termsUrl, setTermsUrl] = useState("");
  const history_info = useHistory();
  // handleValidSubmit
  const handleValidSubmit = values => {
    const email = values?.email?.toLowerCase().trim();
    const password = values?.password?.trim();
    const firstName = values?.firstName?.trim();
    const lastName = values?.lastName?.trim();
    const result = registerInFirebase({
      email,
      firstName,
      lastName,
      password,
      role: "default",
    });
    result.then(result => {
      if (result.user) {
        emailVerification(result);
        history_info.push("/email-verification");
      }
    });
  };
  useEffect(() => {
    if (authenticated) {
      props.history.push("/dashboard");
    }
  }, [authenticated]);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordIcon, setShowPasswordIcon] = useState(true);

  const handleClickShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const closeModal = () => {
    setOpenTermsAndConditions(false);
  }
    const getTermsAndCondiitonsUrl = async () => {
    const url = await getDownloadURL(TERMS_AND_CONDITIONS_URL_PATH);
    setTermsUrl(url);
  }
  useEffect(()=>{
    getTermsAndCondiitonsUrl();
  },[])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Industry Best Digital</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      {(openTermsAndConditions && <PDFModal
          isOpen={openTermsAndConditions}
          closeModal={closeModal}
          pdfUrl={termsUrl}
          pdfName={"Terms and Conditions"}
          pdfJob={() => {}}
          isTermsAndConditions={true}
        />
      )}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                        <p>The best digital tradesman tool of all-time!</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-xl profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-white">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="104"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      model={{ email: params.id || "" }}
                      className="form-horizontal"
                      onSubmit={values => {
                        handleValidSubmit(values);
                      }}
                      initialValues={{
                        email: "",
                        firstName: "",
                        lastName: "",
                        password: "",
                      }}
                      validationSchema={yup.object().shape({
                        email: yup
                          .string()
                          .trim()
                          .isRequired(true, "Please enter a valid email."),
                        firstName: yup
                          .string()
                          .trim()
                          .isRequired(true, "Please enter first name."),
                        lastName: yup
                          .string()
                          .trim()
                          .isRequired(true, "Please enter last name."),
                        password: yup
                          .string()
                          .trim()
                          .isRequired(true, "Please enter a password."),
                      })}
                      validate={values => {
                        if (values?.password === "") {
                          setShowPasswordIcon(false);
                        } else {
                          setShowPasswordIcon(true);
                        }
                      }}
                    >
                      {authenticated && (
                        <Alert color="success">
                          User Registration Successfully.{" "}
                          <Link
                            to="/login"
                            className="font-weight-medium text-primary"
                          >
                            {" "}
                            Login Here
                          </Link>{" "}
                        </Alert>
                      )}
                      {registerError && registerError.message ? (
                        <Alert color="danger">{registerError.message}</Alert>
                      ) : null}
                      <div style={{ display: "flex" }}>
                        <div
                          className="mb-3"
                          style={{ width: "50%", marginRight: "10px" }}
                        >
                          <Field
                            name="firstName"
                            type="text"
                            label="First Name"
                            placeholder="Enter First Name"
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3" style={{ width: "50%" }}>
                          <Field
                            name="lastName"
                            type="text"
                            label="Last Name"
                            placeholder="Enter Last Name"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Field
                          id="email"
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                        />
                      </div>
                      <div className="mb-3">
                        <Field
                          name="password"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password"
                        />
                        {showPasswordIcon && (
                          <InputAdornment
                            position="end"
                            style={{
                              flexDirection: "column",
                              alignItems: "end",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              style={{
                                fontSize: "1.5rem",
                                position: "relative",
                                top: "-2.2rem",
                                right: "1.6rem",
                              }}
                            >
                              {showPassword ? (
                                <i className="mdi mdi-eye"></i>
                              ) : (
                                <i className="mdi mdi-eye-off"></i>
                              )}
                            </span>
                          </InputAdornment>
                        )}
                      </div>
                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                          disabled={loading}
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                            </>
                          )}
                          Register
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Industry Best{" "}
                          <button className="text-primary" style={{
                            backgroundColor:"white",
                            border:"none"
                          }} onClick={()=>
                          {
                            setOpenTermsAndConditions(true)}}>
                            Terms of Use
                          </button>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © 2024 Industry Best. Gratefully helping you.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
