import React from "react";
import { Media } from "reactstrap";
import { Circle } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./CardStyles.scss";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import ParticipantsUiInCard from "./ParicipantsUiInCard";
import { AVATAR_URL } from "common/constants";
export function CardData(result, notification, startDate, endDate) {
  return (
    <Media>
      <div className="avatar-md me-4">
        <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
          <img
            src={`${AVATAR_URL}&name=${result?.client__displayname.raw}`}
            className="rounded-circle avatar-sm"
            alt=""
          />
        </span>
      </div>
      <Media className="overflow-hidden" body>
        <h5 className="text-muted text-truncate font-size-15">
          {`${result?.orgidentitycode?.raw || ""} ${result?.jobno.raw}`}
          {notification !== undefined && (
            <Circle
              className="mr-4 text-wrap font-size-16"
              style={{
                fill: "#50A5F1",
                paddingBottom: "5px",
              }}
            />
          )}
        </h5>
        <h5 className="text-muted text-truncate font-size-15 hover-expand">
          {result?.customjobno?.raw || "No Custom Number"}
        </h5>
        <h5 className="text-muted text-truncate font-size-13 hover-expand">
          {result?.jobname?.raw || "No Job Name"}
        </h5>
        <h5 className="text-muted text-truncate font-size-13 hover-expand ">
          {result.client__displayname.raw}
        </h5>
        <p className="text-muted mb-4 job-address-header text-truncate hover-expand">
          <MapsHomeWorkIcon /> {result.jobaddress.raw || "No Job Address"}
        </p>
        <ParticipantsUiInCard participants={result.participants.raw} />
      </Media>
      <div className="text-muted avatar-lg me-3">
        <span
          style={{ display: "flex", alignItems: "center" }}
          className="font-size-15"
        >
          Start Date: {startDate || ""}
        </span>
        <span
          style={{ display: "flex", alignItems: "center" }}
          className="font-size-15"
        >
          End Date: {endDate || ""}
        </span>
      </div>
    </Media>
  );
}
