import React, {forwardRef} from 'react';
import classNames from 'classnames';

import {Handle} from './Handle';
import {Remove} from "./Remove";

import './container.css';

export const Container = forwardRef(
  (
    {
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      ...props
    },
    ref
  ) => {
    const Component = onClick ? 'button' : 'div';

    return (
      <Component
        {...props}
        ref={ref}
        style={{
          ...style,
          "--columns": columns,
        }}
        className={classNames(
          "Container",
          unstyled && "Unstyled",
          horizontal && "Horizontal",
          hover && "Hover",
          placeholder && "Placeholder",
          scrollable && "Scrollable",
          shadow && "Shadow"
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        {label ? (
          <div className={"Header"}>
            {label}
            <div className={"Actions"}>
              {onRemove ? <Remove onClick={onRemove} /> : undefined}
              <Handle {...handleProps} />
            </div>
          </div>
        ) : null}
        {placeholder ? children : <ul>{children}</ul>}
      </Component>
    );
  }
);
