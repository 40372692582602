import { TextareaAutosize } from "@mui/material";
import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Input, Form, FormGroup, Col, Label, Alert } from "reactstrap";
import { addNotesToDb, updateNotesInDb } from "services/notesService";

export function UpdateNotes({ setModalVisible, selectedNotes }) {
  const { notes } = useSelector(state => state.notes);
  const [notesName, setNotesName] = useState(selectedNotes?.notesName || "");
  const [notesInfo, setNotesInfo] = useState(selectedNotes?.notesInfo || "");
  const [notesNameError, setNotesNameError] = useState(false);
  const [notesInfoError, setNotesInfoError] = useState(false);
  const [isNotesNameExists, setIsNotesNameExists] = useState(false);
  const [isNewNotes, setisNewNotes] = useState(false);
  const { currentUserProfile } = useSelector(state => state.profile);
  useEffect(() => {
    if (notesName?.length == 0) {
      setisNewNotes(true);
    }
  }, []);

  const buttonStyle = {
    padding: "0.6rem",
    width: "7rem",
    marginRight: "10px",
  };
  const isNotesNameAlreadyExists = notesName => {
    return notes?.find(item => {
      return item?.notesName?.toLowerCase() === notesName?.toLowerCase();
    });
  };
  const handleSave = async e => {
    try {
      e.preventDefault();

      if (notesName.trim()?.length === 0) {
        setNotesNameError(true);
        return;
      }
      if (notesInfo.trim()?.length === 0) {
        setNotesInfoError(true);
        return;
      }
      const checkedNotes = isNotesNameAlreadyExists(notesName);
      if (checkedNotes !== undefined && checkedNotes.id !== selectedNotes.id) {
        setIsNotesNameExists(true);
        return;
      }
      setNotesNameError(false);
      setModalVisible(false);
      if (isNewNotes) {
        try {
          await addNotesToDb(
            currentUserProfile?.id,
            notesName[0].toUpperCase() + notesName.slice(1).toLowerCase(),
            notesInfo
          );
          toast.success("Notes added to db successfully!!", {
            autoClose: 2000,
          });
          return;
        } catch (err) {
          toast.error("Failed to addthe notes", {
            autoClose: 2000,
          });
        }
      }
      await updateNotesInDb(
        selectedNotes?.id,
        notesName[0].toUpperCase() + notesName.slice(1).toLowerCase(),
        notesInfo
      );

      toast.success("Notes updated successfully!!", {
        autoClose: 2000,
      });
    } catch (err) {
      toast.error("Failed to addthe notes", {
        autoClose: 2000,
      });
    }
  };
  const handleDiscard = e => {
    e.preventDefault();
    setModalVisible(false);
    setNotesName("");
    setNotesInfo("");
    setNotesNameError(false);
    setIsNotesNameExists(false);
  };

  return (
    <SweetAlert
      showCancel={false}
      showConfirm={false}
      closeOnClickOutside
      style={{ width: "60%", height: "60%" }}
    >
      <Form style={{ width: "100%", height: "75%" }}>
        <FormGroup row style={{ marginBottom: "1rem", marginTop: "1rem" }}>
          <Label for="class_name" sm={2}>
            Name
            <span style={{ color: "red" }}>*</span>
          </Label>
          <Col sm={10}>
            <Input
              id="class_name"
              name="email"
              type="text"
              invalid={notesNameError}
              value={notesName}
              onChange={e => {
                setNotesName(e.target.value);
                setIsNotesNameExists(false);
                setNotesNameError(false);
              }}
            />
            {notesNameError && (
              <Alert
                severity="error"
                color="danger"
                style={{ padding: "0px 5px", margin: "5px" }}
              >
                Please enter the Notes Name...
              </Alert>
            )}
            {isNotesNameExists && (
              <Alert
                severity="error"
                color="danger"
                style={{ padding: "0px 5px", margin: "5px" }}
              >
                Notes Name Already Exists, Try another name...
              </Alert>
            )}
          </Col>
        </FormGroup>
        <FormGroup row style={{ marginBottom: "1rem", marginTop: "1rem" }}>
          <Label for="class_description" sm={2}>
            Notes
            <span style={{ color: "red" }}>*</span>
          </Label>
          <Col sm={10}>
            <TextareaAutosize
              className="form-control"
              id="class_description"
              type="text"
              minRows={3}
              maxRows={Infinity}
              value={notesInfo}
              multiline={true}
              style={{ height: "200px", overflow: "scroll" }}
              onChange={e => {
                setNotesInfo(e.target.value);
              }}
            />
          </Col>
          {notesInfoError && (
            <Alert
              severity="error"
              color="danger"
              style={{ padding: "0px 5px", margin: "5px" }}
            >
              Please enter the Notes...
            </Alert>
          )}
        </FormGroup>
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingLeft: "10px",
          marginTop: "30px",
        }}
      >
        <Button
          color="success"
          style={buttonStyle}
          onClick={handleSave}
          disabled={
            notesName?.trim() === selectedNotes?.notesName &&
            notesInfo?.trim() === selectedNotes?.notesInfo
          }
        >
          Save
        </Button>
        <Button color="danger" style={buttonStyle} onClick={handleDiscard}>
          Discard
        </Button>
      </div>
    </SweetAlert>
  );
}
