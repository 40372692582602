import PlacesAutocomplete from "react-places-autocomplete";
import { Input, Label } from "reactstrap";

export function AddressSuggestion({ address, setAddress }) {
  return (
    <div className="mb-3">
      <PlacesAutocomplete
        value={address || ""}
        onChange={addr => {
          setAddress(addr);
        }}
        onSelect={addr => {
          setAddress(addr);
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Label>Address</Label>
            <Input
              id="address"
              {...getInputProps({
                placeholder: "Search address ...",
              })}
            />
            {(!address || address === "") && (
              <p className="requiredError">*Required</p>
            )}

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                    };

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={index}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
