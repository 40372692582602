import { IconButton, Typography } from "@mui/material";
import { useState } from "react";
import "../jobs/jobCreation/jobCategory.css";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteSelectedJobReportTemplate } from "services";
import { toast } from "react-toastify";
export default function TemplateSavedUnit({ myChecklist, onDragEnd,setShowDeleteAlert,setSelectedItem }) {
  const [searchType, setSearchType] = useState("");
  const handleItemClick = item => {
    setSearchType(item);
    onDragEnd(item);
  };
  const ShowChecklistDetails = ({ selectedChecklist }) => {
    const DisplayChecklistItems = () => {
      return (
        <>
          {selectedChecklist?.items?.map((item, idx) => {
            const sectionNo = idx + 1;
            return (
              <>
                <Typography
                  style={{ fontFamily: "Poppins", fontStyle: "normal" }}
                >
                  {`${sectionNo}. ${
                    item?.content || item?.title || item?.name
                  }`}
                </Typography>
                {item?.description ? (
                  <>
                    <Typography
                      variant="subtitle"
                      style={{ fontFamily: "Poppins", fontStyle: "normal" }}
                    >
                      {item?.description}
                    </Typography>
                  </>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "20px",
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                >
                  {item?.subItems?.map((subItem, jdx) => {
                    const itemNo = jdx + 1;
                    return (
                      <Typography
                        variant="subtitle"
                        style={{ fontFamily: "Poppins", fontStyle: "normal" }}
                      >
                        {`${sectionNo}.${itemNo} ${
                          subItem.title || subItem.content
                        }`}
                      </Typography>
                    );
                  })}
                </div>
              </>
            );
          })}
        </>
      );
    };
    return (
      <div>
        <Typography
          variant="h6"
          className="modal-title mt-0 defaultFont"
          style={{
            textTransform: "capitalize",
            color: "#65a2eb",
            fontFamily: "Poppins",
            fontStyle: "normal",
          }}
        >
          {selectedChecklist?.title || selectedChecklist?.templateName}
        </Typography>
        <DisplayChecklistItems />
      </div>
    );
  };

  return (
    <>
     
      <ShowChecklistDetails />
      <div>
        <FormControl sx={{ m: 1, minWidth: 210 }} style={{float: "right",paddingTop:"7px",marginBottom:'20px',marginRight: "0px"}}>
          <InputLabel id="search-type-label">Default Template</InputLabel>
          <Select
            labelId="search-type-label"
            id="search-type-select"
            value={searchType?.title || searchType}
            label="Default Template"
            defaultValue={searchType}
            size="small"
          >
            {myChecklist.map(item => (
              <MenuItem
                key={item.value}
                value={item.value}
                onClick={() => handleItemClick(item)}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>{item.title || item.name || item.templateName}</span>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  style={{ float: "right" }}
                  onClick={e => {
                    e.stopPropagation();
                    setShowDeleteAlert(true);
                    setSelectedItem(item);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
}
