import React, { useState } from "react";
import { useSelector } from "react-redux";
import JobHeader from "../../searchuicomponents/JobHeader";
import {
  CLIENT_NAME,
  JOB_ADDRESS,
  JOB_NUMBER,
  JOB_NAME,
  CUSTOM_JOB_NUMBER,
} from "common/constants";
import { Col, Container, Row } from "reactstrap";
import {
  ErrorBoundary,
  SearchProvider,
  Paging,
  WithSearch,
} from "@elastic/react-search-ui";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "../../../src/searchuicomponents/customStyles.scss";
import "../../../src/searchuicomponents/jobsCustomStyles.scss";
import { CustomPagination } from "../../searchuicomponents/CustomPagination";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import ProgressBar from "searchuicomponents/ProgressBar";
import { Search } from "./Search";
import ListLayout from "./ListLayout";
import GridLayout from "./GridLayout";
import Loader from "components/Loader";

export const Dashboard = ({
  config,
  searchType,
  setSearchType,
  pathname,
  setJobUpdate,
  isLinkEnabled,
  collectionName
}) => {
  const { isCloning,jobs,loading } = useSelector(state => state.job);
  const [isNewJob,setIsNewJob] = useState(pathname.includes("newjob"));
  const getJobType = () => {
    if (pathname.includes("active")) {
      return "Active Jobs";
    }
    if (pathname.includes("archive")) {
      return "Archived Jobs";
    }
    if (pathname.includes("delete")) {
      return "Deleted Jobs";
    }
    if(pathname.includes("newjob")){
      return "Do you want to link to job?"
    }
    return pathname;
  };
  const jobType = getJobType();
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem(jobType) || "1"
  );
  const handleSetActiveTab = tabIndex => {
    setActiveTab(tabIndex);
    localStorage.setItem(jobType, tabIndex);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <JobHeader
          activeTab={activeTab}
          setActiveTab={handleSetActiveTab}
          title={jobType}
          pathname={pathname}
        />
        <Container fluid>
          <Row>
            <Col lg="12">
              <div>
                <SearchProvider config={config}>
                  <WithSearch
                    mapContextToProps={({
                      isLoading,
                      results,
                      resultSearchTerm,
                      setSearchTerm,
                      shouldClearFilters,
                    }) => ({
                      isLoading,
                      results,
                      resultSearchTerm,
                      setSearchTerm,
                      shouldClearFilters,
                    })}
                  >
                    {({
                      results,
                      isLoading,
                      resultSearchTerm,
                      setSearchTerm,
                      shouldClearFilters,
                    }) => {
                      return (
                        <ErrorBoundary>
                          <div className="parent">
                            <div className="type-box">
                              <FormControl fullWidth>
                                <InputLabel id="search-type-label">
                                  Search On
                                </InputLabel>
                                <Select
                                  labelId="search-type-label"
                                  id="search-type-select"
                                  value={searchType}
                                  label="Search On"
                                  onChange={event => {
                                    setSearchType(event.target.value);
                                    setSearchTerm("", {
                                      shouldClearFilters: false,
                                    });
                                  }}
                                  size="small"
                                >
                                  <MenuItem value={JOB_NUMBER}>
                                    IB Number
                                  </MenuItem>
                                  <MenuItem value={CUSTOM_JOB_NUMBER}>
                                    Custom Number
                                  </MenuItem>
                                  <MenuItem value={CLIENT_NAME}>
                                    Client Name
                                  </MenuItem>
                                  <MenuItem value={JOB_ADDRESS}>
                                    Job Site Address
                                  </MenuItem>
                                  <MenuItem value={JOB_NAME}>Job Name</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="search-box">
                              <Search
                                searchType={searchType}
                                setSearchTerm={setSearchTerm}
                                shouldClearFilters={shouldClearFilters}
                                resultSearchTerm={resultSearchTerm}
                              />
                            </div>
                          </div>
                          <div className="w-100">
                         {loading &&   <Loader />}
                            {activeTab === "2" ? (
                              <ListLayout
                                results={results}
                                isLoading={isLoading}
                                setSearchTerm={setSearchTerm}
                                resultSearchTerm={resultSearchTerm}
                                pathname={pathname}
                                setJobUpdate={setJobUpdate}
                                isLinkEnabled={isLinkEnabled}
                                collectionName={collectionName}
                                isNewJob={isNewJob}
                              />
                            ) : (
                              <GridLayout
                                results={results}
                                isLoading={isLoading}
                                setSearchTerm={setSearchTerm}
                                resultSearchTerm={resultSearchTerm}
                                pathname={pathname}
                                setJobUpdate={setJobUpdate}
                                isLinkEnabled={isLinkEnabled}
                                collectionName={collectionName}
                                isNewJob={isNewJob}
                              />
                            )}
                          </div>
                          {(!loading && results.length > 0) && (
                            <Paging view={CustomPagination} />
                          )}
                        </ErrorBoundary>
                      );
                    }}
                  </WithSearch>
                </SearchProvider>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isCloning && <JobCloneProgress />}
    </React.Fragment>
  );
};

const JobCloneProgress = () => {
  return (
    <SweetAlert
      title={"Cloning the job..."}
      timeout={5000}
      showConfirm={false}
      onConfirm={() => {
        console.log("Job cloned succesfully");
      }}
    >
      <ProgressBar />
    </SweetAlert>
  );
};

export default Dashboard;