import classnames from "classnames";
import { map } from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import {
  deleteChecklists,
  fetchJobFromDbById,
  getChecklistAttachments,
  updateJobRooms,
} from "services";
import FloorContainer from "./FloorsContainer";
import { getIcon } from "common/data/rooms";
import "./../jobCreation/checklist/CheckListPage.scss";
import EditJobCategory from "./editJobCategory/EditJobCategory";
import {
  COMMERCIAL_CATEGORY,
  INFRASTRUCTURE_CATEGORY,
  INSPECTION_DB_NAME,
  RESIDENTIAL_CATEGORY,
} from "common/constants";
import SweetAlert from "react-bootstrap-sweetalert";
import firebase from "config/firebase";
import { toast } from "react-toastify";
import { deleteChecklistAttachments } from "../utils";
import { Alert } from "@mui/material";

export default function RoomsOverview({ job, canEdit, collectionName }) {
  const [showRoomInternalModal, setShowRoomInternalModal] = useState(false);
  const [showRoomExternalModal, setShowRoomExternalModal] = useState(false);
  const [showBelowGroundModal, setShowBelowGroundModal] = useState(false);
  const [isConfirmAlertOpen, setIsConfirmAlertOpen] = useState(false);
  const [selectJobCategory, setSelectJobCategory] = useState();

  const db = firebase.firestore();
  const INTERNAL_TAB = "1";
  const EXTERNAL_TAB = "2";
  const BELOW_GROUND_TAB = "3";

  const [hasInternal, setHasInternal] = useState(job.jobType.internal);
  const [hasExternal, setHasExternal] = useState(job.jobType.external);
  const [hasBelow, setHasBelow] = useState(job.jobType.belowground);
  const pathName =
    collectionName === INSPECTION_DB_NAME ? "inspectionactive" : "active";
  const defaultActiveTab = () => {
    return hasInternal
      ? INTERNAL_TAB
      : hasExternal
      ? EXTERNAL_TAB
      : BELOW_GROUND_TAB;
  };
  defaultActiveTab();
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setHasBelow(job.jobType.belowground);
    setHasExternal(job.jobType.external);
    setHasInternal(job.jobType.internal);
  }, [job.jobType]);

  useEffect(() => {
    if (job.jobType.internal && activeTab === INTERNAL_TAB) {
      toggle(INTERNAL_TAB);
    } else if (job.jobType.external && activeTab === EXTERNAL_TAB) {
      toggle(EXTERNAL_TAB);
    } else if (job.jobType.belowground && activeTab === BELOW_GROUND_TAB) {
      toggle(BELOW_GROUND_TAB);
    }
  }, [job.jobType]);

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setLoading(false);
    }
  };

  const onSaveChanges = (jobType, rooms) => {
    updateJobRooms(job.id, jobType, rooms, collectionName);
  };

  let totalAreasInJob = rooms => {
    let totalAreas = 0;
    rooms?.forEach(
      room =>
        (totalAreas += room.subItems?.length > 0 ? room.subItems.length : 1)
    );
    return totalAreas;
  };

  const handleJobCategory = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDeleteJobCategory = async () => {
    setIsLoading(true);
    try {
      await db.runTransaction(async txn => {
        const jobRef = fetchJobFromDbById(collectionName, job.id);
        const doc = await txn.get(jobRef);
        const jobData = doc.data();
        jobData.jobType[selectJobCategory] = false;
        jobData[selectJobCategory].rooms.map((room, index) => {
          if (room.subItems.length > 0) {
            room.subItems.map(async (item, index) => {
              const attachmenstToDelete = await getChecklistAttachments(
                job.id,
                collectionName,
                [item.id]
              );
              deleteChecklistAttachments(attachmenstToDelete).then(async () => {
                await deleteChecklists(job.id, collectionName, [item.id]);
              });
            });
          }
        });
        jobData[selectJobCategory] = null;
        txn.update(jobRef, jobData);
        job.jobType = jobData.jobType;
      });
      setIsConfirmAlertOpen(false);
      toast.success(`Job category deleted successfully`, { autoClose: 2000 });
      if (job.jobType.internal) {
        toggle(INTERNAL_TAB);
      } else if (job.jobType.external) {
        toggle(EXTERNAL_TAB);
      } else if (job.jobType.belowground) {
        toggle(BELOW_GROUND_TAB);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Failed to delete job category", error);
      setIsConfirmAlertOpen(false);
      toast.warn(`Failed to delete job category ${error.message}`, {
        autoClose: 2000,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <EditJobCategory
          isOpen={isOpen}
          closeModal={closeModal}
          jobId={job?.id}
          collectionName={collectionName}
        />
      )}
      <Card>
        <CardBody>
          <Row>
            <Col md={9}>
              <CardTitle className="mb-4">Floor and Area(s)</CardTitle>
            </Col>
            <Col md={3}>
              {canEdit && (
                <Button
                  color="primary"
                  className="btn btn-primary btn-sm"
                  onClick={() => handleJobCategory()}
                >
                  Edit
                </Button>
              )}
            </Col>
          </Row>
          <Nav tabs>
            {hasInternal && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === INTERNAL_TAB,
                  })}
                  onClick={() => {
                    toggle(INTERNAL_TAB);
                  }}
                >
                  {RESIDENTIAL_CATEGORY}
                  {canEdit && (
                    <i
                      className="dripicons-cross text-danger ml-5"
                      onClick={() => {
                        setIsConfirmAlertOpen(true);
                        setSelectJobCategory("internal");
                      }}
                    />
                  )}
                </NavLink>
              </NavItem>
            )}
            {hasExternal && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === EXTERNAL_TAB,
                  })}
                  onClick={() => {
                    toggle(EXTERNAL_TAB);
                  }}
                >
                  {COMMERCIAL_CATEGORY}
                  {canEdit && (
                    <i
                      className="dripicons-cross text-danger ml-5"
                      onClick={() => {
                        setIsConfirmAlertOpen(true);
                        setSelectJobCategory("external");
                      }}
                    />
                  )}
                </NavLink>
              </NavItem>
            )}
            {hasBelow && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === BELOW_GROUND_TAB,
                  })}
                  onClick={() => {
                    toggle(BELOW_GROUND_TAB);
                  }}
                >
                  {INFRASTRUCTURE_CATEGORY}
                  {canEdit && (
                    <i
                      className="dripicons-cross text-danger ml-5"
                      onClick={() => {
                        setIsConfirmAlertOpen(true);
                        setSelectJobCategory("belowground");
                      }}
                    />
                  )}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          {job?.jobType?.internal ||
          job?.jobType?.external ||
          job?.jobType?.belowground ? (
            <TabContent activeTab={activeTab} className="p-3 text-muted">
              <TabPane tabId={INTERNAL_TAB}>
                {job?.internal?.rooms?.length === 0 ? (
                  <p style={{ textAlign: "center" }}>There are no floors</p>
                ) : null}
                {canEdit && (
                  <Button
                    color="primary"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setShowRoomInternalModal(true);
                    }}
                  >
                    Edit
                  </Button>
                )}
                <div
                  className={
                    totalAreasInJob(job.internal?.rooms) > 4
                      ? "scrollable-content"
                      : ""
                  }
                >
                  {map(job.internal?.rooms, (floor, i) => {
                    return (
                      <Card key={i}>
                        <CardBody>
                          <CardTitle className="mb-4">
                            {floor.content}
                          </CardTitle>
                          <div className="table-responsive">
                            <table className="table table-nowrap align-middle mb-0">
                              <tbody>
                                {floor.subItems.length > 0 ? (
                                  floor.subItems.map((item, i) => (
                                    <tr
                                      key={i}
                                      style={{
                                        backgroundColor: job?.areas?.[item?.id]
                                          ?.isLocked
                                          ? "#C5C5C5"
                                          : "",
                                      }}
                                    >
                                      <td>
                                        <img
                                          src={getIcon(item.type)}
                                          style={{
                                            float: "left",
                                            height: "30px",
                                            transform: "translate(0, -5px)",
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <h5 className="text-truncate font-size-14 m-0">
                                          <Link
                                            to={`/${pathName}/${job.id}/${item.id}`}
                                            className="text-dark"
                                          >
                                            {item.content}
                                          </Link>
                                        </h5>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                      The floor has no areas
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    );
                  })}
                </div>
              </TabPane>
              <TabPane tabId={EXTERNAL_TAB}>
                {job?.external?.rooms?.length === 0 ? (
                  <p style={{ textAlign: "center" }}>There are no floors</p>
                ) : null}
                {canEdit && (
                  <Button
                    color="primary"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setShowRoomExternalModal(true);
                    }}
                  >
                    Edit
                  </Button>
                )}
                <div
                  className={
                    totalAreasInJob(job.external?.rooms) > 4
                      ? "scrollable-content"
                      : ""
                  }
                >
                  {map(job.external?.rooms, (floor, i) => {
                    return (
                      <Card key={i}>
                        <CardBody>
                          <CardTitle className="mb-4">
                            {floor.content}
                          </CardTitle>
                          <div className="table-responsive">
                            <table className="table table-nowrap align-middle mb-0">
                              <tbody>
                                {floor.subItems.length > 0 ? (
                                  floor.subItems.map((item, i) => (
                                    <tr
                                      key={i}
                                      style={{
                                        backgroundColor: job?.areas?.[item?.id]
                                          ?.isLocked
                                          ? "#C5C5C5"
                                          : "",
                                      }}
                                    >
                                      <td>
                                        <img
                                          src={getIcon(item.type)}
                                          style={{
                                            float: "left",
                                            height: "30px",
                                            transform: "translate(0, -5px)",
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <h5 className="text-truncate font-size-14 m-0">
                                          <Link
                                            to={`/${pathName}/${job.id}/${item.id}`}
                                            className="text-dark"
                                          >
                                            {item.content}
                                          </Link>
                                        </h5>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                      The floor has no areas
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    );
                  })}
                </div>
              </TabPane>
              <TabPane tabId={BELOW_GROUND_TAB}>
                {job?.belowground?.rooms?.length === 0 ? (
                  <p style={{ textAlign: "center" }}>There are no floors</p>
                ) : null}
                {canEdit && (
                  <Button
                    color="primary"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setShowBelowGroundModal(true);
                    }}
                  >
                    Edit
                  </Button>
                )}

                <div
                  className={
                    totalAreasInJob(job.belowground?.rooms) > 4
                      ? "scrollable-content"
                      : ""
                  }
                >
                  {map(job.belowground?.rooms, (floor, i) => {
                    return (
                      <Card key={i}>
                        <CardBody>
                          <CardTitle className="mb-4">
                            {floor.content}
                          </CardTitle>
                          <div className="table-responsive">
                            <table className="table table-nowrap align-middle mb-0">
                              <tbody>
                                {floor.subItems.length > 0 ? (
                                  floor.subItems.map((item, i) => (
                                    <tr
                                      key={i}
                                      style={{
                                        backgroundColor: job?.areas?.[item?.id]
                                          ?.isLocked
                                          ? "#C5C5C5"
                                          : "",
                                      }}
                                    >
                                      <td>
                                        <img
                                          src={getIcon(item.type)}
                                          style={{
                                            float: "left",
                                            height: "30px",
                                            transform: "translate(0, -5px)",
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <h5 className="text-truncate font-size-14 m-0">
                                          <Link
                                            to={`/${pathName}/${job.id}/${item.id}`}
                                            className="text-dark"
                                          >
                                            {item.content}
                                          </Link>
                                        </h5>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                      The floor has no areas
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    );
                  })}
                </div>
              </TabPane>
            </TabContent>
          ) : (
            <tr>
              <td style={{ textAlign: "center", padding: "2rem" }}>
                There are no job category
              </td>
            </tr>
          )}
        </CardBody>
      </Card>
      <Modal
        className="p-4"
        isOpen={showRoomInternalModal}
        size="lg"
        onOpened={() => {
          setTimeout(() => {
            setLoading(true);
          }, 200);
        }}
        onClosed={() => {
          setLoading(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {RESIDENTIAL_CATEGORY} - Add/Remove Floor and Area(s)
          </h5>
          <button
            type="button"
            onClick={() => setShowRoomInternalModal(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <FloorContainer
            job={job}
            jobType={"internal"}
            onSave={onSaveChanges}
            onDone={() => setShowRoomInternalModal(false)}
            floorLoading={loading}
            collectionName={collectionName}
          />
        </div>
      </Modal>
      <Modal
        className="p-4"
        isOpen={showRoomExternalModal}
        size="lg"
        onOpened={() => {
          setTimeout(() => {
            setLoading(true);
          }, 200);
        }}
        onClosed={() => {
          setLoading(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {COMMERCIAL_CATEGORY} - Add/Remove Floor and Area(s)
          </h5>
          <button
            type="button"
            onClick={() => setShowRoomExternalModal(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <FloorContainer
            job={job}
            jobType={"external"}
            onSave={onSaveChanges}
            onDone={() => setShowRoomExternalModal(false)}
            floorLoading={loading}
            collectionName={collectionName}
          />
        </div>
      </Modal>
      <Modal
        className="p-4"
        isOpen={showBelowGroundModal}
        size="lg"
        onOpened={() => {
          setTimeout(() => {
            setLoading(true);
          }, 200);
        }}
        onClosed={() => {
          setLoading(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {INFRASTRUCTURE_CATEGORY} - Add/Remove Floor and Area(s)
          </h5>
          <button
            type="button"
            onClick={() => setShowBelowGroundModal(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <FloorContainer
            job={job}
            jobType={"belowground"}
            onSave={onSaveChanges}
            onDone={() => setShowBelowGroundModal(false)}
            floorLoading={loading}
            collectionName={collectionName}
          />
        </div>
      </Modal>
      {isConfirmAlertOpen ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel={!isLoading}
          showConfirm={!isLoading}
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleDeleteJobCategory();
          }}
          onCancel={() => {
            setIsConfirmAlertOpen(false);
          }}
        >
          <p>
            Entire data in this job category like floor and area, checklists,
            photos and notes will be deleted permanently.
          </p>
          {isLoading && (
            <Alert color="warning" className="mb-2">
              <h5 className="alert-heading mb-2">
                Deleting Job Category is in progress, Please wait..
              </h5>
            </Alert>
          )}
        </SweetAlert>
      ) : null}
    </>
  );
}
