import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { memo } from "react";
import Html from "react-pdf-html";
const styles = StyleSheet.create({
  container: {
    marginTop: 3,
    flexDirection: "column",
  },
  description: {
    width: "100%",
  },
  title: {
    flexDirection: "row",
    alignSelf: "flex-start",
    fontSize: 16,
    fontWeight: 600,
    color: "#000",
  },
  p: {
    fontSize: 14,
    color:"red",
    lineHeight: "0px",
  }
});
  function removeEmptyParagraphs(input) {
    console.log("input", input);
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = input;
    var paragraphs = tempDiv.querySelectorAll(".editor-paragraph");
    for (var i = paragraphs.length - 1; i >= 0; i--) {
      console.log(paragraphs[i]);
      var paragraph = paragraphs[i];
      if (paragraph?.textContent?.trim()) {
        break;
      }
      paragraph?.parentNode?.removeChild(paragraph);
    }
    for (var i = 0; i <paragraphs.length ; i++) {
      console.log(paragraphs[i]);
      var paragraph = paragraphs[i];
      if (paragraph.textContent.trim()) {
        break;
      }
      paragraph?.parentNode?.removeChild(paragraph);
    }
    return tempDiv.innerHTML;
  }

const DynamicJobReportTemplateChecklist = ({ templates,position }) => {
  return (
    <View>
      {templates?.map(checklist => {
        return (
          <>
            {checklist?.selectedValue === position && (
              <>
                <Text style={styles.title}>{checklist?.name}</Text>
                <View style={styles.container}>
                  <Html style={styles.p}>
                    {removeEmptyParagraphs(checklist?.description)}
                  </Html>
                </View>
              </>
            )}
          </>
        );
      })}
    </View>
  );
};

export default memo(DynamicJobReportTemplateChecklist);
