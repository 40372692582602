import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import cuid from "cuid";
import { toast } from "react-toastify";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { addNewListItemToDB } from "services";
import MasterListItems from "../../jobDetails/rooms/checklist/CheckListMasterUnit";
import "./CheckListPage.scss";

const ChecklistItemSection = ({
  checklistItemName,
  setChecklistItemName,
  isDuplicate,
  items,
  setSelectedListItem,
  openModalDlg,
  setItemSearch,
  itemSearch,
  myListItems,
  profile,
}) => {
  const addCheckListItem = () => {
    const newItem = {
      id: cuid(),
      title: checklistItemName,
      checkBox: true,
      checked: false,
      documents: [],
      comment: false,
      comments: [],
      attachment: false,
      attachments: [],
      signature: false,
      signatures: [],
      holdPoint: false,
      helpPoint: false,
      locked: false,
      isValidItem: true,
    };
    addNewListItemToDB(newItem, profile?.orgAccessId);
    if (items.length) {
      setSelectedListItem({
        ...newItem,
        content: newItem.title,
        isValidItem: true,
      });
      openModalDlg();
    }
    setChecklistItemName("");
  };
  return (
    <>
      <div className="mb-2">
        <Label className="checklist-label">Add items to the checklist</Label>
        <div className="add-checklist-item mb-4">
          <Input
            name="checklistItemName"
            value={checklistItemName}
            placeholder="New item name"
            onChange={e => setChecklistItemName(e.target.value)}
          ></Input>

          <Button
            disabled={!checklistItemName}
            className="add-checklist-item__action"
            size="sm"
            outline
            color="primary"
            onClick={() => {
              if (isDuplicate(checklistItemName)) {
                toast.error("Duplicate list item.", {
                  autoClose: 3000,
                });
                return;
              }
              addCheckListItem();
            }}
          >
            Add
          </Button>
        </div>
      </div>

      <div className="mb-2">
        <Label className="checklist-label">Search for saved items</Label>

        <div className="checklist-item-search">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="checklist-item-search-icon">
                <SearchOutlinedIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="search"
              name="itemSearch"
              placeholder="Enter keyword"
              onChange={e => setItemSearch(e.target.value)}
            ></Input>
          </InputGroup>
        </div>
      </div>

      <Row>
        <MasterListItems itemSearch={itemSearch} myListItems={myListItems} />
      </Row>
    </>
  );
};
export default ChecklistItemSection;
