import { Button } from "components/dnd";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addWorkingDatesForParticipants } from "services";
import "./DaterangePicker.scss";
import { toast } from "react-toastify";
const DateRangePicker = ({ user, collectionName, jobId }) => {
  const currentDate = new Date();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    if (user?.startDate && user?.endDate) {
      const initalDate = getDate(user?.startDate);
      setStartDate(initalDate);
      const endDate = getDate(user?.endDate);
      setEndDate(endDate);
    }
  }, [user]);
  const getDate = date => {
    const milliseconds = date?.seconds * 1000 + date.nanoseconds / 1e6;
    const parsedDate = new Date(milliseconds);
    return parsedDate;
  };

  const [errors, setErrors] = useState({ startDate: "", endDate: "" });
  const handleStartDateChange = date => {
    setErrors({ startDate: "", endDate: "" });
    if (endDate >= date || !endDate) {
      setStartDate(date);
      return;
    }
    setErrors({
      startDate: "Ensure start date is not less than the end date",
      endDate: errors.endDate,
    });
  };

  const handleEndDateChange = date => {
    setErrors({ startDate: "", endDate: "" });
    if (date >= startDate || !startDate) {
      setEndDate(date);
      return;
    }
    setErrors({
      startDate: errors.startDate,
      endDate: "Ensure end date is greater than the start date",
    });
  };
  const handleSubmit = () => {
    if (!startDate || !endDate) {
      setErrors({
        startDate: !startDate ? "Start date is required" : "",
        endDate: !endDate ? "End date is required" : "",
      });
      return;
    }
    addWorkingDatesForParticipants(
      jobId,
      {
        userId: user?.userId,
        startDate,
        jobId,
        endDate,
        collectionName,
        jobType: "jobs",
      },
      collectionName
    );
    toast.success("Successfully added dates to user", { autoClose: 2000 });
    return;
  };

  return (
    <div>
      <h5 className="text-truncate font-size-12">{"Start Date"}</h5>
      <DatePicker
        value={startDate || "Select start date"}
        selected={startDate}
        onChange={handleStartDateChange}
        minDate={currentDate}
        startDate={startDate}
        className="custom-date-picker"
        dateFormat={"dd/MM/yyyy"}
        onSelect={e => {
          if (
            startDate &&
            e.getDate() === startDate.getDate() &&
            e.getMonth() === startDate.getMonth() &&
            e.getFullYear() === startDate.getFullYear()
          ) {
            setErrors({ startDate: "", endDate: "" });
          }
        }}
      />
      {errors.startDate && <p style={{ color: "red" }}>{errors.startDate}</p>}
      <h5 className="text-truncate font-size-12">{"End Date"}</h5>

      <DatePicker
        value={endDate || "Select end date"}
        selected={endDate}
        onChange={handleEndDateChange}
        minDate={currentDate}
        endDate={endDate}
        className="custom-date-picker"
        dateFormat={"dd/MM/yyyy"}
        onSelect={e => {
          if (
            endDate &&
            e.getDate() === endDate.getDate() &&
            e.getMonth() === endDate.getMonth() &&
            e.getFullYear() === endDate.getFullYear()
          ) {
            setErrors({ startDate: "", endDate: "" });
          }
        }}
      />
      {errors.endDate && <p style={{ color: "red" }}>{errors.endDate}</p>}
      <Button
        onClick={handleSubmit}
        style={{
          margin: "0.5rem",
          padding: "0.5rem 0.75rem",
          backgroundColor: (!startDate || !endDate)?"#7fb6e7":"#50a5f1",
          color: "white",
          border: "none",
          cursor: "pointer",
          borderRadius: "5px",
        }}
        disabled={!startDate || !endDate}
      >
        Submit
      </Button>
    </div>
  );
};

export default DateRangePicker;
