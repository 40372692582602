import { INSPECTION_DB_NAME } from "common/constants";
import firebase from "../config/firebase";
import cuid from "cuid";
const db = firebase.firestore();
const CHAT = "chat";
const NOTIFICATIONS = "notifications";
export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map(e =>
      Object.assign({}, e[1], { id: e[0] })
    );
  }
}

export function addJobChatComment(jobKey, values,collectionName) {
  const user = firebase.auth().currentUser;
  const newComment = {
    displayName: user.displayName,
    photoURL: user.photoURL,
    uid: user.uid,
    text: values.comment,
    imageUrl: values.imageUrl,
    date: Date.now(),
    parentId: values.parentId,
    isInspectionJob:collectionName === INSPECTION_DB_NAME,
  };
  return firebase.database().ref(`chat/${jobKey}`).push(newComment);
}


export function getJobChatRef(jobKey) {
  return firebase.database().ref(`chat/${jobKey}`).orderByKey();
}
