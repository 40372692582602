import React, { useState } from "react";
import { toast } from "react-toastify";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import cuid from "cuid";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
//Import Breadcrumb
import DeleteIcon from "@mui/icons-material/Delete";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { useDispatch, useSelector } from "react-redux";
import { addClientToDb, deleteClientInDb, fetchClientsFromDb } from "services";
import Papa from "papaparse";
import {
  fetchClients,
  fetchClientsError,
  fetchClientsSuccess,
} from "store/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CsvDownloader from 'react-csv-downloader';
import CreateNewClient from "./CreateNewClient";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ClientAdditionalInfo } from "./ClientAdditionalInfo";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import firebase from "../../config/firebase";
import DeleteConfirmationPromptWithCheckBox from "components/Common/DeleteConfirmationPromptWIthCheckBox";
import Loader from "components/Loader";
import { Loading } from "mdi-material-ui";
const Clients = () => {
  const { clients } = useSelector(state => state.person);

  const { currentUserProfile } = useSelector(state => state.profile);
  const [showCreateNewClient, setShowCreateNewClient] = useState(false);
  const [isEditClient, setEditClient] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [client, setClient] = useState({});
  const dispatch = useDispatch();
  const db = firebase.firestore();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const handleDeleteAllClients = async () => {
    if (checkedBoxes?.length > 0) {
      const batch = db.batch();
      checkedBoxes?.forEach(clientId => {
        const clientRef = db
          .collection(`orgs/${currentUserProfile?.orgAccessId}/client`)
          .doc(clientId);
        batch.delete(clientRef);
      });
      toast.success("The clients are deleted!", {
        autoClose: 2000,
      });
      setShowDeleteAlert(false);
      setCheckedBoxes([]);
      try {
        await batch.commit();
      } catch (error) {
        setShowDeleteAlert(false);
        console.error("Error deleting clients:", error);
      }
    }
  };
  function handleClient(clientId) {
    if (checkedBoxes.includes(clientId)) {
      setCheckedBoxes(checkedBoxes.filter(id => id !== clientId));
      return;
    }
    setCheckedBoxes([...checkedBoxes, clientId]);
    return;
  }
  useFirestoreCollection({
    query: () => fetchClientsFromDb(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchClients()),
    onSuccess: docs => dispatch(fetchClientsSuccess(docs)),
    onError: error => dispatch(fetchClientsError(error)),
    deps: [dispatch],
  });

  const onClientEdit = client => {
    setEditClient(true);
    setClient(client);
    setShowCreateNewClient(true);
  };
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 16,
      maxWidth: 500,
    },
  }));
  const onClientDelete = async id => {
    try {
      await deleteClientInDb(currentUserProfile.orgAccessId, id);
      toast.success("The client is deleted!", {
        autoClose: 2000,
      });
    } catch (err) {
      toast.error("Failed to delete the client", {
        autoClose: 2000,
      });
    }
  };
  const DisplayClient = ({ client, index, showCheckBox, handleClient }) => {
    return (
      <Tr key={index}>
        <Td style={{ display: "flex", alignItems: "center" }}>
          {showCheckBox && (
            <input
              type="checkbox"
              className="checkbox"
              style={{
                float: "left",
                width: "20px",
                height: "20px",
                marginRight: "5px",
                border: "2px solid #50a5f1",
              }}
              checked={checkedBoxes?.includes(client.id)}
              onClick={() => handleClient(client.id)}
            />
          )}
          {client?.photoURL ? (
            <div>
              <img
                className="rounded-circle avatar-xs"
                src={client?.photoURL}
                alt=""
              />
            </div>
          ) : (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {client?.name?.charAt(0)}
              </span>
            </div>
          )}
        </Td>
        <Td>{client?.name}</Td>
        <Td>{client?.email}</Td>
        <Td>{client?.phone}</Td>
        <Td>{client?.address}</Td>

        <Td>
          <div className="d-flex gap-3">
            <Link className="text-primary" to="#">
              <LightTooltip
                title={ClientAdditionalInfo(client)}
                placement="left"
                style={{ cursor: "pointer", backgroundColor: "white" }}
              >
                <VisibilityIcon id="viewtooltip"></VisibilityIcon>
              </LightTooltip>
            </Link>

            <Link className="text-success" to="#">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                aria-disabled={showCheckBox}
                onClick={() => onClientEdit(client)}
              ></i>
            </Link>
            <DeleteIcon
              aria-disabled={showCheckBox}
              onClick={async () => {
                onClientDelete(client.id);
              }}
              size={18}
              color="error"
            />
          </div>
        </Td>
      </Tr>
    );
  };

  const displayClients = showCheckBox => {
    const sortedClients = clients?.sort((client1, client2) => {
      const client1Name = client1?.name?.toLowerCase() || "";
      const client2Name = client2?.name?.toLowerCase() || "";
      if (client1Name < client2Name) {
        return -1;
      }
      if (client1Name > client2Name) {
        return 1;
      }
      return 0;
    })
    return sortedClients?.map((client, index) => (
      <DisplayClient
        client={client}
        index={index}
        showCheckBox={showCheckBox}
        handleClient={handleClient}
        key={index}
      />
    ));
  };
  const handleShowCheckBoxes = () => {
    setShowCheckBox(_showCheckBox => !showCheckBox);
    setCheckedBoxes([]);
  };
  let allowedExtensions = ["csv"]
  const isValidEmail = (email) => {
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  const isUniqueClientEmail = (email) => {
    const existingClients = clients.filter(existingClient => {
      return (
        existingClient.email?.trim()?.toLowerCase() ===
        email?.trim()?.toLowerCase()
      );
    });
    return existingClients.length <= 0;
  };
  const handleFileChange = async (e) => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        toast.error("Please input a CSV file.", {
          autoClose: 2000,
        });
        return;
      }
      if (!inputFile) {
        toast.error("Enter a valid file.", {
          autoClose: 2000,
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, {
          header: true,
        });
        const parsedData = csv?.data;
        const rows = Object.keys(parsedData[0]);
        const columns = Object.values(parsedData[0]);
        const res = rows.reduce((acc, e, i) => {
          return [...acc, [[e], columns[i]]];
        }, []);

        const promises = [];
        let hasValidEmail = false;

        for (let key in parsedData) {
          let email = parsedData[key]?.email;

          if (!isValidEmail(email)) {
            // toast.error(`Invalid email format: ${email}`, {
            //     autoClose: 2000,
            // });
            continue; 
          }

          if (!isUniqueClientEmail(email)) {
            // toast.error(`Duplicate email found: ${email}`, {
            //     autoClose: 2000,
            // });
            continue; 
          }

          hasValidEmail = true;

          const newUser = {
            id: cuid(),
            name: '',
            contactName: '',
            phone: '',
            telephone: "",
            secondaryPhone: "",
            secondaryTelephone: "",
            email: email,
            primaryEmail: "",
            secondaryEmail: "",
            address: "",
            type: "client",
            abn: "",
            registeredLicenseNumber: "",
            primaryName: "",
            secondaryName: "",
            phoneDialCode: "",
            telephoneDialCode: "",
            secondaryPhoneDialCode: "",
            secondaryTelephoneDialCode: "",
          };

          promises.push(
            addClientToDb(currentUserProfile.orgAccessId, newUser)
              .then((result) => console.log("resssss", result))
              .catch((err) => console.log(err))
          );
        }

        try {
          await Promise.all(promises);

          if (hasValidEmail) {
            toast.success("Success! All clients added.", {
              autoClose: 2000,
            });
          } else {
            toast.error("No valid unique email IDs found in CSV.", {
              autoClose: 2000,
            });
          }
        } catch (error) {
          console.log("Error while adding clients", error);
          // toast.error("Error while adding clients.", {
          //     autoClose: 2000,
          // });
        }
      };

      reader.readAsText(inputFile);
    } else {
      toast.error("No file selected.", {
        autoClose: 2000,
      });
      console.log("No file selected");
    }
    e.target.value = null;
  };
  const datas = [
    {
      email: 'testemail1@gmail.com',
    },
    {
      email: 'testemail2@gmail.com',
    },
  ];

  const columns = [
    {
      id: 'email',
      displayName: 'email',
    },
  ];

  return (
    <React.Fragment>
      {showDeleteAlert && (
        <DeleteConfirmationPromptWithCheckBox
          label={
            "I understand that deleting is permanent and cannot be undone."
          }
          onCancel={() => {
            setShowDeleteAlert(false);
          }}
          onConfirm={handleDeleteAllClients}
        />
      )}
      <div className="page-content">
        <MetaTags>
          <title>Clients List</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Setup" breadcrumbItem="Clients" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="12">
                      <div className="text-sm-end d-flex justify-content-end align-items-center">
                        <CsvDownloader
                          filename="sample"
                          extension=".csv"
                          separator=";"
                          className='font-16 btn-block btn btn-primary me-2'
                          wrapColumnChar="" // Remove double quotes by setting this to an empty string
                          columns={columns}
                          datas={datas}
                          text=" Download Sample csv"
                        />
                        <div className="upload_csv" >
                          <input type="file" id="upload_csv" onChange={handleFileChange} />
                          Upload CSV
                        </div>

                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={() => {
                            setShowCreateNewClient(true);
                            setEditClient(false);
                            setClient({});
                          }}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Create New Client
                        </Button>
                        {clients?.length > 0 && (
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary m-2"
                            onClick={handleShowCheckBoxes}
                          >
                            <i className="mdi mdi-plus-minus-outline me-1" />
                            {showCheckBox ? "Cancel" : "Delete Clients"}
                          </Button>
                        )}

                        {checkedBoxes?.length > 0 && showCheckBox && (
                          <DeleteIcon
                            onClick={() => {
                              setShowDeleteAlert(true);
                            }}
                            size={18}
                            color="error"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  {showCreateNewClient && (
                    <CreateNewClient
                      allClients={clients}
                      showCreateNewClient={showCreateNewClient}
                      setShowCreateNewClient={setShowCreateNewClient}
                      isEditClient={isEditClient}
                      setEditClient={setEditClient}
                      client={client}
                      setClient={setClient}
                      currentUserProfile={currentUserProfile}
                    />
                  )}

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th data-priority="1">Name</Th>
                            <Th data-priority="1">Email</Th>
                            <Th data-priority="1">Phone</Th>
                            <Th data-priority="4">Address</Th>
                            <Th data-priority="6">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>{displayClients(showCheckBox)}</Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Clients;