import { StyleSheet, Text, View } from "@react-pdf/renderer";
import {
  firstTableColHeaderStyle,
  firstTableColStyle,
  tableCellHeaderStyle,
  tableCellStyle,
  tableColHeaderStyle,
  tableColStyle,
  tableRowStyle,
  tableStyle,
} from "../tableStyles";
import { memo } from "react";
const FloorAndRoomDetails = ({ rooms: floors, jobType, buildingClasses }) => {
  const styles = StyleSheet.create({
    container: {
      paddingTop: 8,
    },
    horizontalLine: {
      borderBottom: 1,

      borderColor: "gray",
      width: "100%",
    },
  });
  const TableHeader = () => {
    return (
      <View>
        <View style={tableRowStyle}>
          <View style={{ ...firstTableColHeaderStyle, width: "50%" }}>
            <Text style={tableCellHeaderStyle}>Floor Level</Text>
          </View>
          <View style={{ ...tableColHeaderStyle, width: "50%" }}>
            <Text style={tableCellHeaderStyle}>Area(s)</Text>
          </View>
        </View>
        <View style={styles.horizontalLine} />
      </View>
    );
  };
  return (
    <View>
      <View style={tableStyle}>
        <TableHeader />
        {floors.map((floor, floorIndex) => {
          return floor?.subItems?.map((room, roomIndex) => {
            return (
              <View>
                <View key={`${floorIndex}${roomIndex}`} style={tableRowStyle}>
                  <View style={{ ...firstTableColStyle, width: "50%" }}>
                    <Text style={tableCellStyle}>{floor?.content}</Text>
                  </View>
                  <View style={{ ...tableColStyle, width: "50%" }}>
                    <Text style={tableCellStyle}>{room?.content}</Text>
                  </View>
                </View>
                  <View style={styles.horizontalLine} />
              </View>
            );
          });
        })}
      </View>
    </View>
  );
};
export default memo(FloorAndRoomDetails);
