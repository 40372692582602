import { useEffect } from "react";
import { dataFromSnapshot } from "../../services/index";

export default function useFirestoreDoc({
  query,
  onStart,
  onSuccess,
  onError,
  deps,
}) {
  useEffect(() => {
    onStart();
    const unsubscribe = query().onSnapshot(
      snapshot => {
        if (!snapshot.exists) {
          onSuccess({});
        }
        onSuccess(dataFromSnapshot(snapshot));
      },
      error => onError(error)
    );
    return () => {
      unsubscribe();
    };
  }, deps);
}
