import React from "react";
import { useDroppable } from "@dnd-kit/core";
import classNames from "classnames";

import { droppable } from "./droppable-svg";
import "./droppable.css";

export function Droppable({ children, id, dragging }) {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        "Droppable",
        isOver && "Over",
        dragging && "Dragging",
        children && "Dropped"
      )}
      aria-label="Droppable region"
    >
      {children}
      {droppable}
    </div>
  );
}
