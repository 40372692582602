// a little function to help us with reordering the result
export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 6;
export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    textAlign: "left",
    borderColor: "",
    borderStyle: "solid",
    borderRadius: "2px",
    borderWidth: "thin",

    // change background colour if dragging
    background: isDragging ? "rgba(50, 110, 230, 0.1)" : "white",
    borderColor: isDragging ? "#50a5f1" : "rgba(50, 110, 230, 0.3)",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

export const getListContainerStyle = isDraggingOver => ({
  background: isDraggingOver ? "rgba(50, 110, 230, 0.1)" : "white",
  padding: 1,
});

export const getListStyle = isDraggingOver => ({
  width: "96%",
  background: isDraggingOver ? "rgba(50, 110, 230, 0.1)" : "white",
  padding: 1,
});
