import React, { useEffect } from "react";
import { Container, Row, Table } from "reactstrap";

import "../../searchuicomponents/customStyles.scss";
import "../../searchuicomponents/jobsCustomStyles.scss";
import NotFound from "components/NotFound";
import { JobsListView } from "pages/dashboard/jobsListView";
import { CustomPagination } from "searchuicomponents/CustomPagination";
import { Paging } from "@elastic/react-search-ui";
import { getChatNotificationOfJob } from "services";
import { useSelector, useDispatch } from "react-redux";

const DashBoardListLayout = ({ results, isLoading ,resultSearchTerm}) => {
  const dispatch = useDispatch();
  const { jobs } = useSelector(state => state.job);
  const { notifications } = useSelector(state => state.notifications);
  const { currentUserProfile } = useSelector(state => state.profile);

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          {results.length > 0 ? (
            <div className="w-100">
              <Table className="table-search-ui project-list-table table-nowrap align-middle table-borderless">
                <thead>
                  <tr>
                    <th scope="col" style={{ textIndent: "15px" }}>
                     IB No.
                    </th>
                    <th scope="col">Custom No.</th>
                    <th scope="col">Job Name</th>
                    <th scope="col">Client</th>
                    <th scope="col">Site Address</th>
                    <th scope="col">Category</th>
                    <th scope="col">Participants</th>
                    <th scope="col">Progress</th>
                  </tr>
                </thead>
                {results.map(result => {
                  const job = jobs.find(job => job.id === result.id.raw);
                  const notification = getChatNotificationOfJob(
                    notifications,
                    currentUserProfile?.id,
                    result.id.raw
                  );
                  return (
                    <JobsListView
                      key={result.id.raw}
                      result={result}
                      job={job}
                      notification={notification}
                      resultSearchTerm={resultSearchTerm}
                    />
                  );
                })}
              </Table>
              <Paging view={CustomPagination} />
            </div>
          ) : isLoading ? (
            <div className="w-100">
              <Table className="table-search-ui project-list-table table-nowrap align-middle table-borderless">
                <thead>
                  <tr>
                    <th scope="col" style={{ textIndent: "15px" }}>
                      IB No.
                    </th>
                    <th scope="col">Custom No.</th>
                    <th scope="col">Job Name</th>
                    <th scope="col">Client</th>
                    <th scope="col">Site Address</th>
                    <th scope="col">Type</th>
                    <th scope="col">Participants</th>
                    <th scope="col">Progress</th>
                  </tr>
                </thead>
                <tbody align="center">
                  <tr>
                    <td>
                      {" "}
                      <div
                        className="spinner-border text-primary"
                        id="spinner"
                        role="status"
                      ></div>
                      <div>Loading...</div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : (
            <NotFound
              message={
                "No job found in Active jobs, try searching in Archived or Deleted jobs"
              }
            />
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default DashBoardListLayout;
