import { useEffect, useState, memo, useCallback } from "react";
import classnames from "classnames";
import ChecklistsPage from "pages/jobs/jobCreation/checklist/ChecklistsPage";
import RouteLeavingGuard from "pages/jobs/jobCreation/RouteLeavingGuard";
import JobLeavingGuard from "pages/jobs/jobCreation/JobLeavingGuard";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Alert,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  setAreaChecklistFlag,
  updateAreaChecklist,
  deleteChecklists,
  replicateChecklistsInDb,
  fetchDraftRefFromDb,
} from "services";
import "./ChecklistNew.scss";
import {
  INTERNAL,
  EXTERNAL,
  BELOW_GROUND,
  DRAFTS_DB_NAME,
  INSPECTION_DB_NAME,
  RESIDENTIAL_CATEGORY,
  COMMERCIAL_CATEGORY,
  INFRASTRUCTURE_CATEGORY,
} from "../../../../common/constants";

const RoomTab = memo(({ isActive, setRoomId, isFlagged, areaName, roomId }) => {
  return (
    <NavItem>
      <NavLink
        style={{ cursor: "pointer" }}
        className={classnames({
          active: isActive,
        })}
        onClick={() => setRoomId(roomId)}
      >
        {areaName}
        <span className={isFlagged && "yellow-dot"} />
      </NavLink>
    </NavItem>
  );
});

export default function CheckListNew(props) {
  const { isDirty, setDirty, newJob } = props;
  const { setIsWizardExited, isWizardExited, collectionName } = props;
  const [activeTab, setActiveTab] = useState("0");
  const history = useHistory();

  const [internalAreas, setInternalAreas] = useState([]);
  const [externalAreas, setExternalAreas] = useState([]);
  const [belowAreas, setBelowAreas] = useState([]);

  const [internalAreaId, setInternalAreaId] = useState(null);
  const [externalAreaId, setExternalAreaId] = useState(null);
  const [belowAreaId, setBelowAreaId] = useState(null);

  const [internalRoomId, setInternalRoomId] = useState(null);
  const [externalRoomId, setExternalRoomId] = useState(null);
  const [belowRoomId, setBelowRoomId] = useState(null);

  const [isNext, setIsNext] = useState(false);
  const [flaggedRooms, setFlaggedRooms] = useState([]);

  const [areasWithChecklist, setAreasWithChecklist] = useState({});

  const hasInternal = newJob.jobType.internal;
  const hasExternal = newJob.jobType.external;
  const hasBelow = newJob.jobType.belowground;

  useEffect(async () => {
    const docRef = fetchDraftRefFromDb(newJob.id, collectionName);
    docRef.onSnapshot(doc => {
      if (!doc.exists) return;
      setAreasWithChecklist(doc.data().areas);
    });
  }, []);

  useEffect(() => {
    props.validateParticipants(props.newJob.participants);
  }, [props.newJob.participants]);

  useEffect(() => {
    if (props.newJob.jobType.internal) {
      toggle("1");
    } else if (props.newJob.jobType.external) {
      toggle("2");
    } else if (props.newJob.jobType.belowground) {
      toggle("3");
    }
  }, [props.newJob.jobType]);

  useEffect(() => {
    if (props.newJob?.rooms?.internal) {
      setInternalAreas(props.newJob?.rooms?.internal);
      if (!internalAreaId) {
        setInternalAreaId(props.newJob?.rooms?.internal[0]?.id);
        setInternalRoomId(
          props.newJob?.rooms?.internal[0]?.subItems[0]?.id || null
        );
      }
    }
    if (props.newJob?.rooms?.external) {
      setExternalAreas(props.newJob?.rooms?.external);
      if (!externalAreaId) {
        setExternalAreaId(props.newJob?.rooms?.external[0]?.id);
        setExternalRoomId(
          props.newJob?.rooms?.external[0]?.subItems[0]?.id || null
        );
      }
    }
    if (props.newJob?.rooms?.belowground) {
      setBelowAreas(props.newJob?.rooms?.belowground);
      if (!belowAreaId) {
        setBelowAreaId(props.newJob?.rooms?.belowground[0]?.id);
        setBelowRoomId(
          props.newJob?.rooms?.belowground[0]?.subItems[0]?.id || null
        );
      }
    }
  }, [props.newJob.rooms]);

  useEffect(() => {
    const isInternalValid = hasCheckList(props.newJob.rooms.internal);
    const isExternalValid = hasCheckList(props.newJob.rooms.external);
    const isBelowgroundValid = hasCheckList(props.newJob.rooms.belowground);
    const isNext =
      isInternalValid &&
      isExternalValid &&
      isBelowgroundValid &&
      flaggedRooms.length === 0;
    setIsNext(isNext);
    if (isNext) {
      setDirty(false);
    }
  }, [props.newJob, flaggedRooms, areasWithChecklist]);

  const toggle = useCallback(
    tab => {
      if (activeTab !== tab) {
        setActiveTab(tab);
        switch (tab) {
          case "1":
            if (!externalAreaId)
              setExternalAreaId(newJob?.rooms?.external[0]?.id);
            if (!externalRoomId)
              setExternalRoomId(
                newJob?.rooms?.external[0]?.subItems[0]?.id || null
              );
          case "2":
            if (!belowAreaId) setBelowAreaId(newJob?.rooms?.belowground[0]?.id);
            if (!belowRoomId)
              setBelowRoomId(
                newJob?.rooms?.belowground[0]?.subItems[0]?.id || null
              );
          default:
            if (!internalAreaId)
              setInternalAreaId(newJob?.rooms?.internal[0]?.id);
            if (!internalRoomId)
              setInternalRoomId(
                newJob?.rooms?.internal[0]?.subItems[0]?.id || null
              );
            break;
        }
      }
    },
    [newJob, activeTab]
  );

  const hasCheckList = useCallback(
    floors => {
      if (!floors.length) return true;
      return floors.reduce((isFloorHasChecklists, floor) => {
        const emptyChecklist = floor?.subItems?.filter(room => {
          if (room.length == 0) return false;
          return !areasWithChecklist[room.id]?.isChecklistExists;
        });
        return (
          isFloorHasChecklists &&
          emptyChecklist.length == 0 &&
          floor.subItems?.length != 0
        );
      }, true);
    },
    [areasWithChecklist]
  );

  useEffect(() => {
    const isInternalValid = hasCheckList(props.newJob.rooms.internal);
    const isExternalValid = hasCheckList(props.newJob.rooms.external);
    const isBelowgroundValid = hasCheckList(props.newJob.rooms.belowground);
    setIsNext(isInternalValid && isExternalValid && isBelowgroundValid);
  }, [props.newJob]);

  const replicateChecklist = useCallback(
    async (
      checklist,
      roomName,
      checklistName,
      type,
      currentAreaId,
      templateId
    ) => {
      const roomIdsWithSameName = [];
      // const jobChecklists = newJob.checklist;
      if (type === INTERNAL) {
        newJob.rooms?.internal?.forEach(floor => {
          floor.subItems?.forEach(room => {
            if (room.content.toLowerCase().includes(roomName.toLowerCase())) {
              roomIdsWithSameName.push(room.id);
            }
          });
        });
      }
      if (type === EXTERNAL) {
        newJob.rooms?.external?.forEach(floor => {
          floor.subItems?.forEach(room => {
            if (room.content.toLowerCase().includes(roomName.toLowerCase())) {
              roomIdsWithSameName.push(room.id);
            }
          });
        });
      }
      if (type === BELOW_GROUND) {
        newJob.rooms?.belowground?.forEach(floor => {
          floor.subItems?.forEach(room => {
            if (room.content.toLowerCase().includes(roomName.toLowerCase())) {
              roomIdsWithSameName.push(room.id);
            }
          });
        });
      }
      try {
        await replicateChecklistsInDb(
          newJob.id,
          roomIdsWithSameName,
          checklist,
          checklistName,
          currentAreaId,
          templateId,
          collectionName
        );
        setFlaggedRooms(flaggedRooms => {
          return flaggedRooms.filter(
            _areaId => !roomIdsWithSameName.includes(_areaId)
          );
        });
        toast.success(`Replicated the checklist in all ${roomName}s`, {
          autoClose: 2000,
        });
      } catch (err) {
        console.error(err);
        toast.error(`Failed to replicate in all ${roomName}s`, {
          autoClose: 2000,
        });
      }
    },
    [newJob]
  );

  const onSave = useCallback(() => {
    setDirty(false);
  }, []);

  const saveChecklist = useCallback(
    async (checklistData, areaId) => {
      await setAreaChecklistFlag(newJob.id, collectionName, [areaId]);
      await updateAreaChecklist(
        newJob.id,
        collectionName,
        checklistData,
        areaId
      );
      setDirty(false);
    },
    [newJob.id]
  );

  useEffect(() => {
    setDirty(false);
  }, [internalRoomId, externalRoomId, belowRoomId]);

  const clearChecklist = useCallback(async areaId => {
    setDirty(false);
    deleteChecklists(newJob.id, collectionName, [areaId]);
  }, []);

  const onNext = useCallback(() => {
    onSave();
    props.next();
  }, []);

  const onPrev = useCallback(() => {
    props.prev();
  }, []);

  const isTabFlagged = useCallback(
    areaId => {
      return (
        flaggedRooms?.find(_areaId => _areaId === areaId) ||
        !(
          areasWithChecklist[areaId] &&
          areasWithChecklist[areaId]?.isChecklistExists
        )
      );
    },
    [areasWithChecklist, flaggedRooms]
  );

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  // CHECKLIST-TODO: Do not pass checklist to all ChecklistPage

  return (
    <>
      <RouteLeavingGuard
        when={isDirty}
        // Navigate function
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return isDirty;
        }}
        isChecklist={true}
        setWizardExited={setIsWizardExited}
        saveJob={onSave}
      />
      <JobLeavingGuard
        when={!isDirty && !isWizardExited}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return !isDirty && !isWizardExited;
        }}
      />
      <Card>
        <CardBody style={{ margin: "20px" }}>
          <Nav tabs>
            {hasInternal && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  {RESIDENTIAL_CATEGORY}
                </NavLink>
              </NavItem>
            )}
            {hasExternal && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  {COMMERCIAL_CATEGORY}
                </NavLink>
              </NavItem>
            )}
            {hasBelow && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  {INFRASTRUCTURE_CATEGORY}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab} className="p-3 text-muted">
            <TabPane tabId="1">
              {internalAreas && (
                <>
                  <Nav tabs>
                    {internalAreas.map(area => {
                      return (
                        <NavItem key={area.id}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: internalAreaId === area.id,
                            })}
                            onClick={() => {
                              setInternalAreaId(area.id);
                              setInternalRoomId(area.subItems[0]?.id || null);
                            }}
                          >
                            {area.content}
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                  <TabContent
                    activeTab={internalAreaId}
                    className="p-3 text-muted"
                  >
                    {internalAreas.length ? (
                      internalAreas.map(area => {
                        return (
                          <TabPane tabId={area.id} key={area.id}>
                            {internalAreaId === area.id && area.subItems && (
                              <>
                                <Nav tabs>
                                  {area?.subItems.length > 0 ? (
                                    area.subItems.map(room => {
                                      return (
                                        <RoomTab
                                          areaName={room.content}
                                          isFlagged={isTabFlagged(room.id)}
                                          isActive={internalRoomId === room.id}
                                          roomId={room.id}
                                          setRoomId={setInternalRoomId}
                                        />
                                      );
                                    })
                                  ) : (
                                    <Alert color="warning">
                                      No areas found.
                                    </Alert>
                                  )}
                                </Nav>
                                <TabContent
                                  activeTab={internalRoomId}
                                  className="p-3 text-muted"
                                >
                                  {area.subItems?.map(room => {
                                    return (
                                      <TabPane tabId={room.id} key={room.id}>
                                        {internalRoomId === room.id && (
                                          <ChecklistsPage
                                            setIsDirty={setDirty}
                                            setFlaggedRooms={setFlaggedRooms}
                                            onClear={clearChecklist}
                                            onSave={saveChecklist}
                                            roomId={room.id}
                                            jobDocId={newJob.id}
                                            type={INTERNAL}
                                            roomName={room.content}
                                            replicateChecklist={
                                              replicateChecklist
                                            }
                                          />
                                        )}
                                      </TabPane>
                                    );
                                  })}
                                </TabContent>
                              </>
                            )}
                          </TabPane>
                        );
                      })
                    ) : (
                      <Alert color="danger">
                        Please add internal floor areas
                      </Alert>
                    )}
                  </TabContent>
                </>
              )}
            </TabPane>
            <TabPane tabId="2">
              {externalAreas && (
                <>
                  <Nav tabs>
                    {externalAreas?.map(area => {
                      return (
                        <NavItem key={area.id}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: externalAreaId === area.id,
                            })}
                            onClick={() => {
                              setExternalAreaId(area.id);
                              setExternalRoomId(area.subItems[0]?.id || null);
                            }}
                          >
                            {area.content}
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                  <TabContent
                    activeTab={externalAreaId}
                    className="p-3 text-muted"
                  >
                    {externalAreas?.length ? (
                      externalAreas?.map(area => {
                        return (
                          <TabPane tabId={area?.id} key={area?.id}>
                            {externalAreaId === area?.id && area?.subItems && (
                              <>
                                <Nav tabs>
                                  {area?.subItems?.map(room => {
                                    return (
                                      <RoomTab
                                        areaName={room.content}
                                        isFlagged={isTabFlagged(room.id)}
                                        isActive={externalRoomId === room.id}
                                        roomId={room.id}
                                        setRoomId={setExternalRoomId}
                                      />
                                    );
                                  })}
                                </Nav>
                                <TabContent
                                  activeTab={externalRoomId}
                                  className="p-3 text-muted"
                                >
                                  {area?.subItems.length > 0 ? (
                                    area?.subItems?.map(room => {
                                      return (
                                        <TabPane tabId={room.id} key={room.id}>
                                          {externalRoomId === room.id && (
                                            <ChecklistsPage
                                              setIsDirty={setDirty}
                                              setFlaggedRooms={setFlaggedRooms}
                                              onClear={clearChecklist}
                                              onSave={saveChecklist}
                                              jobDocId={newJob.id}
                                              roomId={room.id}
                                              type={EXTERNAL}
                                              roomName={room.content}
                                              replicateChecklist={
                                                replicateChecklist
                                              }
                                            />
                                          )}
                                        </TabPane>
                                      );
                                    })
                                  ) : (
                                    <Alert color="warning">
                                      No areas found.
                                    </Alert>
                                  )}
                                </TabContent>
                              </>
                            )}
                          </TabPane>
                        );
                      })
                    ) : (
                      <Alert color="danger">
                        Please add external floor areas
                      </Alert>
                    )}
                  </TabContent>
                </>
              )}
            </TabPane>
            <TabPane tabId="3">
              {" "}
              {belowAreas && (
                <>
                  <Nav tabs>
                    {belowAreas.map(area => {
                      return (
                        <NavItem key={area.id}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: belowAreaId === area.id,
                            })}
                            onClick={() => {
                              setBelowAreaId(area.id);
                              setBelowRoomId(area.subItems[0]?.id || null);
                            }}
                          >
                            <span className="d-none d-sm-block">
                              {area.content}
                            </span>
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                  <TabContent
                    activeTab={belowAreaId}
                    className="p-3 text-muted"
                  >
                    {belowAreas.length ? (
                      belowAreas.map(area => {
                        return (
                          <TabPane tabId={area.id} key={area.id}>
                            {belowAreaId === area.id && area.subItems && (
                              <>
                                <Nav tabs>
                                  {area.subItems.map(room => {
                                    return (
                                      <RoomTab
                                        areaName={room.content}
                                        isFlagged={isTabFlagged(room.id)}
                                        isActive={belowRoomId === room.id}
                                        roomId={room.id}
                                        setRoomId={setBelowRoomId}
                                      />
                                    );
                                  })}
                                </Nav>
                                <TabContent
                                  activeTab={belowRoomId}
                                  className="p-3 text-muted"
                                >
                                  {area.subItems.length > 0 ? (
                                    area.subItems?.map(room => {
                                      return (
                                        <TabPane tabId={room.id} key={room.id}>
                                          {belowRoomId === room.id && (
                                            <ChecklistsPage
                                              setIsDirty={setDirty}
                                              setFlaggedRooms={setFlaggedRooms}
                                              onClear={clearChecklist}
                                              onSave={saveChecklist}
                                              jobDocId={newJob.id}
                                              roomId={room.id}
                                              type={BELOW_GROUND}
                                              roomName={room.content}
                                              replicateChecklist={
                                                replicateChecklist
                                              }
                                            />
                                          )}
                                        </TabPane>
                                      );
                                    })
                                  ) : (
                                    <Alert color="warning">
                                      No areas found.
                                    </Alert>
                                  )}
                                </TabContent>
                              </>
                            )}
                          </TabPane>
                        );
                      })
                    ) : (
                      <Alert color="danger">Please add floor area</Alert>
                    )}
                  </TabContent>
                </>
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>

      <br />
      <Row className="clearfix" style={{ marginBottom: "20px" }}>
        <div className="text-end">
          <button
            className="btn btn-primary w-md mtb-2"
            onClick={() => onPrev()}
            type="button"
          >
            Previous
          </button>
          <button
            disabled={isDirty}
            className="btn btn-primary w-md mtb-2 ml-20"
            onClick={() => onSave()}
            type="button"
          >
            Save
          </button>
          <button
            disabled={!isNext}
            className="btn btn-primary w-md ml-20 mtb-2"
            type="button"
            onClick={() => onNext()}
          >
            Next
          </button>
        </div>
      </Row>
    </>
  );
}
