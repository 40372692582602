
export const duplicateItemsInCheckList = items => {
  const copiedItems = _.cloneDeep(items);
  const checkListItems = copiedItems.filter(item => !item.holdPoint);
  const processedItems = checkListItems.map(item => {
    item.content = item.content.trim().toLowerCase();
    return item;
  });
  const counts = _.countBy(processedItems, "content");
  const duplicateValues = _.filter(
    processedItems,
    item => counts[item.content] > 1
  );
  return duplicateValues;
};
