import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import RouteLeavingGuard from "pages/jobs/jobCreation/RouteLeavingGuard";
import JobLeavingGuard from "pages/jobs/jobCreation/JobLeavingGuard";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Form, Row } from "reactstrap";
import { updateJobInDb } from "services";
import belowLogo from "../../../../assets/images/icons8/icons8-below.png";
import externalLogo from "../../../../assets/images/icons8/icons8-external.png";
import newHomeLogo from "../../../../assets/images/icons8/icons8-home-new.png";
import internalLogo from "../../../../assets/images/icons8/icons8-internal.png";
import rectifyLogo from "../../../../assets/images/icons8/icons8-rectify.png";
import renovateLogo from "../../../../assets/images/icons8/icons8-renovate.png";
import "../../jobCreation/jobCategory.css";
import { buildingClassDataMap } from "common/data/clases";
import { COMMERCIAL_CATEGORY, INFRASTRUCTURE_CATEGORY, RESIDENTIAL_CATEGORY } from "common/constants";

export default function JobCategory({
  job,
  setJob,
  next,
  isDirty,
  setDirty,
  setIsWizardExited,
  isWizardExited,
  collectionName,
  jobCategory,
  setJobCategory,
  jobType,
  setJobType,
  closeModal,
}) {
  const history = useHistory();
  const [isNext, setIsNext] = useState(false);

  useEffect(() => {
    const isValidInternalSelection = jobType?.internal
      ? jobCategory?.internal !== "none"
      : true;
    const isValidExternalSelection = jobType?.external
      ? jobCategory?.external !== "none"
      : true;
    const isValidBelowgroundSelection = jobType?.belowground
      ? jobCategory?.belowground !== "none"
      : true;

    const atleastOneJobCategorySelected =
      jobType?.internal || jobType?.external || jobType?.belowground;

    setIsNext(
      atleastOneJobCategorySelected &&
        isValidInternalSelection &&
        isValidExternalSelection &&
        isValidBelowgroundSelection
    );
  }, [job, jobCategory, jobType, job?.jobCategory, job?.jobType]);

  const onSave = () => {
    closeModal();
    setDirty(false);
    let updateJob = { ...job };
    updateJob.internal = {
      ...job.internal,
      buildingClass: buildingClassDataMap,
      jobCategory: jobCategory?.internal,
      rooms: job?.internal?.rooms ? [...job?.internal?.rooms] : [],
    };
    updateJob.external = {
      ...job.external,
      buildingClass: buildingClassDataMap,
      jobCategory: jobCategory?.external,
      rooms: job?.external?.rooms ? [...job?.external?.rooms] : [],
    };
    updateJob.belowground = {
      ...job.belowground,
      buildingClass: buildingClassDataMap,
      jobCategory: jobCategory?.belowground,
      rooms: job?.belowground?.rooms ? [...job?.belowground?.rooms] : [],
    };
    updateJob.jobType = jobType;
    setJob(updateJob);
    updateJobInDb(updateJob, collectionName);
  };

  const onNext = () => {
    next();
  };

  const JobTypeButton = ({label, logoSrc, onClick, jobTypeName,isDisabled }) => {
    const checked = jobType[jobTypeName.toLowerCase()];
    return (
      <div className={`jobTypeButton ${checked ? "jobTypeButtonChecked" : ""}`}>
        <img src={logoSrc} />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={onClick}
              className="checked"
              disabled={isDisabled}
            />
          }
          label={label}
          className="fontStyle"
        />
      </div>
    );
  };

  const JobCategoryButton = ({
    categoryName,
    logoSrc,
    jobTypeName,
    jobCategoryType,
    disabled,
  }) => {
    const onClick = e => {
      setJobCategory({
        ...jobCategory,
        [jobTypeName?.toLowerCase()]: jobCategoryType,
      });
      setDirty(true);
    };

    const checked = jobCategory[jobTypeName?.toLowerCase()] === jobCategoryType;

    return (
      <div
        className={`jobCategoryButton ${checked ? "jobCategorySelected" : ""}`}
      >
        <FormControlLabel
          control={
            <div>
              <Radio
                checked={checked}
                onChange={onClick}
                size="small"
                disabled={disabled}
                className="checked"
              />
              <img
                src={logoSrc}
                alt="Header Avatar"
                style={{ marginRight: "10px", width: "28px", height: "28px" }}
              />
            </div>
          }
          label={categoryName}
          className="fontStyle"
        />
      </div>
    );
  };

  const JobCategoriesTypes = ({ jobTypeName, hide }) => {
    return (
      <div className={hide ? "blur" : ""}>
        <RadioGroup>
          <JobCategoryButton
            categoryName={"New construction"}
            logoSrc={newHomeLogo}
            jobTypeName={jobTypeName}
            jobCategoryType={"new"}
            disabled={hide}
          />
          <JobCategoryButton
            categoryName={"Renovation"}
            logoSrc={renovateLogo}
            jobTypeName={jobTypeName}
            jobCategoryType={"renovation"}
            disabled={hide}
          />
          <JobCategoryButton
            categoryName={"Remediation"}
            logoSrc={rectifyLogo}
            jobTypeName={jobTypeName}
            jobCategoryType={"Remediation"}
            disabled={hide}
          />
        </RadioGroup>
      </div>
    );
  };

  const InternalJobType = () => {
    return (
      !!job &&
      !!jobType &&
      !!jobCategory && (
        <div className="jobTypeContainer">
          <JobTypeButton
            label={RESIDENTIAL_CATEGORY}
            jobTypeName={"Internal"}
            logoSrc={internalLogo}
            onClick={e => {
              setJobType({ ...jobType, internal: !jobType?.internal });
              if (jobType?.internal) {
                setJobCategory({ ...jobCategory, internal: "none" });
              }
              setDirty(true);
            }}
            isDisabled={job?.jobType?.internal}
          />

          <JobCategoriesTypes
            jobCategory={jobCategory?.internal}
            jobTypeName={"internal"}
            hide={!jobType?.internal}
          />
        </div>
      )
    );
  };

  const ExternalJobType = () => {
    return (
      !!job &&
      !!jobType &&
      !!jobCategory && (
        <div className="jobTypeContainer">
          <JobTypeButton
            label={COMMERCIAL_CATEGORY}
            jobTypeName={"External"}
            logoSrc={externalLogo}
            onClick={e => {
              setJobType({ ...jobType, external: !jobType?.external });
              if (jobType?.external) {
                setJobCategory({ ...jobCategory, external: "none" });
              }
              setDirty(true);
            }}
            isDisabled={job?.jobType?.external}
          />

          <JobCategoriesTypes
            jobCategory={jobCategory?.external}
            jobTypeName={"external"}
            hide={!jobType?.external}
          />
        </div>
      )
    );
  };

  const BelowGroundJobType = () => {
    return (
      !!job &&
      !!jobType &&
      !!jobCategory && (
        <div className="jobTypeContainer">
          <JobTypeButton
            label={INFRASTRUCTURE_CATEGORY}
            jobTypeName={"Belowground"}
            logoSrc={belowLogo}
            onClick={e => {
              setJobType({
                ...jobType,
                belowground: !jobType?.belowground,
              });
              if (jobType?.belowground) {
                setJobCategory({ ...jobCategory, belowground: "none" });
              }
              setDirty(true);
            }}
            isDisabled={job?.jobType?.belowground}
          />

          <JobCategoriesTypes
            jobCategory={jobCategory?.belowground}
            jobTypeName={"belowground"}
            hide={!jobType?.belowground}
          />
        </div>
      )
    );
  };

  const PageNavigationButtons = () => {
    return (
      <Row className="clearfix mt-3" style={{ marginBottom: "20px" }}>
        <div className="text-end">
          <button
            disabled={!isNext}
            className="btn btn-primary w-md mtb-2 ml-20"
            onClick={() => onSave()}
            type="button"
          >
            Save
          </button>

          <button
            disabled={!isNext}
            className="btn btn-primary w-md ml-20"
            type="button"
            onClick={() => onNext()}
          >
            Next
          </button>
        </div>
      </Row>
    );
  };

  return (
    <>
      <RouteLeavingGuard
        when={isDirty}
        // Navigate function
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return isDirty;
        }}
        setWizardExited={setIsWizardExited}
      />
      <JobLeavingGuard
        when={!isDirty && !isWizardExited}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return !isDirty && !isWizardExited;
        }}
      />
      <Form>
        <Card>
          <CardBody style={{ margin: "20px" }}>
            <Typography className="fontStyle">
              Select the appropriate job category
            </Typography>
            <div className="typesContainer">
              <InternalJobType />
              <ExternalJobType />
              <BelowGroundJobType />
            </div>
          </CardBody>
        </Card>

        <PageNavigationButtons />
      </Form>
    </>
  );
}
