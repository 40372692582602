import { Row } from "reactstrap";

export const CustomPagination = ({
  current,
  onChange,
  resultsPerPage,
  totalPages,
}) => {
  return (
    <Row className="clearfix" style={{ marginBottom: "20px" }}>
      <div className="text-center">
        <button
          className="btn btn-primary w-md mtb-2"
          onClick={() => onChange(current - 1)}
          type="button"
          disabled={current === 1}
        >
          Previous
        </button>
        <button
          className="btn btn-primary  w-md ml-20 mtb-2"
          type="button"
          onClick={() => onChange(current + 1)}
          disabled={current === totalPages}
        >
          Next
        </button>
      </div>
    </Row>
  );
};
