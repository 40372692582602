import cuid from "cuid";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { Toast } from "reactstrap";
import {
  addNewJobReportTemplateToDB,
  updateJobReportTemplatesListInDb,
} from "services";

export function updateJobReportTemplate(
  Checklist,
  templateName,
  currentUserProfile,
  setShowOverrideChoiceAlert,
  setIsChecklistChanged,
  templateId,
  updateMyChecklist
) {
  return useCallback(() => {
    console.log("The checklist is:", templateId, templateName, Checklist);
    const updatedCheckList = {
      id: templateId,
      title: templateName,
      items: Checklist.items || Checklist,
    };
    updateJobReportTemplatesListInDb(
      updatedCheckList,
      templateId,
      currentUserProfile?.orgAccessId
    )
      .then(
        toast.success(`Template updated successfully`, {
          autoClose: 2000,
        })
      )
      .catch(e =>
        console.log(
          "There is some error in updating:",
          templateId,
          currentUserProfile?.orgAccessId,
          e
        )
      );
    setShowOverrideChoiceAlert(false);
    updateMyChecklist();
    setIsChecklistChanged(false);
    toast.success(`Template added successfully`, {
      autoClose: 2000,
    });
  }, [Checklist, templateName, currentUserProfile, Checklist]);
}
export function createNewJobReportTemplate(
  Checklist,
  setChecklist,
  currentUserProfile,
  setShowOverrideChoiceAlert,
  templateName,
  setTemplateId
) {
  return useCallback(
    templateName => {
      const newCheckList = {
        id: cuid(),
        items: Checklist.items || Checklist,
        templateName,
      };
      setChecklist({
        templateId: newCheckList.id,
        items: Checklist.items || Checklist,
        templateName,
      });
      addNewJobReportTemplateToDB(newCheckList, currentUserProfile?.orgAccessId)
        .then(() => {
          toast.success(`Template added successfully`, {
            autoClose: 2000,
          });
        })
        .catch(e => {
          toast.error(`Something went wrong!`, {
            autoClose: 2000,
          });
        });
      setTemplateId(_templateId => newCheckList.id);
      setShowOverrideChoiceAlert(false);
    },
    [Checklist, templateName, currentUserProfile]
  );
}
