import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import { cloneJob } from "pages/jobs/utils";
import { ARCHIVED_JOBS_DB_NAME, INSPECTION_DB_NAME } from "common/constants";

import { moveArchivedJobToActive,updateInspectionJobStatus } from "services";
import { fetchSingleDraftSuccess, setIsCloning } from "store/actions";

export default function ArchivedMenu({ setSearchTerm, job ,setJobUpdate,collectionName}) {
  const dispatch = useDispatch();
  const history = useHistory();
const pathName = collectionName === INSPECTION_DB_NAME  ? "newinspectionjob" : "newjob";
  const handleClone = async () => {
    dispatch(setIsCloning(true));
    const clonedJob = await cloneJob(job, collectionName);
    dispatch(setIsCloning(false));
    dispatch(fetchSingleDraftSuccess(clonedJob));
    history.push(`/${pathName}/${clonedJob.id}?type=cloned`);
    toast.success("Successfully cloned the job", {
      autoClose: 3000,
    });
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle href="#" className="card-drop" tag="i">
        <i className="mdi mdi-dots-horizontal font-size-18" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-start">
        <DropdownItem
          href="#"
          onClick={() => {
            setJobUpdate("active");
            { history?.location?.pathname === '/archived'  ? moveArchivedJobToActive(job) : updateInspectionJobStatus(job?.id,'jobs') }
            setSearchTerm("", { shouldClearFilters: false });
          }}
        >
          <i className="mdi mdi-pencil font-size-16 text-success me-1" /> Move
          to Active
        </DropdownItem>
        <DropdownItem href="#" onClick={handleClone}>
          <i className="mdi mdi-content-copy font-size-16 text-success me-1" />{" "}
          Clone
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}