import React from "react";
import cuid from "cuid";

const defaultCheckList = [
  {
    title: "SUBSTRATE ACCEPTANCE",
    listItems: [
      {
        id: cuid(),
        title:
          "Bathroom design is confirmed to be consistent with plan dimensions.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "En-suite design is confirmed to be consistent with plan dimensions.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Laundry design is confirmed to be consistent with plan dimensions.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Bathroom design is confirmed to be consistent with plan dimensions.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "All substrates are consistent with those listed on the plans.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "All substrates are sound and contaminant free.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Timber bottom plates and frames are securely fixed with no voids.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Timber bottom plates and frames are securely fixed with no voids.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Fixings and fasteners are flush with the finished surface.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Wall & floor junction does not exceed 15mm.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Wall & floor junction does not exceed 15mm.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Concrete, masonry and screeds have been assessed.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Structural falls have been assessed for compliance.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Have you been notified that the substrates are ready for WPM installation?",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "I accept and confirm the substrates have been installed to manufacturers specification.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: true,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Once you accept the substrate you own it.",
        checkBox: false,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: true,
      },
    ],
  },
  {
    title: "DRAINAGE OUTLETS",
    listItems: [
      {
        id: cuid(),
        title: "All outlets are consistent with those listed on the plans.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Leak control (Puddle) flanges are recessed and securely fixed down.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Strip/ channel drain is securely fixed down and in the correct location.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Overflow provisions have been identified and confirmed to be in the correct location.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "I accept and confirm the outlets are of the type to allow for sub-tile water release.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: true,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Any outlet should be installed to be at the lowest point of the WPM.",
        checkBox: false,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: true,
      },
    ],
  },
  {
    title: "WATER STOPS",
    listItems: [
      {
        id: cuid(),
        title: "Water stops confirmed to be installed at WPM stage.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Water stops confirmed to be installed at tiling stage.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
    ],
  },
  {
    title: "PENETRATIONS",
    listItems: [
      {
        id: cuid(),
        title:
          "All penetrations are consistent with those listed on the plans.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Strip/ channel drain is securely fixed down and in the correct location.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Overflow provisions have been identified and confirmed to be in the correct location.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "I accept and confirm the outlets are of the type to allow for sub-tile water release.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: true,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Spindle taps will require the membrane to be terminated to the tap body to allow the washer to be serviced, not to the spindle itself.",
        checkBox: false,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: true,
      },
    ],
  },

  {
    title: "WPM FLOOR INSTALLATION (Liquid)",
    listItems: [
      {
        id: cuid(),
        title:
          "WPM products confirmed and TDS have been supplied to all parties.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "WPM usage rates have been identified and agreed upon.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Moisture content has been taken and readings recorded for all substrates.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Wet film thickness readings have been taken and readings recorded.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Water stop installed at WPM stage.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Water stop installed at tiling stage.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "3 way corners and critical junctions.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "All parties have been notified for acceptance testing.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Providing airflow for the freshly membraned area will assist dramatically in speeding up the drying time between coats. Masking up the termination points of the membrane installation will assist in assessing the WPM Dry Film Thickness.",
        checkBox: false,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: true,
      },
    ],
  },
  {
    title: "WPM WALL INSTALLATION (Liquid)",
    listItems: [
      {
        id: cuid(),
        title:
          "WPM products confirmed and TDS have been supplied to all parties.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "WPM usage rates have been identified and agreed upon.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Moisture content has been taken and readings recorded for all substrates.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Wet film thickness readings have been taken and readings recorded.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Water stop installed at WPM stage.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Water stop installed at tiling stage.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "3 way corners and critical junctions.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "All parties have been notified for acceptance testing.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
    ],
  },
  {
    title: "WPM FLOOR INSTALLATION (Sheet)",
    listItems: [
      {
        id: cuid(),
        title:
          "WPM products confirmed and TDS have been supplied to all parties.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "WPM usage rates have been identified and agreed upon.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title:
          "Moisture content has been taken and readings recorded for all substrates.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Lap joints have been assessed with a seam probe.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Water stop installed at WPM stage.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "Water stop installed at tiling stage.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "3 way corners and critical junctions.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
      {
        id: cuid(),
        title: "All parties have been notified for acceptance testing.",
        checkBox: true,
        checked: false,
        documents: [],
        comment: true,
        comments: [],
        attachment: true,
        attachments: [],
        signature: false,
        signatures: [],
        holdPoint: false,
        helpPoint: false,
      },
    ],
  },
];

const testListItems = [
  {
    id: cuid(),
    content: "NEW CHECK ITEM 1",
    type: "checkbox",
    value: false,
  },
  {
    id: cuid(),
    content: "NEW CHECK ITEM 2",
    type: "checkbox",
    value: false,
  },
];
export default defaultCheckList;
