import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import check from "assets/images/icons8/icons8-check.png";
import { format } from "date-fns";
import {
  firstTableColHeaderStyle,
  firstTableColStyle,
  tableCellHeaderStyle,
  tableCellStyle,
  tableColHeaderStyle,
  tableColStyle,
  tableRowStyle,
  tableStyle,
} from "../../tableStyles";
import { DisplayImagesInRow } from "./ChecklistPhotos";
import {
  calaculateItemsStatus,
  inspectionChecklistSectionItems,
} from "pages/jobs/jobTypes/active/percentageCalculation";
import {
  ACTIVE_JOBS_DB_NAME,
  COMMERCIAL_CATEGORY,
  EXTERNAL,
  INFRASTRUCTURE_CATEGORY,
  INSPECTION_DB_NAME,
  INTERNAL,
  PLACE_AFTER_CHECKLIST_ITEM,
  PLACE_BEFORE_CHECKLIST_ITEM,
  RESIDENTIAL_CATEGORY,
} from "common/constants";
import DynamicJobReportTemplateChecklist from "../DynamicJobReportTemplateChecklist";
import { memo } from "react";
const styles = StyleSheet.create({
  sigText: {
    margin: 1,
    fontSize: 8,
    textAlign: "justify",
  },
  sigTimeText: {
    margin: 1,
    fontSize: 6,
    textAlign: "justify",
  },
  sigImage: {
    marginVertical: 2,
    marginHorizontal: 2,
    height: 100,
    width: 150,
  },
  container: {
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    flexGrow: 1,
  },
  checklistSectionItemsStatusHeading: {
    padding: 5,
    marginTop: 8,
    fontSize: 18,
    fontWeight: 600,
    color: "#ffffff",
    backgroundColor: "#89B27B",
  },
  statusText: {
    color: "white",
    fontSize: 14,
    marginTop: 15,
    marginLeft: 10,
    marginRight: 10,
    flex: 1,
    paddingBottom: 2,
    wordWrap: "break-word",
    textAlign: "center",
    padding: 5,
    alignContent: "center",
  },
  checklistItemHeading: {
    marginTop: 12,
    fontSize: 18,
    color: "black",
  },
  checklistPhotosHeading: {
    margin: 2,
    marginTop: 12,
    fontSize: 16,
    fontWeight: 600,
    color: "black",
  },
  status: {
    width: 12,
    height: 12,
  },
  signContainer: {
    flexDirection: "column",
  },
  title: {
    fontSize: 24,
    textAlign: "left",
    fontWeight: 700,
    color: "#004F6B",
  },
  photosContainer: {
    padding: "10px",
  },
  photosHeader: {
    fontWeight: 600,
  },
  imageStatus: {
    width: 30,
    height: 30,
    alignSelf: "center",
    padding: "5px",
  },
  horizontalLine: {
    borderBottom: 1,
    borderColor: "gray",
    width: "100%",
  },
  itemNotes: {
    fontSize: 14,
    margin: 5,
    textAlign: "justify",
  },
  sectionContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: -45,
    position: "relative",
  },
  itemContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: -40,
  },
  table: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    border: "none",
  },
  cell: {
    padding: 5,
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: 5,
    backgroundColor: "gray",
    border: "none",
  },
  lastCell: {
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 5,
    backgroundColor: "gray",
    border: "none",
  },
  cellText: {
    color: "black",
  },
  checklistContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    backgroundColor: "#89B27B",
    marginTop: 10,
  },
  checklistSectionHeading: {
    flex: 7,
    textAlign: "left",
    fontSize: 18,
    fontWeight: "600",
    color: "#ffffff",
    backgroundColor: "#1F4E68",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  checklistSectionStatusHeading: {
    flex: 3,
    textAlign: "left",
    color: "#ffffff",
    fontWeight: "600",
    fontSize: 18,
    backgroundColor: "#89B27B",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  checklistSectionText: {
    paddingBottom: 5,
  },
  inspectionItems: {
    fontSize: 12,
    textAlign: "left",
    color: "black",
  },
});

const ChecklistInfoHeader = memo(() => {
  console.log("renderHeader")
  return (
    <>
      <View style={tableRowStyle}>
        <View style={firstTableColHeaderStyle}>
          <Text style={{ ...tableCellHeaderStyle, color: "#004F6B" }}>
            Job Category
          </Text>
        </View>
        <View style={{ ...tableColHeaderStyle, width: "40%" }}>
          <Text style={{ ...tableCellHeaderStyle, color: "#004F6B" }}>
            Floor Level
          </Text>
        </View>
        <View style={{ ...tableColHeaderStyle, width: "40%" }}>
          <Text style={{ ...tableCellHeaderStyle, color: "#004F6B" }}>
            Area(s)
          </Text>
        </View>
      </View>
      <View style={styles.horizontalLine} />
    </>
  );
});
export const getJobCategory = jobType => {
  if (jobType === INTERNAL) {
    return RESIDENTIAL_CATEGORY;
  }
  if (jobType === EXTERNAL) {
    return COMMERCIAL_CATEGORY;
  }
  return INFRASTRUCTURE_CATEGORY;
};
const CertificateCheckListSection = ({ certificate, collectionName }) => {
  const rows = certificate.rooms.map((area, rowIndex) =>
    area.subItems?.map((room, columnIndex) => (
      <View key={room.id} break={rowIndex + columnIndex == 0 ? false : true}>
        <Text style={styles.title}>Checklist Details</Text>
        <View>
          <View style={tableStyle}>
            <ChecklistInfoHeader />
            <View key={room.id} style={tableRowStyle}>
              <View style={firstTableColStyle}>
                <Text style={tableCellStyle}>{getJobCategory(certificate.jobType)}</Text>
              </View>
              <View style={{ ...tableColStyle, width: "40%" }}>
                <Text style={tableCellStyle}>{area.content}</Text>
              </View>
              <View style={{ ...tableColStyle, width: "40%" }}>
                <Text style={tableCellStyle}>{room.content}</Text>
              </View>
            </View>
          </View>
        </View>
        {collectionName === INSPECTION_DB_NAME && (
          <CertificateInspectionChecklistSection
            checklist={certificate[room.id]?.items}
            collectionName={collectionName}
            itemStatus={certificate?.includeItemStatus}
          />
        )}
        {collectionName === ACTIVE_JOBS_DB_NAME && (
          <CertificateChecklistSection
            checklist={certificate[room.id]?.items}
            collectionName={collectionName}
            itemStatus={certificate?.includeItemStatus}
          />
        )}
      </View>
    ))
  );
  return <>{rows}</>;
};

export default memo(CertificateCheckListSection);

const showPassItems = item => {
  const result = calaculateItemsStatus(item);
  return `${result.checkedItems}/${result.totalItems} `;
};
const CertificateChecklistSection = memo(({
  checklist,
  collectionName,
  itemStatus,
}) => {
  const rows = checklist?.map((item, i) => (
    <View key={item.id}>
      <View style={styles.checklistContainer} wrap>
        <Text style={styles.checklistSectionHeading} wrap break>
          {i + 1 + ". " + item.content}
        </Text>
        {/* <Text style={styles.checklistSectionStatusHeading} wrap break>
          Pass Items: {showPassItems(item)}
        </Text> */}
      </View>

      {item.subItems.length ? (
        <>
          <CertificateCheckItemRow
            items={item.subItems}
            index={i + 1}
            collectionName={collectionName}
            itemStatus={itemStatus}
          />
        </>
      ) : null}
    </View>
  ));
  return <>{rows}</>;
});
const SectionInspectionSummary = memo(({ section }) => (
  <View style={styles.table}>
    {/* <View
      style={{ ...styles.cell, backgroundColor: "gray", borderColor: "gray" }}
    >
      <Text style={styles.inspectionItems}>Summary:</Text>
    </View> */}
    <View
      style={{
        ...styles.cell,
        backgroundColor: "#1F4E681A",
        borderColor: "#1F4E681A",
      }}
    >
      <Text style={styles.inspectionItems}>
        Total Assesments : {section.totalItems}
      </Text>
    </View>
    <View
      style={{
        ...styles.cell,
        backgroundColor: "#89B27B",
        borderColor: "#89B27B",
      }}
    >
      <Text style={styles.inspectionItems}>Pass : {section.passedItems}</Text>
    </View>
    <View
      style={{
        ...styles.cell,
        backgroundColor: "#F0CF89",
        borderColor: "#F0CF89",
      }}
    >
      <Text style={styles.inspectionItems}>F/A : {section.furtherItems}</Text>
    </View>
    <View
      style={{
        ...styles.cell,
        backgroundColor: "#A7CBF7",
        borderColor: "#A7CBF7",
      }}
    >
      <Text style={styles.inspectionItems}>Done : {section.doneItems}</Text>
    </View>
    <View
      style={{
        ...styles.lastCell,
        backgroundColor: "#EF847E",
        borderColor: "#EF847E",
      }}
    >
      <Text style={styles.inspectionItems}>Fail : {section.failedItems}</Text>
    </View>
    <View style={{ ...styles.lastCell, backgroundColor: "#656466" }}>
      <Text style={styles.inspectionItems}>
        N/A : {section.notAppliedItems}
      </Text>
    </View>
  </View>
));
const CertificateInspectionChecklistSection = memo(({
  checklist,
  collectionName,
  itemStatus,
}) => {
  console.log("IRENDEREDDDDDDDDD")
  const rows = checklist?.map((item, i) => (
    <View key={item.id}>
      <View>
        <Text
          style={{
            textAlign: "left",
            fontSize: 18,
            fontWeight: "600",
            color: "#ffffff",
            backgroundColor: "#1F4E68",
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
            marginTop: 10,
            marginBottom: 10,
          }}
          wrap
          break
        >
          {i + 1 + ".  " + item.content}
        </Text>
        <SectionInspectionSummary
          section={inspectionChecklistSectionItems(item)}
        />
      </View>

      {item.subItems.length ? (
        <>
          <InspectionChecklistItemRow
            items={item.subItems}
            index={i + 1}
            collectionName={collectionName}
            itemStatus={itemStatus}
          />
        </>
      ) : null}
    </View>
  ));
  return <>{rows}</>;
});
const CertificateCheckItemRow = memo(({
  items,
  index,
  collectionName,
  itemStatus,
}) => {
  const rows = items?.map((item, i, itemsArray) => {
    return (
      <View>
        <View>
          <DynamicJobReportTemplateChecklist
            templates={item?.templates}
            position={PLACE_BEFORE_CHECKLIST_ITEM}
          />
        </View>

        <View key={item.id + (item?.title || item?.content)}>
          <View
            style={{ ...styles.checklistContainer, backgroundColor: "white" }}
            wrap
            break
          >
            <Text
              style={{ ...styles.checklistItemHeading, flex: 1 }}
              wrap
              break
            >
              {index + "." + (i + 1)}
            </Text>
            <Text
              style={{
                ...styles.checklistItemHeading,
                textAlign: "justify",
                flex: 8,
              }}
              wrap
              break
            >
              {item.content || item.title}
            </Text>
            {!item?.holdPoint &&
              (item.value === true && (
                <Text
                  style={{
                    ...styles.checklistSectionItemsStatusHeading,
                    ...styles.statusText,
                    backgroundColor: "#89B27B",
                    border: "1px solid #89B27B",
                  }}
                >
                  Pass
                </Text>
              ))}
          </View>
          <View>
            {item.type === "digitalsign" && item.signatures ? (
              <CertificateSigImageRow images={item.signatures} />
            ) : null}
          </View>
          {/* {!collectionName && (
            <View>
              <Text>
                {((item.holdPoint && item.signatures) || item.value) && (
                  <Image style={styles.status} src={check} />
                )}
              </Text>
            </View>
          )} */}
          <View>
            <Text style={styles.itemNotes}>
              {item.notes ? item.notes : "   "}
            </Text>
          </View>
        </View>
        <View key={i}>
          {item?.attachments?.length ? (
            <View style={styles.photosContainer}>
              <Text style={styles.checklistPhotosHeading}>{`${
                index + "." + (i + 1)
              } Photos`}</Text>
              <DisplayImagesInRow images={item.attachments} />
            </View>
          ) : null}
        </View>
        <View>
          <DynamicJobReportTemplateChecklist
            templates={item?.templates}
            position={PLACE_AFTER_CHECKLIST_ITEM}
          />
        </View>
      </View>
    );
  });

  return (
    <View style={tableStyle}>
      {/* <ChecklistItemsHeader collectionName={collectionName} itemStatus ={itemStatus}/> */}
      {rows}
    </View>
  );
});
const InspectionChecklistItemRow = memo(({
  items,
  index,
  collectionName,
  itemStatus,
}) => {
  const rows = items?.map((item, i, itemsArray) => {
    return (
      <View>
        <View>
          <DynamicJobReportTemplateChecklist
            templates={item?.templates}
            position={PLACE_BEFORE_CHECKLIST_ITEM}
          />
        </View>

        <View key={item.id + (item?.title || item?.content)}>
          <View
            style={{ ...styles.checklistContainer, backgroundColor: "white" }}
            wrap
            break
          >
            <Text
              style={{ ...styles.checklistItemHeading, flex: 1 }}
              wrap
              break
            >
              {index + "." + (i + 1)}
            </Text>
            <Text
              style={{
                ...styles.checklistItemHeading,
                textAlign: "justify",
                flex: 8,
              }}
              wrap
              break
            >
              {item.content || item.title}
            </Text>
            {!item?.holdPoint && (
              <>
                {item.value === "done" && (
                  <Text
                    style={{
                      ...styles.checklistSectionItemsStatusHeading,
                      ...styles.statusText,
                      backgroundColor: "#A7CBF7",
                      border: "1px solid #A7CBF7",
                    }}
                  >
                    Done
                  </Text>
                )}
                {item.value === "tick" && (
                  <Text
                    style={{
                      ...styles.checklistSectionItemsStatusHeading,
                      ...styles.statusText,
                      backgroundColor: "#89B27B",
                      border: "1px solid #89B27B",
                    }}
                  >
                    Pass
                  </Text>
                )}
                {item.value === "cross" && (
                  <Text
                    style={{
                      ...styles.checklistSectionItemsStatusHeading,
                      ...styles.statusText,
                      backgroundColor: "#EF847E",
                      border: "1px solid #EF847E",
                    }}
                  >
                    Fail
                  </Text>
                )}
                {item.value === "N/A" && (
                  <Text
                    style={{
                      ...styles.checklistSectionItemsStatusHeading,
                      ...styles.statusText,
                      backgroundColor: "#656466",
                      border: "1px solid #656466",
                    }}
                  >
                    N/A
                  </Text>
                )}
                {item.value === "F/A" && (
                  <Text
                    style={{
                      ...styles.checklistSectionItemsStatusHeading,
                      ...styles.statusText,
                      backgroundColor: "#F0CF89",
                      border: "1px solid #F0CF89",
                    }}
                  >
                    F/A
                  </Text>
                )}
              </>
            )}
          </View>
          <View>
            {item.type === "digitalsign" && item.signatures ? (
              <CertificateSigImageRow images={item.signatures} />
            ) : null}
          </View>
          {!collectionName && (
            <View>
              <Text>
                {((item.holdPoint && item.signatures) || item.value) && (
                  <Image style={styles.status} src={check} />
                )}
              </Text>
            </View>
          )}
          <View>
            <Text style={styles.itemNotes}>
              {item.notes ? item.notes : "   "}
            </Text>
          </View>
        </View>
        <View key={i}>
          {item?.attachments?.length ? (
            <View style={styles.photosContainer}>
              <Text style={styles.checklistPhotosHeading}>{`${
                index + "." + (i + 1)
              } Photos`}</Text>
              <DisplayImagesInRow images={item.attachments} />
            </View>
          ) : null}
        </View>
        <View>
          <DynamicJobReportTemplateChecklist
            templates={item?.templates}
            position={PLACE_AFTER_CHECKLIST_ITEM}
          />
        </View>
      </View>
    );
  });

  return (
    <View style={tableStyle}>
      {/* <ChecklistItemsHeader collectionName={collectionName} itemStatus ={itemStatus}/> */}
      {rows}
    </View>
  );
});

const CertificateSigImageRow = memo(({ images }) => {
  let chunk = 3;
  let res = [];
  for (let i = 0, j = images.length; i < j; i += chunk) {
    let temparray = images.slice(i, i + chunk);
    const rows = temparray.map((image, i) => {
      return (
        <View
          style={{ ...styles.sign, alignSelf: "flex-start" }}
          key={image?.id + i}
          className="no-page-break"
          wrap={false}
        >
          <Image key={image.id} style={styles.sigImage} src={image.url} />
          <Text style={styles.sigText}>{image.name}</Text>
          <Text style={styles.sigTimeText}>
            {format(new Date(image.date.seconds * 1000), "dd MMM yyyy hh:mm a")}
          </Text>
        </View>
      );
    });
    res.push(<View style={styles.signContainer}>{rows}</View>);
  }

  return <View style={styles.container}>{res}</View>;
});
