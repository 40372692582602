import SweetAlert from "react-bootstrap-sweetalert";
const CertificateGenerationErrorModal = ({
  showErrorModal,
  onClose,
}) => {
  return (
    <>
      {showErrorModal && (
        <SweetAlert
          error
          title={"Cerficate Generate error"}
          confirmBtnText="Close"
          onConfirm={() => {
            onClose();
          }}
        >
          <label className="floors-error">
            {`Unable to generate the  report as there are no floors added. To continue please add floors and try again.`}
          </label>
        </SweetAlert>
      )}
    </>
  );
};
export default CertificateGenerationErrorModal;
