import {
  getListContainerStyle,
  Reorder,
} from "common/checklistUtils/dndStyleUtil";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import cuid from "cuid";
import { Add, Upload } from "@mui/icons-material";
import AddSection from "pages/jobs/jobCreation/checklist/AddSection";
import ChecklistItemSection from "pages/jobs/jobCreation/checklist/ChecklistItemSection";
import "pages/jobs/jobCreation/checklist/CheckListPage.scss";
import { deleteChecklistAttachments } from "pages/jobs/utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  deleteMultipleSelectedChecklist,
  fetchChecklistsFromDB,
  fetchDefaultListItemsFromDb,
  fetchListItemsFromDb,
} from "services";
import {
  fetchCustomListItems,
  fetchCustomListItemsError,
  fetchCustomListItemsSuccess,
  fetchDefaultListItems,
  fetchDefaultListItemsError,
  fetchDefaultListItemsSuccess,
  fetchSavedChecklist,
  fetchSavedChecklistError,
  fetchSavedChecklistSuccess,
} from "store/actions";
import { duplicateItemsInCheckList } from "pages/jobs/jobCreation/checklist/duplicateItemsInCheckList";
import { toast } from "react-toastify";
import SavedCheckList from "../../../../checklistLibrary/CheckListSavedUnit";
import defaultCheckList from "pages/jobs/jobCreation/checklist/ChecklistDeafaultData";
import DeleteConfirmationPromptWithCheckBox from "components/Common/DeleteConfirmationPromptWIthCheckBox";
import CheckListParser from "pages/jobs/jobCreation/checklist/CheckListParser";

import { ExcelRenderer } from "react-excel-renderer";
export default function CheckListPage({
  checklistItems = null,
  onSave = null,
  onClose = null,
}) {
  const dispatch = useDispatch();
  const [checklistItemName, setChecklistItemName] = useState();
  const [addNewModal, setAddNewModal] = useState(false);
  const [activeTab, setactiveTab] = useState("1");
  const [itemSearch, setItemSearch] = useState("");
  const { customListItems, savedChecklists } = useSelector(
    state => state.setup
  );
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentChecklist, setCurrentChecklist] = useState(
    checklistItems?.map(item => Object.assign({}, item)) || []
  );
  const { currentUserProfile } = useSelector(state => state.profile);
  const [checklistSearch, setChecklistSearch] = useState("");
  const [allListItems, setAllListItems] = useState([]);
  const [myListItems, setMyListItems] = useState([]);
  const [selectedListItem, setSelectedListItem] = useState(null);
  const [isDirty, setDirty] = useState(false);
  const [isValidChecklist, setIsValidChecklist] = useState(true);
  const [attachmenstToDelete, setAttachmentsToDelete] = useState([]);
  const [myCheckLists, setMyCheckLists] = useState(defaultCheckList);
  const [
    isDuplicateCheckItemsInChecklist,
    setIsDuplicateCheckItemsInChecklist,
  ] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [duplicateItem, setDuplicateItem] = useState("");
  const [showCheckBox, setShowCheckBox] = useState(false);
  const fileInput = useRef(null);
  useFirestoreCollection({
    query: () => fetchDefaultListItemsFromDb(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchDefaultListItems()),
    onSuccess: docs => dispatch(fetchDefaultListItemsSuccess(docs)),
    onError: error => dispatch(fetchDefaultListItemsError(error)),
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () => fetchChecklistsFromDB(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchSavedChecklist()),
    onSuccess: docs => dispatch(fetchSavedChecklistSuccess(docs)),
    onError: error => dispatch(fetchSavedChecklistError(error)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchListItemsFromDb(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchCustomListItems()),
    onSuccess: docs => dispatch(fetchCustomListItemsSuccess(docs)),
    onError: error => dispatch(fetchCustomListItemsError(error)),
    deps: [dispatch],
  });

  useEffect(() => {
    let tempList = [...customListItems];
    tempList.sort((a, b) => {
      const aStr = a.title || a.content;
      const bStr = b.title || b.content;
      return aStr.localeCompare(bStr);
    });
    setAllListItems(tempList);
  }, [customListItems]);
  useEffect(() => {
    let newList = savedChecklists.filter(item => {
      const title = item?.title || item.content;
      return title?.toLowerCase().includes(checklistSearch.toLowerCase());
    });

    newList.sort((a, b) => {
      const aStr = a.title || a.content;
      const bStr = b.title || b.content;
      return aStr.localeCompare(bStr);
    });
    setMyCheckLists([...newList]);
  }, [checklistSearch, savedChecklists]);
  const isDuplicate = value => {
    if (customListItems.length <= 0) {
      return false;
    }
    let findOne = customListItems.filter(
      item =>
        item.title?.toLowerCase() === value.toLowerCase() ||
        item.content?.toLowerCase() === value.toLowerCase()
    );
    return findOne && findOne.length > 0;
  };
  const checkItemIsChecked = [...myCheckLists].find(item => item.checked);
  const openModalDlg = () => {
    setAddNewModal(true);
    setSelectedIds([]);
  };
  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const closeModalDlg = () => {
    setAddNewModal(false);
    setSelectedIds([]);
    setSelectedListItem(null);
  };
  const onCancel = useCallback(() => {
    [...myCheckLists].map(item => {
      item.checked = false;
    });
    setMyCheckLists([...myCheckLists]);
    setShowCheckBox(false);
  }, [myCheckLists]);

  const removeId = remId => {
    setSelectedIds([...selectedIds.filter(id => id !== remId)]);
    setDirty(true);
  };
  const openFileBrowser = useCallback(() => {
    fileInput.current.click();
  }, []);
  const addId = id => {
    setSelectedIds([...selectedIds, id]);
    setDirty(true);
  };

  const getItemById = id => {
    const [res] = myListItems.filter(item => item.id === id);
    return res;
  };

  useEffect(() => {
    if (itemSearch.length >= 1) {
      const newList = allListItems.filter(item => {
        const title = item.title || item.content;
        return title.toLowerCase().includes(itemSearch.toLowerCase());
      });
      setMyListItems([...newList]);
    } else {
      setMyListItems([]);
    }
  }, [itemSearch, allListItems]);

  const onSaveChecklist = async () => {
    let totalDuplicateListObjects = [];
    try {
      currentChecklist.forEach(item => {
        const data = duplicateItemsInCheckList(item.subItems);
        if (data.length) {
          totalDuplicateListObjects.push(data);
        }
      });
        await deleteChecklistAttachments(attachmenstToDelete);
        onSave(currentChecklist);
        setDirty(false);
        onClose();
    } catch (error) {
      console.log("on save checklist error: ", error);
    }
  };
  const renderFile = fileObj => {
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const mainList = CheckListParser(resp);
        if (currentChecklist.length > 0) {
          setCurrentChecklist([...currentChecklist, ...mainList]);
          return;
        }
        setCurrentChecklist([...mainList]);
      }
    });
  };
  const onChangeTextHeader = (text, id) => {
    const newItems = currentChecklist.map(item => {
      if (item.id === id) {
        const newItem = { ...item, content: text };
        return newItem;
      }
      return item;
    });
    setCurrentChecklist([...newItems]);
    setDirty(true);
  };

  const onChangeItemData = (data, id, parent) => {
    const newItems = currentChecklist.map(item => {
      if (item.id === parent) {
        const newItem = {
          ...item,
          subItems: item.subItems?.map(sub => {
            if (sub.id === id) {
              const newSub = { ...sub, ...data };
              return newSub;
            }
            return sub;
          }),
        };
        return newItem;
      }
      return item;
    });
    setCurrentChecklist([...newItems]);
    setDirty(true);
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      if (result.source.droppableId === "checklistitemsonly") {
        const subItem = getItemById(result.draggableId);
        const newSubItem = {
          ...subItem,
          id: cuid(),
          content: subItem.title || subItem.content,
          uploaded: false,
          isValidItem: true,
        };
        const newItem = {
          id: cuid(),
          subItems: [],
          content: "New Checklist Heading",
          type: "list",
          subItems: [newSubItem],
        };
        setCurrentChecklist([...currentChecklist, newItem]);
      }
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (result.type === "droppableItem") {
      // if source droppableid is droppable
      if (
        (result.source.droppableId === result.destination.droppableId &&
        result.source.droppableId === "checklistitemsonly" ) || (
        result.source.droppableId === result.destination.droppableId &&
        result.source.droppableId === "checklistonly" 
        )
      ) {
        console.log("do nothing");
        // do nothing
      } else if (result.source.droppableId === result.destination.droppableId) {
        const itemsNew = Reorder(currentChecklist, sourceIndex, destIndex);
        setCurrentChecklist(itemsNew);
        setDirty(true);
      } else {
        // get the list checklit
        const id = result.draggableId;
        const [selectedChecklist] = savedChecklists.filter(
          item => id === item.id
        );
        setCurrentChecklist([...currentChecklist, ...selectedChecklist.items]);
        setDirty(true);
      }
    } else if (result.type === "droppableSubItem") {
      if (
        (result.destination.droppableId === "checklistitemsonly" ||
          result.destination.droppableId === "checklistspecialitemsonly") &&
        (result.source.droppableId === "checklistitemsonly" ||
          result.source.droppableId === "checklistspecialitemsonly")
      ) {
        console.log("do nothing");
        // Do nothing
      } else {
        const itemSubItemMap = currentChecklist.reduce((acc, item) => {
          acc[item.id] = item.subItems;
          return acc;
        }, {});

        const sourceParentId = result.source.droppableId;
        const destParentId = result.destination.droppableId;

        const destSubItems = itemSubItemMap[destParentId];
        let newItems = [...currentChecklist];

        if (result.source.droppableId === "checklistitemsonly") {
          const subItem = getItemById(result.draggableId);
          const newSubItem = {
            ...subItem,
            id: cuid(),
            content: subItem.title || subItem.content,
            uploaded: false,
            type: "checkbox",
            isValidItem: true,
          };
          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, newSubItem);
          newItems = newItems.map(item => {
            if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setCurrentChecklist(newItems);
          setDirty(true);
          return;
        }

        if (result.source.droppableId === "checklistspecialitemsonly") {
          const newSubItem = {
            id: cuid(),
            checkBox: false,
            checked: false,
            documents: [],
            comment: false,
            comments: [],
            attachment: false,
            attachments: [],
            signature: true,
            signatures: [],
            holdPoint: true,
            helpPoint: false,
            locked: false,
            content: "Hold Point",
            uploaded: false,
            type: "digitalsign",
            isValidItem: true,
          };
          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, newSubItem);
          newItems = newItems.map(item => {
            if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setCurrentChecklist(newItems);
          setDirty(true);
          return;
        }

        const sourceSubItems = itemSubItemMap[sourceParentId];

        /** In this case subItems are reOrdered inside same Parent */
        if (sourceParentId === destParentId) {
          const reorderedSubItems = Reorder(
            sourceSubItems,
            sourceIndex,
            destIndex
          );
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = reorderedSubItems;
            }
            return item;
          });
          setCurrentChecklist(newItems);
        } else {
          let newSourceSubItems = [...sourceSubItems];
          const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, draggedItem);
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = newSourceSubItems;
            } else if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setCurrentChecklist(newItems);
          setDirty(true);
        }
      }
    }
  };
  const fileHandler = useCallback(
    event => {
      if (event.target.files.length) {
        let fileObj = event.target.files[0];
        let fileName = fileObj.name;

        //check for file extension and pass only if it is .xlsx and display error message otherwise
        if (fileName.slice(fileName.lastIndexOf(".") + 1) === "xlsx") {
          // this.setState({
          //   uploadedFileName: fileName,
          //   isFormInvalid: false,
          // });
          renderFile(fileObj);
          setDirty(true);
        } else {
          console.log("Error, file type not supported");
        }
      }
    },
    [currentChecklist]
  );
  const deleteSelectedChecklist = useCallback(async () => {
    const newChecklists = [
      ...myCheckLists.filter(item => item.checked === true),
    ];
    const checklistIds = newChecklists.map(item => item.id);
    const toastMessage =
      checklistIds.length === 1
        ? "The checklist is deleted!"
        : "The checklists are deleted!";

    setShowDeleteAlert(false);
    await deleteMultipleSelectedChecklist(
      checklistIds,
      currentUserProfile?.orgAccessId
    )
      .then(() => {
        toast.success(toastMessage, {
          autoClose: 2000,
        });
      })
      .catch(() => {
        toast.error("Failed while deleting the checklist", {
          autoClose: 2000,
        });
      });
  }, [myCheckLists]);
  const onRemoveCheckListGroup = async id => {
    let attachments = [];
    let newItems = [
      ...currentChecklist.filter(item => {
        if (item.id === id) {
          item.subItems.forEach(async subItem => {
            attachments = [...attachments, ...subItem.attachments];
          });
        }
        return item.id !== id;
      }),
    ];
    setAttachmentsToDelete([...attachmenstToDelete, ...attachments]);
    setCurrentChecklist(newItems);
    setDirty(true);
  };

  const onRemoveCheckListItem = id => {
    setCurrentChecklist([
      ...currentChecklist.map(item => {
        return {
          ...item,
          subItems: item.subItems.filter(subItem => {
            if (subItem.id === id) {
              setAttachmentsToDelete([
                ...attachmenstToDelete,
                ...subItem?.attachments,
              ]);
            }
            return subItem.id !== id;
          }),
        };
      }),
    ]);
    setDirty(true);
  };

  const onClear = () => {
    let attachments = [];
    currentChecklist.forEach(item => {
      item.subItems.forEach(subItem => {
        attachments = [...attachments, ...subItem?.attachments];
      });
    });
    setAttachmentsToDelete(attachments);
    setCurrentChecklist([]);
  };
  useEffect(() => {
    const isValid = currentChecklist.reduce((isSectionValid, item) => {
      return (
        isSectionValid &&
        item?.subItems?.reduce(
          (isItemsValid, subItem) =>
            subItem.isValidItem &&
            isItemsValid &&
            (subItem.content || subItem.title).trim().length != 0,
          true
        )
      );
    }, true);
    setIsValidChecklist(isValid);
  }, [JSON.stringify(currentChecklist)]); // for checking items deep equality

  return (
    <React.Fragment>
      <Modal isOpen={addNewModal} size="md">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {"New Checklist Item"}
          </h5>
          <button
            type="button"
            onClick={closeModalDlg}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>
            Would you like to add this newly created item to your current
            checklist?
          </h5>
          <p>{selectedListItem?.title}</p>
          {currentChecklist?.map(item => {
            return (
              <div key={item.id + item.title || item.content}>
                <Input
                  type="checkbox"
                  onChange={e => {
                    if (!e.target.checked) {
                      removeId(item.id);
                    } else {
                      addId(item.id);
                    }
                  }}
                ></Input>
                {"  "}
                {item.content}
              </div>
            );
          })}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              currentChecklist.map(item => {
                if (selectedIds.includes(item.id)) {
                  item.subItems = [
                    ...item.subItems,
                    { ...selectedListItem, id: cuid(), uploaded: false },
                  ];
                }
                return item;
              });
              closeModalDlg();
              toast.success("New CheckList Item added Successfully", {
                autoClose: 2000,
              });
            }}
            className="btn btn-success float-left"
            data-dismiss="modal"
            disabled={selectedIds.length > 0 ? true : false}
          >
            Add
          </button>
          <button
            type="button"
            onClick={closeModalDlg}
            className="btn btn-secondary float-left"
            data-dismiss="modal"
          >
            No
          </button>
        </div>
      </Modal>
      <Container fluid>
        <Row>
          <DragDropContext onDragEnd={onDragEnd}>
            <Col lg="7" md="7" sm="12" xs="12">
              <Row className="builder-actions">
                <Label className="builder-actions__name">
                  ITP - Inspection Test Plan Checklist
                </Label>
                <Label
                  className="builder-actions__label"
                  onClick={openFileBrowser}
                >
                  <Upload fontSize="small" />
                  <span className="text-with-icon">Upload Checklist</span>
                </Label>
                <input
                  type="file"
                  hidden
                  onChange={fileHandler}
                  ref={fileInput}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onClick={event => {
                    event.target.value = null;
                  }}
                  style={{ padding: "10px" }}
                />
                <Label
                  className="builder-actions__label mt-1 mb-1"
                  onClick={() => {
                    toast.success("Section added successfully", {
                      autoClose: 2000,
                    });
                    const newItem = {
                      id: cuid(),
                      subItems: [],
                      content: "New Checklist Heading",
                      type: "list",
                    };
                    setCurrentChecklist([...currentChecklist, newItem]);
                    setDirty(true);
                  }}
                >
                  <Add fontSize="small" />
                  <span className="text-with-icon">Add Section</span>
                </Label>
              </Row>

              <Droppable droppableId="droppable" type="droppableItem">
                {(provided, snapshot) => (
                  <div
                    className="dropzone dropzone-edit-checklist mb-2 scrollable-checklist-content"
                    ref={provided.innerRef}
                    style={getListContainerStyle(snapshot.isDraggingOver)}
                  >
                    {currentChecklist.length === 0 && (
                      <div className="dz-message dz-checklist-message mt-2">
                        <h4>
                          {`Create Your Checklist Here`}
                        </h4>
                      </div>
                    )}
                    <div>
                      {currentChecklist.length > 0 && currentChecklist?.map((item, index) => (
                        <AddSection
                          item={item}
                          index={index}
                          setItems={setCurrentChecklist}
                          items={currentChecklist}
                          onChangeTextHeader={onChangeTextHeader}
                          onRemoveCheckListGroup={onRemoveCheckListGroup}
                          setDirty={setDirty}
                          onRemoveCheckListItem={onRemoveCheckListItem}
                          onChangeItemData={onChangeItemData}
                          isValidChecklist={isValidChecklist}
                          setIsValidChecklist={setIsValidChecklist}
                          key={
                            item.id + index.toString() ||
                            item.id + item.title ||
                            item.content + index.toString()
                          }
                        />
                      ))}
                    </div>
                    {provided.placeholder}
                   
                  </div>
                )}
              </Droppable>
              <Col className="dz-actions-checklist jobs-checklist-clear">
                      <Button
                        outline
                        color="primary"
                        onClick={onClear}
                        data-dismiss="modal"
                        disabled={!isValidChecklist}
                        style={{
                          border: "2px solid #65a2eb",
                        }}
                      >
                        Clear
                      </Button>

                      <Button
                        outline
                        onClick={() => {
                          onClose();
                        }}
                        color="white"
                        className="btn btn-light w-md float-left"
                        data-dismiss="modal"
                      >
                        Cancel
                      </Button>
                      <Button
                        outline
                        color="white"
                        onClick={() => {
                          onSaveChecklist();
                        }}
                        className="btn btn-primary float-left"
                        data-dismiss="modal"
                        disabled={!isValidChecklist}
                      >
                        Save and Close
                      </Button>
                    </Col>
            </Col>
            <Col lg="5" md="5" sm="5" xs="5">
              <>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        borderRadius: "4px 4px 0 0 ",

                      }}
                      className={classnames({
                        active: activeTab === "1",
                        "builder-actions__label": activeTab !== "1",
                      })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Checklist Items
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                        "builder-actions__label": activeTab !== "2",
                      })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Saved Checklist
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                    <ChecklistItemSection
                      profile={currentUserProfile}
                      checklistItemName={checklistItemName}
                      setChecklistItemName={setChecklistItemName}
                      isDuplicate={isDuplicate}
                      items={checklistItems}
                      setSelectedListItem={setSelectedListItem}
                      openModalDlg={openModalDlg}
                      setItemSearch={setItemSearch}
                      itemSearch={itemSearch}
                      myListItems={myListItems}
                    />
                  </TabPane>
                  <TabPane className="saved-checklist-tab" tabId="2">
                    <div className="mb-2">
                      <div>
                        <Label className="saved-checklist-tab__label">
                          Search saved checklist
                        </Label>
                        {showCheckBox && checkItemIsChecked && (
                          <Button
                            size="md"
                            color="link"
                            className="saved-checklist-tab__button"
                            onClick={() => {
                              setShowDeleteAlert(true);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                        )}
                        <Button
                          size="md"
                          color="link"
                          className="saved-checklist-tab__select"
                          onClick={() => {
                            showCheckBox ? onCancel() : setShowCheckBox(true);
                          }}
                        >
                          {showCheckBox ? "Cancel" : "Select Multiple"}
                        </Button>
                      </div>
                      <Input
                        type="search"
                        placeholder="Enter keyword"
                        name="checklistSearch"
                        onChange={e => setChecklistSearch(e.target.value)}
                      ></Input>
                    </div>
                    <Row className="saved-checklist-tab__list">
                      <SavedCheckList
                        showCheckBox={showCheckBox}
                        myChecklist={myCheckLists}
                        setMyChecklist={setMyCheckLists}
                        checklistSearch={checklistSearch}
                      />
                    </Row>
                  </TabPane>
                </TabContent>
              </>
            </Col>
          </DragDropContext>
        </Row>
      </Container>
      {showDeleteAlert && (
        <DeleteConfirmationPromptWithCheckBox
          label={
            "I understand that deleting is permanent and cannot be undone."
          }
          onCancel={() => {
            setShowDeleteAlert(false);
          }}
          onConfirm={deleteSelectedChecklist}
        />
      )}
    </React.Fragment>
  );
}
