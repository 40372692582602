import { PLATFORM_OWNER } from "common/constants";
import React, { useState } from "react";
//i18n
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ActiveJobs from "./LatestJobs";
// Pages Components
import WelcomeComp from "./WelcomeComp";

const Dashboard = props => {
  const { currentUserProfile } = useSelector(state => state.profile);
  const [subscribed, setSubscribed] = useState(
    currentUserProfile?.subscription !== "none"
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | IB</title>
        </MetaTags>
        <Container fluid={true}>
          {" "}
          <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xl="6">
              <WelcomeComp />
            </Col>
          </Row>
          {currentUserProfile?.type != PLATFORM_OWNER &&
            (
              <Row>
                <ActiveJobs />
              </Row>
            )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Dashboard);
