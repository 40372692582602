import firebase from "../config/firebase";
const db = firebase.firestore();

export function fetchDocumentListsFromDb(orgId) {
  return db.collection(`orgs/${orgId}/documentLists`);
}

export function addDocumentListsToDb(orgId, documentList) {
  return db.collection(`orgs/${orgId}/documentLists`).add({
    ...documentList,
    date: firebase.firestore.FieldValue.serverTimestamp(),
  });
}

export function updateDocumentListsInDb(orgId, documentList) {
  return db
    .collection(`orgs/${orgId}/documentLists`)
    .doc(documentList.id)
    .update(documentList);
}

export function fetchDocumentListFromDb(orgId, documentListId) {
  return db.collection(`orgs/${orgId}/documentLists`).doc(documentListId);
}