import React, { useState, useRef } from "react";
import cuid from "cuid";
import firebase from "./../../config/firebase";
import { updateUserProfile } from "./../../services/index";
import { Modal } from "reactstrap";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { closeSignaturePad } from "store/actions";
import SignaturePad from "react-signature-pad-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
export default function NewSignaturePad() {
  const storageRef = firebase.storage().ref();
  const signatureRef = useRef();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [isSignatureNotExist, setIsSignatureNotExist] = useState(false);
  const { currentUserProfile, showSignature } = useSelector(
    state => state.profile
  );
  const onClickSave = async () => {
    const isSignatureExist =
      signatureRef.current.signaturePad._data.length == 0;
    if (isSignatureExist) {
      setIsSignatureNotExist(isSignatureExist);
    } else {
      // location in storage you want to create/send file to
      setSaving(true);
      const filePath = `${currentUserProfile.id}/signature/${cuid()}`;
      const dataURL = await signatureRef.current.toDataURL("image/png");
      const filesRef = storageRef.child(filePath);

      filesRef
        .putString(dataURL, "data_url")
        .then(snapshot => {
          console.log("Uploaded a blob or file!");
        })
        .catch(error => {
          toast.alert(error);
        })
        .finally(() => {
          console.log("finally");
          storageRef
            .child(filePath)
            .getDownloadURL()
            .then(async url => {
              await updateUserProfile({ ...currentUserProfile, signURL: url });
              dispatch(closeSignaturePad());
              setSaving(false);
            });
        });
    }
  };
  
  return (
    <React.Fragment>
      {isSignatureNotExist && (
        <SignatureDialog setIsSignatureNotExist={setIsSignatureNotExist} />
      )}
      <Modal isOpen={showSignature} size="xl" className="modal-fullscreen" style={{ marginTop: "-14rem" }}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Signature
          </h5>
          <button
            type="button"
            onClick={() => dispatch(closeSignaturePad())}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <SignaturePad
            options={{ backgroundColor: "white" }}
            redrawOnResize={true}
            ref={signatureRef}
          />
        </div>
        <div className="modal-footer">
          <button
            disabled={saving}
            type="button"
            onClick={() => dispatch(closeSignaturePad())}
            className="btn btn-secondary float-left"
            data-dismiss="modal"
          >
            <i className="dripicons-cross" />
            Close
          </button>
          <button
            disabled={saving}
            type="button"
            onClick={() => signatureRef.current.clear()}
            className="btn btn-primary "
            data-dismiss="modal"
          >
            <i style={{ fontSize: "1rem" }} className="bx bxs-eraser" /> Clear
          </button>
          <button
            disabled={saving}
            type="button"
            className="btn btn-success "
            onClick={onClickSave}
          >
            {saving ? (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            ) : (
              <i style={{ fontSize: "1rem" }} className="bx bx-save" />
            )}{" "}
            Save
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
}
export const SignatureDialog = ({ setIsSignatureNotExist }) => {
  return (
    <>
      <SweetAlert
        title="E-signature not added."
        warning
        confirmButtonText="OK"
        confirmBtnBsStyle="success"
        onConfirm={() => {
          setIsSignatureNotExist(false);
        }}
      >
        Please add E-signature and try again.
      </SweetAlert>
    </>
  );
};
