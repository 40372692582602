import { useState } from "react";
import { CardTitle } from "reactstrap";
import AttachmentPlaceholder from "../AttachmentPlaceholder";
import CertAttachment from "../CertAttachment";
import CertUpload from "../CertUpload";

export const UploadDocuments = ({ job, canEdit, collectionName }) => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <CardTitle className="mb-4">Manual Upload Certificates</CardTitle>
      {loading && (
        <div>
          <AttachmentPlaceholder />
          <AttachmentPlaceholder />
        </div>
      )}
      {job?.manualCertificates?.map(file => {
        return (
          <CertAttachment
            file={file}
            job={job}
            canEdit={canEdit}
            key={file.id}
            collectionName={collectionName}
          />
        );
      })}
      {canEdit && (
        <CertUpload
          job={job}
          canEdit={canEdit}
          setLoading={setLoading}
          collectionName={collectionName}
        />
      )}
    </>
  );
};
