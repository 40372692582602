import { ORGANISATION_OWNER } from "common/constants";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  InspectionJobCreationSidebar,
  JobCreationSidebar,
} from "./OrgUserSidebar";
import { SetupSidebar } from "./OrgUserSidebar";
import { InspectionJobsSidebar, JobsSidebar } from "./JobsSidebar";
import { isValidOrgUser } from "routes/allRoutes";
import organisationStatus from "utils/organisationStatus";

export function OrgOwnerSidebar({ currentUserProfile, props }) {
  const [isValidUser, setIsValidUser] = useState(false);
  const isOrganisationActive = organisationStatus(currentUserProfile);
  useEffect(() => {
    setIsValidUser(isValidOrgUser(currentUserProfile));
  }, [currentUserProfile?.orgAccessId, currentUserProfile?.orgStatus]);
  return (
    <>
      {currentUserProfile?.type === ORGANISATION_OWNER &&
        isValidUser &&
        isOrganisationActive && (
          <>
            <li className="menu-title" style={{color:"black",fontSize:"14px"}}>{props.t("User Mangement")} </li>
            <li>
              <Link
                to="/org-users-management"
                className={
                  props.location.pathname.includes("/org-users-management")
                    ? "mm-active"
                    : ""
                }
              >
                <i className="bx bx-wrench"></i>
                <span>{props.t("Manage Users")}</span>
              </Link>
            </li>
            <li className="menu-title" style={{color:"black",fontSize:"14px"}}>{props.t("Company Profile")} </li>
            <li>
              <Link
                to="/company-profile"
                className={
                  props.location.pathname.includes("/company-profile")
                    ? "mm-active"
                    : ""
                }
              >
                <i className="bx bx-buildings"></i>
                <span>{props.t("Company Details")}</span>
              </Link>
            </li>
          </>
        )}
      <li className="menu-title" style={{color:"black",fontSize:"14px"}}>{props.t("Dashboard")} </li>
      <li>
        <Link
          to="/dashboard"
          className={
            props.location.pathname.includes("/dashboard") ? "mm-active" : ""
          }
        >
          <i className="bx bx-home"></i>
          <span>{props.t("Overview")}</span>
        </Link>
      </li>
      <li className="menu-title" style={{color:"black",fontSize:"14px"}}>{props.t("Jobs")} </li>
      {isValidUser && isOrganisationActive && (
        <JobCreationSidebar props={props} />
      )}
      {<JobsSidebar props={props} />}
      <li className="menu-title" style={{color:"black",fontSize:"14px"}}>{props.t("Inspections")} </li>
      {isValidUser && isOrganisationActive && (
        <InspectionJobCreationSidebar props={props} />
      )}
      {<InspectionJobsSidebar props={props} />}
      {isValidUser && isOrganisationActive && <SetupSidebar props={props} />}
      {currentUserProfile?.type !== ORGANISATION_OWNER && (
        <>
          <li className="menu-title" style={{color:"black",fontSize:"14px"}}>{props.t("Organisation")} </li>
          <li>
            <Link
              to="/org-onboarding"
              className={
                props.location.pathname.includes("/org-onboarding")
                  ? "mm-active"
                  : ""
              }
            >
              <i className="bx bx-briefcase-alt-2"></i>
              <span>{props.t("Organisation Details")}</span>
            </Link>
          </li>
        </>
      )}
    </>
  );
}
