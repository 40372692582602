import {
  FETCH_JOB_CUSTOM_LISTITEM,
  FETCH_JOB_CUSTOM_LISTITEM_FAILURE,
  FETCH_JOB_CUSTOM_LISTITEM_SUCCESS,
  FETCH_JOB_DEFAULT_LISTITEM,
  FETCH_JOB_DEFAULT_LISTITEM_FAILURE,
  FETCH_JOB_DEFAULT_LISTITEM_SUCCESS,
  FETCH_JOB_SAVED_CHECKLIST,
  FETCH_JOB_SAVED_CHECKLIST_FAILURE,
  FETCH_JOB_SAVED_CHECKLIST_SUCCESS,
} from "./actionTypes";

export function fetchJobDefaultListItems() {
  return {
    type: FETCH_JOB_DEFAULT_LISTITEM,
  };
}

export function fetchJobCustomListItems() {
  return {
    type: FETCH_JOB_CUSTOM_LISTITEM,
  };
}

export function fetchJobSavedChecklist() {
  return {
    type: FETCH_JOB_SAVED_CHECKLIST,
  };
}

export function fetchJobDefaultListItemsSuccess(items) {
  return {
    type: FETCH_JOB_DEFAULT_LISTITEM_SUCCESS,
    payload: items,
  };
}

export function fetchJobCustomListItemsSuccess(items) {
  return {
    type: FETCH_JOB_CUSTOM_LISTITEM_SUCCESS,
    payload: items,
  };
}

export function fetchJobDefaultListItemsError(error) {
  console.log(error);
  return {
    type: FETCH_JOB_DEFAULT_LISTITEM_FAILURE,
    payload: error,
  };
}

export function fetchJobCustomListItemsError(error) {
  return {
    type: FETCH_JOB_CUSTOM_LISTITEM_FAILURE,
    payload: error,
  };
}


export function fetchJobSavedChecklistSuccess(items) {

  return {
    type: FETCH_JOB_SAVED_CHECKLIST_SUCCESS,
    payload: items,
  };
}

export function fetchJobSavedChecklistError(error) {
  return {
    type: FETCH_JOB_SAVED_CHECKLIST_FAILURE,
    payload: error,
  };
}