import useFirestoreDoc from "common/hooks/useFirestoreDoc";
import { fetchJobFromDb } from "services";
import "../../jobCreation/checklist/CheckListPage.scss";
import { useState } from "react";
import RoomsOverview from "./RoomsOverview";
import { ACTIVE_JOBS_DB_NAME } from "common/constants";

export default function ActiveJobsRoomsOverview(props) {
  const {
    match: { params },
    history,
  } = props;
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useFirestoreDoc({
    query: () => fetchJobFromDb(params.jobid),
    onStart: () => () => setLoading(true),
    onSuccess: job => {
      setJobDetails(job);
    },
    onError: error => setError(error),
    deps: [params],
  });
  return (
    <RoomsOverview
      jobDetails={jobDetails}
      roomId={params?.roomid}
      collectionName={ACTIVE_JOBS_DB_NAME}
      history={history}
    />
  );
}