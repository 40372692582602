import CertificateGenerateComponent from "./CerficateGenerateComponent";
import "./Certificate.scss";
import "./../../../jobCreation/checklist/CheckListPage.scss";
import { DisplayDocuments } from "./DisplayDocuments";
import { UploadDocuments } from "./UploadDocuments";

export default function Certificate({
  job,
  canEdit,
  collectionName,
  setShowCertModal,
  setCertificateDetails,
}) {
  const onInvokeCertificateModal = async certificateProperties => {
    setCertificateDetails(certificateProperties);
    setShowCertModal(true);
  };
  return (
    <>
      {canEdit && (
        <CertificateGenerateComponent
          className="generate-details-container"
          onInvokeCertificateModal={onInvokeCertificateModal}
          job={job}
          collectionName={collectionName}
        />
      )}
          <DisplayDocuments
            job={job}
            canEdit={canEdit}
            collectionName={collectionName}
          />
          <br />
          {/* Commented for avoid manual certificate part */}
          {/* <UploadDocuments
            job={job}
            canEdit={canEdit}
            collectionName={collectionName}
          /> */}
        </>
  );
}
