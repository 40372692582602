import organisationStatus from "utils/organisationStatus";

export function getInspectionConfiguration(
  connector,
  searchType,
  currentUserProfile,
  jobType
) {
  const isOrganisationActive = organisationStatus(currentUserProfile);
  const orgAccessId = currentUserProfile?.orgAccessId
    ? currentUserProfile?.orgAccessId
    : "not part of any organisation";
  const filter = () => {
    return isOrganisationActive
      ?  [
            { field: "accessids", values: [currentUserProfile?.id] },
            { field: "status", values: [jobType] },
          ]
      :  [
          { field: "accessids", values: [currentUserProfile?.id] },
          {
            field: "orgid",
            values: [orgAccessId],
            type: "none",
          },
          { field: "status", values: [jobType] },
        ]
  };
  return {
    alwaysSearchOnInitialLoad: true,
    apiConnector: connector,
    hasA11yNotifications: true,
    initialState: { resultsPerPage: 10 },
    searchQuery: {
      precision: searchType === "jobno" ? 9 : 2,
      filters: filter(),
      sort: [{ _score: "desc" }, { jobfield: "desc" }],
      result_fields: {
        jobno: { raw: {} },
        customjobno: { raw: {} },
        client__displayname: { raw: {} },
        jobaddress: { raw: {} },
        client__address: { raw: {} },
        jobtype: { raw: {} },
        participants: { raw: {} },
        participantids: { raw: {} },
        jobname: { raw: {} },
        orgidentitycode : { raw: {} },
        orgid: { raw: {} },
      },
      search_fields: {
        [searchType]: {
          weight: 5,
        },
      },
    },
    autocompleteQuery: {
      suggestions: {
        types: {
          documents: { fields: [searchType] },
        },
      },
    },
  };
}