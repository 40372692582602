import {
  FETCH_CUSTOM_LISTITEM,
  FETCH_CUSTOM_LISTITEM_FAILURE,
  FETCH_CUSTOM_LISTITEM_SUCCESS,
  FETCH_DEFAULT_LISTITEM,
  FETCH_DEFAULT_LISTITEM_FAILURE,
  FETCH_DEFAULT_LISTITEM_SUCCESS,
  FETCH_SAVED_CHECKLIST,
  FETCH_SAVED_CHECKLIST_SUCCESS,
  FETCH_SAVED_CHECKLIST_FAILURE
} from "./actionTypes";

const initialState = {
  defaultloading: false,
  customloading:false,
  savedLoading: false,
  customListItems: [],
  defaultListItems: [],
  savedChecklists: [],
  errorCustom: null,
  errorDefault: null,
  errorSaved: null
};

export default function setupReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_CUSTOM_LISTITEM:
      return {
        ...state,
        defaultloading: true,
      };
    case FETCH_DEFAULT_LISTITEM:
      return {
        ...state,
        customloading: true,
      };
    case FETCH_SAVED_CHECKLIST:
      return {
        ...state,
        savedLoading: true,
      };
    case FETCH_CUSTOM_LISTITEM_FAILURE:
      return {
        ...state,
        error: payload,
        customloading: false,
      };
    case FETCH_DEFAULT_LISTITEM_FAILURE:
      return {
        ...state,
        error: payload,
        defaultloading: false,
      };
    case FETCH_CUSTOM_LISTITEM_SUCCESS:
      return {
        ...state,
        customListItems: payload,
        customloading: false,
      };
    case FETCH_DEFAULT_LISTITEM_SUCCESS:
      return {
        ...state,
        defaultListItems: payload,
        defaultloading: false,
      };
    case FETCH_SAVED_CHECKLIST_SUCCESS:
      return {
        ...state,
        savedChecklists: payload,
        savedLoading: false,
      };
    case FETCH_SAVED_CHECKLIST_FAILURE:
      return {
        ...state,
        errorSaved: payload,
        savedLoading: false,
      };
    default:
      return state;
  }
}
