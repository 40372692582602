import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Radio,
  FormControlLabel,
  FormLabel,
  Box,
} from "@mui/material";
import "./Certificate.scss";
import "../../../jobCreation/checklist/addSection.scss";
import "./ReportGeneration.scss";
import {
  PLACE_AFTER_CHECKLIST_ITEM,
  PLACE_BEFORE_CHECKLIST_ITEM,
  PLACE_IN_CHECKLIST,
  PLACE_REPORT_END,
  PLACE_REPORT_START,
} from "common/constants";
import { handleUpdateSection } from "./handleDynamicFieldsInTemplate";
import { fetchChecklist } from "services";
const TemplateSectionPosition = ({
  includeChecklist,
  setIsSectionToBePlaced,
  currentTemplate,
  job,
  jobType,
  jobReportTemplate,
  setJobReportTemplate,
  collectionName,
  setIncludeChecklist,
  setEditIndex,
  floor,
  areas,
}) => {
  const [selectedFloor, setSelectedFloor] = useState({});
  const [selectedArea, setSelectedArea] = useState({});
  const [selectedChecklist, setSelectedChecklist] = useState({});
  const [selectedSection, setSelectedSection] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedValue, setSelectedValue] = useState(
    currentTemplate?.selectedValue || PLACE_REPORT_END
  );
  const [selectedType, setSelectedType] = useState([]);
  const handleChange = event => {
    setSelectedValue(event.target.value);
  };
  useEffect(() => {
    if (!selectedFloor?.id && floor.content) {
      setSelectedFloor({
        id: floor?.id,
        content: floor?.content,
      });
      if (areas?.length === 1) {
        setSelectedArea({
          id: areas[0]?.value,
          content: areas[0]?.label,
        });
        return;
      }
    }
  }, []);
  useEffect(() => {
    let selectedTypes = [];
    if (currentTemplate) {
      if (currentTemplate.floor) {
        setSelectedFloor({
          id: currentTemplate?.floor?.id,
          content: currentTemplate?.floor?.content,
        });
        selectedTypes.push("Floor");
      }

      if (currentTemplate.area) {
        setSelectedArea({
          id: currentTemplate?.area?.id,
          content: currentTemplate?.area?.content,
        });
        selectedTypes.push("Area");
      }

      if (currentTemplate.checklist) {
        fetchChecklistOfArea(
          collectionName,
          job.id,
          currentTemplate?.selectedArea?.id
        );
        selectedTypes.push("Checklist");
      }

      if (currentTemplate.section?.id) {
        selectedTypes.push("Section");
      }
      if (currentTemplate.item) {
        setSelectedItem({
          id: currentTemplate?.item?.id,
          content: currentTemplate?.item?.content,
        });
        selectedTypes.push("Item");
      }
      setSelectedType(_selectedType => [...selectedTypes]);
    }
  }, [currentTemplate]);

  async function fetchChecklistOfArea(areaId) {
    const areaChecklist = await fetchChecklist(collectionName, job.id, areaId);
    if (areaChecklist) {
      setSelectedChecklist(areaChecklist);
      if (currentTemplate?.section?.id) {
        const section = areaChecklist?.items?.find(
          item => item?.id === currentTemplate?.section?.id
        );
        setSelectedSection({
          id: section?.id,
          content: section?.title || section?.content,
          subItems: section?.subItems,
        });
      }
      return;
    }
    setSelectedChecklist({});
  }
  useEffect(() => {
    if (
      selectedFloor?.id &&
      !(selectedArea?.id || selectedArea?.value) &&
      selectedType.length === 0
    ) {
      setSelectedType(_selectedType => [..._selectedType, "Floor"]);
    }
    setSelectedType(_selectedType => [..._selectedType, "Floor", "Area"]);
    if (selectedArea?.id || selectedArea?.value) {
      fetchChecklistOfArea(selectedArea?.id || selectedArea?.value);
    }
  }, [selectedFloor, selectedArea]);

  const handleCloseModal = () => {
    setIsSectionToBePlaced(false);
    setSelectedFloor({});
    setSelectedArea({});
    setSelectedChecklist({});
    setSelectedSection({});
    setSelectedItem({});
    setSelectedValue(PLACE_REPORT_END);
    setEditIndex(-1);
    setSelectedType([]);
  };
  const handleSave = () => {
    const section =
      selectedValue === PLACE_BEFORE_CHECKLIST_ITEM ||
      selectedValue === PLACE_IN_CHECKLIST ||
      selectedValue === PLACE_AFTER_CHECKLIST_ITEM
        ? {
            ...currentTemplate,
            floor: {
              id: selectedFloor?.id,
              content: selectedFloor?.content,
            },
            area: { id: selectedArea?.id, content: selectedArea?.content },
            checklist: {
              id: selectedChecklist?.id,
              content: selectedChecklist?.title,
            },
            section: {
              id: selectedSection?.id,
              content: selectedSection?.content,
            },
            item: {
              id: selectedItem?.id || currentTemplate?.id,
              content: selectedItem?.content || currentTemplate?.content,
              position:
                selectedSection.index + 1 + "." + (selectedItem?.index + 1),
            },
            selectedValue: selectedValue,
          }
        : {
            ...currentTemplate,
            selectedValue: selectedValue,
          };
    handleUpdateSection(section, jobReportTemplate, setJobReportTemplate);
    handleCloseModal();
    // console.log("Selected value is :", );
  };
  return (
    <div
      className="field"
      style={{
        border: "none",
        width: "80%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box>
          <FormControl
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <FormLabel style={{ marginRight: "10px" }}>
              Place this section{" "}
            </FormLabel>

            <FormControlLabel
              value={PLACE_REPORT_START}
              control={<Radio />}
              label="At the start of the report"
              checked={selectedValue === PLACE_REPORT_START}
              onChange={handleChange}
            />
            <FormControlLabel
              value={PLACE_IN_CHECKLIST}
              control={<Radio />}
              label="Before or After the checklist Item"
              checked={selectedValue === PLACE_IN_CHECKLIST}
              onChange={() => {
                setSelectedValue(PLACE_IN_CHECKLIST);
                setIncludeChecklist(true);
              }}
            />
            <FormControlLabel
              value={PLACE_REPORT_END}
              control={<Radio />}
              label="At the end of the report"
              checked={selectedValue === PLACE_REPORT_END}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
      </div>
      {includeChecklist &&
        (selectedValue === PLACE_BEFORE_CHECKLIST_ITEM ||
          selectedValue === PLACE_IN_CHECKLIST ||
          selectedValue === PLACE_AFTER_CHECKLIST_ITEM) && (
          <>
            <p>Select floor and area</p>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              {job[jobType]?.rooms ? (
                <FormControl
                  fullWidth
                  style={{ marginRight: "10px", minWidth: "200px" }}
                >
                  <InputLabel id="search-type-label">
                    {selectedFloor?.content || "Floor"}{" "}
                  </InputLabel>
                  <Select
                    labelId="search-type-label"
                    id="search-type-select"
                    value={
                      JSON.stringify(selectedFloor) ||
                      JSON.stringify(floor) ||
                      currentTemplate.floor?.content ||
                      ""
                    }
                    label={selectedFloor?.content || "Floor"}
                    onChange={e => {
                      try {
                        const value = JSON.parse(e.target.value);
                        setSelectedFloor(value);
                        setSelectedType(_selectedType => [
                          ...selectedType,
                          "Floor",
                        ]);
                      } catch (error) {
                        console.error("Error parsing JSON:", error);
                      }
                    }}
                    size="small"
                  >
                    {selectedFloor?.content && (
                      <MenuItem value={JSON.stringify(selectedFloor)}>
                        {selectedFloor?.content}
                      </MenuItem>
                    )}
                    {floor?.content ? (
                      <MenuItem value={JSON.stringify(floor)}>
                        {floor?.content}
                      </MenuItem>
                    ) : (
                      job[jobType]?.rooms.map(room => (
                        <MenuItem key={room?.id} value={JSON.stringify(room)}>
                          {room?.content}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              ) : (
                <p>No Floors Exists</p>
              )}
              {selectedType.find(item => item === "Floor") ? (
                selectedFloor?.id ? (
                  <FormControl
                    fullWidth
                    style={{ marginRight: "10px", minWidth: "200px" }}
                  >
                    <InputLabel id="search-type-label">
                      {selectedArea?.content || "Area"}
                    </InputLabel>
                    <Select
                      labelId="search-type-label"
                      id="search-type-select"
                      value={
                        JSON.stringify(selectedArea) ||
                        (areas?.length === 1 && JSON.stringify(areas[0])) ||
                        JSON.stringify(currentTemplate.area) ||
                        ""
                      }
                      label={selectedArea?.content || "Area"}
                      onChange={e => {
                        const value = JSON.parse(e.target.value);
                        setSelectedArea(value);
                        setSelectedType(_selectedType => [
                          ...selectedType,
                          "Area",
                        ]);
                        setSelectedSection({});
                        setSelectedItem({});
                      }}
                      size="small"
                    >
                      {selectedArea?.content && (
                        <MenuItem value={JSON.stringify(selectedArea)}>
                          {selectedArea?.content}
                        </MenuItem>
                      )}
                      {areas?.length > 0 ? (
                        areas?.length === 1 ? (
                          <MenuItem value={JSON.stringify(areas[0])}>
                            {areas[0]?.label}
                          </MenuItem>
                        ) : (
                          areas?.map(area => (
                            <MenuItem value={JSON.stringify(area)}>
                              {area?.label}
                            </MenuItem>
                          ))
                        )
                      ) : (
                        selectedFloor?.subItems?.map(area => (
                          <MenuItem key={area?.id} value={JSON.stringify(area)}>
                            {area?.content}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <p>No Areas Exists</p>
                )
              ) : null}
              {selectedType.find(item => item === "Area") ? (
                selectedChecklist?.id &&
                selectedChecklist?.items?.length > 0 ? (
                  <FormControl
                    style={{ marginRight: "10px", minWidth: "200px" }}
                  >
                    <InputLabel id="search-type-label">
                      {selectedSection?.content || "Checklist"}
                    </InputLabel>
                    <Select
                      labelId="search-type-label"
                      id="search-type-select"
                      value={
                        JSON.stringify(selectedSection) ||
                        currentTemplate.section?.content ||
                        ""
                      }
                      label={selectedSection?.content || "Checklist"}
                      onChange={e => {
                        const value = JSON.parse(e.target.value);
                        setSelectedSection(value);
                        setSelectedType(_selectedType => [
                          ...selectedType,
                          "Section",
                        ]);
                      }}
                      size="small"
                    >
                      {selectedChecklist?.items?.map((section, index) => (
                        <MenuItem
                          key={section?.id}
                          value={JSON.stringify({ ...section, index })}
                        >
                          {section?.content}
                        </MenuItem>
                      ))}
                      {selectedSection?.content && (
                        <MenuItem
                          key={selectedSection?.id}
                          value={JSON.stringify(selectedSection)}
                        >
                          {selectedSection?.content}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <p>No Checklist exists</p>
                )
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: "30px",
              }}
            >
              {selectedType.find(item => item === "Section") ? (
                selectedSection?.id && selectedSection?.subItems?.length > 0 ? (
                  <>
                    <Box>
                      <FormControl
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <FormLabel
                          component="legend"
                          style={{ marginRight: "10px" }}
                        >
                          Place this item
                        </FormLabel>
                        <FormControlLabel
                          value={PLACE_BEFORE_CHECKLIST_ITEM}
                          control={<Radio />}
                          label="Before"
                          checked={
                            selectedValue === PLACE_BEFORE_CHECKLIST_ITEM
                          }
                          onChange={handleChange}
                          disabled={!selectedItem?.id}
                        />
                        <FormControlLabel
                          value={PLACE_AFTER_CHECKLIST_ITEM}
                          control={<Radio />}
                          label="After"
                          checked={selectedValue === PLACE_AFTER_CHECKLIST_ITEM}
                          onChange={handleChange}
                          disabled={!selectedItem?.id}
                        />
                      </FormControl>
                    </Box>

                    <FormControl fullWidth style={{ width: "50%" }}>
                      <InputLabel id="search-type-label">
                        {selectedItem?.content || "Checklist Item/Hold Point"}
                      </InputLabel>
                      <Select
                        labelId="search-type-label"
                        id="search-type-select"
                        value={
                          JSON.stringify(selectedItem) ||
                          currentTemplate.item?.content ||
                          ""
                        }
                        label={
                          selectedItem?.content || "Checklist Item/Hold Point"
                        }
                        onChange={e => {
                          const value = JSON.parse(e.target.value);
                          setSelectedItem(value);
                        }}
                        size="small"
                      >
                        {selectedItem?.content && (
                          <MenuItem
                            key={selectedItem?.id}
                            value={JSON.stringify(selectedItem)}
                          >
                            {selectedItem?.content}
                          </MenuItem>
                        )}
                        {selectedSection?.subItems?.map((item, index) => (
                          <MenuItem
                            key={item?.id}
                            value={JSON.stringify({ ...item, index })}
                          >
                            {item?.content}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <p>No items exists</p>
                )
              ) : null}
            </div>
          </>
        )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px",
        }}
      >
        <Button
          color="primary"
          className="btn btn-primary float-left m-2"
          onClick={handleSave}
        >
          Confirm
        </Button>
        <Button
          color="danger"
          className="btn btn-primary float-left m-2"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
export default TemplateSectionPosition;
