import { FETCH_ORGS, FETCH_ORG_ERROR, FETCH_ORG_SUCCESS } from "./actionTypes";

export function fetchOrgs() {
  return {
    type: FETCH_ORGS,
  };
}

export function fetchOrgsSuccess(orgs) {
  return {
    type: FETCH_ORG_SUCCESS,
    payload: orgs,
  };
}

export function fetchOrgsError(error) {
  return {
    type: FETCH_ORG_ERROR,
    payload: error,
  };
}
