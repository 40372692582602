import exampleTheme from "themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "plugins/TreeViewPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import ListMaxIndentLevelPlugin from "plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "plugins/CodeHighlightPlugin";
import PlaygroundAutoLinkPlugin from "plugins/AutoLinkPlugin";
import { useState } from "react";
import LexicalEditorTopBar from "plugins/LexicalEditorTopBar";
import { ContentPastePlugin } from "plugins/PastePlugin";
function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}


const editorConfig = {
  theme: exampleTheme,
  onError(error) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

export default function RichTextEditor({
  handleDescriptionChange,
  index,
  description,
  jobReportTemplate,
  setJobReportTemplate,
  isSectionToBePlaced,
  setDirty,
  setIsChecklistChanged
}) {
  const [isDescriptionDirty, setIsDescriptionDirty] = useState(false);
  
  function onChange() {
    setIsDescriptionDirty(true);
  }
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
      <LexicalEditorTopBar />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
            
          />
          <OnChangePlugin onChange={onChange} />
          <HistoryPlugin />
          <ContentPastePlugin />
          <TreeViewPlugin
            isDescriptionDirty={isDescriptionDirty}
            setIsDescriptionDirty={setIsDescriptionDirty}
            handleDescriptionChange={handleDescriptionChange}
            index={index}
            description={description}
            jobReportTemplate={jobReportTemplate}
            setJobReportTemplate={setJobReportTemplate}
            setDirty={setDirty}
            setIsChecklistChanged={setIsChecklistChanged}
          />
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <PlaygroundAutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
      </div>
    </LexicalComposer>
  );
}
