import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";

const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const handleBlockedNavigation = nextLocation => {
    if (nextLocation.pathname === location.pathname) return false;
    if (!confirmedNavigation && shouldBlockNavigation()) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const closeModal = () => {
    setModalVisible(false);
  };
  const onConfirm = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      lastLocation?.hash !== ""
        ? navigate(lastLocation.pathname + lastLocation.hash)
        : navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);
  const boldStyle = {
    fontWeight: "bold",
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {modalVisible && (
        <SweetAlert
          title={
            <span>
              The job will be saved in <i>Drafts</i>
            </span>
          }
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="primary"
          onConfirm={onConfirm}
          onCancel={closeModal}
        >
          <p>
            Please check <b style={boldStyle}>Drafts</b> to continue editing
            this job
          </p>

          <Button color="link" size="lg" onClick={closeModal}>
            Continue editing
          </Button>
        </SweetAlert>
      )}
    </>
  );
};
export default RouteLeavingGuard;
