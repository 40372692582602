// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "@redux-devtools/extension";
// import rootReducer from "./rootReducer";
// import thunk from "redux-thunk";
// import { verifyAuth } from "./actions";
// import { createBrowserHistory } from "history";

// export const history = createBrowserHistory();

// export function configureStore() {
//   const store = createStore(
//     rootReducer(history),
//     composeWithDevTools(applyMiddleware(thunk))
//   );
//   store.dispatch(verifyAuth());

//   return store;
// }

// export const store = configureStore();

import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import { verifyAuth } from "./actions";
import { createBrowserHistory, createMemoryHistory } from "history";

const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
export const history = isBrowser ? createBrowserHistory() : createMemoryHistory();

export function configureStore() {
  const store = createStore(
    rootReducer(history),
    composeWithDevTools(applyMiddleware(thunk))
  );
  store.dispatch(verifyAuth());

  return store;
}

export const store = configureStore();
