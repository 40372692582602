import React, { useState, useEffect } from 'react';
import mammoth from 'mammoth';

const DocxViewer = ({ fileUrl }) => {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDocx = async () => {
            setLoading(true);
            setError(null);

            try {
                // Ensure URL is valid and correct


                const response = await fetch(fileUrl);

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Check the Content-Type header
                const contentType = response.headers.get('Content-Type');
                if (contentType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    throw new Error(`Expected a .docx file, but received content type: ${contentType}`);
                }

                const arrayBuffer = await response.arrayBuffer();

                // Optional: Validate if the arrayBuffer starts with the expected .docx signature
                const signature = new Uint8Array(arrayBuffer.slice(0, 4));
                if (signature[0] !== 0x50 || signature[1] !== 0x4B || signature[2] !== 0x03 || signature[3] !== 0x04) {
                    throw new Error('The fetched file does not seem to be a valid .docx file.');
                }

                // Convert the arrayBuffer to HTML
                const result = await mammoth.convertToHtml({ arrayBuffer });
                setContent(result.value);
            } catch (error) {
                console.error('Error fetching or processing the .docx file:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDocx();
    }, [fileUrl]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
};

export default DocxViewer;
