import {
  FETCH_JOB_CUSTOM_LISTITEM,
  FETCH_JOB_CUSTOM_LISTITEM_FAILURE,
  FETCH_JOB_CUSTOM_LISTITEM_SUCCESS,
  FETCH_JOB_DEFAULT_LISTITEM,
  FETCH_JOB_DEFAULT_LISTITEM_FAILURE,
  FETCH_JOB_DEFAULT_LISTITEM_SUCCESS,
  FETCH_JOB_SAVED_CHECKLIST,
  FETCH_JOB_SAVED_CHECKLIST_SUCCESS,
  FETCH_JOB_SAVED_CHECKLIST_FAILURE
} from "./actionTypes";

const initialState = {
  jobDefaultLoading: false,
  jobCustomLoading:false,
  jobSavedLoading: false,
  jobCustomListItems: [],
  jobDefaultListItems: [],
  jobSavedChecklists: [],
  jobErrorCustom: null,
  jobErrorDefault: null,
  jobErrorSaved: null
};

export default function setupReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_JOB_CUSTOM_LISTITEM:
      return {
        ...state,
        jobDefaultLoading: true,
      };
    case FETCH_JOB_DEFAULT_LISTITEM:
      return {
        ...state,
        jobCustomLoading: true,
      };
    case FETCH_JOB_SAVED_CHECKLIST:
      return {
        ...state,
        jobSavedLoading: true,
      };
    case FETCH_JOB_CUSTOM_LISTITEM_FAILURE:
      return {
        ...state,
        error: payload,
        jobCustomLoading: false,
      };
    case FETCH_JOB_DEFAULT_LISTITEM_FAILURE:
      return {
        ...state,
        error: payload,
        jobDefaultLoading: false,
      };
    case FETCH_JOB_CUSTOM_LISTITEM_SUCCESS:
      return {
        ...state,
        jobCustomListItems: payload,
        jobCustomLoading: false,
      };
    case FETCH_JOB_DEFAULT_LISTITEM_SUCCESS:
      return {
        ...state,
        jobDefaultListItems: payload,
        jobDefaultLoading: false,
      };
    case FETCH_JOB_SAVED_CHECKLIST_SUCCESS:
      return {
        ...state,
        jobSavedChecklists: payload,
        jobSavedLoading: false,
      };
    case FETCH_JOB_SAVED_CHECKLIST_FAILURE:
      return {
        ...state,
        jobErrorSaved: payload,
        jobSavedLoading: false,
      };
    default:
      return state;
  }
}
