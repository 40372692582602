import React, { useState, useCallback } from "react";
import "./CircularProgressBar.scss";
import ProgressBar from "./HorizontalProgressBar";
function CircularProgressBar(props) {
  const { sqSize, strokeWidth, percentage } = props;
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <svg
      width={sqSize}
      height={sqSize}
      viewBox={viewBox}
      values="${percentage}"
    >
      <circle
        className="circle-background"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className="circle-progress"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
      <circle
        className="circle-dashes"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        style={{
          strokeDasharray: "5 10",
        }}
      />
      <text
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        fontSize="12px"
        fill="#000000"
      >
        {percentage}%
      </text>
    </svg>
  );
}

CircularProgressBar.defaultProps = {
  sqSize: 50,
  percentage: 25,
  strokeWidth: 10,
};

function Progress(props) {
  const { percentage } = props;
  return (
    <div>
      <ProgressBar
        percentage={percentage}
      />
    </div>
  );
}

export default Progress;
