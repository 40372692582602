import {
  FETCH_NOTES,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAILURE,
} from "./actionTypes";

export function fetchNotes() {
  return {
    type: FETCH_NOTES,
  };
}

export function fetchNotesSuccess(notes) {
  return {
    type: FETCH_NOTES_SUCCESS,
    payload: notes,
  };
}

export function fetchNotesError(error) {
  console.log(error);
  return {
    type: FETCH_NOTES_FAILURE,
    payload: error,
  };
}
