export const FETCH_JOB_DEFAULT_LISTITEM = "FETCH_JOB_DEFAULT_LISTITEM";
export const FETCH_JOB_CUSTOM_LISTITEM = "FETCH_JOB_CUSTOM_LISTITEM";
export const FETCH_JOB_SAVED_CHECKLIST = "FETCH_JOB_SAVED_CHECKLIST";

export const FETCH_JOB_DEFAULT_LISTITEM_SUCCESS = "FETCH_JOB_DEFAULT_LISTITEM_SUCCESS";
export const FETCH_JOB_DEFAULT_LISTITEM_FAILURE = "FETCH_JOB_DEFAULT_LISTITEM_FAILURE";

export const FETCH_JOB_CUSTOM_LISTITEM_SUCCESS = "FETCH_JOB_CUSTOM_LISTITEM_SUCCESS";
export const FETCH_JOB_CUSTOM_LISTITEM_FAILURE = "FETCH_JOB_CUSTOM_LISTITEM_FAILURE";

export const FETCH_JOB_SAVED_CHECKLIST_SUCCESS = "FETCH_JOB_SAVED_CHECKLIST_SUCCESS";
export const FETCH_JOB_SAVED_CHECKLIST_FAILURE = "FETCH_JOB_SAVED_CHECKLIST_FAILURE";