import React, {CSSProperties} from 'react';
import classNames from 'classnames';

import './action.css';

export function Action({active, className, cursor, style, ...props}) {
  return (
    <button
      {...props}
      className={classNames("Action", className)}
      tabIndex={0}
      style={
        {
          ...style,
          cursor,
          '--fill': active?.fill,
          '--background': active?.background,
        } 
      }
    />
  );
}
