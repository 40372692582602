import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $patchStyleText } from '@lexical/selection';
import { $createRangeSelection, $getRoot } from 'lexical';

const $createFullContentSelection = () => {
  const allContentSelection = $createRangeSelection();

  const textNodes = $getRoot().getAllTextNodes();
  const firstNode = textNodes[0];
  const lastNode = textNodes.at(-1);

  if (firstNode && lastNode) {
    allContentSelection.setTextNodeRange(
      firstNode,
      0,
      lastNode,
      lastNode?.getTextContentSize(),
    );
  }

  return allContentSelection;
};

export function ContentPastePlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener((listener) => {
      if (!listener.tags.has('paste')) return;
      editor.update(() => {
        const selection = $createFullContentSelection();
        $patchStyleText(selection, { fontFamily:"Roboto" });
      });
    });

  }, [editor]);

  return null;
}
