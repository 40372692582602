import {
  FETCH_INSPECTION_CUSTOM_LISTITEM,
  FETCH_INSPECTION_CUSTOM_LISTITEM_FAILURE,
  FETCH_INSPECTION_CUSTOM_LISTITEM_SUCCESS,
  FETCH_INSPECTION_DEFAULT_LISTITEM,
  FETCH_INSPECTION_DEFAULT_LISTITEM_FAILURE,
  FETCH_INSPECTION_DEFAULT_LISTITEM_SUCCESS,
  FETCH_INSPECTION_SAVED_CHECKLIST,
  FETCH_INSPECTION_SAVED_CHECKLIST_FAILURE,
  FETCH_INSPECTION_SAVED_CHECKLIST_SUCCESS,
} from "./actionTypes";

export function fetchInspectionDefaultListItems() {
  return {
    type: FETCH_INSPECTION_DEFAULT_LISTITEM,
  };
}

export function fetchInspectionCustomListItems() {
  return {
    type: FETCH_INSPECTION_CUSTOM_LISTITEM,
  };
}

export function fetchInspectionSavedChecklist() {
  return {
    type: FETCH_INSPECTION_SAVED_CHECKLIST,
  };
}

export function fetchInspectionDefaultListItemsSuccess(items) {
  return {
    type: FETCH_INSPECTION_DEFAULT_LISTITEM_SUCCESS,
    payload: items,
  };
}

export function fetchInspectionCustomListItemsSuccess(items) {
  return {
    type: FETCH_INSPECTION_CUSTOM_LISTITEM_SUCCESS,
    payload: items,
  };
}

export function fetchInspectionDefaultListItemsError(error) {
  console.log(error);
  return {
    type: FETCH_INSPECTION_DEFAULT_LISTITEM_FAILURE,
    payload: error,
  };
}

export function fetchInspectionCustomListItemsError(error) {
  return {
    type: FETCH_INSPECTION_CUSTOM_LISTITEM_FAILURE,
    payload: error,
  };
}

//fetchInspectionDefaultListItemsSuccess, fetchInspectionCustomListItemsSuccess, fetchDefaultListItemError, fetchCustomListItemError

export function fetchInspectionSavedChecklistSuccess(items) {

  return {
    type: FETCH_INSPECTION_SAVED_CHECKLIST_SUCCESS,
    payload: items,
  };
}

export function fetchInspectionSavedChecklistError(error) {
  return {
    type: FETCH_INSPECTION_SAVED_CHECKLIST_FAILURE,
    payload: error,
  };
}