import React from "react";
import { Table, Tbody, Td, Tr } from "react-super-responsive-table";

export function ClientAdditionalInfo(client) {
  const isAdditionalFieldsExist=client?.primaryEmail ||
    client?.secondaryEmail ||
    client?.abn ||
    client?.registeredLicenseNumber ||
    client?.telephone ||
    client?.secondaryPhone ||
    client?.secondaryTelephone ||
    client?.abn ||
    client?.registeredLicenseNumber ||
    client?.primaryName || client?.secondaryName
  return (
    <>
      {isAdditionalFieldsExist ? <div id="tooltip" className="left">
        <div className="tooltip-arrow" />
        <div className="tooltip-label">
          <Table>
            <Tbody>
              {client?.primaryName && (
                <Tr>
                  <Td>Primary name : ${client?.primaryName}</Td>
                </Tr>
              )}
              {client?.secondaryName && (
                <Tr>
                  <Td>Secondary Name : {client.secondaryName}</Td>
                </Tr>
              )}
              {client?.primaryEmail && (
                <Tr>
                  <Td>Primary Email:{client.primaryEmail}</Td>
                </Tr>
              )}
              {client?.secondaryEmail && (
                <Tr>
                  <Td>Secondary Email:{client.secondaryEmail}</Td>
                </Tr>
              )}

              {client?.abn && (
                <Tr>
                  <Td>Business/Company Number:{client.abn}</Td>
                </Tr>
              )}
              {client?.registeredLicenseNumber && (
                <Tr>
                  <Td>
                    Registered License Number:{client.registeredLicenseNumber}
                  </Td>
                </Tr>
              )}
              {client?.telephone && (
                <Tr>
                  <Td>Telephone:{client.telephone}</Td>
                </Tr>
              )}
              {client?.secondaryPhone && (
                <Tr>
                  <Td>Secondary Phone:{client.secondaryPhone}</Td>
                </Tr>
              )}
              {client?.secondaryTelephone && (
                <Tr>
                  <Td>Secondary Telephone:{client.secondaryTelephone}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </div>
      </div>
:<p>No additional fields</p>}
    </>
  );
}
export default ClientAdditionalInfo;
