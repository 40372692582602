import { APPROVED, ORGANISATION_OWNER } from "common/constants";
import firebase from "../../config/firebase";
const db = firebase.firestore();

export function getOrgOwnerDetails(orgId) {
  return db
    .collection("users")
    .where("orgAccessId", "==", orgId)
    .where("type", "==", ORGANISATION_OWNER)
    .where("orgStatus", "==", APPROVED)
    .limit(1);
}

export function getOrgOwner(orgId) {
  return db
    .collection("users")
    .where("orgAccessId", "==", orgId)
    .where("type", "==", ORGANISATION_OWNER)
    .where("orgStatus", "==", APPROVED)
    .get();
}
