import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch } from "react-redux";
import JobsGrid from "./JobsGrid";

import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { fetchDraftsFromDb } from "services";
import {
  fetchDraftsError,
  fetchDraftsScuccess,
  fetchJobs,
} from "store/job/actions";
import { DRAFTS_DB_NAME } from "common/constants";
import { fetchJobsSuccess } from "store/actions";
import Loader from "components/Loader";
import usePaginationation from "common/hooks/usePaginationation";
import PrevNextUI from "searchuicomponents/PrevNextUI";

const Dashboard = props => {
let { page, runPagination, totalPage, loading } = usePaginationation();
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>

            <title>Jobs | Drafts</title>

        </MetaTags>
        <h4 className="mb-0 font-size-18" style={{ marginLeft: 12 }}>
          {"Draft"}
        </h4>
        <div className="d-xl-flex">
          <div className="w-100">
            {
              loading ? <Loader/>:
              <div className="w-100" style={{ marginTop: 5 }}>
              <JobsGrid collectionName={DRAFTS_DB_NAME}/>
              <PrevNextUI onClick={runPagination} disablePrev={page === 1 } disableNext={page==totalPage}/>
           
            </div>
            }
           
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
