import React, { useRef, useState } from "react";
import { isEmpty } from "lodash";
import {
  addJobChatComment,
  addJobChatNotification,
  uploadImageToFirebaseStorage,
} from "services";
import {
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { getBase64String } from "common/utils";
import PropTypes from "prop-types";
import { Button, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function ChatInputField({ job ,collectionName}) {
  const { currentUserProfile } = useSelector(state => state.profile);
  const [chatMessage, setChatMessage] = useState("");
  const fileInput = useRef(null);
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);

  const onKeyPress = e => {
    const { key, value } = e;
    if (key === "Enter") {
      e.preventDefault();
      onSend();
    }
  };

  const onSend = () => {
    uploadImagesToChat();
    addChatTextComment();
  };

  const addChatTextComment = () => {
    if (!isEmpty(chatMessage.trim())) {
      addJobChatComment(job.id, {
        comment: chatMessage,
        parentId: 0,
        imageUrl: "",
      },collectionName);
      addJobChatNotification(job.id,job.accessIds,job.jobNo,collectionName);
    }
    resetChatText();
  };

  const addChatImageAttachments = downloadURL => {
    if (!isEmpty(downloadURL.trim())) {
      addJobChatComment(job.id, {
        comment: "",
        parentId: 0,
        imageUrl: downloadURL,
      },collectionName);
      addJobChatNotification(job.id, job.accessIds,job.jobNo,collectionName);
    }
  };

  const resetChatText = () => {
    setChatMessage("");
  };

  const resetChatAttachments = () => {
    setImages([]);
  };

  const openFileBrowser = () => {
    fileInput.current.click();
  };

  const handleSelectedFiles = event => {
    const targetFiles = event.target.files;
    const imageFiles = [];

    if (targetFiles.length > 10) {
      toast.error("10 images are allowed to be select at a time.");
      fileInput.current.value = ""; 
      return;
    }
    [...targetFiles].forEach(file => {
      // Checking Duplicates entries
      if (images.findIndex(f => f.name === file.name) === -1) {
        imageFiles.push({
          name: file.name,
          preview: URL.createObjectURL(file),
          file: file,
        });
      }
    });
    setImages([...images, ...imageFiles]);
  };

  const handleRemoveFile = e => {
    const updatedFiles = images.filter((_item, index) => index !== e);
    setImages(updatedFiles);
  };

  const uploadImagesToChat = () => {
    if (images.length > 10) {
      toast.error("10 images are allowed to be upload at a time.");
      return;
    }
    images?.forEach(async image => {
      setUploading[true];
      const base64String = await getBase64String(image.file);
      const uploadTask = uploadImageToFirebaseStorage(
        base64String,
        image.name,
        image.file.type,
        job.orgId,
        job.jobNo
      );
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setUploading(progress !== 100);
        },
        error => {
          console.error("error", error.messege);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            if (image.file.type.includes("image")) {
              addChatImageAttachments(downloadURL);
            }
          });
        }
      );
    });
    setUploading(false);
    resetChatAttachments();
  };

  return (
    <div className="text-center mt-4 pt-2">
      <Row>
        <Col>
          <div className="form-control chat-input">
            <TextField
              onKeyPress={onKeyPress}
              onChange={e => setChatMessage(e.target.value)}
              value={chatMessage}
              placeholder="Enter Message..."
              fullWidth
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Upload Images" placement="top">
                      <IconButton
                        className="mdi mdi-file-image-outline"
                        id="attach-chat-image"
                        onClick={openFileBrowser}
                      ></IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
            <input
              title="Attach Images"
              type="file"
              multiple
              accept="image/png,image/gif,image/jpeg"
              ref={fileInput}
              style={{ display: "none" }}
              onChange={handleSelectedFiles}
              onClick={event => {
                event.target.value = "";
              }}
            />
            {!uploading && !isEmpty(images) && (
              <div className="pt-2" style={{ width: "fit-content" }}>
                {images.map((item, index) => {
                  return (
                    <span key={index} className="mr-2">
                      <img
                        src={item.preview}
                        className="mb-2 pr-1"
                        alt="preview"
                        width="45"
                        height="45"
                        style={{ verticalAlign: "text-top" }}
                      />
                      <HighlightOffOutlinedIcon
                        fontSize="small"
                        color="warning"
                        className="pr-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveFile(index)}
                      />
                    </span>
                  );
                })}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="mt-2">
          {uploading && <LinearProgress variant="indeterminate" />}
        </Col>
      </Row>
      <Row>
        <Col className="col-auto mt-2 ms-2">
          <Button
            title="Send Comments"
            disabled={isEmpty(chatMessage.trim()) && isEmpty(images)}
            type="button"
            color="primary"
            onClick={onSend}
            className="btn btn-primary btn-rounded chat-send w-md "
          >
            <span className="d-none d-sm-inline-block me-2">Send</span>{" "}
            <i className="mdi mdi-send" />
          </Button>
        </Col>
      </Row>
    </div>
  );
}
ChatInputField.propTypes = {
  job: PropTypes.object.isRequired,
};
