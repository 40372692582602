import React from "react";
import { Link } from "react-router-dom";

export function PlatfromOwnerSidebar({props}) {
  return <>
    <li className="menu-title" style={{fontSize:"16px",color:"black"}}>{props.t("Dashboard")} </li>
    <li>
      <Link
        to="/dashboard"
        className={props.location.pathname.includes("/dashboard")
          ? "mm-active"
          : ""}
      >
        <i className="bx bx-home"></i>
        <span>{props.t("Overview")}</span>
      </Link>
    </li>
    <li className="menu-title" style={{color:"black",fontSize:"14px"}}>{props.t("PLatform Mangement")} </li>
    <li>
      <Link
        to="/org-management"
        className={props.location.pathname.includes("/org-management")
          ? "mm-active"
          : ""}
      >
        <i className="bx bx-wrench"></i>
        <span>{props.t("Manage Organisation")}</span>
      </Link>
    </li>
    <li>
      <Link
        to="/subscriptions"
        className={props.location.pathname.includes("/subscriptions")
          ? "mm-active"
          : ""}
      >
        <i className="bx bx-wrench"></i>
        <span>{props.t("Manage Subscription")}</span>
      </Link>
    </li>
  </>;
}
