// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.org-card {
  min-height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex; }

.org-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.status-label {
  margin-right: 10px;
  margin-top: 8px; }
`, "",{"version":3,"sources":["webpack://./src/pages/platformManagement/org-creation/OrgManagement.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAA;;AAEf;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B,EAAA;;AAEhC;EACE,kBAAkB;EAClB,eAAe,EAAA","sourcesContent":[".org-card {\n  min-height: 500px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n}\n.org-form {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.status-label {\n  margin-right: 10px;\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
