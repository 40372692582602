import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $getSelection, $insertNodes } from "lexical";
import { useEffect } from "react";
import { $patchStyleText } from "@lexical/selection";
export default function TreeViewPlugin({
  isDescriptionDirty,
  setIsDescriptionDirty,
  handleDescriptionChange,
  index,
  description,
  jobReportTemplate,
  setJobReportTemplate,
  setDirty,
  setIsChecklistChanged
}) {
  const [editor] = useLexicalComposerContext();
  const generateHtml = editor => {
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);
      handleDescriptionChange(
        index,
        htmlString,
        jobReportTemplate,
        setJobReportTemplate,
        setDirty,
        setIsChecklistChanged
      );
    });
  };
  useEffect(() => {
    const handleConversion = editor => {
      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(
          `<p>${description}</p>`,
          "text/html"
        );
        const nodes = $generateNodesFromDOM(editor, dom);
        $getRoot()?.getChildAtIndex(0)?.select();
        const selection = $getSelection();
        if (selection) {
          $patchStyleText(selection, {
            "font-family": "Roboto",
          });
        }
        $getRoot().select();
        $insertNodes(nodes);
      });
    };
    handleConversion(editor);
  }, []);
  useEffect(() => {
      generateHtml(editor);
      setIsDescriptionDirty(false);
  }, [isDescriptionDirty]);
  return null;
}
