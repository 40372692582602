import { ORG_ERROR_MESSAGE } from "common/constants";
import firebase from "../../config/firebase";
const db = firebase.firestore();

export function addOrgToDb(orgDetails) {
  const user = firebase.auth().currentUser;
  return db.collection("orgs").add({
    ...orgDetails,
    createdByUserId: user.uid,
    nameWithoutCase: orgDetails?.name?.toLowerCase(),
    date: firebase.firestore.FieldValue.serverTimestamp(),
  });
}
export function updateOrgInDb(org) {
  return db.collection("orgs").doc(org.id).update(org);
}
export const fetchAllOrgFromDb = () => {
  return firebase.firestore().collection("orgs");
};

export function getOrgFromDb(id) {
  return firebase.firestore().collection("orgs").doc(id).get();
}

export async function updateOrgDetailsInDB(org, checkForuniqness, orgId) {
  if (checkForuniqness) {
    const result = await db
      .collection("orgs")
      .where("nameWithoutCase", "==", org?.name?.toLowerCase())
      .get();
    if (!result.empty) {
      return ORG_ERROR_MESSAGE;
    }
  }
  return db
    .collection("orgs")
    .doc(orgId)
    .update({
      ...org,
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    });
}
