import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import AdressAutoComplete from "components/AddressAutoComplete/AddressAutoComplete";
import MuiPhoneNumber from "mui-phone-number";
import { useState } from "react";
import Editable from "react-bootstrap-editable";
import { Button, Card, CardBody, Media } from "reactstrap";
import {
  updateJobAddress,
  updateClientEmail,
  updateClientMobile,
  updateClientName,
  updateJobName,
  updateCustomJobNo,
  fetchJobsByJobNo,
  dataFromSnapshot,
  updateClientDetails,
} from "services";
import { isValidEmail } from "common/utils";
import { useSelector } from "react-redux";
import Category from "../jobTypes/Category";
import {
  INTERNAL,
  EXTERNAL,
  BELOW_GROUND,
  INSPECTION_DB_NAME,
  ACTIVE_JOBS_DB_NAME,
  AVATAR_URL,
} from "common/constants";
import { map } from "lodash";
import LinkJobsInJobsDetails from "../jobCreation/jobLinkage/LinkJobsInJobsDetails";
import UploadJobAvatar from "pages/company/UploadJobAvatar";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import jobsitephoto from "../../../assets/images/jobsite.jpg";
import JobDates from "./JobDates";
// import { pointer } from "@testing-library/user-event/dist/types/pointer";
export default function JobDetail({ job, canEdit, collectionName }) {
  const { currentUserProfile } = useSelector(state => state.profile);
  const [editPhone, setEditPhone] = useState(false);
  const [editAdreess, setEditAddress] = useState(false);
  const [phone, setPhone] = useState(job?.client?.mobile || "");
  const [address, setAddress] = useState(job?.jobAddress || "");
  const [countryDialCode, setCountryDialCode] = useState(
    job?.client?.countryDialCode || ""
  );
  const [editAvatar, setEditAvatar] = useState(false);
  const history = useHistory();
  const isEmailAlreadyExists = value => {
    const existingUsers = [];
    job.participants?.map(existingUser => {
      if (
        existingUser.email.toLowerCase() === value.trim().toLowerCase() &&
        existingUser.email.toLowerCase() !==
          currentUserProfile.email.toLowerCase()
      ) {
        existingUsers.push(existingUser.email.toLowerCase());
      }
    });

    if (existingUsers?.length) {
      return true;
    }

    return false;
  };
  const isInternal = !!job.internal;
  const isExternal = !!job.external;
  const isBelowground = !!job.belowground;

  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const handleLinkedJobs = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handlePhoneNumberChange = (value, dialCode) => {
    if (
      value?.replace(`+${dialCode}`, "")?.length <= 10 &&
      value?.replace(`+${dialCode}`, "")?.length > 4
    ) {
      setError(false);
    } else {
      setError(true);
    }
    setPhone(value);
    setCountryDialCode(dialCode);
  };
  const getLinkedJobPathName = () => {
    if (collectionName === INSPECTION_DB_NAME) {
      return "active";
    }
    return "inspectionactive";
  };

  return (
    <>
      {editAvatar && (
        <UploadJobAvatar
          editAvatar={editAvatar}
          setEditAvatar={setEditAvatar}
          jobId={job.id}
          collectionName={collectionName}
        />
      )}
      {isOpen && (
        <LinkJobsInJobsDetails
          isOpen={isOpen}
          closeModal={closeModal}
          job={job}
          collectionName={collectionName}
        />
      )}
      <Card>
        <CardBody>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {" "}
              <Media>
                <div
                  className="edit"
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src={job?.photoURL || jobsitephoto}
                    alt="Add"
                    className="avatar-xl me-4 rounded-circle"
                    onClick={() => setEditAvatar(true)}
                    onMouseEnter={() => {
                      setIsMouseOver(true);
                    }}
                    style={{ cursor: "pointer" }}
                    onMouseLeave={() => setIsMouseOver(false)}
                  />
                   <i
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          left: "-1rem",
                          fontSize: "16px",
                          color:"#50a5f1",
                          cursor:"pointer",


                        }}
                        onClick={()=>setEditAvatar(true)}
                      >Edit</i>
                </div>
                <Media className="overflow-hidden" body>
                  <h5 className="text-truncate font-size-15">{`${
                    job?.orgIdentityCode || ""
                  }  ${job.jobNo}`}</h5>
                  <h5 className="text-truncate font-size-15">
                    <Editable
                      ajax={null}
                      alwaysEditing={false}
                      className={null}
                      disabled={!canEdit}
                      editText="Edit Custom Job Number"
                      id={null}
                      initialValue={job?.customjobno}
                      isValueClickable={!!job?.customjobno}
                      mode="inline"
                      onSubmit={async value => {
                        if (value) {
                          job.customjobno = value;
                          await updateCustomJobNo(
                            job.id,
                            value,
                            collectionName
                          );
                        }
                      }}
                      onValidated={null}
                      options={null}
                      renderCancelElement={null}
                      renderConfirmElement={null}
                      showText
                      type="textfield"
                      validate={value => {
                        if (!value) {
                          return "Required";
                        }
                      }}
                    />
                  </h5>
                  <h5 className="text-truncate font-size-15">
                    <Editable
                      ajax={null}
                      alwaysEditing={false}
                      className={null}
                      disabled={!canEdit}
                      editText="Edit Job Name"
                      id={null}
                      initialValue={job.jobName}
                      isValueClickable={!!job.jobName}
                      mode="inline"
                      onSubmit={async value => {
                        if (value) {
                          job.jobName = value;
                          await updateJobName(job.id, value, collectionName);
                        }
                      }}
                      onValidated={null}
                      options={null}
                      renderCancelElement={null}
                      renderConfirmElement={null}
                      showText
                      type="textfield"
                      validate={value => {
                        if (!value) {
                          return "Required";
                        }
                      }}
                    />
                  </h5>
                  <h5 className="text-truncate font-size-15">
                    <Editable
                      ajax={null}
                      alwaysEditing={false}
                      className={null}
                      disabled={!canEdit}
                      editText="Edit"
                      id={null}
                      initialValue={job.client.displayName}
                      isValueClickable={!!job.client.displayName}
                      mode="inline"
                      onSubmit={async value => {
                        if (value) {
                          job.client.displayName = value;
                          await updateClientName(job.id, value, collectionName);
                        }
                      }}
                      onValidated={() => {}}
                      options={null}
                      renderCancelElement={null}
                      renderConfirmElement={null}
                      showText
                      type="textfield"
                      validate={value => {
                        if (!value) {
                          return "Required";
                        }
                      }}
                    />
                  </h5>
                  {editAdreess && canEdit ? (
                    <>
                      <h5 className="font-size-15">
                        <AdressAutoComplete
                          address={address}
                          initialAddressValue={job.jobAddress}
                          setAddress={setAddress}
                          setEditAddress={setEditAddress}
                          onSaveClickHandler={async () => {
                            if (address) {
                              job.jobAddress = address;
                              await updateJobAddress(
                                job.id,
                                address,
                                collectionName
                              );
                              setEditAddress(false);
                            }
                          }}
                        />

                        {!address && (
                          <small className="requiredError">{"*Required"}</small>
                        )}
                      </h5>
                    </>
                  ) : (
                    <div>
                      <label
                        style={{
                          color: canEdit ? "#50a5f1" : null,
                          width:"90%"
                        }}
                        onClick={() => {
                          {
                            setEditAddress(!editAdreess);
                          }
                        }}
                      >
                        <span
                          className=" font-size-15"
                          style={{
                            display: "block",
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                            wordBreak: "break-word",
                          }}
                        >
                          {job.jobAddress || "Edit Job Address"}
                        </span>
                      </label>
                    </div>
                  )}
                  <h5 className="text-truncate font-size-15">
                    <ul
                      className="list-inline mb-0 flex-start-center"

                    >
                      <li className="list-inline-item font-size-15">
                        {isInternal && <Category type={INTERNAL} job={job} />}
                      </li>
                      <li className="list-inline-item font-size-15">
                        {isExternal && <Category type={EXTERNAL} job={job} />}
                      </li>
                      <li className="list-inline-item font-size-15">
                        {isBelowground && (
                          <Category type={BELOW_GROUND} job={job} />
                        )}
                      </li>
                    </ul>
                  </h5>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: "-1.5rem",
                    }}
                  >
                    <JobDates 
                      jobStartDate={job.startDate || null}
                      jobEndDate={job.endDate || ""}
                      collectionName={collectionName}
                      jobId={job.id}
                    />
                  </div>
                      <div
                  className="text-muted mt-4 font-size-15"
                  style={{display: "flex" ,alignItems:"baseline"}}
                >
                  <h5 style={{ marginRight: "1rem" }} className="font-size-15"> {`Post Code `}</h5>
                  <Editable
                    ajax={null}
                    alwaysEditing={false}
                    className={null}
                    disabled={!canEdit}
                    editText="Edit"
                    id={null}
                    initialValue={job?.postCode}
                    isValueClickable={!!job?.postCode}
                    mode="inline"
                    onSubmit={async value => {
                        await updateClientDetails(job.id, {postCode:value}, collectionName);
                      
                    }}
                    onValidated={() => {}}
                    options={null}
                    renderCancelElement={null}
                    renderConfirmElement={null}
                    showText
                    type="textfield"
                    validate={value => {
                      if (!value) {
                        return "Required";
                      }
                    }}
                  />
                </div>
                </Media>
              </Media>
              <h5 className="font-size-15 mt-4">Contact Details</h5>
              <div style={{ display: "flex" ,alignItems:"center",justifyContent:"space-between",width:"90%"}}>
                <div
                  className="text-muted mt-4 font-size-15"
                  style={{ display: "flex" ,alignItems:"baseline"}}
                >
                  <h5 style={{ marginRight: "1rem" }} className="font-size-15"> {`Mobile `}</h5>
                  {editPhone && canEdit ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "280px",
                          paddingTop: "5px",
                        }}
                      >
                        <div style={{ flex: 1,alignItems: "center",marginRight:"0.1rem" }}>
                          <MuiPhoneNumber
                            name="phone"
                            label="Mobile"
                            defaultCountry={"au"}
                            variant="outlined"
                            className="phoneNumber font-size-15"
                            value={phone}
                            size="small"
                            fullWidth
                            onChange={(text, country) =>
                              handlePhoneNumberChange(text, country?.dialCode)
                            }
                            autoFocus
                            autoFormat={false}
                            error={error}
                          />
                          {(phone.length < 4 && (
                            <small className="requiredError">
                              {"*Required"}
                            </small>
                          )) ||
                            (error && (
                              <small className="requiredError">
                                {"*Invalid Phone number"}
                              </small>
                            ))}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <CheckOutlinedIcon
                            className="saveOrCancelButton"
                            style={{
                              backgroundColor: "#34c38f",
                            }}
                            onClick={async () => {
                              const mobile = phone === "+" ? "" : phone;
                              if (
                                mobile &&
                                mobile.replace(`+${countryDialCode}`, "")
                                  .length <= 10 &&
                                mobile.replace(`+${countryDialCode}`, "")
                                  .length > 4
                              ) {
                                job.client.mobile = mobile;
                                await updateClientMobile(
                                  job.id,
                                  mobile,
                                  collectionName,
                                  countryDialCode
                                );
                                setEditPhone(false);
                              }
                            }}
                          />
                          <CloseIcon
                            style={{
                              backgroundColor: "#f46a6a",
                            }}
                            className="saveOrCancelButton"
                            onClick={() => {
                              setPhone("");
                              setEditPhone(false);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      <label
                        style={{
                          color: canEdit ? "#50a5f1" : null,
                        }}
                        onClick={() => setEditPhone(!editPhone)}
                      >
                        {job.client.mobile || "Edit"}
                      </label>
                    </div>
                  )}
                </div>

                <div
                  className="text-muted mt-4 font-size-15"
                  style={{display: "flex" ,alignItems:"baseline"}}
                >
                  <h5 style={{ marginRight: "1rem" }} className="font-size-15"> {`Email `}</h5>
                  <Editable
                    ajax={null}
                    alwaysEditing={false}
                    className={null}
                    disabled={!canEdit}
                    editText="Edit"
                    id={null}
                    initialValue={job.client.email}
                    isValueClickable={!!job.client.email}
                    mode="inline"
                    onSubmit={async value => {
                      if (
                        value &&
                        isValidEmail(value.trim()) &&
                        !isEmailAlreadyExists(value)
                      ) {
                        job.client.email = value;
                        await updateClientEmail(job.id, value, collectionName);
                      }
                    }}
                    onValidated={() => {}}
                    options={null}
                    renderCancelElement={null}
                    renderConfirmElement={null}
                    showText
                    type="textfield"
                    validate={value => {
                      if (!value) {
                        return "Required";
                      }
                      if (value && !isValidEmail(value.trim())) {
                        return "Invalid Email";
                      }
                      if (isEmailAlreadyExists(value)) {
                        return "Email Already Exists";
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginRight: "30px",display:"flex",flexDirection:"column",alignItems:"center" }}>
              <div>
                 {collectionName === INSPECTION_DB_NAME && (
                <Button
                  variant="contained"
                  color="primary"
                  className="btn btn-primary btn-sm"
                  onClick={handleLinkedJobs}
                >
                  <span className="font-size-15">Link Jobs</span>
                </Button>
              )}
              </div>
              <div>
                 {job?.linkedJobs?.length > 0 && (
                <Card
                  style={{
                    maxHeight: "200px",
                    overflow: "auto",
                    alignItems: "center",
                  }}
                >
                  <CardBody>
                    <h5
                      className="font-size-15"
                      style={{ textAlign: "center" }}
                    >
                      Linked Jobs
                    </h5>
                    {map(job?.linkedJobs, (checkedJob, i) => {
                      return (
                        <>
                          <br />
                          <button
                            className="btn btn-primary btn-sm"
                            style={{
                              textDecoration: "underline",
                              color: "#50a5f1",
                              backgroundColor: "white",
                              border: "none",
                              cursor: "pointer",
                              marginBottom: "5px",
                            }}
                            onClick={() => {
                              const jobRef = fetchJobsByJobNo(
                                ACTIVE_JOBS_DB_NAME,
                                checkedJob?.jobNo,
                                currentUserProfile?.id
                              );
                              jobRef.onSnapshot(snapshot => {
                                const jobs = snapshot.docs.map(doc =>
                                  dataFromSnapshot(doc)
                                );
                                if (jobs.length != 0) {
                                  history.push(
                                    `/${getLinkedJobPathName()}/${
                                      checkedJob.id
                                    }`
                                  );
                                } else {
                                  toast.error(
                                    "Access denied - please request access to original job",
                                    {
                                      autoClose: 2000,
                                    }
                                  );
                                }
                              });
                            }}
                          >
                            <span className="font-size-15">
                              {" "}
                              Job No: {checkedJob.jobNo}
                            </span>
                          </button>
                        </>
                      );
                    })}
                  </CardBody>
                </Card>
              )}
              </div>
             
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
