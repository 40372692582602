import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, UncontrolledTooltip } from "reactstrap";
import { useSelector } from "react-redux";
import CircleIcon from "@mui/icons-material/Circle";
import { totalPercentage } from "pages/jobs/jobTypes/active/percentageCalculation";
import { MenuType } from "components/jobs-ui/MenuType";
import { AVATAR_URL, BELOW_GROUND, EXTERNAL, INTERNAL } from "common/constants";
import Category from "pages/jobs/jobTypes/Category";
import { useLinkJobContext } from "context/LinkjobContext";
import { currentUserHasFullAccess } from "common/utils";

export const InspectionListView = ({
  result,
  job,
  setSearchTerm,
  notification,
  resultSearchTerm,
  pathname,
  setJobUpdate,
  isLinkEnabled,
  collectionName,
}) => {
  const { currentUserProfile } = useSelector(state => state.profile);
  const { jobs } = useSelector(state => state.job);
  const {
    internal: isInternal,
    external: isExternal,
    belowground: isBelowground,
    report: isReport,
    other: isOther,
  } = JSON.parse(result.jobtype.raw);
  const [percentage, setPercentage] = useState(0);
  const { checkedItems, toggleCheckbox } = useLinkJobContext();
  const isChecked = checkedItems.some(
    object => object.id === job.id && object.jobNo === job.jobNo
  );
  const handleCheckboxChange = () => {
    toggleCheckbox({ id: job.id, jobNo: job.jobNo });
  };
  useEffect(() => {
    const nonMatchingjobIds = checkedItems.filter(
      checkedJob => !jobs.some(job => job.id === checkedJob.id)
    );
    nonMatchingjobIds.map(checkedJob => {
      toggleCheckbox({ id: checkedJob.id, jobNo: checkedJob.jobNo });
    });
  }, [jobs]);
  useEffect(() => {
    const calculateTotalPercentage = async () => {
      const result = await totalPercentage(job);
      setPercentage(result);
    };
    calculateTotalPercentage();
  }, [job]);
  return (
    <React.Fragment>
      <tbody>
        <tr onClick={handleCheckboxChange}>
          <td>
            {notification !== undefined ? (
              <CircleIcon
                className="mr-4 text-wrap font-size-14"
                style={{ fill: "#50A5F1", display: "inline-block" }}
              />
            ) : null}
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            {isLinkEnabled ? (
              <h5
                className="text-wrap font-size-14"
                style={{
                  textIndent: notification !== undefined ? "15px" : "30px",
                  display: "inline-block",
                }}
              >
                <Link
                  to={{
                    pathname: `${pathname}/${result.id.raw}`,
                    state: {
                      pageNo: location?.search,
                      query: resultSearchTerm,
                      type: pathname,
                    },
                  }}
                  className="text-dark"
                >
                  {`${result?.orgidentitycode?.raw} ${result.jobno.raw}`}
                </Link>
              </h5>
            ) : (
              <h5
                className="text-wrap font-size-14"
                style={{
                  textIndent: notification !== undefined ? "15px" : "30px",
                  display: "inline-block",
                }}
              >
                {`${result?.orgidentitycode?.raw} ${result.jobno.raw}`}
              </h5>
            )}
          </td>
          <td>
            {isLinkEnabled ? (
              <h5 className="text-wrap font-size-14">
                <Link
                  to={{
                    pathname: `${pathname}/${result.id.raw}`,
                    state: {
                      pageNo: location?.search,
                      query: resultSearchTerm,
                      type: pathname,
                    },
                  }}
                  className="text-dark"
                >
                  {result.jobname?.raw}
                </Link>
              </h5>
            ) : (
              <h5 className="text-wrap font-size-14"> {result.jobname?.raw}</h5>
            )}
          </td>
          <td>
            {isLinkEnabled ? (
              <h5 className="text-wrap font-size-14">
                <Link
                  to={{
                    pathname: `${pathname}/${result.id.raw}`,
                    state: {
                      pageNo: location?.search,
                      query: resultSearchTerm,
                      type: pathname,
                    },
                  }}
                  className="text-dark"
                >
                  {result.client__displayname.raw}
                </Link>
              </h5>
            ) : (
              <h5 className="text-wrap font-size-14">
                {" "}
                {result.client__displayname.raw}
              </h5>
            )}
          </td>
          <td>
            {isLinkEnabled ? (
              <h5 className="text-wrap font-size-14">
                <Link
                  to={{
                    pathname: `${pathname}/${result.id.raw}`,
                    state: {
                      pageNo: location?.search,
                      query: resultSearchTerm,
                      type: pathname,
                    },
                  }}
                  className="text-dark"
                >
                  {result.jobaddress.raw}
                </Link>
              </h5>
            ) : (
              <h5 className="text-wrap font-size-14">
                {" "}
                {result.jobaddress.raw}
              </h5>
            )}
          </td>

          <td>
            <h5 className="text-wrap font-size-14">
              {isInternal && <Category type={INTERNAL} job={job} isList />}
              {isExternal && <Category type={EXTERNAL} job={job} isList />}
              {isBelowground && (
                <Category type={BELOW_GROUND} job={job} isList />
              )}
              {isReport && <Badge className={"bg-secondary"}>{"Report"}</Badge>}
              {isOther && <Badge className={"bg-danger"}>{"Other"}</Badge>}
            </h5>
          </td>
          <td>
            <div className="avatar-group">
              {map(result.participants.raw, (member, index) => {
                const { displayName, id } = JSON.parse(member);
                return (
                  <div className="avatar-group-item" key={index.toString()}>
                    <div
                      className="team-member d-inline-block"
                      id={"member" + id}
                    >
                      <img
                        src={
                          JSON.parse(member).photoURL ||
                          `${AVATAR_URL}&name=${displayName}`
                        }
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={"member" + id}
                      >
                        {displayName}
                      </UncontrolledTooltip>
                    </div>
                  </div>
                );
              })}
            </div>
          </td>
          <td>
            {currentUserHasFullAccess(job, currentUserProfile.id) && (
              <MenuType
                pathname={pathname}
                setSearchTerm={setSearchTerm}
                job={job}
                setJobUpdate={setJobUpdate}
                isLinkEnabled={isLinkEnabled}
                collectionName={collectionName}
              />
            )}
          </td>
        </tr>
      </tbody>
    </React.Fragment>
  );
};
