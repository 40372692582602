import React, { memo, useCallback, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import dragReorder from "assets/images/icons8/icons8-scroll.png";
import cuid from "cuid";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Label } from "reactstrap";
import CheckListItem from "../../jobDetails/rooms/checklist/CheckListUnit";
import "./addSection.scss";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Loader from "components/Loader";
const MemoizedTextField = memo(TextField);
const SectionHeading = memo(({ onChange, content, index, itemId } = props) => {
  const [heading, setHeading] = useState(content || "New Checklist Heading");
  return (
    <>
      <div className="headingContainer">
        <span className="headingContainer__section-index">
          {index + 1 + ". "}
        </span>
        <MemoizedTextField
          multiline={true}
          variant="standard"
          id="standard-textarea"
          placeholder="Add Heading"
          className="headingContainer-text"
          style={{
            width: "84%",
            fontSize: "16px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "24px",
          }}
          value={heading}
          onChange={e => {
            setHeading(e.target.value);
          }}
          onBlur={() => {
            if (heading) {
              onChange(heading, itemId, true);
            }
          }}
        />
      </div>
      {!heading && <small className="headingRequired">*Required</small>}
    </>
  );
});

const PlaceChecklistItem = memo(() => {
  return <div className="placeChecklist">Place Checklist Item</div>;
});

const AddSection = ({
  item,
  index,
  setItems,
  items,
  onChangeTextHeader,
  onRemoveCheckListGroup,
  setDirty,
  onRemoveCheckListItem,
  onChangeItemData,
  isValidChecklist,
  setIsValidChecklist,
  setIsChecklistChanged = () => { },
} = props) => {
  const [isTextVisible, setTextVisible] = useState(true);

  const toggleTextVisibility = () => {
    setTextVisible(!isTextVisible);
  };

  const onAddItem = useCallback(() => {
    setIsValidChecklist(false);
    setItems([
      ...items.map(itm => {
        if (itm.id === item.id) {
          itm.subItems = [
            ...itm.subItems,
            {
              id: cuid(),
              content: "",
              checkBox: true,
              checked: false,
              documents: [],
              comment: false,
              comments: [],
              attachment: false,
              attachments: [],
              signature: false,
              signatures: [],
              holdPoint: false,
              helpPoint: false,
              locked: false,
              isValidItem: false,
            },
          ];
        }
        return itm;
      }),
    ]);
  }, [items]);

  const onAddHoldPoint = useCallback(() => {
    setIsChecklistChanged(true);
    setItems([
      ...items.map(itm => {
        if (itm.id === item.id) {
          itm.subItems = [
            ...itm.subItems,
            {
              id: cuid(),
              checkBox: false,
              checked: false,
              documents: [],
              comment: false,
              comments: [],
              attachment: false,
              attachments: [],
              signature: true,
              signatures: [],
              holdPoint: true,
              helpPoint: false,
              locked: false,
              content: "Hold Point",
              uploaded: false,
              type: "digitalsign",
              isValidItem: true,
            },
          ];
        }
        return itm;
      }),
    ]);
    setDirty(true);
  }, [items]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (items?.length > 30) {

      const timer = setTimeout(() => {
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    } else {

      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [items]);

  return (
    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
      {(provided, snapshot) => (
        <div>
          {loading ? <Loader /> :
            <div ref={provided.innerRef} {...provided.draggableProps}>
              <Card raised={false} >
                <CardHeader
                  subheader="SECTION"
                  action={
                    <IconButton
                      onClick={() => {
                        onRemoveCheckListGroup(item.id);
                        setDirty(true);
                        setIsChecklistChanged(true);
                      }}
                    >
                      <CloseIcon style={{ color: "#D57D7D" }} />
                    </IconButton>
                  }
                />

                <CardContent>
                  <SectionHeading
                    key={item.id}
                    itemId={item.id}
                    onChange={() => { }}
                    content={item.content}
                    index={index}
                  />
                  <button
                    onClick={toggleTextVisibility}
                    style={{
                      float: "right",
                      border: "none",
                      background: "none",

                    }}
                  >
                    {isTextVisible ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </button>
                  <div className="sectionContainer">
                    <div className="itemsContainer">

                      {isTextVisible && !snapshot?.isDragging && (
                        <div style={{ maxHeight: "400px", overflow: "auto" }}>
                          <CheckListItem
                            subItems={item.subItems}
                            mainIndex={index + 1}
                            type={item.id}
                            onDelete={onRemoveCheckListItem}
                            onChange={onChangeItemData}
                            allowed={"droppableSubItem"}
                            setDirty={setDirty}
                            isValidChecklist={isValidChecklist}
                            setIsValidChecklist={setIsValidChecklist}
                          />
                        </div>
                      )}

                      <PlaceChecklistItem />

                    </div>

                    <span {...provided.dragHandleProps}>
                      <img
                        src={dragReorder}
                        style={{
                          float: "right",
                          height: "30px",
                          marginLeft: "20px",
                          transform: "translate(0, -5px)",
                        }}
                      />
                    </span>
                  </div>
                </CardContent>
                <CardActions style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex" }}> <Label
                    onClick={onAddHoldPoint}
                    className="builder-actions__label"
                    style={{
                      marginRight: "20px",

                    }}
                  >
                    Add HoldPoint
                  </Label>
                    <Label className="builder-actions__label" onClick={onAddItem}>
                      Add Checklist Item
                    </Label></div>

                  <Label>Total Items: {item?.subItems?.length}</Label>
                </CardActions>
              </Card>
            </div>}
        </div>
      )}
    </Draggable>
  );
};

export default memo(AddSection);
