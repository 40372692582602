import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";

const NotFound = ({ message }) => {
  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        height: "20vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {message}
    </div>
  );
};
export default NotFound;
