import { memo, useState } from "react";
import { Alert, Button, Input } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { getInspectionTemplate, getJobReportTemplates } from "services";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

export function OverRideInspection(buttonStyle) {
  return memo(
    ({
      setVisibility,
      onUpdate,
      onCreateNew,
      setNewName,
    }) => {
      const { currentUserProfile } = useSelector(state => state.profile);
      const [isCreateNew, setIsCreateNew] = useState(false);
      const [newTemplateName, setNewTemplateName] = useState("");
      const [inputError, setInputError] = useState("");
      const closeModal = () => {
        setVisibility(false);
      };

      const handleCreateNew = async () => {
        if (newTemplateName.length === 0) {
          setInputError("Please enter the name");
          return;
        }
        const InspectionSnapshot = await getInspectionTemplate(
          currentUserProfile?.orgAccessId
        );
        const InspectionData = InspectionSnapshot?.docs?.map(doc => doc.data());
        const isInspectionWithSameName = InspectionData?.find(
          Inspection =>
            Inspection.templateName.toLowerCase() ===
            newTemplateName.toLowerCase()
        );
        if (isInspectionWithSameName) {
          setInputError("A template already exists with the same name");
          return;
        }
        setNewName(newTemplateName);
        onCreateNew(newTemplateName);
      };

      const handleInput = e => {
        setInputError("");
        setNewTemplateName(e.target.value.trim());
      };

      return (
        <>
          <SweetAlert
            title="Overwrite the Inspection template or create new?"
            warning
            showCancel={false}
            showConfirm={false}
            closeOnClickOutside
            onCancel={closeModal}
          >
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                backgroundColor: "white",
                top: "1rem",
                right: "1rem",
                border: "none",
              }}
            >
              <CloseIcon
                style={{
                  color: "gray",
                }}
              />
            </button>
            <p>
              Do you want to update the existing Inspection template or create
              new?
            </p>
            {isCreateNew ? (
              <div>
                <Input
                  name="InspectionName"
                  placeholder="New Inspection template name"
                  onChange={handleInput}
                  style={{ marginBottom: 5 }}
                />
                {inputError && (
                  <Alert
                    severity="error"
                    style={{
                      padding: "0px 5px",
                      marginBottom: 10,
                      color: "red",
                      background: "none",
                      border: "none",
                    }}
                  >
                    {inputError}
                  </Alert>
                )}
                <div
                  style={{
                    padding: "1rem 0",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Button
                    color=""
                    style={{ ...buttonStyle, color: "red", border: "none" }}
                    onClick={() => {
                      setIsCreateNew(false);
                      setInputError("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    style={buttonStyle}
                    onClick={handleCreateNew}
                  >
                    Create New
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  padding: "1rem 0",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Button color="warning" style={buttonStyle} onClick={onUpdate}>
                  Overwrite
                </Button>
                <Button
                  color="success"
                  style={buttonStyle}
                  onClick={() => setIsCreateNew(true)}
                >
                  Create New
                </Button>
              </div>
            )}
          </SweetAlert>
        </>
      );
    }
  );
}
export function inspectionReportAlert() {
  return memo(({ setShowOverrideAlert }) => {
    return (
      <>
        <SweetAlert
          title="Inspection Report Name already exists"
          warning
          confirmButtonText="OK"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowOverrideAlert(false);
          }}
        >
          Please change the Inspection Report Name and try again.
        </SweetAlert>
      </>
    );
  });
}

export function jobReportAlert() {
  return memo(({ setShowOverrideAlert, }) => {
    return (
      <>
        <SweetAlert
          title={`TemplateName already exists`}
          warning
          confirmButtonText="OK"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowOverrideAlert(false);
          }}
        >
          Please change the Template Name and try again.
        </SweetAlert>
      </>
    );
  });
}

export function OverRideJobReportTemplate(buttonStyle) {
  return memo(
    ({
      setVisibility,
      onUpdate,
      onCreateNew,
      setNewName,
    }) => {
      const { currentUserProfile } = useSelector(state => state.profile);
      const [isCreateNew, setIsCreateNew] = useState(false);
      const [newTemplateName, setNewTemplateName] = useState("");
      const [inputError, setInputError] = useState("");
      const closeModal = () => {
        setVisibility(false);
      };

      const handleCreateNew = async () => {
        if (newTemplateName.length === 0) {
          setInputError("Please enter the name");
          return;
        }
        const JobSnapshot = await getJobReportTemplates(
          currentUserProfile?.orgAccessId
        );
        const JobData = JobSnapshot?.docs?.map(doc => doc.data());
        const isJobWithSameName = JobData?.find(
          JobReport =>
            JobReport.templateName.toLowerCase() ===
            newTemplateName.toLowerCase()
        );
        if (isJobWithSameName) {
          setInputError("A template already exists with the same name");
          return;
        }
        setNewName(newTemplateName);
        onCreateNew(newTemplateName);
      };

      const handleInput = e => {
        setInputError("");
        setNewTemplateName(e.target.value.trim());
      };

      return (
        <>
          <SweetAlert
            title={`Overwrite the template or create new?`}
            warning
            showCancel={false}
            showConfirm={false}
            closeOnClickOutside
            onCancel={closeModal}
          >
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                backgroundColor: "white",
                top: "1rem",
                right: "1rem",
                border: "none",
              }}
            >
              <CloseIcon
                style={{
                  color: "gray",
                }}
              />
            </button>
            <p>
             {` Do you want to update the existing template or create
              new ? `}
            </p>
            {isCreateNew ? (
              <div>
                <Input
                  name={` template name`}
                  placeholder={`New template name`}
                  onChange={handleInput}
                  style={{ marginBottom: 5 }}
                />
                {inputError && (
                  <Alert
                    severity="error"
                    style={{
                      padding: "0px 5px",
                      marginBottom: 10,
                      color: "red",
                      background: "none",
                      border: "none",
                    }}
                  >
                    {inputError}
                  </Alert>
                )}
                <div
                  style={{
                    padding: "1rem 0",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Button
                    color=""
                    style={{ ...buttonStyle, color: "red", border: "none" }}
                    onClick={() => {
                      setIsCreateNew(false);
                      setInputError("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    style={buttonStyle}
                    onClick={handleCreateNew}
                  >
                    Create New
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  padding: "1rem 0",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Button color="warning" style={buttonStyle} onClick={onUpdate}>
                  Overwrite
                </Button>
                <Button
                  color="success"
                  style={buttonStyle}
                  onClick={() => setIsCreateNew(true)}
                >
                  Create New
                </Button>
              </div>
            )}
          </SweetAlert>
        </>
      );
    }
  );
}