import {
  CREATE_JOB,
  FETCH_JOBS_FAILURE,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS,
  FETCH_ARCHIVED_SUCCESS,
  FETCH_DELETED_SUCCESS,
  FETCH_DRAFT_FAILURE,
  FETCH_DRAFT_SUCCESS,
  FETCH_SINGLE_DRAFT_SUCCESS,
  IS_CLONING,
} from "./actionTypes";

const initialState = {
  loading: false,
  isCloning: false,
  jobs: [],
  archived: [],
  deleted: [],
  drafts: [],
};

export default function jobReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_JOB:
      return {
        ...state,
        jobs: [...state.jobs, payload],
      };
    case FETCH_JOBS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        jobs: payload,
        loading: false,
      };
    case FETCH_JOBS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case FETCH_ARCHIVED_SUCCESS:
      return {
        ...state,
        archived: payload,
        loading: false,
      };
    case FETCH_DELETED_SUCCESS:
      return {
        ...state,
        deleted: payload,
        loading: false,
      };
    case FETCH_DRAFT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case FETCH_DRAFT_SUCCESS:
      return {
        ...state,
        drafts: payload,
        loading: false,
      };
    case FETCH_SINGLE_DRAFT_SUCCESS:
      return {
        ...state,
        drafts: [...state.drafts, payload],
        loading: false,
      };
    case IS_CLONING:
      return {
        ...state,
        isCloning: payload,
      };
    default:
      return state;
  }
}
