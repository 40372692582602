import React, { useEffect, useState } from "react";
import { currentUserHasFullAccess } from "common/utils";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import Category from "../../pages/jobs/jobTypes/Category";
import { INTERNAL, EXTERNAL, BELOW_GROUND } from "../../common/constants";
import { MenuType } from "./MenuType";
import { totalPercentage } from "pages/jobs/jobTypes/active/percentageCalculation";
import ProgressBar from "pages/jobstracker/HorizontalProgressBar";
import "./CardStyles.scss";
import { CardData } from "./CardData";
import "./CardStyles.scss";
const CardView = ({
  result,
  job,
  setSearchTerm,
  notification,
  resultSearchTerm,
  pathname,
  setJobUpdate,
  isLinkEnabled,
  collectionName,
}) => {
  const { currentUserProfile } = useSelector(state => state.profile);
  const {
    internal: isInternal,
    external: isExternal,
    belowground: isBelowground,
  } = JSON.parse(result.jobtype.raw);
  const [percentage, setPercentage] = useState(0);
  const [jobDate, setJobDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const getJobDate = jobStartDate => {
    const dateObject = new Date(jobStartDate);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const formattedDate =
      (day < 10 ? "0" : "") +
      day +
      "/" +
      (month < 10 ? "0" : "") +
      month +
      "/" +
      year;
   return formattedDate;
  };
  useEffect(() => {
    if(job.startDate){
      setStartDate(getJobDate(job.startDate));
    }
    if(job.endDate){
      setEndDate(getJobDate(job.endDate));
    }
    const calculateTotalPercentage = async () => {
      const result = await totalPercentage(job);
      setPercentage(result);
    };
    calculateTotalPercentage();
  }, [job]);

  return (
    <React.Fragment>
      <Col xl="4" lg="6" md="6" sm="12" key={job.id}>
        <Card className="job-card-container">
          <CardBody>
            {currentUserHasFullAccess(job, currentUserProfile.id) && (
              <MenuType
                pathname={pathname}
                setSearchTerm={setSearchTerm}
                job={job}
                setJobUpdate={setJobUpdate}
                isLinkEnabled={isLinkEnabled}
                collectionName={collectionName}
              />
            )}

            {isLinkEnabled ? ( 
              <Link
                to={{
                  pathname: `${pathname}/${result.id.raw}`,
                  state: {
                    pageNo: location?.search,
                    query: resultSearchTerm,
                    type: pathname,
                  },
                }}
                className="text-dark"
              >
                {CardData(result, notification, startDate, endDate)}
              </Link>
            ) : (
              <>{CardData(result, notification, startDate, endDate)}</>
            )}
          </CardBody>
          <div style={{display: "flex"}}>
            <div className="px-4 py-3 border-top">
              <ul className="list-inline mb-0 flex-start-center">
                <li className="list-inline-item me-1">
                  {isInternal && <Category type={INTERNAL} job={job} />}
                </li>
                <li className="list-inline-item me-1">
                  {isExternal && <Category type={EXTERNAL} job={job} />}
                </li>
                <li className="list-inline-item me-1">
                  {isBelowground && <Category type={BELOW_GROUND} job={job} />}
                </li>
              </ul>
            </div>
            <div className="px-4 py-3 border-top" style={{flex: 1}}>
              <ProgressBar percentage={percentage} />
            </div>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

CardView.propTypes = {
  projects: PropTypes.array,
};
export default CardView;

