import {
  BELOW_GROUND,
  INTERNAL_TYPE,
  EXTERNAL_TYPE,
  RESIDENTIAL_CATEGORY,
  COMMERCIAL_CATEGORY,
  INFRASTRUCTURE_CATEGORY,
} from "common/constants";
import cuid from "cuid";
import moment from "moment";
import { fetchChecklistsForAreas } from "../../../../../services/jobService.js";

export function jobReportGeneration(
  setProgress,
  certificateDetails,
  job,
  getOrgInfo,
  currentUserProfile,
  collectionName,
  setCertificate,
  setCertificateDescription
) {
  return async ({
    includeChecklist,
    jobReportTemplate,
    setIncludeChecklist,
    setLoading,
  }) => {
    setProgress(20);
    const { jobCategory } = certificateDetails;
    const jobType = jobCategory.toLowerCase();
    const newId = cuid();
    const buildingClass = job[jobType]?.buildingClass
      ?.filter(buildClass => buildClass.checked)
      ?.map(buildClass => buildClass.value);

    const orgInfo = await getOrgInfo();
    setProgress((prev)=>prev+20);
    const { nextDocumentNumber } = orgInfo;
    const certificateDescription = {
      id: newId,
      docNo: nextDocumentNumber,
      docName: certificateDetails.documentName,
      createdByid: currentUserProfile.id,
      date: Date()
        .toLocaleString(("en-AU", { timeZone: "UTC" }))
        .slice(0, 24),
      certificateDate: moment(Date()).format("DD MMM YYYY"),
      jobNo: job.jobNo,
      jobType: jobCategory === BELOW_GROUND ? "Below ground" : jobCategory,
    };
    setProgress((prev)=>prev+20);
    let certificate = {
      id: newId,
      docNo: nextDocumentNumber,
      docName: certificateDetails.documentName,
      createdByid: currentUserProfile.id,
      createdByEmail: currentUserProfile.email,
      createdByName: currentUserProfile.displayName,
      createdUserInfo: currentUserProfile,
      orgInfo: orgInfo,
      date: Date()
        .toLocaleString(("en-AU", { timeZone: "UTC" }))
        .slice(0, 24),
      certificateDate: moment(Date()).format("DD MMM YYYY"),
      logoUrl: currentUserProfile.photoURL || "",
      jobNo: job.jobNo,
      jobAddress: job.jobAddress,
      jobId: job.id,
      jobPhotoUrl: job.photoURL || "",
      client: job.client,
      jobType: jobCategory === BELOW_GROUND ? "Below ground" : jobCategory,
      participants: job.participants,
      buildingClasses: buildingClass,
      rooms: certificateDetails?.selectedFloor?.content
        ? [certificateDetails?.selectedFloor]
        : job[jobType].rooms,
      includeChecklist: includeChecklist,
      includeStaging: false,
      includeTesting: false,
      jobReportTemplate: jobReportTemplate,
    };
    if (includeChecklist) {
      let areaIds = [];
      if (certificateDetails?.selectedFloor?.content) {
        certificate.rooms[0].subItems = [];
        certificateDetails?.selectedAreas?.map(area => {
          certificate?.rooms[0]?.subItems.push({
            id: area.value,
            content: area.label,
            type: "area",
          });
          areaIds.push(area.value);
        });
      } else {
        job[jobType]?.rooms?.forEach(floor => {
          areaIds = [...areaIds, ...floor?.subItems?.map(area => area.id)];
        });
      }
      const reportChecklists = await fetchChecklistsForAreas(
        collectionName,
        job.id,
        areaIds
      );
      if (reportChecklists?.length > 0) {
        (jobReportTemplate?.items || jobReportTemplate)?.forEach(
          templateInfo => {
            const checklist = reportChecklists?.find(
              checklist =>
                checklist?.id ===
                (templateInfo?.checklist && templateInfo?.checklist.id)
            );
            if (checklist) {
              const section = checklist?.items?.find(
                item =>
                  item?.id ===
                  (templateInfo?.section && templateInfo?.section.id)
              );
              if (section) {
                const checklistItem = section?.subItems?.find(
                  item =>
                    item?.id === (templateInfo?.item && templateInfo.item.id)
                );
                if (checklistItem) {
                  checklistItem.templates = [
                    ...(checklistItem?.templates || []),
                    templateInfo,
                  ];
                }
              }
            }
          }
        );

        let roomsData = {};
        reportChecklists?.forEach(checklist => {
          roomsData[checklist?.areaId] = checklist;
        });
        if (roomsData) {
          certificate = { ...certificate, ...roomsData };
          setCertificate(certificate);
          setProgress(100);
          setCertificateDescription(certificateDescription);
        }
        return;
      }
    }
  
    setCertificateDescription(certificateDescription);
    setCertificate(certificate);
 
    return;
  };
}
