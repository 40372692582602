import { useSelector } from "react-redux";
export function getAllJobsFromDb(
  jobNumbers,
  clientNames,
  jobAddresses,
  jobNames,
  customJobNumbers
) {
  return async () => {
    const { jobs } = useSelector(state => state.job);
    jobs?.forEach(job => jobNumbers?.add(job?.jobNo));
    jobs?.forEach(job => clientNames?.add(job?.client?.displayName?.toLowerCase()));
    jobs?.forEach(job => jobNames?.add(job?.jobName?.toLowerCase()));
    jobs?.forEach(job => jobAddresses?.add(job?.jobAddress));
    jobs?.forEach(job => customJobNumbers?.add(job?.customjobno?.toLowerCase()));
  };
}
