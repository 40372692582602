import React from "react";
import { Link } from "react-router-dom";

export function JobsSidebar({ props }) {
  return (
    <>
      <li>
        <Link
          to="/active"
          className={
            props.location.pathname.includes("/active") ? "mm-active" : ""
          }
        >
          <i className="bx bx-task"></i>
          <span>{props.t("Active Jobs")}</span>
        </Link>
      </li>
      <li>
        <Link
          to="/archived"
          className={
            props.location.pathname.includes("/archived") ? "mm-active" : ""
          }
        >
          <i className="bx bx-archive"></i>
          <span>{props.t("Archived Jobs")}</span>
        </Link>
      </li>
      <li>
        <Link
          to="/deleted"
          className={
            props.location.pathname.includes("/deleted") ? "mm-active" : ""
          }
        >
          <i className="bx bx-trash"></i>
          <span>{props.t("Deleted Jobs")}</span>
        </Link>
      </li>
    </>
  );
}

export function InspectionJobsSidebar({ props }) {
  return (
    <>
      <li>
        <Link
          to="/inspectionactive"
          className={
            props.location.pathname.includes("/inspectionactive")
              ? "mm-active"
              : ""
          }
        >
          <i className="bx bx-task"></i>
          <span>{props.t("Active")}</span>
        </Link>
      </li>
      <li>
        <Link
          to="/inspectionarchived"
          className={
            props.location.pathname.includes("/inspectionarchived")
              ? "mm-active"
              : ""
          }
        >
          <i className="bx bx-archive"></i>
          <span>{props.t("Archived")}</span>
        </Link>
      </li>
      <li>
        <Link
          to="/inspectiondeleted"
          className={
            props.location.pathname.includes("/inspectiondeleted")
              ? "mm-active"
              : ""
          }
        >
          <i className="bx bx-trash"></i>
          <span>{props.t("Deleted")}</span>
        </Link>
      </li>
    </>
  );
}
