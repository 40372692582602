export const buildingClassDataMap = [
  {
    key: "class1a",
    text: "A Class 1a building is a single dwelling being a detached house; or one of a group of attached dwellings being a town house, row house or the like.",
    value: "Class 1a",
    checked: false,
  },
  {
    key: "class1b",
    text: "A Class 1b building is a boarding house, guest house or hostel that has a floor area less than 300 m2 and ordinarily has less than 12 people living in it. It can also be 4 or more single dwellings located on one allotment which are used for short-term holiday accommodation.",
    value: "Class 1b",
    checked: false,
  },
  {
    key: "class2",
    text: "Class 2 buildings are apartment buildings. They are typically multi-unit residential buildings where people live above and below each other. The NCC describes the space considered as an apartment as a sole-occupancy unit (SOU).",
    value: "Class 2",
    checked: false,
  },
  {
    key: "class3",
    text: `Class 3 applies to residential buildings other than Class 1 or Class 2 buildings, or a Class 4 part of a building. Class 3 buildings are a common place of long term or transient living for a number of unrelated people. Examples include a boarding house, guest house, hostel or backpackers (that are larger than the limits for a Class 1b building). Class 3 buildings could also include dormitory style accommodation, or workers’ quarters for shearers or fruit pickers. Class 3 buildings may also be ‘care-type’ facilities such as accommodation buildings for children, the elderly, or people with disability, which are not Class 9 buildings.`,
    value: "Class 3",
    checked: false,
  },
  {
    key: "class4",
    text: "A Class 4 part of a building is a sole dwelling or residence within a building of a non-residential nature. An example of a Class 4 part of a building would be a caretaker’s residence in a storage facility. A Class 4 part can only be located in a Class 5 to 9 building.",
    value: "Class 4",
    checked: false,
  },
  {
    key: "class5",
    text: "Class 5 buildings are office buildings used for professional or commercial purposes. Examples of Class 5 buildings are offices for lawyers, accountants, government agencies and architects.",
    value: "Class 5",
    checked: false,
  },
  {
    key: "class6",
    text: `Class 6 buildings are typically shops, restaurants and cafés. They are a place for the sale of retail goods or the supply of services direct to the public. Some examples are: • A dining room, bar, shop or kiosk part of a hotel or motel. • A hairdresser or barber shop. • A public laundry. • A market or showroom. • A funeral parlour. • A shopping centre.`,
    value: "Class 6",
    checked: false,
  },
  {
    key: "class7a",
    text: "Class 7a buildings are carparks.",
    value: "Class 7a",
    checked: false,
  },
  {
    key: "class7b",
    text: "Class 7b buildings are typically warehouses, storage buildings or buildings for the display of goods (or produce) for wholesale.",
    value: "Class 7b",
    checked: false,
  },
  {
    key: "class8",
    text: `Class 8 A factory is the most common way to describe a Class 8 building. It’s a building in which a process (or handicraft) is carried out for trade, sale, or gain. The building can be used for production, assembling, altering, repairing, finishing, packing, or cleaning of goods or produce. It includes buildings such as a mechanic’s workshop. It may also be a building for food processing, such as an abattoir.`,
    value: "Class 8",
    checked: false,
  },
  {
    key: "class9a",
    text: "Class 9a buildings are generally hospitals, referred to as health-care buildings in the NCC. They are buildings in which occupants or patients undergo medical treatment and may need physical assistance to evacuate in the case of an emergency. This includes a clinic (or day surgery) where the effects of the treatment administered involve patients becoming unconscious or unable to move. This in turn requires supervised medical care (on the premises) for some time after treatment has been administered.",
    value: "Class 9a",
    checked: false,
  },
  {
    key: "class9b",
    text: "Class 9b buildings are assembly buildings in which people may gather for social, theatrical, political, religious or civil purposes. They include schools, universities, childcare centres, pre-schools, sporting facilities, night clubs, or public transport buildings.",
    value: "Class 9b",
    checked: false,
  },
  {
    key: "class9c",
    text: "Class 9c buildings are residential care buildings that may contain residents who have various care level needs. They are a place of residence where 10% or more of persons who reside there need physical assistance in conducting their daily activities and to evacuate the building during an emergency. An aged care building, where residents are provided with personal care services, is a Class 9c building.",
    value: "Class 9c",
    checked: false,
  },
  {
    key: "class10a",
    text: "Class 10a buildings are non-habitable buildings including sheds, carports, and private garages.",
    value: "Class 10a",
    checked: false,
  },
  {
    key: "class10b",
    text: "Class 10b is a structure being a fence, mast, antenna, retaining wall, swimming pool, or the like.",
    value: "Class 10b",
    checked: false,
  },
  {
    key: "class10c",
    text: "A Class 10c building is a private bushfire shelter. A private bushfire shelter is a structure associated with, but not attached to, a Class 1a building.",
    value: "Class 10c",
    checked: false,
  },
];

export const buildingClassImgMap = {
  class1a: "/assets/images/classes/Building-Class1.png",
  class1b: "/assets/images/classes/Building-Class1.png",
  class2: "/assets/images/classes/Building-Class2.png",
  class3: "/assets/images/classes/Building-Class3.png",
  class4: "/assets/images/classes/Building-Class4.png",
  class5: "/assets/images/classes/Building-Class5.png",
  class6: "/assets/images/classes/Building-Class6.png",
  class7a: "/assets/images/classes/Building-Class7.png",
  class7b: "/assets/images/classes/Building-Class7.png",
  class8: "/assets/images/classes/Building-Class8.png",
  class9a: "/assets/images/classes/Building-Class9.png",
  class9b: "/assets/images/classes/Building-Class9.png",
  class9c: "/assets/images/classes/Building-Class9.png",
  class10a: "/assets/images/classes/Building-Class10.png",
  class10b: "/assets/images/classes/Building-Class10.png",
  class10c: "/assets/images/classes/Building-Class10.png",
  classCustom: "/assets/images/classes/Building-Class10.png",
};
