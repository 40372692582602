// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Droppable {
  position: relative;
  padding-top: 80px;
  text-align: center;
  border-radius: 10px;
  width: 340px;
  height: 340px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: inset rgba(201, 211, 219, 0.5) 0 0 0 2px,
    rgba(255, 255, 255, 0) 0 0 0 1px, rgba(201, 211, 219, 0.25) 20px 14px 24px;
  transition: box-shadow 250ms ease;
}
.Droppable > svg {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 200px;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0.8;
  transition: opacity 300ms ease, transform 200ms ease;
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}
.Dragging > svg {
  opacity: 0.8;
}
.Over {
  box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
}
.Over > svg {
  opacity: 1;
}
.Over.dropped {
  box-shadow: inset rgba(201, 211, 219, 0.7) 0 0 0 3px,
    rgba(201, 211, 219, 0.5) 20px 14px 24px;
}
.Dropped > svg {
  opacity: 0.2;
  transform: translate3d(-50%, 100%, 0) scale(0.8);
}
`, "",{"version":3,"sources":["webpack://./src/components/dnd/droppable.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB;8EAC4E;EAC5E,iCAAiC;AACnC;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,YAAY;EACZ,qCAAqC;EACrC,YAAY;EACZ,oDAAoD;EACpD,yBAAiB;UAAjB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,YAAY;AACd;AACA;EACE,4EAA4E;AAC9E;AACA;EACE,UAAU;AACZ;AACA;EACE;2CACyC;AAC3C;AACA;EACE,YAAY;EACZ,gDAAgD;AAClD","sourcesContent":[".Droppable {\n  position: relative;\n  padding-top: 80px;\n  text-align: center;\n  border-radius: 10px;\n  width: 340px;\n  height: 340px;\n  box-sizing: border-box;\n  background-color: #fff;\n  box-shadow: inset rgba(201, 211, 219, 0.5) 0 0 0 2px,\n    rgba(255, 255, 255, 0) 0 0 0 1px, rgba(201, 211, 219, 0.25) 20px 14px 24px;\n  transition: box-shadow 250ms ease;\n}\n.Droppable > svg {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  width: 200px;\n  transform: translate3d(-50%, -50%, 0);\n  opacity: 0.8;\n  transition: opacity 300ms ease, transform 200ms ease;\n  user-select: none;\n  pointer-events: none;\n}\n.Dragging > svg {\n  opacity: 0.8;\n}\n.Over {\n  box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;\n}\n.Over > svg {\n  opacity: 1;\n}\n.Over.dropped {\n  box-shadow: inset rgba(201, 211, 219, 0.7) 0 0 0 3px,\n    rgba(201, 211, 219, 0.5) 20px 14px 24px;\n}\n.Dropped > svg {\n  opacity: 0.2;\n  transform: translate3d(-50%, 100%, 0) scale(0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
