import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import TeamMembers from "./TeamMembers";

import "../../../components/Common/TopbarDropdown/NotificationStyles.css";
import { useHistory } from "react-router-dom";
import CerficateGeneration from "./documents/documentsGeneration/CertificateGeneration";
import { JobsDetailsOverView } from "./JobsDetailsOverView";
export default function JobsOverview({
  loading,
  jobDetails,
  redirectUrl,
  collectionName,
  keyy,
}) {
  const { currentUserProfile } = useSelector(state => state.profile);
  const [showCertModal, setShowCertModal] = useState(false);

  const [certificateDetails, setCertificateDetails] = useState({});
  const history = useHistory();
  const [participantsModal, setShowPaticipantsModal] = useState(false);
  useEffect(() => {
    if (jobDetails === null || !currentUserProfile) return;
    if (isEmpty(jobDetails)) {
      toast.warn("The job is not available", {
        autoClose: 3000,
      });
      history.push("/active");
      return;
    }
    const accessId = jobDetails.accessIds?.find(
      id => id === currentUserProfile?.id
    );
    if (!accessId) {
      toast.warn("The job is not available", {
        autoClose: 3000,
      });
      history.push("/active");
    }
  }, [jobDetails, currentUserProfile]);

  const canEdit = () => {
    if (isEmpty(jobDetails)) return false;
    const [myAccess] = jobDetails.participants.filter(
      user => user.id === currentUserProfile.id
    );
    return myAccess?.access.value === "full";
  };

  const canUpload = () => {
    if (isEmpty(jobDetails)) return false;
    const [myAccess] = jobDetails.participants.filter(
      user => user.id === currentUserProfile.id
    );
    return (
      myAccess?.access.value === "full" || myAccess?.access.value === "partial"
    );
  };

  const canSign = () => {
    if (isEmpty(jobDetails)) return false;
    const [myAccess] = jobDetails.participants.filter(
      user => user.id === currentUserProfile.id
    );
    return myAccess?.access.value !== "readonly";
  };

  if (isEmpty(jobDetails)) return null;

  return (
    <div className="page-content" id="job-details">
      <MetaTags>
        <title>Job Details | Industry Best</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumbs */}
        {showCertModal && (
          <CerficateGeneration
            showCertModal={showCertModal}
            setShowCertModal={setShowCertModal}
            job={jobDetails}
            collectionName={collectionName}
            certificateDetails={certificateDetails}
          />
        )}
        {participantsModal && (
          <TeamMembers
            job={jobDetails}
            canEdit={canEdit()}
            collectionName={collectionName}
            setShowPaticipantsModal={setShowPaticipantsModal}
            participantsModal={participantsModal}
          />
        )}
        {!showCertModal &&
          !participantsModal &&
          JobsDetailsOverView(
            history,
            redirectUrl,
            loading,
            jobDetails,
            canEdit,
            collectionName,
            setShowPaticipantsModal,
            participantsModal,
            canUpload,
            showCertModal,
            setShowCertModal,
            certificateDetails,
            setCertificateDetails,
            keyy
          )}
      </Container>
    </div>
  );
}

export const UseHeader = ({ title, history, redirectUrl }) => {
  const url = useMemo(() => getUrl(redirectUrl, history), [redirectUrl]);

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">
            <Button
              color="link"
              onClick={() => {
                history.push(url);
              }}
              style={{ transform: "translateY(-5%)" }}
            >
              <i
                className="bx bx-caret-left"
                style={{ transform: "translateY(4%)" }}
              ></i>
              Back
            </Button>
            {title}
          </h4>
        </div>
      </Col>
    </Row>
  );

  function getUrl(redirectUrl, history) {
    if (redirectUrl?.pageNo) {
      if (redirectUrl?.query)
        return `/${redirectUrl.type}?q=${redirectUrl.query}&${redirectUrl?.pageNo}`;
      return `/${redirectUrl.type}${redirectUrl?.pageNo}`;
    }
    return history?.location?.pathname?.includes("inspectionactive")
      ? `/inspectionactive`
      : `/active`;
  }
};
