import CloseIcon from "@mui/icons-material/Close";

import { Checkbox, IconButton, Typography } from "@mui/material";
import dropLeft from "assets/images/icons8/drop-left.png";
import { useState } from "react";
import NotesListGroup from "../jobs/jobDetails/rooms/checklist/CheckListGroupUnit";
import { ContentCopy } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
} from "reactstrap";
import "../jobs/jobCreation/checklist/addSection.scss";
import  "../jobs/jobCreation/checklist/CheckListPage.scss"
import { UpdateNotes } from "./NotesUpdation";
export default function NotesListUnit({
  notesNameSearch,
  myNotes,
  showCheckBox,
  setMyNotes,
}) {
  const limit = 20;
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  

  const ShowNotesDetails = () => {
    const DisplayNotesData = () => {
      return (
        <>
          <Typography style={{ fontFamily: "Poppins", fontStyle: "normal" }}>
            {`${selectedNotes?.notesInfo}`}
          </Typography>
        </>
      );
    };

    return (
      <>
      {modalVisible && (
         <UpdateNotes setModalVisible={setModalVisible} selectedNotes={selectedNotes} />
        )}
        <Modal isOpen={isModalOpen} size="l" scrollable={true}>
          <div className="modal-header">
            <Typography
              variant="h6"
              className="modal-title mt-0 defaultFont"
              style={{
                textTransform: "capitalize",
                color: "#65a2eb",
                fontFamily: "Poppins",
                fontStyle: "normal",
              }}
            >
              {selectedNotes?.notesName}
            </Typography>
            <div>
              <IconButton
                onClick={() => {
                  setModalOpen(false);
                setModalVisible(true);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
              </IconButton>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(selectedNotes?.notesInfo);
                  toast.success("Notes copied successfully!!", {
                    autoClose: 2000,
                  });
                }}
              >
                <ContentCopy fontSize="small" style={{ color: "#50a5f1" }} />
              </IconButton>
              <IconButton
                onClick={() => {
                  setModalOpen(false);
                  setSelectedNotes(null);
                }}
              >
                <CloseIcon style={{ color: "#D57D7D" }} />
              </IconButton>
            </div>
          </div>

          <div className="modal-body">
            <DisplayNotesData />
          </div>

          <div className="modal-footer">
            <Button
              outline
              color="primary"
              onClick={() => {
                setModalOpen(false);
                setSelectedNotes(null);
              }}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  };

  const DisplayNotes = ({ item, index }) => {
    return (
      <div>
        <NotesListGroup>
          {showCheckBox && (
            <Checkbox
              checked={item.checked}
              style={{
                float: "left",
                height: "30px",
                marginRight: "5px",
                color: "#65a2eb",
              }}
              onChange={() => {
                const newNotesList = [...myNotes];
                newNotesList[index].checked = !item.checked;
                setMyNotes(newNotesList);
              }}
            />
          )}
          <img
            src={dropLeft}
            style={{
              float: "left",
              height: "30px",
              marginRight: "5px",
            }}
          />
          {item.notesName}

          <Button
            size="sm"
            color="link"
            onClick={() => {
              setSelectedNotes(item);
              setModalOpen(true);
            }}
          >
            Show details
          </Button>
        </NotesListGroup>
      </div>
    );
  };

  return (
    <>
      <ShowNotesDetails />

      <div className="checklist-saved-list">
        {!notesNameSearch && myNotes?.length > limit && (
          <p>
            {`Showing ${limit} of ${myNotes?.length} Notes. Refine your search`}
          </p>
        )}
        {notesNameSearch && myNotes?.length == 0 && (
          <p>No matching results found. Refine your search and try again.</p>
        )}
        {notesNameSearch && myNotes?.length > 0 && myNotes?.length <= limit && (
          <p>
            {`${myNotes.length} ${
              myNotes?.length > 1 ? "items" : "item"
            } found`}
          </p>
        )}
        {notesNameSearch && myNotes?.length > limit && (
          <p>
            {`Showing ${limit} of ${myNotes?.length} matching results. Refine your search`}
          </p>
        )}
        <div className="scrollable-content">
          {myNotes.slice(0, limit).map((item, index) => (
            <DisplayNotes key={index} item={item} index={index} />
          ))}
        </div>
      </div>
      
    </>
  );
}

