import React, {  } from "react";
import Modal from "react-modal";
import CloseButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "../../jobCreation/jobCategory.css";
import { LinkJobContextProvider, useLinkJobContext } from "context/LinkjobContext";
import { LinkActiveJobs } from "./LinkActiveJobs";
import { updateActiveLinkedJobs, updateLinkedJobs } from "services";
import { Row } from "reactstrap";
import "./linkJobStyles.scss"
import ScrollLock from "./ScrollLock";
const LinkJobsInJobsDetails = ({ isOpen, closeModal, job,collectionName }) => {
  Modal.setAppElement("#root");
  if (job.linkedJobs) {
    localStorage.setItem(
      "checkedJobs",
      JSON.stringify(job.linkedJobs)
    );
  }
  const onSave = checkedItems => {
    updateLinkedJobs(job.id,checkedItems,collectionName);
    updateActiveLinkedJobs(job.id,job.jobNo,checkedItems);
    closeModal();
    
  };
  const PageNavigationButtons = () => {
    const { checkedItems } = useLinkJobContext();
    return (
      <Row
        className="clearfix"
        style={{ marginBottom: "20px", marginRight: "0px" }}
      >
        <div className="text-end">
          <button
            // disabled={!isNext}
            className="btn btn-primary w-md mtb-2 ml-20"
            onClick={() => onSave(checkedItems)}
            type="button"
          >
            Save
          </button>
         
        </div>
      </Row>
    );
  }
  return (
    <div className="custom-modal">
       <CloseButton onClick={closeModal} style={{ float: "right" }}>
          <CloseIcon />
        </CloseButton> 
        { 
        (job !== null)  &&
        <LinkJobContextProvider>
        <LinkActiveJobs />
        <PageNavigationButtons />
      </LinkJobContextProvider>
        }
         <ScrollLock isEnabled={isOpen} />
    </div>
  );
};

export default LinkJobsInJobsDetails;
