// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.include-photos-checkbox {
  margin-left: 15px; }

.certificate-header {
  margin-bottom: 20; }

.generate-buuton-container {
  display: flex;
  justify-content: center; }

.generate-details-container {
  background: white;
  padding: 20px; }

.esign-label {
  color: red; }

.floors-error {
  color: red;
  display: flex;
  justify-content: center;
  font-size: 16px; }

.progress-bar-container {
  padding: 30px;
  padding-top: 0;
  padding-bottom: 5px; }

.checklist-item {
  display: flex;
  align-items: center; }

.item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.item-actions {
  display: flex;
  gap: 5px; }
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/jobDetails/documents/documentsGeneration/Certificate.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAA;;AAGnB;EACE,iBAAiB,EAAA;;AAEnB;EACE,aAAa;EACb,uBAAuB,EAAA;;AAEzB;EACE,iBAAiB;EACjB,aAAa,EAAA;;AAEf;EACE,UAAU,EAAA;;AAGZ;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,eAAe,EAAA;;AAEjB;EACE,aAAa;EACb,cAAc;EACd,mBAAmB,EAAA;;AAErB;EACE,aAAa;EACb,mBAAmB,EAAA;;AAGrB;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW,EAAA;;AAGb;EACE,aAAa;EACb,QAAQ,EAAA","sourcesContent":[".include-photos-checkbox {\n  margin-left: 15px;\n}\n\n.certificate-header {\n  margin-bottom: 20;\n}\n.generate-buuton-container {\n  display: flex;\n  justify-content: center;\n}\n.generate-details-container {\n  background: white;\n  padding: 20px;\n}\n.esign-label {\n  color: red;\n}\n\n.floors-error {\n  color: red;\n  display: flex;\n  justify-content: center;\n  font-size: 16px;\n}\n.progress-bar-container {\n  padding: 30px;\n  padding-top: 0;\n  padding-bottom: 5px;\n}\n.checklist-item {\n  display: flex;\n  align-items: center;\n}\n\n.item-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.item-actions {\n  display: flex;\n  gap: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
