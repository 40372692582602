import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { memo } from "react";

const styles = StyleSheet.create({
  headerContainer: {
    paddingTop: 16,
    paddingBottom: 16,
    width: "55%",
    marginRight: 30,
  },
  clientInfo: {
    marginBottom: 6,
    flexDirection: "row",
    alignSelf: "left",
    fontSize: 20,
    fontWeight: 600,
    color: "#004F6B",
  },
  fieldContainer: {
    flexDirection: "row",

    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  fieldName: {
    fontWeight: 600,
    fontSize: 14,
    color: "#072535",
  },
  fieldValue: {
    fontSize: 14,
    color: "#072535",
  },
});
const ClientInfo = ({ client }) => (<>
  <View style={styles.headerContainer}>
    <Text style={styles.clientInfo}>Client Details</Text>
    <View style={styles.headerContainaer}>
      <View style={styles.fieldContainer}>
        <Text style={styles.fieldName}>Name: </Text>
        <Text style={styles.fieldValue}>{client?.displayName}</Text>
      </View>
      <View style={styles.fieldContainer}>
        <Text style={styles.fieldName}>Email: </Text>
        <Text style={styles.fieldValue}>{client?.email}</Text>
      </View>
      <View style={styles.fieldContainer}>
        <Text style={styles.fieldName}>Phone: </Text>
        <Text style={styles.fieldValue}>{client?.mobile}</Text>
      </View>
      <View style={styles.fieldContainer}>
        <Text style={styles.fieldName}>Address: </Text>
        <Text style={styles.fieldValue}>{client?.address}</Text>
      </View>
    </View>
  </View>
  </>);
export default memo(ClientInfo);
