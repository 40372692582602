import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { COMPLAINCE, INSPECTION, JOB_REPORT } from "common/constants";
import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { Button, Card, Col, Row } from "reactstrap";
import { deleteImageFromFirebaseStoreByUrl, updateJobInDb } from "services";
import "./CertCertificate.scss";

export default function AttachmentCard({ certificate, job, canEdit,collectionName }) {
  const [confirmAlert, setConfirmAlert] = useState(false);
  const { currentUserProfile } = useSelector(state => state.profile);
  const [successDlg, setSuccessDlg] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [dynamicDescription, setDynamicDescription] = useState("");

  const onDeletedAttachment = async () => {
    await deleteImageFromFirebaseStoreByUrl(certificate.pdfUrl);
    job.certificate = job.certificate.filter(
      cert => certificate.id !== cert.id
    );

    updateJobInDb(job,collectionName);
  };

  const downloadPDF = certificate => {
   const name=`${job.jobAddress}:  ${certificate?.docName || certificate?.docType || "certificate"}`
    fetch(`${certificate.pdfUrl}`).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${name || certificate?.docName || "certificate"}.pdf`;
        a.click();
      });
    });
  };

  return (
    <Card className="mt-1 mb-0 shadow-none border  certificate-container dz-processing dz-image-preview dz-success dz-complete">
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            onDeletedAttachment();
            setConfirmAlert(false);
            setSuccessDlg(true);
            setDynamicTitle("Deleted");
            setDynamicDescription("Your file has been deleted.");
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {successDlg ? (
        <SweetAlert
          success
          title={dynamicTitle}
          onConfirm={() => {
            setSuccessDlg(false);
          }}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}

      <div className="p-2">
        <Row className="align-items-center">
          <Col className="col-auto">
            {(canEdit || currentUserProfile.id === certificate.createdByid) && (
              <i
                style={{ color: "red" }}
                className="dripicons-cross"
                onClick={() => {
                  setConfirmAlert(true);
                }}
              />
            )}{" "}
          </Col>
          <Col>
            <p className="mb-0">{certificate.createdByEmail}</p>
            <p className="mb-0">
              <strong>{`${job.jobAddress}: ${certificate?.docName || certificate?.docType || "Certificate"}`}</strong>
            </p>
            <p className="mb-0">{certificate.date}</p>
            <p className="mb-0 capitilize">{certificate?.jobClassification}</p>
            <p className="mb-0">{certificate.jobType}</p>
          </Col>
          <Col className="download-button">
            <Button
              size="sm"
              color="primary"
              onClick={() => downloadPDF(certificate)}
            >
              <PictureAsPdfIcon />
              {"Download"}
            </Button>
          </Col>
        </Row>
      </div>
    </Card>
  );
}
