import { FormControl, InputLabel, Select, Switch } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import {
  ACTIVE,
  APPROVED,
  CLOUD_FUNCTION_URL,
  DATE_FORMAT,
  INACTIVE,
  MOBILE_USER,
  ORGANISATION_OWNER,
  PENDING,
  USER_ALREADY_EXISTS,
  WEB_USER,
} from "common/constants";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { isValidEmail } from "common/utils";
import { compareAsc } from "date-fns";
import add from "date-fns/add";
import intervalToDuration from "date-fns/intervalToDuration";
import generator from "generate-password-browser";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  addAdminNotificationsToDb,
  dataFromSnapshot,
  fetchAllOrgFromDb,
  getAllUsers,
  getUser,
  updateAllUsers,
  updateUserInDb,
} from "services";
import {
  addSubscriptionToDb,
  updateOrgOwnerInActiveJobs,
  updateOrgOwnerInArchivedJobs,
  updateOrgOwnerInDeletedJobs,
  updateOrgOwnerInDraftsJobs,
  updateOrgOwnerInInspectionJobs,
  updateSubscriptionInDb,
} from "services/subscriptionsService";
import { fetchOrgs, fetchOrgsError, fetchOrgsSuccess } from "store/actions";
import "../../../common/styles.css";
import "./createSubscription.scss";
import cuid from "cuid";
import firebase from "../../../config/firebase";
export default function CreateSubscription({
  showCreateSubscription,
  setShowCreateSubscription,
  isEditSubscription,
  setEditSubscription,
  subscription,
  subscriptionData: subscriptionOldData,
  setSubscription,
}) {
  const [startDate, setStartDate] = useState(
    subscription?.startDate || new Date()
  );
  const [endDate, setEndDate] = useState(subscription?.endDate || new Date());
  const [status, setStatus] = useState(
    subscription?.status ? subscription?.status === ACTIVE : true
  );
  const { orgs } = useSelector(state => state.org);
  const { subscriptions } = useSelector(state => state.subscriptions);
  const [subscriptionTerm, setSubscriptionTerm] = useState("");
  const [orgOwnerError, setOrgOwnerError] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useFirestoreCollection({
    query: () => fetchAllOrgFromDb(),
    onStart: () => dispatch(fetchOrgs()),
    onSuccess: docs => dispatch(fetchOrgsSuccess(docs)),
    onError: error => dispatch(fetchOrgsError(error)),
    deps: [dispatch],
  });

  useEffect(() => {
    const duration = intervalToDuration({
      start: add(new Date(startDate.toDateString()), { days: 1 }), // Including the start date also in date interval
      end: add(new Date(endDate.toDateString()), { days: 1 }), // Including the end date also in the date interval
    });

    const totalMonths = duration.months + duration.years * 12;
    const { days } = duration;
    setSubscriptionTerm(
      `${
        totalMonths > 0
          ? totalMonths === 1
            ? totalMonths + " month,"
            : totalMonths + " months,"
          : ""
      } ${days === 1 ? days + " day" : days + " days"}`
    );
  }, [startDate, endDate]);

  const registerOrgOwnerAndOnboardingEmail = async user => {
    try {
      user.email = user.email.toLowerCase();
      const resp = await axios.post(`${CLOUD_FUNCTION_URL}/createUser`, user);
      if (resp.status === 200) {
        const orgName = orgs.find(org => org.id === subscription.orgId)?.name;
        await axios.post(
          `${CLOUD_FUNCTION_URL}/sendSubscriptionAndLoginInfoMailToOrgOwner`,
          {
            orgName,
            email: user.email,
            password: user.password,
            webUsersCount: subscription.webUsersCount,
            mobileUsersCount: subscription.mobileUsersCount,
            subscriptionExpiryDate: endDate,
          }
        );
      } else {
        console.log("------error: ", resp);
      }
    } catch (error) {
      console.log("-----error: ", error);
    }
  };

  const sendOrgOnboardingMailForExistingUser = async email => {
    try {
      const orgName = orgs.find(org => org.id === subscription.orgId)?.name;
      const userInfo = await getUserInfo(email);
      const notification = {
        id: cuid(),
        description: `${userInfo?.displayName} has been added as organisation owner to ${orgName}.`,
        jobId: null,
        title: `Hello ${userInfo?.displayName}`,
        isRead: false,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        type: "admin",
        recipientId: userInfo?.id,
        actionType: "org-owner",
      };
      addAdminNotificationsToDb([notification]);
      await axios.post(
        `${CLOUD_FUNCTION_URL}/sendSubscriptionInfoMailToOrgOwner`,
        {
          orgName,
          email: email,
          webUsersCount: subscription.webUsersCount,
          mobileUsersCount: subscription.mobileUsersCount,
          subscriptionExpiryDate: endDate,
        }
      );
    } catch (error) {
      console.log("-----error: ", error);
    }
  };

  const sendUpdatedSubscriptionInfoToOrgOwner = async subscriptionStatus => {
    try {
      const orgName = orgs.find(org => org.id === subscription.orgId)?.name;
      const userInfo = await getUserInfo(subscription.orgOwnerEmail);
      const notification = {
        id: cuid(),
        description: `Updated Subscription plan: ${orgName} has now access to ${subscription.webUsersCount} web users and ${subscription.mobileUsersCount} mobile users`,
        jobId: null,
        title: `Hello ${userInfo?.displayName}`,
        isRead: false,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        type: "admin",
        recipientId: userInfo?.id,
        actionType: "org-owner",
      };
      addAdminNotificationsToDb([notification]);
      await axios.post(
        `${CLOUD_FUNCTION_URL}/sendSubscriptionUpdatedInfoMailToOrgOwner`,
        {
          orgName,
          email: subscription.orgOwnerEmail,
          webUsersCount: subscription.webUsersCount,
          mobileUsersCount: subscription.mobileUsersCount,
          subscriptionStartDate: startDate,
          subscriptionExpiryDate: endDate,
          subscriptionStatus: subscriptionStatus,
        }
      );
    } catch (error) {
      console.log("-----error: ", error);
    }
  };

  const getUserInfo = async email => {
    const snapshot = await getUser(email);
    if (!snapshot.empty) {
      const docs = snapshot.docs.map(doc => dataFromSnapshot(doc));
      return docs[0];
    }
    return null;
  };
  const updateOldOrgOwnerRole = async email => {
    const userInfo = await getUserInfo(email);

    try {
      await updateUserInDb({
        ...userInfo,
        registered: true,
        orgStatus: APPROVED,
        type: WEB_USER,
        orgAccessId: subscription.orgId,
      });
    } catch (error) {
      console.log("-----error: ", error);
    }
  };
  const newOrgOwnerOnboarding = async orgOwnerInfo => {
    if (orgOwnerInfo) {
      await updateUserInDb({
        ...orgOwnerInfo,
        registered: true,
        orgStatus: APPROVED,
        type: ORGANISATION_OWNER,
        orgAccessId: subscription.orgId,
      });
      await updateOrgOwnerInActiveJobs(orgOwnerInfo, subscription.orgId);
      await updateOrgOwnerInArchivedJobs(orgOwnerInfo, subscription.orgId);
      await updateOrgOwnerInDeletedJobs(orgOwnerInfo, subscription.orgId);
      await updateOrgOwnerInDraftsJobs(orgOwnerInfo, subscription.orgId);
      await updateOrgOwnerInInspectionJobs(orgOwnerInfo, subscription.orgId);
      await sendOrgOnboardingMailForExistingUser(subscription.orgOwnerEmail);
    } else {
      const orgOwnerDefaultPassword = generator.generate({
        length: 10,
        numbers: true,
      });

      await registerOrgOwnerAndOnboardingEmail({
        email: subscription.orgOwnerEmail,
        password: orgOwnerDefaultPassword,
        displayName: subscription.orgOwnerEmail,
        registered: true,
        orgStatus: APPROVED,
        type: ORGANISATION_OWNER,
        orgAccessId: subscription.orgId,
      }).then(async () => {
        const ownerInfo = await getUserInfo(subscription.orgOwnerEmail);
        if (ownerInfo) {
          await updateOrgOwnerInActiveJobs(ownerInfo, subscription.orgId);
          await updateOrgOwnerInArchivedJobs(ownerInfo, subscription.orgId);
          await updateOrgOwnerInDeletedJobs(ownerInfo, subscription.orgId);
          await updateOrgOwnerInDraftsJobs(ownerInfo, subscription.orgId);
          await updateOrgOwnerInInspectionJobs(ownerInfo, subscription.orgId);
        }
      });
    }
  };

  const handleUpdatedUsersCount = async subscriptionInfo => {
    const users = await getAllUsers(subscriptionInfo.orgId);
    const webUsers = users.filter(user => user.type === WEB_USER);
    const mobileUsers = users.filter(user => user.type === MOBILE_USER);
    if (
      subscriptionInfo.webUsersCount < webUsers?.length ||
      subscriptionInfo.mobileUsersCount < mobileUsers?.length
    ) {
      const orgUsers = users.filter(user => user.type != ORGANISATION_OWNER);
      const updatedusers = orgUsers.map(user => {
        return { ...user, orgStatus: PENDING, type: null };
      });
      await updateAllUsers(updatedusers);
    }
  };

  // const deletePrevOrgOwner = async orgOwnerEmail => {
  //   console.log("deletePrevOrgOwner called with", orgOwnerEmail);
  //   try {
  //     await axios.post(`${CLOUD_FUNCTION_URL}/deleteUser`, {
  //       email: orgOwnerEmail,
  //     });
  //   } catch (error) {
  //     console.log("-----error: ", error);
  //   }
  // };

  const handleSaveClick = async () => {
    const userInfo = await getUserInfo(subscription.orgOwnerEmail);
    if (
      subscription.orgOwnerEmail != subscriptionOldData.orgOwnerEmail &&
      userInfo &&
      userInfo.orgAccessId &&
      userInfo.orgAccessId !== null &&
      userInfo.orgAccessId !== subscription.orgId
    ) {
      return setOrgOwnerError(USER_ALREADY_EXISTS);
    }
    const checkEndDateEqualsCurrentDate = endDate => {
      const today = new Date();
      const endDateInfo = new Date(endDate);
      return (
        today.getTime() > endDateInfo.getTime() ||
        (today.getMonth() === endDateInfo.getMonth() &&
          today.getDate() >= endDateInfo.getDate()) ||
        (today.getFullYear() === endDateInfo.getFullYear() &&
          today.getMonth() > endDateInfo.getMonth())
      );
    };
    const isSubscriptionExpired = checkEndDateEqualsCurrentDate(endDate);
    const today = new Date();
    const subscriptionInfo = {
      ...subscription,
      startDate,
      endDate,
      status: isSubscriptionExpired ? INACTIVE : ACTIVE,
    };
    let promises = [];
    if (isEditSubscription) {
      if (subscription.orgOwnerEmail !== subscriptionOldData.orgOwnerEmail) {
        await updateOldOrgOwnerRole(subscriptionOldData.orgOwnerEmail).then(
          () => {
            promises.push(newOrgOwnerOnboarding(userInfo));
          }
        );
      } else {
        promises.push(
          sendUpdatedSubscriptionInfoToOrgOwner(subscriptionInfo.status)
        );
      }
      promises.push(handleUpdatedUsersCount(subscriptionInfo));
      promises.push(updateSubscriptionInDb(subscriptionInfo));
    } else {
      promises.push(newOrgOwnerOnboarding(userInfo));
      promises.push(addSubscriptionToDb(subscriptionInfo));
    }

    try {
      setLoading(true);
      await Promise.all(promises);
      setLoading(false);

      setEditSubscription(false);
      setShowCreateSubscription(false);
    } catch (error) {
      setLoading(false);
      console.log("error: ", error);
    }
  };

  const canSaveForm = () => {
    return (
      !subscription.orgId ||
      !startDate ||
      !endDate ||
      compareAsc(endDate, startDate) != 1 ||
      !subscription?.webUsersCount ||
      subscription?.webUsersCount < 1 ||
      !subscription?.mobileUsersCount ||
      subscription.mobileUsersCount < 0 ||
      !subscription?.orgOwnerEmail ||
      !isValidEmail(subscription?.orgOwnerEmail)
    );
  };

  return (
    <Modal
      isOpen={showCreateSubscription}
      toggle={() => setShowCreateSubscription(!showCreateSubscription)}
    >
      <ModalHeader
        toggle={() => setShowCreateSubscription(!showCreateSubscription)}
        tag="h4"
      >
        {isEditSubscription ? "Edit Subscription" : "Add Subscription"}
      </ModalHeader>

      <ModalBody>
        <Row form>
          <Col xs={12}>
            <div className="mb-3">
              <Label>Organisation Name</Label>
              {isEditSubscription ? (
                <Input value={subscription.orgName} disabled={true} />
              ) : (
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-multiple-name-label">
                    Organisation Name
                  </InputLabel>

                  <Select
                    value={subscription?.orgId}
                    defaultValue={subscription?.orgId}
                    onChange={event => {
                      setSubscription({
                        ...subscription,
                        orgId: event.target.value,
                      });
                    }}
                    label={"Organisation Name"}
                  >
                    {orgs
                      .filter(
                        org =>
                          org.status === ACTIVE &&
                          !subscriptions.find(sub => sub.orgId === org.id)
                      )
                      .map(org => (
                        <MenuItem value={org.id}>{org.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              {!subscription?.orgId && (
                <small className="requiredError">{"*Required"}</small>
              )}
            </div>

            <div className="mb-3">
              <div className="datePickerContainer">
                <Label className="datePickerLabel">Start Date</Label>
                <DatePicker
                  dateFormat={DATE_FORMAT}
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                />
              </div>
              {!startDate && (
                <small className="requiredError">{"*Required"}</small>
              )}
            </div>

            <div className="mb-3">
              <div className="datePickerContainer">
                <Label className="datePickerLabel">End Date</Label>
                <DatePicker
                  dateFormat={DATE_FORMAT}
                  selected={endDate}
                  onChange={date => setEndDate(_endDate => date)}
                />
              </div>
              {!endDate && (
                <small className="requiredError">{"*Required"}</small>
              )}
              {compareAsc(endDate, startDate) != 1 ? (
                <small className="requiredError">
                  {"End date should be greater than start date"}
                </small>
              ) : (
                <div>{"Term: " + subscriptionTerm}</div>
              )}
            </div>

            <div className="mb-3">
              <Label>Number of Web Users</Label>
              <Input
                name="webUsersCount"
                type="number"
                value={subscription?.webUsersCount || ""}
                onChange={e => {
                  setSubscription({
                    ...subscription,
                    webUsersCount: e.target.value,
                  });
                }}
              />
              {!subscription?.webUsersCount ? (
                <small className="requiredError">{"*Required"}</small>
              ) : (
                subscription?.webUsersCount < 1 && (
                  <small className="requiredError">
                    {"Web users count should never be less than one"}
                  </small>
                )
              )}
            </div>

            <div className="mb-3">
              <Label>Number of Mobile Users</Label>
              <Input
                name="mobileUsersCount"
                type="number"
                value={subscription?.mobileUsersCount || ""}
                onChange={e => {
                  setSubscription({
                    ...subscription,
                    mobileUsersCount: e.target.value,
                  });
                }}
              />
              {!subscription?.mobileUsersCount ? (
                <small className="requiredError">{"*Required"}</small>
              ) : (
                subscription.mobileUsersCount < 0 && (
                  <small className="requiredError">
                    {"Invalid mobile users count"}
                  </small>
                )
              )}
            </div>

            <div className="mb-3">
              <Label>Organisation Owner's Email</Label>
              <Input
                name="orgOwnerEmail"
                type="email"
                value={subscription?.orgOwnerEmail || ""}
                onChange={e => {
                  setOrgOwnerError("");
                  setSubscription({
                    ...subscription,
                    orgOwnerEmail: e.target.value,
                  });
                }}
              />
              {!subscription?.orgOwnerEmail ? (
                <small className="requiredError">{"*Required"}</small>
              ) : !isValidEmail(subscription?.orgOwnerEmail) ? (
                <small className="requiredError">{"Invalid email"}</small>
              ) : (
                orgOwnerError && (
                  <small className="requiredError">{orgOwnerError}</small>
                )
              )}
            </div>

            {isEditSubscription && (
              <div className="mb-3">
                <Label>Status</Label>
                <Switch
                  checked={status}
                  onChange={event => setStatus(event.target.checked)}
                />
              </div>
            )}
            <div className="mb-3"></div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-success save-user"
                onClick={handleSaveClick}
                disabled={canSaveForm() || loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
