import {
  FETCH_COMPANY_TASKS_ERROR,
  FETCH_COMPANY_TASKS_SUCCESS,
  FETCH_COMPANY_TASKS,
  ADD_TASK,
  DELETE_TASK
} from "./actionTypes";

export function fetchCompanyTasks() {
  return {
    type: FETCH_COMPANY_TASKS,
  };
}

export function fetchCompanyTasksSuccess(tasks) {
  return {
    type: FETCH_COMPANY_TASKS_SUCCESS,
    payload: tasks,
  };
}

export function fetchCompanyTasksError(error) {
  return {
    type: FETCH_COMPANY_TASKS_ERROR,
    payload: error,
  };
}

export function addTask(task){
 return {
   type: ADD_TASK,
   payload: task,
 };
}

export function deleteTask(taskId) {
  return {
    type: DELETE_TASK,
    payload: taskId,
  };
}