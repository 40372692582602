import { Typography } from "@mui/material";
import { HorizontalStepper } from "components/HorizontalStepper/HorizontalStepper";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import "./jobCreate.scss";
import InspectionJobWizard from "./jobCreation/InspectionJobWizard";

const CreateInspectionJob = props => {
  const {
    match: { params },
    location: { search },
    history,
  } = props;

  const { drafts } = useSelector(state => state.job);
  const [draft, setDraft] = useState(null);
  const [isCloned, setIsCloned] = useState(null);
  useEffect(() => {
    const isClonedJob = new URLSearchParams(search).get("type") === "cloned";
    setIsCloned(isClonedJob);
    if (params?.id) {
      const [jobDraft] = drafts.filter(job => job.id === params.id);
      setDraft(jobDraft);
    }
  }, [params.id, search]);
  const [activeStep, setActiveStep] = useState(3);
  const steps = [
    "Client details",
    "Job details",
    "Building class",
    "Participants",
    "Confirm Floor and Areas",
    "Checklist",
    "LinkToJob",
    "Finalise",
  ];

  const Header = () => {
    return (
      <div className="headerContainer">
        {/* <Typography className="header">Create New Inspection</Typography> */}

        <div className="stepperContainer">
          <HorizontalStepper steps={steps} activeStep={activeStep} />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content pageContainer">
        <MetaTags>
          <title>Create Inspection Details | Industry Best</title>
        </MetaTags>

        <Header />
        {isCloned !== null && (
          <InspectionJobWizard
            draft={draft}
            setActiveStep={setActiveStep}
            isCloned={isCloned}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default CreateInspectionJob;
