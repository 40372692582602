import Divider from "@mui/material/Divider";
import { isValidEmail } from "common/utils";
import MuiPhoneNumber from "mui-phone-number";
import React, {useState , Fragment, useEffect } from "react";
import { ClearButton, Highlighter, Typeahead } from "react-bootstrap-typeahead";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Alert, Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import UserCards from "./UserCards";
import {  getAllUsers } from "services";
import { useSelector } from "react-redux";
import { PENDING } from "common/constants";

export function ParticipantsUi(
  onModalClose,
  searchRef,
  isDropdownOpen,
  setIsDropdownOpen,
  setSelectedUser,
  isRolesLoading,
  setRole,
  handleCreate,
  roleOptions,
  role,
  accessOptions,
  setAccess,
  access,
  selectedUser,
  onAddExistingUser,
  manual,
  setManual,
  name,
  setName,
  email,
  setEmail,
  mobile,
  setMobile,
  invitedAccessOptions,
  setInvitedAccess,
  invitedAccess,
  onAddInvitedUser,
  job,
  onRemoveUser,
  onAccessChange,
  onRoleChange,
  participantWorkDates,
) {
  const [error, setError] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { currentUserProfile } = useSelector(state => state.profile);
  const handlePhoneNumberChange = (value, dialCode) => {
    if (
      value?.replace(`+${dialCode}`, "")?.length <= 10 &&
      value?.replace(`+${dialCode}`, "")?.length > 4
    ) {
      setError(false);
    } else {
      setError(true);
    }
    setMobile(value);
  };
  const getOptions = async () => {
  const allUsers = await getAllUsers(currentUserProfile?.orgAccessId);
  const updatedOptions = allUsers?.map(user => {
    const isPendingUser = user.orgStatus && user.orgStatus === PENDING;
    return { ...user, disabled: isPendingUser };
  });
  setOptions(updatedOptions || []);
};
const filteredOptions = options?.filter(option => {
  const query = searchQuery.toLowerCase();
  return (
    (option.displayName && option.displayName.toLowerCase().includes(query)) ||
    (option.mobile && option.mobile.includes(searchQuery)) ||
    option.email.toLowerCase().includes(query)
  );
});
  useEffect(() => {
    getOptions();
  },[])

  return (
    <>
      <div className="modal-header" style={{justifyContent:'flex-start'}}>
        <Button
          color="link"
          onClick={onModalClose}
          style={{ transform: "translateY(-5%)" }}
        >
          <i
            className="bx bx-caret-left"
            style={{ transform: "translateY(4%)" }}
          ></i>
          Back
        </Button>
        <h5 className="modal-title mt-0" id="myModalLabel">
          Add/Remove Participants
        </h5>
      </div>
      <div className="modal-body">
        <>
          <Row>
            <Alert color="primary">
              <i className="mdi mdi-bullseye-arrow me-2"></i>
              You can search for user using their{" "}
                <span>
                <b>name,</b>
              </span>{" "}
              <span>
                <b>email</b>
              </span>{" "}
              or{" "}
              <span>
                <b>mobile</b>
              </span>{" "}
              if they are registered in the platform{" "}
              <span style={{ color: "red" }}>
                <b>OR</b>
              </span>{" "}
              add their details manually.
            </Alert>{" "}
          </Row>
          <Row>
            <Col lg="6" md="6">
              <FormGroup className="mb-3">
                <Label htmlFor="basicpill-firstname-input1">Search</Label>
                <Typeahead
                  id="client-search"
                  labelKey={option =>
                    `${option.displayName || ""} (${
                      option.mobile || "No Mobile"
                    }) - ${option.email}`
                  }
                  ref={searchRef}
                  open={isDropdownOpen}
                  onFocus={() => setIsDropdownOpen(true)}
                  onBlur={() => setIsDropdownOpen(false)}
                  clearButton
                  renderMenuItemChildren={(option, { text }, index) => (
                    <Fragment>
                      <Highlighter search={text}>{option.email}</Highlighter>
                      <div>
                        <small>
                          <Highlighter search={text}>
                            {`${option.displayName} ${
                              option.disabled ? "(Pending Access)" : ""
                            }`}
                          </Highlighter>
                        </small>
                      </div>
                      <div>
                        <small>
                          <Highlighter search={text}>
                            {option.mobile || ""}
                          </Highlighter>
                        </small>
                      </div>
                    </Fragment>
                  )}
                  onChange={selected => {
                    if (selected && selected.length) {
                      setSelectedUser(selected);
                      setIsDropdownOpen(false);
                    } else {
                      setSelectedUser(null);
                    }
                  }}
                  onInputChange={(text, e) => {
                    setSearchQuery(text);
                  }}
                  options={filteredOptions}
                  placeholder="User Search..."
                >
                  {({ onClear, selected }) =>
                    !!selected.length && (
                      <div className="rbt-aux">
                        <ClearButton
                          onClick={e => {
                            onClear();
                            setIsDropdownOpen(false);
                          }}
                        />
                      </div>
                    )
                  }
                </Typeahead>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <>
              <Col lg="6" md="6">
                <Label htmlFor="basicpill-client-name">Role</Label>
                <CreatableSelect
                  isClearable
                  isDisabled={isRolesLoading}
                  isLoading={isRolesLoading}
                  onChange={newValue => setRole(newValue)}
                  onCreateOption={handleCreate}
                  options={roleOptions}
                  value={role}
                />
              </Col>
              <Col lg="6" md="6">
                <Label htmlFor="basicpill-client-name">Access</Label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={accessOptions[0]}
                  onChange={newValue => setAccess(newValue)}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isSearchable={false}
                  options={accessOptions}
                />
              </Col>
            </>
          </Row>
          <Row className="clearfix invited-user-add-button">
            <div className="text-end add-button">
              <button
                disabled={
                  !role || !access || !selectedUser || selectedUser[0].disabled
                }
                className="btn btn-success w-md ml-20"
                onClick={onAddExistingUser}
              >
                Add Existing user
              </button>
            </div>
          </Row>

          <Divider className="divider" variant="middle" />
          {!manual && (
            <div className="invite-user-text">
              <Button color="link" onClick={() => setManual(true)}>
                Click here to add Invited Users details manually.
              </Button>
            </div>
          )}
          <br />
          {manual && (
            <>
              <Row>
                <Col lg="6" md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-client-name">
                      Invited User’s Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-client-name"
                      value={name}
                      onChange={e => {
                        setName(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-email">
                      Invited User's Email
                    </Label>
                    <Input
                      type="email"
                      className="form-control"
                      id="basicpill-email"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                    {email && !isValidEmail(email) && (
                      <small className="requiredError">{"Invalid email"}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6" md="6">
                  <FormGroup className="mb-3">
                    <Label>Phone</Label>
                    <MuiPhoneNumber
                      name="phone"
                      label="Mobile"
                      autoFocus={false}
                      autoFormat={false}
                      defaultCountry={"au"}
                      variant="outlined"
                      value={mobile || ""}
                      size="small"
                      fullWidth
                      onChange={(text, country) =>
                        handlePhoneNumberChange(text, country?.dialCode)
                      }
                      error={error}
                    />
                    {mobile?.length < 4 ? (
                      <small className="requiredError">{"*Required"}</small>
                    ) : (
                      error && (
                        <small className="requiredError">
                          {"*Invalid Phone number"}
                        </small>
                      )
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6" md="6">
                  <Label htmlFor="basicpill-client-name">Role</Label>
                  <CreatableSelect
                    isClearable
                    isDisabled={isRolesLoading}
                    isLoading={isRolesLoading}
                    onChange={newValue => setRole(newValue)}
                    onCreateOption={handleCreate}
                    options={roleOptions}
                    value={role}
                  />
                </Col>
                <Col lg="6" md="6">
                  <Label htmlFor="basicpill-client-name">Access</Label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={invitedAccessOptions[0]}
                    onChange={newValue => setInvitedAccess(newValue)}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isSearchable={false}
                    options={invitedAccessOptions}
                  />
                </Col>
              </Row>
              <Row className="clearfix invited-user-add-button">
                <div className="text-end add-button">
                  <button
                    disabled={
                      !name ||
                      !email ||
                      !mobile ||
                      error ||
                      !role ||
                      !invitedAccess
                    }
                    className="btn btn-success w-md ml-20"
                    onClick={() => onAddInvitedUser()}
                  >
                    Add Invited User
                  </button>
                </div>
              </Row>
            </>
          )}
          <Divider className="divider" variant="middle" />
          {job.participants.length > 0 && (
            <UserCards
              users={job.participants}
              onRemove={onRemoveUser}
              onAccessChange={onAccessChange}
              onRoleChange={onRoleChange}
              participantWorkDates={participantWorkDates}
            />
          )}
        </>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={onModalClose}
          className="btn btn-secondary float-left"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </>
  );
}
