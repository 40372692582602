import * as React from "react";
import { Typography } from "@mui/material";
import "./styles.scss";

export const HorizontalStepper = ({ steps, activeStep }) => {
  const Header = () => {
    return (
      <div className="headerContainer">
        <Typography variant="subtitle1" className="activeLabel">
          {steps[activeStep - 1]}
        </Typography>

        <Typography variant="subtitle2">{`${activeStep}/${steps.length}`}</Typography>
      </div>
    );
  };

  const Steps = () => {
    return (
      <div className="stepsContainer">
        {steps.map((step, index) => {
          return (
            <div
              className={
                index < activeStep ? "step completedStep" : "step pendingStep"
              }
              key={index}
            ></div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Header />
      <Steps />
    </div>
  );
};
