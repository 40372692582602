import React from "react";
import MetaTags from "react-meta-tags";
import { useDispatch } from "react-redux";
import JobsGrid from "./JobsGrid";

import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import {
  fetchDraftsError,
  fetchDraftsScuccess,
  fetchJobs,
} from "store/job/actions";
import { fetchInspectionDraftsFromDb } from "services/inspectionService";
import { INSPECTION_DB_NAME } from "common/constants";

const Dashboard = props => {
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchInspectionDraftsFromDb(),
    onStart: () => dispatch(fetchJobs()),
    onSuccess: docs => dispatch(fetchDraftsScuccess(docs)),
    onError: error => dispatch(fetchDraftsError(error)),
    deps: [dispatch],
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Jobs | Inspection Drafts</title>
        </MetaTags>
        <h4 className="mb-0 font-size-18" style={{ marginLeft: 12 }}>
          {"Draft"}
        </h4>
        <div className="d-xl-flex">
          <div className="w-100">
            <div className="w-100" style={{ marginTop: 5 }}>
              <JobsGrid collectionName={INSPECTION_DB_NAME} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
