import { Card, Col, Row } from "reactstrap";

import Skeleton from '@mui/material/Skeleton';
import "react-image-lightbox/style.css";

export default function AttachmentPlaceholder() {
  return (
    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
      <div className="p-2">
        <Row className="align-items-center">
          <Col className="col-auto" style={{ marginLeft: "1rem" }}>
            <Skeleton
              className="rounded"
              variant="rectangular"
              width={50}
              height={50}
            />
          </Col>
          <Col>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={150} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={50} />
          </Col>
        </Row>
      </div>
    </Card>
  );
}
