import { toast } from "react-toastify";
import {
  Row,
} from "reactstrap";
import {
  deleteSelectedJobReportTemplate,
  fetchDefaultListItemsFromDb,
  fetchJobReportTemplatesChecklistsFromDB,
  fetchListItemsFromDb,
  getJobReportTemplates,
} from "services";
import { useState, useCallback, useEffect } from "react";
import "./Certificate.scss";
import { useSelector } from "react-redux";
import {
  fetchJobCustomListItems,
  fetchJobCustomListItemsError,
  fetchJobCustomListItemsSuccess,
  fetchJobDefaultListItems,
  fetchJobDefaultListItemsError,
  fetchJobDefaultListItemsSuccess,
  fetchJobSavedChecklist,
  fetchJobSavedChecklistError,
  fetchJobSavedChecklistSuccess,
} from "store/job_setup/actions";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { useDispatch } from "react-redux";
import TemplateSavedUnit from "pages/checklistLibrary/TemplateSavedUnit";
import DeleteConfirmationPrompt from "components/Common/DeleteConfirmationPrompt";
const JobReportChecklist = ({ myListItems,onDragEnd }) => {
  const dispatch = useDispatch();
  const { jobSavedChecklists } = useSelector(state => state.job_setup);
  useFirestoreCollection({
    query: () => fetchDefaultListItemsFromDb(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchJobDefaultListItems()),
    onSuccess: docs => dispatch(fetchJobDefaultListItemsSuccess(docs)),
    onError: error => dispatch(fetchJobDefaultListItemsError(error)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () =>
      fetchJobReportTemplatesChecklistsFromDB(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchJobSavedChecklist()),
    onSuccess: docs => dispatch(fetchJobSavedChecklistSuccess(docs)),
    onError: error => dispatch(fetchJobSavedChecklistError(error)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchListItemsFromDb(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchJobCustomListItems()),
    onSuccess: docs => dispatch(fetchJobCustomListItemsSuccess(docs)),
    onError: error => dispatch(fetchJobCustomListItemsError(error)),
    deps: [dispatch],
  });

  const { currentUserProfile } = useSelector(state => state.profile);
  const [checklistSearch, setChecklistSearch] = useState("");
  const [myCheckLists, setMyCheckLists] = useState(myListItems);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const onCancel = useCallback(() => {
    [...myCheckLists].map(item => {
      item.checked = false;
    });
    setMyCheckLists([...myCheckLists]);
    setShowCheckBox(false);
  }, [myCheckLists]);
  const handleDelete = async () => {
    await deleteSelectedJobReportTemplate(selectedItem.id, selectedItem.orgId).then(() => {
      toast.success("Template Deleted Successfully.", {
        autoClose: 2000,
      });
      updateMyChecklist();
      setSelectedItem(null);
      setShowDeleteAlert(false);
    });
  };
  const updateMyChecklist = async () => {
    const ChecklistSnapshot = await getJobReportTemplates(
      currentUserProfile?.orgAccessId
    );
    const JobReportTemplateData = ChecklistSnapshot?.docs?.map(doc =>
      doc.data()
    );
    setMyCheckLists([...JobReportTemplateData]);
  };

  useEffect(() => {
    updateMyChecklist();
  }, [myListItems]);
  useEffect(() => {
    let newList = jobSavedChecklists?.filter(item => {
      const title = item.templateName;
      return title.toLowerCase().includes(checklistSearch.toLowerCase());
    });

    newList?.sort((a, b) => {
      const aStr = a.templateName;
      const bStr = b.templateName;
      return aStr.localeCompare(bStr);
    });
    setMyCheckLists([...newList]);
  }, [checklistSearch, jobSavedChecklists]);

  return (
    <>
      {showDeleteAlert && (
        <DeleteConfirmationPrompt
          onCancel={() => {
            setShowDeleteAlert(false);
            setSelectedItem(null);
          }}
          onConfirm={handleDelete}
          title={`Are you sure you want to delete the template?`}
       confirmBtnText={"Yes, delete it."}
       cancelBtnText={"No, Keep it."}
        />
      )}
      <Row>
        <TemplateSavedUnit
          showCheckBox={showCheckBox}
          myChecklist={myCheckLists}
          setMyChecklist={setMyCheckLists}
          checklistSearcha={checklistSearch}
          onDragEnd={onDragEnd}
          setShowDeleteAlert={setShowDeleteAlert}
          setSelectedItem={setSelectedItem}
        />
      </Row>
    </>
  );
};
export default JobReportChecklist;
