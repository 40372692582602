import React, { useState } from "react";
import { Row, Col, Card, Form, CardBody, Container } from "reactstrap";
import Dropzone from "react-dropzone";
import Uploader from "./Uploader";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
const FormUpload = ({ qualifier }) => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const onDrop = useCallback(acceptedFiles => {
    setselectedFiles([]);
    handleAcceptedFiles(acceptedFiles);
  });
  const { getRootProps, getInputProps,isDragAccept,isDragReject } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [
        ".jpeg",
        ".png",
        ".jpg",
        ".JPEG",
        ".PNG",
        ".JPG",
      ],
      "application/pdf":['.pdf',".PDF"]
    },
  });
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Form encType="multipart/form-data">
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      {isDragReject ? (
                        <h4>Sorry,Please upload valid file format.</h4>
                      ) : (
                        <>
                          <h4>Drop files here or click to upload.</h4>
                          <p style={{ fontSize: "12px" }}>
                            Only jpeg, jpg, png , gif & pdf formats supported.
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Uploader
                          key={i + "-file"}
                          file={f}
                          qualifier={qualifier}
                        />
                      );
                    })}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default FormUpload;
