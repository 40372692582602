import React, { memo } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
import { buttonStyle } from "./ChecklistsPage";


export const ReplicateConfirmationDialog = memo(
  ({ roomName, onConfirm, closeModal, isReplicating }) => {
    return (
      <>
        <SweetAlert
          title={`Are you sure  you want to replicate in all '${roomName}'s`}
          warning
          showCancel={false}
          showConfirm={false}
          onConfirm={onConfirm}
        >
          You are going to replicate the checklist in all '{roomName}'s
          {isReplicating ? (
            <p className="mt-4 link-primary">
              <i>
                <b>Replicating...</b>
              </i>
            </p>
          ) : (
            <div
              style={{
                padding: "1rem 0",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button color="danger" style={buttonStyle} onClick={closeModal}>
                Cancel
              </Button>
              <Button color="success" style={buttonStyle} onClick={onConfirm}>
                Replicate
              </Button>
            </div>
          )}
        </SweetAlert>
      </>
    );
  }
);
