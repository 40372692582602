import { Divider } from "@mui/material";
import { COMMERCIAL_CATEGORY, INFRASTRUCTURE_CATEGORY, ORGANISATION_OWNER, PENDING, RESIDENTIAL_CATEGORY } from "common/constants";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { isValidEmail } from "common/utils";
import cuid from "cuid";
import MuiPhoneNumber from "mui-phone-number";
import RouteLeavingGuard from "pages/jobs/jobCreation/RouteLeavingGuard";
import JobLeavingGuard from "pages/jobs/jobCreation/JobLeavingGuard";
import React, { Fragment, useEffect, useState } from "react";
import Editable from "react-bootstrap-editable";
import { Highlighter, Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";

import {
  dataFromSnapshot,
  getAllUsers,
  getOrgOwnerDetails,
  getUser,
  updateJobDraftInDb,
} from "services";
import "../../../common/styles.css";
import "./Installer.scss";
import {
  createOption,
  defaultAccessOptions,
  defaultRoleOptions,
  invitedUserAccessOptions,
} from "./utils/user";
import DeleteConfirmationPrompt from "../../../components/Common/DeleteConfirmationPrompt";
export default function Installer({
  newJob,
  setNewJob,
  next,
  prev,
  isDirty,
  setDirty,
  setIsWizardExited,
  isWizardExited,
  validateParticipants,
  collectionName,
}) {
  const history = useHistory();
  const { currentUserProfile } = useSelector(state => state.profile);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState({
    label: "Installer",
    value: "installer",
  });
  const [access, setAccess] = useState({ label: "Full", value: "full" });
  const [invitedAccess, setInvitedAccess] = useState({
    label: "Tick, Notes, Photos, E-sign",
    value: "ticknotesviachatphotosesign",
  });
  const [userToRemove, setUserToRemove] = useState(null);
  const [isDeleteConfirmationPromptOpen, setIsDeleteConfirmationPromptOpen] =
    useState(false);
  const [installers, setInstallers] = useState([]);

  const [isRolesLoading, setRolesLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [accessOptions, setAccessOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [invitedAccessOptions, setInvitedAccessOptions] = useState([]);
  const [options, setOptions] = useState([]);

  const [search, setSearch] = useState("email");
  const [isNext, setIsNext] = useState(false);
  const [manual, setManual] = useState(false);
  const [orgOwnerDetails, setOrgOwnerDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const searchRef = React.createRef();
  const regExpForDigits = new RegExp("^[0-9]+$");
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  useFirestoreCollection({
    query: () => getOrgOwnerDetails(currentUserProfile.orgAccessId),
    onStart: () => () => setLoading(true),
    onSuccess: data => {
      setOrgOwnerDetails(data[0]);
      setLoading(false);
    },
    onError: error => setError(error),
    deps: [],
  });

  useEffect(() => {
    const getOptions = async () => {
      const allUsers = await getAllUsers(currentUserProfile?.orgAccessId);
      const updatedOptions = allUsers?.map(user => {
        const isPendingUser = user.orgStatus && user.orgStatus === PENDING;
        return { ...user, disabled: isPendingUser };
      });
      setOptions(updatedOptions || []);
    };
    getOptions();
    if (isUserAndOrgOwnerNotParticipents()) {
      setInstallers([
        ...newJob.participants,
        getCurrentProfile(),
        getOrgOwner(),
      ]);
    } else if (isUserPariticiepentNotOrgOwner()) {
      setInstallers([...newJob.participants, getOrgOwner()]);
    } else if (isOrgOwnerPariticiepentNotUser()) {
      setInstallers([...newJob.participants, getCurrentProfile()]);
    } else if (isUserAndOrgOwnerSameAndNotParticipent()) {
      setInstallers([
        ...newJob.participants,
        {
          ...getCurrentProfile(),
          role: { label: "Org Owner", value: "Org Owner" },
        },
      ]);
    } else {
      setInstallers(newJob.participants);
    }
    validateParticipants(newJob?.participants);
  }, [newJob.participants, orgOwnerDetails]);

  useEffect(() => {
    setIsNext(installers.length);
  }, [installers]);

  useEffect(() => {
    setRoleOptions(defaultRoleOptions);
    setAccessOptions(defaultAccessOptions);
    setInvitedAccessOptions(invitedUserAccessOptions);
  }, []);

  const isUserAndOrgOwnerSameAndNotParticipent = () => {
    return (
      orgOwnerDetails.id === currentUserProfile.id &&
      !newJob.participants.find(user => user.id === currentUserProfile.id)
    );
  };
  const isUserPariticiepentNotOrgOwner = () => {
    return (
      Object.keys(orgOwnerDetails).length > 0 &&
      orgOwnerDetails.id !== currentUserProfile.id &&
      !newJob.participants.find(user => user.id === orgOwnerDetails.id) &&
      newJob.participants.find(user => user.id === currentUserProfile.id)
    );
  };
  const isOrgOwnerPariticiepentNotUser = () => {
    return (
      Object.keys(orgOwnerDetails).length > 0 &&
      orgOwnerDetails.id !== currentUserProfile.id &&
      newJob.participants.find(user => user.id === orgOwnerDetails.id) &&
      !newJob.participants.find(user => user.id === currentUserProfile.id)
    );
  };
  const isUserAndOrgOwnerNotParticipents = () => {
    return (
      Object.keys(orgOwnerDetails).length > 0 &&
      orgOwnerDetails.id !== currentUserProfile.id &&
      !newJob.participants.find(user => user.id === orgOwnerDetails.id) &&
      !newJob.participants.find(user => user.id === currentUserProfile.id)
    );
  };
  const getOrgOwner = () => {
    return {
      displayName: orgOwnerDetails?.displayName,
      email: orgOwnerDetails.email,
      mobile: orgOwnerDetails?.mobile || null,
      id: orgOwnerDetails.id,
      photoURL: orgOwnerDetails?.photoURL,
      registered: true,
      role: { label: "Org Owner", value: "Org Owner" },
      access: access,
      type: orgOwnerDetails.type,
      internal: newJob.jobType.internal,
      external: newJob.jobType.external,
      below: newJob.jobType.belowground,
    };
  };
  const getCurrentProfile = () => {
    return {
      displayName: currentUserProfile?.displayName,
      email: currentUserProfile.email,
      mobile: currentUserProfile?.mobile || null,
      id: currentUserProfile.id,
      photoURL: currentUserProfile?.photoURL,
      type: currentUserProfile.type,
      registered: true,
      role: role,
      access: access,
      internal: newJob.jobType.internal,
      external: newJob.jobType.external,
      below: newJob.jobType.belowground,
    };
  };

  const onSave = () => {
    setNewJob({ ...newJob, participants: installers });
    updateJobDraftInDb({ ...newJob, participants: installers },collectionName);
    setDirty(false);
  };

  const onNext = () => {
    onSave();
    next();
  };

  const onPrev = () => {
    setNewJob({ ...newJob, participants: installers });
    updateJobDraftInDb({ ...newJob, participants: installers },collectionName);
    prev();
  };

  const onAddInvitedUser = async () => {
    const userEmail = email.toLowerCase();
    const userSnapshot = await getUser(userEmail);
    const docs = userSnapshot.docs.map(doc => dataFromSnapshot(doc));
    const user = docs && docs.length ? docs[0] : null;
    if (installers.some(user => user.email === userEmail)) {
      toast.warning("The user is already a participant of this job", {
        autoClose: 4000,
      });
      searchRef.current.clear();
      return;
    }
    if(!user?.orgAccessId){
      toast.warning("The user is not having ib license", {
        autoClose: 4000,
      });
      searchRef.current.clear();
      return;
    }
    console.log("options: ", options);
    const isUserPartOfTheOrg = options.some(
      user => user.email === userEmail && user.orgStatus !== "Pending"
    );
    if (isUserPartOfTheOrg) {
      toast.warning(
        "The user is part of the Organisation. Add as an Existing User",
        {
          autoClose: 4000,
        }
      );
      return;
    }

    setInstallers([
      ...installers,
      {
        displayName: name,
        email: userEmail,
        mobile: mobile === "+" ? "" : mobile,
        id: user ? user.id : cuid(),
        invited: true,
        isEmailSent: false,
        access: invitedAccess,
        role,
        registered: user ? user.registered : false,
        internal: newJob.jobType.internal,
        external: newJob.jobType.external,
        below: newJob.jobType.belowground,
      },
    ]);
    setEmail("");
    setMobile("");
    setName("");
    setDirty(true);
  };

  const onRemove = id => {
    setUserToRemove(installers.find(user => user.id === id));
    setIsDeleteConfirmationPromptOpen(true);
  };

  const onRemoveUserConfirmation = () => {
    setInstallers([...installers.filter(user => user.id !== userToRemove.id)]);
    setIsDeleteConfirmationPromptOpen(false);
    setDirty(true);
  };

  const onUpdate = (id, data) => {
    setInstallers([
      ...installers.map(userInfo => {
        if (userInfo.id === id) {
          return { ...userInfo, ...data };
        }
        return userInfo;
      }),
    ]);
    setDirty(true);
  };

  const handleCreate = inputValue => {
    setRolesLoading(true);
    console.group("Option created");
    console.log("Wait a moment...");

    const newOption = createOption(inputValue);
    console.groupEnd();
    setRoleOptions([...roleOptions, newOption]);
    setRolesLoading(false);
    setRole(newOption);
  };

  const getRoleOptions = () => {
    return defaultRoleOptions.map(role => role.label);
  };

  const getAccessOptions = invited => {
    return invited
      ? invitedUserAccessOptions.map(role => role.label)
      : defaultAccessOptions.map(role => role.label);
  };

  const onAddExistingUser = () => {
    const participantIds = installers.map(user => user.id);
    if (!selectedUser || !selectedUser.length) {
      return;
    }
    const userToAdd = selectedUser[0];
    if (installers.some(user => user.email === userToAdd.email)) {
      toast.warning("The user is already a participant of this job", {
        autoClose: 4000,
      });
      searchRef.current.clear();
      setSelectedUser(null);
      return;
    }
    const newList = installers.filter(user => user.id !== userToAdd.id);
    setInstallers([
      ...newList,
      {
        displayName: userToAdd.displayName || null,
        email: userToAdd.email,
        mobile: userToAdd.mobile || null,
        id: userToAdd.id,
        type: userToAdd.type,
        registered: true,
        invited: false,
        isNewlyAdded: true,
        isEmailSent: participantIds.includes(userToAdd.id),
        internal: newJob.jobType.internal,
        external: newJob.jobType.external,
        below: newJob.jobType.belowground,
        role:
          (userToAdd.type === ORGANISATION_OWNER && {
            label: "Org Owner",
            value: "Org Owner",
          }) ||
          role,
        access: (userToAdd.type === ORGANISATION_OWNER && {
          label: "Full",
          value: "full",
        }) ||
          access || { label: "Full", value: "full" },
      },
    ]);
    setDirty(true);
    searchRef.current.clear();
    setSelectedUser(null);
  };
  const isEmailAlreadyExists = (value, user) => {
    const existingUsers = [];
    installers.map(existingUser => {
      if (
        existingUser.email.toLowerCase() === value.trim().toLowerCase() &&
        existingUser.email.toLowerCase() !== user.email.toLowerCase()
      ) {
        existingUsers.push(existingUser.email.toLowerCase());
      }
    });

    if (existingUsers?.length) {
      setIsEmailValid(false);
      return true;
    }
    setIsEmailValid(true);
    return false;
  };
  const isMobileNumberInValid = value => {
    const number = value?.slice(1);

    if (
      value.charAt(0) !== "+" ||
      !regExpForDigits.test(number) ||
      number.length < 3
    ) {
      setIsMobileValid(false);
      return true;
    }
    setIsMobileValid(true);
    return false;
  };
  
  const [phoneError,setPhoneError] = useState(false);
  const handlePhoneNumberChange = (value,dialCode) => {
    if (value?.replace(`+${dialCode}`, '')?.length <= 10 && value?.replace(`+${dialCode}`, '')?.length > 4) {
      setPhoneError(false)
    } else {
      setPhoneError(true)
    }
    setMobile(value);
  };
  const filteredOptions = options?.filter(option => {
    const query = searchQuery.toLowerCase();
    return (
      (option.displayName && option.displayName.toLowerCase().includes(query)) ||
      (option.mobile && option.mobile.includes(searchQuery)) ||
      option.email.toLowerCase().includes(query)
    );
  });
  return (
    <>
      <RouteLeavingGuard
        when={isDirty}
        // Navigate function
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return isDirty;
        }}
        setWizardExited={setIsWizardExited}
        saveJob={onSave}
      />
      <JobLeavingGuard
        when={!isDirty && !isWizardExited}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return !isDirty && !isWizardExited;
        }}
      />
      <Card>
        <CardBody style={{ margin: "20px" }}>
          <Row>
            <Alert color="primary">
              <i className="mdi mdi-bullseye-arrow me-2"></i>
              You can search for user using their{" "}
              <span>
                <b>email</b>
              </span>{" "}
              or{" "}
              <span>
                <b>mobile</b>
              </span>{" "}
              if they are registered in the platform{" "}
              <span style={{ color: "red" }}>
                <b>OR</b>
              </span>{" "}
              add their details manually.
            </Alert>{" "}
          </Row>
          <Row>
            <Col lg="6" md="6">
              <FormGroup className="mb-3">
                <Label htmlFor="basicpill-firstname-input1">Search</Label>
                <Typeahead
                  id="client-search"
                  labelKey={(option) => `${option.displayName || ""}  (${option.mobile || "No Phone"}) - ${option.email}`}
                  ref={searchRef}
                  clearButton
                  renderMenuItemChildren={(option, { text }, index) => (
                    <Fragment>
                      <Highlighter search={text}>{option.email}</Highlighter>
                      <div>
                        <small>
                          <Highlighter search={text}>
                            {`${option.displayName} ${
                              option.disabled ? "(Pending Access)" : ""
                            }`}
                          </Highlighter>
                        </small>
                      </div>
                      <div>
                        <small>
                          <Highlighter search={text}>
                            {option.mobile || ""}
                          </Highlighter>
                        </small>
                      </div>
                    </Fragment>
                  )}
                  onChange={selected => {
                    if (selected && selected.length) {
                      setSelectedUser(selected);
                    } else {
                      setSelectedUser(null);
                    }
                  }}
                  onInputChange={(text, e) => {
                    setSearchQuery(text);
                  }}
                  options={filteredOptions}
                  placeholder="User Search..."
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <>
              <Col lg="6" md="6">
                <Label htmlFor="basicpill-client-name">Role</Label>
                <CreatableSelect
                  isClearable
                  isDisabled={isRolesLoading}
                  isLoading={isRolesLoading}
                  onChange={newValue => setRole(newValue)}
                  onCreateOption={handleCreate}
                  options={roleOptions}
                  value={role}
                />
              </Col>
              <Col lg="6" md="6">
                <Label htmlFor="basicpill-client-name">Access</Label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={
                    accessOptions.length > 0
                      ? accessOptions[0]
                      : { label: "Full", value: "full" }
                  }
                  onChange={newValue => setAccess(newValue)}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isSearchable={false}
                  options={accessOptions}
                />
              </Col>
            </>
          </Row>
          <Row className="clearfix add-user-button">
            <div className="text-end add-button">
              <button
                disabled={
                  !access || !role || !selectedUser || selectedUser[0].disabled
                }
                className="btn btn-success w-md ml-20"
                onClick={onAddExistingUser}
              >
                Add Existing User
              </button>
            </div>
          </Row>
          <Row>
            <Divider className="divider" variant="middle" />
            <br />
            {!manual && (
              <Button color="link" onClick={() => setManual(true)}>
                Click here to add Invited Users details manually.
              </Button>
            )}
          </Row>
          <br />
          {manual && (
            <>
              <Row>
                <Col lg="6" md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-client-name">
                      Invited User’s Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-client-name"
                      value={name}
                      onChange={e => {
                        setName(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-email">
                      Invited User's Email
                    </Label>
                    <Input
                      type="email"
                      className="form-control"
                      id="basicpill-email"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                    {email && !isValidEmail(email) && (
                      <small className="requiredError">{"Invalid email"}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6" md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-phoneno">Phone</Label>
                    <MuiPhoneNumber
                      className="form-control clientDetailsPhoneNumber"
                      name="phone"
                      label="Phone"
                      defaultCountry={"au"}
                      variant="outlined"
                      value={mobile || ""}
                      size="small"
                      fullWidth
                      onChange={(text,country) => handlePhoneNumberChange(text,country?.dialCode)}
                      autoFormat={false}
                      error={phoneError}
                    />
                    {(!mobile || mobile === "+" || mobile?.length < 4) ? (
                    <small className="requiredError">{"*Required"}</small>
                    ) : phoneError && (
                    <small className="requiredError">{"*Invalid Phone number"}</small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <>
                  <Col lg="6" md="6">
                    <Label htmlFor="basicpill-client-name">Role</Label>
                    <CreatableSelect
                      isClearable
                      isDisabled={isRolesLoading}
                      isLoading={isRolesLoading}
                      onChange={newValue => setRole(newValue)}
                      onCreateOption={handleCreate}
                      options={roleOptions}
                      value={role}
                    />
                  </Col>
                  <Col lg="6" md="6">
                    <Label htmlFor="basicpill-client-name">Access</Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={invitedAccessOptions[0]}
                      onChange={newValue => setInvitedAccess(newValue)}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isSearchable={false}
                      options={invitedAccessOptions}
                    />
                  </Col>
                </>
              </Row>
              <Row className="clearfix invited-user-add-button">
                <div className="text-end add-button">
                  <button
                    disabled={
                      !name || !email || !isValidEmail(email) || !mobile || phoneError
                    }
                    className="btn btn-success w-md ml-20"
                    onClick={onAddInvitedUser}
                  >
                    Add Invited User
                  </button>
                </div>
              </Row>
            </>
          )}
          <Divider className="divider" variant="middle" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th data-priority="1">Name</Th>
                            <Th data-priority="1">Email</Th>
                            <Th data-priority="1">Phone</Th>
                            <Th data-priority="1">Access</Th>
                            <Th data-priority="1">Role</Th>
                            {newJob.jobType.internal &&
                              (newJob.jobType.external ||
                                newJob.jobType.belowground) && (
                                <Th data-priority="1">{RESIDENTIAL_CATEGORY}</Th>
                              )}
                            {newJob.jobType.external &&
                              (newJob.jobType.internal ||
                                newJob.jobType.belowground) && (
                                <Th data-priority="1">{COMMERCIAL_CATEGORY}</Th>
                              )}
                            {newJob.jobType.belowground &&
                              (newJob.jobType.external ||
                                newJob.jobType.internal) && (
                                <Th data-priority="1">{INFRASTRUCTURE_CATEGORY}</Th>
                              )}
                            <Th data-priority="6">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {installers?.map(user => (
                            <Tr>
                              <Th>
                                <>
                                  {!user?.photoURL ? (
                                    <div className="avatar-xs">
                                      <span className="avatar-title rounded-circle">
                                        {user?.displayName.charAt(0)}
                                      </span>
                                    </div>
                                  ) : (
                                    <div>
                                      <img
                                        className="rounded-circle avatar-xs"
                                        src={user?.photoURL}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                </>
                              </Th>
                              <Td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={user.registered === true}
                                  editText="Edit"
                                  id={null}
                                  initialValue={user.displayName}
                                  isValueClickable={!!user.displayName}
                                  mode="inline"
                                  onSubmit={value => {
                                    if (value) {
                                      onUpdate(user.id, {
                                        displayName: value?.trim(),
                                      });
                                    }
                                  }}
                                  onValidated={() => {}}
                                  options={null}
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText
                                  type="textfield"
                                  validate={value => {
                                    if (!value) {
                                      setIsNameValid(false);
                                      return "Required";
                                    }
                                    setIsNameValid(true);
                                  }}
                                />
                              </Td>
                              <Td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={user.registered === true}
                                  editText="Email"
                                  id={null}
                                  initialValue={user.email}
                                  isValueClickable={!!user.email}
                                  mode="inline"
                                  onSubmit={value => {
                                    if (
                                      value &&
                                      isValidEmail(value.trim()) &&
                                      !isEmailAlreadyExists(value, user)
                                    ) {
                                      onUpdate(user.id, {
                                        email: value,
                                      });
                                    }
                                  }}
                                  onValidated={() => {}}
                                  options={null}
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText
                                  type="textfield"
                                  validate={value => {
                                    if (!value) {
                                      setIsEmailValid(false);
                                      return "Required";
                                    }
                                    if (value && !isValidEmail(value.trim())) {
                                      setIsEmailValid(false);
                                      return "Invalid Email";
                                    }
                                    if (isEmailAlreadyExists(value, user)) {
                                      return "Email Already Exists";
                                    }
                                  }}
                                />
                              </Td>
                              <Td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={user.registered === true}
                                  editText="Mobile"
                                  id={null}
                                  initialValue={user.mobile}
                                  isValueClickable={!!user.mobile}
                                  mode="inline"
                                  onSubmit={value => {
                                    if (value) {
                                      if (!isMobileNumberInValid(value)) {
                                        onUpdate(user.id, {
                                          mobile: value,
                                        });
                                      }
                                    }
                                  }}
                                  onValidated={() => {}}
                                  options={null}
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText
                                  type="textfield"
                                  validate={value => {
                                    if (!value) {
                                      return "Required";
                                    }
                                    if (isMobileNumberInValid(value)) {
                                      return "Invalid Mobile Number";
                                    }
                                  }}
                                />
                              </Td>
                              <Td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={user.type === ORGANISATION_OWNER}
                                  editText="Edit"
                                  id={null}
                                  initialValue={user.access.label}
                                  isValueClickable={!!user.access.label}
                                  mode="inline"
                                  onSubmit={value => {
                                    if (value) {
                                      onUpdate(user.id, {
                                        access: {
                                          label: value,
                                          value: value
                                            .toLowerCase()
                                            .replace(/\W/g, ""),
                                        },
                                      });
                                    }
                                  }}
                                  onValidated={null}
                                  options={getAccessOptions(
                                    user.invited || false
                                  )}
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText
                                  type="select"
                                  validate={null}
                                />
                              </Td>
                              <Td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={user.role.label}
                                  isValueClickable={!!user.role.label}
                                  mode="inline"
                                  onSubmit={value => {
                                    if (value) {
                                      onUpdate(user.id, {
                                        role: {
                                          label: value,
                                          value: value
                                            .toLowerCase()
                                            .replace(/\W/g, ""),
                                        },
                                      });
                                    }
                                  }}
                                  onValidated={null}
                                  options={getRoleOptions()}
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText
                                  type="select"
                                  validate={null}
                                />
                              </Td>
                              {newJob.jobType.internal &&
                                (newJob.jobType.external ||
                                  newJob.jobType.belowground) && (
                                  <Td>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={user.internal}
                                      onChange={e => {
                                        //console.log(e);
                                        onUpdate(user.id, {
                                          internal: e.target.checked,
                                        });
                                      }}
                                    />
                                  </Td>
                                )}
                              {newJob.jobType.external &&
                                (newJob.jobType.internal ||
                                  newJob.jobType.belowground) && (
                                  <Td>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={user.external}
                                      onChange={e => {
                                        //console.log(e);
                                        onUpdate(user.id, {
                                          external: e.target.checked,
                                        });
                                      }}
                                    />
                                  </Td>
                                )}
                              {newJob.jobType.belowground &&
                                (newJob.jobType.external ||
                                  newJob.jobType.internal) && (
                                  <Td>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={user.below}
                                      onChange={e => {
                                        onUpdate(user.id, {
                                          below: e.target.checked,
                                        });
                                      }}
                                    />
                                  </Td>
                                )}
                              <Td>
                                <div className="d-flex gap-3">
                                  <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() => onRemove(user.id)}
                                    disabled={
                                      currentUserProfile.id === user.id ||
                                      orgOwnerDetails.id === user.id
                                    }
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row className="clearfix mt-3" style={{ marginBottom: "20px" }}>
        <div className="text-end">
          <button
            className="btn btn-primary w-md mtb-2"
            onClick={() => onPrev()}
            type="button"
          >
            Previous
          </button>
          <button
            disabled={!isDirty}
            className="btn btn-primary w-md mtb-2 ml-20"
            onClick={() => onSave()}
            type="button"
          >
            Save
          </button>
          <button
            disabled={!isNext}
            className="btn btn-primary w-md ml-20 mtb-2"
            type="button"
            onClick={() => onNext()}
          >
            Next
          </button>
        </div>
      </Row>
      {isDeleteConfirmationPromptOpen && (
        <DeleteConfirmationPrompt
          onCancel={() => setIsDeleteConfirmationPromptOpen(false)}
          onConfirm={onRemoveUserConfirmation}
          title={`Are you sure you want to remove the User?`}
          renderDescription={() => {
            return (
              <div>
                Removing <strong>{userToRemove?.displayName}</strong> from the
                job
              </div>
            );
          }}
          confirmBtnText={"Remove"}
        />
      )}
    </>
  );
}
