import firebase from "../config/firebase";
import { useSelector } from "react-redux";
import cuid from "cuid";
import { INSPECTION_DB_NAME } from "common/constants";
const db = firebase.firestore();
const NOTIFICATIONS = "notifications";
const CHAT = "chat";
export function fetchAllNotifications() {
  const user = firebase.auth().currentUser;
  const limit = 10;
  let ref = db.collection(NOTIFICATIONS);

  return ref.where("recipientId", "==", user?.uid).orderBy("timestamp", "desc");
}

export async function markAsRead(notification) {
  notification.isRead = true;
  await db.collection(NOTIFICATIONS).doc(notification.id).update(notification);
}

export async function markAllAsRead(notifications) {
  const batch = db.batch();
  notifications.forEach(notification => {
    notification.isRead = true;
    let ref = db.collection(NOTIFICATIONS).doc(notification.id);
    batch.update(ref, notification);
  });
  await batch.commit();
}

export async function deleteAll(notifications) {
  const batch = db.batch();
  notifications.forEach(notification => {
    let ref = db.collection(NOTIFICATIONS).doc(notification.id);
    batch.delete(ref, notification);
  });
  await batch.commit();
}

export async function addAdminNotificationsToDb(notifications) {
  const batch = db.batch();
  notifications?.forEach(notification => {
    const docRef = db.collection(NOTIFICATIONS).doc();
    batch.set(docRef, notification);
  });
  await batch.commit();
}
export async function addJobChatNotification(jobId, participants, jobNo,collectionName) {
  const user = firebase.auth().currentUser;
  const notificationObjects = createNotificationObjects(
    participants,
    user,
    jobId,
    jobNo,
    collectionName
  );
  createNotifications(notificationObjects);
}

async function getRecipient(jobId, recipientId) {
  return await db
    .collection(NOTIFICATIONS)
    .where("recipientId", "==", recipientId)
    .where("type", "==", CHAT)
    .where("jobId", "==", jobId)
    .get();
}

function createNotificationObjects(participants, user, jobId, jobNo,collectionName) {
  const notificationDocs = [];
  participants.forEach(async id => {
    if (id !== user.uid) {
      const notification = {
        id: cuid(),
        jobId: jobId,
        isRead: false,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        type: "chat",
        description: `Job ${jobNo} - You have got new messages.`,
        recipientId: id,
      };
      if (collectionName === INSPECTION_DB_NAME)
      {
        notification.collectionName = true;
      }
      notificationDocs.push(notification);
    }
  });
  return notificationDocs;
}

async function createNotifications(notificationObects) {
  const batch = db.batch();
  for (let i = 0; i < notificationObects.length; i++) {
    const recipient = getRecipient(
      notificationObects[i].jobId,
      notificationObects[i].recipientId
    );
    const isRecipientNotExist = (await recipient).empty;

    if (isRecipientNotExist) {
      const docRef = db.collection(NOTIFICATIONS).doc();
      batch.set(docRef, notificationObects[i]);
    } else {
      const recipientDoc = (await recipient).docs;

      const ref = db.collection(NOTIFICATIONS).doc(recipientDoc[0].id);
      batch.update(ref, notificationObects[i]);
    }
  }
  await batch.commit();
}

export function getChatNotificationOfJob(notifications, userId, jobId) {
  return notifications.find(
    notification =>
      notification.jobId == jobId &&
      notification.recipientId == userId &&
      notification.type == CHAT &&
      notification.isRead == false
  );
}
