import {
  changeSidebarType as changeSidebarTypeAction,
  changeTopbarTheme as changeTopbarThemeAction,
} from "./../store/actions";
import { store } from "./../store/configureStore";

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

/**
 * Changes the layout type
 * @param {*} param0
 */
export function changeLayout(layout) {
  try {
    if (layout === "horizontal") {
      store.dispatch(changeTopbarThemeAction("dark"));
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-sidebar-image");
      document.body.removeAttribute("data-sidebar-size");
    } else {
      store.dispatch(changeTopbarThemeAction("light"));
    }
    changeBodyAttribute("data-layout", layout);
  } catch (error) {}
}

/**
 * Changes the layout width
 * @param {*} param0
 */
export function changeLayoutWidth(width) {
  try {
    if (width === "boxed") {
      store.dispatch(changeSidebarTypeAction("icon"));
      changeBodyAttribute("data-layout-size", width);
      changeBodyAttribute("data-layout-scrollable", false);
    } else if (width === "scrollable") {
      store.dispatch(changeSidebarTypeAction("default"));
      schangeBodyAttribute("data-layout-scrollable", true);
    } else {
      store.dispatch(changeSidebarTypeAction("default"));
      changeBodyAttribute("data-layout-size", width);
      changeBodyAttribute("data-layout-scrollable", false);
    }
  } catch (error) {}
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
export function changeSidebarTheme(theme) {
  try {
    changeBodyAttribute("data-sidebar", theme);
  } catch (error) {}
}

/**
 * Changes the left sidebar theme Image
 * @param {*} param0
 */
export function changeSidebarThemeImage(theme) {
  try {
    changeBodyAttribute("data-sidebar-image", theme);
  } catch (error) {}
}

/**
 * Changes the topbar theme
 * @param {*} param0
 */
export function changeTopbarTheme(theme) {
  try {
    changeBodyAttribute("data-topbar", theme);
  } catch (error) {}
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
export function changeSidebarType({ sidebarType, isMobile  }) {
  try {
    switch (sidebarType) {
      case "compact":
        changeBodyAttribute("data-sidebar-size", "small");
        manageBodyClass("sidebar-enable", "remove");
        manageBodyClass("vertical-collpsed", "remove");
        break;
      case "icon":
        changeBodyAttribute("data-sidebar-size", "");
        changeBodyAttribute("data-keep-enlarged", "true");
        manageBodyClass("vertical-collpsed", "add");
        break;
      case "condensed":
        manageBodyClass("sidebar-enable", "add");
        if (window.screen.width >= 992) {
          manageBodyClass("vertical-collpsed", "remove");
          manageBodyClass("sidebar-enable", "remove");
          manageBodyClass("vertical-collpsed", "add");
          manageBodyClass("sidebar-enable", "add");
        } else {
          manageBodyClass("sidebar-enable", "add");
          manageBodyClass("vertical-collpsed", "add");
        }
        break;
      default:
        changeBodyAttribute("data-sidebar-size", "");
        manageBodyClass("sidebar-enable", "remove");
        if (!isMobile)
          manageBodyClass("vertical-collpsed", "remove");
        break;
    }
  } catch (error) {}
}

/**
 * Show the rightsidebar
 */
export function showRightSidebar() {
  try {
    manageBodyClass("right-bar-enabled", "add");
  } catch (error) {}
}
