import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "reactstrap";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { signOutFirebase, updateUserPassword } from "services";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Field, Form } from "@availity/form";
import "@availity/yup";
import * as yup from "yup";
import { InputAdornment } from "@mui/material";

const ChangePassword = () => {
  const { currentUserProfile } = useSelector(state => state.profile);
  const { resetError, loading } = useSelector(state => state.auth);
  const [complete, setComplete] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordIcon, setShowPasswordIcon] = useState(true);
  const [showConfirmPasswordIcon, setShowConfirmPasswordIcon] = useState(true);
  
  const handleValidSubmit = async values => {
    if (values?.newPassword?.length < 6) {
      toast.error(`Password should be at least 6 characters`, {
        autoClose: 5000,
      });
      return;
    }
    updateUserPassword(values);

    toast.success(`Password changed successfully. Please re-login`, {
      autoClose: 3000,
    });
    logOutAllSessionOnUpdatePassword();
    signOutFirebase();
    history.push("/login");
    setComplete(true);
  };
  const logOutAllSessionOnUpdatePassword = async () => {
    try {
      const userId = currentUserProfile.id;
      await axios.post(`${CLOUD_FUNCTION_URL}/logOutAllSessions`, {
        id: userId,
      });
    } catch (error) {
      console.log("-----error: ", error);
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleMouseDown = event => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(prevShowConfirmPassword => !prevShowConfirmPassword);
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Change Password</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs title="Setup" breadcrumbItem="Settings" />

        {!complete && (
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h5>Change password</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={values => {
                      handleValidSubmit(values);
                    }}
                    initialValues={{
                      newPassword: "",
                      confirmPassword: "",
                    }}
                    validationSchema={yup.object().shape({
                      newPassword: yup
                        .string()
                        .isRequired(true, "Please enter a password."),
                      confirmPassword: yup
                        .string()
                        .isRequired(true, "Please confirm password.")
                        .oneOf(
                          [yup.ref("newPassword"), null],
                          "Passwords do not match"
                        ),
                    })}
                    validate = {(values)=>{
                      if(values?.newPassword === ""){
                        setShowPasswordIcon(false)
                      }
                      else{
                        setShowPasswordIcon(true);
                      }
                      if(values?.confirmPassword === "" || values?.confirmPassword !== values?.newPassword){
                        setShowConfirmPasswordIcon(false)
                      }
                      else{
                        setShowConfirmPasswordIcon(true);
                      }
                    }}
                  >
                    {resetError && resetError.message ? (
                      <Alert color="danger">{resetError.message}</Alert>
                    ) : null}
                    <div className="mb-3">
                      <Field
                        name="newPassword"
                        disabled={loading}
                        label="New password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter password"
                      />
                      { showPasswordIcon &&
                        <InputAdornment position="end" style={{flexDirection: 'column', alignItems:'end', cursor:'pointer'}}>
                            <span
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDown}
                              style={{fontSize:'1.5rem', position:'relative',top:'-2.2rem', right:'1.6rem'}}
                            >
                              {showPassword ? <i className='mdi mdi-eye' ></i>: <i className='mdi mdi-eye-off' ></i>}
                            </span>
                          </InputAdornment>}
                    </div>
                    <div className="mb-3">
                      <Field
                        name="confirmPassword"
                        disabled={loading}
                        label="Confirm new password"
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Re-enter password"
                      />
                       { showConfirmPasswordIcon &&
                        <InputAdornment position="end" style={{flexDirection: 'column', alignItems:'end', cursor:'pointer'}}>
                            <span
                              aria-label="toggle confirm password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDown}
                              style={{fontSize:'1.5rem', position:'relative',top:'-2.2rem', right:'1.6rem'}}
                            >
                              {showConfirmPassword ? <i className='mdi mdi-eye' ></i>: <i className='mdi mdi-eye-off' ></i>}
                            </span>
                          </InputAdornment>}
                    </div>
                    <div className="text-center">
                      <Button
                        className="mt-4"
                        type="submit"
                        disabled={loading}
                        style={{
                          backgroundColor:"#50a5f1",
                          border:"#50a5f1",
                        }}
                      >
                        {loading && (
                          <span
                            className="spinner-grow spinner-grow-sm mr-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        Change password
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {complete && (
          <Row>
            {" "}
            <Col>
              <Card>
                <CardHeader>
                  <h5>Change password</h5>
                </CardHeader>
                <CardBody>
                  <Alert>Password changed successfully</Alert>
                  <Alert color="warning">
                    Please logout and re-login with your new password
                  </Alert>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
