import firebase from "../config/firebase";
const db = firebase.firestore();

export function fetchClientsFromDb(orgId) {
  return db.collection(`orgs/${orgId}/client`);
}

export function addClientToDb(orgId, client) {
  return db.collection(`orgs/${orgId}/client`).add({
    ...client,
    date: firebase.firestore.FieldValue.serverTimestamp(),
  });
}

export function updateClientInDb(orgId, client) {
  return db.collection(`orgs/${orgId}/client`).doc(client.id).update(client);
}

export function deleteClientInDb(orgId, clientId) {
  return db.collection(`orgs/${orgId}/client`).doc(clientId).delete();
}
