import React, { memo } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { ACTIVE_JOBS_DB_NAME, INSPECTION_DB_NAME } from 'common/constants';
import { sectionSummary,  inspectionSectionSummary } from 'pages/jobs/jobTypes/active/percentageCalculation';
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
  },
  table: {
    flexDirection: 'row',
    width: '100%',
   border:"none",
  },
  cell: {
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 5,
    backgroundColor: 'gray',
    border:"none",
  },
  lastCell: {
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 5,
    backgroundColor: 'gray',
    border:"none",
  },
  cellText: {
    color: 'black',
  },
});

// const SectionSummary = ({section}) => (
//   <View style={styles.table}>
//     <View style={{...styles.cell,backgroundColor:"#1F4E681A",borderColor:"#1F4E681A"}}>
//       <Text style={{color: "black",fontSize:12}}>Total number of Assesments : {section.totalItems}</Text>
//     </View>
//     <View style={{...styles.cell,backgroundColor:"#89B27B",borderColor:"#89B27B"}}>
//       <Text style={{color: "black",fontSize:12}}>Completed Items : {section.checkedItems}</Text>
//     </View>
//     <View style={{...styles.cell,backgroundColor:"#EF847E",borderColor:"#EF847E"}}>
//       <Text style={{color: "black",fontSize:12}}>Pending Items : {section.failedItems}</Text>
//     </View>
//   </View>
// );
const InspectionSectionSummary = ({ section}) => (
  <View style={styles.table}>
    <View style={{...styles.cell,backgroundColor:"#1F4E681A",borderColor:"#1F4E681A"}}>
      <Text style={{color: "black",fontSize:12}}>Total number of Assesments : {section.totalItems}</Text>
    </View>
    <View style={{...styles.cell,backgroundColor:"#89B27B",borderColor:"#89B27B"}}>
      <Text style={{color: "black",fontSize:12}}>Pass : {section.passedItems}</Text>
    </View>
    <View style={{...styles.cell,backgroundColor:"#F0CF89",borderColor:"#F0CF89"}}>
      <Text style={{color: "black",fontSize:12}}>F/A : {section.furtherItems}</Text>
    </View>
    <View style={{...styles.cell,backgroundColor:"#A7CBF7",borderColor:"#A7CBF7"}}>
      <Text style={{color: "black",fontSize:12}}>Done : {section.doneItems}</Text>
    </View>
    <View style={{...styles.lastCell,backgroundColor:"#EF847E",borderColor:"#EF847E"}}>
      <Text style={{color: "black",fontSize:12}}>Fail : {section.failedItems}</Text>
    </View>
    <View style={{...styles.lastCell,backgroundColor:"#656466"}}>
      <Text style={{color: "black",fontSize:12}}>N/A : {section.notAppliedItems}</Text>
    </View>
  </View>
);
const ChecklistAssesments = ({certificate,collectionName}) => {
  const sectionItemsInfo = (collectionName, rooms, certificate) => {
    if (collectionName === INSPECTION_DB_NAME)
      return inspectionSectionSummary(rooms, certificate);
    return sectionSummary(rooms, certificate);
  };
  const showSectionItemsStatus = sectionItemsInfo(collectionName, certificate?.rooms, certificate);
  return (
    <View>
    {collectionName === INSPECTION_DB_NAME && <InspectionSectionSummary section={showSectionItemsStatus}/>}
    {/* {collectionName == ACTIVE_JOBS_DB_NAME && <SectionSummary section={showSectionItemsStatus}/>} */}
  </View>
  )

}

export default memo(ChecklistAssesments);