import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "../authentication/EmailVerification.scss";
import { useSelector } from "react-redux";
import { sendEmailVerification } from "services";

const EmailVerification = () => {
  const { isEmailSent } = useSelector(state => state.auth);
  const [currentUser, setCurrentUser] = useState(null);
  const user = firebase.auth().currentUser;

  const history = useHistory();
  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, []);
  useEffect(() => {
    const verifyEmail = async () => {
      if (!isEmailSent && !currentUser) {
        history.push("/login");
      }
    };
    verifyEmail();
  }, [currentUser]);

  const resendEmail = async () => {
     await sendEmailVerification(currentUser)
       .then(() => {
         console.log(
           "Email send for verification! Please check your email for verification."
         );
       })
       .catch(error => {
         console.error("Error sending verification email:", error);
       });
   
  };
  return (
    <>
      <img
        src="https://i.ibb.co/B6LXbPn/ibLogo.png"
        alt="logo"
        id="logo"
        style={{ width: "80px", height: "80px" }}
      />
      <div className="email-verification">
        <h1>
          Email verification
        </h1>
        <div className="warning">
          <img
            src="https://i.ibb.co/Thv9j6P/warning-Icon.png"
            alt="warningIcon"
            style={{ width: "15px", height: "15px" }}
          />
          <p>You need to verify your email address to activate your account.</p>
        </div>
        <p>
          An email with instructions to verify your email address has been sent
          to you.
        </p>
        <p>
          Haven't received a verification code in your email?
        </p>
        <p>
          <a href="#" onClick={resendEmail}>
            {" "}
            Click here
          </a>{" "}
          to re-send the email.
        </p>
      </div>
    </>
  );
};

export default EmailVerification;
