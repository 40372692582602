import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { Prompt, useLocation } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";

const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  saveJob,
  setWizardExited,
  isChecklist,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [isDiscard, setIsDiscard] = useState(false); // for ClientDetails to delete draft checklist subcollection on Discard
  const location = useLocation();
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = nextLocation => {
    if (nextLocation.pathname === location.pathname) return false;
    if (!confirmedNavigation && shouldBlockNavigation()) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigation = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    setIsDiscard(true);
  };

  const handleSaveAndConfirmNavigation = () => {
    saveJob();
    setModalVisible(false);
    setConfirmedNavigation(true);
    setWizardExited(true);
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      lastLocation?.hash !== ""
        ? navigate(lastLocation.pathname + lastLocation.hash, isDiscard)
        : navigate(lastLocation.pathname, isDiscard);
    }
  }, [confirmedNavigation, lastLocation]);

  useEffect(() => {}, [confirmedNavigation]);

  const boldStyle = {
    fontWeight: "bold",
  };

  const buttonStyle = {
    padding: "0.6rem",
    width: "7rem",
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {modalVisible &&
        (isChecklist ? (
          <SweetAlert
            title="ITP Checklist not saved!"
            warning
            confirmBtnText="Ok"
            closeOnClickOutside
            confirmBtnBsStyle="warning"
            onConfirm={closeModal}
          >
            Please save all the checklists
          </SweetAlert>
        ) : (
          <SweetAlert
            title="Unsaved changes"
            warning
            showCancel={false}
            showConfirm={false}
            closeOnClickOutside
            onCancel={closeModal}
          >
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                backgroundColor: "white",
                top: "1rem",
                right: "1rem",
                border: "none",
              }}
            >
              <CloseIcon
                style={{
                  color: "gray",
                }}
              />
            </button>
            <p>You have unsaved changes in this step.</p>
            <p>
              <InfoIcon
                style={{
                  color: "skyblue",
                }}
              />{" "}
              <span>
                The job will be saved in <b style={boldStyle}>Drafts</b> for you
                to continue editing later.
              </span>
            </p>
            <div
              style={{
                padding: "1rem 0",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                color="danger"
                style={buttonStyle}
                onClick={handleConfirmNavigation}
              >
                Discard
              </Button>
              <Button
                color="success"
                style={buttonStyle}
                onClick={handleSaveAndConfirmNavigation}
              >
                Save
              </Button>
            </div>
          </SweetAlert>
        ))}
    </>
  );
};
export default RouteLeavingGuard;
