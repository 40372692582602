import cuid from "cuid";
import { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import "../../common/styles.css";
import { addDocumentListsToDb, updateDocumentListsInDb } from "services/documentService";
import { toast } from "react-toastify";

export default function CreateNewDocumentType({
  showCreateNewDocument,
  setShowCreateNewDocument,
  isEditDocument,
  setEditDocument,
  document,
  setDocument,
  currentUserProfile,
}) {
  const [isValidForm, setIsValidForm] = useState(isEditDocument ? true : false);

  useEffect(() => {
    setIsValidForm(
      document?.name 
    );
  }, [document]);

  
  const handleSaveClick = () => {
    try{
    if (document && document.name === "") {
      setIsValidForm(false);
      return;
    }
  
    const newDocument = {
      id: document?.id || cuid(),
      name: document?.name || "",
      attachments: document?.attachments || [],
    };

    if (!isEditDocument) {
      addDocumentListsToDb(currentUserProfile.orgAccessId, newDocument);
    } else {
      const updateUser = { ...newDocument, id: document?.id };
      updateDocumentListsInDb(currentUserProfile.orgAccessId, updateUser);
    }
  
    setEditDocument(false);
    setShowCreateNewDocument(false);
    toast.success("Document folder added successfully!", {
      autoClose: 2000,
    });
  }catch(e){
    toast.error(e.message, {
      autoClose: 2000,
    });
    console.error(e);
  }
  };

  return (
    <Modal
      isOpen={showCreateNewDocument}
      toggle={() => setShowCreateNewDocument(!showCreateNewDocument)}
      size="md"
    >
      <ModalHeader
        toggle={() => setShowCreateNewDocument(!showCreateNewDocument)}
        tag="h4"
      >
        {isEditDocument ? "Edit Document Type" : "Add Document Type"}
      </ModalHeader>

      <ModalBody>
        <Row form>
          <Col>
            <Row>
                <Col lg="12" md="12">
                  <Label>Document Type Name</Label>
                  <Input
                    name="name"
                    type="text"
                    value={document?.name || ""}
                    onChange={e => {
                      setDocument({
                        ...document,
                        name: e.target.value,
                      });
                    }}
                  />
                  {!document?.name && (
                    <small className="requiredError">{"*Required"}</small>
                  )}
                </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <div className="text-end mt-3">
            <button
              type="submit"
              className="btn btn-success save-user"
              disabled={!isValidForm}
              onClick={handleSaveClick}
            >
              Save
            </button>
          </div>
        </Row>
      </ModalBody>
    </Modal>
  );
}
