import React, { createContext, useContext, useEffect, useState } from 'react';

export const LinkJobContext = createContext({
  checkedItems: [],
  toggleCheckbox: () => {},
});

export const useLinkJobContext = () => useContext(LinkJobContext);

export const LinkJobContextProvider = ({ children }) => {
  const [checkedItems, setCheckedItems] = useState([]);
  
  useEffect(() => {
    const checkedJobs = localStorage.getItem('checkedJobs');
    if (checkedJobs) {
      const jobs = JSON.parse(checkedJobs);
      setCheckedItems(jobs);
    }
  }, []);
  
  const toggleCheckbox = (item) => {
    console.log("Item",item)
    const isItemChecked = checkedItems?.some((checkedItem) => checkedItem.id === item.id);
    console.log("is item checked",isItemChecked);
    if (isItemChecked) {
      setCheckedItems(checkedItems?.filter((checkedItem) => checkedItem.id !== item.id));
    } else {
      setCheckedItems([...checkedItems, item]);
    }
  };

  return (
    <LinkJobContext.Provider value={{ checkedItems, toggleCheckbox }}>
      {children}
    </LinkJobContext.Provider>
  );
};
