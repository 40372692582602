import {
  FETCH_ORG_USERS,
  FETCH_ORG_USERS_ERROR,
  FETCH_ORG_USERS_SUCCESS,
} from "./actionTypes";

const initialState = {
  orgUsers: [],
  loading: false,
};

export default function orgUsersReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_ORG_USERS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORG_USERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ORG_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orgUsers: payload,
      };
    default:
      return state;
  }
}
