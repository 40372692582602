export function DownloadImage() {

  return async (imageUrl,filename) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob); 
      downloadLink.download = filename
      downloadLink.click();
      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      console.log("Error downloading image:", error);
    }
  };
}
