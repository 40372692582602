import { memo, useEffect, useState } from "react";
import PDFModal from "./PdfModal";
import { generateBlob } from "./generateBlob";
export function PreviewPdfModal({
  certificate,
  collectionName,
  setShowCertModal,
  uploadDocument,
  progress,
  setCertificate,
  setLoading,
  setProgress
}) {
  const [blob, setBlob] = useState(null);
  const [isPdf, setPdf] = useState(false);
  useEffect(() => {
    let isMounted = true; 
    (async function blobGeneration() {
      // setProgress((prev) => prev + 20);
      setLoading(true)
      try {
        const blob = await generateBlob(certificate, collectionName, setPdf);
        if (isMounted) {
          setBlob(blob);
          setProgress(100);
          setLoading(false)
        }
      } catch (error) {
        console.error('Error generating blob:', error);
      }
    })();
  
    return () => {
      isMounted = false;
    };
  }, [certificate, collectionName]);

  const closeModal = () => {
    setPdf(false);
    setBlob(null);
    setCertificate(null);
    setLoading(false);
    setProgress(0)
    // setShowCertModal(false);
  };
  console.log(":PDFModalPDFModalPDFModal::",)
  return (
    <div>
      {blob !== null && (
        <PDFModal
          isOpen={isPdf}
          closeModal={closeModal}
          pdfUrl={blob}
          pdfName={certificate.docNo}
          pdfJob={uploadDocument}
          progress={progress}
          setShowCertModal={setShowCertModal}
        />
      )}
    </div>
  );
}
export default memo(PreviewPdfModal);
