// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mtb-2 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.move-down-1 {
  transform: translate(0, 10px);
}

.move-down-2 {
  transform: translate(0, 15px);
}

.thumbnail:hover {
  position: absolute;
  top: -25px;
  left: -35px;
  width: 100px;
  height: auto;
  display: block;
  z-index: 999;
}

.building-class {
  height: 50px;
  width: 50px;
  transform: translate(0, -8px);
}

.clientDetailsPhoneNumber input {
  color: #495057;
  font-weight: 400;
  font-size: 13px;
  padding: 0.42rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/jobCreation/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".ml-5 {\n  margin-left: 5px;\n}\n\n.mr-5 {\n  margin-right: 5px;\n}\n\n.ml-10 {\n  margin-left: 10px;\n}\n\n.ml-20 {\n  margin-left: 20px;\n}\n\n.mtb-2 {\n  margin-top: 5px !important;\n  margin-bottom: 5px !important;\n}\n\n.move-down-1 {\n  transform: translate(0, 10px);\n}\n\n.move-down-2 {\n  transform: translate(0, 15px);\n}\n\n.thumbnail:hover {\n  position: absolute;\n  top: -25px;\n  left: -35px;\n  width: 100px;\n  height: auto;\n  display: block;\n  z-index: 999;\n}\n\n.building-class {\n  height: 50px;\n  width: 50px;\n  transform: translate(0, -8px);\n}\n\n.clientDetailsPhoneNumber input {\n  color: #495057;\n  font-weight: 400;\n  font-size: 13px;\n  padding: 0.42rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
