import { FETCH_ORGS, FETCH_ORG_SUCCESS, FETCH_ORG_ERROR } from "./actionTypes";

const initialState = {
  orgs: [],
  loading: false,
};

export default function orgReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_ORGS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORG_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ORG_SUCCESS:
      return {
        ...state,
        loading: false,
        orgs: payload,
      };
    default:
      return state;
  }
}
