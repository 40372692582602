import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { Input, Label } from "reactstrap";

export default function AdressAutoComplete({
  address,
  setAddress,
  onSaveClickHandler,
  initialAddressValue,
  setEditAddress,
  label = false,
  labelName = "Address",
  labelComponent = null,
  buttons = true,
}) {
    const sortPlaces = (a, b) => {
    if (a.description.includes('Australia') && !b.description.includes('Australia')) {
      return -1;
    } else if (!a.description.includes('Australia') && b.description.includes('Australia')) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "280px",
      }}
    >
      <PlacesAutocomplete
        value={address}
        onChange={addr => setAddress(addr)}
        onSelect={addr => setAddress(addr)}
        // searchOptions={{ types: ['(regions)'] }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            {label && labelName ? <Label>{labelName}</Label> : labelComponent}
            <Input
              className={label ? "addressWithLabel" : "addressInput"}
              {...getInputProps({
                placeholder: "Search address ...",
              })}
            />
            {label && !address && (
              <small className="requiredError"></small>
            )}

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions?.sort(sortPlaces).map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                console.log(suggestion);
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {buttons && (
        <div className="action-buttons">
          <CheckOutlinedIcon
            className="saveOrCancelButton"
            style={{
              backgroundColor: "#34c38f",
            }}
            onClick={onSaveClickHandler}
          />

          <CloseIcon
            style={{
              backgroundColor: "#f46a6a",
            }}
            className="saveOrCancelButton"
            onClick={() => {
               initialAddressValue?.length < 1
                ? setAddress("")
                : setAddress(initialAddressValue);

              setEditAddress(false);
            }}
          />
        </div>
      )}
    </div>
  );
}
AdressAutoComplete.propTypes = {
  address: PropTypes.string.isRequired,
  setAddress: PropTypes.func.isRequired,
  onSaveClickHandler: PropTypes.func,
  setEditAddress: PropTypes.func,
  initialAddressValue: PropTypes.string,
  label: PropTypes.bool,
  buttons: PropTypes.bool,
};
