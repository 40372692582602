import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "../components/JobSearch.scss";
import "./customStyles.scss";
import { ClearSearchBox } from "./ClearSearchBox";

export const CustomSearchView = ({
  getAutocomplete,
  getInputProps,
  getButtonProps,
  setSearchTerm,
  shouldClearFilters,
  resultSearchTerm,
}) => {
  return (
    <>
      <FormControl fullWidth>
        <div className="sui-search-box__wrapper">
          <TextField
            {...getInputProps({
              label: "Enter Search Parameter",
              variant: "outlined",
              className: "search-parameter-input",
              size: "small",
              InputProps: {
                endAdornment: resultSearchTerm && (
                  <InputAdornment position="end">
                    <ClearSearchBox
                      setSearchTerm={setSearchTerm}
                      shouldClearFilters={shouldClearFilters}
                    />
                  </InputAdornment>
                ),
              },
            })}
          />
          {getAutocomplete()}
        </div>
      </FormControl>
      <IconButton {...getButtonProps()}>
        <SearchIcon />
      </IconButton>
    </>
  );
};
