import { formatDistance } from "date-fns";
import { map } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, CardTitle, Col, Media, Row } from "reactstrap";
import {
  addJobChatComment,
  addJobChatNotification,
  firebaseObjectToArray,
  getJobChatRef,
  uploadImageToFirebaseStorage,
} from "services";
import ChatInputField from "components/ChatInputField/ChatInputField";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as markerjs2 from "markerjs2";
import { DownloadImage } from "pages/jobs/jobDetails/rooms/DownloadImage";
import { toast } from "react-toastify";
import { getBase64String } from "common/utils";
import { isEmpty } from "lodash";
import cuid from "cuid";
import SweetAlert from "react-bootstrap-sweetalert";

export default function Comments({
  id,
  job,
  isChatSelected,
  isJobSelected,
  collectionName,
}) {
  const [selectChat, setSelectChat] = useState(isChatSelected);

  useEffect(() => {
    if (isJobSelected && !isChatSelected) setSelectChat(false);
    else setSelectChat(true);
    const timeout = setTimeout(() => {
      setSelectChat(true);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [isChatSelected, id, isJobSelected]);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const jobKey = job.id;
    getJobChatRef(jobKey).on("value", snapshot => {
      if (!snapshot.exists()) return;
      const chats = firebaseObjectToArray(snapshot.val()).reverse();
      setComments(chats);
    });
    return () => {
      getJobChatRef().off();
    };
  }, [job]);

  return (
    <Card style={{ maxHeight: "600px", overflow: "scroll" }} id="chat-area">
      <CardBody className={selectChat ? "text-muted" : "bg-primary bg-soft"}>
        <CardTitle className="mb-4">Chat</CardTitle>
        <ChatInputField job={job} collectionName={collectionName} />
        <br />
        {map(comments, (comment, index) => (
          <Media className="mb-4" key={index}>
            <div className="me-3">
              {comment.photoURL ? (
                <img
                  className="media-object rounded-circle avatar-xs"
                  alt=""
                  src={comment.photoURL}
                />
              ) : comment.displayName?.includes("https:/") ? (
                <img
                  className="media-object rounded-circle avatar-xs"
                  alt=""
                  src={comment.displayName}
                />
              ) : (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16">
                    {comment.displayName?.charAt(0)}
                  </span>
                </div>
              )}
            </div>
            <ChatContent
              comment={comment}
              job={job}
              collectionName={collectionName}
            />
          </Media>
        ))}
      </CardBody>
    </Card>
  );
}

export const ChatContent = ({ comment, job, collectionName }) => {
  const [isImageFullScreen, setIsImageFullScreen] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);

  const handleConfirm = () => {
    setConfirmAlert(false);
    toggle();
  };

  const handleCancel = () => {
    setConfirmAlert(false);
  };
  const divRef = useRef(null);
  const imgRef = useRef(null);

  const convertToBlob = (file, mimeType) => {
    const byteCharacters = atob(file.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    return blob;
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const addChatImageAttachments = downloadURL => {
    if (!isEmpty(downloadURL.trim())) {
      addJobChatComment(
        job.id,
        {
          comment: "",
          parentId: 0,
          imageUrl: downloadURL,
        },
        collectionName
      );
      addJobChatNotification(job.id, job.accessIds, job.jobNo, collectionName);
    }
  };

  const showMarkerArea = () => {
    if (imgRef.current !== null) {
      const markerArea = new markerjs2.MarkerArea(imgRef.current);
      markerArea.targetRoot = divRef.current;
      markerArea.settings.defaultColor = "#000000";
      markerArea.settings.defaultColorsFollowCurrentColors = true;
      markerArea.settings.defaultFillColor = "transparent";

      markerArea.uiStyleSettings.zoomButtonVisible = true;
      markerArea.uiStyleSettings.zoomOutButtonVisible = true;

      markerArea.availableMarkerTypes = [
        "FrameMarker",
        "FreehandMarker",
        markerjs2.ArrowMarker,
        "EllipseMarker",
        "HighlightMarker",
        "CalloutMarker",
      ];
      markerArea.addEventListener("beforeclose", event => {
        if (event.markerArea.markers.length > 0) {
          setConfirmAlert(true);
        }
        else{
          event.preventDefault();
          toggle();
        }
      });

      markerArea.addEventListener("render", async event => {
        try {
          if (imgRef.current && event.markerArea.markers.length > 0) {
            const match = event?.dataUrl?.match(/^data:(.*?);base64,/);
            const mimeType = match ? match[1] : null;

            imgRef.current.src = event?.dataUrl;
            comment.imageUrl = event?.dataUrl;

            const blob = convertToBlob(event?.dataUrl, mimeType);
            const base64String = await getBase64String(blob);

            const uploadTask = uploadImageToFirebaseStorage(
              base64String,
              cuid(),
              formatBytes(mimeType),
              job.orgId,
              job.jobNo
            );

            uploadTask.on(
              "state_changed",
              snapshot => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
              },
              error => {
                console.error("error", error.messege);
              },
              () => {
                uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                  if (mimeType.includes("image")) {
                    addChatImageAttachments(downloadURL);
                  }
                });
              }
            );
            toast.success("photo updated successfully !", {
              autoClose: 2000,
            });
            toggle();
          }
        } catch (err) {
          console.log(err);
        }
      });
      markerArea.show();
    }
  };

  const toggle = () => {
    setIsImageFullScreen(false);
    showMarkerArea();
  };

  const downloadImage = DownloadImage();
  return (
    <Media body>
      {!comment.displayName?.includes("https:/") && (
        <h5 className="font-size-13 mb-1">{comment.displayName}</h5>
      )}

      {comment.imageUrl ? (
        <img
          alt="Image not found"
          src={comment.imageUrl}
          width={150}
          height={150}
          onClick={() => {
            toast.info("Click on image or pen icon to edit image ", {
              autoClose: 3000,
            });
            setIsImageFullScreen(true);
          }}
          style={{
            cursor: "pointer",
          }}
        />
      ) : (
        <p className="text-muted mb-1">{comment.text}</p>
      )}

      {isImageFullScreen && (
        <Modal
          isOpen={isImageFullScreen}
          toggle={toggle}
          size="xl"
          className="modal-fullscreen"
          style={{ marginTop: "-14rem" }}
        >
          <ModalHeader toggle={toggle} style={{ justifyContent: "end" }}>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ marginTop: "-2rem" }}
            >
              <button
                type="button"
                className="btn btn-lg text-primary"
                onClick={showMarkerArea}
              >
                <i
                  className="fas fa-pencil-alt"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </button>
              <button
                type="button"
                className="btn btn-lg text-success"
                onClick={() => {
                  downloadImage(comment.imageUrl, "image");
                }}
              >
                <i
                  className="far fa-arrow-alt-circle-down"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </button>
            </div>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col
                sm="10"
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  ref={divRef}
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    width: "1100px",
                    height: "590px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    ref={imgRef}
                    src={comment.imageUrl}
                    alt={`Annotated image from ${comment.imageUrl}`}
                    crossOrigin="anonymous"
                    style={{ width: "100%", height: "100%", objectFit: "fill" }}
                    onClick={() => {
                      showMarkerArea();
                    }}
                  />
                </div>
              </Col>
              <Col sm="2">
                <div className="d-flex flex-column align-items-start">
                  <div className="d-flex align-items-center">
                    <h5 className="m-1 text-danger">Symbols Legend</h5>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-mouse-pointer"></i>
                    <p className="m-1">Select Mode</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="far fa-trash-alt"></i>
                    <p className="m-1">Delete Changes</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-undo"></i>
                    <p className="m-1">Undo</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-search-plus"></i>
                    <p className="m-1">zoom in</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-search-minus"></i>
                    <p className="m-1">zoom out</p>
                  </div>
                  <div className="d-flex align-items-center ">
                    <i className="far fa-square"></i>
                    <p className="m-1">Frame marker</p>
                  </div>
                  <div className="d-flex align-items-center ">
                    <i className="fas fa-pencil-alt"></i>
                    <p className="m-1">Freehand Marker</p>
                  </div>
                  <div className="d-flex align-items-center ">
                    <i className="fas fa-location-arrow fa-rotate-180"></i>
                    <p className="m-1">Arrow Marker</p>
                  </div>
                  <div className="d-flex align-items-center ">
                    <i className="fas fa-circle"></i>
                    <p className="m-1">Ellipse Marker</p>
                  </div>
                  <div className="d-flex align-items-center ">
                    <i className="fas fa-highlighter"></i>
                    <p className="m-1">Highlight Marker</p>
                  </div>
                  <div className="d-flex align-items-center ">
                    <i className="far fa-comment-alt"></i>
                    <p className="m-1">Callout marker</p>
                  </div>
                  <div className="d-flex align-items-center ">
                    <i className="fas fa-check"></i>
                    <p className="m-1">Save and Close</p>
                  </div>
                  <div className="d-flex align-items-center ">
                    <i className="fas fa-times"></i>
                    <p className="m-1">Close</p>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}

      <div className="ml-3">
        {formatDistance(comment.date, new Date()) + " ago"}
      </div>
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleConfirm();
          }}
          onCancel={() => {
            handleCancel();
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
    </Media>
  );
};
