import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import OpenSansBold from "assets/fonts/open-sans-600.ttf";
import React, { useEffect } from 'react';
import OpenSans from "assets/fonts/open-sans-regular.ttf";
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";
import RobotBold from "assets/fonts/Roboto-Bold.ttf";
import RobotItalic from "assets/fonts/Roboto-Italic.ttf";
import RobotBoldItalic from "assets/fonts/Roboto-BoldItalic.ttf";
import RobotBlack from "assets/fonts/Roboto-Black.ttf";
import RobotBlackItalic from "assets/fonts/Roboto-BlackItalic.ttf";
import RobotLight from "assets/fonts/Roboto-Light.ttf";
import RobotLightItalic from "assets/fonts/Roboto-LightItalic.ttf";
import RobotMedium from "assets/fonts/Roboto-Medium.ttf";
import RobotMediumItalic from "assets/fonts/Roboto-MediumItalic.ttf";
import RobotThin from "assets/fonts/Roboto-Thin.ttf";
import RobotThinItalic from "assets/fonts/Roboto-ThinItalic.ttf";
import NotoSans from "assets/fonts/NotoSans-V.ttf";
import NotoSansSC from "assets/fonts/NotoSansSC.ttf";
import NotoSansBold from "assets/fonts/noto-sans.bold.ttf";
import NotoSansBoldItalic from "assets/fonts/noto-sans.bold-italic.ttf";
import NotoSansItalic from "assets/fonts/NotoSans-Italic-Variable.ttf";
import NotoSansRegular from "assets/fonts/noto-sans.regular.ttf";
import NotoSansJp from "assets/fonts/NotoSansJP.ttf";






import CertificateTableCheckListRow from "./checklist/CertificateTableCheckListRow";
import FloorAndRoomDetails from "./FloorsAndRooms";
import ESignature from "./footer/ESignature";
import Footer from "./footer/Footer";
import ClientInfo from "./frontPage/ClientInfo";
import Header from "./frontPage/Header";
import JobDetails from "./frontPage/JobDetails";
import DynamicJobReportTemplateChecklist from "./DynamicJobReportTemplateChecklist";
import { PLACE_REPORT_END, PLACE_REPORT_START } from "common/constants";
import Participants from "./frontPage/Participaints";
import ChecklistAssesments from "./frontPage/ChecklistAssesments";
import CoverPageFooter from "./footer/CoverPageFooter";
import { memo } from "react";

Font.register({
  family: 'ZCool',
  src: 'https://fonts.gstatic.com/s/zcoolkuaile/v7/tssqApdaRQokwFjFJjvM6h2WpozzoXhC2g.ttf'
});

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: OpenSans,
    },
    {
      src: OpenSansBold,
      fontWeight: 600,
    },
  ],
});

Font.register({
  family: 'Noto Sans',
  src: NotoSans,
  fonts: [
    
    {
      src:NotoSansSC
    },
    { src: NotoSansRegular, fontWeight: 400 },
    { src: NotoSansBold, fontWeight: 600 },
    { src: NotoSansItalic, fontStyle: 'italic' },
    { src: NotoSansBoldItalic, fontWeight: 600, fontStyle: 'italic' },
    {
      src:NotoSansBold,
      fontWeight:600
    },
    {
      src:NotoSansItalic,
    },
    {
      src:NotoSansBoldItalic,
    },
    {
      src:NotoSansRegular,
      fontStyle: 600,
      fontStyle:"normal"
    },
    {
      src:NotoSansJp,
    }
    

    
  ]
});


Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoRegular,
      fontWeight: "normal",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontStretch: "normal",
      fontVariant: "normal",
      fontFeatureSettings: "normal",
    },
    {
      src: RobotBold,
      fontWeight: "bold",
    },
    {
      src: RobotItalic,
      fontStyle: "italic",
    },
    {
      src: RobotBoldItalic,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: RobotBlack,
      fontWeight: "bold",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontStretch: "normal",
      fontVariant: "normal",
      fontFeatureSettings: "normal",
    },
    {
      src: RobotBlackItalic,
      fontWeight: "bold",
      fontFamily: "Roboto",
      fontStyle: "italic",
      fontStretch: "normal",
      fontVariant: "normal",
      fontFeatureSettings: "normal",
    },
    {
      src: RobotLight,
      fontWeight: "normal",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontStretch: "normal",
      fontVariant: "normal",
      fontFeatureSettings: "normal",
    },
    {
      src: RobotLightItalic,
      fontWeight: "normal",
      fontFamily: "Roboto",
      fontStyle: "italic",
      fontStretch: "normal",
      fontVariant: "normal",
      fontFeatureSettings: "normal",
    },
    {
      src: RobotMedium,
      fontWeight: "normal",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontStretch: "normal",
      fontVariant: "normal",
      fontFeatureSettings: "normal",
    },
    {
      src: RobotMediumItalic,
      fontWeight: "normal",
      fontFamily: "Roboto",
      fontStyle: "italic",
      fontStretch: "normal",
      fontVariant: "normal",
      fontFeatureSettings: "normal",
    },
    {
      src: RobotThin,
      fontWeight: "normal",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontStretch: "normal",
      fontVariant: "normal",
      fontFeatureSettings: "normal",
    },
    {
      src: RobotThinItalic,
      fontWeight: "normal",
      fontFamily: "Roboto",
      fontStyle: "italic",
      fontStretch: "normal",
      fontVariant: "normal",
      fontFeatureSettings: "normal",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    fontFamily: "Noto Sans",
    fontSize: 11,
    paddingTop: 24,
    paddingBottom: 60,
    paddingLeft: 24,
    paddingRight: 24,
    lineHeight: 1.5,
    flexDirection: "column",
    borderColor: "#f5f0f0",
  },
  chineseText:{fontFamily: 'ZCool'},
  boxContainer: {
    borderWidth: 1,
    borderColor: "#f5f0f0",
  },
  title: {
    fontSize: 20,
    marginTop: 12,
    marginBottom: 12,
    textAlign: "left",
    fontWeight: 600,
    color: "#1F4E68",
  },
  text: {
    margin: 12,
    fontSize: 11,
    textAlign: "justify",
  },
  footer: {
    position: "absolute",
    bottom: 20,
    right: "auto",
    alignSelf: "flex-end",
    paddingRight: 10,
    paddingTop: 10,
  },
  sign: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 40,
    paddingLeft: 25,
  },
  container: {
    flexGrow: 1,
  },
});

const Certificate = ({ certificate, collectionName }) => {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem("IMAGE_COUNT", 0);
  }

  const { orgInfo, docNo, certificateDate, jobReportTemplate } = certificate;
  // console.log("The client is:", certificate.client);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header certificate={certificate} />
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          {certificate.client?.displayName != null && (
            <ClientInfo client={certificate.client} />
          )}
          {console.log("Client is:", certificate.client.displayName)}
          <JobDetails certificate={certificate} />
        </View>
        {certificate?.includeChecklist && (
          <ChecklistAssesments
            certificate={certificate}
            collectionName={collectionName}
          />
        )}

        <ESignature
          certificate={certificate}
          includeChecklist={certificate?.includeChecklist}
        />
        <CoverPageFooter orgInfo={orgInfo} />
      </Page>
      {jobReportTemplate &&
        (jobReportTemplate?.items || jobReportTemplate)?.find(
          template => template.selectedValue === PLACE_REPORT_START
        ) && (
          <Page size="A4" style={styles.page}>
            <View style={styles.container}>
              <DynamicJobReportTemplateChecklist
                templates={
                  certificate?.jobReportTemplate?.items || jobReportTemplate
                }
                position={PLACE_REPORT_START}
              />
            </View>
            <Footer
              orgInfo={orgInfo}
              title={orgInfo?.name || ""}
              islogoRequired={true}
            />
          </Page>
        )}

      <Page size="A4" style={styles.page}>
        <View>
          {certificate?.participants && (
            <Participants
              participants={certificate?.participants}
              collectionName={collectionName}
            />
          )}
          {certificate?.rooms?.length && (
            <View style={{ marginTop: "50px" }}>
              <Text style={styles.title}>{"Floor & Area Details"}</Text>
              <FloorAndRoomDetails
                jobType={certificate.jobType}
                buildingClasses={certificate.buildingClasses}
                rooms={certificate.rooms}
              />
            </View>
          )}
        </View>
        <Footer
          orgInfo={orgInfo}
          title={"Floor & Area Details"}
          islogoRequired={false}
        />
      </Page>

      {certificate.includeChecklist ? (
        <Page size="A4" style={styles.page}>
          <CertificateTableCheckListRow
            certificate={certificate}
            collectionName={collectionName}
          />
          <Footer
            orgInfo={orgInfo}
            title={orgInfo?.name || ""}
            islogoRequired={true}
          />
        </Page>
      ) : null}
      {jobReportTemplate &&
        (jobReportTemplate?.items || jobReportTemplate)?.find(
          template => template.selectedValue === PLACE_REPORT_END
        ) && (
          <Page size="A4" style={styles.page}>
            <View style={styles.container}>
              <DynamicJobReportTemplateChecklist
                templates={
                  certificate?.jobReportTemplate?.items || jobReportTemplate
                }
                position={PLACE_REPORT_END}
              />
            </View>
            <Footer
              orgInfo={orgInfo}
              title={orgInfo?.name || ""}
              islogoRequired={true}
            />
          </Page>
        )}
    </Document>
  );
};
export default memo(Certificate);