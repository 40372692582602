import React, { useState } from "react";
import { updateUserProfile } from "../../services/index";
import { Row, Col, Card, Modal } from "reactstrap";
// Redux
import { useSelector } from "react-redux";
import pdfLogo from "./../../assets/images/pdflogo.png";
import { Link } from "react-router-dom";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import firebase from "config/firebase";

//Lightbox
import Lightbox from "react-18-image-lightbox";
import "react-image-lightbox/style.css";
import { Document, Page } from "react-pdf";

import { PDFReader, MobilePDFReader } from "react-read-pdf";
import { BrowserView, MobileView } from "react-device-detect";

export default function AttachmentCard({ file, qualifier }) {
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successDlg, setSuccessDlg] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [dynamicDescription, setDynamicDescription] = useState("");
  const { currentUserProfile } = useSelector(state => state.profile);
  const [isGallery, setGallery] = useState(false);
  const [isPdf, setPdf] = useState(false);

  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const nextPage = () => {
    let nextPageNumber;

    if (pageNumber + 1 > totalPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = pageNumber + 1;
    }
    setPageNumber(nextPageNumber);
  };

  const onDeletedAttachment = () => {
    currentUserProfile[qualifier] = currentUserProfile[qualifier].filter(
      f => file.id !== f.id
    );
    updateUserProfile(currentUserProfile);
    const storageRef = firebase.storage().ref();
    const deleteRef = storageRef.child(
      `${currentUserProfile.id}/documents/${file.id}`
    );
    deleteRef.delete();
  };

  return (
    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            onDeletedAttachment();
            setConfirmAlert(false);
            setSuccessDlg(true);
            setDynamicTitle("Deleted");
            setDynamicDescription("Your file has been deleted.");
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {successDlg ? (
        <SweetAlert
          success
          title={dynamicTitle}
          onConfirm={() => {
            setSuccessDlg(false);
          }}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}
      {isGallery && file.type !== "application/pdf" ? (
        <Lightbox
          mainSrc={file.url}
          onCloseRequest={() => {
            setGallery(false);
          }}
        />
      ) : null}
      {isPdf && file.type === "application/pdf" ? (
        <Modal isOpen={isPdf} size="xl">
          <div className="modal-header">
            <button
              type="button"
              onClick={() => setPdf(false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div style={{ overflow: "scroll"}} onClick={nextPage}>
              <Document
                file={file.url}
                onLoadSuccess={onDocumentLoadSuccess}
                noData={<h4>File Not Found</h4>}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <p>
                Page {pageNumber} of {totalPages}
              </p>
            </div>
          </div>
        </Modal>
      ) : null}
      <div className="p-2">
        <Row className="align-items-center">
          <Col className="col-auto">
            <i
              className="dripicons-cross"
              onClick={() => {
                setConfirmAlert(true);
              }}
            />{" "}
            <img
              data-dz-thumbnail=""
              height="80"
              onClick={() => {
                if (file.type === "application/pdf") {
                  setPdf(true);
                } else {
                  setGallery(true);
                }
              }}
              className="avatar-sm rounded bg-light"
              alt={file.name}
              src={file.type === "application/pdf" ? pdfLogo : file.url}
            />
          </Col>
          <Col>
            <Link to="#" className="text-muted font-weight-bold">
              {file.name}
            </Link>
            <p className="mb-0">
              <strong>{file.size}</strong>
            </p>
          </Col>
        </Row>
      </div>
    </Card>
  );
}
