import buildingLogo from "assets/images/icons8/floor-icon-40x40.png"
import floorPlanLogo from "assets/images/icons8/icons8-floor-plan-40.png";
import { FLOOR } from "common/constants";

export const getIcon = (type) => {
  switch (type.trim()) {
    // support floor icons for existing jobs
    // start of existing floor types
    // DO NOT USE below types for new floors, type for floors is "floor"
    case "groundfloor":
    case "first":
    case "second":
    case "third":
    case "fourth":
    case "fifth":
    case "basement":
    // end of existing floor types
    case FLOOR:
    case "other":
      return buildingLogo;
    // default is for all the areas
    default: return floorPlanLogo;
  }
};
