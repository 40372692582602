import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  CLIENT_NAME,
  JOB_ADDRESS,
  JOB_NUMBER,
  JOB_NAME,
  CUSTOM_JOB_NUMBER,
} from "common/constants";
import Loader from "components/Loader";
import { Col, Container, Row } from "reactstrap";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
  ErrorBoundary,
  SearchProvider,
  SearchBox,
  WithSearch,
} from "@elastic/react-search-ui";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import "../../searchuicomponents/customStyles.scss";
import "../../searchuicomponents/jobsCustomStyles.scss";
import { fetchInvitedJobsFromDb, fetchJobsFromDb } from "services";
import { CustomSearchView } from "../../searchuicomponents/CustomSearchView";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { getConfiguration } from "../../searchuicomponents/configuration";
import { getAllJobsFromDb } from "utils/getAllJobsFromDb";
import DashBoardListLayout from "./DashBoardListLayout";
import { useEffect } from "react";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { fetchJobs, fetchJobsError, fetchJobsSuccess } from "store/actions";
import organisationStatus from "utils/organisationStatus";
import { useHistory } from 'react-router-dom';
import { Search } from "components/jobs-ui/Search";

export default function JobsList() {
  const history = useHistory()
  const { loading } = useSelector(state => state.job);
  const { currentUserProfile } = useSelector(state => state.profile);
  const [searchType, setSearchType] = useState(
    sessionStorage.getItem("SearchType") || JOB_NUMBER
  );
  const [currentUrl,setCurrentUrl] = useState(history?.location?.pathname);
  const isOrganisationActive = organisationStatus(currentUserProfile);
  const dispatch = useDispatch();
  useEffect(() => {
    sessionStorage.setItem("SearchType", searchType);
  }, [searchType]);
  const connector = new AppSearchAPIConnector({
    searchKey: process.env.REACT_APP_ELASTIC_SEARCH_PRIVATE_KEY,
    engineName: process.env.REACT_APP_ELASTIC_SEARCH_ACTIVE_ENGINE,
    endpointBase: process.env.REACT_APP_ELASTIC_SEARCH_URL,
    cacheResponses: false,
  });

  useFirestoreCollection({
    query: () =>
      isOrganisationActive
        ? fetchJobsFromDb("jobs")
        : fetchInvitedJobsFromDb(
            "jobs",
            currentUserProfile?.orgAccessId || null
          ),
    onStart: () => dispatch(fetchJobs()),
    onSuccess: docs => dispatch(fetchJobsSuccess(docs)),
    onError: error => dispatch(fetchJobsError(error)),
    deps: [dispatch],
  });

  const jobNumbers = new Set();
  const clientNames = new Set();
  const jobAddresses = new Set();
  const jobNames = new Set();
  const customJobNumbers = new Set();
  const config = getConfiguration(
    connector,
    searchType,
    currentUserProfile,
    currentUrl
  );
  const getAllJobs = getAllJobsFromDb(
    jobNumbers,
    clientNames,
    jobAddresses,
    jobNames,
    customJobNumbers
  );
  getAllJobs();

  return (
    <React.Fragment>
      <Container fluid>
        {loading ? (
          <Loader />
        ) : (
          <Row>
            <Col lg="12">
              <div>
                <div>
                  <SearchProvider config={config}>
                    <WithSearch
                      mapContextToProps={({
                        results,
                        isLoading,
                        resultSearchTerm,
                        setSearchTerm,
                        shouldClearFilters,
                      }) => ({
                        results,
                        isLoading,
                        resultSearchTerm,
                        setSearchTerm,
                        shouldClearFilters,
                      })}
                    >
                      {({
                        results,
                        isLoading,
                        setSearchTerm,
                        resultSearchTerm,
                        shouldClearFilters,
                      }) => {
                        return (
                          <ErrorBoundary>
                            <div className="parent">
                              <div className="type-box">
                                <FormControl fullWidth>
                                  <InputLabel id="search-type-label">
                                    Search On
                                  </InputLabel>
                                  <Select
                                    labelId="search-type-label"
                                    id="search-type-select"
                                    value={searchType}
                                    label="Search On"
                                    onChange={event => {
                                      setSearchType(event.target.value);
                                      setSearchTerm("", {
                                        shouldClearFilters: false,
                                      });
                                    }}
                                    size="small"
                                  >
                                    <MenuItem value={JOB_NUMBER}>
                                      IB Number
                                    </MenuItem>
                                    <MenuItem value={CUSTOM_JOB_NUMBER}>
                                      Custom Number
                                    </MenuItem>
                                    <MenuItem value={CLIENT_NAME}>
                                      Client Name
                                    </MenuItem>
                                    <MenuItem value={JOB_ADDRESS}>
                                      Site Address
                                    </MenuItem>
                                    <MenuItem value={JOB_NAME}>
                                      Job Name
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              <div className="search-box">
                              <Search
                                searchType={searchType}
                                setSearchTerm={setSearchTerm}
                                shouldClearFilters={shouldClearFilters}
                                resultSearchTerm={resultSearchTerm}
                              />
                            </div>
                            </div>
                            <DashBoardListLayout
                              results={results}
                              isLoading={isLoading}
                              resultSearchTerm={resultSearchTerm}
                            />
                          </ErrorBoundary>
                        );
                      }}
                    </WithSearch>
                  </SearchProvider>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
}
