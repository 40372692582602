import TextField from "@mui/material/TextField";
import crossMark from "assets/images/icons8/icons8-gray-cross.png";
import dragReorder from "assets/images/icons8/icons8-scroll.png";
import {
  getItemStyle,
  getListContainerStyle,
  Reorder,
} from "common/checklistUtils/dndStyleUtil";
import cuid from "cuid";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CardBody, Col, Label, Row } from "reactstrap";
import { deleteChecklistAttachments } from "../utils";
import "./FloorContainer.scss";
import FloorUnit from "./UnitFloors";
import RoomUnit from "./UnitRooms";
import { deleteChecklists, getChecklistAttachments } from "services";
import { getIcon } from "common/data/rooms";
import {
  IS_FLOOR_CLONING_ENABLED,
} from "common/constants";
import { Add } from "@mui/icons-material";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function CommandContainer(props) {
  const [items, setItems] = useState([]);
  const [initialItems, setInitialItems] = useState([]);
  const [floorsOnly, setFloorsOnly] = useState([]);
  const [roomsOnly, setRoomsOnly] = useState([]);
  const [switchParent] = useState(true);
  const [roomsToDelete, setRoomsToDelete] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rooms, setRooms] = useState([]);
  const [isValidFloorAndAreas, setIsValidFloorAndAreas] = useState(false);
  const [showDeleteFloorsPopup, setShowDeleteFloorsPopup] = useState(false);

  const isValidSelection = items => {
    return items.reduce((isValid, floor) => {
      return isValid && floor.subItems.length ? true : false;
    }, true);
  };
  useEffect(() => {
    const isValidFloorAndAreas = isValidSelection(items);
    setIsValidFloorAndAreas(isValidFloorAndAreas);
  }, [items]);

  useEffect(() => {
    const rooms = _.cloneDeep(props.job[props.jobType]?.rooms);
    setRooms(rooms);
    if (rooms.length === 0) {
      setLoading(false);
    }
  }, [props.job[props.jobType]?.rooms]);

  useEffect(() => {
    if (rooms.length > 0 && props.floorLoading) {
      setLoading(true);
      setItems(rooms);
      setInitialItems(rooms);
      setLoading(false);
    }
  }, [rooms, props.floorLoading]);

  const onRemoveFloor = id => {
    let roomsToRemove = [];
    setItems([
      ...items.filter(floor => {
        if (floor.id === id) {
          floor?.subItems.forEach(room => {
            roomsToRemove = [...roomsToRemove, room.id];
          });
        }
        return floor.id !== id;
      }),
    ]);
    setRoomsToDelete([...roomsToDelete, ...roomsToRemove]);
    toast.success("Floor removed successfully", {
      autoClose: 2000,
    });
  };

  const onRemoveRoom = id => {
    setRoomsToDelete([...roomsToDelete, id]);
    setItems([
      ...items.map(item => {
        return {
          ...item,
          subItems: item.subItems.filter(subItem => {
            return subItem.id !== id;
          }),
        };
      }),
    ]);
    toast.success("Area removed successfully", {
      autoClose: 2000,
    });
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (result.type === "droppableItem") {
      // if source droppableid is droppable
      if (
        result.source.droppableId === result.destination.droppableId &&
        result.source.droppableId === "floorsonly"
      ) {
        const newFloors = Reorder(floorsOnly, sourceIndex, destIndex);
        setFloorsOnly(newFloors);
      } else if (result.source.droppableId === result.destination.droppableId) {
        const itemsNew = Reorder(items, sourceIndex, destIndex);
        setItems(itemsNew);
      } else {
        const newItem = {
          ...floorsOnly[sourceIndex],
          id: cuid(),
          subItems: [],
        };
        let newItems = [...items];
        newItems.splice(destIndex, 0, newItem);
        setItems(newItems);
      }
    } else if (result.type === "droppableSubItem") {
      if (
        result.source.droppableId === result.destination.droppableId &&
        result.source.droppableId === "roomsonly"
      ) {
        const newRooms = Reorder(roomsOnly, sourceIndex, destIndex);
        setRoomsOnly(newRooms);
      } else {
        const itemSubItemMap = items.reduce((acc, item) => {
          acc[item.id] = item.subItems;
          return acc;
        }, {});

        const sourceParentId = result.source.droppableId;
        const destParentId = result.destination.droppableId;

        const destSubItems = itemSubItemMap[destParentId];
        let newItems = [...items];

        if (result.source.droppableId === "roomsonly") {
          const newRoom = {
            ...roomsOnly[sourceIndex],
            id: cuid(),
          };
          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, newRoom);
          newItems = newItems.map(item => {
            if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setItems(newItems);
          return;
        }

        const sourceSubItems = itemSubItemMap[sourceParentId];

        /** In this case subItems are reOrdered inside same Parent */
        if (sourceParentId === destParentId) {
          const reorderedSubItems = Reorder(
            sourceSubItems,
            sourceIndex,
            destIndex
          );
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = reorderedSubItems;
            }
            return item;
          });
          setItems(newItems);
        } else {
          let newSourceSubItems = [...sourceSubItems];
          const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, draggedItem);
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = newSourceSubItems;
            } else if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setItems(newItems);
        }
      }
    }
  };

  const onChangeTextFloor = (text, id) => {
    const newItems = items.map(item => {
      if (item.id === id) {
        const newItem = { ...item, content: text.trim() };
        return newItem;
      }
      return item;
    });
    setItems(newItems);
  };

  const onChagneTextRoom = (text, id, parent) => {
    const newItems = items.map(item => {
      if (item.id === parent) {
        const newItem = {
          ...item,
          subItems: item.subItems.map(sub => {
            if (sub.id === id) {
              const newSub = { ...sub, content: text.trim() };
              return newSub;
            }
            return sub;
          }),
        };
        return newItem;
      }
      return item;
    });
    setItems(newItems);
  };

  const onDone = async () => {
    if (
      items?.length == 0 &&
      !showDeleteFloorsPopup &&
      roomsToDelete?.length > 0 &&
      initialItems?.length > 0
    ) {
      setShowDeleteFloorsPopup(true);
      return;
    }
    await floorsAndAreasDeletion();
  };
  const floorsAndAreasDeletion = async () => {
    try {
      if (roomsToDelete.length > 0) {
        const attachmenstToDelete = await getChecklistAttachments(
          props.job.id,
          props.collectionName,
          roomsToDelete
        );

        deleteChecklistAttachments(attachmenstToDelete).then(async () => {
          await deleteChecklists(
            props.job.id,
            props.collectionName,
            roomsToDelete
          );
        });
      }
      props.onSave(props.jobType, items);
      props.onDone();
    } catch (error) {
      console.log("on save error: ", error);
    }
  };

  const onAddFloorLevel = (items, setItems) => {
    const newFloorLevel = {
      content: "New Floor Level",
      id: cuid(),
      type: "floor",
      subItems: [],
    };
    setItems([...items, newFloorLevel]);
    toast.success("Floor Level added successfully", {
      autoClose: 2000,
    });
  };

  const onAddArea = (items, floorId, setItems) => {
    const newArea = {
      content: "New Area",
      id: cuid(),
      type: "area",
    };
    const newItems = items.map(item => {
      if (item.id === floorId) {
        const newSubItems = [...item.subItems, newArea];
        item.subItems = newSubItems;
      }
      return item;
    });
    setItems(newItems);
    toast.success("Area added successfully", {
      autoClose: 2000,
    });
  };
  const onDeleteConfirm = async () => {
    await floorsAndAreasDeletion();
    setShowDeleteFloorsPopup(false);
  };
  const onCancel = () => {
    setShowDeleteFloorsPopup(false);
    props.onDone();
  };
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return (
    <>
      {showDeleteFloorsPopup && (
        <SweetAlert
          title={"Are you sure you want to delete all floors and areas?"}
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={onDeleteConfirm}
          onCancel={onCancel}
        >
          <p>
            All checklists, photos, Notes, E-signatures will be lost, if any...
          </p>
        </SweetAlert>
      )}
      <Row>
        <DragDropContext onDragEnd={onDragEnd}>
          <Col lg="12" md="12" xs="auto">
            <Label>{"Floor and Area(s)"}</Label>
            <Label
              className="builder-actions__label add-floor-btn mt-2 mr-2"
              onClick={() => onAddFloorLevel(items, setItems)}
            >
              <Add fontSize="small" />
              <span className="text-with-icon">Add Floor Level</span>
            </Label>
            <Droppable droppableId="droppable" type="droppableItem">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={{
                    ...getListContainerStyle(snapshot.isDraggingOver),
                    minHeight: "590px",
                  }}
                  className="dropzone dropzone-floor-room mb-2"
                >
                  {loading ? (
                    <div className="dz-floor-message mt-2">
                      <CardBody align="center">
                        <div
                          className="spinner-border text-primary"
                          id="spinner"
                          role="status"
                        ></div>
                        <div>Loading...</div>
                      </CardBody>
                    </div>
                  ) : items.length <= 0 ? (
                    <div className="dz-message dz-floor-message mt-2 justify-content-center">
                      <h4>
                        Add Floors and Areas to configure your work area(s).
                      </h4>
                    </div>
                  ) : (
                    <div className="scrollable-floors-areas">
                      {items?.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={`${item.id}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div>
                              <div
                                className="overflow-hidden"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <FloorComponent
                                  item={item}
                                  onChangeTextFloor={onChangeTextFloor}
                                  provided={provided}
                                  onRemoveFloor={onRemoveFloor}
                                  items={items}
                                  index={index}
                                  onClone={setItems}
                                />

                                <RoomUnit
                                  subItems={item.subItems}
                                  type={item.id}
                                  onDelete={onRemoveRoom}
                                  onChange={onChagneTextRoom}
                                  allowed={
                                    switchParent ? "droppableSubItem" : item.id
                                  }
                                />
                                <div>
                                  <Label
                                    className="builder-actions__label float-sm-end mb-0"
                                    onClick={() =>
                                      onAddArea(items, item.id, setItems)
                                    }
                                  >
                                    <Add fontSize="small" />
                                    <span className="text-with-icon">
                                      Add Area
                                    </span>
                                  </Label>
                                </div>
                              </div>
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </Col>
        </DragDropContext>
      </Row>
      <Row>
        <Col></Col>
        <Col>
          <button
            type="button"
            color="primary"
            onClick={onDone}
            className="btn btn-primary w-md done-btn"
            data-dismiss="modal"
            disabled={!isValidFloorAndAreas}
          >
            Done
          </button>
        </Col>
      </Row>
    </>
  );
}
function FloorComponent({
  item,
  onChangeTextFloor,
  provided,
  onRemoveFloor,
  items,
  index,
  onClone
}
) {
  const [floorName, setFloorName] = useState(item.content);
  const [newFloorName, setNewFloorName] = useState(floorName);
  const [showCloneDialog, setShowCloneDialog] = useState(false);
  const [startingNumber, setStartingNumber] = useState("");
  const [cloneCount, setCloneCount] = useState("");
  const [cloneCountError, setCloneCountError] = useState(false);
  const [startingNumberError, setStartingNumberError] = useState(false);
  const [floorNameError, setFloorNameError] = useState(false);
  const [startCloning, setStartCloning] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (startCloning) {
      setTimeout(() => {
        addFloors();
      }, 2000);
    }
  }, [startCloning]);

  const addFloors = () => {
    if (cloneCount > 0) {
      const clonedFloors = [];
      for (let i = 1; i <= cloneCount; i++) {
        const clonedSubItems = item.subItems.map(s => {
          return {
            ...s,
            id: cuid(),
          };
        });
        const content = `${newFloorName} ${parseInt(startingNumber) + i - 1}`;
        clonedFloors.push({
          ...item,
          id: cuid(),
          subItems: clonedSubItems,
          content: content,
        });
      }
      onClone([
        ...items.slice(0, index + 1),
        ...clonedFloors,
        ...items.slice(index + 1),
      ]);
      handleCloneDialogClose();
      setNewFloorName(floorName);
    }
  };

  const handleStartingNumberChange = event => {
    const input = event.target.value;
    handleValueChange(input, setStartingNumber, setStartingNumberError);
  };

  const handleCloneCountChange = event => {
    const input = event.target.value;
    handleValueChange(input, setCloneCount, setCloneCountError);
  };
  const handleFloorNameChange = event => {
    const input = event.target.value;
    handleFloorNameValueChange(input, setNewFloorName, setFloorNameError);
  };

  const handleFloorNameValueChange = (value, setValue, setError) => {
    if (value?.length > 0) {
      setValue(value);
      setError(false);
    } else {
      setValue("");
      setError(true);
    }
  };

  const handleValueChange = (value, setValue, setError) => {
    if (value === "" || /^\d+$/.test(value)) {
      setValue(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleCloneDialogClose = () => {
    setShowCloneDialog(false);
    setStartingNumber("");
    setCloneCount("");
    setLoading(false);
    setStartCloning(false);
    setStartingNumberError(false);
    setFloorNameError(false);
    setCloneCountError(false);
  };

  return (
    <FloorUnit>
      <div className="display-unit">
        <span>
          <img
            onClick={() => {
              onRemoveFloor(item.id);
            }}
            src={crossMark}
            style={{
              height: "14px",
              padding: "2px",
              marginRight: "10px",
              cursor: "pointer",
            }}
          />
        </span>
        <span>
          <img
            src={getIcon(item.type)}
            style={{
              height: "25px",
              marginRight: "5px",
              marginTop: "5px",
              transform: "translate(0, -5px)",
            }}
          />
        </span>
        <TextField
          multiline={true}
          variant="standard"
          id="standard-textarea"
          className="floor-container-name"
          placeholder="Add floor name"
          error={Boolean(floorName)}
          helperText={floorName ? "" : "*Required"}
          InputProps={{ disableUnderline: true }}
          value={floorName}
          onChange={e => {
            setFloorName(e.target.value);
          }}
          onBlur={() => {
            if (floorName.trim().length > 0) {
              onChangeTextFloor(floorName.trim(), item.id);
            } else {
              setFloorName(item.content);
            }
          }}
        />
        {IS_FLOOR_CLONING_ENABLED && (
          <button
            disabled={!item.subItems.length > 0}
            className="btn btn-primary w-md mr-8 ml-8"
            onClick={() => {
              setShowCloneDialog(true);
              const clonedFloorName = floorName.replace(/\d+$/g, "");
              setNewFloorName(clonedFloorName);
            }}
            type="button"
          >
            Clone
          </button>
        )}
        <span {...provided.dragHandleProps}>
          <img
            src={dragReorder}
            style={{
              height: "26px",
              width: "10px",
              marginTop: "2px",
              transform: "translate(0, -5px)",
            }}
          />
        </span>
      </div>
      {showCloneDialog && (
        <div>
          <Dialog open={showCloneDialog} onClose={handleCloneDialogClose}>
            <DialogTitle>Clone Floor Level</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To clone the floor level, please enter the number of floors you
                want to clone and the starting number of the floor/apartment.
              </DialogContentText>
              <TextField
                autoFocus
                label="How many Floors/Apartments do you want to add?"
                value={cloneCount}
                onChange={handleCloneCountChange}
                error={Boolean(cloneCountError)}
                margin="normal"
                helperText={cloneCountError ? "Enter a valid number" : ""}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                  endAdornment: (
                    <span
                      style={{ visibility: cloneCount ? "visible" : "hidden" }}
                    >
                      <IconButton
                        onClick={() => setCloneCount("")}
                        onMouseDown={event => event.preventDefault()}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </span>
                  ),
                }}
                fullWidth
              />

              <TextField
                label="Enter the floor name"
                value={newFloorName}
                onChange={handleFloorNameChange}
                error={Boolean(floorNameError)}
                margin="normal"
                helperText={floorNameError ? "Enter the floor Name" : ""}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                  endAdornment: (
                    <span
                      style={{
                        visibility: newFloorName ? "visible" : "hidden",
                      }}
                    >
                      <IconButton
                        onClick={() => setNewFloorName("")}
                        onMouseDown={event => event.preventDefault()}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </span>
                  ),
                }}
                fullWidth
              />
              <TextField
                label="Enter the starting Floor/Apartment number"
                value={startingNumber}
                onChange={handleStartingNumberChange}
                error={Boolean(startingNumberError)}
                margin="normal"
                helperText={startingNumberError ? "Enter a valid number" : ""}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                  endAdornment: (
                    <span
                      style={{
                        visibility: startingNumber ? "visible" : "hidden",
                      }}
                    >
                      <IconButton
                        onClick={() => setStartingNumber("")}
                        onMouseDown={event => event.preventDefault()}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </span>
                  ),
                }}
                fullWidth
              />
            </DialogContent>
            {loading && (
              <Alert color="primary" className="mb-2">
                <h5 className="alert-heading mb-2">
                  Adding Floors/Apartments is in progress, Please wait..
                </h5>
              </Alert>
            )}
            <DialogActions className="mb-3 px-4">
              <button
                type="button"
                disabled={loading}
                onClick={() => setShowCloneDialog(false)}
                className="btn btn-light w-md float-left"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                color="primary"
                onClick={() => {
                  setStartCloning(true);
                  setLoading(true);
                }}
                className="btn btn-primary w-md float-left"
                data-dismiss="modal"
                disabled={
                  loading ||
                  cloneCountError ||
                  startingNumberError ||
                  floorNameError ||
                  !(
                    cloneCount > 0 &&
                    startingNumber > 0 &&
                    newFloorName?.length > 0
                  )
                }
              >
                Clone
              </button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </FloorUnit>
  );
}
