import React from "react";
import { Link } from "react-router-dom";
import { Td, Tr } from "react-super-responsive-table";

export function DisplayOrgs({ orgs, onOrgEdit }) {
  return orgs?.map((organisation, index) => (
    <Tr key={index}>
      <Td>{organisation.id}</Td>
      <Td>{organisation.name}</Td>
      <Td>{organisation.abn}</Td>
      <Td>{organisation.phone}</Td>
      <Td>{organisation.address}</Td>
      <Td  style={ organisation?.status === "Active" ? { color: "green", fontWeight: "bold", textTransform: "uppercase" } : { color: "red",fontWeight: "bold", textTransform: "uppercase" }} >
   {organisation.status}</Td>

      <Td>
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => onOrgEdit(organisation)}
            ></i>
          </Link>
        </div>
      </Td>
    </Tr>
  ));
}
