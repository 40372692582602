import React from 'react';
import './grid.css';

export function Grid({size}) {
  return (
    <div
      className={"Grid"}
      style={
        {
          '--grid-size': `${size}px`,
        }
      }
    />
  );
}
