import {
  FETCH_ORG_USERS,
  FETCH_ORG_USERS_ERROR,
  FETCH_ORG_USERS_SUCCESS,
} from "./actionTypes";

export function fetchOrgUsers() {
  return {
    type: FETCH_ORG_USERS,
  };
}

export function fetchOrgUsersSuccess(orgs) {
  return {
    type: FETCH_ORG_USERS_SUCCESS,
    payload: orgs,
  };
}

export function fetchOrgUsersError(error) {
  return {
    type: FETCH_ORG_USERS_ERROR,
    payload: error,
  };
}
