import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import firebase from "../../config/firebase";
import { fetchDraftsScuccess } from 'store/actions';
const db = firebase.firestore();
function usePaginationation() {

    const dispatch = useDispatch();
    const [lastDoc, setlastDoc] = useState('')
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false)
    let pageSize= 10;

    const runPagination = (e) => {
        let { name } = e.target;
        setLoading(true)
        if (name === "next") {
            const user = firebase.auth().currentUser;
            let jobsRef = db.collection('drafts').orderBy("date", "desc");
            jobsRef.where("userId", "==", user?.uid).startAfter(lastDoc)
                .limit(pageSize)
                .get().then((collections) => {
                    const data = collections.docs.map((item) => item.data());
                    dispatch(fetchDraftsScuccess(data))
                    if (data.length > 0) {
                        const lastDoc_ = collections.docs[collections.docs.length - 1];
                        if (lastDoc != "") {
                            setPage(page + 1); 
                        }
                        setLoading(false)
                        setlastDoc(lastDoc_);
                        // setlastDocForPrevious(collections.docs[0]);
                    }
                });
        } else {
            const user = firebase.auth().currentUser;
            let jobsRef = db.collection('drafts').orderBy("date", "desc");
            jobsRef.where("userId", "==", user?.uid).endBefore(lastDoc)
                .limit(pageSize)
                .get().then((collections) => {
                    const data = collections.docs.map((item) => item.data());
                    dispatch(fetchDraftsScuccess(data))
                    setLoading(false)
                    if (data.length > 0) {
                        const lastDoc_ = collections.docs[collections.docs.length - 1];
                        setPage(1); 
                        setlastDoc(lastDoc_);

                        // setlastDocForPrevious(collections.docs[0]);
                    } else {
                        alert("No more next data");
                    }
                });
        }
    }
    useEffect(() => {
        const user = firebase.auth().currentUser;
        let jobsRef = db.collection('drafts').orderBy("date", "desc");
        db.collection('drafts').orderBy("date", "desc");
        jobsRef.where("userId", "==", user?.uid)
            .get()
            .then((querySnapshot) => {
                const count = querySnapshot.size;
                setTotalPage(Math.ceil(count / 10))
            })
            .catch((error) => {
                console.error("Error getting documents:", error);
            });

        runPagination({ target: { name: "next" } })
    }, [])
    return (
        { page, runPagination, totalPage, loading }
    )
}

export default usePaginationation

