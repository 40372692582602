import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Col,
  Container,
  Card,
  CardBody,
  PaginationLink,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import Cards
import CardJob from "./CardJob";
import PrevNextUI from "searchuicomponents/PrevNextUI";

const JobGrid = (props) => {
  const { loading, drafts } = useSelector((state) => state.job);

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          {loading ? (
            <Col xl="4" lg="6" md="6" sm="12">
              <Card>
                <CardBody align="center">
                  <div
                    className="spinner-border text-primary"
                    id="spinner"
                    role="status"
                  ></div>
                  <div>Loading...</div>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <CardJob jobs={drafts} collectionName={props.collectionName} />
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default React.memo(JobGrid);

