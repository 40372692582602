import React, { memo } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

export const CheckListOverWriteDialog = memo(({ setShowOverrideAlert }) => {
  return (
    <>
      <SweetAlert
        title="Checklist Name already exists"
        warning
        confirmButtonText="OK"
        confirmBtnBsStyle="success"
        onConfirm={() => {
          setShowOverrideAlert(false);
        } }
      >
        Please change the Checklist Name and try again.
      </SweetAlert>
    </>
  );
});

