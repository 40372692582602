import {
  APPROVED,
  ORGANISATION_OWNER,
  PLATFORM_OWNER,
  WEB_USER,
} from "common/constants";
import OrgUsers from "pages/orgUsersManagment/OrgUsers";
import Subscriptions from "pages/platformManagement/subscription/Subscriptions";
import { useSelector } from "react-redux";
import ForgetPwd from "../pages/authentication/ForgetPassword";
// Authentication related pages
import Login from "../pages/authentication/Login";
import Register from "../pages/authentication/Register";
// Profile
import Checklists from "../pages/checklistLibrary/Checklists";
import Clients from "../pages/clients/Clients";
import CompanyProfile from "../pages/company/Profile";
import Dashboard from "../pages/dashboard/home";
import Drafts from "../pages/jobs/draft/drafts";
import InspectionDrafts from "../pages/jobs/draft/inspectionDrafts";
import OrgManagement from "../pages/platformManagement/org-creation/OrgManagement";
import ChangePassword from "../pages/settings/ChangePassword";
import UserOnboadingForOrg from "../pages/UserOnboadingForOrg/UserOnboadingForOrg";
import UserProfile from "../pages/userProfile/UserProfile";
import EmailVerification from "pages/authentication/EmailVerification";
import organisationStatus from "utils/organisationStatus";
import { ActiveJobs } from "pages/jobs/jobTypes/active/ActiveJobs";
import { InspectionActiveJobs } from "pages/jobs/jobTypes/active/InspectionActiveJobs";
import ActiveJobsOverview from "pages/jobs/jobDetails/ActiveJobsOverview";
import { InspectionJobsOverview } from "pages/jobs/jobDetails/InspectionJobsOverview";
import { ArchivedJobs } from "pages/jobs/jobTypes/archived/ArchivedJobs";
import { InspectionArchivedJobs } from "pages/jobs/jobTypes/archived/InspectionArchivedJobs";
import { DeletedJobs } from "pages/jobs/jobTypes/deleted/DeletedJobs";
import { InspectionDeletedJobs } from "pages/jobs/jobTypes/deleted/InspectionDeletedJobs";
import ActiveJobsRoomsOverview from "pages/jobs/jobDetails/rooms/ActiveJobsRoomsOverview";
import InspectionJobsRoomsOverview from "pages/jobs/jobDetails/rooms/InspectionJobsRoomsOverview";
import DocumentLists from "pages/documentLibrary/DocumentLists";
import CreateJob from "pages/jobs/JobCreate";
import CreateInspectionJob from "pages/jobs/InspectionJobCreate";
const defaultRoutes = [
  // //profile
  { path: "/dashboard", component: Dashboard, exact: true },
  { path: "/profile", component: UserProfile, exact: true },
  { path: "/active/:jobid/:roomid", component:ActiveJobsRoomsOverview, exact: true },
  { path: "/active/:id", component: ActiveJobsOverview, exact: true },
  { path: "/active", component: ActiveJobs, exact: true },
  { path: "/archived", component: ArchivedJobs, exact: true },
  { path: "/deleted", component: DeletedJobs, exact: true },
  {
    path: "/inspectionactive/:jobid/:roomid",
    component:InspectionJobsRoomsOverview,
    exact: true,
  },
  { path: "/inspectionactive/:id", component: InspectionJobsOverview, exact: true },
  { path: "/inspectionactive", component: InspectionActiveJobs, exact: true },
  { path: "/inspectionarchived", component: InspectionArchivedJobs, exact: true },
  { path: "/inspectiondeleted", component: InspectionDeletedJobs, exact: true },
  { path: "/org-onboarding", component: UserOnboadingForOrg, exact: true },
  { path: "/settings", component: ChangePassword, exact: true },
];

const platformOwnerRoutes = [
  { path: "/dashboard", component: Dashboard, exact: true },
  { path: "/org-management", component: OrgManagement, exact: true },
  { path: "/subscriptions", component: Subscriptions, exact: true },
  { path: "/profile", component: UserProfile, exact: true },
  { path: "/settings", component: ChangePassword, exact: true },
  { path: "/*", component: Login },
];

const webUserRoutes = [
  // //profile
  { path: "/dashboard", component: Dashboard, exact: true },
  { path: "/profile", component: UserProfile, exact: true },
  { path: "/newjob/:id", component: CreateJob, exact: true },
  { path: "/newjob", component: CreateJob, exact: true },
  { path: "/draft", component: Drafts, exact: true },
  { path: "/newinspectionjob/:id", component: CreateInspectionJob, exact: true },
  { path: "/newinspectionjob", component: CreateInspectionJob, exact: true },
  { path: "/inspectiondraft", component: InspectionDrafts, exact: true },
  { path: "/active/:jobid/:roomid", component: ActiveJobsRoomsOverview, exact: true },
  { path: "/active/:id", component: ActiveJobsOverview, exact: true },
  { path: "/active", component: ActiveJobs, exact: true },
  { path: "/archived", component: ArchivedJobs, exact: true },
  { path: "/deleted", component: DeletedJobs, exact: true },
  {
    path: "/inspectionactive/:jobid/:roomid",
    component:InspectionJobsRoomsOverview,
    exact: true,
  },
  { path: "/inspectionactive/:id", component: InspectionJobsOverview, exact: true },
  { path: "/inspectionactive", component: InspectionActiveJobs, exact: true },
  { path: "/inspectionarchived", component: InspectionArchivedJobs, exact: true },
  { path: "/inspectiondeleted", component: InspectionDeletedJobs, exact: true },
  { path: "/pricing", component: UserOnboadingForOrg, exact: true },
  { path: "/org-onboarding", component: UserOnboadingForOrg, exact: true },
  { path: "/clients", component: Clients, exact: true },
  { path: "/checklist", component: Checklists, exact: true },
  { path: "/documentList", component: DocumentLists, exact: true },
  { path: "/settings", component: ChangePassword, exact: true },
  { path: "/*", component: Login },
];
const orgOWnerRoutes = [
  // //profile
  { path: "/dashboard", component: Dashboard, exact: true },
  { path: "/profile", component: UserProfile, exact: true },
  { path: "/newjob/:id", component: CreateJob, exact: true },
  { path: "/newjob", component: CreateJob, exact: true },
  { path: "/draft", component: Drafts, exact: true },
  { path: "/newinspectionjob/:id", component: CreateInspectionJob, exact: true },
  { path: "/newinspectionjob", component: CreateInspectionJob, exact: true },
  { path: "/inspectiondraft", component: InspectionDrafts, exact: true },
  { path: "/active/:jobid/:roomid", component:ActiveJobsRoomsOverview, exact: true },
  { path: "/active/:id", component: ActiveJobsOverview, exact: true },
  { path: "/active", component: ActiveJobs, exact: true },
  { path: "/archived", component: ArchivedJobs, exact: true },
  { path: "/deleted", component: DeletedJobs, exact: true },
  {
    path: "/inspectionactive/:jobid/:roomid",
    component:InspectionJobsRoomsOverview,
    exact: true,
  },
  { path: "/inspectionactive/:id", component: InspectionJobsOverview, exact: true },
  { path: "/inspectionactive", component: InspectionActiveJobs, exact: true },
  { path: "/inspectionarchived", component: InspectionArchivedJobs, exact: true },
  { path: "/inspectiondeleted", component: InspectionDeletedJobs, exact: true },
  { path: "/pricing", component: UserOnboadingForOrg, exact: true },
  { path: "/company-profile", component: CompanyProfile, exact: true },
  { path: "/clients", component: Clients, exact: true },
  { path: "/checklist", component: Checklists, exact: true },
  { path: "/documentList", component: DocumentLists, exact: true },
  { path: "/settings", component: ChangePassword, exact: true },
  { path: "/org-users-management", component: OrgUsers, exact: true },
  { path: "/*", component: Login },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register/:id", component: Register },
  { path: "/register", component: Register },
  { path: "/", component: Login, exact: true },
  { path: "/email-verification", component: EmailVerification, exact: true },
];

const isValidOrgUser = profile => {
  return (
    profile &&
    profile.orgAccessId !== undefined &&
    profile.orgStatus === APPROVED
  );
};

const useCustomRouteFinder = () => {
  const { currentUserProfile } = useSelector(state => state.profile);
  const isOrganisationActive = organisationStatus(currentUserProfile);
  const getUserRoutes = () => {
    switch (currentUserProfile?.type) {
      case PLATFORM_OWNER:
        return platformOwnerRoutes;
      case ORGANISATION_OWNER:
        if (isOrganisationActive) {
          return orgOWnerRoutes;
        }
        return defaultRoutes;
      case WEB_USER:
        if (isOrganisationActive) {
          return webUserRoutes;
        }
        return defaultRoutes;
      default:
        // orgStatus === PENDING, will be assigned to defaultRoutes
        return defaultRoutes;
    }
  };

  return getUserRoutes;
};

export { useCustomRouteFinder, isValidOrgUser, authRoutes };
