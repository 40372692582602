import { Clear } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const ClearSearchBox = ({ setSearchTerm }, shouldClearFilters) => {
  return (
    <div className="sui-search-box__close">
      <IconButton
        onClick={() => setSearchTerm("", { shouldClearFilters: false })}
      >
        <Clear />
      </IconButton>
    </div>
  );
};
