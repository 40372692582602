import React from "react";
import classNames from "classnames";

const styles = {
  Wrapper: {
    display: "flex",
    width: "100%",
    boxSizing: "border-box",
    padding: "20px",
    justifyContent: "flex-start",
  },
  center: { justifyContent: "center" },
};

export function Wrapper({ children, center, style }) {
  return (
    <div
      className={classNames(styles.Wrapper, center && styles.center)}
      style={style}
    >
      {children}
    </div>
  );
}
