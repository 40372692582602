import { dataFromSnapshot, fetchJobsFromDb } from "services";
import { store } from "store/configureStore";
import {
  CREATE_JOB,
  FETCH_ARCHIVED_SUCCESS,
  FETCH_DELETED_SUCCESS,
  FETCH_DRAFT_FAILURE,
  FETCH_DRAFT_SUCCESS,
  FETCH_JOBS,
  FETCH_JOBS_FAILURE,
  FETCH_JOBS_SUCCESS,
  FETCH_SINGLE_DRAFT_SUCCESS,
  IS_CLONING,
} from "./actionTypes";

export function fetchJobs() {
  return {
    type: FETCH_JOBS,
  };
}

export function retriveJobs() {
  fetchJobsFromDb("jobs").onSnapshot(
    snapshot => {
      const jobs = snapshot.docs.map(doc => dataFromSnapshot(doc));
      store.dispatch(fetchJobsSuccess(jobs));
    },
    error => store.dispatch(fetchJobsError(error))
  );
}

export function fetchJobsSuccess(jobs) {
  return {
    type: FETCH_JOBS_SUCCESS,
    payload: jobs,
  };
}

export function fetchArchivedSuccess(jobs) {
  return {
    type: FETCH_ARCHIVED_SUCCESS,
    payload: jobs,
  };
}

export function fetchDeletedSuccess(jobs) {
  return {
    type: FETCH_DELETED_SUCCESS,
    payload: jobs,
  };
}

export function fetchJobsError(error) {
  console.log(error);
  return {
    type: FETCH_JOBS_FAILURE,
    payload: error,
  };
}

export function createJob(job) {
  return {
    type: CREATE_JOB,
    payload: job,
  };
}

export function fetchDraftsError(error) {
  return {
    type: FETCH_DRAFT_FAILURE,
    payload: error,
  };
}

export function fetchDraftsScuccess(jobs) {
  return {
    type: FETCH_DRAFT_SUCCESS,
    payload: jobs,
  };
}

export function fetchSingleDraftSuccess(job) {
  return {
    type: FETCH_SINGLE_DRAFT_SUCCESS,
    payload: job,
  };
}

export function setIsCloning(isCloning) {
  return {
    type: IS_CLONING,
    payload: isCloning,
  };
}
