import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector/lib/esm/AppSearchAPIConnector";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchJobsFromDbofSameOrganisation } from "services";
import { fetchJobs, fetchJobsError, fetchJobsSuccess } from "store/actions";
import organisationStatus from "utils/organisationStatus";
import { JOB_NUMBER } from "common/constants";
import Dashboard from "components/jobs-ui/DashBoardUi";
import { getConfiguration } from "searchuicomponents/configurationOfSameOrgJobs";
export function LinkActiveJobs() {
  const { currentUserProfile } = useSelector(state => state.profile);
  const [searchType, setSearchType] = useState(
    sessionStorage.getItem("ActiveSearchType") || JOB_NUMBER
  );
  const [jobupdate, setJobUpdate] = useState("");
  const dispatch = useDispatch();
  const isOrganisationActive = organisationStatus(currentUserProfile);
  useFirestoreCollection({
    query: () =>
      isOrganisationActive &&
      fetchJobsFromDbofSameOrganisation(
        "jobs",
        currentUserProfile?.orgAccessId || null
      ),
    onStart: () => dispatch(fetchJobs()),
    onSuccess: docs => dispatch(fetchJobsSuccess(docs)),
    onError: error => dispatch(fetchJobsError(error)),
    deps: [dispatch],
  });
  const connector = new AppSearchAPIConnector({
    searchKey: process.env.REACT_APP_ELASTIC_SEARCH_PRIVATE_KEY,
    engineName: process.env.REACT_APP_ELASTIC_SEARCH_ACTIVE_ENGINE,
    endpointBase: process.env.REACT_APP_ELASTIC_SEARCH_URL,
    cacheResponses: false,
  });
  const config = getConfiguration(connector, searchType, currentUserProfile);
  useEffect(() => {
    sessionStorage.setItem("ActiveSearchType", searchType);
  }, [searchType]);
  return (
    <>
      <Dashboard
        config={config}
        searchType={searchType}
        setSearchType={setSearchType}
        pathname={"newjob"}
        setJobUpdate={setJobUpdate}
        isLinkEnabled={false}
        collectionName={"jobs"}
      />
    </>
  );
}
