import { Alert, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const DeleteConfirmationPromptWithCheckBox = ({
  onConfirm,
  onCancel,
  label,
}) => {
  const [checked, setChecked] = useState(false);
  const [inputError, setInputError] = useState(false);

  const onDeleteConfirm = () => {
    if (checked === false) {
      setInputError(true);
      return;
    }
    onConfirm();
  };

  useEffect(() => {
    if (checked === true) {
      setInputError(false);
    }
  }, [checked]);

  return (
    <SweetAlert
      title={"Are you sure you want to delete?"}
      warning
      showCancel
      confirmButtonText="Ok"
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      onConfirm={onDeleteConfirm}
      onCancel={onCancel}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={e => {
              setChecked(e.target.checked);
            }}
            size="small"
            style={{
              marginBottom: "24px",
              marginLeft: "20px",
              marginRight: "-10px",
              float: "left",
              height: "20px",
              color: "#65a2eb",
            }}
          />
        }
        label={label}
      />
      {inputError && (
        <Alert severity="error" style={{ padding: "0px 5px", margin: "5px" }}>
          Please Tick the checkbox to confirm.
        </Alert>
      )}
    </SweetAlert>
  );
};

export default DeleteConfirmationPromptWithCheckBox;
