import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getOrgFromDb } from "services";
import PreviewPdfModal from "../../rooms/PreviewPdfModal";
import JobReportGeneration from "./JobReportGeneration";
import CertificateGenerationErrorModal from "./CertificateGenerationErrorModal";
import "./Certificate.scss";
import firebase from "config/firebase";
import "./../../../jobCreation/checklist/CheckListPage.scss";
import { jobReportGeneration } from "./jobReportGeneration.jsx";
import { documentUploadToFirebase } from "./documentUploadToFirebase";
import { pdfGenerationAndUploadingToFirebase } from "./documentUploadToFirebase";
const CerficateGeneration = ({
  showCertModal,
  setShowCertModal,
  job,
  collectionName,
  certificateDetails,
}) => {
  const { currentUserProfile } = useSelector(state => state.profile);
  const [progress, setProgress] = useState(0);
  const [certificate, setCertificate] = useState(null);
  const [certificateDescription, setCertificateDescription] = useState(null);
  const [loading, setLoading] = useState(false);
  const storageRef = firebase.storage().ref();
  const db = firebase.firestore();
  const getOrgInfo = async () => {
    const snapshot = await getOrgFromDb(job.orgId);
    return snapshot.data();
  };

  const genaratePDFAndUploadToFirebase = pdfGenerationAndUploadingToFirebase(
    setProgress,
    collectionName,
    storageRef
  );

  const uploadDocumentToFirebase = documentUploadToFirebase(
    db,
    setProgress,
    genaratePDFAndUploadToFirebase
  );
  const uploadPdfToFireBase = async pdfUrl => {
    await uploadDocumentToFirebase(
      job,
      certificate,
      certificateDescription,
      collectionName,
      setCertificate,
      pdfUrl
    );
  };
  const onJobReportGenerate = jobReportGeneration(
    setProgress,
    certificateDetails,
    job,
    getOrgInfo,
    currentUserProfile,
    collectionName,
    setCertificate,
    setCertificateDescription,
  );
  const getJobtype = () => {
    const { jobCategory } = certificateDetails;
    return jobCategory ? jobCategory.toLowerCase() : jobCategory;
  };

  return (
    <>
      {certificate && (
        <PreviewPdfModal
          certificate={certificate}
          collectionName={collectionName}
          setShowCertModal={setShowCertModal}
          uploadDocument={uploadPdfToFireBase}
          progress={progress}
          setProgress={setProgress}
          setCertificate={setCertificate}
          setLoading={setLoading}
        />
      )}
      <>
        {getJobtype() && (
          <>
            {job[getJobtype()]?.rooms.length > 0 ? (
              <JobReportGeneration
                documentName={certificateDetails?.documentName}
                showCertModal={showCertModal}
                setShowCertModal={setShowCertModal}
                onGenerate={(e)=>{onJobReportGenerate(e);setProgress(0)}}
                progress={progress}
                job={job}
                jobType={getJobtype()}
                collectionName={collectionName}
                loading={loading}
                setLoading={setLoading}
                floor={certificateDetails?.selectedFloor}
                areas={certificateDetails?.selectedAreas}
              />
            ) : (
              <CertificateGenerationErrorModal
                showErrorModal={showCertModal}
                onClose={() => {
                  setShowCertModal(false);
                }}
              />
            )}
          </>
        )}
      </>
    </>
  );
};
export default CerficateGeneration;
