import React from "react";
import { Col, Container, Card, CardBody, Row } from "reactstrap";
import NotFound from "components/NotFound";
import { useSelector } from "react-redux";
import CardView from "./CardView";
import InspectionCardView from "pages/jobs/jobCreation/jobLinkage/InspectionCardView";
import { notFoundMessage } from "services";

const GridLayout = ({
  results,
  isLoading,
  setSearchTerm,
  resultSearchTerm,
  pathname,
  setJobUpdate,
  isLinkEnabled,
  collectionName,
  isNewJob,
}) => {
  const { jobs } = useSelector(state => state.job);
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          {results.length > 0 ? (
            results.map(result => {
              const job = jobs.find(job => job.id === result.id.raw);
              if (isNewJob)
                return (
                  job && (
                    <InspectionCardView
                      result={result}
                      job={job}
                      isLoading={isLoading}
                      setSearchTerm={setSearchTerm}
                      resultSearchTerm={resultSearchTerm}
                      pathname={pathname}
                      setJobUpdate={setJobUpdate}
                      isLinkEnabled={isLinkEnabled}
                      collectionName={collectionName}
                      key={result.id.raw}
                    />
                  )
                );
              return (
                job && (
                  <CardView
                    result={result}
                    job={job}
                    isLoading={isLoading}
                    setSearchTerm={setSearchTerm}
                    resultSearchTerm={resultSearchTerm}
                    pathname={pathname}
                    setJobUpdate={setJobUpdate}
                    isLinkEnabled={isLinkEnabled}
                    collectionName={collectionName}
                    key={result.id.raw}
                  />
                )
              );
            })
          ) : isLoading ? (
            <Col xl="4" lg="6" md="6" sm="12">
              <Card>
                <CardBody align="center">
                  {" "}
                  <div
                    className="spinner-border text-primary"
                    id="spinner"
                    role="status"
                  ></div>
                  <div>Loading...</div>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <NotFound
              message={
               notFoundMessage(pathname)
              }
            />
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default GridLayout;
