import classnames from "classnames";
import { useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CheckListPage from "../jobs/jobCreation/checklist/ChecklistsPage";

export default function Dashboard({ props }) {
  const [activeTab, setactiveTab] = useState("1");

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Checklists | IB</title>
        </MetaTags>
        <Container fluid>
          <>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Create New Checklist
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="p-3 text-muted">
              <TabPane tabId="1">
                <CheckListPage />
              </TabPane>
            </TabContent>
          </>
        </Container>
      </div>
    </>
  );
}
