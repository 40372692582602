import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const FloorSelection = ({ job, jobType, selectedFloor, floorHandler }) => {
  return job[jobType]?.rooms ? (
    <FormControl
      sx={{
        m: 1,
        minWidth: 150,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <InputLabel id="search-type-label" style={{ textAlign: "center" }}>
        {"Floor"}
      </InputLabel>
      <Select
        labelId="search-type-label"
        id="search-type-select"
        value={selectedFloor || ""}
        label={"Floor"}
        onChange={e => {
          floorHandler(JSON.stringify(e.target.value));
        }}
        size="small"
        renderValue={selected => (selected && selected.content) || ""}
      >
        {job[jobType]?.rooms.map(room => (
          <MenuItem key={room?.id} value={room}>
            {room?.content}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <p>No Floors Exists</p>
  );
};
export default FloorSelection;