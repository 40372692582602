import cuid from "cuid";

const TypeEnum = {
  SECTION: "Section",
  CHECKLIST_ITEM: "Checklist Item",
  HOLD_POINT: "HoldPoint",
};
const RequiredEnum = {
  YES: "Yes",
  NO: "No",
};
const content = 0;
const type = 1;
const required = 2;
const checkForValidItem = (mainItem, rowData, itemType) => {
  return (
    mainItem &&
    rowData[type] === itemType &&
    rowData[content] &&
    rowData[required]
  );
};
const CheckListParser = resp => {
  let mainItem = null;
  let mainList = [];
  let data = resp.rows;
  let blankRowsCount = 0;
  let i = 1;

  const addToMainlist = () => {
    if (mainItem) {
      mainList.push(mainItem);
      mainItem = null;
    }
  };

  while (i < data.length && blankRowsCount < 5) {
    const rowData = data[i];
    if (!rowData || rowData.length == 0) {
      blankRowsCount++;
      i++;
      continue;
    }

    if (rowData[type] === TypeEnum.SECTION && rowData[content]) {
      addToMainlist();
      mainItem = {
        id: cuid(),
        subItems: [],
        content: rowData[content],
        type: "list",
      };
    } else if (checkForValidItem(mainItem, rowData, TypeEnum.CHECKLIST_ITEM)) {
      mainItem.subItems.push({
        id: cuid(),
        attachment: rowData[required] === RequiredEnum.YES,
        attachments: [],
        signature: false,
        holdPoint: false,
        content: rowData[content],
        isValidItem: rowData[content] ? true : false,
        checkBox: true,
        type: "checkbox",
      });
    } else if (checkForValidItem(mainItem, rowData, TypeEnum.HOLD_POINT)) {
      mainItem.subItems.push({
        id: cuid(),
        attachment: false,
        attachments: [],
        signature: rowData[required] === RequiredEnum.YES,
        holdPoint: true,
        content: rowData[content],
        isValidItem: rowData[content] ? true : false,
        checkBox: false,
        uploaded: false,
        type: "digitalsign",
      });
    }
    i++;
  }
  addToMainlist();

  return mainList;
};
export default CheckListParser;
