import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";

// Redux
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Field, Form, Checkbox, CheckboxGroup } from "@availity/form";
import "@availity/yup";
import * as yup from "yup";

// actions
import { signInWithEmail } from "../../services/authService";

// import images
//import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo-color.png";
import profile from "assets/images/profile-img.png";
import { InputAdornment } from "@mui/material";
import { USER_DO_NOT_HAVE_ANY_ACTIVE_LICENSE } from "common/constants";

//Import config

export default function Login({ history }) {
  const { loginError, authenticated, loading } = useSelector(
    state => state.auth
  );
  // handleValidSubmit
  const handleValidSubmit = values => {
    signInWithEmail(values);
  };

  useEffect(() => {
    if (authenticated) {
      history.push("/dashboard");
    }
  }, [authenticated]);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordIcon, setShowPasswordIcon] = useState(true);

  const handleClickShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };


  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Industry Best Digital</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          The best digital tradesman tool of all-time!
                        </h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-white">
                      <div className="avatar-xl profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-white">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="104"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={values => {
                        handleValidSubmit(values);
                      }}
                      initialValues={{
                        email: "",
                        password: "",
                        rememberMe: [],
                      }}
                      validationSchema={yup.object().shape({
                        email: yup
                          .string().trim()
                          .isRequired(true, "Please enter valid email."),
                        password: yup
                          .string().trim()
                          .isRequired(true, "Please enter password."),
                      })}
                      validate = {(values)=>{
                        if(values?.password === ""){
                          setShowPasswordIcon(false)
                        }
                        else{
                          setShowPasswordIcon(true);
                        }
                      }}
                    >
                      {loginError && loginError.message === USER_DO_NOT_HAVE_ANY_ACTIVE_LICENSE ? (
                        <Alert color="danger">
                          Login error, because your subscription plan is no longer valid. Please contact 
                          { <a href="mailto:customersupport@industrybest.com.au"> customer support </a> } 
                          for further assistance.
                        </Alert>
                      ) : loginError && loginError.message ? (
                        <Alert color="danger">{loginError.message}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Field
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                        />
                      </div>

                      <div className="mb-3">
                        <Field
                          name="password"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password"
                        />
                       { showPasswordIcon &&
                        <InputAdornment position="end" style={{flexDirection: 'column', alignItems:'end', cursor:'pointer'}}>
                            <span
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              style={{fontSize:'1.5rem', position:'relative',top:'-2.2rem', right:'1.6rem'}}
                            >
                              {showPassword ? <i className='mdi mdi-eye' ></i>: <i className='mdi mdi-eye-off' ></i>}
                            </span>
                          </InputAdornment>}
                      </div>

                      <CheckboxGroup name="rememberMe">
                        <Checkbox
                          groupName="rememberMe"
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          value="true"
                          label="Remember me"
                        />
                      </CheckboxGroup>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={loading}
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                            </>
                          )}
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © 2024 Industry Best. Gratefully helping you.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
