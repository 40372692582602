import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  BELOW_GROUND,
  COMMERCIAL,
  EXTERNAL,
  INDUSTRIAL,
  INTERNAL,
  RESIDENTIAL,
  RESIDENTIAL_CATEGORY,
  COMMERCIAL_CATEGORY,
  INFRASTRUCTURE_CATEGORY,
} from "common/constants";
import { useState } from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import FloorSelection from "./FloorSelection.js";
import MultiSelect from "./MultiSelect.js";

const CertificateGenerateComponent = ({ job, onInvokeCertificateModal }) => {
  const history = useHistory();
  const [jobCategory, setJobCategory] = useState("");
  const [jobType, setJobType] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [currentUrl, setCurrentUrl] = useState(
    history?.location?.pathname?.split("/")[1]
  );
  const [selectedFloor, setSelectedFloor] = useState({});
  const [selectedAreas, setSelectedAreas] = useState([] | null);
  const [defaultFloors, setDefaultFloors] = useState([]);
  const isValidDetails = () => {
    const result = jobCategory !== "";

    return result && documentName && documentName?.trim() !== "";
  };
  const floorHandler = floor => {
    try {
      const parsedFloor = JSON.parse(floor);
      setSelectedFloor(parsedFloor);
      const areas = [];
      parsedFloor?.subItems?.map(item => {
        areas.push(item);
      });
      setSelectedAreas([]);
      const areasWithLabels = [];
      areas.map(item => {
        areasWithLabels.push({
          label: item.content,
          value: item.id,
        });
      });
      setDefaultFloors(areasWithLabels);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };
  const handleAreasSelection = selected => {
    setSelectedAreas(selected);
  };
  return (
    <div className="generate-details-container">
      <Typography
        className="certificate-header"
        variant="h5"
        sx={{ fontWeight: "bold" }}
      >
        {currentUrl === "inspectionactive"
          ? "Inspection Document"
          : "Generate Document"}
      </Typography>
      <div>
        <div style={{ display: "flex" }}>
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="job-category-label" size="small">
              Job Category
            </InputLabel>
            <Select
              labelId="job-category-label"
              id="job-category-select"
              value={jobCategory}
              onChange={e => {
                setJobCategory(e.target.value);
                setSelectedFloor({});
                setDefaultFloors([]);
              }}
              label="Job Category"
              size="small"
            >
              {job?.jobType?.internal && (
                <MenuItem value={INTERNAL}>{RESIDENTIAL_CATEGORY}</MenuItem>
              )}
              {job?.jobType?.external && (
                <MenuItem value={EXTERNAL}>{COMMERCIAL_CATEGORY}</MenuItem>
              )}
              {job?.jobType?.belowground && (
                <MenuItem value={BELOW_GROUND}>
                  {INFRASTRUCTURE_CATEGORY}
                </MenuItem>
              )}
            </Select>
          </FormControl>
          {jobCategory && (
            <FloorSelection
              job={job}
              jobType={jobCategory?.toLowerCase()}
              selectedFloor={selectedFloor}
              floorHandler={floorHandler}
            />
          )}
        </div>
        <div>
          {jobCategory && selectedFloor?.id && defaultFloors && (
            <FormControl
              sx={{
                m: 1,
                minWidth: 150,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <MultiSelect
                key="example_id"
                options={defaultFloors}
                onChange={handleAreasSelection}
                value={selectedAreas}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
            </FormControl>
          )}
        </div>
        <FormControl
          sx={{
            m: 1,
            minWidth: 150,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Document Name"
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 40 }}
            onChange={e => setDocumentName(e.target.value)}
            placeholder="Enter Document Name"
          />
        </FormControl>
      </div>
      <div className="generate-buuton-container">
        <Button
          disabled={!isValidDetails()}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          color="primary"
          size="lg"
          onClick={() => {
            onInvokeCertificateModal({
              jobCategory,
              documentName,
              selectedFloor,
              selectedAreas,
            });
          }}
        >
          Preview and Generate
        </Button>
      </div>
    </div>
  );
};
export default CertificateGenerateComponent;