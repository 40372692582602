import axios from "axios";
import { CLOUD_FUNCTION_URL } from "common/constants";
export const createOption = label => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

export const defaultRoleOptions = [
  createOption("Installer"),
  createOption("Inspector"),
  createOption("Builder"),
  createOption("Consultant"),
  createOption("Client"),
  createOption("Building Surveyor"),
  createOption("Engineer"),
  createOption("Architect"),
  createOption("Designer"),
  createOption("Supervisor"),
  createOption("Other"),
];

export const defaultAccessOptions = [
  createOption("Full"),
  createOption("Tick, Notes, Photos, E-sign"),
  createOption("Read Only"),
];

export const invitedUserAccessOptions = [
  createOption("Full"),
  createOption("Tick, Notes, Photos, E-sign"),
  createOption("Read Only"),
];

export const sendEmailToInvitedUserOfJob = async (
  orgName,
  user,
  hostUser,
  job,
) => {
  try {
      await axios.post(`${CLOUD_FUNCTION_URL}/sendMailToInvitedUserOfJob`, {
        orgName,
         user,
        hostUser,
        job,
      });
  } catch (error) {
    if (error.response) {
      console.log("------error: ", error.response.data);
    }
    console.log("-----error: ", error);
  }
};
