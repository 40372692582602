import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Media,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { deleteDraftJobInDb, deleteChecklistSubCollection } from "services";
import { AVATAR_URL, CHEKCLIST_COLLECTION, COMMERCIAL_CATEGORY, DRAFTS_DB_NAME, INFRASTRUCTURE_CATEGORY, INSPECTION_DB_NAME, RESIDENTIAL_CATEGORY, WORKING_DATES_COLLECTION } from "common/constants";

const CardProject = ({ jobs, collectionName }) => {
  const getPathName=()  =>{
    if(collectionName === DRAFTS_DB_NAME){
      return "newjob";
    }
    if(collectionName === INSPECTION_DB_NAME){
      return "newinspectionjob"
    }
    return null
  }
  return (
    <React.Fragment>
      {map(jobs, (job, key) => (
        <Col xl="4" lg="6" md="6" sm="12" key={job.id}>
          <Card>
            <CardBody>
              <UncontrolledDropdown>
                <DropdownToggle href="#" className="card-drop" tag="i">
                  <i className="mdi mdi-dots-horizontal font-size-18" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-start">
                  <DropdownItem>
                    <i className="mdi mdi-home-edit font-size-16 text-success me-1" />{" "}
                    <Link to={`/${getPathName()}/${job.id}`} className="text-dark">
                      Open
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      deleteChecklistSubCollection(job.id, collectionName,CHEKCLIST_COLLECTION);
                      deleteChecklistSubCollection(job.id,collectionName,WORKING_DATES_COLLECTION)
                      deleteDraftJobInDb(job.id,collectionName);
                    }}
                  >
                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                    Delete Forever
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <Media>
                <div className="avatar-md me-4">
                  <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                    <img
                      src={`${AVATAR_URL}&name=${job.client.displayName}`}
                      className="rounded-circle avatar-sm"
                      alt=""
                    />
                  </span>
                </div>

                <Media className="overflow-hidden" body>
                  <h5 className="text-truncate font-size-15">
                    <Link to={`/${getPathName()}/${job.id}`} className="text-dark">
                      {job.client.displayName}
                    </Link>
                  </h5>
                  <p className="text-muted mb-4">
                    <Link to={`/${getPathName()}/${job.id}`} className="text-dark">
                      {job.jobAddress}
                    </Link>
                  </p>

                  <div className="avatar-group">
                    {job.participants.map((team, key) => (
                      <React.Fragment key={team.id}>
                        <div className="avatar-group-item">
                          <Link
                            to="#"
                            className="d-inline-block"
                            id={"member" + team.id}
                          >
                            <img
                              src={
                                team.photoURL ||
                                `${AVATAR_URL}&name=${team.displayName}`
                              }
                              className="rounded-circle avatar-xs"
                              alt=""
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={"member" + team.id}
                            >
                              {team.displayName}
                            </UncontrolledTooltip>
                          </Link>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </Media>
              </Media>
            </CardBody>
            <div className="px-4 py-3 border-top">
              <ul className="list-inline mb-0">
                <li className="list-inline-item me-3">
                  {job.jobType.internal && (
                    <Badge className={"bg-success"}>{RESIDENTIAL_CATEGORY}</Badge>
                  )}
                  {job.jobType.external && (
                    <Badge className={"bg-warning"}>{COMMERCIAL_CATEGORY}</Badge>
                  )}
                  {job.jobType.belowground && (
                    <Badge className={"bg-primary"}>{INFRASTRUCTURE_CATEGORY}</Badge>
                  )}
                </li>
              </ul>
            </div>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

CardProject.propTypes = {
  projects: PropTypes.array,
};

export default CardProject;
