export const STEP = {
  CLIENTDETAILS: 1,
  JOBCATEGORY: 2,
  BUILDINGCLASS: 3,
    // INSTALLER: 4,
    ROOMS: 4,
    CHECKLIST: 5,
    FINAL: 6,
};

export function getLastStepOfWizard(job) {
  if (!job) return STEP.CLIENTDETAILS;
  if (
    // !job.client.address ||
    !job.jobAddress 
    // !job.client.displayName ||
    // !job.client.email ||
    // !job.client.mobile
  ) {
    return STEP.CLIENTDETAILS;
  }
  if (
    !job.jobType.internal &&
    !job.jobType.external &&
    !job.jobType.belowground &&
    job.jobCategory.internal === "none" &&
    job.jobCategory.external === "none" &&
    job.jobCategory.belowground === "none"
  ) {
    return STEP.CLIENTDETAILS;
  }
  if (
    (job.jobType.internal &&
      !job.buildingClass.internal.find(classItem => classItem.checked)) ||
    (job.jobType.external &&
      !job.buildingClass.external.find(classItem => classItem.checked)) ||
    (job.jobType.belowground &&
      !job.buildingClass.belowground.find(classItem => classItem.checked))
  ) {
    return STEP.BUILDINGCLASS;
  }
  if (job.participants.length === 0) {
    return STEP.BUILDINGCLASS;
  }
  if (
    job.rooms.belowground.length == 0 &&
    job.rooms.internal.length == 0 &&
    job.rooms.external.length == 0
  ) {
    return STEP.INSTALLER;
  }
  if (Object.keys(job.areas || {}).length === 0) {
    return STEP.ROOMS;
  }
  if (job.jobType.internal && job.rooms.internal.length == 0) {
    return STEP.ROOMS;
  }
  if (job.jobType.external && job.rooms.external.length == 0) {
    return STEP.ROOMS;
  }
  if (job.jobType.belowground && job.rooms.belowground.length == 0) {
    return STEP.ROOMS;
  }
  if (job.rooms.belowground.length != 0) {
    const isBelowGroundEmptyAreasExist = job.rooms.belowground.find(
      floor => floor.subItems.length == 0
    );
    if (isBelowGroundEmptyAreasExist) return STEP.ROOMS;
  }
  if (job.rooms.internal.length != 0) {
    const isInternalEmptyAreasExist = job.rooms.internal.find(
      floor => floor.subItems.length == 0
    );
    if (isInternalEmptyAreasExist) return STEP.ROOMS;
  }
  if (job.rooms.external.length != 0) {
    const isExternalEmptyAreasExist = job.rooms.external.find(
      floor => floor.subItems.length == 0
    );
    if (isExternalEmptyAreasExist) return STEP.ROOMS;
  }
  return STEP.CHECKLIST;
}

export const UPDATE_CATEGORY_STEP = {
  JOBCATEGORY: 1,
  BUILDINGCLASS: 2,
};