import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import DeleteConfirmationPromptWithCheckBox from "components/Common/DeleteConfirmationPromptWIthCheckBox";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deleteMultipleNotes, fetchAllNotes } from "services/notesService";
import { fetchNotes, fetchNotesError, fetchNotesSuccess } from "store/actions";
import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import NotesListUnit from "./NotesListUnit";
import "../jobs/jobCreation/checklist/CheckListPage.scss";
import NotesDefaultList from "./NotesDefaultList";
import { UpdateNotes } from "./NotesUpdation";
import { NotesPermission } from "pages/jobs/jobDetails/rooms/NotesPermission";
export function NotesLibrary() {
  const { currentUserProfile } = useSelector(state => state.profile);
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchAllNotes(currentUserProfile?.id),
    onStart: () => dispatch(fetchNotes),
    onSuccess: docs => dispatch(fetchNotesSuccess(docs)),
    onError: error => dispatch(fetchNotesError(error)),
    deps: [dispatch],
  });

  const { notes } = useSelector(state => state.notes);
  const [activeTab, setActiveTab] = useState("1");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [notesNameSearch, setNotesNameSearch] = useState("");
  const [myNotes, setMyNotes] = useState(NotesDefaultList);
  const [ modalVisible,setModalVisible] = useState(false);
  const [notesPermission, setNotesPermission] = useState(false);
  const deleteSelectedNotes = useCallback(async () => {
    const newNotes = [...notes.filter(item => item.checked === true)];
    const notesIds = newNotes.map(item => item.id);
    const toastMessage =
      notesIds.length === 1
        ? "The notes is deleted!"
        : "The notes are deleted!";

    setShowDeleteAlert(false);
    await deleteMultipleNotes(notesIds)
      .then(() => {
        toast.success(toastMessage, {
          autoClose: 2000,
        });
      })
      .catch(() => {
        toast.error("Failed while deleting the checklist", {
          autoClose: 2000,
        });
      });
  }, [notes]);

  const onCancel = useCallback(() => {
    [...notes].map(item => {
      item.checked = false;
    });
    setMyNotes([...notes]);
    setShowCheckBox(false);
  }, [notes]);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    let newList = notes.filter(item => {
      const title = item?.notesName;
      return title?.toLowerCase().includes(notesNameSearch.toLowerCase());
    });
    newList.sort((a, b) => {
      const aStr = a.notesName;
      const bStr = b.notesName;
      return aStr.localeCompare(bStr);
    });
    setMyNotes([...newList]);
  }, [notesNameSearch, notes]);
  const notesIsChecked = [...myNotes].find(item => item.checked);
  return (
    <React.Fragment>
       {modalVisible && (
         <UpdateNotes setModalVisible={setModalVisible} selectedNotes={{}} />
        )}
        {notesPermission && (
        <NotesPermission setNotesPermission={setNotesPermission}   
        label={""}/>)}
      <Container fluid>
        <Row>
          <Col>
            <> 
            <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => toggleTab("1")}
                  >
                    Saved Notes
                  </NavLink>
                </NavItem>
               
              </Nav>
              <button style={{
                  padding: "10px 10px",
                  backgroundColor: "#50a5f1",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "5px",
                  float:"right",
                  marginTop:"-45px"

                }}
                onClick={() => {
                  setNotesPermission(true);
                }}>{currentUserProfile?.disableNotes?"Enable Popup":"Disable Popup"}</button> 
              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane className="saved-checklist-tab" tabId="1">
                  <div className="mb-2">
                    <div>
                      <Label className="saved-checklist-tab__label">
                        Search Saved Notes
                      </Label>
                     
                      {showCheckBox && notesIsChecked && (
                        <Button
                          size="md"
                          color="link"
                          className="saved-checklist-tab__button"
                          onClick={() => {
                            setShowDeleteAlert(true);
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </Button>
                      )}
                      <Button
                        size="md"
                        color="link"
                        className="saved-checklist-tab__select"
                        onClick={() => {
                          showCheckBox ? onCancel() : setShowCheckBox(true);
                        }}
                      >
                        {showCheckBox ? "Cancel" : "Select Multiple"}
                      </Button>
                      <Button
                        size="md"
                        color="link"
                        className="saved-checklist-tab__select"
                        onClick={() => {
                          setModalVisible(true)
                        }}
                      >
                        Add
                      </Button>
                    </div>
                    <Input
                      type="search"
                      placeholder="Enter keyword"
                      name="NotesSearch"
                      onChange={e => setNotesNameSearch(e.target.value)}
                    ></Input>
                  </div>

                  <Row className="saved-checklist-tab__list">
                    <NotesListUnit
                      notesNameSearch={notesNameSearch}
                      myNotes= {myNotes}
                      showCheckBox={showCheckBox}
                      setMyNotes={setMyNotes}
                    />
                  </Row>
                </TabPane>
              </TabContent>
            </>
          </Col>
        </Row>
      </Container>
      {showDeleteAlert && (
        <DeleteConfirmationPromptWithCheckBox
          label={
            "I understand that deleting is permanent and cannot be undone."
          }
          onCancel={() => {
            setShowDeleteAlert(false);
          }}
          onConfirm={deleteSelectedNotes}
        />
      )}
    </React.Fragment>
  );
}

export default NotesLibrary;
