import { documentCategory } from "common/data/documents";
import { getDownloadURL } from "common/utils";
import { buildingClassDataMap } from "common/data/clases";
import {
  deleteImageFromFirebaseStoreByUrl,
  updateJobDraftInDb,
  cloneChecklistsSubCollection,
} from "services";
import cuid from "cuid";

import { CHEKCLIST_COLLECTION, DRAFTS_DB_NAME, INSPECTION_DB_NAME } from "common/constants";

// a little function to help us with reordering the result
export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.froneChecklistm(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 6;
export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    textAlign: "left",
    borderColor: "",
    borderStyle: "solid",
    borderRadius: "2px",
    borderWidth: "thin",

    // change background colour if dragging
    background: isDragging ? "rgba(85, 110, 230, 0.1)" : "white",
    borderColor: isDragging ? "#556ee6" : "rgba(85, 110, 230, 0.3)",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

export const getListContainerStyle = isDraggingOver => ({
  background: isDraggingOver ? "rgba(85, 110, 230, 0.1)" : "white",
  padding: 8,
});

export const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "rgba(85, 110, 230, 0.1)" : "white",
  padding: 4,
});

export const deleteChecklistAttachments = attachments => {
  const promises = [];
  attachments?.forEach(async attachment => {
    const resizedUrl = await getDownloadURL(attachment.resizedImagePath);
    promises.push(deleteImageFromFirebaseStoreByUrl(resizedUrl));
    promises.push(deleteImageFromFirebaseStoreByUrl(attachment.url));
  });
  return Promise.all(promises);
};

export const cloneJob = async (job, collectionName) => {
  const toCollection =
    collectionName === INSPECTION_DB_NAME ? INSPECTION_DB_NAME : DRAFTS_DB_NAME;
  const clonedJob = {
    id: cuid(),
    client: job.client,
    jobName: job.jobName ?? "",
    jobAddress: job.jobAddress,
    jobType: job.jobType,
    areas: job.areas || {},
    jobCategory: {
      internal: job.internal?.jobCategory ?? "none",
      external: job.external?.jobCategory ?? "none",
      belowground: job.belowground?.jobCategory ?? "none",
    },
    buildingClass: {
      internal: job.internal?.buildingClass ?? buildingClassDataMap,
      external: job.external?.buildingClass ?? buildingClassDataMap,
      belowground: job.belowground?.buildingClass ?? buildingClassDataMap,
    },
    participants: job.participants,
    rooms: {
      internal: job.internal?.rooms ?? [],
      external: job.external?.rooms ?? [],
      belowground: job.belowground?.rooms ?? [],
    },
    documents: documentCategory,
  };
  await updateJobDraftInDb(clonedJob,collectionName);
  await cloneChecklistsSubCollection(
    CHEKCLIST_COLLECTION,
    collectionName,
    toCollection,
    job.id,
    clonedJob.id
  );
  return clonedJob;
};

export function convertTextToEllipsis(str, limit) {
  var dots = "...";
  if (str.length > limit) {
    str = str.substring(0, limit) + dots;
  }
  return str;
}
