import React from "react";
import classNames from "classnames";
import "./button.css"

export function Button({ children, ...props }) {
  return (
    <button className={classNames("Button")} {...props}>
      {children}
    </button>
  );
}
