import {
  FETCH_ALL_SUBSCRIPTIONS,
  FETCH_ALL_SUBSCRIPTIONS_ERROR,
  FETCH_ALL_SUBSCRIPTIONS_SUCCESS,
} from "./actionTypes";

const initialState = {
  subscriptions: [],
  loading: false,
};

export default function subscriptionsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_ALL_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ALL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptions: payload,
      };
    default:
      return state;
  }
}
