import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { COMMERCIAL_CATEGORY, EXTERNAL, EXTERNAL_TYPE, INFRASTRUCTURE_CATEGORY, INTERNAL, RESIDENTIAL_CATEGORY } from "common/constants";
import { memo } from "react";

const styles = StyleSheet.create({
  headerContainer: {
    paddingTop: 14,
    paddingBottom: 14,
    width: "45%",
  },
  clientInfo: {
    marginBottom: 6,
    flexDirection: "row",
    alignSelf: "left",
    fontSize: 20,
    fontWeight: 600,
    color: "#004F6B",
  },
  fieldContainer: {
    flexDirection: "row",

    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  fieldName: {
    fontWeight: 600,
    fontSize: 14,
    color: "#072535",
  },
  fieldValue: {
    fontSize: 14,
    color: "#072535",
  },
});
const getJobCategory = (jobType) => {
  console.log("job received", jobType);
  if(jobType === INTERNAL) {
    return RESIDENTIAL_CATEGORY;
  }
  if(jobType === EXTERNAL) {
    return COMMERCIAL_CATEGORY;
  }
  return INFRASTRUCTURE_CATEGORY;
}
const JobDetails = ({ certificate }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.clientInfo}>Job Details</Text>
    <View style={styles.headerContainaer}>
      <View style={styles.fieldContainer}>
        <Text style={styles.fieldName}>Job Number: </Text>
        <Text style={styles.fieldValue}>{certificate?.jobNo}</Text>
      </View>
      <View style={styles.fieldContainer}>
        <Text style={styles.fieldName}>Site Address: </Text>
        <Text style={styles.fieldValue}>{certificate?.jobAddress}</Text>
      </View>
      <View style={styles.fieldContainer}>
        <Text style={styles.fieldName}>Building Class: </Text>
        <Text style={styles.fieldValue}>{certificate?.buildingClasses[0]}</Text>
      </View>
      <View style={styles.fieldContainer}>
        <Text style={styles.fieldName}>Job Category: </Text>
        <Text style={styles.fieldValue}>{getJobCategory(certificate.jobType)}</Text>
      </View>
    </View>
  </View>
);
export default memo(JobDetails);
