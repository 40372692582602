import React, { useState, useEffect } from "react";
import { Button, Nav, NavLink, NavItem, Row, Col, Tooltip } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { isValidOrgUser } from "routes/allRoutes";
import { useSelector } from "react-redux";
import organisationStatus from "utils/organisationStatus";

// className="product-view-nav justify-content-center"
const Breadcrumb = ({ activeTab, setActiveTab,title ,pathname}) => {
  const [gridToolTip, setGridToolTip] = useState(false);
  const [listToolTip, setListToolTip] = useState(false);
  const { currentUserProfile } = useSelector(state => state.profile);
  const [isValidUser, setIsValidUser] = useState(false);
  const isOrganisationActive = organisationStatus(currentUserProfile);

  useEffect(() => {
    setIsValidUser(isValidOrgUser(currentUserProfile));
  }, [currentUserProfile?.orgAccessId, currentUserProfile?.orgStatus]);

  return (
    <Row>
      <Col>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18" style={{ marginLeft: 12 }}>
            {title}
          </h4>

          <div className="page-title-right" style={{ marginRight: 12 }}>
            <Nav className="product-view-nav" pills>
              <NavItem id="gridToolTipId">
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    setActiveTab("1");
                  }}
                >
                  <i className="bx bx-grid-alt" />
                </NavLink>
              </NavItem>
              <Tooltip
                placement="top"
                isOpen={gridToolTip}
                target="gridToolTipId"
                toggle={() => setGridToolTip(!gridToolTip)}
              >
                Grid View
              </Tooltip>
              <NavItem id="listToolTipId">
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    setActiveTab("2");
                  }}
                >
                  <i className="bx bx-list-ul" />
                </NavLink>
              </NavItem>
              <Tooltip
                placement="top"
                isOpen={listToolTip}
                target="listToolTipId"
                toggle={() => setListToolTip(!listToolTip)}
              >
                List View
              </Tooltip>
              {isValidUser && isOrganisationActive && !pathname.includes("newjob") && (
                <Link to={"/newjob"}>
                  <Button
                    color="primary"
                    style={{ marginLeft: 10 }}
                  >
                    <i className="fa fa-plus"></i>  New Job
                  </Button>
                </Link>
              )}
              {isValidUser && isOrganisationActive && !pathname.includes("newjob") &&(
                <Link to="/newinspectionjob">
                  <Button
                    color="primary"
                    style={{ marginLeft: 10 }}
                  >
                    <i className="fa fa-plus"></i> New Inspection
                  </Button>
                </Link>
              )}
            </Nav>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
