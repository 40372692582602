const tasks = [
  {
    id: 1,
    start: "2021-12-18 09:30:00",
    end: "2021-12-19 23:30:00",
    resourceId: 1,
    title: "I am finished",
    bgColor: "#D9D9D9",
  },
  {
    id: 2,
    start: "2021-12-18 12:30:00",
    end: "2021-12-26 23:30:00",
    resourceId: 2,
    title: "I am on Lockdown",
  },
  {
    id: 3,
    start: "2021-7-19 12:30:00",
    end: "2021-7-20 20:30:00",
    resourceId: 3,
    title: "Waterproffing for the balcony",
  },
  {
    id: 4,
    start: "2021-12-19 14:30:00",
    end: "2021-12-20 23:30:00",
    resourceId: 1,
    title: "I am not yet to be started",
  },
  {
    id: 5,
    start: "2021-07-19 15:30:00",
    end: "2021-07-20 23:30:00",
    resourceId: 2,
    title: "task need to be done",
    bgColor: "#f759ab",
  },
  {
    id: 6,
    start: "2021-7-19 12:30:00",
    end: "2021-7-20 20:30:00",
    resourceId: 3,
    groupId: 11,
    title: "Group job for the balcony",
  },
  {
    id: 7,
    start: "2021-7-19 12:30:00",
    end: "2021-7-20 20:30:00",
    resourceId: 2,
    groupId: 11,
    title: "Group job for the balcony",
  },
];

export { tasks };
