import React from "react";
import { SearchBox } from "@elastic/react-search-ui";
import { getAllJobsFromDb } from "utils/getAllJobsFromDb";
import { CustomSearchView } from "searchuicomponents/CustomSearchView";
import { useSelector } from "react-redux";

export function Search({
  searchType,
  setSearchTerm,
  shouldClearFilters,
  resultSearchTerm,
}) {
  const jobNumbers = new Set();
  const clientNames = new Set();
  const jobAddresses = new Set();
  const jobNames = new Set();
  const customJobNumbers = new Set();
  const getAllJobs = getAllJobsFromDb(
    jobNumbers,
    clientNames,
    jobAddresses,
    jobNames,
    customJobNumbers
  );
  getAllJobs();
  return (
    <SearchBox
      autocompleteView={({ autocompletedSuggestions, getItemProps }) => (
        <div className="sui-search-box__autocomplete-container">
          {autocompletedSuggestions.documents.map((filteredList, i) => {
            const jobNumberList = Array.from(jobNumbers);
            const clientNamesList = Array.from(clientNames);
            const jobAddressList = Array.from(jobAddresses);
            const jobNameList = Array.from(jobNames);
            const customjobNumbersList = Array.from(customJobNumbers);
            const value =
              searchType === "jobno"
                ? jobNumberList.includes(parseInt(filteredList.suggestion))
                : searchType === "jobaddress"
                ? jobAddressList.some(address =>
                    address
                      .toLowerCase()
                      .includes(filteredList.suggestion.toLowerCase())
                  )
                : searchType === "jobname"
                ? jobNameList?.includes(filteredList.suggestion.toLowerCase())
                : searchType === "customjobno"
                ? customjobNumbersList?.includes(
                    filteredList.suggestion.toLowerCase()
                  )
                : clientNamesList.includes(
                    filteredList.suggestion.toLowerCase()
                  );

            return (
              value && (
                <div
                  style={{
                    paddingLeft: "10px",
                  }}
                  {...getItemProps({
                    key: i,
                    item: filteredList,
                  })}
                >
                  {filteredList.suggestion}
                </div>
              )
            );
          })}
        </div>
      )}
      inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
        <CustomSearchView
          getAutocomplete={getAutocomplete}
          getButtonProps={getButtonProps}
          getInputProps={getInputProps}
          setSearchTerm={setSearchTerm}
          shouldClearFilters={shouldClearFilters}
          resultSearchTerm={resultSearchTerm}
        />
      )}
      autocompleteSuggestions={{
        sectionTitle: "Suggested Queries",
      }}
      debounceLength={0}
    />
  );
}
