import { useEffect } from "react";
import { useState } from "react";
import { Progress } from "reactstrap";

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress >= 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 210);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <Progress animated color="success" value={progress} min={0} max={100} />
      <p>{Math.floor(progress)}%</p>
    </div>
  );
};

export default ProgressBar;
