import { LOCATION_CHANGE } from "connected-react-router";
import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_ERROR,
  REGISTER_USER,
  RESET_ERROR,
  RESET_PASSWORD,
  RESET_SUCCESS,
} from "./actionTypes";

const initialState = {
  authenticated: false,
  currentUser: null,
  prevLocation: null,
  currentLocation: null,
  loading: true,
  loginError: null,
  registerError: null,
  resetError: null,
  isEmailSent:false,
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_USER:
      return {
        ...state,
        loading: true,
        isEmailSent:true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        loading: false,
        currentUser: {
          email: payload?.email,
          photoURL: payload?.photoURL,
          uid: payload?.uid,
          displayName: payload?.displayName,
        },
        loginError: null,
        registerError: null,
      };
    case LOGOUT_USER:
      return {
        ...state,
        authenticated: false,
        currentUser: null,
        loading: false,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        prevLocation: state.currentLocation,
        currentLocation: payload.location,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginError: payload,
        loading: false,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        registerError: payload,
        loading: false,
      };
    case RESET_ERROR:
      return {
        ...state,
        resetError: payload,
        loading: false,
      };
    case RESET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
