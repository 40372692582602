import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import organisationStatus from "utils/organisationStatus";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { fetchInvitedJobsFromDb, fetchJobsFromDb } from "services";
import { fetchJobs, fetchJobsError, fetchJobsSuccess } from "store/actions";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector/lib/esm/AppSearchAPIConnector";
import { getConfiguration } from "searchuicomponents/configuration";
import { JOB_NUMBER } from "common/constants";
import ProgressBar from "searchuicomponents/ProgressBar";
import SweetAlert from "react-bootstrap-sweetalert";
import { MetaTags } from "react-meta-tags";
import { onDeleteJob } from "./util";
import Dashboard from "../../../../components/jobs-ui/DashBoardUi";
import DeleteConfirmationPromptWithInput from  "../../../../components/Common/DeleteConfirmationPromptWithInput";
export function DeletedJobs() {
  const { currentUserProfile } = useSelector(state => state.profile);
  const [searchType, setSearchType] = useState(
    sessionStorage.getItem("DeletedSearchType") || JOB_NUMBER
  );
  const dispatch = useDispatch();
  const isOrganisationActive = organisationStatus(currentUserProfile);
  const [jobupdate, setJobUpdate] = useState("");
  useFirestoreCollection({
    query: () =>
      isOrganisationActive
        ? fetchJobsFromDb("deleted")
        : fetchInvitedJobsFromDb(
            "deleted",
            currentUserProfile?.orgAccessId || null
          ),
    onStart: () => dispatch(fetchJobs()),
    onSuccess: docs => dispatch(fetchJobsSuccess(docs)),
    onError: error => dispatch(fetchJobsError(error)),
    deps: [dispatch],
  });
  const connector = new AppSearchAPIConnector({
    searchKey: process.env.REACT_APP_ELASTIC_SEARCH_PRIVATE_KEY,
    engineName: process.env.REACT_APP_ELASTIC_SEARCH_DELETED_ENGINE,
    endpointBase: process.env.REACT_APP_ELASTIC_SEARCH_URL,
    cacheResponses: false,
  });
  const config = getConfiguration(connector, searchType, currentUserProfile);
  const getTitleOfSweetAlert = () => {
    if (jobupdate === "active") {
      return "Job restoring is in progress...";
    }
    if (jobupdate === "confirmdelete") {
      return "Job Deletion is in Progress...";
    }
  };
  const title = getTitleOfSweetAlert();
  const number = jobupdate?.replace(/^\D+/g, '');
  useEffect(() => {
    sessionStorage.setItem("DeleteSearchType", searchType);
  }, [searchType]);
  const onConfirmDelete = () => {
    setJobUpdate("confirmdelete");
  };
  return (
    <>
      {jobupdate?.length ? (
        <>
          {jobupdate === "active" || jobupdate === "confirmdelete" ? (
            <SweetAlert
              title={title}
              timeout={4000}
              showConfirm={false}
              onConfirm={()=>{
                setJobUpdate("")
              }}
            >
              <ProgressBar />
            </SweetAlert>
          ) : (
            <DeleteConfirmationPromptWithInput
              textTypeToDisplay={"Job Number"}
              confirmationText={number}
              onCancel={() => {
                setJobUpdate("");
              }}
              onConfirm={onConfirmDelete}
            />
          )}
        </>
      ) : (
        <>
          <MetaTags>
            <title>Jobs | Deleted</title>
          </MetaTags>
          <Dashboard
            config={config}
            searchType={searchType}
            setSearchType={setSearchType}
            pathname={"deleted"}
            setJobUpdate={setJobUpdate}
            isLinkEnabled ={false} 
            collectionName={"deleted"}
          />
        </>
      )}
    </>
  );
}