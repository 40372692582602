import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom"

import { Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/worker-outline.svg"

//Logout action
import { signOutFirebase } from "../../../services/authService"
import { userName } from "pages/platformManagement/org-creation/orgService";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { currentUserProfile } = useSelector(state => state.profile);
  const history = useHistory();

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={currentUserProfile?.photoURL || user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {userName(currentUserProfile)}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </Link>

          <Link to="/settings" className="dropdown-item">
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </Link>
         
          <div className="dropdown-divider" />
          <DropdownItem
            onClick={() => {
              signOutFirebase();
              localStorage.removeItem("ActiveJobs")
              localStorage.removeItem("ArchivedJobs")
              localStorage.removeItem("DeletedJobs")
              localStorage.removeItem("IMAGE_COUNT")
              localStorage.removeItem('checkedJobs')
              history.push("/login");
            }}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(ProfileMenu)
