import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
// actions
import { forgotPassword } from "services/authService";

// import images
import profile from "assets/images/profile-img.png";
import logo from "../../assets/images/logo-color.png";
import { Field, Form } from "@availity/form";
import "@availity/yup";
import * as yup from "yup";

const Recoverpw = () => {
  const [loading, setLoading] = useState(false);
  // handleValidSubmit
  const handleValidSubmit = values => {
    setLoading(true);
    forgotPassword(values);
    toast(
      "If an account with this email exists a link will be sent to reset your password. Please check your email and spam email accounts, or contact customersupport@industrybest.com.au for further assistance.",
      {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <MetaTags>
          <title>Recover Password | Industry Best Digital</title>
        </MetaTags>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h4 className="text-primary"> Reset password</h4>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="dashboard">
                      <div className="avatar-xl profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-white">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="104"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    <div
                      className="alert alert-success text-center mb-4"
                      role="alert"
                    >
                      {" "}
                      Enter your Email and instructions will be sent to you!{" "}
                    </div>

                    <Form
                      className="form-horizontal"
                      onSubmit={values => {
                        handleValidSubmit(values);
                      }}
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={yup.object().shape({
                        email: yup
                          .string()
                          .isRequired(true, "Please enter a valid email."),
                      })}
                    >
                      <div className="mb-3">
                        <Field
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                        />
                      </div>
                      <div className="text-end">
                        <button
                          className="btn btn-primary w-md "
                          type="submit"
                          disabled={loading}
                        >
                          Reset
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="login" className="fw-medium text-primary">
                    {" "}
                    Sign In here
                  </Link>{" "}
                </p>
                <p>
                  © 2024 Industry Best. Gratefully helping you.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Recoverpw;
