import {
  ACTIVE,
} from "common/constants";

import { addOrgToDb, updateOrgInDb } from "services";

import { orgIdentityCodeUpdationForAllUsers } from "./orgService.js";


export async function handleOrganisation(
  allOrgs,
  setOrgNameError,
setorgIdentityCodeError,
  organisation,
  address,
  isEditOrg,
  setIsEditOrg,
  setShowCreateNewOrg,
  orgIdentityCode
) {
const isValidOrgName = organisationUniquenameCheck(
  allOrgs,
  organisation,
  isEditOrg
);

const isValidorgIdentityCode = organisationUniqueorgIdentityCodeCheck(
  allOrgs,
  orgIdentityCode,
  isEditOrg,
  organisation
);
setOrgNameError(!isValidOrgName);
setorgIdentityCodeError(!isValidorgIdentityCode);
const isValidChange = isValidOrgName && isValidorgIdentityCode;

  const orgDetails = {
    name: organisation?.name,
    phone: organisation?.phone === "+" ? "" : organisation?.phone || "",
    address,
    status: organisation.status || ACTIVE,
    startingJobNumber: Number(organisation?.startingJobNumber )|| 0,
    nextJobNumber: isEditOrg
      ? Number(organisation?.nextJobNumber)
      : Number(organisation?.startingJobNumber) || 1,
    documentPreferenceNumber: Number(organisation?.documentPreferenceNumber) || 0,
    nextDocumentNumber: isEditOrg
      ? Number(organisation?.nextDocumentNumber)
      : Number(organisation?.documentPreferenceNumber)  || 1,
    countryCode : organisation?.countryCode || '00',
    orgIdentityCode: orgIdentityCode || "",
  };

  if (!isEditOrg) {
    createNewOrganisation(
      organisation,
      setShowCreateNewOrg,
      setIsEditOrg,
      orgDetails,
      isValidChange
    );

  } else {
    updateExistingOrg(
      organisation,
      setShowCreateNewOrg,
      setIsEditOrg,
      orgDetails,
      isValidChange
    );
const isorgIdentityCodeUpdated = organisationorgIdentityCodeUpdateCheck(
  allOrgs,
  organisation?.id,
  orgIdentityCode
);
if (isorgIdentityCodeUpdated) {
  await orgIdentityCodeUpdationForAllUsers(organisation?.id, orgIdentityCode);
}

  }
}
export function updateExistingOrg(
  organisation,
  setShowCreateNewOrg,
  setIsEditOrg,
  orgDetails,
  isValidChange
) {
  const updatedOrg = {
    id: organisation.id,
    ...orgDetails,
  };
  console.log("updatedOrg", updatedOrg);
  const updateduserWithUpdatedProps = {
    ...updatedOrg,
    abn: organisation.abn,
  };
  
  if (isValidChange) {
    updateOrgInDb(organisation.abn ? updateduserWithUpdatedProps : updatedOrg);
    setShowCreateNewOrg(false);
    setIsEditOrg(false);
  }
}
export function createNewOrganisation(
  organisation,
  setShowCreateNewOrg,
  setIsEditOrg,
  orgDetails,
  isValidChange
) {
  const newOrgWithAbn = {
    ...orgDetails,
    abn: organisation.abn,
  };
  if (isValidChange) {
    addOrgToDb(organisation.abn ? newOrgWithAbn : orgDetails);
    setShowCreateNewOrg(false);
    setIsEditOrg(false);
  }
}
export function organisationUniquenameCheck(
  allOrganizations,
  organization,
  isEditOrganization
) {
  const existingOrganization = allOrganizations.filter(org => {
    return org.name?.toLowerCase() === organization?.name?.toLowerCase();
  });
   if (isEditOrganization) {
    if(existingOrganization.length === 1 && existingOrganization[0].id !== organization.id) {
      return false;
    }
    return true;
  }
  console.log(existingOrganization);
  return existingOrganization?.length === 0;
}
export function organisationUniqueorgIdentityCodeCheck(
  allOrganizations,
  orgIdentityCode,
  isEditOrganization,
  organisation
) {
  const existingOrganization = allOrganizations.filter(org => {
    return org?.orgIdentityCode && org.orgIdentityCode?.toLowerCase() === orgIdentityCode?.toLowerCase();
  });
  if (isEditOrganization) {
    if (existingOrganization.length === 0) {
      return true;
    }
    return (
      existingOrganization.length === 1 &&
      existingOrganization[0]?.id === organisation?.id
    );
  }
  return existingOrganization.length === 0;
}

export function organisationorgIdentityCodeUpdateCheck(
  allOrganizations,
  orgId,
  orgIdentityCode
) {
  const existingOrganization = allOrganizations?.filter(org => {
    return orgIdentityCode && orgIdentityCode?.toLowerCase() === org?.orgIdentityCode?.toLowerCase();
  });
  if (existingOrganization.length === 0) {
  return true;
  }
  if (
    existingOrganization.length === 1 &&
    existingOrganization[0].id === orgId &&
    existingOrganization[0]?.orgIdentityCode === orgIdentityCode
  ) 
  {
    return false;
  }
    return true;
}