import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AVATAR_URL } from "common/constants";
import { userName } from "pages/platformManagement/org-creation/orgService";

// import MigrationButton from "../../components/MigrationButton";

const WelcomeComp = () => {
  const { currentUserProfile } = useSelector(state => state.profile);
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="12">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Industry Best</p>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="12">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={
                    currentUserProfile?.photoURL ||
                    `${AVATAR_URL}&name=${currentUserProfile?.displayName}`
                  }
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">
                {userName(currentUserProfile)}
              </h5>
              <p className="text-muted mb-0 text-truncate">
                {currentUserProfile?.email}
              </p>
              <div className="mt-4">
                <Link to="/profile" className="btn btn-primary  btn-sm">
                  View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeComp;
