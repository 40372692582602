
export async function generateBlob(certificate, collectionName, setPdf) {
  return new Promise((resolve, reject) => {
    const worker = new Worker(new URL("./pdfWorker.js", import.meta.url));

    worker.onmessage = function (e) {
      setPdf(true);
      resolve(e.data);
    };

    worker.onerror = function (error) {
      reject(error);
    };

    worker.postMessage({ certificate, collectionName });
  });
}
import Compliance from "../documents/documentCreation/Document";
import ReactPDF from "@react-pdf/renderer";
import useProgressStore from "./zustandService/progressStore";
const { setProgressValue } = useProgressStore.getState();


