import React, { useState, memo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import {
  Button, Input,
  Alert
} from "reactstrap";
import { getChecklist } from "services";
import { buttonStyle } from "./ChecklistsPage";

export const CheckListOverWriteChoice = memo(
  ({ setIsSaveDisabled, setVisibility, onUpdate, onCreateNew, setNewName }) => {
    const { currentUserProfile } = useSelector(state => state.profile);
    const [isCreateNew, setIsCreateNew] = useState(false);
    const [newTemplateName, setNewTemplateName] = useState("");
    const [inputError, setInputError] = useState("");
    const closeModal = () => {
      setIsSaveDisabled(false);
      setVisibility(false);
    };

    const handleCreateNew = async () => {
      if (newTemplateName.length === 0) {
        setInputError("Please enter the name");
        return;
      }
      const checklistSnapshot = await getChecklist(
        currentUserProfile?.orgAccessId
      );
      const checklistData = checklistSnapshot.docs.map(doc => doc.data());
      const isCheckListWithSameName = checklistData.find(
        checkList => checkList?.title.toLowerCase() === newTemplateName.toLowerCase()
      );
      if (isCheckListWithSameName) {
        setInputError("A template already exists with the same name");
        return;
      }
      setNewName(newTemplateName);
      onCreateNew(newTemplateName);
    };

    const handleInput = e => {
      setInputError("");
      setNewTemplateName(e.target.value.trim());
    };

    return (
      <>
        <SweetAlert
          title="Overwrite the checklist template or create new?"
          warning
          showCancel={false}
          showConfirm={false}
          closeOnClickOutside
          onCancel={closeModal}
        >
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              backgroundColor: "white",
              top: "1rem",
              right: "1rem",
              border: "none",
            }}
          >
            <CloseIcon
              style={{
                color: "gray",
              }} />
          </button>
          <p>
            Do you want to update the existing checklist template or create new?
          </p>
          {isCreateNew ? (
            <div>
              <Input
                name="checklistName"
                placeholder="New checklist template name"
                onChange={handleInput}
                style={{ marginBottom: 5 }} />
              {inputError && (
                <Alert
                  severity="error"
                  style={{
                    padding: "0px 5px",
                    marginBottom: 10,
                    color: "red",
                    background: "none",
                    border: "none",
                  }}
                >
                  {inputError}
                </Alert>
              )}
              <div
                style={{
                  padding: "1rem 0",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  color=""
                  style={{ ...buttonStyle, color: "red", border: "none" }}
                  onClick={() => {
                    setIsCreateNew(false);
                    setInputError("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  style={buttonStyle}
                  onClick={handleCreateNew}
                >
                  Create New
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                padding: "1rem 0",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button color="warning" style={buttonStyle} onClick={onUpdate}>
                Overwrite
              </Button>
              <Button
                color="success"
                style={buttonStyle}
                onClick={() => setIsCreateNew(true)}
              >
                Create New
              </Button>
            </div>
          )}
        </SweetAlert>
      </>
    );
  }
);
