import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  APPROVED,
  EMAIL_ALREADY_EXISTS,
  MOBILE_USER,
  ORGANISATION_OWNER,
  PENDING,
  PLATFORM_OWNER,
  TYPE_ASSIGNMENT_SUBSCRIPTION_PLAN_LIMIT_EXCEEDED,
  USER_CREATION_ERROR,
  WEB_USER,
} from "common/constants";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  getOrgFromDb,
  addAdminNotificationsToDb,
  updateActiveJobAccess,
  updateArchivedJobAccess,
  updateDeletedJobAccess,
  getUser,
  dataFromSnapshot,
  getUserById,
  orgInvitationEmailNotificationToUser,
} from "services";
import "../../common/styles.css";
import firebase from "config/firebase";
import cuid from "cuid";
import { updateInspectionJobAccess } from "services/inspectionService";
import { createNewUser } from "pages/jobs/jobCreation/createNewUser";
import generator from "generate-password-browser";
import { toast } from "react-toastify";
export default function CreateUser({
  showUserMenu,
  setShowUserMenu,
  subscription,
}) {
  const { orgUsers } = useSelector(state => state.orgUsers);
  const [activeWebUsersCount, setActiveWebUsersCount] = useState(0);
  const [activeMobileUsersCount, setActiveMobileUsersCount] = useState(0);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [type, setType] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const { currentUserProfile } = useSelector(state => state.profile);
  const db = firebase.firestore();

  useEffect(() => {
    const webUsersCount = orgUsers.filter(
      orgUser =>
        (orgUser?.type === WEB_USER && orgUser?.orgStatus === APPROVED) ||
        orgUser?.type === ORGANISATION_OWNER
    )?.length;
    setActiveWebUsersCount(webUsersCount);

    const mobileUsersCount = orgUsers.filter(
      orgUser => orgUser?.type === MOBILE_USER && orgUser?.orgStatus === APPROVED
    )?.length;
    setActiveMobileUsersCount(mobileUsersCount);
  }, [orgUsers]);

  const isWebUsersLimitExceeded = () => {
    return (
      type === WEB_USER && subscription?.webUsersCount < activeWebUsersCount + 1
    );
  };

  const isMobileUsersLimitExceeded = () => {
    return (
      type === MOBILE_USER &&
      subscription?.mobileUsersCount < activeMobileUsersCount + 1
    );
  };
  const getUserInfo = async email => {
    const snapshot = await getUser(email);
    if (!snapshot.empty) {
      const docs = snapshot.docs.map(doc => dataFromSnapshot(doc));
      return docs[0];
    }
    return null;
  };
  const onSave = async () => {
    try {
      await db
        .runTransaction(async txn => {
          setIsSaveButtonDisabled(false);
          let user = await getUserInfo(email);
          if (user?.type === PLATFORM_OWNER) {
            setShowUserMenu(false);
            return {
              messageType: "error",
              message: "Cannot add Platform Owner to the organisation!",
            };
          }
          const isUserPartOfDifferentOrg =
            user?.orgAccessId !== subscription?.orgId;
          const isUserPartOfSameOrg = user?.orgAccessId === subscription?.orgId;
          if (isWebUsersLimitExceeded() || isMobileUsersLimitExceeded()) {
            return setError(TYPE_ASSIGNMENT_SUBSCRIPTION_PLAN_LIMIT_EXCEEDED);
          }
          let password;
          if (!user) {
            password = user
              ? null
              : generator.generate({
                  length: 10,
                  numbers: true,
                });
            const newUser = {
              email: email,
              photoURL: null,
              registered: true,
              orgAccessId: subscription.orgId,
              orgStatus: APPROVED,
              type: type,
              firstName: firstName,
              lastName: lastName,
            };
            const userSnapshot = await getUser(email);

            const userInfo = await getUserInfo(email);

            const result = await createNewUser(newUser, password);

            console.log("result=>", result);

            if (result.status === 'error') {
              setShowUserMenu(false);
              return {
                messageType: "error",
                message: result.message,
              };
            }
          }
          if (!user?.id) {
            user = await getUserInfo(email);
          }
          if (
            user &&
            isUserPartOfDifferentOrg &&
            user?.orgStatus === APPROVED
          ) {
            setShowUserMenu(false);
            return {
              messageType: "error",
              message: "User already part of other organisation",
            };
          }
          if (user && isUserPartOfSameOrg && user?.orgStatus === APPROVED) {
            setShowUserMenu(false);
            return {
              messageType: "error",
              message: "User already exists.",
            };
          }
          if (user && (!user?.orgAccessId || user?.orgStatus === PENDING)) {
            const userRef = getUserById(user?.id);

            const doc = await txn.get(userRef);
            const userData = doc.data();

            const userInfo = {
              ...userData,
              orgAccessId: subscription.orgId,
              orgStatus: APPROVED,
              type: type,
              firstName: firstName,
              lastName: lastName,
            };
            txn.update(userRef, userInfo);
            await updateUserInOrgAndJobs(user, password);
            setShowUserMenu(false);
            return {
              messageType: "success",
              message: `User added to organisation as ${type} user`,
            };
          }
          if (
            user?.orgAccessId === subscription?.orgId &&
            user?.orgStatus === APPROVED
          ) {
            setShowUserMenu(false);
            return {
              messageType: "warning",
              message: `User already exists as ${type} user`,
            };
          }
          setShowUserMenu(false);
          return;
        })
        .then(result => {
          if (result?.messageType === "success") {
            toast.success(result?.message, { autoClose: 2000 });
            return;
          }
          if (result?.messageType === "warning") {
            toast.warn(result?.message, { autoClose: 2000 });
            return;
          }
          if (result?.messageType === "error") {
            toast.error(result?.message, { autoClose: 2000 });
            return;
          }
        });
    } catch (error) {
      console.log("Failed to fetch user", error);
    }
  };
  const updateUserInOrgAndJobs = async (user, password) => {
    const promises = [];
    updateActiveJobAccess(user.id, currentUserProfile.orgAccessId);
    updateArchivedJobAccess(user.id, currentUserProfile.orgAccessId);
    updateDeletedJobAccess(user.id, currentUserProfile.orgAccessId);
    updateInspectionJobAccess(user.id, currentUserProfile.orgAccessId);
    const snapshot = await getOrgFromDb(subscription?.orgId);
    const { name: orgName } = snapshot.data();
    const notification = {
      id: cuid(),
      jobId: null,
      title: orgName,
      isRead: false,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      type: "admin",
      description: `${
        currentUserProfile?.displayName ||
        currentUserProfile?.firstName + currentUserProfile?.lastName
      } added to ${orgName} as ${type} user`,
      recipientId: user.id,
      actionType: "join-organisation",
    };
    addAdminNotificationsToDb([notification]);

    promises.push(
      orgInvitationEmailNotificationToUser(
        orgName,
        {
          email: user.email,
          displayName: user?.displayName || user?.firstName + user?.lastName,
          type: type === WEB_USER ? "Web" : "Mobile",
          password,
        },
        {
          email: currentUserProfile?.email,
          displayName:
            currentUserProfile?.displayName ||
            currentUserProfile?.firstName + currentUserProfile?.lastName,
        }
      )
    );
    await Promise.all(promises);
  };
  return (
    <Modal isOpen={showUserMenu} toggle={() => setShowUserMenu(!showUserMenu)}>
      <ModalHeader toggle={() => setShowUserMenu(!showUserMenu)} tag="h4">
        {"User Details"}
      </ModalHeader>

      <ModalBody>
        <Row form>
          <Col xs={12}>
            <div style={{ display: "flex" }}>
              <div
                className="mb-3"
                style={{ width: "50%", marginRight: "10px" }}
              >
                <Label>First Name</Label>
                <Input
                  data-testid="name-input"
                  name="name"
                  type="text"
                  onChange={e => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "50%" }}>
                <Label>Last Name</Label>
                <Input
                  data-testid="name-input"
                  name="name"
                  type="text"
                  onChange={e => {
                    setLastName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <Label>Email</Label>
              <Input
                data-testid="email-input"
                name="email"
                type="email"
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
            </div>

            <div className="mb-3">
              <Label>Type</Label>
              <FormControl fullWidth size="small">
                <InputLabel>Type</InputLabel>
                <Select
                  data-testid="type-select"
                  label="Type"
                  onChange={event => {
                    setError("");
                    setType(event.target.value);
                  }}
                >
                  <MenuItem value={WEB_USER}>Web</MenuItem>
                  <MenuItem value={MOBILE_USER}>Mobile</MenuItem>
                </Select>
              </FormControl>
            </div>
            {error && <Alert severity="error">{error}</Alert>}

            <div className="mb-3"></div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-success save-user"
                disabled={
                  !(
                    email &&
                    type &&
                    isSaveButtonDisabled &&
                    !error &&
                    firstName?.trim() &&
                    lastName?.trim()
                  )
                }
                onClick={onSave}
              >
                Save
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
