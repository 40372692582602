import axios from "axios";
import { CLOUD_FUNCTION_URL, PENDING } from "common/constants";
import React, { useState } from "react";
import { Card, CardBody, Col, Media, UncontrolledAlert } from "reactstrap";
import {
  addAdminNotificationsToDb,
  dataFromSnapshot,
  getOrgOwner,
  isorgCodeExists,
  updateUserProfile,
} from "services";
import firebase from "config/firebase";
import cuid from "cuid";
const CardCopoun = ({ currentUserProfile }) => {
  const db = firebase.firestore();
  const [orgCode, setCode] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [processing, setProcessing] = useState(false);

  const sendEmailToOrgOwnerForOrgApproval = async orgId => {
    try {
      const snapshot = await getOrgOwner(orgId);
      const docs = snapshot.docs.map(doc => dataFromSnapshot(doc));
      if (!docs || !docs.length) {
        throw "Org owner details not found";
      }

      const orgOwner = docs[0];

      const notification = {
        id: cuid(),
        description: `${currentUserProfile.displayName} requested to join your Organisation.`,
        jobId: null,
        title: `Hello ${orgOwner.displayName}`,
        isRead: false,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        type: "admin",
        recipientId: orgOwner.id,
        actionType: "request-to-join",
      };
      addAdminNotificationsToDb([notification]);
      await axios.post(`${CLOUD_FUNCTION_URL}/sendMailToRequestOrgApproval`, {
        orgOwner: {
          displayName: orgOwner.displayName,
          email: orgOwner.email,
        },
        requestedUser: {
          email: currentUserProfile.email,
          displayName: currentUserProfile.displayName,
        },
      });
    } catch (error) {
      console.log("-----error: ", error);
    }
  };

  const checkCopoun = async () => {
    setProcessing(true);
    setError(null);
    setMessage(null);
    try {
      const isValidOrgCode = await isorgCodeExists(orgCode);
      if (isValidOrgCode) {
        await updateUserProfile({
          ...currentUserProfile,
          orgAccessId: orgCode,
          orgStatus: PENDING,
        });
        await sendEmailToOrgOwnerForOrgApproval(orgCode);
        setMessage(
          "org code added successfully and email will be sent to org owner"
        );
      } else {
        setError("Please enter a valid org code");
      }
    } catch (e) {
      setError("Something went wrong. Try again later.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <React.Fragment>
      <Col xl="3" md="6">
        <Card className="plan-box">
          <CardBody className="p-4">
            <Media>
              <Media body>
                <h5>{"Enter Your Organisation Code Here"}</h5>
                <input
                  className="form-control"
                  type="text"
                  value={orgCode}
                  disabled={message}
                  onChange={e => setCode(e.target.value)}
                />
              </Media>
            </Media>
            <br />

            <div className="text-center">
              <button
                className="btn btn-primary btn-sm"
                disabled={!orgCode || processing}
                onClick={checkCopoun}
              >
                {"Submit"}
              </button>
            </div>
            <br />
            {error && (
              <UncontrolledAlert color="danger" role="alert">
                {error}
              </UncontrolledAlert>
            )}
            {message && (
              <UncontrolledAlert color="success" role="alert">
                {message}
              </UncontrolledAlert>
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CardCopoun;
