export const FETCH_DEFAULT_LISTITEM = "FETCH_DEFAULT_LISTITEM";
export const FETCH_CUSTOM_LISTITEM = "FETCH_CUSTOM_LISTITEM";
export const FETCH_SAVED_CHECKLIST = "FETCH_SAVED_CHECKLIST";

export const FETCH_DEFAULT_LISTITEM_SUCCESS = "FETCH_DEFAULT_LISTITEM_SUCCESS";
export const FETCH_DEFAULT_LISTITEM_FAILURE = "FETCH_DEFAULT_LISTITEM_FAILURE";

export const FETCH_CUSTOM_LISTITEM_SUCCESS = "FETCH_CUSTOM_LISTITEM_SUCCESS";
export const FETCH_CUSTOM_LISTITEM_FAILURE = "FETCH_CUSTOM_LISTITEM_FAILURE";

export const FETCH_SAVED_CHECKLIST_SUCCESS = "FETCH_SAVED_CHECKLIST_SUCCESS";
export const FETCH_SAVED_CHECKLIST_FAILURE = "FETCH_SAVED_CHECKLIST_FAILURE";