import React from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CardCopoun from "./CardCopoun";
import CardSubscription from "./CardSubscription";

const UserOnboadingForOrg = () => {
  const { currentUserProfile } = useSelector(state => state.profile);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Organisation | Industry Best</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Organisation"
            breadcrumbItem="Organisation Details"
          />

          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-5">
                <h4>Add Your Company/Organisation Unique Code</h4>
                <p className="text-muted">
                  You need to apply your organisation's code to participate in
                  Jobs. Please reach out to your Organisation Admin for the Same
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {currentUserProfile?.orgAccessId &&
            currentUserProfile?.orgStatus ? (
              <CardSubscription currentUserProfile={currentUserProfile} />
            ) : (
              <CardCopoun currentUserProfile={currentUserProfile} />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default UserOnboadingForOrg;
