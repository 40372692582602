import { Alert } from "@mui/material";
import { toast } from "react-toastify";
import dropLeft from "assets/images/icons8/drop-left.png";
import dragIcon from "assets/images/icons8/icons8-drag-and-drop-50.png";
import { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button, Input } from "reactstrap";
import { deleteListItemInDb } from "services";
import CheckListGroup from "./CheckListGroupUnit";

export default function ServiceCommandUnit({ itemSearch, myListItems }) {
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  const limit = 50;

  const ChecklistDeletionConfirmPrompt = ({
    setIsDeleteConfirmPromptOpen,
    item,
  }) => {
    const confirmationKeyword = "delete";
    const [keyword, setKeyword] = useState("");
    const [keywordError, setKeywordError] = useState(false);

    const onDeleteConfirm = async () => {
      if (keyword !== confirmationKeyword) {
        setKeywordError(true);
        return;
      }
      setKeywordError(false);
      setIsDeleteConfirmPromptOpen(false);
      try {
        await deleteListItemInDb(item.id, item.orgId);
        toast.success("The checklist-item is deleted!", {
          autoClose: 2000,
        });
      } catch (err) {
        toast.error("Failed to delete the checklist-item", {
          autoClose: 2000,
        });
      }
    };
    return (
      <SweetAlert
        title="Are you sure you want to permanently delete it?"
        warning
        showCancel
        confirmButtonText="Yes, delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={onDeleteConfirm}
        onCancel={() => {
          setIsDeleteConfirmPromptOpen(false);
        }}
      >
        <div>
          Please confirm deletion by entering the keyword "{confirmationKeyword}
          " in below shown box
        </div>
        <Input
          name="clientName"
          placeholder={`Enter "${confirmationKeyword}" to confirm deletion`}
          onChange={e => setKeyword(e.target.value)}
          style={{ margin: "5px" }}
        />
        {keywordError && (
          <Alert severity="error" style={{ padding: "0px 5px", margin: "5px" }}>
            You didn't enter the keyword correctly
          </Alert>
        )}
      </SweetAlert>
    );
  };

  const DisplayChecklistItem = ({ item, index }) => {
    const [isDeleteConfirmPromptOpen, setIsDeleteConfirmPromptOpen] =
      useState(false);

    return (
      <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
        {(provided, snapshot) => (
          <div>
            <div ref={provided.innerRef} {...provided.draggableProps}>
              <CheckListGroup>
                <span {...provided.dragHandleProps}>
                  <img
                    src={dropLeft}
                    style={{
                      float: "left",
                      height: "30px",
                      marginRight: "5px",
                    }}
                  />
                  <span className="ml-2 mr-2">
                    <img
                      src={dragIcon}
                      style={{
                        float: "right",
                        height: "30px",
                      }}
                    />
                  </span>
                  {item.title || item.content}
                  <Button
                    size="sm"
                    color="link"
                    onClick={() => setIsDeleteConfirmPromptOpen(true)}
                  >
                    Delete
                  </Button>
                </span>
              </CheckListGroup>
            </div>
            {provided.placeholder}

            {isDeleteConfirmPromptOpen && (
              <ChecklistDeletionConfirmPrompt
                setIsDeleteConfirmPromptOpen={setIsDeleteConfirmPromptOpen}
                item={item}
              />
            )}
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <Droppable
      key={myListItems.length}
      droppableId="checklistitemsonly"
      type="droppableSubItem"
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className="checklist-items-list">
          {!itemSearch && myListItems.length > limit && (
            <p>
              {`Showing ${limit} of ${myListItems.length} list items. Refine your search`}
            </p>
          )}
          {itemSearch && myListItems.length == 0 && (
            <p>No matching results found. Refine your search and try again.</p>
          )}
          {itemSearch &&
            myListItems.length > 0 &&
            myListItems.length <= limit && (
              <p>{`${myListItems.length} ${
                myListItems.length > 1 ? "items" : "item"
              } found`}</p>
            )}
          {itemSearch && myListItems.length > limit && (
            <p>
              {`Showing ${limit} of ${myListItems.length} matching results. Refine your search`}
            </p>
          )}
          <div className="scrollable-checklist">
          {myListItems.slice(0, limit).map((item, index) => (
            <DisplayChecklistItem item={item} index={index} />
          ))}
            {provided.placeholder}
            </div>
        </div>
      )}
    </Droppable>
  );
}
