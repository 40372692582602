export const PLATFORM_OWNER = "platformOwner";
export const ORGANISATION_OWNER = "orgOwner";
export const WEB_USER = "web";
export const MOBILE_USER = "mobile";

export const PENDING = "Pending";
export const APPROVED = "Approved";

export const INACTIVE = "Inactive";
export const ACTIVE = "Active";

export const DATE_FORMAT = "dd-MMM-yyyy";

export const ORG_ERROR_MESSAGE =
  "Orgnisation name already existed, Please enter diffent orgnisation name";

export const CLOUD_FUNCTION_URL = process.env.REACT_APP_CLOUD_FUNCTION_URL;

export const TYPE_ASSIGNMENT_SUBSCRIPTION_PLAN_LIMIT_EXCEEDED =
  "Type assignment exceeding existing plan limit";
export const MOBILE_USER_LOGIN_ERROR_MESSAGE =
  "Web Application access denied. Please contact administrator";
export const USER_DO_NOT_HAVE_ANY_ACTIVE_LICENSE =
  "don't have any active license";
export const SUBCRIPTION_EXPIRY_LOGIN_ERROR_MESSAGE =
  "No active plan found. Please contact administrator";

export const USER_ALREADY_EXISTS =
"Email entered already signed-up in other Organisation. Please remove the user access first";
export const EMAIL_ALREADY_EXISTS = "Email entered already exists";

export const INVALID_EMAIL = "Email address is invalid"

export const INTERNAL = "Internal";
export const EXTERNAL = "External";
export const BELOW_GROUND = "Belowground";

export const RESIDENTIAL_CATEGORY = "RESIDENTIAL";
export const COMMERCIAL_CATEGORY = "COMMERCIAL";
export const INFRASTRUCTURE_CATEGORY = "INFRASTRUCTURE";

export const INTERNAL_TYPE = "internal";
export const EXTERNAL_TYPE = "external";
export const BELOW_GROUND_TYPE = "belowground";

export const COMPLAINCE = "Compliance";
export const JOB_REPORT = "JobReport";
export const INSPECTION = "Inspection";
export const RESIDENTIAL = "residential";
export const COMMERCIAL = "commercial";
export const INDUSTRIAL = "industrial";

export const RESIZED_IMAGES_DIMENSIONS = "500x500";
export const RESIZED_IMAGES_PATH = "resizedImages";
export const JOB_NAME = "jobname";
export const JOB_NUMBER = "jobno";
export const CUSTOM_JOB_NUMBER = "customjobno";
export const JOB_ADDRESS = "jobaddress";
export const CLIENT_NAME = "client__displayname";
export const PARTICIPANT_NAME = "participantName";

export const NO_OF_JOBS_PER_PAGE = 10;

export const USERS_DB_NAME = "users";

export const ACTIVE_JOBS_DB_NAME = "jobs";
export const ARCHIVED_JOBS_DB_NAME = "archived";
export const DELETED_JOBS_DB_NAME = "deleted";
export const DRAFTS_DB_NAME = "drafts";
export const INSPECTION_DB_NAME = "inspection";
export const ACCESS_CHANGE = "access";
export const ROLE_CHANGE = "role";
export const ADD_EXISTING_USER = "add-existing-user";
export const ADD_INVITED_USER = "add-invited-user";
export const REMOVE_USER = "remove-user";

export const EXISTING_USER_INVITE = "added";
export const INVITED_USER_INVITE = "invited";

export const CLONED_CHECKLIST_TEXT = "cloned-checklist";

export const FLOOR = "floor";
export const IS_FLOOR_CLONING_ENABLED = true;
export const USER_CREATION_ERROR = "User Creation failed";

export const PLACE_REPORT_END = "At the end of the report";
export const PLACE_REPORT_START = "At the start of the report";

export const PLACE_IN_CHECKLIST = "In the checklist";
export const PLACE_BEFORE_CHECKLIST_ITEM = "Before checklist item";
export const PLACE_AFTER_CHECKLIST_ITEM = "After checklist item";

export const AVATAR_URL =
  "https://ui-avatars.com/api/?size=150&background=random&color=fff";

export const WORKING_DATES_COLLECTION = "workingdates";

export const CHEKCLIST_COLLECTION = "checklists";

export const TERMS_AND_CONDITIONS_URL_PATH =
  "ibApplicationTermsAndConditions/Industry Best Construction Terms and Conditions.pdf";
