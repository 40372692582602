import TextField from "@mui/material/TextField";
import photoReq from "assets/images/icons8/icons8-camera-40.png";
import crossMark from "assets/images/icons8/icons8-gray-cross.png";
import noSigReq from "assets/images/icons8/icons8-no-edit-40.png";
import noPhotoReq from "assets/images/icons8/icons8-no-photo-req-40.png";
import dragReorder from "assets/images/icons8/icons8-scroll.png";
import sigReq from "assets/images/icons8/icons8-signature-40.png";
import { getItemStyle, getListStyle } from "common/checklistUtils/dndStyleUtil";
import { memo, useEffect, useMemo, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import "./ChecklistUnit.scss";
import { Loading } from "mdi-material-ui";
import { Loader } from "react-bootstrap-typeahead";

function ServiceCommandUnit(props) {
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.subItems?.length > 30) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    }else{
      setLoading(false)
    }
  }, []);
  return (
    <Droppable droppableId={`${props.type}`} type={`${props.allowed}`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {props.subItems.map((item, index) => {
            return (
              <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    className="checklist-item-unit"
                  >
                    <span>
                      <img
                        onClick={() => {
                          props.onDelete(item.id);
                          props.setDirty(true);
                        }}
                        src={crossMark}
                        style={{
                          float: "left",
                          height: "13px",
                          marginRight: "6px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                    <span className="cheklist-item-unit-index">
                      {`${props.mainIndex}.${index + 1}  `}
                    </span>
                    
                    {loading ? <Loader /> :
                      <CheckListItemField item={item} props={props} />
                      }
                    <span
                      className="checklist-item-actions"
                      {...provided.dragHandleProps}
                    >
                      {item.attachment && !item.holdPoint && (
                        <img
                          onClick={() =>
                            props.onChange(
                              { attachment: false },
                              item.id,
                              props.type
                            )
                          }
                          src={photoReq}
                          style={{
                            marginRight: "12px",
                            marginLeft: "5px",
                            padding: "6px",
                            height: "30px",
                            transform: "translate(0, -5px)",
                            cursor: "pointer",
                          }}
                        />
                      )}
                      {!item.attachment && !item.holdPoint && (
                        <img
                          onClick={() => {
                            props.onChange(
                              { attachment: true },
                              item.id,
                              props.type
                            );
                            props.setDirty(true);
                          }}
                          src={noPhotoReq}
                          style={{
                            marginRight: "12px",
                            marginLeft: "5px",
                            padding: "6px",
                            height: "30px",
                            transform: "translate(0, -5px)",
                            cursor: "pointer",
                          }}
                        />
                      )}
                      {item.signature && item.holdPoint && (
                        <img
                          onClick={() => {
                            props.onChange(
                              { signature: false },
                              item.id,
                              props.type
                            );
                            props.setDirty(true);
                          }}
                          src={sigReq}
                          style={{
                            marginRight: "12px",
                            marginLeft: "5px",
                            padding: "6px",
                            height: "30px",
                            transform: "translate(0, -5px)",
                            cursor: "pointer",
                          }}
                        />
                      )}
                      {!item.signature && item.holdPoint && (
                        <img
                          onClick={() => {
                            props.onChange(
                              { signature: true },
                              item.id,
                              props.type
                            );
                            props.setDirty(true);
                          }}
                          src={noSigReq}
                          style={{
                            marginRight: "12px",
                            marginLeft: "5px",
                            padding: "6px",
                            height: "30px",
                            transform: "translate(0, -5px)",
                            cursor: "pointer",
                          }}
                        />
                      )}
                      <img
                        src={dragReorder}
                        style={{
                          height: "20px",
                          width: "10px",
                          marginLeft: "2px",
                          marginTop: "4px",
                          transform: "translate(0, -5px)",
                        }}
                      />
                    </span>
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default memo(ServiceCommandUnit)
const MemoizedTextField = memo(TextField);
const CheckListItemField = memo(({ item, props }) => {
  let content = useMemo(() => {
    return item.content
  }, [item.content])
  const [itemValue, setItemValue] = useState(content);
  return (
    <MemoizedTextField
      multiline={true}
      variant="standard"
      id="standard-textarea"
      placeholder="NEW CHECKLIST ITEM"
      className="cheklist-item-unit-name"
      style={{
        width: "100%",
        fontSize: "16px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
      }}
      error={Boolean(itemValue)}
      helperText={itemValue.trim() ? "" : "*Required"}
      InputProps={{ disableUnderline: true }}
      value={itemValue}
      onChange={e => {
        const text = e.target.value;
        setItemValue(text);
        if (text) {
          props.onChange(
            { content: text, isValidItem: true },
            item.id,
            props.type
          );
        } else {
          props.onChange({ isValidItem: false }, item.id, props.type);
          props.setIsValidChecklist(false);
        }
        props.setDirty(true);
      }}
    />
  );
});
