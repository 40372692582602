import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Form,
  Container,
} from "reactstrap";

import JobPhotoUploader from "pages/userProfile/JobPhotoUploader";

export default function UploadJobAvatar({ editAvatar, setEditAvatar, jobId,collectionName }) {
  const [selectedFiles, setselectedFiles] = useState([]);;
  const onDrop = useCallback(acceptedFiles => {
    setselectedFiles([]);
    handleAcceptedFiles(acceptedFiles);
  });
  const { getRootProps, getInputProps,isDragReject } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [
        ".jpeg",
        ".png",
        ".jpg",
        ".JPEG",
        ".PNG",
        ".JPG",
      ],
    },
  });
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        percent: 0,
        downloadUrl: '',
        id: '',
      })
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const goClear = () => {
    setEditAvatar(false);
    setselectedFiles([]);
  };
  return (
    <Modal isOpen={editAvatar}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit Job Site Profile
        </h5>
        <button
          type="button"
          onClick={goClear}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Form>
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        {isDragReject ? (
                          <h4>Sorry,Please upload valid file format.</h4>
                        ) : (
                          <>
                            <h4>Drop files here or click to upload.</h4>
                            <p style={{ fontSize: "12px" }}>
                              Only jpeg, jpg, png formats supported.
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.length > 0 && (
                        <JobPhotoUploader
                          file={selectedFiles[0]}
                          jobId={jobId}
                          goClear={goClear}
                          collectionName={collectionName}
                        />
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Modal>
  );
}
