import {
  CLOUD_FUNCTION_URL,
  EMAIL_ALREADY_EXISTS,
  USER_CREATION_ERROR,
  INVALID_EMAIL,
} from "common/constants";
import axios from "axios";
import { getUserInfo } from "./Finalize";

export async function createNewUser(participant, password) {
  try {
    const user = {
      email: participant.email.toLowerCase(),
      role: participant?.role?.label || "",
      password,
      firstName: participant?.firstName?.trim() || "",
      lastName: participant?.lastName?.trim() || "",
    };
    const resp = await axios.post(`${CLOUD_FUNCTION_URL}/createUser`, user);
    if (resp.status === 200) {
      const user = await getUserInfo(participant.email.toLowerCase());
      console.log("User check", user);
      return user.id;
    }
  } catch (err) {
    if (err?.response?.data?.code === "auth/email-already-exists") {
      return {
        status: "error",
        message: `Failed to add User. ${EMAIL_ALREADY_EXISTS}`,
      };
    } else if (err?.response?.data?.code === "auth/invalid-email") {
      return {
        status: "error",
        message: INVALID_EMAIL,
      };
    } else {
      return {
        status: "error",
        message: USER_CREATION_ERROR,
      };
    }
  }

  return null;
}
