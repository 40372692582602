import CloseIcon from "@mui/icons-material/Close";

import { Checkbox, IconButton, Typography } from "@mui/material";
import dropLeft from "assets/images/icons8/drop-left.png";
import dragIcon from "assets/images/icons8/icons8-drag-and-drop-50.png";
import { useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Input, Modal } from "reactstrap";
import CheckListGroup from "../jobs/jobDetails/rooms/checklist/CheckListGroupUnit";
import "../jobs/jobCreation/jobCategory.css";
export default function ServiceCommandUnit({
  checklistSearch,
  myChecklist,
  showCheckBox,
  setMyChecklist,
}) {
  const limit = 10;
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const ShowChecklistDetails = () => {
    const DisplayChecklistItems = () => {
      return (
        <>
          {selectedChecklist?.items?.map((item, idx) => {
            const sectionNo = idx + 1;
            return (
              <>
                <Typography
                  style={{ fontFamily: "Poppins", fontStyle: "normal" }}
                >
                  {`${sectionNo}. ${
                    item?.content || item?.title || item?.name
                  }`}
                </Typography>
                {item?.description ? (
                  <>
                    <Typography
                      variant="subtitle"
                      style={{ fontFamily: "Poppins", fontStyle: "normal" }}
                    >
                      {item?.description}
                    </Typography>
                  </>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "20px",
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                >
                  {item?.subItems?.map((subItem, jdx) => {
                    const itemNo = jdx + 1;
                    return (
                      <Typography
                        variant="subtitle"
                        style={{ fontFamily: "Poppins", fontStyle: "normal" }}
                      >
                        {`${sectionNo}.${itemNo} ${
                          subItem?.title || subItem?.content
                        }`}
                      </Typography>
                    );
                  })}
                </div>
              </>
            );
          })}
        </>
      );
    };
    return (
      <Modal isOpen={isModalOpen} size="l" scrollable={true}>
        <div className="modal-header">
          <Typography
            variant="h6"
            className="modal-title mt-0 defaultFont"
            style={{
              textTransform: "capitalize",
              color: "#65a2eb",
              fontFamily: "Poppins",
              fontStyle: "normal",
            }}
          >
            {selectedChecklist?.title || selectedChecklist?.templateName}
          </Typography>
          <IconButton
            onClick={() => {
              setModalOpen(false);
              setSelectedChecklist(null);
            }}
          >
            <CloseIcon style={{ color: "#D57D7D" }} />
          </IconButton>
        </div>

        <div className="modal-body">
          <DisplayChecklistItems />
        </div>

        <div className="modal-footer">
          <Button
            outline
            color="primary"
            onClick={() => {
              setModalOpen(false);
              setSelectedChecklist(null);
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  };

  const DisplayChecklist = ({ item, index }) => {
    return (
      <Draggable
        key={item?.id}
        draggableId={`${item?.id}`}
        index={index}
        isDragDisabled={showCheckBox}
      >
        {(provided, snapshot) => (
          <div>
            <div ref={provided?.innerRef} {...provided?.draggableProps}>
              <CheckListGroup>
                <span {...provided?.dragHandleProps}>
                  {showCheckBox && (
                    <Checkbox
                      className="checked"
                      checked={item?.checked}
                      style={{
                        float: "left",
                        height: "30px",
                        marginRight: "5px",
                        color: "#65a2eb",
                      }}
                      onChange={() => {
                        const newChecklist = [...myChecklist];
                        newChecklist[index].checked = !item.checked;
                        setMyChecklist(newChecklist);
                      }}
                    />
                  )}
                  <img
                    src={dropLeft}
                    style={{
                      float: "left",
                      height: "30px",
                      marginRight: "5px",
                    }}
                  />
                  <span className="ml-2 mr-2">
                    <img
                      src={dragIcon}
                      style={{
                        float: "right",
                        height: "30px",
                      }}
                    />
                  </span>

                  {item?.title || item?.name || item?.templateName}

                  <Button
                    size="sm"
                    color="link"
                    onClick={() => {
                      setSelectedChecklist(item);
                      setModalOpen(true);
                    }}
                  >
                    Show details
                  </Button>
                </span>
              </CheckListGroup>
            </div>
            {provided?.placeholder}
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <>
      <ShowChecklistDetails />
      <Droppable
        key={myChecklist?.length}
        droppableId="checklistonly"
        type="droppableItem"
      >
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className="checklist-saved-list">
            {!checklistSearch && myChecklist?.length > limit && (
              <p>
                {`Showing ${myChecklist?.length} checklists. Refine your search`}
              </p>
            )}
            {checklistSearch && myChecklist?.length == 0 && (
              <p>
                No matching results found. Refine your search and try again.
              </p>
            )}
            {checklistSearch &&
              myChecklist?.length > 0 &&
              myChecklist?.length <= limit && (
                <p>
                  {`${myChecklist?.length} ${
                    myChecklist?.length > 1 ? "items" : "item"
                  } found`}
                </p>
              )}
            {checklistSearch && myChecklist?.length > limit && (
              <p>
                {`Showing ${myChecklist?.length} checklists. Refine your search`}
              </p>
            )}
            <div className="scrollable-checklist">
              {myChecklist?.map((item, index) => (
                <DisplayChecklist key={index} item={item} index={index} />
              ))}
              {provided?.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </>
  );
}
