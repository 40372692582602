import { compareAsc } from "date-fns";
import firebase from "../config/firebase";

const MISSING_ATTACHMENT_FALLBACK_URL =
  process.env.REACT_APP_URL + "/missing-attachment-placeholder.png";

export const isValidEmail = email => {
  if (email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
};
export const isValideSubscription = subscription => {
  const currentDate = new Date(
    firebase.firestore.Timestamp.now().seconds * 1000
  );
  const subscriptionStartDate = new Date(subscription.startDate);
  const subscriptionEndDate = new Date(subscription.endDate);
  return (
    compareAsc(currentDate, subscriptionStartDate) == 1 &&
    compareAsc(subscriptionEndDate, currentDate) == 1
  );
};
export const currentUserHasFullAccess = (job, currentUserId) => {
  const [myAccess] = job.participants.filter(user => user.id === currentUserId);
  return myAccess?.access.value === "full";
};

export const getDownloadURL = imagePath => {
  const storage = firebase.storage();
  return new Promise(resolve => {
    storage
      .ref(imagePath)
      .getDownloadURL()
      .then(url => {
        resolve(url);
      })
      .catch(err => {
        resolve(MISSING_ATTACHMENT_FALLBACK_URL);
      });
  });
};

export const getBase64String = file => {
  let reader = new FileReader();
  return new Promise(resolve => {
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      resolve(base64String);
    };
  });
};
