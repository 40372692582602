// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yellow-dot {
  float: left;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 6px;
  height: 6px;
  width: 6px;
  border-radius: 50%; }

.yellow-dot {
  background-color: #f5c344; }
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/jobCreation/checklist/ChecklistNew.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,UAAU;EACV,kBAAkB,EAAA;;AAEpB;EAEE,yBAAyB,EAAA","sourcesContent":["%dot-style {\n  float: left;\n  cursor: pointer;\n  margin-right: 5px;\n  margin-top: 6px;\n  height: 6px;\n  width: 6px;\n  border-radius: 50%;\n}\n.yellow-dot {\n  @extend %dot-style;\n  background-color: #f5c344;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
