import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { AVATAR_URL, PLATFORM_OWNER } from "common/constants";
import AdressAutoComplete from "components/AddressAutoComplete/AddressAutoComplete";
import MuiPhoneNumber from "mui-phone-number";
import React, { useState } from "react";
import Editable from "react-bootstrap-editable";
import MetaTags from "react-meta-tags";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
import { showSignaturePad } from "store/actions";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { updateUserProfile } from "../../services/index";
import AttachmentCard from "./AttachmentInfoCard";
import "./styles.css";
import UploadAvatarMoadl from "./UploadAvatar";
import UploadFiles from "./UploadFiles";
import { userName, userProfileName } from "pages/platformManagement/org-creation/orgService";

export default function UserProfile(props) {
  const { currentUserProfile } = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const [editAvatar, setEditAvatar] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editAdreess, setEditAddress] = useState(false);
  const [phone, setPhone] = useState(currentUserProfile?.phone || "");
  const [address, setAddress] = useState(currentUserProfile?.address || "");

  const [error, setError] = useState(false);
  const handlePhoneNumberChange = (value, dialCode) => {
    if (value?.replace(`+${dialCode}`, '')?.length <= 10 && value?.replace(`+${dialCode}`, '')?.length > 4) {
      setError(false)
    } else {
      setError(true)
    }
    setPhone(value);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Industry Best</title>
        </MetaTags>
        {/* Render Breadcrumb */}
        <Breadcrumb title="Industry Best" breadcrumbItem="Profile" />
        <UserHeader profile={currentUserProfile} />
        <UploadAvatarMoadl
          editAvatar={editAvatar}
          setEditAvatar={setEditAvatar}
        />
        <Container
          className="mt--7"
          fluid
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            marginTop: "-6rem",
          }}
        >
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          setEditAvatar(true);
                        }}
                      >
                        <img
                          alt="..."
                          className="rounded-circle"
                          style={{
                            maxWidth: 180,
                            borderRadius: ".375rem",
                            transform: "translate(-50%,-30%)",
                            position: "absolute",
                            left: "50%",
                            transition: "all .15s ease",
                          }}
                          src={
                            currentUserProfile?.photoURL ||
                            `${AVATAR_URL}&name=${userProfileName(currentUserProfile)}`
                          }
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader
                  className="text-center pt-8 pt-md-4 pb-0 pb-md-4"
                  style={{
                    borderRadius: "calc(.375rem - 1px) calc(.375rem - 1px) 0 0",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Button
                      className="mr-4"
                      color="link"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setEditAvatar(true);
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <div className="text-center margin-top-avatar">
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      {currentUserProfile?.email}
                    </div>
                  </div>
                </CardBody>
              </Card>
              {currentUserProfile?.type != PLATFORM_OWNER && (
                <Card>
                  <CardBody>
                    <CardTitle className="mt-0">Signature</CardTitle>
                  </CardBody>
                  {currentUserProfile?.signURL && (
                    <CardImg
                      className="img-fluid"
                      src={currentUserProfile?.signURL}
                      alt="Your Signature"
                    />
                  )}
                  <CardBody>
                    <Link
                      to="#"
                      className="card-link"
                      onClick={() => dispatch(showSignaturePad())}
                    >
                      New Signature
                    </Link>
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="shadow ">
                <CardHeader
                  className="bg-white"
                  style={{
                    borderRadius: "calc(.375rem - 1px) calc(.375rem - 1px) 0 0",
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      {/* <Row>
                        <Col lg="6" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-display-name"
                          >
                            Display Name
                          </label>
                          {currentUserProfile?.displayName?.trim()?.length >
                          0 ? (
                            <div>{currentUserProfile?.displayName?.trim()}</div>
                          ) : (
                            <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Edit"
                              id={null}
                              initialValue={currentUserProfile?.displayName}
                              isValueClickable={
                                !!currentUserProfile?.displayName
                              }
                              mode="inline"
                              onSubmit={value => {
                                if (value) {
                                  updateUserProfile({
                                    ...currentUserProfile,
                                    displayName: value,
                                  });
                                }
                              }}
                              onValidated={null}
                              options={null}
                              renderCancelElement={null}
                              renderConfirmElement={null}
                              showText
                              type="textfield"
                              validate={value => {
                                if (value === null || value?.length === 0) {
                                  return "Please enter your Display Name";
                                }
                              }}
                            />
                          )}
                        </Col>
                      </Row> */}
                      <Row>
                        <Col lg="6" md="6">
                          <FormGroup>
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            {currentUserProfile?.firstName?.trim()?.length >
                            0 ? (
                              <div>{currentUserProfile?.firstName?.trim()}</div>
                            ) : (
                              <>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={currentUserProfile?.firstName?.trim()}
                                  isValueClickable={
                                    !!currentUserProfile?.firstName?.trim() &&
                                    currentUserProfile?.firstName?.trim() === ""
                                  }
                                  mode="inline"
                                  onSubmit={value => {
                                    if (value?.trim() !== "") {
                                      updateUserProfile({
                                        ...currentUserProfile,
                                        firstName: value?.trim(),
                                      });
                                    }
                                  }}
                                  onValidated={null}
                                  options={null}
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText
                                  type="textfield"
                                  validate={value => {
                                    if (value === null || value?.length === 0) {
                                      return "Please enter your First Name";
                                    }
                                  }}
                                />
                              </>
                            )}
                            {!currentUserProfile?.firstName && (
                              <small className="requiredError">
                                {"*Required"}
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>

                            {currentUserProfile?.lastName?.trim()?.length >
                            0 ? (
                              <div>{currentUserProfile?.lastName?.trim()}</div>
                            ) : (
                              <>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={currentUserProfile?.lastName?.trim()}
                                  isValueClickable={
                                    !!currentUserProfile?.lastName?.trim() &&
                                    currentUserProfile?.lastName?.trim() === ""
                                  }
                                  mode="inline"
                                  onSubmit={value => {
                                    if (value?.trim() !== "") {
                                      updateUserProfile({
                                        ...currentUserProfile,
                                        lastName: value?.trim(),
                                      });
                                    }
                                  }}
                                  onValidated={null}
                                  options={null}
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText
                                  type="textfield"
                                  validate={value => {
                                    if (value === null || value?.length === 0) {
                                      return "Please enter your Last Name";
                                    }
                                  }}
                                />
                              </>
                            )}
                            {!currentUserProfile?.lastName && (
                              <small className="requiredError">
                                {"*Required"}
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="8" lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-mobile"
                          >
                            Mobile
                          </label>
                          {editPhone ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "280px",
                              }}
                            >
                              <MuiPhoneNumber
                                name="phone"
                                label="Mobile"
                                defaultCountry={"au"}
                                variant="outlined"
                                className="phoneNumber"
                                value={currentUserProfile?.mobile || ""}
                                size="small"
                                fullWidth
                                onChange={(text,country) => handlePhoneNumberChange(text,country?.dialCode)}
                                autoFocus
                                autoFormat={false}
                                error={error}
                              />

                              <CheckOutlinedIcon
                                className="saveOrCancelButton"
                                style={{
                                  backgroundColor: "#34c38f",
                                }}
                                onClick={async () => {
                                  if(error===false){
                                    await updateUserProfile({
                                      ...currentUserProfile,
                                      mobile: phone === "+" ? "" : phone,
                                    });
                                    setEditPhone(false);
                                  }
                                }}
                              />

                              <CloseIcon
                                style={{
                                  backgroundColor: "#f46a6a",
                                }}
                                className="saveOrCancelButton"
                                onClick={() => {
                                  setPhone("");
                                  setEditPhone(false);
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <label
                                style={{
                                  color: "#50a5f1",
                                }}
                                onClick={() => setEditPhone(!editPhone)}
                              >
                                {currentUserProfile?.mobile || "Edit"}
                              </label>
                            </div>
                          )}
                          {phone?.length < 4 ? (
                          <small className="requiredError">{"*Required"}</small> )
                          : error && (
                          <small className="requiredError">{"*Invalid Phone number"}</small>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="8" lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-mobile"
                          >
                            Address
                          </label>
                          {editAdreess ? (
                            <AdressAutoComplete
                              address={address}
                              initialAddressValue={currentUserProfile?.address}
                              setAddress={setAddress}
                              setEditAddress={setEditAddress}
                              onSaveClickHandler={async () => {
                                await updateUserProfile({
                                  ...currentUserProfile,
                                  address,
                                });
                                setEditAddress(false);
                              }}
                            />
                          ) : (
                            <div>
                              <label
                                style={{
                                  color: "#50a5f1",
                                }}
                                onClick={() => setEditAddress(!editAdreess)}
                              >
                                {currentUserProfile?.address || "Edit"}
                              </label>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                    {currentUserProfile?.type != PLATFORM_OWNER && (
                      <div>
                        <hr className="my-4" />
                        {/* Description */}
                        <h6 className="heading-small text-muted mb-4">
                          About me
                        </h6>
                        <div className="pl-lg-4">
                          <FormGroup>
                            <label>About Me</label>
                            <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Edit"
                              id={null}
                              initialValue={currentUserProfile?.aboutMe}
                              isValueClickable={!!currentUserProfile?.aboutMe}
                              mode="inline"
                              onSubmit={value => {
                                if (value) {
                                  updateUserProfile({
                                    ...currentUserProfile,
                                    aboutMe: value,
                                  });
                                }
                              }}
                              onValidated={null}
                              options={null}
                              renderCancelElement={null}
                              renderConfirmElement={null}
                              showText
                              type="textarea"
                              validate={null}
                            />
                          </FormGroup>
                        </div>
                        <br />
                        <Row>
                          <Col lg="6" md="6">
                            <label
                              className="form-control-label"
                              htmlFor="input-user-abn"
                            >
                              Business/Company Number
                            </label>
                            <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Edit"
                              id={null}
                              initialValue={currentUserProfile?.abn}
                              isValueClickable={!!currentUserProfile?.abn}
                              mode="inline"
                              onSubmit={value => {
                                if (value && value?.length === 11) {
                                  updateUserProfile({
                                    ...currentUserProfile,
                                    abn: value || "",
                                  });
                                }
                              }}
                              onValidated={null}
                              options={null}
                              renderCancelElement={null}
                              renderConfirmElement={null}
                              showText
                              type="textfield"
                              validate={value => {
                                if (value === null || value?.length !== 11) {
                                  return "Please enter your 11 digit ABN number";
                                }
                              }}
                            />
                          </Col>
                          <Col lg="6" md="6">
                            <label
                              className="form-control-label"
                              htmlFor="input-user-abn"
                            >
                              User Type
                            </label>
                            <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Edit"
                              id={null}
                              initialValue={currentUserProfile?.userType}
                              isValueClickable={!!currentUserProfile?.userType}
                              mode="inline"
                              onSubmit={value => {
                                if (value) {
                                  updateUserProfile({
                                    ...currentUserProfile,
                                    userType: value,
                                  });
                                }
                              }}
                              onValidated={null}
                              options={[
                                "Company",
                                "Employee",
                                "Sole Trader",
                                "Sub Contractor",
                                "Other",
                              ]}
                              renderCancelElement={null}
                              renderConfirmElement={null}
                              showText
                              type="select"
                              validate={null}
                            />
                          </Col>
                        </Row>
                        <hr />
                        <div className="pl-lg-4">
                          <FormGroup>
                            <label>Insurance details</label>
                            {/* <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Edit"
                              id={null}
                              initialValue={currentUserProfile?.insuranceInfo}
                              isValueClickable={
                                !!currentUserProfile?.insuranceInfo
                              }
                              mode="inline"
                              onSubmit={value => {
                                if (value) {
                                  updateUserProfile({
                                    ...currentUserProfile,
                                    insuranceInfo: value,
                                  });
                                }
                              }}
                              onValidated={null}
                              options={null}
                              renderCancelElement={null}
                              renderConfirmElement={null}
                              showText
                              type="textarea"
                              validate={null}
                            /> */}
                          </FormGroup>
                          <br></br>
                          {currentUserProfile?.insuranceFiles?.map(file => {
                            return (
                              <AttachmentCard
                                file={file}
                                qualifier={"insuranceFiles"}
                              />
                            );
                          })}
                          <UploadFiles qualifier={"insuranceFiles"} />
                        </div>
                        <hr />
                        <div className="pl-lg-4">
                          <FormGroup>
                            <label>Career Info</label>
                            {/* <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Edit"
                              id={null}
                              initialValue={currentUserProfile?.careerInfo}
                              isValueClickable={
                                !!currentUserProfile?.careerInfo
                              }
                              mode="inline"
                              onSubmit={value => {
                                if (value) {
                                  updateUserProfile({
                                    ...currentUserProfile,
                                    careerInfo: value,
                                  });
                                }
                              }}
                              onValidated={null}
                              options={null}
                              renderCancelElement={null}
                              renderConfirmElement={null}
                              showText
                              type="textarea"
                              validate={null}
                            /> */}
                          </FormGroup>
                          <br></br>
                          {currentUserProfile?.careerFiles?.map(file => {
                            return (
                              <AttachmentCard
                                file={file}
                                qualifier={"careerFiles"}
                              />
                            );
                          })}
                          <UploadFiles qualifier={"careerFiles"} />
                        </div>
                        <hr />
                        <div className="pl-lg-4">
                          <FormGroup>
                            <label>Qualifications</label>
                            {/* <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Edit"
                              id={null}
                              initialValue={
                                currentUserProfile?.qualificationInfo
                              }
                              isValueClickable={
                                !!currentUserProfile?.qualificationInfo
                              }
                              mode="inline"
                              onSubmit={value => {
                                if (value) {
                                  updateUserProfile({
                                    ...currentUserProfile,
                                    qualificationInfo: value,
                                  });
                                }
                              }}
                              onValidated={null}
                              options={null}
                              renderCancelElement={null}
                              renderConfirmElement={null}
                              showText
                              type="textarea"
                              validate={null}
                            /> */}
                          </FormGroup>
                          {currentUserProfile?.qualificationFiles?.map(file => {
                            return (
                              <AttachmentCard
                                file={file}
                                qualifier={"qualificationFiles"}
                              />
                            );
                          })}
                          <UploadFiles qualifier={"qualificationFiles"} />
                        </div>
                        <hr />
                        <div className="pl-lg-4">
                          <FormGroup>
                            <label>C P D</label>
                            {/* <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Edit"
                              id={null}
                              initialValue={currentUserProfile?.cpdInfo}
                              isValueClickable={!!currentUserProfile?.cpdInfo}
                              mode="inline"
                              onSubmit={value => {
                                if (value) {
                                  updateUserProfile({
                                    ...currentUserProfile,
                                    cpdInfo: value,
                                  });
                                }
                              }}
                              onValidated={null}
                              options={null}
                              renderCancelElement={null}
                              renderConfirmElement={null}
                              showText
                              type="textarea"
                              validate={null}
                            /> */}
                          </FormGroup>
                          <br></br>
                          {currentUserProfile?.cpdFiles?.map(file => {
                            return (
                              <AttachmentCard
                                file={file}
                                qualifier={"cpdFiles"}
                              />
                            );
                          })}
                          <UploadFiles qualifier={"cpdFiles"} />
                        </div>
                        <hr />
                        <div className="pl-lg-4">
                          <FormGroup>
                            <label>Access Cards</label>
                            {/* <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Edit"
                              id={null}
                              initialValue={currentUserProfile?.accessCardInfo}
                              isValueClickable={
                                !!currentUserProfile?.accessCardInfo
                              }
                              mode="inline"
                              onSubmit={value => {
                                if (value) {
                                  updateUserProfile({
                                    ...currentUserProfile,
                                    accessCardInfo: value,
                                  });
                                }
                              }}
                              onValidated={null}
                              options={null}
                              renderCancelElement={null}
                              renderConfirmElement={null}
                              showText
                              type="textarea"
                              validate={null}
                            /> */}
                          </FormGroup>
                          <br></br>
                          {currentUserProfile?.accessCards?.map(file => {
                            return (
                              <AttachmentCard
                                file={file}
                                qualifier={"accessCards"}
                              />
                            );
                          })}
                          <UploadFiles qualifier={"accessCards"} />
                        </div>
                        <hr />
                        <div className="pl-lg-4">
                          <FormGroup>
                            <label>Licences</label>
                            {/* <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Edit"
                              id={null}
                              initialValue={currentUserProfile?.licenceInfo}
                              isValueClickable={
                                !!currentUserProfile?.licenceInfo
                              }
                              mode="inline"
                              onSubmit={value => {
                                if (value) {
                                  updateUserProfile({
                                    ...currentUserProfile,
                                    licenceInfo: value,
                                  });
                                }
                              }}
                              onValidated={null}
                              options={null}
                              renderCancelElement={null}
                              renderConfirmElement={null}
                              showText
                              type="textarea"
                              validate={null}
                            /> */}
                          </FormGroup>
                          <br></br>
                          {currentUserProfile?.licenceDocs?.map(file => {
                            return (
                              <AttachmentCard
                                file={file}
                                qualifier={"licenceDocs"}
                              />
                            );
                          })}
                          <UploadFiles qualifier={"licenceDocs"} />
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

const UserHeader = ({ profile }) => {
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "400px",
          backgroundImage: `url(${profile?.photoURL})`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
          position: "relative",
        }}
      >
        {/* Mask */}
        <span
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            transition: "all .15s ease",
            opacity: "0.9",
            background: "linear-gradient(87deg,#172b4d,#1a174d)",
          }}
        />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col>
              <h3 className="display-4 text-white">{userName(profile)}</h3>
              <p className="text-white mt-0 mb-5"></p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
