import {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_ERROR,
} from "./actionTypes";

const initialState = {
  documents: [],
  loading: false,
};

export default function documentsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_DOCUMENTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: payload,
      };
    default:
      return state;
  }
}
