import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import Modal from "react-modal";
import "../PdfStyle.scss";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { FormGroup, TextField, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import useProgressStore from "./zustandService/progressStore";

const {  setProgressValue } = useProgressStore.getState();

export function PdfInfo({ pdfUrl }) {
  setProgressValue(100)
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(0.7);
const [rotationAngle,setRotationAngle]=useState(0);
  const minScale=0.3;
  const maxScale =1.5;
  Modal.setAppElement("#root");
  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleZoomIn = () => {
    if (scale >= maxScale) {
      return;
    }
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    if (scale <= minScale) {
      return;
    }
    setScale(scale - 0.1);
  };
  const rotatePdfClockwise = () => {
    setRotationAngle(_rotationAngle => _rotationAngle + 90);
  }
  const rotatePdfCounterClockwise = () => {
    setRotationAngle(_rotationAngle => _rotationAngle - 90);
  }
  useEffect(() => {
    setCurrentPage(1);
  }, [pdfUrl]);

  return (
    <>
      <div
        className="text-center"
        style={{
          marginLeft: "10rem",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <button onClick={rotatePdfClockwise}  style={{
            backgroundColor: "white",
            border: "none",
            marginTop: "20px",
            marginRight: "2rem",
          }}>
          <RotateRightIcon   style={{ color: "black", height: "2rem", width: "2rem" }}/>
        </button>

        <button
          onClick={handleZoomIn}
          style={{
            backgroundColor: "white",
            border: "none",
            marginTop: "20px",
            marginRight: "2rem",
          }}
        >
          <ZoomInIcon
            style={{ color: "black", height: "2rem", width: "2rem" }}
          />
        </button>

        <FormGroup
          className="m-2"
          style={{ width: "4rem", height: "2.7rem", marginRight: "2rem" }}
        >
          <Box display="flex" alignItems="center">
            <TextField
              label="Page no."
              type="number"
              variant="standard"
              id="fullWidth margin-none"
              size="medium"
              value={currentPage || ""}
              onChange={e => {
                const enteredValue = Number(e.target.value);
                if (enteredValue >= 0 && enteredValue <= numPages) {
                  setCurrentPage(enteredValue);
                }
              }}
            />
            <span style={{ marginTop: "1rem" }}> /{numPages}</span>
          </Box>
        </FormGroup>
        <button
          onClick={handleZoomOut}
          style={{
            backgroundColor: "white",
            border: "none",
            marginTop: "1rem",
            marginLeft: "2rem",
          }}
        >
          <ZoomOutIcon
            style={{ color: "black", height: "2rem", width: "2rem" }}
          />
        </button>
        <button
          onClick={rotatePdfCounterClockwise}
          style={{
            backgroundColor: "white",
            border: "none",
            marginTop: "20px",
            marginRight: "2rem",
          }}
        >
          <RotateLeftIcon   style={{ color: "black", height: "2rem", width: "2rem" }}/>
        </button>
      </div>
      <div
        className="textPdf"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "3rem",
            width: "3rem",
            marginLeft: "4rem",
          }}
        >
          <ArrowBackIcon style={{ color: "black" }} />
        </button>
        <Document file={pdfUrl} onLoadSuccess={handleDocumentLoadSuccess}>
          <Page pageNumber={currentPage} scale={scale} rotate ={rotationAngle}/>
          <div
            className="text-center"
            style={{
              display: "flex",
              position: "fixed",
              bottom: "0px",
              width: "50%",
              justifyContent: "space-between",
            }}
          ></div>
        </Document>
        <button
          onClick={nextPage}
          disabled={currentPage === numPages}
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "3rem",
            width: "3rem",
            marginRight: "4rem",
          }}
        >
          <ArrowForwardIcon style={{ color: "black" }} />
        </button>
      </div>
    </>
  );
}

