// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  margin: 25px 0px !important; }

.invite-user-text {
  display: flex;
  justify-content: center; }

.invited-user-add-button {
  margin-top: 20px !important; }

.add-button {
  display: flex;
  justify-content: center; }

.custom-table-responsive {
  height: 310px;
  overflow-y: auto; }

.notes-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem; }

.notes-data {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/jobDetails/TeamMembers.scss"],"names":[],"mappings":"AAAA;EACE,2BAA2B,EAAA;;AAE7B;EACE,aAAa;EACb,uBAAuB,EAAA;;AAEzB;EACE,2BAA2B,EAAA;;AAE7B;EACE,aAAa;EACb,uBAAuB,EAAA;;AAGzB;EACE,aAAa;EACb,gBAAgB,EAAA;;AAGlB;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,mBAAmB;EACnB,qBAAqB,EAAA;;AAGvB;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,8BAA8B,EAAA","sourcesContent":[".divider {\n  margin: 25px 0px !important;\n}\n.invite-user-text {\n  display: flex;\n  justify-content: center;\n}\n.invited-user-add-button {\n  margin-top: 20px !important;\n}\n.add-button {\n  display: flex;\n  justify-content: center;\n}\n\n.custom-table-responsive{\n  height: 310px;\n  overflow-y: auto;\n}\n\n.notes-header{\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 0.2rem;;\n}\n\n.notes-data{\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
