import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Modal,
  Input,
} from "reactstrap";
//Import Breadcrumb
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CreateNewDocumentType from "./CreateNewDocumentType";
import { Form, Progress } from "reactstrap";
import { useDropzone } from "react-dropzone";
import firebase from "config/firebase";
import cuid from "cuid";
import {
  fetchDocumentListFromDb,
  fetchDocumentListsFromDb,
} from "services/documentService";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import {
  fetchDocuments,
  fetchDocumentsError,
  fetchDocumentsSuccess,
} from "store/actions";
import pdfLogo from "assets/images/pdflogo.png";
import PDFModal from "pages/jobs/jobDetails/rooms/PdfModal";
import { DownloadImage } from "pages/jobs/jobDetails/rooms/DownloadImage";
import { deleteImageFromFirebaseStoreByUrl } from "services";
import SweetAlert from "react-bootstrap-sweetalert";
import Lightbox from "react-18-image-lightbox";
import DownloadIcon from "@mui/icons-material/Download";
import {
  RESIZED_IMAGES_DIMENSIONS,
  RESIZED_IMAGES_PATH,
} from "common/constants";
import { getDownloadURL } from "common/utils";
import { toast } from "react-toastify";
import { userName } from "pages/platformManagement/org-creation/orgService";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationPromptWithCheckBoxAndLoading from "components/Common/DeleteConfirmationPromptWIthCheckBoxAndLoading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
const DocumentLists = () => {
  const { currentUserProfile } = useSelector(state => state.profile);
  const [showCreateNewDocument, setShowCreateNewDocument] = useState(false);
  const [isEditDocument, setEditDocument] = useState(false);
  const [document, setDocument] = useState({});
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const documentLibraryList = useSelector(state => state.documents);
  const [documentListId, setDocumentListId] = useState("");
  const [isPdf, setPdf] = useState(false);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [confirmAlertForAll, setConfirmAlertForAll] = useState(false);
  const [selectedAttachId, setSelectedAttachId] = useState(null);
  const [selectedDocType, setSelectedDocType] = useState(null);
  const [isGallery, setGallery] = useState(false);
  const db = firebase.firestore();
  const [checklistSearch, setChecklistSearch] = useState("");
  const [documents, setDocuments] = useState(documentLibraryList?.documents);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setPdf(false);
  };

  const handleShowCheckBoxes = () => {
    setShowCheckBox(_showCheckBox => !showCheckBox);
    setSelectedDocuments([]);
  };
  function handleSelectedDocuments(documentId) {
    if (selectedDocuments.includes(documentId)) {
      setSelectedDocuments(selectedDocuments.filter(id => id !== documentId));
      return;
    }
    setSelectedDocuments([...selectedDocuments, documentId]);
    return;
  }

  useFirestoreCollection({
    query: () => fetchDocumentListsFromDb(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchDocuments()),
    onSuccess: docs => dispatch(fetchDocumentsSuccess(docs)),
    onError: error => dispatch(fetchDocumentsError(error)),
    deps: [dispatch],
  });

  useEffect(() => {
    setDocuments(documentLibraryList?.documents);
  }, [documentLibraryList]);

  useEffect(() => {
    let newList = documentLibraryList?.documents?.filter(document => {
      const title = document.name;
      return title.toLowerCase().includes(checklistSearch.toLowerCase());
    });
    newList?.sort((a, b) => {
      const aStr = a.name;
      const bStr = b.name;
      return aStr.localeCompare(bStr);
    });
    setDocuments([...newList]);
  }, [checklistSearch, documentLibraryList]);

  const onDeletedAttachment = async (docType, attachmentId, documentListId) => {
    try {
      await db.runTransaction(async txn => {
        const orgRef = fetchDocumentListFromDb(
          currentUserProfile?.orgAccessId,
          documentListId
        );
        const doc = await txn.get(orgRef);
        const jobData = doc.data();
        let attachmentUrl;
        let resizedImagePath;
        let updatedDocuments = jobData.attachments.filter(attachment => {
          if (attachment?.id === attachmentId) {
            attachmentUrl = attachment?.url;
            resizedImagePath = attachment?.resizedImagePath;
          }
          return attachment.id !== attachmentId;
        });
        const documentUpdate = {
          attachments: updatedDocuments,
        };
        txn.update(orgRef, documentUpdate);
        await deleteImageFromFirebaseStoreByUrl(attachmentUrl);
        if (docType.includes("image")) {
          const resizedImageUrl = await getDownloadURL(resizedImagePath);
          await deleteImageFromFirebaseStoreByUrl(resizedImageUrl);
          toast.success("Document deleted successfully !", {
            autoClose: 2000,
          });
          return;
        }
      });
    } catch (error) {
      toast.error("Failed to delete documents", {
        autoClose: 2000,
      });
    }
  };

  const onDeletedDocumentTypeAndAttachments = async documentListId => {
    try {
      await db.runTransaction(async txn => {
        const orgRef = fetchDocumentListFromDb(
          currentUserProfile?.orgAccessId,
          documentListId
        );
        const doc = await txn.get(orgRef);
        const jobData = doc.data();
        let attachments = jobData.attachments;
        // delete the documents from the storage
        if (attachments?.length > 0 && attachments?.length) {
          attachments.forEach(async attachment => {
            deleteImageFromFirebaseStoreByUrl(attachment.url);
            if (attachment.type.includes("image")) {
              let resizedImagePath = attachment?.resizedImagePath;
              const resizedImageUrl = await getDownloadURL(resizedImagePath);
              if (
                resizedImagePath !== undefined &&
                resizedImageUrl !== undefined
              ) {
                deleteImageFromFirebaseStoreByUrl(resizedImageUrl);
              }
            }
          });
        }
        txn.delete(orgRef);
      });
      toast.success(
        "Document Folder and Documents are deleted successfully !",
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      toast.error("Failed to delete documents", {
        autoClose: 2000,
      });
    }
  };

  const handleDeleteAllDocuments = async () => {
    if (!selectedDocuments || selectedDocuments.length === 0) {
      return;
    }
    try {
      setIsLoading(true);
      await Promise.all(
        selectedDocuments?.map(async attachmentToBeDelete => {
          await db.runTransaction(async txn => {
            const orgRef = fetchDocumentListFromDb(
              currentUserProfile?.orgAccessId,
              documentListId
            );

            const doc = await txn.get(orgRef);
            const jobData = doc.data();
            let attachmentUrl;
            let resizedImagePath;

            let updatedDocuments = jobData.attachments.filter(attachment => {
              if (attachment?.id === attachmentToBeDelete) {
                attachmentUrl = attachment?.url;
                resizedImagePath = attachment?.resizedImagePath;
              }
              return attachment.id !== attachmentToBeDelete;
            });

            const documentUpdate = {
              attachments: updatedDocuments,
            };

            txn.update(orgRef, documentUpdate);

            try {
              // Delete the original image
              await deleteImageFromFirebaseStoreByUrl(attachmentUrl);
              const resizedImageUrl = await getDownloadURL(resizedImagePath);
              // Delete the resized image
              if (
                resizedImagePath !== undefined &&
                resizedImageUrl !== undefined
              ) {
                deleteImageFromFirebaseStoreByUrl(resizedImageUrl);
              }
            } catch (error) {
              console.error("Error deleting original image:", error);
            }
          });
        })
      );
      toast.success("The documents are deleted!", {
        autoClose: 2000,
      });
      setSelectedDocuments([]);
      setShowDeleteAlert(false);
      setShowCheckBox(false);
      setIsLoading(false);
    } catch (error) {
      setShowDeleteAlert(false);
      setIsLoading(false);
      console.error("Error deleting clients:", error);
    }
  };

  const DisplayDocumentList = ({ document, index, documentId, collapsed }) => {

    return (collapsed ?
      <div key={index} className="w-100">
        <Row className="align-items-center">
          <Col sm="6" className="d-flex align-items-center text-align-center">
            {showCheckBox && (
              <input
                type="checkbox"
                className="checkbox"
                style={{
                  float: "left",
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  border: "2px solid #50a5f1",
                }}
                checked={selectedDocuments?.includes(document.id)}
                onChange={() => {
                  handleSelectedDocuments(document.id);
                  setDocumentListId(documentId);
                }}
              />
            )}
            <img
              data-dz-thumbnail=""
              className="avatar-sm rounded bg-light img-thumbnail mb-1"
              alt={document.name}
              onClick={() => {
                setImage(document.url);
                setImageName(document?.name);
                if (document.type !== "application/pdf") {
                  setGallery(true);
                } else {
                  setPdf(true);
                }
              }}
              src={document.type === "application/pdf" ? pdfLogo : document.url}
              style={{ cursor: "pointer", fontSize: "1rem" }}
              name={document.name}
            />
            <p className="text-truncate m-2">{document?.name}</p>
          </Col>
          {
            !showCheckBox &&
            <Col sm="6">
              <DeleteIcon
                onClick={() => {
                  setSelectedAttachId(document?.id);
                  setSelectedDocType(document?.type);
                  setDocumentListId(documentId);
                  setConfirmAlert(true);
                }}
                size={18}
                style={{ cursor: "pointer" }}
                color="error"
              />
            </Col>
          }
        </Row>
      </div> : null
    );
  };

  const DisplayDocument = ({ document, index }) => {
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };
    return (
      <div className="d-flex" key={index}>
        <Card
          style={{
            width: "100%",
          }}
          className="mt-1 mb-0 shadow-md border  certificate-container dz-processing dz-image-preview dz-success dz-complete"
        >
          <CardBody>
            <Row>
              <Col sm="6">
                <CardTitle
                  style={{ cursor: "pointer", fontSize: "1.2rem", display: "flex", alignItems: "center" }}

                >
                  <button
                    onClick={() => toggleCollapse()}
                    style={{ border: "none", background: "none", marginLeft: "5px", display: "flex", alignItems: "center" }}
                  >
                    <FontAwesomeIcon icon={faAngleDown} />
                  </button>
                  <span onClick={() => {
                    setShowCreateNewDocument(true);
                    setEditDocument(true);
                    setDocument(document);
                  }}>   {document?.name}</span>

                </CardTitle>
              </Col>
              <Col sm="6">
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    color="primary"
                    className="font-12 btn-block btn btn-primary m-2"
                    onClick={() => {
                      setShowModal(true);
                      setDocumentListId(document?.id);
                    }}
                  >
                    Upload Document
                  </Button>
                  <Button
                    color="red"
                    className="font-12 btn-block btn btn-danger m-2"
                    onClick={() => {
                      setDocumentListId(document?.id);
                      setConfirmAlertForAll(true);
                    }}
                  >
                    Delete Folder

                  </Button>
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-danger m-2"
                    onClick={handleShowCheckBoxes}
                  >
                    <i className="mdi mdi-plus-minus-outline me-1" />
                    {showCheckBox ? "Cancel" : "Delete Multiple Docs"}
                  </Button>

                  {selectedDocuments?.length > 0 && showCheckBox && (
                    <DeleteIcon
                      onClick={() => {
                        setShowDeleteAlert(true);
                      }}
                      size={18}
                      style={{ cursor: "pointer" }}
                      color="error"
                    />
                  )}
                </div>
              </Col>
            </Row>



            {document?.attachments?.map((attachment, index) => (

              <DisplayDocumentList
                document={attachment}
                index={index}
                key={index}
                documentId={document.id}
                collapsed={collapsed}
              />
            ))}
          </CardBody>
        </Card>
      </div>
    );
  };

  const DisplayDocuments = () => {
    return documents?.map((document, index) => (
      <DisplayDocument document={document} index={index} key={index} />
    ));
  };

  const [selectedFiles, setselectedFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setselectedFiles([]);
    handleAcceptedFiles(acceptedFiles);
  });

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        percent: 0,
        downloadUrl: "",
        id: "",
      })
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "image/jpg": [".jpeg", ".png", ".jpg", ".JPEG", ".PNG", ".JPG"],
        "application/pdf": [".pdf", ".PDF"],
      },
    });
  const downloadImage = DownloadImage();
  return (
    <React.Fragment>
      {showDeleteAlert && (
        <DeleteConfirmationPromptWithCheckBoxAndLoading
          label={
            "I understand that deleting is permanent and cannot be undone."
          }
          onCancel={() => {
            setShowDeleteAlert(false);
          }}
          onConfirm={handleDeleteAllDocuments}
          isLoading={isLoading}
          alertLabel="Deleting documents is in progress, Please wait.."
        />
      )}
      {isGallery ? (
        <Lightbox
          mainSrc={image}
          toolbarButtons={[
            <DownloadIcon
              onClick={() => {
                const imageUrl = image;
                downloadImage(imageUrl, "image");
              }}
            />,
          ]}
          onCloseRequest={() => {
            setGallery(false);
            setImage(null);
            setImageName("");
          }}
        />
      ) : null}
      <div className="page-content">
        <MetaTags>
          <title>Documents Library</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Setup" breadcrumbItem="Documents Library" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="3">
                      <div className="text-sm-end">
                        <Input
                          type="search"
                          placeholder="search document folder"
                          name="checklistSearch"
                          onChange={e => setChecklistSearch(e.target.value)}
                        ></Input>
                      </div>
                    </Col>
                    <Col sm="9">
                      <div className="text-sm-end">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={() => {
                            setShowCreateNewDocument(true);
                            setEditDocument(false);
                            setDocument({});
                          }}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add Document Folder
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {showCreateNewDocument && (
                    <CreateNewDocumentType
                      showCreateNewDocument={showCreateNewDocument}
                      setShowCreateNewDocument={setShowCreateNewDocument}
                      isEditDocument={isEditDocument}
                      setEditDocument={setEditDocument}
                      document={document}
                      setDocument={setDocument}
                      currentUserProfile={currentUserProfile}
                    />
                  )}

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <DisplayDocuments />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal isOpen={showModal} size="xl">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Upload Files
            </h5>
            <button
              type="button"
              onClick={() => {
                setShowModal(false);
                setselectedFiles([]);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Form encType="multipart/form-data">
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          {isDragReject ? (
                            <h4>Sorry,Please upload valid image format.</h4>
                          ) : (
                            <>
                              <h4>Drop files here or click to upload.</h4>
                              <p style={{ fontSize: "12px" }}>
                                Only jpeg, jpg, png , gif & pdf formats
                                supported.
                              </p>
                            </>
                          )}
                        </div>
                      </div>

                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.length > 0 && (
                          <Uploader
                            orgId={currentUserProfile?.orgAccessId}
                            // id={id}
                            files={selectedFiles}
                            documentListId={documentListId}
                          />
                        )}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
      {isPdf && (
        <PDFModal
          isOpen={isPdf}
          closeModal={closeModal}
          pdfUrl={image}
          pdfName={imageName}
          pdfJob={downloadImage}
        />
      )}
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            onDeletedAttachment(
              selectedDocType,
              selectedAttachId,
              documentListId
            );
            setSelectedAttachId(null);
            setSelectedDocType(null);
            setDocumentListId(null);
            setConfirmAlert(false);
          }}
          onCancel={() => {
            setDocumentListId(null);
            setSelectedAttachId(null);
            setSelectedDocType(null);
            setConfirmAlert(false);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {confirmAlertForAll ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            onDeletedDocumentTypeAndAttachments(documentListId);
            setDocumentListId(null);
            setConfirmAlertForAll(false);
          }}
          onCancel={() => {
            setDocumentListId(null);
            setConfirmAlertForAll(false);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
    </React.Fragment>
  );
};

export function Uploader({ orgId, files, documentListId }) {
  const storageRef = firebase.storage().ref();
  const db = firebase.firestore();
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState(null);
  const { currentUserProfile } = useSelector(state => state.profile);

  const addAttachmentDetails = async () => {
    try {
      await db.runTransaction(async txn => {
        const orgRef = fetchDocumentListFromDb(orgId, documentListId);
        const doc = await txn.get(orgRef);
        const jobData = doc.data();
        const documents = jobData?.attachments;
        files.map(file => {
          let resizedImagePath;
          if (file?.type?.includes("image")) {
            resizedImagePath = `${orgId}/documents/${RESIZED_IMAGES_PATH}/${file.id}_${RESIZED_IMAGES_DIMENSIONS}`;
            documents.push({
              id: file.id,
              userId: currentUserProfile.id,
              name: file.name,
              type: file.type,
              url: file.downloadUrl,
              size: file.formattedSize,
              resizedImagePath: resizedImagePath,
              userName: userName(currentUserProfile)
            });
          }
          else {
            documents.push({
              id: file.id,
              userId: currentUserProfile.id,
              name: file.name,
              type: file.type,
              url: file.downloadUrl,
              size: file.formattedSize,
              userName: userName(currentUserProfile)
            });
          }
        });
        const documentUpdate = { attachments: documents };
        txn.update(orgRef, documentUpdate);
        toast.success("Document added successfully !", {
          autoClose: 2000,
        });
      });
    } catch (error) {
      console.log("Failed to add job document attachments details", error);
    }
  };

  useEffect(() => {
    uploadToFirebase();
  }, []);

  const uploadToFirebase = async () => {
    await Promise.all(
      files?.map(file => {
        return new Promise(resolve => {
          const fileId = cuid();
          file.id = fileId;
          const filePath = `${orgId}/documents/${fileId}`;
          const uploadTask = storageRef.child(filePath).put(file, {
            contentType: file.type,
          });

          uploadTask.on(
            "state_changed",
            snap => {
              const percentUploaded = Math.round(
                (snap.bytesTransferred / snap.totalBytes) * 100
              );
              file.percent = percentUploaded;
              setPercent(percentUploaded);
            },
            err => {
              console.error(err);
              setError(err);
            },
            () => {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(async downloadUrl => {
                  file.downloadUrl = downloadUrl;
                  resolve(downloadUrl);
                })
                .catch(err => {
                  console.error("err", err);
                  setError(err);
                });
            }
          );
        });
      })
    );
    addAttachmentDetails();
  };

  const Message = ({ children }) => {
    const [show, setShow] = useState(true);

    // On componentDidMount set the timer
    useEffect(() => {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setPercent(-1);
        setShow(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    }, []);

    // If show is false the component will return null and stop here
    if (!show) {
      return null;
    }

    // If show is true this will be returned
    return <>{children}</>;
  };

  const FileInfo = () => {
    return files.map((file, i) => {
      return (
        <div key={file.id + i.toString()}>
          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
            <div className="p-2">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <img
                    data-dz-thumbnail=""
                    height="80"
                    className="avatar-sm rounded bg-light"
                    alt={file.name}
                    src={
                      file.type === "application/pdf" ? pdfLogo : file.preview
                    }
                  />
                </Col>
                <Col>
                  <div to="#" className="text-muted font-weight-bold">
                    {file.name}
                  </div>
                  <p className="mb-0">
                    <strong>{file.formattedSize}</strong>
                  </p>
                </Col>
              </Row>
            </div>
            <div className="p-1">
              {file.percent === 100 ? (
                <Progress
                  className="progress-sm"
                  striped
                  animated
                  color="success"
                  value={file.percent}
                />
              ) : (
                <Progress
                  className="progress-sm"
                  striped
                  animated
                  color="danger"
                  value={file.percent}
                />
              )}
            </div>
          </Card>
        </div>
      );
    });
  };

  if (percent === -1) {
    return null;
  } else if (percent === 100) {
    return (
      <Message>
        <FileInfo />
      </Message>
    );
  } else {
    return <FileInfo />;
  }
}

export default DocumentLists;