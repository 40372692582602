import {
  LISTEN_TO_CURRENT_USER_PROFILE,
  LISTEN_TO_SELECTED_USER_PROFILE,
  OPEN_SIGNATURE_PAD,
  CLOSE_SIGNATURE_PAD,
} from './actionTypes';

const initialState = {
  currentUserProfile: null,
  selectedUserProfile: null,
  showSignature: false,
};

export default function profileReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LISTEN_TO_CURRENT_USER_PROFILE:
      return {
        ...state,
        currentUserProfile: payload,
      };
    case LISTEN_TO_SELECTED_USER_PROFILE:
      return {
        ...state,
        selectedUserProfile: payload,
      };
    case OPEN_SIGNATURE_PAD:
      return {
        ...state,
        showSignature: true,
      };
    case CLOSE_SIGNATURE_PAD:
      return {
        ...state,
        showSignature: false,
      };
    default: {
      return state;
    }
  }
}
