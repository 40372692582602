import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { format } from "date-fns";
import {
  Badge,
  Box,
  IconButton,
  List,
  ListItemText,
  ListItemAvatar,
  Popover,
  ListItemButton,
  Divider,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import { fetchAllNotifications, markAsRead, markAllAsRead } from "services";
import {
  fetchNotifications,
  fetchNotificationsError,
  fetchNotificationsSuccess,
} from "store/actions";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import "./NotificationStyles.css";
import * as _ from "lodash";
import { HashLink } from "react-router-hash-link";
export default function Notifications({
  setIsHoldPointNotification,
  setHoldPointNotificationMessage,
}) {
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchAllNotifications(),
    onStart: () => dispatch(fetchNotifications),
    onSuccess: docs => dispatch(fetchNotificationsSuccess(docs)),
    onError: error => dispatch(fetchNotificationsError(error)),
    deps: [dispatch],
  });

  const { notifications } = useSelector(state => state.notifications);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const DEFAULT_NOTIFICATIONS_AT_START = 10;
  const [displayNotificationsCount, setDisplayNotificationsCount] = useState(
    DEFAULT_NOTIFICATIONS_AT_START
  );
  let sortedNotifications = _.sortBy(notifications, "timestamp").reverse();
  const loadMoreItems = () => {
    setDisplayNotificationsCount(
      prevValue => prevValue + DEFAULT_NOTIFICATIONS_AT_START
    );
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDisplayNotificationsCount(DEFAULT_NOTIFICATIONS_AT_START);
  };

  const notificationCount = () => {
    let count = 0;
    notifications.forEach(notification => {
      if (notification.isRead == false) count++;
    });
    return count;
  };
  const notificationRedirectionPage = (actionType, jobId, type, isInspectionJob) => {
    const pathName = isInspectionJob ? "inspectionactive" : "active";
    if (actionType) {
      if (
        actionType.trim() == "request-to-join" ||
        actionType.trim() == "org-owner"
      )
        return `/org-users-management`;
      if (actionType.trim() == "approved-join-request" || actionType.trim() == "join-organisation") return `/active/`;
      if (type === "chat") return `/${pathName}/${jobId}#chat-area`;
      if (type === "admin" && actionType.trim() == "added-holdPoint")
        return `#`;
      return `/${pathName}/${jobId}#job-details`;
    }
    if (type === "chat") return `/${pathName}/${jobId}#chat-area`;
    return `/${pathName}/${jobId}#job-details`;
  };
  const displayNotificationsInUi = sortedNotifications?.slice(
    0,
    displayNotificationsCount
  );

  const NotificationEmptyState = () => {
    return (
      <React.Fragment>
        <Box style={{ marginTop: "0px" }}>
          <List style={{ width: "450px" }}>
            <center>
              <CheckCircleOutlineIcon
                style={{
                  color: "green",
                  fontSize: "50px",
                  textAlign: "center",
                }}
                className="m-1"
              />
            </center>
            <center>
              <ListItemText
                primary="You're all caught up!"
                secondary="you have no new notifications"
              ></ListItemText>
            </center>
          </List>
        </Box>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Box>
        <IconButton
          size="large"
          onClick={handleClick}
          className="header-item noti-icon"
          disableRipple={true}
          data-testid="notifications-icon-button"
        >
          <Badge badgeContent={(length = notificationCount())} color="error">
            <NotificationsIcon color="action" fontSize="24" />
          </Badge>
        </IconButton>
        <Popover
          className={
            (length =
              sortedNotifications?.length > 6
                ? "notification-modal-ui-1"
                : "notification-modal-ui")
          }
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {sortedNotifications.length == 0 ? (
            <NotificationEmptyState />
          ) : (
            <List style={{ width: "450px" }}>
              {displayNotificationsInUi.map(notification => {
                return (
                  <ListItemButton
                    data-testid="notification"
                    divider
                    key={notification.id}
                    className={
                      notification.isRead ? "text-muted" : "bg-primary bg-soft"
                    }
                  >
                    <ListItemAvatar
                      style={{ alignSelf: "start", marginTop: "10px" }}
                    >
                      {notification.type == "admin" ? (
                        <AccountCircleIcon />
                      ) : notification.isRead ? (
                        <MarkChatReadIcon />
                      ) : (
                        <MarkChatUnreadIcon />
                      )}
                    </ListItemAvatar>
                    <HashLink
                      to={notificationRedirectionPage(
                        notification?.actionType,
                        notification?.jobId,
                        notification?.type,
                        notification?.isInspectionJob,
                      )}
                      className="text-dark w-100"
                      onClick={() => {
                        if (
                          notification?.actionType == "added-holdPoint" &&
                          notification?.type === "admin"
                        ) {
                          setHoldPointNotificationMessage(
                            notification?.detailedDescription
                          );
                          setIsHoldPointNotification(true);
                        }
                        handleClose();
                        notification.isRead ? null : markAsRead(notification);
                      }}
                      smooth
                    >
                      <ListItemText
                        primary={notification?.description}
                        secondary={format(
                          new Date(notification?.timestamp),
                          "dd LLL yy hh:mm aaa"
                        )}
                        secondaryTypographyProps={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      />
                    </HashLink>
                  </ListItemButton>
                );
              })}
            </List>
          )}
          <Divider />
          <Box
            m={1}
            ml={2}
            mr={2}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Button
              className="primary"
              color="primary"
              onClick={() => markAllAsRead(displayNotificationsInUi)}
              // disable when no unread notification
              disabled={!sortedNotifications.some(item => !item.isRead)}
              data-testid="mark-all-as-read-button"
            >
              Mark all as read
            </Button>
            <Button
              data-testid="load-more-button"
              className="primary"
              color="primary"
              onClick={loadMoreItems}
              disabled={displayNotificationsCount >= sortedNotifications.length}
            >
              Load more
            </Button>
          </Box>
        </Popover>
      </Box>
    </React.Fragment>
  );
}
