import firebase from "../config/firebase";
const db = firebase.firestore();

export function addNotesToDb(userId,notes,notesInfo) {
  return db.collection(`noteslibrary`).add({
    userId,
    notesName: notes,
    notesInfo,
    date: firebase.firestore.FieldValue.serverTimestamp(),
  });
}
export function fetchNotesFromDb(userId,notesName) {
    return db.collection(`noteslibrary`).where("userId", "==", userId).where("notes", "==", notesName);
}
export function fetchAllNotes(userId) {
  return db.collection(`noteslibrary`).where("userId", "==", userId);
}
export async function deleteMultipleNotes(notesIds) {
  const batch = db.batch();
  notesIds.forEach(id => {
    const ref = db.collection(`noteslibrary`).doc(id);
    batch.delete(ref);
  });
  return batch.commit();
}
export function updateNotesInDb(notesId,notesName,notesInfo) {
  return db.collection(`noteslibrary`).doc(notesId).update({
    notesName,
    notesInfo,
  });
}
export function deleteNotesFromDb(notesId) {
  return db.collection(`noteslibrary`).doc(notesId).delete();
}