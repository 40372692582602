import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
} from "./actionTypes";

const initialState = {
  notifications: [],
};

export default function notificationsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: payload,
      };
    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}
