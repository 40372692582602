import {
  FETCH_CLIENTS_ERROR,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS,
} from "./actionTypes";

const initialState = {
  users: [],
  clients: [],
  loading: false,
};

export default function personsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_CLIENTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CLIENTS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: payload,
      };
    default:
      return state;
  }
}
