import React, { useState, useEffect } from "react";
import cuid from "cuid";
import firebase from "../../config/firebase";
import { updateUserProfile } from "../../services/index";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  Progress,
  CardSubtitle,
  Container,
} from "reactstrap";
// Redux
import { useSelector } from "react-redux";
import pdfLogo from "./../../assets/images/pdflogo.png";
import { Link } from "react-router-dom";

export default function Uploader({ qualifier, file }) {

  const storageRef = firebase.storage().ref();
  const { currentUserProfile } = useSelector(state => state.profile);
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    uploadToFirebase();
  }, []);

  const uploadToFirebase = () => {
    const id = cuid();
    const filePath = `${currentUserProfile.id}/documents/${id}`;
    const uploadTask = storageRef.child(filePath).put(file, {
      contentType: file.type,
    });

    uploadTask.on(
      "state_changed",
      snap => {
        const percentUploaded = Math.round(
          (snap.bytesTransferred / snap.totalBytes) * 100
        );
        setPercent(percentUploaded);
      },
      err => {
        console.error(err);
        setError(error);
      },
      () => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(async downloadUrl => {
            if (currentUserProfile[qualifier]) {
              currentUserProfile[qualifier] = [
                {
                  id: id,
                  name: file.name,
                  type: file.type,
                  url: downloadUrl,
                  size: file.formattedSize,
                },
                ...currentUserProfile[qualifier],
              ];
            } else {
              currentUserProfile[qualifier] = [
                {
                  id: id,
                  name: file.name,
                  url: downloadUrl,
                  type: file.type,
                  size: file.formattedSize,
                },
              ];
            }
            updateUserProfile(currentUserProfile);
          })
          .catch(err => {
            console.error(err);
            setError(error);
          });
      }
    );
  };

  const Message = ({ children }) => {
    const [show, setShow] = useState(true);

    // On componentDidMount set the timer
    useEffect(() => {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setPercent(-1);
        setShow(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    }, []);
    if (!show) {
      return null;
    }
    return <>{children}</>;
  };

  const FileInfo = () => (
    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
      <div className="p-2">
        <Row className="align-items-center">
          <Col className="col-auto">
            <img
              data-dz-thumbnail=""
              height="80"
              className="avatar-sm rounded bg-light"
              alt={file.name}
              src={file.type === "application/pdf" ? pdfLogo : file.preview}
            />
          </Col>
          <Col>
            <Link to="#" className="text-muted font-weight-bold">
              {file.name}
            </Link>
            <p className="mb-0">
              <strong>{file.formattedSize}</strong>
            </p>
          </Col>
        </Row>
      </div>
      <div className="p-1">
        {percent === 100 ? (
          <Progress
            className="progress-sm"
            striped
            animated
            color="success"
            value={percent}
          />
        ) : (
          <Progress
            className="progress-sm"
            striped
            animated
            color="danger"
            value={percent}
          />
        )}
      </div>
    </Card>
  );

  if (percent === -1) {
    return null;
  } else if ((percent === 100)) {
    return (
      <Message>
        <FileInfo />
      </Message>
    );
  }else{
      return <FileInfo />;
  }
}
