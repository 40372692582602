import classnames from "classnames";
import { toast } from "react-toastify";
import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Checklist from "./Checklist";
import Comments from "./../Comments";
import JobDetail from "./JobDetail";
import { getIcon } from "common/data/rooms";
import "../../jobCreation/checklist/CheckListPage.scss";
import NotesLibrary from "pages/notesLibrary/NotesLibrary";
import {
  COMMERCIAL_CATEGORY,
  INFRASTRUCTURE_CATEGORY,
  RESIDENTIAL_CATEGORY,
} from "common/constants";
export default function RoomsOverview({
  jobDetails,
  roomId,
  collectionName,
  history,
}) {
  const { currentUserProfile } = useSelector(state => state.profile);
  const [activeTab, setactiveTab] = useState("0");
  const [internalRoomId, setInternalRoomId] = useState(null);
  const [externalRoomId, setExternalRoomId] = useState(null);
  const [belowGroundRoomId, setBelowGroundRoomId] = useState(null);
  const hasInternal = jobDetails?.jobType?.internal;
  const hasExternal = jobDetails?.jobType?.external;
  const hasBelow = jobDetails?.jobType?.belowground;
  const [showNotes, setShowNotes] = useState(false);
  const [showRooms, setShowRooms] = useState(true);
  useEffect(() => {
    if (!jobDetails) return;
    const roomsCheck = checkForEmptyRooms(jobDetails);
    if (!roomsCheck) {
      history.push(`/active/${jobDetails.id}`);
    }
  }, [
    jobDetails?.internal?.rooms,
    jobDetails?.external?.rooms,
    jobDetails?.belowground?.rooms,
  ]);

  const checkForEmptyRooms = jobDetails => {
    const externalRoomsCheck = jobDetails?.external?.rooms?.find(
      floor => floor.subItems.length > 0
    );
    const internalRoomsCheck = jobDetails?.internal?.rooms?.find(
      floor => floor.subItems.length > 0
    );
    const belowGroundRoomsCheck = jobDetails?.belowground?.rooms?.find(
      floor => floor.subItems.length > 0
    );
    return externalRoomsCheck || internalRoomsCheck || belowGroundRoomsCheck;
  };
  const totalAreasInJob = rooms => {
    let totalAreas = 0;
    rooms?.forEach(
      room =>
        (totalAreas += room.subItems?.length > 0 ? room.subItems.length : 1)
    );
    return totalAreas;
  };
  const handleDisplayNotes = () => {
    if (showNotes) {
      setShowNotes(false);
      return;
    }
    setShowNotes(true);
    return;
  };

  const handleDisplayRooms = () => {
    if (showRooms) {
      setShowRooms(false);
      return;
    }
    setShowRooms(true);
    return;
  };

  useEffect(() => {
    let queryRoomIdType = "internal";
    if (!jobDetails || !roomId) return;
    if (hasInternal && !internalRoomId) {
      jobDetails.internal?.rooms?.forEach(floor => {
        floor.subItems.forEach(room => {
          if (room.id === roomId) {
            queryRoomIdType = "internal";
            toggle("1");
            setInternalRoomId(roomId);
          }
        });
      });
    }
    if (hasExternal && !externalRoomId) {
      jobDetails.external?.rooms?.forEach(floor => {
        floor.subItems.forEach(room => {
          if (room.id === roomId) {
            queryRoomIdType = "external";
            toggle("2");
            setExternalRoomId(roomId);
          }
        });
      });
    }
    if (hasBelow && !belowGroundRoomId) {
      jobDetails.belowground?.rooms?.forEach(floor => {
        floor.subItems.forEach(room => {
          if (room.id === roomId) {
            queryRoomIdType = "belowground";
            toggle("3");
            setBelowGroundRoomId(roomId);
          }
        });
      });
    }
    if (!internalRoomId && queryRoomIdType !== "internal") {
      setInternalRoomId(jobDetails?.internal?.rooms[0]?.subItems[0]?.id);
    }
    if (!externalRoomId && queryRoomIdType !== "external") {
      setExternalRoomId(jobDetails?.external?.rooms[0]?.subItems[0]?.id);
    }
    if (!belowGroundRoomId && queryRoomIdType !== "belowground") {
      setBelowGroundRoomId(jobDetails?.belowground?.rooms[0]?.subItems[0]?.id);
    }
  }, [roomId, jobDetails, activeTab]);

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  useEffect(() => {
    if (jobDetails === null || !currentUserProfile) return;
    if (isEmpty(jobDetails)) {
      toast.warn("The job is not available", {
        autoClose: 3000,
      });
      history.push("/active");
      return;
    }
    const accessId = jobDetails.accessIds?.find(
      id => id === currentUserProfile?.id
    );
    // if (!currentUserProfile.orgAccessId && !accessId) {
    //   toast.warn("You are removed from the Organisation", {
    //     autoClose: 3000,
    //   });
    //   history.push("/active");
    //   return;
    // }
    if (!accessId) {
      toast.warn("The job is not available", {
        autoClose: 3000,
      });
      history.push("/active");
    }
  }, [jobDetails, currentUserProfile]);

  const changeRoomInternal = id => {
    setInternalRoomId(id);
  };
  const changeRoomExternal = id => {
    setExternalRoomId(id);
  };
  const changeRoomBelow = id => {
    setBelowGroundRoomId(id);
  };

  const canEdit = () => {
    const [myAccess] = jobDetails?.participants.filter(
      user => user.id === currentUserProfile.id
    );
    return myAccess?.access.value === "full";
  };

  const canEditViewAndSign = () => {
    const [myAccess] = jobDetails?.participants.filter(
      user => user.id === currentUserProfile.id
    );
    return (
      myAccess?.access.value === "ticknotesviachatphotosesign" ||
      myAccess?.access.value === "ticknotesphotosesign"
    );
  };

  const canUpload = () => {
    const [myAccess] = jobDetails?.participants.filter(
      user => user.id === currentUserProfile.id
    );
    return (
      myAccess?.access.value === "full" ||
      myAccess?.access.value === "ticknotesviachatphotosesign" ||
      myAccess?.access.value === "ticknotesphotosesign"
    );
  };

  const canSign = () => {
    const [myAccess] = jobDetails?.participants.filter(
      user => user.id === currentUserProfile.id
    );
    return myAccess?.access.value !== "readonly";
  };

  if (isEmpty(jobDetails)) return null;
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Floor and Area(s) Details | Industry Best</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <UseHeader
            title="Floor and Area(s) Details"
            history={history}
            jobId={jobDetails.id}
          />

          {!isEmpty(jobDetails) && (
            <>
              <Row>
                <JobDetail job={jobDetails} />
              </Row>
              <Nav tabs>
                {hasInternal && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      {RESIDENTIAL_CATEGORY}
                    </NavLink>
                  </NavItem>
                )}
                {hasExternal && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      {COMMERCIAL_CATEGORY}
                    </NavLink>
                  </NavItem>
                )}
                {hasBelow && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      {INFRASTRUCTURE_CATEGORY}
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="1">
                  {" "}
                  <Row>
                    <Col lg={showRooms ? "4" : "auto"}>
                      <Row>
                        <Col>
                          <div style={{ display: "flex" }}>
                            {showRooms && (
                              <Card style={{ width: 550 }}>
                                <CardBody>
                                  <CardTitle className="mb-4">Rooms</CardTitle>
                                  {jobDetails?.internal?.rooms?.length === 0 ? (
                                    <p style={{ textAlign: "center" }}>
                                      There are no floors
                                    </p>
                                  ) : null}
                                  <div
                                    className={
                                      totalAreasInJob(
                                        jobDetails.internal?.rooms
                                      ) > 4
                                        ? "scrollable-content"
                                        : ""
                                    }
                                  >
                                    {map(
                                      jobDetails.internal?.rooms,
                                      (floor, i) => {
                                        return (
                                          <Card key={i}>
                                            <CardBody>
                                              <CardTitle className="mb-4">
                                                {floor.content}
                                              </CardTitle>
                                              <div className="table-responsive">
                                                <table className="table table-nowrap align-middle mb-0">
                                                  <tbody>
                                                    {floor.subItems.length >
                                                    0 ? (
                                                      floor.subItems.map(
                                                        (item, i) => (
                                                          <tr
                                                            key={i}
                                                            style={
                                                              internalRoomId ===
                                                              item.id
                                                                ? {
                                                                    backgroundColor:
                                                                      jobDetails
                                                                        ?.areas[
                                                                        internalRoomId
                                                                      ]
                                                                        ?.isLocked
                                                                        ? "#C5C5C5"
                                                                        : "#556ee6",
                                                                  }
                                                                : {
                                                                    backgroundColor:
                                                                      jobDetails
                                                                        ?.areas[
                                                                        item.id
                                                                      ]
                                                                        ?.isLocked
                                                                        ? "#D3D3D3"
                                                                        : "",
                                                                    cursor:
                                                                      "pointer",
                                                                  }
                                                            }
                                                            onClick={() =>
                                                              changeRoomInternal(
                                                                item.id
                                                              )
                                                            }
                                                          >
                                                            <td>
                                                              <img
                                                                src={getIcon(
                                                                  item.type
                                                                )}
                                                                style={{
                                                                  float: "left",
                                                                  height:
                                                                    "30px",
                                                                  transform:
                                                                    "translate(0, -5px)",
                                                                }}
                                                              />
                                                            </td>
                                                            <td>
                                                              <h5 className="text-truncate font-size-14 m-0">
                                                                <div
                                                                  className={
                                                                    internalRoomId ===
                                                                    item.id
                                                                      ? "text-light"
                                                                      : "text-dark"
                                                                  }
                                                                >
                                                                  {item.content}
                                                                </div>
                                                              </h5>
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    ) : (
                                                      <tr>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          The floor has no areas
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </CardBody>
                                          </Card>
                                        );
                                      }
                                    )}
                                  </div>
                                </CardBody>
                              </Card>
                            )}
                            <div
                              style={{
                                // maxWidth: showRooms ? "25%" : "100%",
                                marginLeft: 30,
                              }}
                            >
                              <Nav
                                tabs
                                className="nav-rotate"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "0",
                                    })}
                                    onClick={handleDisplayRooms}
                                  >
                                    {showRooms ? (
                                      <i
                                        className="fas fa-angle-double-up"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-angle-double-down"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    )}
                                    <span
                                      style={{
                                        paddingLeft: "2px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {" "}
                                      {showRooms ? "Close Rooms" : "Open Rooms"}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </div>
                          </div>
                        </Col>
                        {/* <Col
                          lg="auto"
                          style={{
                            flex: 1,
                            maxWidth: showRooms ? "15%" : "100%",
                            marginLeft: 20,
                          }}
                        >
                          <div>
                            <div style={{ display: "flex" }}>
                              <Nav
                                tabs
                                className="nav-rotate"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "1",
                                    })}
                                    onClick={handleDisplayRooms}
                                  >
                                    {showRooms ? (
                                      <i
                                        className="fas fa-angle-double-up"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-angle-double-down"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    )}
                                    <span
                                      style={{
                                        paddingLeft: "2px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {" "}
                                      {showRooms ? "Close Rooms" : "Open Rooms"}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                      {/* <Row>
                        <Comments
                          job={jobDetails}
                          collectionName={collectionName}
                        />
                      </Row> */}
                    </Col>
                    <Col
                      lg={
                        showNotes && showRooms
                          ? "4"
                          : showNotes
                          ? "7"
                          : showRooms
                          ? "7"
                          : "9"
                      }
                      // style={{marginLeft: -60}}
                    >
                      {jobDetails?.internal?.rooms?.length > 0 &&
                        jobDetails?.internal?.rooms?.find(
                          floor => floor.subItems.length > 0
                        ) && (
                          <Checklist
                            job={jobDetails}
                            type={"internal"}
                            roomId={internalRoomId}
                            canEdit={canEdit()}
                            canEditViewAndSign={canEditViewAndSign()}
                            canUpload={canUpload()}
                            canSign={canSign()}
                            collectionName={collectionName}
                          />
                        )}
                    </Col>
                    <Col>
                      <div>
                        <div style={{ display: "flex" }}>
                          <Nav
                            tabs
                            className="nav-rotate"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === "0",
                                })}
                                onClick={handleDisplayNotes}
                              >
                                {showNotes ? (
                                  <i
                                    className="fa fa-angle-double-down"
                                    style={{ color: "#50a5f1" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fas fa-angle-double-up"
                                    style={{ color: "#50a5f1" }}
                                  ></i>
                                )}
                                <span
                                  style={{
                                    paddingLeft: "7px",
                                    fontSize: "14px",
                                  }}
                                >
                                  {" "}
                                  Saved Notes
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                        {showNotes && (
                          <div
                            style={{ marginLeft: "30px", marginTop: "-60px" }}
                          >
                            <NotesLibrary />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Row style={{ height: "28rem" }}>
                      <Comments
                        job={jobDetails}
                        collectionName={collectionName}
                      />
                    </Row>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  {" "}
                  <Row>
                    <Col lg={showRooms ? "4" : "auto"}>
                      <Row>
                        <Col>
                          <div style={{ display: "flex" }}>
                            {showRooms && (
                              <Card style={{ width: 550 }}>
                                <CardBody>
                                  <CardTitle className="mb-4">Rooms</CardTitle>
                                  {jobDetails?.external?.rooms?.length === 0 ? (
                                    <p style={{ textAlign: "center" }}>
                                      There are no floors
                                    </p>
                                  ) : null}
                                  <div
                                    className={
                                      totalAreasInJob(
                                        jobDetails.external?.rooms
                                      ) > 4
                                        ? "scrollable-content"
                                        : ""
                                    }
                                  >
                                    {map(
                                      jobDetails.external?.rooms,
                                      (floor, i) => {
                                        return (
                                          <Card key={i}>
                                            <CardBody>
                                              <CardTitle className="mb-4">
                                                {floor.content}
                                              </CardTitle>
                                              <div className="table-responsive">
                                                <table className="table table-nowrap align-middle mb-0">
                                                  <tbody>
                                                    {floor.subItems.length >
                                                    0 ? (
                                                      floor.subItems.map(
                                                        (item, i) => (
                                                          <tr
                                                            key={i}
                                                            style={
                                                              externalRoomId ===
                                                              item.id
                                                                ? {
                                                                    backgroundColor:
                                                                      jobDetails
                                                                        ?.areas[
                                                                        externalRoomId
                                                                      ]
                                                                        ?.isLocked
                                                                        ? "#C5C5C5"
                                                                        : "#556ee6",
                                                                  }
                                                                : {
                                                                    backgroundColor:
                                                                      jobDetails
                                                                        ?.areas[
                                                                        item.id
                                                                      ]
                                                                        ?.isLocked
                                                                        ? "#D3D3D3"
                                                                        : "",
                                                                    cursor:
                                                                      "pointer",
                                                                  }
                                                            }
                                                            onClick={() =>
                                                              changeRoomExternal(
                                                                item.id
                                                              )
                                                            }
                                                          >
                                                            <td>
                                                              <img
                                                                src={getIcon(
                                                                  item.type
                                                                )}
                                                                style={{
                                                                  float: "left",
                                                                  height:
                                                                    "30px",
                                                                  transform:
                                                                    "translate(0, -5px)",
                                                                }}
                                                              />
                                                            </td>
                                                            <td>
                                                              <h5 className="text-truncate font-size-14 m-0">
                                                                <div
                                                                  className={
                                                                    externalRoomId ===
                                                                    item.id
                                                                      ? "text-light"
                                                                      : "text-dark"
                                                                  }
                                                                >
                                                                  {item.content}
                                                                </div>
                                                              </h5>
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    ) : (
                                                      <tr>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          The floor has no areas
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </CardBody>
                                          </Card>
                                        );
                                      }
                                    )}
                                  </div>
                                </CardBody>
                              </Card>
                            )}
                            <div
                              style={{
                                // maxWidth: showRooms ? "25%" : "100%",
                                marginLeft: 30,
                              }}
                            >
                              <Nav
                                tabs
                                className="nav-rotate"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "0",
                                    })}
                                    onClick={handleDisplayRooms}
                                  >
                                    {showRooms ? (
                                      <i
                                        className="fas fa-angle-double-up"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-angle-double-down"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    )}
                                    <span
                                      style={{
                                        paddingLeft: "2px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {" "}
                                      {showRooms ? "Close Rooms" : "Open Rooms"}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </div>
                          </div>
                        </Col>
                        {/* <Col
                          lg="auto"
                          style={{
                            flex: 1,
                            maxWidth: showRooms ? "15%" : "100%",
                            marginLeft: 20,
                          }}
                        >
                          <div>
                            <div style={{ display: "flex" }}>
                              <Nav
                                tabs
                                className="nav-rotate"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "2",
                                    })}
                                    onClick={handleDisplayRooms}
                                  >
                                    {showRooms ? (
                                      <i
                                        className="fas fa-angle-double-up"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-angle-double-down"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    )}
                                    <span
                                      style={{
                                        paddingLeft: "2px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {" "}
                                      {showRooms ? "Close Rooms" : "Open Rooms"}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                    </Col>

                    <Col
                      lg={
                        showNotes && showRooms
                          ? "4"
                          : showNotes
                          ? "7"
                          : showRooms
                          ? "7"
                          : "9"
                      }
                    >
                      {jobDetails?.external?.rooms?.length > 0 &&
                        jobDetails?.external?.rooms?.find(
                          floor => floor.subItems.length > 0
                        ) && (
                          <Checklist
                            job={jobDetails}
                            canEditViewAndSign={canEditViewAndSign()}
                            type={"external"}
                            roomId={externalRoomId}
                            canEdit={canEdit()}
                            canUpload={canUpload()}
                            canSign={canSign()}
                            collectionName={collectionName}
                          />
                        )}
                    </Col>
                    <Col>
                      <div>
                        <div style={{ display: "flex" }}>
                          <Nav
                            tabs
                            className="nav-rotate"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === "1",
                                })}
                                onClick={handleDisplayNotes}
                              >
                                {showNotes ? (
                                  <i
                                    className="fa fa-angle-double-down"
                                    style={{ color: "#50a5f1" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fas fa-angle-double-up"
                                    style={{ color: "#50a5f1" }}
                                  ></i>
                                )}
                                <span style={{ paddingLeft: "7px" }}>
                                  {" "}
                                  Saved Notes
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                        {showNotes && (
                          <div
                            style={{ marginLeft: "30px", marginTop: "-60px" }}
                          >
                            <NotesLibrary />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Row style={{ height: "28rem" }}>
                      <Comments
                        job={jobDetails}
                        collectionName={collectionName}
                      />
                    </Row>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  {" "}
                  <Row>
                    <Col lg={showRooms ? "4" : "auto"}>
                      <Row>
                        <Col>
                          <div style={{ display: "flex" }}>
                            {showRooms && (
                              <Card style={{ width: 550 }}>
                                <CardBody>
                                  <CardTitle className="mb-4">Rooms</CardTitle>
                                  {jobDetails?.belowground?.rooms?.length ===
                                  0 ? (
                                    <td style={{ textAlign: "center" }}>
                                      There are no floors
                                    </td>
                                  ) : null}
                                  <div
                                    className={
                                      totalAreasInJob(
                                        jobDetails.belowground?.rooms
                                      ) > 4
                                        ? "scrollable-content"
                                        : ""
                                    }
                                  >
                                    {map(
                                      jobDetails.belowground?.rooms,
                                      (floor, i) => {
                                        return (
                                          <Card key={i}>
                                            <CardBody>
                                              <CardTitle className="mb-4">
                                                {floor.content}
                                              </CardTitle>
                                              <div className="table-responsive">
                                                <table className="table table-nowrap align-middle mb-0">
                                                  <tbody>
                                                    {floor.subItems.length >
                                                    0 ? (
                                                      floor.subItems.map(
                                                        (item, i) => (
                                                          <tr
                                                            key={i}
                                                            style={
                                                              belowGroundRoomId ===
                                                              item.id
                                                                ? {
                                                                    backgroundColor:
                                                                      jobDetails
                                                                        ?.areas[
                                                                        belowGroundRoomId
                                                                      ]
                                                                        ?.isLocked
                                                                        ? "#C5C5C5"
                                                                        : "#556ee6",
                                                                  }
                                                                : {
                                                                    backgroundColor:
                                                                      jobDetails
                                                                        ?.areas[
                                                                        item.id
                                                                      ]
                                                                        ?.isLocked
                                                                        ? "#D3D3D3"
                                                                        : "",
                                                                    cursor:
                                                                      "pointer",
                                                                  }
                                                            }
                                                            onClick={() =>
                                                              changeRoomBelow(
                                                                item.id
                                                              )
                                                            }
                                                          >
                                                            <td>
                                                              <img
                                                                src={getIcon(
                                                                  item.type
                                                                )}
                                                                style={{
                                                                  float: "left",
                                                                  height:
                                                                    "30px",
                                                                  transform:
                                                                    "translate(0, -5px)",
                                                                }}
                                                              />
                                                            </td>
                                                            <td>
                                                              <h5 className="text-truncate font-size-14 m-0">
                                                                <div
                                                                  className={
                                                                    belowGroundRoomId ===
                                                                    item.id
                                                                      ? "text-light"
                                                                      : "text-dark"
                                                                  }
                                                                >
                                                                  {item.content}
                                                                </div>
                                                              </h5>
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    ) : (
                                                      <tr>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          The floor has no areas
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </CardBody>
                                          </Card>
                                        );
                                      }
                                    )}
                                  </div>
                                </CardBody>
                              </Card>
                            )}
                            <div
                              style={{
                                // maxWidth: showRooms ? "25%" : "100%",
                                marginLeft: 30,
                              }}
                            >
                              <Nav
                                tabs
                                className="nav-rotate"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "0",
                                    })}
                                    onClick={handleDisplayRooms}
                                  >
                                    {showRooms ? (
                                      <i
                                        className="fas fa-angle-double-up"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-angle-double-down"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    )}
                                    <span
                                      style={{
                                        paddingLeft: "2px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {" "}
                                      {showRooms ? "Close Rooms" : "Open Rooms"}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </div>
                          </div>
                        </Col>
                        {/* <Col
                          lg="auto"
                          style={{
                            flex: 1,
                            maxWidth: showRooms ? "15%" : "100%",
                            marginLeft: 20,
                          }}
                        >
                          <div>
                            <div style={{ display: "flex" }}>
                              <Nav
                                tabs
                                className="nav-rotate"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "3",
                                    })}
                                    onClick={handleDisplayRooms}
                                  >
                                    {showRooms ? (
                                      <i
                                        className="fas fa-angle-double-up"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-angle-double-down"
                                        style={{ color: "#50a5f1" }}
                                      ></i>
                                    )}
                                    <span
                                      style={{
                                        paddingLeft: "2px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {" "}
                                      {showRooms ? "Close Rooms" : "Open Rooms"}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                      {/* <Row>
                        <Comments
                          job={jobDetails}
                          collectionName={collectionName}
                        />
                      </Row> */}
                    </Col>

                    <Col
                      lg={
                        showNotes && showRooms
                          ? "4"
                          : showNotes
                          ? "7"
                          : showRooms
                          ? "7"
                          : "9"
                      }
                    >
                      {jobDetails?.belowground?.rooms?.length > 0 &&
                        jobDetails?.belowground?.rooms?.find(
                          floor => floor.subItems.length > 0
                        ) && (
                          <Checklist
                            job={jobDetails}
                            canEditViewAndSign={canEditViewAndSign()}
                            type={"belowground"}
                            roomId={belowGroundRoomId}
                            canEdit={canEdit()}
                            canUpload={canUpload()}
                            canSign={canSign()}
                            collectionName={collectionName}
                          />
                        )}
                    </Col>
                    <Col>
                      <div>
                        <div style={{ display: "flex" }}>
                          <Nav
                            tabs
                            className="nav-rotate"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === "1",
                                })}
                                onClick={handleDisplayNotes}
                              >
                                {showNotes ? (
                                  <i
                                    className="fa fa-angle-double-down"
                                    style={{ color: "#50a5f1" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fas fa-angle-double-up"
                                    style={{ color: "#50a5f1" }}
                                  ></i>
                                )}
                                <span style={{ paddingLeft: "7px" }}>
                                  {" "}
                                  Saved Notes
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                        {showNotes && (
                          <div
                            style={{ marginLeft: "30px", marginTop: "-60px" }}
                          >
                            <NotesLibrary />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Row style={{ height: "28rem" }}>
                      <Comments
                        job={jobDetails}
                        collectionName={collectionName}
                      />
                    </Row>
                  </Row>
                </TabPane>
              </TabContent>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}

const UseHeader = ({ title, history, jobId }) => {
  const path = history?.location?.pathname?.includes("inspectionactive")
    ? "inspectionactive"
    : "active";
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">
            <Button
              color="link"
              onClick={() => {
                history.push(`/${path}/${jobId}`);
              }}
              style={{ transform: "translateY(-5%)" }}
            >
              <i
                className="bx bx-caret-left"
                style={{ transform: "translateY(4%)" }}
              ></i>
              Back
            </Button>
            {title}
          </h4>
        </div>
      </Col>
    </Row>
  );
};
