import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { getDownloadURL } from "common/utils";
import React, { useState ,useEffect} from 'react';
import "../../CertCertificate.scss";
const styles = StyleSheet.create({
  image: {
    marginVertical: 4,
    marginHorizontal: 4,
    height: 170,
    width: 170,
  },
  container: {
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    flexGrow: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  sigText: {
    margin: 1,
    marginRight: 5,
    fontSize: 12,
    textAlign: "left",
    maxWidth:170,
  },
  sigTimeText: {
    margin: 1,
    fontSize: 10,
    textAlign: "left",
  },
});

    
    
const updateImageCount = (imagesLength) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const imageCount = localStorage.getItem('IMAGE_COUNT') ?? 0;
    const newCount = Number(imageCount) + imagesLength;
    localStorage.setItem('IMAGE_COUNT', newCount);
    return newCount;
  }
  return imagesLength;
};
export const DisplayImagesInRow = ({ images }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const updatedCount = updateImageCount(images.length);
    setCount(updatedCount);
  }, [images]);

  let imagesPerRow = 3;
  let res = [];
  let imageCount = count;

  for (let i = 0, j = images.length; i < j; i += imagesPerRow) {
    let temparray = images.slice(i, i + imagesPerRow);
    const rows = temparray.map((image, index) => {
      imageCount++;
      return (
        <View
          key={index}
          style={{
            alignSelf: "flex-start",
             backgroundColor:"#F5F6F7"

          }}
          className="no-page-break"
          wrap={false}
        >
          <Image
            key={image.id}
            style={styles.image}
            src={getDownloadURL(image.resizedImagePath)}
            alt="missedimage"
          />
          <Text style={styles.sigText}>{`Photo ${count}`}</Text>
          <Text style={styles.sigTimeText}>{image?.updatedDate || ""}</Text>
          <Text style={styles.sigText}>{image?.userName || ""}</Text>
        </View>
      );
    });
    res.push(<View style={styles.row}>{rows}</View>);
  }
  return <View style={styles.container}>{res}</View>;
};