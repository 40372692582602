import React, { useState } from 'react';
import Papa from 'papaparse';
import { useTable } from 'react-table';

const CsvViewer = ({ fileUrl }) => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCSV = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const result = await reader.read();
            const text = new TextDecoder().decode(result.value);

            Papa.parse(text, {
                header: true,
                complete: (results) => {
                    const columns = Object.keys(results.data[0]).map((key) => ({
                        Header: key,
                        accessor: key
                    }));
                    setColumns(columns);
                    setData(results.data);
                },
                error: (error) => {
                    throw new Error(error.message);
                }
            });
        } catch (error) {
            console.error('Error fetching or parsing the CSV file:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchCSV();
    }, [fileUrl]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} style={{ border: '1px solid black', padding: '8px' }}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()} style={{ border: '1px solid black', padding: '8px' }}>
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default CsvViewer;
