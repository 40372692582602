import { CardTitle } from "reactstrap";
import CertCertificate from "../CertCertificate";

export const DisplayDocuments = ({ job, canEdit,collectionName }) => {
  return (
    <>
      <CardTitle>Documents</CardTitle>
      {job?.certificate?.length > 0 ? (
        <div
          className={job?.certificate?.length > 3 ? "scrollable-content" : ""}
        >
          {[...job?.certificate]?.reverse().map(certificate => {
            return (
              <CertCertificate
                key={certificate.id+certificate.docNo.toString()}
                certificate={certificate}
                job={job}
                canEdit={canEdit}
                collectionName={collectionName}
              />
            );
          })}
        </div>
      ) : (
        <div className="no-documents">
          <p
            style={{
              color: "grey",
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            No documents found
          </p>
        </div>
      )}
    </>
  );
};
