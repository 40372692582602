import {
  ADD_TASK,
  DELETE_TASK,
  FETCH_COMPANY_TASKS_SUCCESS,
  FETCH_COMPANY_TASKS,
} from "./actionTypes";
import { tasks } from "./data";

const initialState = {
  tasks: tasks,
  loading: false,
  error: null,
};

export default function clientsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_COMPANY_TASKS:
      return {
        ...state,
        loading: true,
      };
    case ADD_TASK:
      return {
        ...state,
        users: [...state.tasks, payload],
      };
    case DELETE_TASK:
      return {
        ...state,
        users: [...state.tasks.filter(tsk => tsk.id !== payload)],
      };
    case FETCH_COMPANY_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: payload,
      };

    default:
      return state;
  }
}
