import { memo, useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import "./Certificate.scss";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import {
  getJobReportTemplates,
  deleteMultipleSelectedJobReportTemplatesChecklist,
  addNewListItemToDB,
} from "services";
import { useSelector } from "react-redux";
import duplicateItemsInInspectionTemplate from "pages/jobs/jobCreation/checklist/duplicateItemsInInspectionTemplate";
import cuid from "cuid";
import { OverRideJobReportTemplate, jobReportAlert } from "./OverRideChecklist";
import DeleteConfirmationPromptWithCheckBox from "../../../../../components/Common/DeleteConfirmationPromptWIthCheckBox";
import "../../../jobCreation/checklist/addSection.scss";
import CloseIcon from "@mui/icons-material/Close";
import {
  createNewJobReportTemplate,
  updateJobReportTemplate,
} from "./updateJobReportTemplate";
import CreateTemplateModel from "./CreateTemplateModel";
import { PLACE_REPORT_END } from "common/constants";
import { updateSaveButtonStatus } from "./handleDynamicFieldsInTemplate";

export const JobReportGeneration = ({
  showCertModal,
  setShowCertModal,
  onGenerate,
  job,
  jobType,
  collectionName,
  loading,
  setLoading,
  floor,
  areas,
  progress
}) => {
  const [jobReportTemplate, setJobReportTemplate] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [templateName, setTemplateName] = useState("");
  const [showOverrideAlert, setShowOverrideAlert] = useState(false);
  const [showOverrideChoiceAlert, setShowOverrideChoiceAlert] = useState(false);
  const [duplicateItem, setDuplicateItem] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isChecklistChanged, setIsChecklistChanged] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const buttonStyle = {
    padding: "0.6rem",
    width: "7rem",
    marginRight: "10px",
  };
  const { currentUserProfile } = useSelector(state => state.profile);
  const updateMyChecklist = async () => {
    const ChecklistSnapshot = await getJobReportTemplates(
      currentUserProfile?.orgAccessId
    );
    const jobTemplateData = ChecklistSnapshot?.docs?.map(doc => doc.data());
    setMyCheckLists([...jobTemplateData]);
  };
  const handleSave = () => {
    const newElement = {
      name: "",
      description: "",
      id: cuid(),
      selectedValue: PLACE_REPORT_END,
    };
    if (editIndex !== -1) {
      setJobReportTemplate(prevJobReportTemplate => {
        const updatedItems = [
          ...(prevJobReportTemplate.items || prevJobReportTemplate),
        ];
        updatedItems?.items?.forEach(item => {
          item.id = cuid();
          item.selectedValue = PLACE_REPORT_END;
        });
        updatedItems[editIndex] = newElement;

        return prevJobReportTemplate.items
          ? { ...prevJobReportTemplate, items: updatedItems }
          : updatedItems;
      });
      setEditIndex(-1); // Reset the edit index
    } else {
      setJobReportTemplate(prevJobReportTemplate => {
        const updatedItems = [
          ...(prevJobReportTemplate.items || prevJobReportTemplate),
          newElement,
        ];
        updatedItems?.items?.forEach(item => {
          item.id = cuid();
          item.selectedValue = PLACE_REPORT_END;
        });
        return prevJobReportTemplate.items
          ? { ...prevJobReportTemplate, items: updatedItems }
          : updatedItems;
      });
    }
    toast.success("Field added successfully.", {
      autoClose: 2000,
    });
    setDirty(true);
    setIsChecklistChanged(true);
  };
  const CheckListOverWriteDialog = jobReportAlert();
  const saveTemplate = async () => {
    setDirty(true);
    const JobReportTemplateSnapshot = await getJobReportTemplates(
      currentUserProfile?.orgAccessId
    );
    const jobTemplateData = JobReportTemplateSnapshot?.docs?.map(doc =>
      doc.data()
    );
    const jobTemplateWithSameName = jobTemplateData?.find(
      jobTemplate =>
        jobTemplate.templateName.toLowerCase() === templateName.toLowerCase()
    );
    if (
      jobTemplateWithSameName &&
      templateId === null &&
      jobTemplateWithSameName?.templateName === templateName
    ) {
      setShowOverrideAlert(true);
      setDirty(true);
      return;
    }
    let totalDuplicateListObjects = [];
    (jobReportTemplate?.items || jobReportTemplate).forEach(item => {
      const data = duplicateItemsInInspectionTemplate([item]);
      if (data.length) totalDuplicateListObjects.push(data);
      (jobReportTemplate.items || jobReportTemplate)?.map(listItem => {
        if (!isDuplicate(listItem.name)) {
          addNewListItemToDB(
            { ...listItem, id: cuid(), selectedValue: PLACE_REPORT_END },
            currentUserProfile?.orgAccessId
          );
        }
        return { ...listItem, uploaded: false };
      });
    });
    let isEmpty = totalDuplicateListObjects =>
      Array.isArray(totalDuplicateListObjects) &&
      totalDuplicateListObjects.every(isEmpty);
    if (
      totalDuplicateListObjects.length > 0 &&
      !isEmpty(totalDuplicateListObjects)
    ) {
      setDuplicateItem(totalDuplicateListObjects[0][0]["name"]);
      totalDuplicateListObjects = [];
      return;
    }
    updateMyChecklist();
    if (jobReportTemplate && templateId === null && templateName) {
      createNewJobReportTemplatesChecklistTemplate(templateName);
      return;
    }
    if (isChecklistChanged || isDirty) {
      setShowOverrideChoiceAlert(true);
      return;
    }
    updateExistingJobReportChecklistTemplate();
  };

  const createNewJobReportTemplatesChecklistTemplate =
    createNewJobReportTemplate(
      jobReportTemplate,
      setJobReportTemplate,
      currentUserProfile,
      setShowOverrideChoiceAlert,
      templateName,
      setTemplateId
    );

  const updateExistingJobReportChecklistTemplate = updateJobReportTemplate(
    jobReportTemplate,
    templateName,
    currentUserProfile,
    setShowOverrideChoiceAlert,
    setIsChecklistChanged,
    templateId,
    updateMyChecklist
  );

  const CheckListOverWriteChoice = OverRideJobReportTemplate(buttonStyle);
  const isDuplicate = useCallback(
    value => {
      if (jobReportTemplate.length <= 0) {
        return false;
      }
      let findOne = (jobReportTemplate?.items || jobReportTemplate)?.filter(
        item => item.name?.toLowerCase() === value.toLowerCase()
      );
      return findOne && findOne.length > 0;
    },
    [jobReportTemplate]
  );
  useEffect(() => {
    if (!showCertModal) {
      setLoading(false);
      setJobReportTemplate([]);
      setTemplateName("");
    }
  }, [showCertModal]);

  useEffect(() => {
    if (modalVisible) {
      handleSave();
      setModalVisible(false);
    }
  }, [modalVisible]);
  const [myCheckLists, setMyCheckLists] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showReplaceAlert, setShowReplaceAlert] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);

  const deleteSelectedChecklist = useCallback(async () => {
    const newChecklists = [
      ...myCheckLists.filter(item => item.checked === true),
    ];
    const checklistIds = newChecklists.map(item => item.id);
    const toastMessage =
      checklistIds.length === 1
        ? "The checklist is deleted!"
        : "The checklists are deleted!";

    setShowDeleteAlert(false);
    await deleteMultipleSelectedJobReportTemplatesChecklist(
      checklistIds,
      currentUserProfile?.orgAccessId
    )
      .then(() => {
        toast.success(toastMessage, {
          autoClose: 2000,
        });
      })
      .catch(() => {
        toast.error("Failed while deleting the checklist", {
          autoClose: 2000,
        });
      });
    updateMyChecklist();
  }, [myCheckLists]);

  useEffect(() => {
    updateMyChecklist();
  }, []);

  const getItemById = useCallback(
    id => {
      const [res] = myCheckLists.filter(item => item.id === id);
      return res;
    },
    [myCheckLists]
  );
  const clearJobReportTemplate = () => {
    setJobReportTemplate([]);
    setTemplateName("");
    setTemplateId(_templateId => null);
    setShowReplaceAlert(false);
    setDirty(true);
  };
  const onDragEnd = result => {
    const newItem = getItemById(result.id);
    newItem?.items?.forEach(item => {
      if (!item.id) item.id = cuid();
      item.selectedValue = PLACE_REPORT_END;
    });
    if (newItem?.id === templateId) {
      setJobReportTemplate(newItem.items);
      setDirty(false);
      return;
    }
    if (jobReportTemplate?.length === 0) {
      setTemplateName(newItem.templateName);
      setJobReportTemplate(newItem.items);
      updateSaveButtonStatus(newItem.items || [], setDirty);
      setTemplateId(newItem.id);
      setDirty(_isDirty => true);
      return;
    }
    if (templateName?.trim() === newItem?.templateName.trim()) {
      setJobReportTemplate([...jobReportTemplate, ...newItem.items]);
      updateSaveButtonStatus(newItem.items || [], setDirty);
      setDirty(false);
      return;
    }
    setShowReplaceAlert(true);
    setChecklistItems(newItem);
    CheckListReplaceSweetAlert({
      setShowReplaceAlert,
    });
    setDirty(false);
    return;
  };

  const CheckListReplaceSweetAlert = ({ setShowReplaceAlert }) => {
    const closeModal = () => {
      setShowReplaceAlert(false);
    };

    const handleAppend = () => {
      let newJobReportTemplate = [
        ...(jobReportTemplate?.items || jobReportTemplate),
      ];
      for (let i = 0; i < checklistItems?.items?.length; i++) {
        const newItem = {
          name: checklistItems?.items[i]?.name,
          description: checklistItems?.items[i]?.description,
          id: cuid(),
          selectedValue: PLACE_REPORT_END,
        };
        newJobReportTemplate.push(newItem);
      }
      setJobReportTemplate(newJobReportTemplate);
      setShowReplaceAlert(false);
      setDirty(false);
    };

    const handleReplace = () => {
      setTemplateId(_templateId => {
        return checklistItems.id;
      });
      checklistItems?.items?.forEach(item => {
        item.id = cuid();
        item.selectedValue = PLACE_REPORT_END;
      });
      setJobReportTemplate([...checklistItems.items]);
      setTemplateName(checklistItems.templateName);
      updateSaveButtonStatus(checklistItems.items || [], setDirty);
      setDirty(true);
      setShowReplaceAlert(false);
    };

    return (
      <SweetAlert
        warning
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside
        title="Do you want to add to the current template or replace it ?"
        onCancel={closeModal}
      >
        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            backgroundColor: "white",
            top: "1rem",
            right: "1rem",
            border: "none",
          }}
        >
          <CloseIcon
            style={{
              color: "gray",
            }}
          />
        </button>
        <div
          style={{
            padding: "1rem 0",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            color="warning"
            style={{ ...buttonStyle, color: "white" }}
            onClick={handleAppend}
          >
            Add
          </Button>
          <Button color="success" style={buttonStyle} onClick={handleReplace}>
            Replace
          </Button>
        </div>
      </SweetAlert>
    );
  };

  return (
    <>
      {showOverrideAlert && (
        <CheckListOverWriteDialog setShowOverrideAlert={setShowOverrideAlert} />
      )}
      {showOverrideChoiceAlert && (
        <CheckListOverWriteChoice
          setVisibility={setShowOverrideChoiceAlert}
          onUpdate={updateExistingJobReportChecklistTemplate}
          onCreateNew={createNewJobReportTemplatesChecklistTemplate}
          setNewName={setTemplateName}
        />
      )}
      {showDeleteAlert && (
        <DeleteConfirmationPromptWithCheckBox
          label={
            "I understand that deleting is permanent and cannot be undone."
          }
          onCancel={() => {
            setShowDeleteAlert(false);
          }}
          onConfirm={deleteSelectedChecklist}
        />
      )}
      {showReplaceAlert && (
        <CheckListReplaceSweetAlert setShowReplaceAlert={setShowReplaceAlert} />
      )}
      {showCertModal && (
        <CreateTemplateModel
        progress={progress}
          loading={loading}
          setLoading={setLoading}
          setShowCertModal={setShowCertModal}
          jobReportTemplate={jobReportTemplate}
          setJobReportTemplate={setJobReportTemplate}
          templateName={templateName}
          setTemplateName={setTemplateName}
          setModalVisible={setModalVisible}
          onDragEnd={onDragEnd}
          onGenerate={onGenerate}
          myCheckLists={myCheckLists}
          saveTemplate={saveTemplate}
          isDirty={isDirty}
          job={job}
          jobType={jobType}
          collectionName={collectionName}
          clearJobReportTemplate={clearJobReportTemplate}
          setDirty={setDirty}
          floor={floor}
          areas={areas}
          setIsChecklistChanged={setIsChecklistChanged}
        />
      )}
    </>
  );
};
export default memo(JobReportGeneration);
