
import axios from "axios";
import { CLOUD_FUNCTION_URL } from "common/constants";
export const userName = user => {
  if (user?.firstName) {
    if (user?.lastName) {
      return user?.firstName + " " + user?.lastName;
    } 
      return user?.firstName;
  }
  return user?.displayName;
};
export const userProfileName = user => {
  if (user?.firstName) {
    if (user?.lastName) {
      return user?.firstName[0] + user?.lastName[0];
    } 
      return user?.firstName[0]+ user?.firstName[1];
  }
  return user?.displayName[0]+ user?.displayName[1];
};
export async function orgIdentityCodeUpdationForAllUsers(orgId, value) {
  try {
    await axios.post(`${CLOUD_FUNCTION_URL}/orgIdentityCodeUpation`, {
      orgId: orgId,
      orgIdentityCode: value || "",
    });
  } catch (error) {
    if (error.response) {
      console.log("------error: ", error.response.data);
    }
    console.log("-----error: ", error);
  }
}

