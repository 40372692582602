import React from 'react';
import './gridContainer.css';

export function GridContainer({children, columns}) {
  return (
    <ul
      className={"GridContainer"}
      style={
        {
          '--col-count': columns,
        }
      }
    >
      {children}
    </ul>
  );
}
