import {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_ERROR,
} from "./actionTypes";

export function fetchDocuments() {
  return {
    type: FETCH_DOCUMENTS,
  };
}

export function fetchDocumentsSuccess(documents) {
  return {
    type: FETCH_DOCUMENTS_SUCCESS,
    payload: documents,
  };
}

export function fetchDocumentsError(error) {
  console.log(error);
  return {
    type: FETCH_DOCUMENTS_ERROR,
    payload: error,
  };
}
