export const duplicateItemsInInspectionTemplate = items => {
  const copiedItems = _.cloneDeep(items);
  const checkListItems = copiedItems.filter(item => !item.holdPoint);
  const processedItems = copiedItems.map(item => {
    item.name = item.name.trim().toLowerCase();
    return item;
  });
  const counts = _.countBy(processedItems, "name");
  const duplicateValues = _.filter(
    processedItems,
    item => counts[item.name] > 1
  );
  return duplicateValues;
};
export default duplicateItemsInInspectionTemplate;
