import { AVATAR_URL } from "common/constants";
import { Card, CardBody, Media } from "reactstrap";

export default function JobDetail({ job }) {
  return (
    <Card>
      <CardBody>
        <Media>
          <img
            src={`${AVATAR_URL}&name=${job?.client?.displayName}`}
            alt=""
            className="avatar-sm me-4 rounded-circle"
          />
          <Media className="overflow-hidden" body>
            <h5 className="text-truncate font-size-15">{job.jobNo}</h5>
            <h5 className="text-truncate font-size-13">
              {job.client.displayName}
            </h5>
            <p className="text-muted">{job.jobAddress}</p>
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
}
