import useFirestoreDoc from "common/hooks/useFirestoreDoc";
import { useEffect, useState } from "react";
import { fetchJobFromDb } from "services";
import JobsOverview from "./JobsOverview";

export default function ActiveJobsOverview(props) {
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(props?.location?.state);
  const {
    match: { params },
  } = props;
  useFirestoreDoc({
    query: () =>  fetchJobFromDb(params.id),
    onStart: () => () => setLoading(true),
    onSuccess: job => {
      setJobDetails(job);
      setLoading(false);
    },
    onError: error => setError(error),
    deps: [params],
  });

  useEffect(() => {
    if (redirectUrl)
      localStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
    else setRedirectUrl(JSON.parse(localStorage.getItem("redirectUrl")));
  }, [redirectUrl]);
  return (
    <JobsOverview
      loading={loading}
      jobDetails={jobDetails}
      redirectUrl={redirectUrl}
      collectionName={"jobs"}
      key={props?.location?.key}
    />
  );
}