import TextField from "@mui/material/TextField";
import crossMark from "assets/images/icons8/icons8-gray-cross.png";
import dragReorder from "assets/images/icons8/icons8-scroll.png";
import { getItemStyle, getListStyle } from "common/checklistUtils/dndStyleUtil";
import { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { getIcon } from "common/data/rooms";

export default function RoomUnit(props) {
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return (
    <Droppable droppableId={`${props.type}`} type={`${props.allowed}`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
          className="room-unit"
        >
          {props.subItems.map((item, index) => {
            return (
              <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    className="display-unit"
                  >
                    <span>
                      <img
                        onClick={() => {
                          props.onDelete(item.id);
                        }}
                        src={crossMark}
                        style={{
                          height: "14px",
                          padding: "2px",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                    <span>
                      <img
                        src={getIcon(item.type)}
                        style={{
                          height: "25px",
                          marginRight: "5px",
                          marginTop: "5px",
                          transform: "translate(0, -5px)",
                        }}
                      />
                    </span>
                    <RoomUnitComponent item={item} props={props} />
                    <span {...provided.dragHandleProps}>
                      <img
                        src={dragReorder}
                        style={{
                          height: "24px",
                          width: "10px",
                          marginTop: "2px",
                          transform: "translate(0, -5px)",
                        }}
                      />
                    </span>
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
export const RoomUnitComponent = ({ item, props }) => {
  const [roomUnitName, setRoomUnitName] = useState(item.content);

  return (
    <TextField
      multiline={true}
      variant="standard"
      id="standard-textarea"
      className="floor-container-name"
      placeholder="Add room name"
      error={Boolean(roomUnitName)}
      helperText={roomUnitName ? "" : "*Required"}
      InputProps={{ disableUnderline: true }}
      value={roomUnitName}
      onChange={e => {
        setRoomUnitName(e.target.value);
      }}
      onBlur={() => {
        if (roomUnitName) {
          props.onChange(roomUnitName.trim(), item.id, props.type);
        } else {
          setRoomUnitName(item.content);
        }
      }}
    />
  );
};
