import { Image, Text, View } from "@react-pdf/renderer";
import { FooterStyleSheet } from "./FooterStyleSheet";
import CoverPageFooter from "./CoverPageFooter";
import { memo } from "react";

const styles = FooterStyleSheet();
const Footer = ({ orgInfo, title, islogoRequired }) => {
  return (
    <>
      <CoverPageFooter orgInfo={orgInfo} />
      <View
        style={{ ...styles.footer, flexDirection: "row", alignItems: "center" }}
        fixed
      >
        {orgInfo?.photoURL && islogoRequired && (
          <Image style={styles.logo} src={orgInfo?.photoURL} />
        )}
        <Text
          style={{
            ...styles.footer,
            textAlign: "left",
            marginLeft: 40,
            color: "#1F4E68",
          }}
        >
          {title}
        </Text>
        <Text
          style={styles.footer}
          render={({ pageNumber }) => `${pageNumber}`}
        />
      </View>
    </>
  );
};

export default memo(Footer);
