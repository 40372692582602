import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { memo } from "react";
const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: 10,
  },
  description: {
    width: "100%",
  },
  title: {
    flexDirection: "row",
    alignSelf: "center",
    fontSize: 16,
    fontWeight: 600,
  },
  logo: {
    width:100,
    height: 75,
  },
  fieldContainer: {
    flexDirection: "row",
  },
  fieldName: {
    fontWeight: 600,
    color: "#072535",
    fontSize: 14,
  },
  fieldValue: {
    fontSize: 14,
  },
});

const ESignature = ({ certificate,includeChecklist }) => {
  const { createdUserInfo: user } = certificate;

  return (
    <View style={styles.container} fixed={!includeChecklist} wrap={includeChecklist}>
      {user?.signURL && (
        <View wrap break>
        <Image
          style={styles.logo}
          src={user?.signURL}
        />
        </View>
      )}
      <View style={styles.fieldContainer} wrap break>
        <Text style={styles.fieldName}>Report Generated By: </Text>
        {user?.firstName &&
        user?.lastName &&
        user?.firstName?.trim() !== "" &&
        user?.lastName?.trim() !== "" ? (
          <Text
            style={styles.fieldValue}
          >{`${user?.firstName} ${user?.lastName}`}</Text>
        ) : (
          <Text style={styles.fieldValue}>{`${user?.displayName}`}</Text>
        )}
      </View>
      <View style={styles.fieldContainer} wrap break>
        <Text style={styles.fieldName}>Document #: </Text>
        <Text style={styles.fieldValue}>{certificate.docNo}</Text>
      </View>
      <View style={styles.fieldContainer} wrap break>
        <Text style={styles.fieldName}>Generated on: </Text>
        <Text style={{ ...styles.fieldValue,marginBottom:15 }}>{certificate.certificateDate}</Text>
      </View>
    </View>
  );
};
export default memo(ESignature);
