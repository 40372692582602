import firebase from "../config/firebase";
const db = firebase.firestore();

export function setUserProfileData(user) {
  return db
    .collection("users")
    .doc(user.uid)
    .set({
      displayName: user?.displayName || null,
      firstName: user?.firstName || null,
      lastName: user?.lastName || null,
      email: user.email,
      photoURL: user.photoURL || null,
      registered: user.registered || false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      validUntil: firebase.firestore.FieldValue.serverTimestamp(),
      type: user.type || null,
    });
}

export function getUserProfile(userId) {
  return db.collection("users").doc(userId);
}

export function updateUserInDb(user) {
  return db.collection("users").doc(user.id).update(user);
}

export async function updateUserProfile(profile) {
  const user = firebase.auth().currentUser;
  try {
    if (user.displayName !== profile.displayName) {
      await user.updateProfile({
        displayName: profile.displayName,
      });
    }
    if (profile.photoURL && user.photoURL !== profile.photoURL) {
      await user.updateProfile({
        photoURL: profile.photoURL,
      });
    }
    return await db.collection("users").doc(user.uid).update(profile);
  } catch (error) {
    throw error;
  }
}

export async function bulkUserAdd(users) {
  let usersInfo = {};
  try {
    await users.forEach(async user => {
      await setUserProfileData({
        uid: user.id,
        id: user.id,
        ...user,
      });
    });
  } catch (error) {
    // Do nothing for now
  }
}
