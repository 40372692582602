import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector/lib/esm/AppSearchAPIConnector";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchInvitedJobsFromDb, fetchJobsFromDb } from "services";
import { fetchJobs, fetchJobsError, fetchJobsSuccess } from "store/actions";
import organisationStatus from "utils/organisationStatus";
import { JOB_NUMBER } from "common/constants";
import { getConfiguration } from "searchuicomponents/configuration";
import SweetAlert from "react-bootstrap-sweetalert";
import ProgressBar from "searchuicomponents/ProgressBar";
import { MetaTags } from "react-meta-tags";
import Dashboard from "components/jobs-ui/DashBoardUi";
export function ActiveJobs() {
  const { currentUserProfile } = useSelector(state => state.profile);
  const [searchType, setSearchType] = useState(
    sessionStorage.getItem("ActiveSearchType") || JOB_NUMBER
  );
  const [jobupdate, setJobUpdate] = useState("");
  const dispatch = useDispatch();
  const isOrganisationActive = organisationStatus(currentUserProfile);
  useFirestoreCollection({
    query: () =>
      isOrganisationActive
        ? fetchJobsFromDb("jobs")
        : fetchInvitedJobsFromDb(
            "jobs",
            currentUserProfile?.orgAccessId || null
          ),
    onStart: () => dispatch(fetchJobs()),
    onSuccess: docs => dispatch(fetchJobsSuccess(docs)),
    onError: error => dispatch(fetchJobsError(error)),
    deps: [dispatch],
  });
  const connector = new AppSearchAPIConnector({
    searchKey: process.env.REACT_APP_ELASTIC_SEARCH_PRIVATE_KEY,
    engineName: process.env.REACT_APP_ELASTIC_SEARCH_ACTIVE_ENGINE,
    endpointBase: process.env.REACT_APP_ELASTIC_SEARCH_URL,
    cacheResponses: false,
  });
  const config = getConfiguration(connector, searchType, currentUserProfile);
  const getTitleOfSweetAlert = () => {
    if (jobupdate === "archive") {
      return "Job Archiving is in Progress...";
    }
    if (jobupdate === "delete") {
      return "Job Deletion is in Progress...";
    }
  };
  const title = getTitleOfSweetAlert();
  useEffect(() => {
    sessionStorage.setItem("ActiveSearchType", searchType);
  }, [searchType]);
  return (
    <>
      {jobupdate?.length ? (
        <SweetAlert
          title={title}
          timeout={4000}
          showConfirm={false}
          onConfirm={() => {
            setJobUpdate("");
          }}
        >
          <ProgressBar />
        </SweetAlert>
      ) : (
        <>
          <MetaTags>
            <title>Jobs | Active</title>
          </MetaTags>
          <Dashboard
            config={config}
            searchType={searchType}
            setSearchType={setSearchType}
            pathname={"active"}
            setJobUpdate={setJobUpdate}
            isLinkEnabled={true}
            collectionName={"jobs"}
          />
        </>
      )}
    </>
  );
}