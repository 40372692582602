import {
  FETCH_INSPECTION_CUSTOM_LISTITEM,
  FETCH_INSPECTION_CUSTOM_LISTITEM_FAILURE,
  FETCH_INSPECTION_CUSTOM_LISTITEM_SUCCESS,
  FETCH_INSPECTION_DEFAULT_LISTITEM,
  FETCH_INSPECTION_DEFAULT_LISTITEM_FAILURE,
  FETCH_INSPECTION_DEFAULT_LISTITEM_SUCCESS,
  FETCH_INSPECTION_SAVED_CHECKLIST,
  FETCH_INSPECTION_SAVED_CHECKLIST_SUCCESS,
  FETCH_INSPECTION_SAVED_CHECKLIST_FAILURE
} from "./actionTypes";

const initialState = {
  inspectionDefaultLoading: false,
  inspectionCustomLoading:false,
  inspectionSavedLoading: false,
  inspectionCustomListItems: [],
  inspectionDefaultListItems: [],
  inspectionSavedChecklists: [],
  inspectionErrorCustom: null,
  inspectionErrorDefault: null,
  inspectionErrorSaved: null
};

export default function setupReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_INSPECTION_CUSTOM_LISTITEM:
      return {
        ...state,
        inspectionDefaultLoading: true,
      };
    case FETCH_INSPECTION_DEFAULT_LISTITEM:
      return {
        ...state,
        inspectionCustomLoading: true,
      };
    case FETCH_INSPECTION_SAVED_CHECKLIST:
      return {
        ...state,
        inspectionSavedLoading: true,
      };
    case FETCH_INSPECTION_CUSTOM_LISTITEM_FAILURE:
      return {
        ...state,
        error: payload,
        inspectionCustomLoading: false,
      };
    case FETCH_INSPECTION_DEFAULT_LISTITEM_FAILURE:
      return {
        ...state,
        error: payload,
        inspectionDefaultLoading: false,
      };
    case FETCH_INSPECTION_CUSTOM_LISTITEM_SUCCESS:
      return {
        ...state,
        inspectionCustomListItems: payload,
        inspectionCustomLoading: false,
      };
    case FETCH_INSPECTION_DEFAULT_LISTITEM_SUCCESS:
      return {
        ...state,
        inspectionDefaultListItems: payload,
        inspectionDefaultLoading: false,
      };
    case FETCH_INSPECTION_SAVED_CHECKLIST_SUCCESS:
      return {
        ...state,
        inspectionSavedChecklists: payload,
        inspectionSavedLoading: false,
      };
    case FETCH_INSPECTION_SAVED_CHECKLIST_FAILURE:
      return {
        ...state,
        inspectionErrorSaved: payload,
        inspectionSavedLoading: false,
      };
    default:
      return state;
  }
}
