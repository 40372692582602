import React, { useState, useRef } from "react";
import Dropzone from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import cuid from "cuid";
import firebase from "./../../config/firebase";
import { updateUserProfile } from "./../../services/index";
import {
  Row,
  Col,
  Card,
  CardBody,
  Progress,
  Modal,
  Form,
  UncontrolledAlert,
} from "reactstrap";
// Redux
import { useSelector } from "react-redux";

export default function UploadAvatar({
  editAvatar,
  setEditAvatar,
  companyProfile,
  updateCompanyProfile,
}) {
  const editorRef = useRef(null);
  const storageRef = firebase.storage().ref();

  const [selectedFiles, setselectedFiles] = useState([]);
  const [percent, setPercent] = useState(10);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  const onClickSave = () => {
    try {
      if (editorRef && editorRef.current) {
        // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
        // drawn on another canvas, or added to the DOM.
        setProcessing(true);
        const canvas = editorRef.current.getImage();
        canvas.toBlob(blob => uploadToFirebase(blob));
        // If you want the image resized to the canvas size (also a HTMLCanvasElement)
        //const canvasScaled = this.editor.getImageScaledToCanvas();
      }
    } catch (error) {
      setError(error);
    }
  };

  const goClear = () => {
    setEditAvatar(false);
    setPercent(0);
    setselectedFiles([]);
    setError(null);
    setProcessing(false);
  };

  const uploadToFirebase = file => {
    if (!file) return;
    // location in storage you want to create/send file to
    const filePath = `${companyProfile.id}/photos/${cuid()}`;
    const uploadTask = storageRef.child(filePath).put(file);

    uploadTask.on(
      "state_changed",
      snap => {
        const percentUploaded = Math.round(
          (snap.bytesTransferred / snap.totalBytes) * 100
        );
        setPercent(percentUploaded);
      },
      err => {
        console.error(err);
        setError(error);
      },
      () => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(async downloadUrl => {
            await updateCompanyProfile({
              ...companyProfile,
              photoURL: downloadUrl,
            });
            goClear();
          })
          .catch(err => {
            console.error(err);
            setError(error);
          });
      }
    );
  };

  return (
    <Modal isOpen={editAvatar} style={{ maxWidth: 374 }}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit Profile Photo
        </h5>
        <button
          type="button"
          onClick={goClear}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="justify-content-center">
          <Col className="col-12">
            <Card className="d-flex justify-content-center align-items-center">
              <CardBody>
                <Form>
                  <AvatarEditor
                    ref={editorRef}
                    width={250}
                    height={250}
                    borderRadius={125}
                    image={selectedFiles[0]}
                  />
                  <Dropzone
                    onDrop={acceptedFiles => {
                      setselectedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="avatar-dropzone">
                        <div
                          className="needsclick mt-2"
                          style={{
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} accept="image/*" />
                          <div className="mb-3"></div>
                          <h4>Drop file here or click here to upload.</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="">
          {processing && !error && (
            <Progress striped animated color="primary" value={percent} />
          )}
          {processing && error && (
            <>
              <Progress striped animated color="danger" value={percent} />
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                <i className="mdi mdi-block-helper me-2"></i>Something went
                wrong. Please try again later.
              </UncontrolledAlert>
            </>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button
          disabled={processing}
          type="button"
          onClick={goClear}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          disabled={selectedFiles.length === 0 || processing}
          type="button"
          className="btn btn-primary "
          onClick={onClickSave}
        >
          <i style={{ fontSize: "1rem" }} className="bx bxs-cloud-upload" />{" "}
          Upload
        </button>
      </div>
    </Modal>
  );
}
