import firebase from "config/firebase";
import cuid from "cuid";
import { useEffect, useState } from "react";

import { Card, Col, Progress, Row } from "reactstrap";
// Redux
import pdfLogo from "assets/images/pdflogo.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchJobFromDbById } from "services/inspectionService";
import { userName } from "pages/platformManagement/org-creation/orgService";

export default function Uploader({ job, files, canEdit, setLoading,collectionName }) {
  const { currentUserProfile } = useSelector(state => state.profile);
  const storageRef = firebase.storage().ref();
  const db = firebase.firestore();
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    uploadToFirebase();
  }, []);

  const addAttachmentDetails = async () => {
    try {
      setLoading(true);
      await db.runTransaction(async txn => {
        const jobRef = fetchJobFromDbById(collectionName,job.id);
        const doc = await txn.get(jobRef);
        const jobData = doc.data();
        files?.map(file => {
          if (jobData.manualCertificates) {
            jobData.manualCertificates = [
              {
                id: file.id,
                name: file.name,
                type: file.type,
                url: file.downloadUrl,
                size: file.formattedSize,
                uid: currentUserProfile.id,
                userName: userName(currentUserProfile),
              },
              ...jobData.manualCertificates,
            ];
          } else {
            jobData.manualCertificates = [
              {
                id: file.id,
                name: file.name,
                url: file.downloadUrl,
                type: file.type,
                size: file.formattedSize,
                uid: currentUserProfile.id,
              },
            ];
          }
        });
        
        job.manualCertificates = jobData.manualCertificates;
        const manualCerificateUpdate = { manualCertificates: jobData.manualCertificates };
        txn.update(jobRef, manualCerificateUpdate);
        setLoading(false);
      });
    } catch (error) {
      console.log("Failed to add certificate details to the job", error);
      setLoading(false);
    }
  };

  const uploadToFirebase = async () => {
    await Promise.all(
      files?.map(file => {
        return new Promise(resolve => {
          const fileId = cuid();
          file.id = fileId;
          const filePath = `${job.orgId}/${job.jobNo}/userUploadedCertificates/${fileId}`;
          const uploadTask = storageRef.child(filePath).put(file, {
            contentType: file.type,
          });

          uploadTask.on(
            "state_changed",
            snap => {
              const percentUploaded = Math.round(
                (snap.bytesTransferred / snap.totalBytes) * 100
              );
              file.percent = percentUploaded;
              setPercent(percentUploaded);
            },
            err => {
              console.error(err);
              setError(err);
            },
            () => {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(async downloadUrl => {
                  file.downloadUrl = downloadUrl;
                  resolve(downloadUrl);
                })
                .catch(err => {
                  console.error(err);
                  setError(err);
                });
            }
          );
        });
      })
    );
    addAttachmentDetails();
  };

  const Message = ({ children }) => {
    const [show, setShow] = useState(true);

    // On componentDidMount set the timer
    useEffect(() => {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setPercent(-1);
        setShow(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    }, []);

    // If show is false the component will return null and stop here
    if (!show) {
      return null;
    }

    // If show is true this will be returned
    return <>{children}</>;
  };

  const FileInfo = () => {
    return files.map((file, i) => {
      return (
        <div key={i}>
          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
          <div className="p-2">
            <Row className="align-items-center">
              <Col className="col-auto">
                <img
                  data-dz-thumbnail=""
                  height="80"
                  className="avatar-sm rounded bg-light"
                  alt={file.name}
                  src={file.type === "application/pdf" ? pdfLogo : file.preview}
                />
              </Col>
              <Col>
                <Link to="#" className="text-muted font-weight-bold">
                  {file.name}
                </Link>
                <p className="mb-0">
                  <strong>{file.formattedSize}</strong>
                </p>
              </Col>
            </Row>
          </div>
          <div className="p-1">
            {file.percent === 100 ? (
              <Progress
                className="progress-sm"
                striped
                animated
                color="success"
                value={file.percent}
              />
            ) : (
              <Progress
                className="progress-sm"
                striped
                animated
                color="danger"
                value={file.percent}
              />
            )}
          </div>
          </Card>
        </div>
      );
    });
  };
  if (percent === -1) {
    return null;
  } else if (percent === 100) {
    return (
      <Message>
        <FileInfo />
      </Message>
    );
  } else {
    return <FileInfo />;
  }
}
