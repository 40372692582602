import React, { useRef, useState } from "react";
import * as markerjs2 from "markerjs2";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { fetchJobFromDbById } from "services";
import firebase from "config/firebase";
import cuid from "cuid";
import { useSelector } from "react-redux";
import { DownloadImage } from "pages/jobs/jobDetails/rooms/DownloadImage";
import { toast } from "react-toastify";
import ProgressLine from "components/ProgressLine";
import {
  RESIZED_IMAGES_DIMENSIONS,
  RESIZED_IMAGES_PATH,
} from "common/constants";
import SweetAlert from "react-bootstrap-sweetalert";
import { userName } from "pages/platformManagement/org-creation/orgService";

function ImageEditor({
  isGallery,
  setisGallery,
  imgRef,
  imageSrc,
  files,
  id,
  job,
  collectionName,
}) {
  const divRef = useRef(null);
  const storageRef = firebase.storage().ref();
  const db = firebase.firestore();
  const { currentUserProfile } = useSelector(state => state.profile);
  const [percent, setPercent] = useState(0);
  const downloadImage = DownloadImage();
  const [confirmAlert, setConfirmAlert] = useState(false);

  // to convert base64 to blob
  const convertBase64ToBlob = (file, mimeType) => {
    const byteCharacters = atob(file.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    return blob;
  };

  const handleConfirm = () => {
    setConfirmAlert(false);
    toggle();
  };

  const handleCancel = () => {
    setConfirmAlert(false);
  };
  const showMarkerArea = () => {
    try {
      if (imgRef.current !== null) {
        // create a marker.js MarkerArea
        const markerArea = new markerjs2.MarkerArea(imgRef.current);
        markerArea.targetRoot = divRef.current;
        markerArea.settings.defaultColor = "#000000";
        markerArea.settings.defaultColorsFollowCurrentColors = true;
        markerArea.settings.defaultFillColor = "transparent";
        markerArea.uiStyleSettings.zoomButtonVisible = true;
        markerArea.uiStyleSettings.zoomOutButtonVisible = true;

        markerArea.availableMarkerTypes = [
          "FrameMarker", 
          "FreehandMarker",
          markerjs2.ArrowMarker,
          "EllipseMarker",
          "HighlightMarker",
          "CalloutMarker",
        ]
        // attach an event handler to assign annotated image back to our image element
        markerArea.addEventListener("beforeclose", event => {
          if (event.markerArea.markers.length > 0) {
            setConfirmAlert(true);
          }else{
          event.preventDefault();
          toggle();
          }
        });

        // attach an event handler to assign annotated image back to our image element
        markerArea.addEventListener("render", async event => {
          try {
            if (imgRef.current && event.markerArea.markers.length > 0) {
              const match = event?.dataUrl?.match(/^data:(.*?);base64,/);
              const mimeType = match ? match[1] : null;

              imgRef.current.src = event?.dataUrl;
              imageSrc = event?.dataUrl;

              const blob = convertBase64ToBlob(event?.dataUrl, mimeType);

              await Promise.all(
                files?.map(file => {
                  return new Promise(async resolve => {
                    const fileId = cuid();
                    file.id = fileId;
                    const filePath = `${job?.orgId}/${job.jobNo}/documents/${fileId}`;
                    const uploadTask = storageRef.child(filePath).put(blob, {
                      contentType: file.type,
                    });

                    uploadTask.on(
                      "state_changed",
                      snap => {
                        const percentUploaded = Math.round(
                          (snap.bytesTransferred / snap.totalBytes) * 100
                        );
                        file.percent = percentUploaded;
                        setPercent(percentUploaded);
                      },
                      err => {
                        console.error(err);
                      },
                      () => {
                        uploadTask.snapshot.ref
                          .getDownloadURL()
                          .then(async downloadUrl => {
                            file.downloadUrl = downloadUrl;
                            resolve(downloadUrl);
                          })
                          .catch(err => {
                            console.error(err);
                          });
                      }
                    );
                  });
                })
              );

              await db.runTransaction(async txn => {
                const jobRef = fetchJobFromDbById(collectionName, job.id);
                const doc = await txn.get(jobRef);
                const jobData = doc.data();
                const documents = jobData.documents;
                const updatedDocuments = documents.map(doc => {
                  if (doc.id === id) {
                    files?.map(file => {
                      const resizedImagePath = `${job?.orgId}/${job.jobNo}/documents/${RESIZED_IMAGES_PATH}/${file.id}_${RESIZED_IMAGES_DIMENSIONS}`;
                      if (doc.attachments) {
                        doc.attachments.push({
                          id: file.id,
                          userId: currentUserProfile.id,
                          name: file.name,
                          type: file.type,
                          url: file.downloadUrl,
                          size: file.size,
                          resizedImagePath: resizedImagePath,
                          date: new Date(),
                          userName: userName(currentUserProfile),
                        });
                      } else {
                        doc.attachments = [
                          {
                            id: file.id,
                            name: file.name,
                            userId: currentUserProfile.id,
                            type: file.type,
                            url: file.downloadUrl,
                            size: file.size,
                            resizedImagePath: resizedImagePath,
                            date: new Date(),
                            userName: userName(currentUserProfile),
                          },
                        ];
                      }
                    });
                    return doc;
                  }
                  return doc;
                });

                job.documents = updatedDocuments;
                const documentUpdate = { documents: updatedDocuments };
                txn.update(jobRef, documentUpdate);
                toast.success("photo updated successfully !", {
                  autoClose: 2000,
                });
              });
              setisGallery(!isGallery);
            }
          } catch (err) {
            console.log(err);
          }
        });
        // launch marker.js
        markerArea.show();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggle = () => {
    setisGallery(!isGallery);
    showMarkerArea();
  };

  return (
    <Modal
      isOpen={isGallery}
      toggle={toggle}
      size="xl"
      className="modal-fullscreen"
      style={{ marginTop: "-14rem" }}
    >
      <ModalHeader toggle={toggle} style={{ justifyContent: "end" }}>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ marginTop: "-2rem" }}
        >
          <button
            type="button"
            className="btn btn-lg text-primary"
            onClick={showMarkerArea}
          >
            <i className="fas fa-pencil-alt" style={{ fontSize: "1.5rem" }}></i>
          </button>
          <button
            type="button"
            className="btn btn-lg text-success"
            onClick={() => {
              downloadImage(imageSrc, "image");
            }}
          >
            <i
              className="far fa-arrow-alt-circle-down"
              style={{ fontSize: "1.5rem" }}
            ></i>
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <Row>
        <Col sm="10" className="d-flex align-items-center justify-content-center">
            <div
              ref={divRef}
              className="d-flex align-items-center justify-content-center"
              style={{ width: "1100px", height: "590px", overflow: "hidden" }}
            >
              <img
                ref={imgRef}
                src={imageSrc}
                alt={`Annotated image from ${imageSrc}`}
                crossOrigin="anonymous"
                style={{ width: "100%", height: "100%", objectFit: "fill" }}
                onClick={() => {
                  showMarkerArea();
                }}
              />
            </div>
          </Col>
          <Col sm="2">
            <div className="d-flex flex-column align-items-start">
              <div className="d-flex align-items-center">
                <h5 className="m-1 text-danger">Symbols Legend</h5>
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-mouse-pointer"></i>
                <p className="m-1">Select Mode</p>
              </div>
              <div className="d-flex align-items-center">
                <i className="far fa-trash-alt"></i>
                <p className="m-1">Delete Changes</p>
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-undo"></i>
                <p className="m-1">Undo</p>
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-search-plus"></i>
                <p className="m-1">zoom in</p>
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-search-minus"></i>
                <p className="m-1">zoom out</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="far fa-square"></i>
                <p className="m-1">Frame marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-pencil-alt"></i>
                <p className="m-1">Freehand Marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-location-arrow fa-rotate-180"></i>
                <p className="m-1">Arrow Marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-circle"></i>
                <p className="m-1">Ellipse Marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-highlighter"></i>
                <p className="m-1">Highlight Marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="far fa-comment-alt"></i>
                <p className="m-1">Callout marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-check"></i>
                <p className="m-1">Save and Close</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-times"></i>
                <p className="m-1">Close</p>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
      {percent > 0 && (
        <ProgressLine percent={percent} setPercent={setPercent} files={files} />
      )}
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleConfirm();
          }}
          onCancel={() => {
            handleCancel();
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
    </Modal>
  );
}

export default ImageEditor;
