import {
  LISTEN_TO_CURRENT_USER_PROFILE,
  LISTEN_TO_SELECTED_USER_PROFILE,
  OPEN_SIGNATURE_PAD,
  CLOSE_SIGNATURE_PAD,
} from "./actionTypes";

export function listenToCurrentUserProfile(profile) {
  return {
    type: LISTEN_TO_CURRENT_USER_PROFILE,
    payload: profile,
  };
}

export function listenToSelectedUserProfile(profile) {
  return {
    type: LISTEN_TO_SELECTED_USER_PROFILE,
    payload: profile,
  };
}

export function showSignaturePad() {
  return {
    type: OPEN_SIGNATURE_PAD,
  };
}

export function closeSignaturePad() {
  return {
    type: CLOSE_SIGNATURE_PAD,
  };
}
