import { Image, View } from "@react-pdf/renderer";
import { FooterStyleSheet } from "./FooterStyleSheet";
import { memo } from "react";

const styles = FooterStyleSheet();
const CoverPageFooter = ({ orgInfo }) => {
  return (
    <>

      {orgInfo?.photoURL && (
        <Image
          style={styles.watermark}
          src={orgInfo?.photoURL}
          fixed
        />
      )}
     <View style={{ ...styles.footer, marginBottom:60,flexDirection: 'row' }} fixed>
        <View style={{ width: '33%', height: 15, backgroundColor: '#1F4E68' }} />
        <View style={{ width: '33%', height: 15, backgroundColor: '#377E69' }} />
        <View style={{ width: '33%', height: 15, backgroundColor: '#7FCBEF' }} />
      </View>
    </>
  );
};

export default memo(CoverPageFooter);

