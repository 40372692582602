import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "../PdfStyle.scss";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import CloseButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Progress } from "reactstrap";
import DocxViewer from "./wordInfo";
const WordModal = ({
  isOpen,
  closeModal,
  fileUrl,
  pdfName,
  pdfJob,
  progress,
  setShowCertModal,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  Modal.setAppElement("#root");
  const isPdfGeneration = disableButton && progress !== undefined;
  const handleWord = async () => {
    setDisableButton(true);
    if (progress === undefined)
      await pdfJob(fileUrl, pdfName).then(() => {
        closeModal();
      });
    await pdfJob(fileUrl).then(() => {
      setShowCertModal(false);
    });
  };
  const deviceHeight = window.innerHeight;
  const modalMaxHeight = Math.floor(deviceHeight * 0.9);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        content: { overflow: "auto", maxHeight: `${modalMaxHeight}px` },
      }}
    >
      <div>
        <CloseButton
          onClick={closeModal}
          style={{ float: "right" }}
          disabled={isPdfGeneration}
        >
          <CloseIcon />
        </CloseButton>
          <button
            onClick={handleWord}
            className="btn btn-primary  w-md mtb-2 m-2"
            style={{ float: "right" }}
            disabled={isPdfGeneration}
          >
            { "Download"}
          </button>
        <DocxViewer fileUrl={fileUrl} />
      </div>
    </Modal>
  );
};

export default WordModal;
