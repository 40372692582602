import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "../PdfStyle.scss";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import CloseButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Progress } from "reactstrap";
import { PdfInfo } from "./PdfInfo";
const PDFModal = ({
  isOpen,
  closeModal,
  pdfUrl,
  pdfName,
  pdfJob,
  progress,
  setShowCertModal,
  isTermsAndConditions,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  Modal.setAppElement("#root");
  const isPdfGeneration = disableButton && progress !== undefined;
  const handlePdf = async () => {
    setDisableButton(true);
    if (progress === undefined)
      await pdfJob(pdfUrl, pdfName).then(() => {
        closeModal();
      });
    await pdfJob(pdfUrl).then(() => {
      setShowCertModal(false);
    });
  };
  const deviceHeight = window.innerHeight;
  const modalMaxHeight = Math.floor(deviceHeight * 0.9);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        content: { overflow: "auto", maxHeight: `${modalMaxHeight}px` },
      }}
    >
      <div>
        <CloseButton
          onClick={closeModal}
          style={{ float: "right" }}
          disabled={isPdfGeneration}
        >
          <CloseIcon />
        </CloseButton>
        {!isTermsAndConditions && (
          <button
            onClick={handlePdf}
            className="btn btn-primary  w-md mtb-2 m-2"
            style={{ float: "right" }}
            disabled={isPdfGeneration}
          >
            {progress === undefined ? "Download" : "Generate"}
          </button>
        )}

        <PdfInfo pdfUrl={pdfUrl} />
        {isPdfGeneration && (
          <div className="progress-bar-container">
            <Alert color="primary" className="mb-2">
              <h4 className="alert-heading mb-2">
                Certificate/Report generation is in progress!
              </h4>
              <p className="mb-0">
                The certificate/report is being generated and this may take a
                while, so please wait until the document is ready to be
                downloaded.
              </p>
            </Alert>
            <div className="text-center mb-2">{progress}%</div>
            <Progress
              animated
              color="success"
              value={progress}
              min={0}
              max={100}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PDFModal;
