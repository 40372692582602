import firebase from "./../config/firebase";

export async function deleteImageFromFirebaseStoreByUrl(fileUrl) {
  try{
  const storageRef = firebase.storage().refFromURL(fileUrl);
  await storageRef.delete();
  }
  catch(err){
    console.log(err);
  }
}

export function uploadImageToFirebaseStorage(
  file,
  fileId,
  fileType,
  orgId,
  jobNo
) {
  const storageRef = firebase.storage().ref();
  return storageRef
    .child(`${orgId}/${jobNo}/images/${fileId}`)
    .putString(file, "base64", {
      contentType: fileType,
    });
}
