import firebase from "config/firebase";
import { fetchJobFromDbById } from "services/inspectionService";

export function documentUploadToFirebase(
  db,
  setProgress,
  genaratePDFAndUploadToFirebase,
) {
  return async (
    job,
    certificate,
    certificateDescription,
    collectionName,
    setCertificate,
    pdfUrl
  ) => {
    try {
      await db
        .runTransaction(async txn => {
          const orgRef = db.collection("orgs").doc(job.orgId);
          const doc = await txn.get(orgRef);
          const org = doc.data();
          const nextDocumentNumber = org?.nextDocumentNumber;
          txn.update(orgRef, {
            nextDocumentNumber: firebase.firestore.FieldValue.increment(1),
          });
          return nextDocumentNumber;
        })
        .then(async documentNumber => {
          certificate = {
            ...certificate,
            docNo: documentNumber,
          };
          const pdfURL = await genaratePDFAndUploadToFirebase(
            job,
            certificate,
            documentNumber,
            pdfUrl
          );
          certificateDescription = {
            ...certificateDescription,
            docNo: documentNumber,
            pdfUrl: pdfURL,
          };
          const jobRef = fetchJobFromDbById(collectionName,job.id);
          jobRef.update({
            certificate: firebase.firestore.FieldValue.arrayUnion(
              certificateDescription
            ),
          });
          setCertificate(null);
        });
    } catch (err) {
      console.log("Failed to generate reports");
      console.log(err);
    }
  };
}
export function pdfGenerationAndUploadingToFirebase(
  setProgress,
  collectionName,
  storageRef
) {
  return async (job, certificate, documentNo,pdfUrl) => {
    const blob = pdfUrl;
    const filePath = `${job.orgId}/${job.jobNo}/certificates/DocNo${documentNo}.pdf`;
    const uploadTask = storageRef.child(filePath).put(blob);
    return new Promise(resolve => {
      uploadTask.on(
        "state_changed",
        snap => {
          const percentUploaded = Math.round(
            (snap.bytesTransferred / snap.totalBytes) * 70
          );
          setProgress(percentUploaded);
          console.log(percentUploaded, "% is uploaded");
        },
        err => {
          console.error(err);
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(downloadUrl => {
              resolve(downloadUrl);
            })
            .catch(err => {
              console.error(err);
            });
        }
      );
    });
  };
}
