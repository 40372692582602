import { Card } from "@mui/material";
import React, { useState } from "react";
//i18n
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, CardBody, Col, Container, Row } from "reactstrap";
import { fetchAllOrgFromDb } from "services";
import { fetchOrgs, fetchOrgsError, fetchOrgsSuccess } from "store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CreateNewOrg from "./OrgCreation";
import "./OrgManagement.scss";
import { DisplayOrgs } from "./DisplayOrganisations";

const OrgManagement = () => {
  const { currentUserProfile } = useSelector(state => state.profile);
  const { orgs } = useSelector(state => state.org);

  const [showCreateNeworg, setShowCreateNewOrg] = useState(false);
  const [isEditOrg, setIsEditOrg] = useState(false);
  const [org, setOrg] = useState({});
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchAllOrgFromDb(),
    onStart: () => dispatch(fetchOrgs()),
    onSuccess: docs => dispatch(fetchOrgsSuccess(docs)),
    onError: error => dispatch(fetchOrgsError(error)),
    deps: [dispatch],
  });
  const sortedOrganisations=orgs?.sort((org1, org2) => {
    const org1Name =org1?.name?.toLowerCase() || "";
    const org2Name =org2?.name?.toLowerCase() || "";
    if (org1Name > org2Name) {
      return 1;
    }
    if (org1Name< org2Name) {
      return -1;
    }
    return 0;
  })
  const onOrgEdit = organisation => {
    setIsEditOrg(true);
    setOrg(organisation);
    setShowCreateNewOrg(true);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Org Creation | IB</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs
            title="Platform Management"
            breadcrumbItem="Manage Organisation"
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="12">
                      <div className="text-sm-end">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={() => {
                            setShowCreateNewOrg(true);
                            setIsEditOrg(false);
                            setOrg({});
                          }}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add Organisation
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {showCreateNeworg && (
                    <CreateNewOrg
                      showCreateNewOrg={showCreateNeworg}
                      setShowCreateNewOrg={setShowCreateNewOrg}
                      isEditOrg={isEditOrg}
                      setIsEditOrg={setIsEditOrg}
                      organisation={org}
                      setOrg={setOrg}
                      allOrgs={orgs}
                      currentUserProfile={currentUserProfile}
                    />
                  )}

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">Org Code</Th>
                            <Th data-priority="2">Org Name</Th>
                            <Th data-priority="1">Business No.</Th>
                            <Th data-priority="1">Phone</Th>
                            <Th data-priority="4">Address</Th>
                            <Th data-priority="1">Status</Th>
                            <Th data-priority="4">Actions </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {<DisplayOrgs orgs={sortedOrganisations} onOrgEdit={onOrgEdit} />}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(OrgManagement);
