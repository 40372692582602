import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert } from "@mui/material";
import { Input } from "reactstrap";

const ConfirmationPromptWithInput = ({
  confirmationText = "delete",
  textTypeToDisplay = "",
  setIsOpen = () => {},
  onConfirm = () => {},
  onCancel = () => {},
  title = "Are you sure you want to delete?",
}) => {
  const [confirmationInput, setConfirmationInput] = useState("");
  const [inputError, setInputError] = useState(false);

  const onDeleteConfirm = () => {
    if (confirmationInput.trim() !== confirmationText.trim()) {
      setInputError(true);
      return;
    }
    onConfirm();
    setIsOpen(false);
  };

  return (
    <SweetAlert
      title={title}
      warning
      showCancel
      confirmButtonText="Delete"
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      onConfirm={onDeleteConfirm}
      onCancel={onCancel}
    >
      <div>
        Please Confirm it by entering
        {textTypeToDisplay ? ` the ${textTypeToDisplay}` : ""}:{" "}
        <b>{confirmationText}</b>
      </div>
      <Input
        name="clientName"
        placeholder={confirmationText === "delete" ? "delete" : ""}
        onChange={e => setConfirmationInput(e.target.value)}
        style={{ margin: "5px" }}
      />
      {inputError && (
        <Alert severity="error" style={{ padding: "0px 5px", margin: "5px" }}>
          You didn't enter{" "}
          {textTypeToDisplay ? "the " + textTypeToDisplay : "conformation text"}{" "}
          correctly
        </Alert>
      )}
    </SweetAlert>
  );
};

export default ConfirmationPromptWithInput;
