export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"

export const LOGIN_ERROR = "LOGIN_API_ERROR"
export const REGISTER_ERROR = "REGISTER_API_ERROR";

export const REGISTER_USER = "REGISTER_USER";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_ERROR = "RESET_ERROR";
export const RESET_SUCCESS = "RESET_SUCCESS";
