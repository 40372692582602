import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AttachedFiles from "./AttachedFiles";
import Comments from "./Comments";
import Certificate from "./documents/documentsGeneration/Certificate";
import JobDetail from "./JobDetail";
import RoomsOverview from "./RoomsOverview";
import TeamMembers from "./TeamMembers";
import { UseHeader } from "./JobsOverview";

export function JobsDetailsOverView(
  history,
  redirectUrl,
  loading,
  jobDetails,
  canEdit,
  collectionName,
  setShowPaticipantsModal,
  participantsModal,
  canUpload,
  showCertModal,
  setShowCertModal,
  certificateDetails,
  setCertificateDetails,
  key
) {
  return (
    <>
      <UseHeader
        title="Job Details"
        history={history}
        redirectUrl={redirectUrl} />
      {loading && (
        <Col xl="4" lg="6" md="6" sm="12">
          <Card>
            <CardBody align="center">
              {" "}
              <div
                className="spinner-border text-primary"
                id="spinner"
                role="status"
              ></div>
              <div>Loading...</div>
            </CardBody>
          </Card>
        </Col>
      )}
      {jobDetails && (
        <>
          <Row>
            <Col lg="8">
              <JobDetail
                job={jobDetails}
                canEdit={canEdit()}
                collectionName={collectionName} />
            </Col>

            <Col lg="4">
              <TeamMembers
                job={jobDetails}
                canEdit={canEdit()}
                collectionName={collectionName}
                setShowPaticipantsModal={setShowPaticipantsModal}
                participantsModal={participantsModal} />
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <AttachedFiles
                job={jobDetails}
                canEdit={canEdit()}
                canUpload={canUpload()}
                collectionName={collectionName} />
              <Certificate
                job={jobDetails}
                canEdit={canEdit()}
                collectionName={collectionName}
                showCertModal={showCertModal}
                setShowCertModal={setShowCertModal}
                certificateDetails={certificateDetails}
                setCertificateDetails={setCertificateDetails} />
            </Col>
            <Col lg="4">
              <RoomsOverview
                job={jobDetails}
                canEdit={canEdit()}
                canUpload={canUpload()}
                collectionName={collectionName} />
            </Col>
            <Col lg="4">
              <Comments
                id={key}
                job={jobDetails}
                isChatSelected={!(
                  history.location.hash &&
                  history.location.hash === "#chat-area"
                )}
                isJobSelected={!(
                  history.location.hash &&
                  history.location.hash === "#job-details"
                )}
                collectionName={collectionName} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
