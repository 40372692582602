import { Card, Col, Progress, Row } from "reactstrap";
import { useEffect, useState } from "react";
import pdfLogo from "assets/images/pdflogo.png";

const ProgressLine = ({ percent, setPercent, files }) => {
  const Message = ({ children }) => {
    const [show, setShow] = useState(true);

    // On componentDidMount set the timer
    useEffect(() => {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setPercent(-1);
        setShow(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    }, []);

    // If show is false the component will return null and stop here
    if (!show) {
      return null;
    }

    // If show is true this will be returned
    return <>{children}</>;
  };

  const FileInfo = () => {
    return files?.map((file, i) => {
      return (
        <div key={file?.id + i.toString()}>
          <Card className="shadow-none border dz-processing dz-image-preview dz-success dz-complete">
            <div className="p-1">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <img
                    data-dz-thumbnail=""
                    height="80"
                    className="avatar-sm rounded bg-light"
                    alt={file?.name}
                    src={
                      file?.type === "application/pdf"
                        ? pdfLogo
                        : (file?.preview ?? file?.url ?? file?.imageUrl)
                    }
                  />
                </Col>
                <Col>
                  <div to="#" className="text-muted font-weight-bold">
                    {file?.name}
                  </div>
                  <p className="mb-0">
                    <strong>{file?.formattedSize}</strong>
                  </p>
                </Col>
              </Row>
            </div>
            <div className="p-1">
              {file?.percent === 100 ? (
                <Progress
                  className="progress-sm"
                  striped
                  animated
                  color="success"
                  value={file?.percent}
                />
              ) : (
                <Progress
                  className="progress-sm"
                  striped
                  animated
                  color="danger"
                  value={file?.percent}
                />
              )}
            </div>
          </Card>
        </div>
      );
    });
  };

  if (percent === -1) {
    return null;
  } else if (percent === 100) {
    return (
      <Message>
        <FileInfo />
      </Message>
    );
  } else {
    return <FileInfo />;
  }
};

export default ProgressLine;