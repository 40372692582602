import {
  FETCH_ALL_SUBSCRIPTIONS,
  FETCH_ALL_SUBSCRIPTIONS_ERROR,
  FETCH_ALL_SUBSCRIPTIONS_SUCCESS,
} from "./actionTypes";

export function fetchAllSubscriptions() {
  return {
    type: FETCH_ALL_SUBSCRIPTIONS,
  };
}

export function fetchAllSubscriptionsSuccess(subscriptions) {
  return {
    type: FETCH_ALL_SUBSCRIPTIONS_SUCCESS,
    payload: subscriptions,
  };
}

export function fetchAllSubscriptionsError(error) {
  return {
    type: FETCH_ALL_SUBSCRIPTIONS_ERROR,
    payload: error,
  };
}
