import firebase from "../config/firebase";

const db = firebase.firestore();

export function addSubscriptionToDb(subscription) {
  return db.collection("subscriptions").add(subscription);
}

export function updateSubscriptionInDb(subscription) {
  return db
    .collection("subscriptions")
    .doc(subscription.id)
    .update(subscription);
}

export function fetchSubscriptionsFromDb() {
  return db.collection("subscriptions");
}

export function fetchOrgSubscriptionInfoFromDb(orgId) {
  return db.collection("subscriptions").where("orgId", "==", orgId);
}
export async function updateOrgOwnerInJobs(jobType, user, orgId) {
  const querySnapshot = await db
    .collection(jobType)
    .where("orgId", "==", orgId)
    .get();
  const userJobIdsOfTheOrg = [];
  querySnapshot.forEach(function (doc) {
    const job = doc.data();
    userJobIdsOfTheOrg.push(doc.id);
    const existingUser = job.participants.find(
      participant => !participant.invited && participant.id === user.id
    );
    const oldOrgOwner = job.participants.find(
      user => user.role.label === "Org Owner"
    );
    const accessIds = job.accessIds.filter(id => id !== oldOrgOwner?.id);
    const participantIds = job.participantIds.filter(
      id => id !== oldOrgOwner?.id
    );
    const participants = job.participants.filter(p => p.id !== oldOrgOwner?.id);
    if (existingUser) {
      existingUser.role.label = "Org Owner";
      existingUser.role.value = "Org Owner";
      existingUser.type = "Org Owner";
      existingUser.access.label = "Full";
      existingUser.access.value = "full";
      doc.ref.update({
        accessIds: accessIds,
        participantIds: participantIds,
        participants: participants,
      });
      return;
    }
    const owner = _.cloneDeep(oldOrgOwner);
    if (owner) {
      owner.id = user.id;
      owner.displayName = user.displayName;
      owner.email = user.email;
      owner.photoURL = user.photoURL;
      doc.ref.update({
        accessIds: [...accessIds, user.id],
        participantIds: [...participantIds, user.id],
        participants: [...participants, owner],
      });
      return;
    }
  });
}
export async function updateOrgOwnerInActiveJobs(user, orgId) {
  updateOrgOwnerInJobs("jobs", user, orgId);
}
export async function updateOrgOwnerInArchivedJobs(user, orgId) {
  updateOrgOwnerInJobs("archived", user, orgId);
}
export async function updateOrgOwnerInDeletedJobs(user, orgId) {
  updateOrgOwnerInJobs("deleted", user, orgId);
}
export async function updateOrgOwnerInInspectionJobs(user, orgId) {
  updateOrgOwnerInJobs("inspection", user, orgId);
}
export async function updateOrgOwnerInDraftsJobs(user, orgId) {
  updateOrgOwnerInJobs("drafts", user, orgId);
}
