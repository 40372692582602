import {
  INACTIVE,
  MOBILE_USER,
  MOBILE_USER_LOGIN_ERROR_MESSAGE,
  USER_DO_NOT_HAVE_ANY_ACTIVE_LICENSE,
} from "common/constants";
import { isValideSubscription } from "common/utils";
import { signOutFirebase } from "services";
import { fetchOrgSubscriptionInfoFromDb } from "services/subscriptionsService";
import firebase from "./../../config/firebase";
import { dataFromSnapshot } from "./../../services/firestoreService";
import { getUserProfile } from "./../../services/profileService";
import { listenToCurrentUserProfile } from "./../actions";
import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_SUCCESS,
  LOGOUT_USER,
  REGISTER_ERROR,
  REGISTER_USER,
  RESET_ERROR,
  RESET_PASSWORD,
  RESET_SUCCESS,
} from "./actionTypes";

export const loginUser = () => {
  return {
    type: LOGIN_USER,
  };
};

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

export const loginError = error => {
  return {
    type: LOGIN_ERROR,
    payload: error,
  };
};

export const registerError = error => {
  return {
    type: REGISTER_ERROR,
    payload: error,
  };
};

export const verifyAuth = () => {
  return function (dispatch) {
    return firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const profileRef = getUserProfile(user.uid);
        profileRef.onSnapshot(snapshot => {
          const profile = dataFromSnapshot(snapshot);
          if (!profile?.registered) {
            if (user && user?.emailVerified === false) {
              dispatch(
                loginError(
                  new Error(
                    "Email not verified. Please verify your email to login."
                  )
                )
              );
              dispatch(logoutUser());
              signOutFirebase();
              return;
            }
          }
          if (profile?.type === MOBILE_USER) {
            dispatch(loginError({ message: MOBILE_USER_LOGIN_ERROR_MESSAGE }));
            signOutFirebase();
            return;
          }
          // if (profile?.orgAccessId && profile?.orgStatus !== APPROVED && profile?.type !== PLATFORM_OWNER) {
          //   dispatch(loginError({ message: USER_DO_NOT_HAVE_ANY_ACTIVE_LICENSE }));
          //   signOutFirebase();
          //   return;
          // }
          if ( profile?.orgAccessId) {
            const subscriptionsRef = fetchOrgSubscriptionInfoFromDb(
              profile.orgAccessId
            );

            subscriptionsRef.onSnapshot(snapshot => {
              const docs = snapshot.docs.map(doc => dataFromSnapshot(doc));
              const subscription = docs.length > 0 ? docs[0] : null;

              if (
                (subscription && subscription.status === INACTIVE) ||
                (subscription && !isValideSubscription(subscription))
              ) {
                dispatch(
                  loginError({
                    message: USER_DO_NOT_HAVE_ANY_ACTIVE_LICENSE,
                  })
                );
                signOutFirebase();
                return;
              } else {
                dispatchSucessfulLogin(user, profile, dispatch);
              }
            });
          }
          else
          {
            dispatchSucessfulLogin(user, profile, dispatch);
          }
        });
      } else {
        dispatch(logoutUser());
      }
    });
  };
};
const dispatchSucessfulLogin = (user, profile, dispatch) => {
  dispatch(loginSuccess(user));
  dispatch(listenToCurrentUserProfile(profile));
};
export const registerUser = () => {
  return {
    type: REGISTER_USER,
  };
};

export const resetPassword = () => {
  return {
    type: RESET_PASSWORD,
  };
};

export const resetSuccess = () => {
  return {
    type: RESET_SUCCESS,
  };
};

export const resetError = () => {
  return {
    type: RESET_ERROR,
  };
};
