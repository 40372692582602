import { getAllChecklistsRef } from "services";
export async function totalchecklistItems(job) {
  let totalItems = 0;
  let checkedItems = 0;
  const checklists = await getAllChecklistsRef("jobs", job?.id);
  checklists?.docs?.map(checklist => {
    checklist?.data()?.items?.map(item => {
      totalItems += item?.subItems?.length;
      item?.subItems?.map(subItem => {
        if (subItem?.value && subItem?.value === true) {
          checkedItems++;
        }
      });
    });
  });
  if (totalItems === 0) {
    return 0;
  }
  const checklistPercentage = Math.floor((checkedItems / totalItems) * 80);
  return checklistPercentage;
}
export async function totalInspectionChecklistItems(job) {
  let totalItems = 0;
  let checkedItems = 0;
  const checklists = await getAllChecklistsRef("inspection", job?.id);
  checklists?.docs.map(checklist => {
    checklist?.data()?.items?.map(item => {
      totalItems += item?.subItems?.length;
      item?.subItems?.map(subItem => {
        if (
          (subItem?.value && subItem?.value === "tick") ||
          subItem?.value === "cross" ||
          subItem?.value === "N/A" ||
          subItem?.value === "F/A" ||
          subItem?.value === "done"
        ) {
          checkedItems++;
        }
      });
    });
  });
  if (totalItems === 0) {
    return 0;
  }
  const checklistPercentage = Math.floor((checkedItems / totalItems) * 80);
  return checklistPercentage;
}
export function sectionSummary(rooms,certificate) {
  let totalItems = 0;
  let checkedItems = 0;
  rooms?.forEach(item => {
    item?.subItems?.map(subItem => {
      certificate[subItem?.id]?.items?.map(item =>{
        totalItems += item?.subItems?.length;
        item?.subItems?.map(subItem => {
          if (subItem?.value && subItem?.value === true) {
            checkedItems++;
          }
        })
      })
      
    });
  });
  if (totalItems === 0) {
    return {
      totalItems: 0,
      checkedItems: 0,
      failedItems: 0,
    };
  }
  return {
    totalItems,
    checkedItems,
    failedItems: totalItems - checkedItems,
  };
}export function inspectionSectionSummary(rooms,certificate) {
  let totalItems = 0;
  let passedItems = 0;
  let failedItems = 0;
  let furtherItems = 0;
  let notAppliedItems = 0;
  let doneItems = 0;
  
  rooms?.forEach(room => {
    room?.subItems?.forEach(subItem => {
      certificate[subItem?.id]?.items?.forEach(item => {
        totalItems += item?.subItems?.length;
        item?.subItems?.forEach(subItem => {
          switch (subItem?.value) {
            case "tick":
              passedItems++;
              break;
            case "cross":
              failedItems++;
              break;
            case "F/A":
              furtherItems++;
              break;
            case "N/A":
              notAppliedItems++;
              break;
            case "done":
              doneItems++;
              break;
          }
        });
      });
    });
  });
  
  if (totalItems === 0) {
    return {
      totalItems: 0,
      passedItems: 0,
      furtherItems: 0,
      doneItems: 0,
      failedItems: 0,
      notAppliedItems: 0,
    };
  }
  
  return {
    totalItems,
    passedItems,
    furtherItems,
    doneItems,
    failedItems,
    notAppliedItems,
  };
}

export function inspectionChecklistSectionItems(item) {
  let passedItems = 0;
  let failedItems = 0;
  let furtherItems = 0;
  let notAppliedItems = 0;
  let doneItems = 0;
  const totalItems = item?.subItems?.length;
  item?.subItems?.forEach(subItem => {
    if (subItem?.value === "tick") passedItems++;
    if (subItem?.value === "cross") failedItems++;
    if (subItem?.value === "F/A") furtherItems++;
    if (subItem?.value === "N/A") notAppliedItems++;
    if (subItem?.value === "done") doneItems++;
  });

  if (totalItems === 0) 
  return {
    totalItems: 0,
    passedItems: 0,
    furtherItems: 0,
    doneItems: 0,
    failedItems: 0,
    notAppliedItems: 0,
  };;
  return {
    totalItems,
    passedItems,
    furtherItems,
    doneItems,
    failedItems,
    notAppliedItems,
  };
}
export function calaculateItemsStatus(item) {
  let checkedItems = 0;
  const totalItems = item?.subItems?.length;
  item?.subItems?.map(subItem => {
    if (subItem?.value && subItem?.value === true) {
      checkedItems++;
    }
  });
  if (totalItems === 0) {
    return {
      totalItems: 0,
      checkedItems: 0,
      failedItems: 0
    };
  }
  return {
    totalItems: totalItems,
    checkedItems: checkedItems,
    failedItems: totalItems - checkedItems
};
}
export function attachmentspercentage(job) {
  let attachmentspercentage = false;
  if (job.documents) {
    job.documents.map(doc => {
      if (doc.attachments) {
        if (doc.attachments?.length > 0) {
          attachmentspercentage = true;
          return;
        }
      }
    });
  }
  return attachmentspercentage;
}
export async function totalPercentage(job) {
  let totalPercentage = 0;
  if (job?.status && job?.status === "jobs") {
    const checklistPercentage = await totalInspectionChecklistItems(job);
    totalPercentage += checklistPercentage;
  } else {
    const checklistPercentage = await totalchecklistItems(job);
    totalPercentage += checklistPercentage;
  }
  if (attachmentspercentage(job)) {
    totalPercentage += 20;
  }
  return totalPercentage;
}
