import {
  FETCH_CUSTOM_LISTITEM,
  FETCH_CUSTOM_LISTITEM_FAILURE,
  FETCH_CUSTOM_LISTITEM_SUCCESS,
  FETCH_DEFAULT_LISTITEM,
  FETCH_DEFAULT_LISTITEM_FAILURE,
  FETCH_DEFAULT_LISTITEM_SUCCESS,
  FETCH_SAVED_CHECKLIST,
  FETCH_SAVED_CHECKLIST_FAILURE,
  FETCH_SAVED_CHECKLIST_SUCCESS,
} from "./actionTypes";

export function fetchDefaultListItems() {
  return {
    type: FETCH_DEFAULT_LISTITEM,
  };
}

export function fetchCustomListItems() {
  return {
    type: FETCH_CUSTOM_LISTITEM,
  };
}

export function fetchSavedChecklist() {
  return {
    type: FETCH_SAVED_CHECKLIST,
  };
}

export function fetchDefaultListItemsSuccess(items) {
  return {
    type: FETCH_DEFAULT_LISTITEM_SUCCESS,
    payload: items,
  };
}

export function fetchCustomListItemsSuccess(items) {
  return {
    type: FETCH_CUSTOM_LISTITEM_SUCCESS,
    payload: items,
  };
}

export function fetchDefaultListItemsError(error) {
  console.log(error);
  return {
    type: FETCH_DEFAULT_LISTITEM_FAILURE,
    payload: error,
  };
}

export function fetchCustomListItemsError(error) {
  return {
    type: FETCH_CUSTOM_LISTITEM_FAILURE,
    payload: error,
  };
}

//fetchDefaultListItemsSuccess, fetchCustomListItemsSuccess, fetchDefaultListItemError, fetchCustomListItemError

export function fetchSavedChecklistSuccess(items) {
  return {
    type: FETCH_SAVED_CHECKLIST_SUCCESS,
    payload: items,
  };
}

export function fetchSavedChecklistError(error) {
  return {
    type: FETCH_SAVED_CHECKLIST_FAILURE,
    payload: error,
  };
}