import axios from "axios";
import { CLOUD_FUNCTION_URL } from "common/constants";
import { dataFromSnapshot, getOrgOwner } from "services";

const getOrgOwnerUserInfo = async (orgId) => {
  const snapshot = await getOrgOwner(orgId);
  const docs = snapshot.docs?.map(doc => dataFromSnapshot(doc));
  if (!docs || !docs.length) {
    console.log("error occured: Org owner details not found");
    throw "Org owner details not found";
  }
  const orgOwner = docs[0];
  return orgOwner;
}
export const sendEmailVerification = async (user) => {
  try
  {
    await axios.post(`${CLOUD_FUNCTION_URL}/sendEmailVerificationNotification`, {
      user
    });
   
  } catch (error)
  {
    console.log("error occured: ", error);
  }
};

export const orgAccessEmailNotificationToUser = async (
  orgName,
  user,
  hostUser
) => {
  try
  {
    await axios.post(`${CLOUD_FUNCTION_URL}/orgAccessEmailNotification`, {
      orgName,
      user,
      hostUser
    });
  } catch (error) {
    console.log("error occured: ", error);
  }
};
export const orgInvitationEmailNotificationToUser = async (
  orgName,
  user,
  hostUser
) => {
  try
  {
    await axios.post(`${CLOUD_FUNCTION_URL}/orgInvitationEmailNotification`, {
      orgName,
      user,
      hostUser
    });
  } catch (error) {
    console.log("error occured: ", error);
  }
};

  export const sendRemovalEmailNotificationToUser = async (
    orgName, orgId, job, removedUser,currentUser
  ) => {
    try {
      const orgOwner = await getOrgOwnerUserInfo(orgId);
      await axios.post(`${CLOUD_FUNCTION_URL}/sendRemoveUserEmailNotification`,
        {
          orgName,
          OrgAdminEmail: orgOwner.email,
          job,
          removedUser,
          currentUser,          
        }
      );
    } catch (error) {
      console.log("error occured: ", error);
    }
  };

  export const sendRoleChangeEmailNotificationToUser = async (
    orgName, orgId, job, user,currentUser
  ) => {
    try {
      const orgOwner = await getOrgOwnerUserInfo(orgId);
      await axios.post(`${CLOUD_FUNCTION_URL}/sendRoleChangeEmailNotification`,
        {
          orgName,
          OrgAdminEmail: orgOwner.email,
          job,
          user,
          currentUser,        
        }
      );
    } catch (error) {
      console.log("error occured: ", error);
    }
  };
