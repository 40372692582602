import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import { moveDeletedJobToActive, updateInspectionJobStatus } from "services";
import { onDeleteJob } from "./util";
import { useHistory } from "react-router-dom";

export default function DeletedMenu({
  setSearchTerm,
  job,
  setJobUpdate,
  collectionName,
}) {
  const history = useHistory();
  return (
    <UncontrolledDropdown>
      <DropdownToggle href="#" className="card-drop" tag="i">
        <i className="mdi mdi-dots-horizontal font-size-18" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-start">
        <DropdownItem
          href="#"
          onClick={() => {
            { history?.location?.pathname === '/deleted'  ? moveDeletedJobToActive(job) : updateInspectionJobStatus(job?.id,'jobs') }
            setJobUpdate("active");
            setSearchTerm("", { shouldClearFilters: false });
          }}
        >
          <i className="mdi mdi-pencil font-size-16 text-success me-1" /> Move
          to Active
        </DropdownItem>
        <DropdownItem
          href="#"
          onClick={() => {
            setJobUpdate(`delete ${job?.jobNo}`);
            onDeleteJob(job, collectionName);
          }}
        >
          <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
          Delete Forever
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}