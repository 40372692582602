import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const ConfirmationPrompt = ({
  onCancel = () => {},
  onConfirm = () => {},
  title = "Are you sure to delete?",
  renderDescription = () => null,
  confirmBtnText = "OK",
  cancelBtnText = "Cancel",
}) => {
  const onDeleteConfirm = () => {
    onConfirm();
  };

  return (
    <SweetAlert
      title={title}
      warning
      showCancel
      confirmBtnText={confirmBtnText}
      cancelBtnText={cancelBtnText}
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      onConfirm={onDeleteConfirm}
      onCancel={onCancel}
    >
      <div>{renderDescription()}</div>
    </SweetAlert>
  );
};

export default ConfirmationPrompt;
