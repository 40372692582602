import React, {forwardRef} from 'react';
import classNames from 'classnames';

import {Handle} from './Handle';

import {
  draggable,
  draggableHorizontal,
  draggableVertical,
} from './draggable-svg';
import './draggable.css';

export const Axis = {
  All: "All",
  Vertical: "Vertical",
  Horizontal: "Horizontal",
}

export const Draggable = forwardRef(
  function Draggable(
    {
      axis,
      dragOverlay,
      dragging,
      handle,
      label,
      listeners,
      translate,
      ...props
    },
    ref
  ) {
    return (
      <div
        className={classNames(
          "Draggable",
          dragOverlay && "DragOverlay",
          dragging && "Dragging",
          handle && "Handle"
        )}
        style={{
          "--translate-x": `${translate?.x ?? 0}px`,
          "--translate-y": `${translate?.y ?? 0}px`,
        }}
      >
        <button
          ref={ref}
          {...props}
          aria-label="Draggable"
          data-cypress="draggable-item"
          {...(handle ? {} : listeners)}
          tabIndex={handle ? -1 : undefined}
        >
          {axis === Axis.Vertical
            ? draggableVertical
            : axis === Axis.Horizontal
            ? draggableHorizontal
            : draggable}
          {handle ? <Handle {...(handle ? listeners : {})} /> : null}
        </button>
        {label ? <label>{label}</label> : null}
      </div>
    );
  }
);
