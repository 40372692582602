import {
  Card,
  CardContent,
  Checkbox,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
} from "@mui/material";
import classnames from "classnames";
import { buildingClassImgMap } from "common/data/clases";
import RouteLeavingGuard from "pages/jobs/jobCreation/RouteLeavingGuard";
import JobLeavingGuard from "pages/jobs/jobCreation/JobLeavingGuard";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Col,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import { updateJobDraftInDb } from "services";
import "./buildingClass.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
import "./jobCategory.css";
import {
  COMMERCIAL_CATEGORY,
  INFRASTRUCTURE_CATEGORY,
  RESIDENTIAL_CATEGORY,
} from "common/constants";
export default function BuidlingClass({
  newJob,
  setNewJob,
  next,
  prev,
  isDirty,
  setDirty,
  collectionName,
}) {
  const history = useHistory();
  const [isNext, setIsNext] = useState(false);
  const [buildingClass, setBuildingClass] = useState(newJob.buildingClass);
  const [hasInternal, setHasInternal] = useState(newJob.jobType.internal);
  const [hasExternal, setHasExternal] = useState(newJob.jobType.external);
  const [hasBelow, setHasBelow] = useState(newJob.jobType.belowground);
  const [activeTab, setactiveTab] = useState("0");
  const [isWizardExited, setIsWizardExited] = useState(false);

  useEffect(() => {
    setHasBelow(newJob.jobType.belowground);
    setHasExternal(newJob.jobType.external);
    setHasInternal(newJob.jobType.internal);
  }, [newJob.jobType]);

  useEffect(() => {
    if (newJob.jobType.internal) {
      toggle("1");
    } else if (newJob.jobType.external) {
      toggle("2");
    } else if (newJob.jobType.belowground) {
      toggle("3");
    }
  }, [newJob.jobType]);

  useEffect(() => {
    let nextFlag = hasInternal || hasExternal || hasBelow;
    if (hasInternal) {
      nextFlag =
        nextFlag &&
        buildingClass.internal.reduce((acc, obj) => {
          return acc || obj.checked;
        }, false);
    }
    if (hasExternal) {
      nextFlag =
        nextFlag &&
        buildingClass.external.reduce((acc, obj) => {
          return acc || obj.checked;
        }, false);
    }
    if (hasBelow) {
      nextFlag =
        nextFlag &&
        buildingClass.belowground.reduce((acc, obj) => {
          return acc || obj.checked;
        }, false);
    }
    setIsNext(nextFlag);
  }, [
    buildingClass.internal,
    buildingClass.external,
    buildingClass.belowground,
  ]);

  const isInternalChecked = key => {
    return buildingClass.internal.find(data => data.key === key).checked;
  };
  const isExternalChecked = key => {
    return buildingClass.external.find(data => data.key === key).checked;
  };
  const isBelowChecked = key => {
    return buildingClass.belowground.find(data => data.key === key).checked;
  };

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const onInternalChanged = (value, key) => {
    setBuildingClass({
      ...buildingClass,
      internal: buildingClass.internal.map(val => {
        if (val.key === key) {
          return { ...val, checked: value };
        }
        return val;
      }),
    });
    setDirty(true);
  };

  const onExternalChanged = (value, key) => {
    setBuildingClass({
      ...buildingClass,
      external: buildingClass.external.map(val => {
        if (val.key === key) {
          return { ...val, checked: value };
        }
        return val;
      }),
    });
    setDirty(true);
  };

  const onBelowChanged = (value, key) => {
    setBuildingClass({
      ...buildingClass,
      belowground: buildingClass.belowground.map(val => {
        if (val.key === key) {
          return { ...val, checked: value };
        }
        return val;
      }),
    });
    setDirty(true);
  };

  const onSave = () => {
    setNewJob({ ...newJob, buildingClass: buildingClass });
    updateJobDraftInDb(
      { ...newJob, buildingClass: buildingClass },
      collectionName
    );
    setDirty(false);

    let newBuildingClass = { ...buildingClass };
    newBuildingClass.internal.filter((item, index, self) => {
      return (
        self.findIndex(
          t =>
            t.key === item.key && t.text === item.text && t.value === item.value
        ) === index
      );
    });
    newBuildingClass.external.filter((item, index, self) => {
      return (
        self.findIndex(
          t =>
            t.key === item.key && t.text === item.text && t.value === item.value
        ) === index
      );
    });
    newBuildingClass.belowground.filter((item, index, self) => {
      return (
        self.findIndex(
          t =>
            t.key === item.key && t.text === item.text && t.value === item.value
        ) === index
      );
    });
    setBuildingClass(newBuildingClass);
  };

  const onNext = () => {
    onSave();
    next();
  };

  const handleDeleteClass = value => {
    setBuildingClass({
      ...buildingClass,
      internal: buildingClass.internal.filter(data => data.key !== value),
      external: buildingClass.external.filter(data => data.key !== value),
      belowground: buildingClass.belowground.filter(data => data.key !== value),
    });
    return;
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [manualClassName, setManualClassName] = useState("");
  const [manualClassDescription, setManualClassDescription] = useState("");
  const [error, setError] = useState(false);

  const buttonStyle = {
    padding: "0.6rem",
    width: "7rem",
  };

  const handleSave = e => {
    e.preventDefault();
    if (manualClassName.trim() === "") {
      setError(true);
      return;
    } else {
      setError(false);
    }
    const internalClassAlreadyExists = buildingClass.internal.find(
      classItem =>
        classItem.value.trim().toLowerCase() ===
        manualClassName.trim().toLowerCase()
    );

    const externalClassAlreadyExists = buildingClass.external.find(
      classItem =>
        classItem.value.trim().toLowerCase() ===
        manualClassName.trim().toLowerCase()
    );

    const belowgroundClassAlreadyExists = buildingClass.belowground.find(
      classItem =>
        classItem.value.trim().toLowerCase() ===
        manualClassName.trim().toLowerCase()
    );

    if (
      internalClassAlreadyExists &&
      externalClassAlreadyExists &&
      belowgroundClassAlreadyExists
    ) {
      toast.error("Class already exists.", {
        autoClose: 2000,
      });
      setError(true);
      return;
    }
    const updatedInternalClassData = {
      ...buildingClass,
      internal: [
        ...buildingClass.internal,
        {
          key: manualClassName,
          text: manualClassDescription,
          value: manualClassName,
          checked: false,
        },
      ],
      external: [
        ...buildingClass.external,
        {
          key: manualClassName,
          text: manualClassDescription,
          value: manualClassName,
          checked: false,
        },
      ],
      belowground: [
        ...buildingClass.belowground,
        {
          key: manualClassName,
          text: manualClassDescription,
          value: manualClassName,
          checked: false,
        },
      ],
    };

    setBuildingClass(updatedInternalClassData);

    toast.success("Class added successfully.", {
      autoClose: 2000,
    });
    setManualClassName("");
    setManualClassDescription("");
    setModalVisible(false);
    return;
  };

  const handleDiscard = e => {
    e.preventDefault();
    setModalVisible(false);
    setManualClassName("");
    setManualClassDescription("");
    setError(false);
  };

  const NavBar = () => {
    return (
      <Nav tabs>
        {hasInternal && (
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              {RESIDENTIAL_CATEGORY}
              <span
                className={`${
                  buildingClass.internal.reduce((acc, obj) => {
                    return acc || obj.checked;
                  }, false)
                    ? ""
                    : "yellowDot"
                }`}
              />
            </NavLink>
          </NavItem>
        )}
        {hasExternal && (
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggle("2");
              }}
            >
              {COMMERCIAL_CATEGORY}
              <span
                className={` ${
                  buildingClass.external.reduce((acc, obj) => {
                    return acc || obj.checked;
                  }, false)
                    ? ""
                    : "yellowDot"
                }`}
              />
            </NavLink>
          </NavItem>
        )}
        {hasBelow && (
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                toggle("3");
              }}
            >
              {INFRASTRUCTURE_CATEGORY}
              <span
                className={`${
                  buildingClass.belowground.reduce((acc, obj) => {
                    return acc || obj.checked;
                  }, false)
                    ? ""
                    : "yellowDot"
                }`}
              />
            </NavLink>
          </NavItem>
        )}
      </Nav>
    );
  };

  const PageNavigationButtons = () => {
    return (
      <Row
        className="clearfix"
        style={{ marginBottom: "20px", marginRight: "0px" }}
      >
        <div className="text-end">
          <button
            className="btn btn-primary w-md"
            onClick={() => prev()}
            type="button"
          >
            Previous
          </button>
          <button
            disabled={!isNext}
            className="btn btn-primary w-md mtb-2 ml-20"
            onClick={() => onSave()}
            type="button"
          >
            Save
          </button>
          <button
            disabled={!isNext}
            className="btn btn-primary w-md ml-20"
            type="button"
            onClick={() => onNext()}
          >
            Next
          </button>
        </div>
      </Row>
    );
  };

  const DisplayClass = ({ classInfo, onClassChange, isClassChecked }) => {
    return (
      <div
        className={`classContainer ${
          isClassChecked(classInfo.key) ? "classChecked" : ""
        }`}
        // onClick={() =>
        //   onClassChange(!isClassChecked(classInfo.key), classInfo.key)
        // }
      >
        <FormControlLabel
          control={
            <>
              {buildingClassImgMap[classInfo.key] && (
                <img
                  className="rounded-circle building-class"
                  style={{ marginTop: "20px" }}
                  src={buildingClassImgMap[classInfo.key]}
                  alt={classInfo.value}
                />
              )}

              <div className="classInfoContainer">
                <div className="classTextContainer">
                  <Typography variant="title" className="className">
                    {classInfo.value}
                  </Typography>
                  <Typography className="classDetails">
                    {classInfo.text}
                  </Typography>
                </div>
                <div>
                  {" "}
                  <Checkbox
                    className="checked"
                    checked={isClassChecked(classInfo.key)}
                    onChange={e =>
                      onClassChange(e.target.checked, classInfo.key)
                    }
                  />
                  {classInfo?.value === classInfo?.key ? (
                    <DeleteIcon
                      size={18}
                      color="error"
                      onClick={() => handleDeleteClass(classInfo?.key)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          }
        />
      </div>
    );
  };

  const DisplayClasses = ({ classesInfo, onClassChange, isClassChecked }) => {
    const selectedClassesCount = classesInfo.reduce((count, classInfo) => {
      return count + (classInfo.checked ? 1 : 0);
    }, 0);

    return (
      <>
        <div className="navContentContainer">
          <Typography variant="title" className="selectionMessage">
            {"Select all the appropriate class that are applied"}
          </Typography>
          <Typography variant="title" className="counter">
            {`${selectedClassesCount}/${classesInfo.length} Selected`}
          </Typography>
        </div>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {classesInfo?.map((classInfo, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <DisplayClass
                classInfo={classInfo}
                onClassChange={onClassChange}
                isClassChecked={isClassChecked}
              />
            </Grid>
          ))}
          <Grid item xs={2} sm={4} md={4}>
            <div
              className={`classContainer classContainerCenter`}
              onClick={() => setModalVisible(true)}
            >
              <AddCircleOutlineIcon
                style={{ margin: "5px", fontSize: "2.2rem" }}
              />
              Classify your job here
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <RouteLeavingGuard
        when={isDirty}
        // Navigate function
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return isDirty;
        }}
        setWizardExited={setIsWizardExited}
        saveJob={onSave}
      />
      <JobLeavingGuard
        when={!isDirty && !isWizardExited}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return !isDirty && !isWizardExited;
        }}
      />
      <Card>
        <CardContent style={{ margin: "20px" }}>
          <Typography className="heading">
            {" "}
            Classification of building{" "}
          </Typography>
          <NavBar />

          <TabContent activeTab={activeTab} className="p-3 text-muted">
            <TabPane tabId="1">
              <DisplayClasses
                classesInfo={buildingClass.internal}
                onClassChange={onInternalChanged}
                isClassChecked={isInternalChecked}
              />
            </TabPane>
            <TabPane tabId="2">
              <DisplayClasses
                classesInfo={buildingClass.external}
                onClassChange={onExternalChanged}
                isClassChecked={isExternalChecked}
              />
            </TabPane>
            <TabPane tabId="3">
              <DisplayClasses
                classesInfo={buildingClass.belowground}
                onClassChange={onBelowChanged}
                isClassChecked={isBelowChecked}
              />
            </TabPane>
            {modalVisible && (
              <SweetAlert
                showCancel={false}
                showConfirm={false}
                closeOnClickOutside
              >
                <Form>
                  <FormGroup
                    row
                    style={{ marginBottom: "1rem", marginTop: "1rem" }}
                  >
                    <Label for="class_name" sm={4}>
                      Class name
                      <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Col sm={8}>
                      <Input
                        id="class_name"
                        name="email"
                        type="text"
                        invalid={error}
                        value={manualClassName}
                        onChange={e => {
                          setManualClassName(e.target.value);
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup
                    row
                    style={{ marginBottom: "1rem", marginTop: "1rem" }}
                  >
                    <Label for="class_description" sm={4}>
                      Description
                    </Label>
                    <Col sm={8}>
                      <TextField
                        className="form-control"
                        id="class_description"
                        name="email"
                        type="text"
                        value={manualClassDescription}
                        onChange={e => {
                          setManualClassDescription(e.target.value);
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
                <div
                  style={{
                    padding: "1rem 0",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Button
                    color="success"
                    style={buttonStyle}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    color="danger"
                    style={buttonStyle}
                    onClick={handleDiscard}
                  >
                    Discard
                  </Button>
                </div>
              </SweetAlert>
            )}
          </TabContent>
        </CardContent>
      </Card>

      <PageNavigationButtons />
    </>
  );
}
