
import { ACTIVE_JOBS_DB_NAME, INSPECTION_DB_NAME} from "common/constants";
import firebase from "../config/firebase";
import { removeParticipantChatNotifications, revokeJobAccess, updateJobAccess, updateUserDatesInJob } from "./jobService";
const db = firebase.firestore();

export function fetchInspectionDraftsFromDb() {
  const user = firebase.auth().currentUser;
  const limit = 50;
  let jobsRef = db
    .collection(INSPECTION_DB_NAME)
    .orderBy("date", "desc")
    .limit(limit);
  return jobsRef
    .where("userId", "==", user?.uid)
    .where("status", "==", "draft");
}
export async function updateLinkedJobs(jobId, linkedJobs,collectionName) {
  await db.runTransaction(async txn => {
    const jobRef =fetchJobFromDbById(collectionName,jobId);
    txn.update(jobRef, {
      linkedJobs:linkedJobs
    });
  });
}
export async function updateActiveLinkedJobs(jobId,jobNo, linkedJobs) {
  const batch = db.batch();
  const jobRefs = linkedJobs.map(job => fetchJobFromDbById(ACTIVE_JOBS_DB_NAME, job.id));
  jobRefs.forEach(jobRef => {
    batch.update(jobRef, {
      linkedJobs: firebase.firestore.FieldValue.arrayUnion(...[{id:jobId,jobNo:jobNo}])
    });
  });
  await batch.commit();
}
export async function addInspectionJobToDb(job, id) {
  const user = firebase.auth().currentUser;
  try {
    await db
      .runTransaction(async txn => {
        const orgRef = db.collection("orgs").doc(job.orgId);
        const doc = await txn.get(orgRef);
        const org = doc.data();
        const nextJobNumber = org?.nextJobNumber;
        // update next job number
        txn.update(orgRef, {
          nextJobNumber: firebase.firestore.FieldValue.increment(1),
        });
        return nextJobNumber;
      })
      .then(async jobNumber => {
        // adds job to the DB with the latest job number
        if (jobNumber) {
          job = {
            ...job,
            jobNo: Number(jobNumber),
            createdByUserId: user.uid,
            date: firebase.firestore.FieldValue.serverTimestamp(),
            status: "jobs",
          };
          await db.collection(INSPECTION_DB_NAME).doc(id).update(job);
        }
      });
  } catch (error) {
    console.log("job creation failed:", error);
  }
  const savedJobRef = db.collection(INSPECTION_DB_NAME).doc(id);
  const jobDataFromDB = await savedJobRef.get();
  const jobData = jobDataFromDB.data();
  return { ...jobData, id };
}

export function updateInspectionJobDraft(draftId, updateData) {
  const user = firebase.auth().currentUser;
  return db
    .collection(INSPECTION_DB_NAME)
    .doc(draftId)
    .update({
      ...updateData,
      userId: user.uid,
      status: "draft",
      date: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

export function fetchInspectionJobFromDb(id) {
  return db.collection(INSPECTION_DB_NAME).doc(id);
}
export function fetchParticipantsDatesFromDb(collectionName,jobId) {
  return db
    .collection(collectionName)
    .doc(jobId)
    .collection("workingdates")
}
export function fetchJobFromDbById(collectionName,id){
  return db.collection(collectionName).doc(id);
}
export async function removeParticipantFromInspectionJobs(
  participantId,
  orgId
) {
  const querySnapshot = await db
    .collection(INSPECTION_DB_NAME)
    .where("participantIds", "array-contains", participantId)
    .where("orgId", "==", orgId)
    .get();
  const userJobIdsOfTheOrg = [];
  querySnapshot.forEach(function (doc) {
    const job = doc.data();
    userJobIdsOfTheOrg.push(doc.id);
    doc.ref.update({
      accessIds: job.accessIds.filter(id => id !== participantId),
      participantIds: job.participantIds.filter(id => id !== participantId),
      participants: job.participants.filter(p => p.id !== participantId),
    });
  });
  await removeParticipantChatNotifications(userJobIdsOfTheOrg);
}
export async function revokeInspectionJobAccess(userId, orgId) {
  revokeJobAccess(INSPECTION_DB_NAME, userId, orgId);
}

export async function updateInspectionJobAccess(userId, orgId) {
  updateJobAccess(INSPECTION_DB_NAME, userId, orgId);
}
export function fetchInspectionJobsWithTypeFromDb(status) {
  const user = firebase.auth().currentUser;
  let jobsRef = db.collection('inspection').orderBy("jobNo", "desc");
  return jobsRef.where("accessIds", "array-contains", user?.uid).where("status", "==", status);
}

export function fetchInvitedInspectionJobsFromDb(jobType, orgAccessId) {
  const user = firebase.auth().currentUser;
  let jobsRef = db.collection('inspection').orderBy("orgId", "desc").orderBy("jobNo", "desc");
  return jobsRef
    .where("accessIds", "array-contains", user?.uid)
    .where("orgId", "!=", orgAccessId).where("status", "==", jobType);
}

export async function updateInspectionJobStatus(jobId,status) {
  await updateUserDatesInJob(jobId,status,INSPECTION_DB_NAME,INSPECTION_DB_NAME);
  return await db
    .collection(INSPECTION_DB_NAME)
    .doc(jobId)
    .update({
      status: status,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

export function deleteInspectionJobInDb(jobId) {
  // CHEKCLIST-TODO: Delete checklist sub-collection as well
  return db.collection(INSPECTION_DB_NAME).doc(jobId).delete();
}