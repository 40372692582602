import { CHEKCLIST_COLLECTION, NO_OF_JOBS_PER_PAGE, WORKING_DATES_COLLECTION } from "common/constants";
import cuid from "cuid";
import firebase from "../config/firebase";
const db = firebase.firestore();

import {
  DRAFTS_DB_NAME,
  ACTIVE_JOBS_DB_NAME,
  ARCHIVED_JOBS_DB_NAME,
  DELETED_JOBS_DB_NAME,
  CLONED_CHECKLIST_TEXT,
} from "common/constants";
import {
  fetchJobFromDbById,
} from "./inspectionService";

const COPY_BATCH_SIZE = 25;
const DELETE_BATCH_SIZE = 400;

export function fetchDraftsFromDb() {
  const user = firebase.auth().currentUser;
  const limit = 50;
  let jobsRef = db
    .collection(DRAFTS_DB_NAME)
    .orderBy("date", "desc")
    // .limit(limit);
  return jobsRef.where("userId", "==", user?.uid);
}

// export function fetchJobsFromDb(jobType) {
//   const user = firebase.auth().currentUser;
//   let jobsRef = db.collection(jobType).orderBy("jobNo", "desc");
//   return jobsRef.where("accessIds", "array-contains", user?.uid);
// }
export function fetchJobsFromDb(jobType) {
  const user = firebase.auth().currentUser;
  const jobsRef = db.collection(jobType).orderBy("jobNo", "desc");
  return jobsRef.where("accessIds", "array-contains", user?.uid);
}

export function fetchJobsFromDbofSameOrganisation(jobType, orgAccessId) {
  const user = firebase.auth().currentUser;
  let jobsRef = db
    .collection(jobType)
    .orderBy("orgId", "desc")
    .orderBy("jobNo", "desc");
  return jobsRef
    .where("accessIds", "array-contains", user?.uid)
    .where("orgId", "==", orgAccessId);
}
export function fetchInvitedJobsFromDb(jobType, orgAccessId) {
  const user = firebase.auth().currentUser;
  let jobsRef = db
    .collection(jobType)
    .orderBy("orgId", "desc")
    .orderBy("jobNo", "desc");
  return jobsRef
    .where("accessIds", "array-contains", user?.uid)
    .where("orgId", "!=", orgAccessId);
}
export async function fetchNextPageJobs(jobType, jobNo) {
  const user = firebase.auth().currentUser;
  const jobRef = db
    .collection(jobType)
    .where("accessIds", "array-contains", user?.uid)
    .orderBy("jobNo", "desc");

  return jobRef
    .startAfter(jobNo)
    .limit(NO_OF_JOBS_PER_PAGE + 1)
    .get();
}

export async function fetchPrevPageJobs(jobType, jobNo) {
  const user = firebase.auth().currentUser;
  const jobRef = db
    .collection(jobType)
    .where("accessIds", "array-contains", user?.uid)
    .orderBy("jobNo", "desc");
  return jobRef
    .endBefore(jobNo)
    .limitToLast(NO_OF_JOBS_PER_PAGE + 1)
    .get();
}

export function fetchJobFromDb(id) {
  return db.collection("jobs").doc(id);
}

export function fetchJobsByJobNo(jobType, jobNo, userId) {
  return db
    .collection(jobType)
    .where("accessIds", "array-contains", userId)
    .where("jobNo", "==", parseInt(jobNo));
}

export async function addJobToDb(job) {
  const user = firebase.auth().currentUser;
  let savedJob;
  // if any update is in progress to org details,
  // the following transaction will get the latest org details &
  // updates the job number to the new job
  try {
    await db
      .runTransaction(async txn => {
        const orgRef = db.collection("orgs").doc(job.orgId);
        const doc = await txn.get(orgRef);
        const org = doc.data();
        const nextJobNumber = org?.nextJobNumber;
        // update next job number
        txn.update(orgRef, {
          nextJobNumber: firebase.firestore.FieldValue.increment(1),
        });
        return nextJobNumber;
      })
      .then(async jobNumber => {
        // adds job to the DB with the latest job number
        if (jobNumber) {
          job = {
            ...job,
            jobNo: Number(jobNumber),
            createdByUserId: user.uid,
            date: firebase.firestore.FieldValue.serverTimestamp(),
          };
          savedJob = await db.collection("jobs").add(job);
          console.log("job created successfully", jobNumber);
        }
      });
  } catch (error) {
    console.log("job creation failed:", error);
  }
  const savedJobRef = db.collection("jobs").doc(savedJob?.id);
  const jobDataFromDB = await savedJobRef.get();
  const jobData = jobDataFromDB.data();
  return { ...jobData, id: savedJob.id };
}

export function fetchDraftRefFromDb(docId, collectionName) {
  return db.collection(collectionName).doc(docId);
}

export function updateJobInDb(job, collectionName) {
  return db.collection(collectionName).doc(job.id).update(job);
}

export function deleteJobInDb(jobId) {
  // CHEKCLIST-TODO: Delete checklist sub-collection as well
  return db.collection("deleted").doc(jobId).delete();
}

export function deleteJob(collectionName, jobId) {
  // CHEKCLIST-TODO: Delete checklist sub-collection as well
  return db.collection(collectionName).doc(jobId).delete();
}

export function deleteDraftJobInDb(jobId, collectionName) {
  return db.collection(collectionName).doc(jobId).delete();
}

export async function moveJobToDeleted(job) {
  const destinationDocRef = db.collection(DELETED_JOBS_DB_NAME).doc();
  await destinationDocRef.set(job);
  await moveChecklistsSubCollection(
    CHEKCLIST_COLLECTION,
    ACTIVE_JOBS_DB_NAME,
    DELETED_JOBS_DB_NAME,
    job.id,
    destinationDocRef.id
  );
   await updateUserDatesInJob(job.id,"deleted",ACTIVE_JOBS_DB_NAME,DELETED_JOBS_DB_NAME)
   await moveChecklistsSubCollection(
   WORKING_DATES_COLLECTION,
    ACTIVE_JOBS_DB_NAME,
    DELETED_JOBS_DB_NAME,
    job.id,
    destinationDocRef.id
  );
  return db.collection(ACTIVE_JOBS_DB_NAME).doc(job.id).delete();
  // TODO: Go delete all events for the jobid
}

export async function moveJobToArchived(job) {
  const destinationDocRef = db.collection(ARCHIVED_JOBS_DB_NAME).doc();
  await destinationDocRef.set(job);
  await moveChecklistsSubCollection(
    CHEKCLIST_COLLECTION,
    ACTIVE_JOBS_DB_NAME,
    ARCHIVED_JOBS_DB_NAME,
    job.id,
    destinationDocRef.id
  );
   await updateUserDatesInJob(job.id,"archived",ACTIVE_JOBS_DB_NAME,ARCHIVED_JOBS_DB_NAME)
   await moveChecklistsSubCollection(
   WORKING_DATES_COLLECTION,
    ACTIVE_JOBS_DB_NAME,
    ARCHIVED_JOBS_DB_NAME,
    job.id,
    destinationDocRef.id
  );
  return db.collection(ACTIVE_JOBS_DB_NAME).doc(job.id).delete();
  // TODO: Go delete all events for the jobid
}

export async function moveDeletedJobToActive(job) {
  const destinationDocRef = db.collection(ACTIVE_JOBS_DB_NAME).doc();
  await destinationDocRef.set(job);
  await moveChecklistsSubCollection(
    CHEKCLIST_COLLECTION,
    DELETED_JOBS_DB_NAME,
    ACTIVE_JOBS_DB_NAME,
    job.id,
    destinationDocRef.id
  );
   await updateUserDatesInJob(job.id,"jobs",DELETED_JOBS_DB_NAME,ACTIVE_JOBS_DB_NAME)
  await moveChecklistsSubCollection(
   WORKING_DATES_COLLECTION,
    DELETED_JOBS_DB_NAME,
    ACTIVE_JOBS_DB_NAME,
    job.id,
    destinationDocRef.id
  );
  return db.collection(DELETED_JOBS_DB_NAME).doc(job.id).delete();
}

export async function moveArchivedJobToActive(job) {
  const destinationDocRef = db.collection(ACTIVE_JOBS_DB_NAME).doc();
  await destinationDocRef.set(job);
  await moveChecklistsSubCollection(
    CHEKCLIST_COLLECTION,
    ARCHIVED_JOBS_DB_NAME,
    ACTIVE_JOBS_DB_NAME,
    job.id,
    destinationDocRef.id
  );
  await updateUserDatesInJob(job.id,"jobs",ARCHIVED_JOBS_DB_NAME,ACTIVE_JOBS_DB_NAME)
  await moveChecklistsSubCollection(
   WORKING_DATES_COLLECTION,
    ARCHIVED_JOBS_DB_NAME,
    ACTIVE_JOBS_DB_NAME,
    job.id,
    destinationDocRef.id
  );
  return db.collection(ARCHIVED_JOBS_DB_NAME).doc(job.id).delete();
}
export async function updateUserDatesInJob(jobId, status, fromCollection,toCollection) {
  const batch = db.batch();
  console.log("status", status, "jobId", jobId, "fromCollection", fromCollection, "toCollection", toCollection);
  const workingDatesRef = db
    .collection(fromCollection)
    .doc(jobId)
    .collection("workingdates");
  const snapshot = await workingDatesRef.get();
  snapshot?.forEach(doc => {
    batch.update(doc.ref, {
      jobType: status,
      collectionName: toCollection,
    });
  });
  console.log("batch", batch,snapshot);
  await batch.commit();
}
export function getJobNumbersFromId(id, jobType) {
  return db
    .collection(jobType)
    .where("accessIds", "array-contains", id)
    .orderBy("jobNo", "desc")
    .get();
}

// export function updateRoomChecklist(jobId, jobType, areaId, checklist) {
//   const checklistUpdate = {};
//   checklistUpdate[`${jobType}.${areaId}.checklist`] = checklist;
//   return db.collection("jobs").doc(jobId).update(checklistUpdate);
// }

export async function removeParticipantFromActiveJobs(participantId, orgId) {
  const querySnapshot = await db
    .collection("jobs")
    .where("participantIds", "array-contains", participantId)
    .where("orgId", "==", orgId)
    .get();
  const userJobIdsOfTheOrg = [];
  querySnapshot.forEach(function (doc) {
    const job = doc.data();
    userJobIdsOfTheOrg.push(doc.id);
    doc.ref.update({
      accessIds: job.accessIds.filter(id => id !== participantId),
      participantIds: job.participantIds.filter(id => id !== participantId),
      participants: job.participants.filter(p => p.id !== participantId),
    });
  });
  await removeParticipantChatNotifications(userJobIdsOfTheOrg);
}

export async function removeParticipantFromArchivedJobs(participantId, orgId) {
  const querySnapshot = await db
    .collection("archived")
    .where("participantIds", "array-contains", participantId)
    .where("orgId", "==", orgId)
    .get();
  const userJobIdsOfTheOrg = [];
  querySnapshot.forEach(function (doc) {
    const job = doc.data();
    userJobIdsOfTheOrg.push(doc.id);
    doc.ref.update({
      accessIds: job.accessIds.filter(id => id !== participantId),
      participantIds: job.participantIds.filter(id => id !== participantId),
      participants: job.participants.filter(p => p.id !== participantId),
    });
  });
}

export async function removeParticipantFromDeletedJobs(participantId, orgId) {
  const querySnapshot = await db
    .collection("deleted")
    .where("participantIds", "array-contains", participantId)
    .where("orgId", "==", orgId)
    .get();
  const userJobIdsOfTheOrg = [];
  querySnapshot.forEach(function (doc) {
    const job = doc.data();
    userJobIdsOfTheOrg.push(doc.id);
    doc.ref.update({
      accessIds: job.accessIds.filter(id => id !== participantId),
      participantIds: job.participantIds.filter(id => id !== participantId),
      participants: job.participants.filter(p => p.id !== participantId),
    });
  });
}

export async function removeParticipantChatNotifications(userJobIdsOfTheOrg) {
  if (userJobIdsOfTheOrg.length === 0) return null;
  return db
    .collection("notifications")
    .where("jobId", "in", userJobIdsOfTheOrg)
    .where("type", "==", "chat")
    .get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
      });
    });
}

export async function removeChatNotificationsOfTheJob(jobId) {
  return db
    .collection("notifications")
    .where("jobId", "==", jobId)
    .where("type", "==", "chat")
    .get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
      });
    });
}

export async function updateDocumentNotes(
  jobId,
  updatedDocuments,
  collectionName
) {
  await db.runTransaction(async txn => {
    const jobRef = fetchJobFromDbById(collectionName, jobId);
    txn.update(jobRef, {
      documents: updatedDocuments,
    });
  });
}

export async function updateClientName(
  jobId,
  updatedClientName,
  collectionName
) {
  const clientNameUpdate = {};
  clientNameUpdate["client.displayName"] = updatedClientName;
  await updateClientDetails(jobId, clientNameUpdate, collectionName);
}

export async function updateJobAddress(jobId, updatedAddress, collectionName) {
  const addressUpdate = {};
  addressUpdate["jobAddress"] = updatedAddress;
  await updateClientDetails(jobId, addressUpdate, collectionName);
}

export async function updateJobName(jobId, updatedJobName, collectionName) {
  const jobName = {};
  jobName["jobName"] = updatedJobName;
  await updateClientDetails(jobId, jobName, collectionName);
}
export async function updateCustomJobNo(
  jobId,
  updatedCustomJobNo,
  collectionName
) {
  const customjobno = {};
  customjobno["customjobno"] = updatedCustomJobNo;
  await updateClientDetails(jobId, customjobno, collectionName);
}

export async function updateClientMobile(
  jobId,
  updatedClientMobile,
  collectionName,
  countryDialCode
) {
  const clientMobileUpdate = {};
  clientMobileUpdate["client.mobile"] = updatedClientMobile;
  clientMobileUpdate["client.countryDialCode"] = countryDialCode;
  await updateClientDetails(jobId, clientMobileUpdate, collectionName);
}

export async function updateClientEmail(
  jobId,
  updatedClientEmail,
  collectionName
) {
  const clientEmailUpdate = {};
  clientEmailUpdate["client.email"] = updatedClientEmail;
  await updateClientDetails(jobId, clientEmailUpdate, collectionName);
}

export async function updateClientDetails(
  jobId,
  updatedClientDetail,
  collectionName
) {
  await db.runTransaction(async txn => {
    const jobRef = fetchJobFromDbById(collectionName, jobId);
    txn.update(jobRef, updatedClientDetail);
  });
}

export async function addJobParticipants(
  jobId,
  newParticipant,
  collectionName
) {
  await db.runTransaction(async txn => {
    const jobRef = fetchJobFromDbById(collectionName, jobId);
    const doc = await txn.get(jobRef);
    const job = doc.data();
    const participantsUpdated = {};
    participantsUpdated["participants"] =
      job.participants?.length > 0
        ? [...job.participants, newParticipant]
        : [newParticipant];
    participantsUpdated["participantIds"] =
      job.participantIds?.length > 0
        ? [...job.participantIds, newParticipant.id]
        : [newParticipant.id];
    participantsUpdated["accessIds"] =
      job.accessIds?.length > 0
        ? [...job.accessIds, newParticipant.id]
        : [newParticipant.id];
    txn.update(jobRef, participantsUpdated);
  });
}

export async function revokeActiveJobAccess(userId, orgId) {
  revokeJobAccess("jobs", userId, orgId);
}

export async function revokeArchivedJobAccess(userId, orgId) {
  revokeJobAccess("archived", userId, orgId);
}

export async function revokeDeletedJobAccess(userId, orgId) {
  revokeJobAccess("deleted", userId, orgId);
}

export async function updateActiveJobAccess(userId, orgId) {
  updateJobAccess("jobs", userId, orgId);
}

export async function updateArchivedJobAccess(userId, orgId) {
  updateJobAccess("archived", userId, orgId);
}

export async function updateDeletedJobAccess(userId, orgId) {
  updateJobAccess("deleted", userId, orgId);
}

export async function revokeJobAccess(jobType, userId, orgId) {
  const querySnapshot = await db
    .collection(jobType)
    .where("accessIds", "array-contains", userId)
    .where("orgId", "==", orgId)
    .get();
  const userJobIdsOfTheOrg = [];
  querySnapshot.forEach(function (doc) {
    const job = doc.data();
    userJobIdsOfTheOrg.push(doc.id);
    const isUserAddedToJob = job.participants.some(
      user => !user.invited && user.id === userId
    );
    if (isUserAddedToJob) {
      doc.ref.update({
        accessIds: job.accessIds.filter(id => id !== userId),
      });
    }
  });
}

export async function updateJobAccess(jobType, userId, orgId) {
  const querySnapshot = await db
    .collection(jobType)
    .where("participantIds", "array-contains", userId)
    .where("orgId", "==", orgId)
    .get();
  const userJobIdsOfTheOrg = [];
  querySnapshot.forEach(function (doc) {
    const job = doc.data();
    userJobIdsOfTheOrg.push(doc.id);
    const isUserAddedToJob = job.participants.some(
      user => !user.invited && user.id === userId
    );
    if (isUserAddedToJob) {
      doc.ref.update({
        accessIds: [...job.accessIds, userId],
      });
    }
  });
}

export async function removeJobParticipants(
  jobId,
  participant,
  collectionName
) {
  await db.runTransaction(async txn => {
    const jobRef = fetchJobFromDbById(collectionName, jobId);
    const doc = await txn.get(jobRef);
    const job = doc.data();
    const participantsUpdated = {};
    participantsUpdated["participants"] =
      job.participants?.length > 0
        ? job.participants.filter(user => user.id !== participant.id)
        : [];
    participantsUpdated["participantIds"] =
      job.participantIds?.length > 0
        ? job.participantIds.filter(id => id !== participant.id)
        : [];
    participantsUpdated["accessIds"] =
      job.accessIds?.length > 0
        ? job.accessIds.filter(id => id !== participant.id)
        : [];
    txn.update(jobRef, participantsUpdated);
  });
}

export async function updateJobParticipant(
  jobId,
  userId,
  updatedData,
  collectionName
) {
  await db.runTransaction(async txn => {
    const jobRef = fetchJobFromDbById(collectionName, jobId);
    const doc = await txn.get(jobRef);
    const job = doc.data();
    const participants = job.participants.map(participant => {
      if (participant.id === userId) {
        return {
          ...participant,
          ...updatedData,
        };
      }
      return participant;
    });
    const participantsUpdated = { participants };
    txn.update(jobRef, participantsUpdated);
  });
}
export function fetchWorkingDatesOfparticipants(jobId, collectionName) {
  return new Promise((resolve, reject) => {
    const workingDatesRef = db
      .collection(collectionName)
      .doc(jobId)
      .collection("workingdates");
    const unsubscribe = workingDatesRef.onSnapshot(
      snapshot => {
        const workingDates = [];
        snapshot.forEach(doc => {
          const data = doc.data();
          workingDates.push({
            jobId: data?.jobId || "",
            startDate: data?.startDate || "",
            endDate: data?.endDate || "",
            userId: data?.userId || "",
            jobType: data?.jobType || "",
            collectionName: data?.collectionName || "",
          });
        });
        resolve(workingDates)
      },
      error => {
        reject(error);
      }
    );
  });
}
export async function jobDate(
  jobId,
  jobDates,
  collectionName
) {
  await db.runTransaction(async txn => {
    const jobRef = fetchJobFromDbById(collectionName, jobId);
    txn.update(jobRef, {
      startDate: jobDates?.startDate || "",
      endDate: jobDates.endDate || "",
    });
  });
}


export async function addWorkingDatesForParticipants(
  jobId,
  newParticipant,
  collectionName
) {
  try {
    await db.runTransaction(async transaction => {
      const jobRef = db
        .collection(collectionName)
        .doc(jobId)
        .collection("workingdates");
      const participantId = newParticipant.userId;
      const participantRef = jobRef.doc(participantId);
      const participantSnapshot = await transaction.get(participantRef);

      if (participantSnapshot.exists) {
        transaction.update(participantRef, {
          startDate: newParticipant.startDate,
          endDate: newParticipant.endDate,
        });
        console.log("Participant updated successfully!");
      } else {
        transaction.set(participantRef, newParticipant);
        console.log("New participant document created successfully!");
      }
    });
    console.log("Transaction completed successfully!");
  } catch (error) {
    console.error("Transaction failed: ", error);
  }
}
export async function deleteWorkingDatesForParticipants(
  jobId,
  userId,
  collectionName
)
{
  await db
    .collection(collectionName)
    .doc(jobId)
    .collection("workingdates")
    .doc(userId)
    .delete();
}
export function updateJobDraft(draftId, updateData) {
  const user = firebase.auth().currentUser;
  return db
    .collection("drafts")
    .doc(draftId)
    .update({
      ...updateData,
      userId: user.uid,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

export function updateJobRooms(jobId, jobType, rooms, collectionName) {
  const roomsUpdate = {};
  roomsUpdate[`${jobType}.rooms`] = rooms;
  return db.collection(collectionName).doc(jobId).update(roomsUpdate);
}

// Limitations: https://firebase.google.com/docs/firestore/query-data/queries#limitations_2
export async function getAreaChecklistsRef(collectionName, jobDocId, areaIds) {
  return await db
    .collection(collectionName)
    .doc(jobDocId)
    .collection(CHEKCLIST_COLLECTION)
    .where("areaId", "in", areaIds)
    .get();
}
export async function getAreaChecklistRef(collectionName, jobDocId, areaId) {
  return await db
    .collection(collectionName)
    .doc(jobDocId)
    .collection(CHEKCLIST_COLLECTION)
    .where("areaId", "==", areaId)
    .get();
}
export async function getAllChecklistsRef(collectionName, jobDocId) {
  return await db
    .collection(collectionName)
    .doc(jobDocId)
    .collection(CHEKCLIST_COLLECTION)
    .get();
}
export async function fetchChecklist(collectionName, jobDocId, areaId) {
  const querySnapshot = await getAreaChecklistsRef(collectionName, jobDocId, [
    areaId,
  ]);

  let checklist;
  querySnapshot.forEach(function (doc) {
    checklist = { ...doc.data(), documentId: doc.id };
  });
  return checklist;
}

export function fetchChecklistByDocumentId(collectionName, jobId, documentId) {
  return db
    .collection(collectionName)
    .doc(jobId)
    .collection(CHEKCLIST_COLLECTION)
    .doc(documentId);
}

export async function updateJobDraftInDb(draft, collectionName) {
  const user = firebase.auth().currentUser;
  const docRef = db.collection(collectionName).doc(draft.id);
  await db.runTransaction(async txn => {
    const draftDoc = await txn.get(docRef);
    if (!draftDoc.exists) {
      txn.set(docRef, {
        ...draft,
        areas: draft.areas || {},
        userId: user.uid,
        date: firebase.firestore.FieldValue.serverTimestamp(),
      });
      return;
    }
    const draftData = draftDoc.data();
    const areasChecklistFlagObject = draftData?.areas || {};
    txn.update(docRef, {
      ...draft,
      areas: areasChecklistFlagObject,
      userId: user.uid,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    });
  });
}

export async function setAreaChecklistFlag(jobDocId, collectionName, areaIds) {
  const docRef = db.collection(collectionName).doc(jobDocId);
  await db.runTransaction(async txn => {
    const jobDoc = await txn.get(docRef);
    const job = jobDoc.data();
    const areasChecklistFlagObject = job?.areas || {};
    areaIds.forEach(areaId => {
      areasChecklistFlagObject[areaId] = {
        isChecklistExists: true,
      };
    });
    txn.update(docRef, {
      areas: areasChecklistFlagObject,
    });
  });
}

export async function updateAreaChecklist(
  jobDocId,
  collectionName,
  checklistData,
  areaId
) {
  const querySnapshot = await getAreaChecklistsRef(collectionName, jobDocId, [
    areaId,
  ]);
  const checklistsRef = db
    .collection(collectionName)
    .doc(jobDocId)
    .collection(CHEKCLIST_COLLECTION);

  if (querySnapshot.empty) {
    console.log("creating new checklist for the area");
    checklistsRef.add({
      areaId,
      items: checklistData.items,
      title: checklistData.title || "",
      id: cuid(),
      templateId: checklistData.id || cuid(),
    });
    return;
  }
  querySnapshot.forEach(doc => {
    if (checklistData.items.length === 0) {
      const jobDocRef = db.collection(collectionName).doc(jobDocId);
      const areaChecklistFlagsToDelete = {};
      areaChecklistFlagsToDelete[`areas.${areaId}`] =
        firebase.firestore.FieldValue.delete();
      jobDocRef.update(areaChecklistFlagsToDelete);
      doc.ref.delete();
      return;
    } else {
      doc.ref
        .update({
          items: checklistData.items,
          title: checklistData.title,
          templateId: checklistData.id,
        })
        .then(() => {
          console.log("Checklist updated successfully!");
        })
        .catch(error => {
          console.error("Error updating the checklist:", error);
        });
    }
  });
}

export async function deleteChecklists(jobDocId, collectionName, areaIds) {
  const jobDocRef = db.collection(collectionName).doc(jobDocId);
  const areaChecklistFlagsToDelete = {};
  areaIds?.forEach(areaId => {
    areaChecklistFlagsToDelete[`areas.${areaId}`] =
      firebase.firestore.FieldValue.delete();
  });
  jobDocRef.update(areaChecklistFlagsToDelete);
  const clonedAreaIds = _.cloneDeep(areaIds);
  while (clonedAreaIds.length) {
    const batchOfAreaIds = clonedAreaIds.splice(0, 10);
    const batch = db.batch();
    getAreaChecklistsRef(collectionName, jobDocId, [...batchOfAreaIds]).then(
      async querySnapshot => {
        if (querySnapshot.empty) {
          return;
        }
        querySnapshot.forEach(doc => {
          batch.delete(doc.ref);
        });
        await batch.commit();
      }
    );
  }
}

export async function deleteChecklistSubCollection(jobDocId, collectionName,subCollection) {
  let batch = db.batch();
  const jobDocRef = db.collection(collectionName).doc(jobDocId);
  const checklistSubCollectionRef = jobDocRef.collection(subCollection);
  const collectionSnapshot = await checklistSubCollectionRef.get();
  if (collectionSnapshot.empty) {
    return;
  }
  const collectionSize = collectionSnapshot.size;
  let operationCount = 0;
  for (let i = 0; i < collectionSnapshot.docs.length; i++) {
    const doc = collectionSnapshot.docs[i];
    batch.delete(doc.ref);
    operationCount++;
    if (
      operationCount === DELETE_BATCH_SIZE ||
      operationCount === collectionSize
    ) {
      operationCount = 0;
      await batch.commit();
      batch = db.batch();
    }
  }
  if (operationCount > 0) {
    await batch.commit();
  }
}

export async function moveChecklistsSubCollection(
  collectionName,
  fromCollection,
  toCollection,
  fromDocId,
  toDocId
) {
  await copyChecklistsSubCollection(
    collectionName,
    fromCollection,
    toCollection,
    fromDocId,
    toDocId
  );
  deleteChecklistSubCollection(fromDocId, fromCollection,collectionName);
  console.log("draft checkist are moved to the job");
}

export async function copyChecklistsSubCollection(
  collectionName,
  fromCollection,
  toCollection,
  fromDocId,
  toDocId
) {
  copyChecklistsCollectionWithTransformation(
    collectionName,
    fromCollection,
    toCollection,
    fromDocId,
    toDocId
  );
}

export async function cloneChecklistsSubCollection(
  collectionName,
  fromCollection,
  toCollection,
  fromDocId,
  toDocId
) {
  copyChecklistsCollectionWithTransformation(
    collectionName,
    fromCollection,
    toCollection,
    fromDocId,
    toDocId,
    cloneChecklist
  );
}

const cloneChecklist = checklist => {
  const checklistItemsWithoutUserData = checklist.items.map(area => ({
    ...area,
    subItems: area.subItems.map(section => ({
      ...section,
      attachments: [],
      comments: [],
      notes: "",
      signatures: [],
      value: false,
    })),
  }));
  return {
    areaId: checklist.areaId,
    templateId: checklist.templateId,
    title: checklist.title ?? `${CLONED_CHECKLIST_TEXT}-${cuid()}`,
    id: checklist.id,
    items: checklistItemsWithoutUserData,
  };
};

export async function copyChecklistsCollectionWithTransformation(
  collectionName,
  fromCollection,
  toCollection,
  fromDocId,
  toDocId,
  transformChecklist = null
) {
  console.error("collection name",collectionName,fromCollection,toCollection)
  let batch = db.batch();
  const draftChecklistsRef = db
    .collection(fromCollection)
    .doc(fromDocId)
    .collection(collectionName);
  const jobChekclistsRef = db
    .collection(toCollection)
    .doc(toDocId)
    .collection(collectionName);
  const collectionSnapshot = await draftChecklistsRef.get();
  const collectionSize = collectionSnapshot.size;
  let operationCount = 0;
  for (let i = 0; i < collectionSnapshot.docs.length; i++) {
    const cl = collectionSnapshot.docs[i].data();
    const newChecklistRef = jobChekclistsRef.doc();
    batch.set(
      newChecklistRef,
      transformChecklist ? transformChecklist(cl) : cl
    );
    operationCount++;
    if (
      operationCount === COPY_BATCH_SIZE ||
      operationCount === collectionSize
    ) {
      operationCount = 0;
      await batch.commit();
      console.log("Batch commited");
      batch = db.batch();
    }
  }
  if (operationCount > 0) {
    await batch.commit();
  }
  console.log("copied checklists successfully");
}

export async function updateChecklistItem(
  collectionName,
  jobId,
  checklistDocumentId,
  itemType,
  sectiontId,
  itemId,
  value
) {
  const checklistRef = fetchChecklistByDocumentId(
    collectionName,
    jobId,
    checklistDocumentId
  );
  await db.runTransaction(async txn => {
    const checklistDoc = await txn.get(checklistRef);
    const checklist = checklistDoc.data();
    const updatedChecklistItems = checklist?.items.map(section => {
      if (section.id === sectiontId) {
        section.subItems = section.subItems.map(item => {
          if (item.id === itemId) {
            // itemType takes either notes or checkbox as a value
            // as updating these are fields having same lines of repeatative code block
            itemType.toLowerCase() === "notes"
              ? (item.notes = value)
              : (item.value = value);
          }
          return item;
        });
      }
      return section;
    });
    txn.update(checklistRef, { items: updatedChecklistItems });
  });
}

export function getChecklistRef(jobId, areaId, collectionName) {
  return db
    .collection(collectionName)
    .doc(jobId)
    .collection(CHEKCLIST_COLLECTION)
    .where("areaId", "==", areaId);
}

export async function getChecklistAttachments(jobId, collection, areaIds) {
  const clonedAreaIds = _.cloneDeep(areaIds);
  let attachments = [];
  while (clonedAreaIds.length) {
    const batch = clonedAreaIds.splice(0, 10);
    await getAreaChecklistsRef(collection, jobId, [...batch]).then(
      querySnapshot => {
        if (querySnapshot.empty) {
          return;
        }
        querySnapshot.forEach(doc => {
          const checklist = doc.data();
          if (checklist) {
            checklist?.items?.forEach(section => {
              section?.subItems?.forEach(item => {
                attachments = [...attachments, ...item?.attachments];
              });
            });
          }
        });
      }
    );
  }
  return attachments;
}

export async function replicateChecklistsInDb(
  jobDocId,
  roomIdsWithSameName,
  checklist,
  checklistName,
  currentAreaId,
  templateId,
  collectionName
) {
  const checklistsRef = db
    .collection(collectionName)
    .doc(jobDocId)
    .collection(CHEKCLIST_COLLECTION);
  roomIdsWithSameName.forEach(async areaId => {
    if (areaId === currentAreaId) {
      return;
    }
    const batch = db.batch();
    const querySnapshot = await checklistsRef
      .where("areaId", "==", areaId)
      .get();
    if (querySnapshot.empty) {
      const newChecklistRef = checklistsRef.doc();
      batch.set(newChecklistRef, {
        title: checklistName,
        items: checklist,
        areaId,
        id: cuid(),
        templateId,
      });
    } else {
      querySnapshot.forEach(doc => {
        batch.update(doc.ref, {
          title: checklistName,
          items: checklist,
          id: cuid(),
          templateId,
        });
      });
    }
    await batch.commit();
  });
  await setAreaChecklistFlag(jobDocId, collectionName, roomIdsWithSameName);
}

// fetches all the area checklists & reurn them as array of objects
export async function fetchChecklistsForAreas(collection, jobDocId, areaIds) {
  if (!areaIds || !areaIds.length || !jobDocId) return [];

  const batches = [];
  const batchSize = 10;

  while (areaIds.length) {
    // firestore limits batches to 10 for "in" query
    const batch = areaIds.splice(0, batchSize);

    // add the batch request to to a queue
    batches.push(
      getAreaChecklistsRef(collection, jobDocId, [...batch]).then(results =>
        results.docs.map(result => ({ ...result.data() }))
      )
    );
  }
  return Promise.all(batches).then(content => content.flat());
}

export async function updateParticipants(jobId, participants, collectionName) {
  const participantIds = participants.map(participant => participant.id);
  const participantDocRef = db.collection(collectionName).doc(jobId);
  await participantDocRef.update({
    participants,
    participantIds,
    accessIds: participantIds,
  });
}
export async function updateJobProfileById(jobInfo) {
  try {
    await db.runTransaction(async txn => {
      const jobRef = fetchJobFromDbById(jobInfo.collectionName, jobInfo.id);
      const doc = await txn.get(jobRef);
      const job = doc.data();
      const updatedJob = { ...job, photoURL: jobInfo.photoURL };
      txn.update(jobRef, updatedJob);
    });
  } catch (error) {
    console.log(error);
  }
}

export const notFoundMessage = pathname => {
  if (pathname.includes("active")) {
    return "No job found in Active jobs, try searching in Archived or Deleted jobs";
  }
  if (pathname.includes("archive")) {
    return "No job found in Archived jobs, try searching in Active or Deleted jobs";
  }
  if (pathname.includes("delete")) {
    return "No job found in Deleted jobs, try searching in Active or Archived jobs";
  }
};
