import { DATE_FORMAT } from "common/constants";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { format } from "date-fns";
import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { fetchAllOrgFromDb } from "services";
import { fetchSubscriptionsFromDb } from "services/subscriptionsService";
import { fetchOrgs, fetchOrgsError, fetchOrgsSuccess } from "store/actions";
import {
  fetchAllSubscriptions,
  fetchAllSubscriptionsError,
  fetchAllSubscriptionsSuccess,
} from "store/subscription/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CreateSubscription from "./CreateSubscription";

const Subscriptions = () => {
  const { subscriptions } = useSelector(state => state.subscriptions);
  const [showCreateSubscription, setShowCreateSubscription] = useState(false);
  const [isEditSubscription, setEditSubscription] = useState(false);
  const [subscription, setSubscription] = useState({});
  const [subscriptionData, setSubcriptionData] = useState({});

  const { orgs } = useSelector(state => state.org);
  const dispatch = useDispatch();

  useFirestoreCollection({
    query: () => fetchAllOrgFromDb(),
    onStart: () => dispatch(fetchOrgs()),
    onSuccess: docs => dispatch(fetchOrgsSuccess(docs)),
    onError: error => dispatch(fetchOrgsError(error)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchSubscriptionsFromDb(),
    onStart: () => dispatch(fetchAllSubscriptions()),
    onSuccess: docs => dispatch(fetchAllSubscriptionsSuccess(docs)),
    onError: error => dispatch(fetchAllSubscriptionsError(error)),
    deps: [dispatch],
  });
  const onSubscriptionEdit = subscription => {
    setEditSubscription(true);
    setSubscription(subscription);
    setSubcriptionData(subscription);
    setShowCreateSubscription(true);
  };

  const displaySubscriptions = () => {
    const orgNames = {};
    orgs.forEach(org => {
      orgNames[org.id] = org.name;
    });
    const sortedSubscriptions = subscriptions?.sort(
      (organisation1, organisation2) => {
        const organisation1Name =
          orgNames[organisation1?.orgId]?.toLowerCase() || "";
        const organisation2Name =
          orgNames[organisation2?.orgId]?.toLowerCase() || "";
        if (organisation1Name > organisation2Name) {
          return 1;
        }
        if (organisation1Name < organisation2Name) {
          return -1;
        }
        return 0;
      }
    );
    return sortedSubscriptions?.map((subscription, index) => (
      <Tr key={index}>
        <Td>{index + 1}</Td>
        <Td>{orgNames[subscription?.orgId]}</Td>
        <Td>{format(subscription?.startDate, DATE_FORMAT)}</Td>
        <Td>{format(subscription?.endDate, DATE_FORMAT)}</Td>
        <Td>{subscription?.webUsersCount}</Td>
        <Td>{subscription?.mobileUsersCount}</Td>
        <Td>{subscription?.orgOwnerEmail}</Td>
        <Td
          style={
            subscription?.status === "Active"
              ? {
                  color: "green",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }
              : { color: "red", fontWeight: "bold", textTransform: "uppercase" }
          }
        >
          {subscription?.status}
        </Td>

        <Td>
          <div className="d-flex gap-3">
            <Link to="#">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() =>
                  onSubscriptionEdit({
                    ...subscription,
                    orgName: orgNames[subscription?.orgId],
                  })
                }
              ></i>
            </Link>
          </div>
        </Td>
      </Tr>
    ));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Subscriptions list</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title="Platform Management"
            breadcrumbItem="Manage Subscription"
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="12">
                      <div className="text-sm-end">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={() => {
                            setShowCreateSubscription(true);
                            setEditSubscription(false);
                            setSubscription({});
                            setSubcriptionData({});
                          }}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Create Subscription
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {showCreateSubscription && (
                    <CreateSubscription
                      showCreateSubscription={showCreateSubscription}
                      setShowCreateSubscription={setShowCreateSubscription}
                      isEditSubscription={isEditSubscription}
                      setEditSubscription={setEditSubscription}
                      subscription={subscription}
                      subscriptionData={subscriptionData}
                      setSubscription={setSubscription}
                    />
                  )}

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>Plan #</Th>
                            <Th data-priority="1">Organization Name</Th>
                            <Th data-priority="1">Start Date</Th>
                            <Th data-priority="1">End Date</Th>
                            <Th data-priority="4">Web Users #</Th>
                            <Th data-priority="6">Mobile Users #</Th>
                            <Th data-priority="6">Org owner Email</Th>
                            <Th data-priority="6">Status</Th>
                            <Th data-priority="6">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>{displaySubscriptions()}</Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Subscriptions;
