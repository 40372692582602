import firebase from "../config/firebase";
import { dataFromSnapshot } from "services";
import { updateJobInDb } from "./jobService";
import { ACTIVE_JOBS_DB_NAME, INSPECTION_DB_NAME } from "common/constants";

export async function mergeAccounts() {
  const db = firebase.firestore();
  const user = firebase.auth().currentUser;

  const snapshot = await db
    .collection("users")
    .where("email", "==", user?.email)
    .where("registered", "==", false)
    .get();
  const accounts = snapshot.docs.map(doc => dataFromSnapshot(doc));

  accounts.forEach(async account => {
    // get all job matching accessId
    const jobSnapshot = await db
      .collection("jobs")
      .where("accessIds", "array-contains", account.id)
      .get();

    updateJobsInDb(jobSnapshot, ACTIVE_JOBS_DB_NAME);
    const inspectionJobSnapshot = await db
      .collection("inspection")
      .where("accessIds", "array-contains", account.id)
      .get();
    updateJobsInDb(inspectionJobSnapshot,INSPECTION_DB_NAME);
    // Merge all notifications
    mergeUserNotifications(account.id);
    //delete the account
    db.collection("users").doc(account.id).delete();

    function updateJobsInDb(jobSnapshot, collectionName) {
      if (!jobSnapshot.empty) {
        const jobs = jobSnapshot.docs.map(doc => dataFromSnapshot(doc));
        // update the access id, participant id and participant details
        jobs.forEach(job => {
          const newJob = {
            ...job,
            accessIds: [
              ...job.accessIds.filter(accessId => accessId !== account.id),
              user.uid,
            ],
            participantIds: [
              ...job.participantIds.filter(
                participantId => participantId !== account.id
              ),
              user.uid,
            ],
            participants: [
              ...job.participants.map(participant => {
                if (participant.id === account.id) {
                  participant.id = user.uid;
                  return participant;
                }
                return participant;
              }),
            ],
          };
          updateJobInDb(newJob, collectionName);
        });
      }
    }
  });
}

async function mergeUserNotifications(userId) {
  const db = firebase.firestore();
  const currentUser = firebase.auth().currentUser;
  const notificationSnapshot = await db
    .collection("notifications")
    .where("recipientId", "==", userId)
    .get();
  if (!notificationSnapshot.empty) {
    notificationSnapshot.forEach(doc => {
      doc.ref.update({ recipientId: currentUser.uid });
    });
  }
}
