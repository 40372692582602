export function handleTitleChange(
  index,
  title,
  setDirty,
  jobReportTemplate,
  setJobReportTemplate
) {
  const updatedSections = [...(jobReportTemplate?.items || jobReportTemplate)];
  updatedSections[index].name = title;
  setJobReportTemplate(updatedSections);
  updateSaveButtonStatus(updatedSections, setDirty);
}
export const updateSaveButtonStatus = (items, setDirty) => {
  const allFieldsFilled = items.every(field => field?.name?.trim() !== "");
  console.log("Checking all fileds:", allFieldsFilled);
  setDirty(_isDirty => !allFieldsFilled);
};
export function toggleDescription(
  index,
  jobReportTemplate,
  setJobReportTemplate
) {
  const updatedSections = [...(jobReportTemplate?.items || jobReportTemplate)];
  updatedSections[index].showDescription =
    !updatedSections[index]?.showDescription;
  setJobReportTemplate(updatedSections);
}
export function handleDescription(
  index,
  jobReportTemplate,
  setJobReportTemplate
) {
  const updatedSections = [...(jobReportTemplate?.items || jobReportTemplate)];
  const sectionToDelete = updatedSections[index];
  if (sectionToDelete && sectionToDelete?.showDescription) {
    sectionToDelete.description = "";
    sectionToDelete.showDescription = false;
    setJobReportTemplate(updatedSections);
  }
}
export function handleDeleteField(
  index,
  setDirty,
  jobReportTemplate,
  setJobReportTemplate
) {
  const updatedSections = [
    ...(jobReportTemplate?.items || jobReportTemplate || []),
  ];
  updatedSections.splice(index, 1);
  setJobReportTemplate(updatedSections);
  updateSaveButtonStatus(updatedSections, setDirty);
}
export function handleDescriptionChange(
  index,
  description,
  jobReportTemplate,
  setJobReportTemplate,
  setDirty,
  setIsChecklistChanged
) {
  const updatedSections = [...(jobReportTemplate?.items || jobReportTemplate)];
  if (updatedSections && updatedSections[index]?.description !== undefined) {
    updatedSections[index].description = description;
    setJobReportTemplate(updatedSections);
  }
  updateSaveButtonStatus(updatedSections, setDirty);
  setIsChecklistChanged(true);
}
export function handleUpdateSection(
  section,
  jobReportTemplate,
  setJobReportTemplate
) {
  const updatedSections = [...(jobReportTemplate?.items || jobReportTemplate)];
  updatedSections[section.index] = section;
  setJobReportTemplate(updatedSections);
}
