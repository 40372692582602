import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { Alert, Typography } from "@mui/material";
import { DATE_FORMAT, ORGANISATION_OWNER } from "common/constants";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { format } from "date-fns";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { getAllOrgUsers, updateUserInDb } from "services";
import { fetchOrgSubscriptionInfoFromDb } from "services/subscriptionsService";
import firebase from "../../config/firebase";
const db = firebase.firestore();
import {
  removeParticipantFromActiveJobs,
  removeParticipantFromArchivedJobs,
  removeParticipantFromDeletedJobs,
  removeParticipantChatNotifications,
} from "services/jobService";
import {
  fetchOrgUsers,
  fetchOrgUsersError,
  fetchOrgUsersSuccess,
} from "store/orgUsers/actions";
import EditUser from "./EditUser";
import DeleteConfirmationPromptWithInput from "../../components/Common/DeleteConfirmationPromptWithInput";
import { removeParticipantFromInspectionJobs } from "services/inspectionService";
import CreateUser from "./CreateUser";
import { userName } from "pages/platformManagement/org-creation/orgService";
import { Paging } from "@elastic/react-search-ui-views";
import { CustomPagination } from "../../searchuicomponents/CustomPagination";
import Loader from "components/Loader";
const OrgUsers = () => {
  const {  orgUsers } = useSelector(state => state.orgUsers);
  const [orgUserss, setOrgUserss] = useState([])
  const { currentUserProfile } = useSelector(state => state.profile);
  const [showEditUser, setShowEditUser] = useState(false);
  const [user, setUser] = useState({});
  const [subscription, setSubscription] = useState({});
  const [isDeleteConfirmationPromptOpen, setIsDeleteConfirmationPromptOpen] =
    useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [lastDoc, setlastDoc] = useState("")
  const [lastDocPrevious, setlastDocForPrevious] = useState("")
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const[loading,setLoading] = useState([])

  const dispatch = useDispatch();
  const sortedOrgUsers = useMemo(()=>{
    return orgUserss
    ?.sort((user1, user2) => {
      const user1DisplayName = userName(user1)?.toLowerCase() || "";
      const user2DisplayName = userName(user2)?.toLowerCase() || "";
      if ((user1DisplayName) > (user2DisplayName)) {
        return 1;
      }
      if (user1DisplayName < user2DisplayName) {
        return -1;
      }
      return 0;
    })
  },[orgUserss]) 

  useFirestoreCollection({
    query: () => getAllOrgUsers(currentUserProfile?.orgAccessId),
    // query: () =>{},
    onStart: () => dispatch(fetchOrgUsers()),
    onSuccess: docs => dispatch(fetchOrgUsersSuccess(docs)),
    onError: error => dispatch(fetchOrgUsersError(error)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () =>
      fetchOrgSubscriptionInfoFromDb(currentUserProfile?.orgAccessId),
    onStart: () => { },
    onSuccess: docs => {
      docs.length && setSubscription(docs[0]);
    },
    onError: error => {
      console.log(error);
    },
    deps: [currentUserProfile?.orgAccessId],
  });

  useEffect(() => {
    run()
  }, [])
  const run = () => {
    setLoading(true)
    db.collection("users")
      .where("orgAccessId", "==", currentUserProfile?.orgAccessId,"type","!=",ORGANISATION_OWNER)
      .get()
      .then((querySnapshot) => {
        const count = querySnapshot.size;
        setTotalPage(Math.ceil(count / 10))
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
      });

    db.collection("users").where("orgAccessId", "==", currentUserProfile?.orgAccessId,"type","!=",ORGANISATION_OWNER).limit(10).get().then((collections) => {
      setOrgUserss(collections.docs.map((item) => item.data()))
      console.log(collections.docs.map((item) => item.data()))
      const lastDoc = collections.docs[collections.docs.length - 1]
      console.log("lastDoc", lastDoc.data())
      setlastDoc(lastDoc)
      setLoading(false)
    }).catch((err)=>{
      setLoading(false)
    })
  }

  const ViewMore = (type) => {
    if (type === "prev") {
      if (page > 1) {
        console.log('Fetching previous page, lastDocForPrevious:', lastDocPrevious.data());
  
        db.collection("users")
          .where("orgAccessId", "==", currentUserProfile?.orgAccessId,"type","!=",ORGANISATION_OWNER)
          .endBefore(lastDocPrevious)
          .limit(10)
          .get()
          .then((collections) => {
            const data = collections.docs.map((item) => item.data());
            setOrgUserss(data);
            console.log('Previous page data:', data);
  
            if (data.length > 0) {
              const lastDoc1_ = collections.docs[0];
              const lastDoc_ = collections.docs[collections.docs.length - 1];
              setlastDoc(lastDoc_);
              setPage(1); 
              setlastDocForPrevious(lastDoc1_);
            } else {
              alert("No more previous data");
            }
          });
      } else {
        alert("You're already on the first page");
      }
    } else {
      console.log('Fetching next page, lastDoc:', lastDoc);
  
      db.collection("users")
        .where("orgAccessId", "==", currentUserProfile?.orgAccessId,"type","!=",ORGANISATION_OWNER)
        .startAfter(lastDoc)
        .limit(10)
        .get()
        .then((collections) => {
          const data = collections.docs.map((item) => item.data());
          setOrgUserss(data);
          console.log('Next page data:', data);
  
          if (data.length > 0) {
            const lastDoc_ = collections.docs[collections.docs.length - 1];
            setPage(page + 1); // Increment page number
            setlastDoc(lastDoc_);
            setlastDocForPrevious(collections.docs[0]);
          } else {
            alert("No more next data");
          }
        });
    }
  };
  
  const onUserEdit = client => {
    setUser(client);
    setShowEditUser(true);
  };

  const onUserDelete = user => {
    setUser(user);
    setIsDeleteConfirmationPromptOpen(true);
  };

  const onDeleteConfirm = async () => {
    setIsDeleteConfirmationPromptOpen(false);
    try {
      await updateUserInDb({
        ...user,
        type: null,
        orgStatus: null,
        orgAccessId: null,
      });
      await removeParticipantFromActiveJobs(user.id, user.orgAccessId);
      await removeParticipantFromArchivedJobs(user.id, user.orgAccessId);
      await removeParticipantFromDeletedJobs(user.id, user.orgAccessId);
      await removeParticipantFromInspectionJobs(user.id, user.orgAccessId);
      toast.success("The user is deleted!", {
        autoClose: 2000,
      });
    } catch (err) {
      toast.error("Failed to delete the user", {
        autoClose: 2000,
      });
    }
  };
  const OrgUser = ({ user, index }) => {
    return (
      <Tr>
        <Td>{index + 1}</Td>
        <Td>{userName(user)}</Td>
        <Td>{user?.email}</Td>
        <Td>{user?.type}</Td>
        <Td>
          {user?.orgStatus === "Approved"
            ? "Active"
            : user?.orgStatus === "Pending"
              ? "Inactive"
              : user?.orgStatus}
        </Td>

        <Td>
          <div className="d-flex gap-3">
            <Link className="text-success" to="#">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() => onUserEdit(user)}
              ></i>
            </Link>
            <Link className="text-danger" to="#">
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
                onClick={() => onUserDelete(user)}
              ></i>
            </Link>
          </div>
        </Td>
      </Tr>
    );
  };

  return (
    <React.Fragment>
      {showUserMenu && (
        <CreateUser
          showUserMenu={showUserMenu}
          setShowUserMenu={setShowUserMenu}
          subscription={subscription}
        />
      )}
      <div className="page-content">
        <MetaTags>
          <title>User Details</title>
        </MetaTags>
        <div className="container-fluid">
          <div>
            <Typography sx={{ fontSize: "16px" }}>User Details</Typography>
            <div>
              <Button
                style={{
                  float: "right",
                  marginTop: "-30px",
                }}
                color="primary"
                onClick={() => {
                  setShowUserMenu(true);
                }}
              >
                Add User
              </Button>
            </div>
            {subscription?.endDate && (
              <Alert
                severity="info"
                sx={{ textAlign: "center", margin: "10px 0px" }}
              >
                {`Licensed for Org Owner with ${subscription.webUsersCount - 1} Web & ${subscription.mobileUsersCount
                  } Mobile Users. End Date: ${format(
                    new Date(subscription.endDate),
                    DATE_FORMAT
                  )}`}
              </Alert>
            )}
          </div>
          {loading ? <Loader />:
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {showEditUser && (
                    <EditUser
                      showEditUser={showEditUser}
                      setShowEditUser={setShowEditUser}
                      user={user}
                      setUser={setUser}
                      subscription={subscription}
                    />
                  )}

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th data-priority="1">Display Name</Th>
                            <Th data-priority="1">Email</Th>
                            <Th data-priority="1">Type</Th>
                            <Th data-priority="4">Onboard Status</Th>
                            <Th data-priority="6">Action</Th>
                          </Tr>
                        </Thead>
                            <Tbody>
                              {sortedOrgUsers
                                .map((user, index) => (
                                  <OrgUser key={index} index={page !== 1 ?(index+(((page-1)*10))):index} user={user} />
                                ))}
                            </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>}
     {(!loading && orgUserss?.length > 1) && 
          <Row className="clearfix" style={{ marginBottom: "20px" }}>
            <div className="text-center">
              <button
                className="btn btn-primary w-md mtb-2"
                onClick={() => ViewMore("prev")}
                type="button"
                disabled={page === 1}
              >
                Go to first Page
              </button>
              <button
                className="btn btn-primary  w-md ml-20 mtb-2"
                type="button"
                onClick={() => ViewMore("next")}
                disabled={page === totalPage}
              >
                Next
              </button>
            </div>
          </Row>
          }
        </div>
      </div>

      {isDeleteConfirmationPromptOpen && (
        <DeleteConfirmationPromptWithInput
          textTypeToDisplay="user's name"
          confirmationText={user.displayName}
          onCancel={() => setIsDeleteConfirmationPromptOpen(false)}
          onConfirm={onDeleteConfirm}
        />
      )}
    </React.Fragment>
  );
};

export default OrgUsers;
