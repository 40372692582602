import pdfLogo from "assets/images/pdflogo.png";
import Compressor from "compressorjs";
import firebase from "config/firebase";
import cuid from "cuid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Editable from "react-bootstrap-editable";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Modal,
  Progress,
  Row,
  UncontrolledTooltip,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  deleteImageFromFirebaseStoreByUrl,
  updateUserProfile,
  setAreaChecklistFlag,
  addAdminNotificationsToDb,
  fetchChecklist,
  updateAreaChecklist,
  updateChecklistItem,
  getChecklistRef,
  fetchChecklistByDocumentId,
  sendHoldPointEmailNotificationToUser,
} from "services";
import CheckListPage from "./checklist/ChecklistsPage";

// Redux
import SignaturePad from "react-signature-pad-wrapper";

//Lightbox
import Lightbox from "react-18-image-lightbox";
import "react-image-lightbox/style.css";

import holdPointGif from "assets/images/icons8/icons8-traffic-light.gif";
import {
  ACTIVE_JOBS_DB_NAME,
  INSPECTION_DB_NAME,
  RESIZED_IMAGES_DIMENSIONS,
  RESIZED_IMAGES_PATH,
} from "common/constants";
import { getDownloadURL } from "common/utils";
import "./checklist.scss";
import { SignatureDialog } from "components/Modal/SignaturePad";
import { useDropzone } from "react-dropzone";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { useDispatch } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import NotesConfirmationPrompt from "./NotesConfirmationBox";
import { DownloadImage } from "./DownloadImage";
import { format } from "date-fns";
import { fetchDocumentListsFromDb } from "services/documentService";
import {
  fetchDocuments,
  fetchDocumentsError,
  fetchDocumentsSuccess,
} from "store/actions";
import classNames from "classnames";
import { Checkbox } from "@mui/material";
import ImageEditor from "components/ImageEditor";
import ProgressLine from "components/ProgressLine";
import { userName } from "pages/platformManagement/org-creation/orgService";
import { Minimize } from "@mui/icons-material";

export default function Checklist({
  job,
  roomId: areaId,
  canEdit,
  canEditViewAndSign,
  canUpload,
  type,
  collectionName,
}) {
  const [areaChecklist, setAreaChecklist] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [currentSelect, setCurrentSelect] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);

  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successDlg, setSuccessDlg] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [dynamicDescription, setDynamicDescription] = useState("");

  const [confirmSignAlert, setConfirmSignAlert] = useState(false);
  const [successSignDlg, setSuccessSignDlg] = useState(false);
  const [dynamicSignTitle, setDynamicSignTitle] = useState("");
  const [dynamicSignDescription, setDynamicSignDescription] = useState("");

  const { currentUserProfile } = useSelector(state => state.profile);

  const [showSignature, setShowSignature] = useState(false);

  const storageRef = firebase.storage().ref();
  const signatureRef = useRef();
  const [saving, setSaving] = useState(false);

  const [photoIndex, setphotoIndex] = useState(0);
  const [isGallery, setisGallery] = useState(false);
  const [images, setImages] = useState([]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [isNotesUpdated, setIsNotesUpdated] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);
  const [notesInfo, setNotesInfo] = useState("");
  const db = firebase.firestore();
  const [isSignatureNotExist, setIsSignatureNotExist] = useState(false);
  const [checklistDocumentId, setChecklistDocumentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { notes } = useSelector(state => state.notes);
  const dispatch = useDispatch();
  const [notesPermission, setNotesPermission] = useState(false);

  const documentLibraryList = useSelector(state => state.documents);
  const [documentLibrary, setDocumentLibrary] = useState([]);
  const [checklistSearch, setChecklistSearch] = useState("");
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [showUploadIcon, setShowUploadIcon] = useState(true);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFileType, setSelectedFileType] = useState("");
  const [percent, setPercent] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isAreaLocked, setIsAreaLocked] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);

  const onCancel = useCallback(() => {
    const newDocumentLibrary = [...documentLibrary];
    newDocumentLibrary.map(document => {
      document?.attachments?.map(doc => {
        doc.checked = false;
      });
    });
    setDocumentLibrary(newDocumentLibrary);
    setShowCheckBox(false);
    setShowUploadIcon(true);
    setSelectedDocuments([]);
  }, [
    documentLibraryList,
    documentLibrary,
    showCheckBox,
    showUploadIcon,
    selectedDocuments,
  ]);

  useFirestoreCollection({
    query: () => fetchDocumentListsFromDb(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchDocuments()),
    onSuccess: docs => dispatch(fetchDocumentsSuccess(docs)),
    onError: error => dispatch(fetchDocumentsError(error)),
    deps: [dispatch, selectedDocument, showModal],
  });
  useFirestoreCollection({
    query: () => getChecklistRef(job.id, areaId, collectionName),
    onStart: () => setLoading(true),
    onSuccess: checklists => {
      if (checklists.length > 0) {
        setChecklistItems(checklists[0]?.items);
        setAreaChecklist(checklists[0]);
      }
      setLoading(false);
    },
    onError: error => {
      console.log("Error fetching checklist", error);
    },
    deps: [dispatch, areaId, showModal],
  });
  useEffect(() => {
    setNotesPermission(true);
  }, [currentUserProfile?.disableNotes]);

  useEffect(async () => {
    setLoading(true);
    const checklist = await fetchChecklist(collectionName, job.id, areaId);
    setChecklistItems(checklist?.items);
    setAreaChecklist(checklist);
    setChecklistDocumentId(checklist?.documentId);
    setLoading(false);
    if (notesPermission) {
      setNotesPermission(false);
      return;
    }
  }, [job, areaId, showModal]);

  useEffect(() => {
    let newList = documentLibraryList?.documents?.filter(document => {
      const title = document.name;
      return title.toLowerCase().includes(checklistSearch.toLowerCase());
    });
    newList?.sort((a, b) => {
      const aStr = a.name;
      const bStr = b.name;
      return aStr.localeCompare(bStr);
    });
    setDocumentLibrary([...newList]);
  }, [checklistSearch, documentLibraryList]);

  const lockAreaChecklist = async () => {
    if (!job?.areas[areaId]?.isChecklistExists) {
      toast.error("Please add a section before locking the area", {
        autoClose: 3000,
      });
      return;
    }
    let lockValue = true;
    if (job?.areas[areaId]?.isLocked) {
      lockValue = false;
    }
    const docRef = db.collection(collectionName).doc(job.id);
    await db.runTransaction(async txn => {
      const jobDoc = await txn.get(docRef);
      const job = jobDoc.data();
      const areasChecklistFlagObject = job?.areas || {};
      [areaId].forEach(roomId => {
        areasChecklistFlagObject[roomId].isLocked = lockValue;
      });
      txn.update(docRef, {
        areas: areasChecklistFlagObject,
      });
    });
    // const checklistRef = fetchChecklistByDocumentId(
    //   collectionName,
    //   job.id,
    //   checklistDocumentId
    // );
    // console.log("The checklist ref is:", checklistRef);
    // try {
    //   await db.runTransaction(async txn => {
    //     const checklistReference = await txn.get(checklistRef);
    //     const checklist = checklistReference.data();
    //     txn.update(checklistRef, { isLocked: lockValue });
    //   });
    // } catch (error) {
    //   toast.error("The area has no section to lock", {
    //     autoClose: 2000,
    //   });
    //   console.log("error", error);
    // }
  };

  const onSaveChanges = async items => {
    const checklist = { ...areaChecklist, items: items };
    setAreaChecklist(checklist);
    await setAreaChecklistFlag(job.id, collectionName, [areaId]);
    setChecklistItems(items);
    await updateAreaChecklist(job.id, collectionName, checklist, areaId);
  };
  const isNotesInfoAlreadyExists = notesName => {
    return notes?.find(item => {
      return item?.notesInfo?.toLowerCase() === notesName?.toLowerCase();
    });
  };
  const onClickSignSave = async () => {
    const isSignatureExist =
      signatureRef.current.signaturePad._data.length == 0;
    if (isSignatureExist) {
      setIsSignatureNotExist(isSignatureExist);
    } else {
      // location in storage you want to create/send file to
      setSaving(true);
      const filePath = `${currentUserProfile.id}/signature/${cuid()}`;
      const dataURL = await signatureRef.current.toDataURL("image/png");
      const filesRef = storageRef.child(filePath);
      filesRef
        .putString(dataURL, "data_url")
        .then(() => {
          console.log("Uploaded a blob or file!");
        })
        .catch(error => {
          toast.alert(error);
        })
        .finally(() => {
          console.log("finally");

          storageRef
            .child(filePath)
            .getDownloadURL()
            .then(async url => {
              await updateUserProfile({ ...currentUserProfile, signURL: url });
              await addSignature(
                currentSelect.itemId,
                currentSelect?.mainIndex,
                currentSelect?.itemIndex,
                url
              );
              setShowSignature(false);
              setSaving(false);
            });
        });
    }
  };
  async function sendHoldPointNotification(mainIndex, itemIndex) {
    const notificationDocs = [];
    const recipientIds = [];
    const roomArea = job[type].rooms
      .map(room => {
        const subItem = room.subItems.find(subItem => subItem.id === areaId);
        return [room?.content || room?.type, subItem?.content || subItem?.type];
      })
      .toString()
      .split(",");

    job.participantIds?.forEach(participantId => {
      if (participantId != currentUserProfile?.id) {
        const notification = {
          id: cuid(),
          jobId: job.id,
          title: job.jobNo,
          isRead: false,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          type: "admin",
          description: `Job ${job.jobNo} - ${currentUserProfile?.displayName} has signed the hold-point`,
          recipientId: participantId,
          actionType: "added-holdPoint",
          detailedDescription: `Job ${job.jobNo} - ${currentUserProfile?.displayName
            } has signed the ${mainIndex + 1} . ${itemIndex + 1
            }  hold-point belonging to the ${type} job category for the ${roomArea[0]
            } ${roomArea[1]}`,
        };
        if (collectionName === INSPECTION_DB_NAME) {
          notification.isInspectionJob = true;
        }
        notificationDocs.push(notification);
        recipientIds.push(participantId);
      }
    });
    await addAdminNotificationsToDb(notificationDocs);
  }

  const [isUpdateDone, setIsUpdateDone] = useState(false);

  useEffect(() => {
    // Change cursor to pointer when isUpdateDone is true
    if (isUpdateDone) {
      document.body.style.cursor = "progress";
    } else {
      document.body.style.cursor = "auto";
    }

    // Clean up the cursor style when the component unmounts
    return () => {
      document.body.style.cursor = "auto";
    };
  }, [isUpdateDone]);

  const onCheckItem = async (sectionId, itemId, value) => {
    setIsUpdateDone(true);
    try {
      await updateChecklistItem(
        collectionName,
        job.id,
        checklistDocumentId,
        "checkbox",
        sectionId,
        itemId,
        value
      );
      setIsUpdateDone(false);
    } catch (err) {
      console.log("Failed to update the checkbox value", err);
      setIsUpdateDone(false);
    }
  };

  const updateNotes = async (sectionId, itemId, value) => {
    try {
      await updateChecklistItem(
        collectionName,
        job.id,
        checklistDocumentId,
        "notes",
        sectionId,
        itemId,
        value
      );
      console.log("Updated the notes successfully");
    } catch (err) {
      console.log("Failed to update the notes", err);
    }
  };

  const addSignature = async (sectionId, sectionIndex, itemIndex, signURL) => {
    const checklistRef = fetchChecklistByDocumentId(
      collectionName,
      job.id,
      checklistDocumentId
    );
    try {
      await db
        .runTransaction(async txn => {
          const checklistDoc = await txn.get(checklistRef);
          const checklist = checklistDoc.data();
          const checkListItem =
            checklist.items[sectionIndex].subItems[itemIndex];
          if (checkListItem.id === sectionId) {
            if (checkListItem.signatures) {
              checkListItem.signatures.push({
                id: cuid(),
                name: userName(currentUserProfile),
                userId: currentUserProfile.id,
                date: new Date(),
                url: _.isNil(signURL) ? currentUserProfile.signURL : signURL,
              });
            } else {
              checkListItem.signatures = [
                {
                  id: cuid(),
                  name: userName(currentUserProfile),
                  userId: currentUserProfile.id,
                  date: new Date(),
                  url: _.isNil(signURL) ? currentUserProfile?.signURL : signURL,
                },
              ];
            }
          }
          checklist.items[sectionIndex].subItems[itemIndex] = checkListItem;
          txn.update(checklistRef, { items: checklist.items });
        })
        .then(() => {
          sendHoldPointNotification(sectionIndex, itemIndex);
        });
    } catch (error) {
      console.log("Failed to add signature", error);
    }
  };
  const clearCache = (attachment) => {
    try {
      // Assuming the local storage key is based on the attachment ID
      const thumbnailKey = `thumbnail_${attachment.id}`;
      localStorage.removeItem(thumbnailKey);
      console.log("Cache cleared successfully for attachment", attachment.id);
    } catch (err) {
      console.log("Error clearing cache from local storage:", err);
    }
  };

  const deleteSignature = async (sectionId, itemId, sigId) => {
    const checklistRef = fetchChecklistByDocumentId(
      collectionName,
      job.id,
      checklistDocumentId
    );
    try {
      await db.runTransaction(async txn => {
        const checklistDoc = await txn.get(checklistRef);
        const checklist = checklistDoc.data();
        const updatedChecklistItems = checklist.items.map(section => {
          if (section.id == sectionId) {
            section.subItems = section.subItems.map(item => {
              if (item.id === itemId) {
                if (item.signatures) {
                  item.signatures = item.signatures.filter(
                    sig => sig.id !== sigId
                  );
                }
                return item;
              }
              return item;
            });
          }
          return section;
        });
        txn.update(checklistRef, { items: updatedChecklistItems });
      });
    } catch (error) {
      console.log("Failed to remove signature", error);
    }
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        percent: 0,
        downloadUrl: "",
        id: "",
      })
    );
    setselectedFiles(files);
  }

  const onDeletedAttachment = async (sectionId, itemId, fileId) => {
    const checklistRef = fetchChecklistByDocumentId(
      collectionName,
      job.id,
      checklistDocumentId
    );

    try {
      const attachmentToDelete = await db.runTransaction(async txn => {
        const checklistDoc = await txn.get(checklistRef);
        const checklist = checklistDoc.data();
        let attachmentToDelete;

        const updatedChecklistItems = checklist?.items.map(section => {
          if (sectionId === section.id) {
            section.subItems = section.subItems.map(item => {
              if (item.id === itemId) {
                item.attachments = item.attachments.filter(attachment => {
                  if (attachment.id === fileId) {
                    attachmentToDelete = attachment;
                  }
                  return attachment.id !== fileId;
                });
                return item;
              }
              return item;
            });
          }
          return section;
        });

        txn.update(checklistRef, { items: updatedChecklistItems });

        return attachmentToDelete;
      });

      if (attachmentToDelete) {
        await deletePhotoFromStorage(attachmentToDelete);
        clearCache(attachmentToDelete)
        console.log("Attachment deleted successfully");
      } else {
        console.log("No attachment found to delete");
      }

    } catch (error) {
      console.log("Failed to delete photos", error);
    }
  };

  // const deletePhotoFromStorage = async attachment => {
  //   try {
  //     const resizedImageUrl = await getDownloadURL(attachment.resizedImagePath);
  //     await deleteImageFromFirebaseStoreByUrl(attachment.url);
  //     await deleteImageFromFirebaseStoreByUrl(resizedImageUrl);
  //   } catch (err) {
  //     console.log("deletePhotoFromStorage", err);
  //   }
  // };

  const deletePhotoFromStorage = async attachment => {
    try {
      if (!attachment) {
        console.log("No attachment provided to delete");
        return;
      }

      // Delete the main image
      await deleteImageFromFirebaseStoreByUrl(attachment.url);
      console.log("Main image deleted successfully");

      // Delete the resized image if it exists
      if (attachment.resizedImagePath) {
        try {
          const resizedImageUrl = await getDownloadURL(attachment.resizedImagePath);
          await deleteImageFromFirebaseStoreByUrl(resizedImageUrl);
          console.log("Resized image deleted successfully");
        } catch (err) {
          console.log("Error deleting resized image:", err);
        }
      } else {
        console.log("No resized image path found, skipping deletion");
      }
    } catch (err) {
      console.log("Error deleting attachment from storage:", err);
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const onModalClose = () => {
    setShowEditModal(false);
    //setItems(job[type][roomId]?.checklist);
  };
  const DisplayItem = ({ mainList, mainIndex, item, itemIndex }) => {
    const [isConfirmAlertOpen, setIsConfirmAlertOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(item.value);
    const [disableSignature, setDisableSignature] = useState(false);
    const handleCheckboxChange = (e) => {
      const checked = e.target.checked;
      setIsChecked(checked);
      item.value = checked;

      const updatedMainList = {
        ...mainList,
        items: mainList.subItems.map((el, idx) => {
          // Update the value for the checkbox being changed
          if (idx === itemIndex) {
            return { ...el, value: checked };
          }
          return el;
        }).filter(el => el.type !== "digitalsign")
      };
    };

    if (item.type === "checkbox" || item?.checkBox) {
      return (
        <React.Fragment key={item.id}>
          <tr>
            {!job?.areas[areaId]?.isLocked && (
              <td>
                <input
                  type="checkbox"
                  style={{
                    margin: "10px",
                    border: "1px solid primary",
                    cursor: isUpdateDone ? "progress" : "pointer",
                  }}
                  className="form-check-input"
                  id={item.id}
                  disabled={
                    isUpdateDone || canEditViewAndSign
                      ? item.value
                        ? canEditViewAndSign
                        : !canEditViewAndSign
                      : !canUpload
                  }
                  checked={isChecked}
                  onChange={e => {
                    handleCheckboxChange(e);
                  }}
                />
              </td>
            )}
            <td>{`${mainIndex + 1}.${itemIndex + 1}`}</td>
            <td>
              <h5 className="font-size-14">{item.content}</h5>

              {!job?.areas[areaId]?.isLocked ? (
                <Editable
                  ajax={null}
                  alwaysEditing={false}
                  className={null}
                  disabled={job?.areas[areaId]?.isLocked || !canUpload}
                  editText="CLICK HERE TO ADD NOTES IF REQUIRED"
                  id={null}
                  initialValue={item.notes}
                  isValueClickable={!!item.notes}
                  label={null}
                  mode="inline"
                  onSubmit={value => {
                    if (value?.trim() !== item.notes) {
                      const isNotesExist = isNotesInfoAlreadyExists(
                        value?.trim()
                      );
                      if (
                        value?.trim().length > 0 &&
                        isNotesExist === undefined &&
                        !currentUserProfile?.disableNotes
                      ) {
                        setIsNotesUpdated(true);
                        setNotesInfo(value?.trim());
                      }
                      updateNotes(mainList.id, item.id, value?.trim());
                    }
                  }}
                  onValidated={null}
                  placement="top"
                  showText
                  type="textarea"
                  validate={null}
                />
              ) : (
                <div>{item.notes}</div>
              )}

              {item?.attachments?.length > 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td style={{ padding: "10px 5px" }}>
                    {item?.attachments?.map((file, idx) => {
                      let createdDate;
                      if (!!file?.date) {
                        const seconds = file?.date?.seconds;
                        const nanoseconds = file?.date?.nanoseconds;
                        const milliseconds =
                          seconds * 1000 + Math.round(nanoseconds / 1000000);
                        const date = new Date(milliseconds);
                        createdDate = format(date, "dd/MM/yy hh:mm a");
                      }

                      return (
                        <span style={{ display: "inline-block" }} key={idx}>
                          <img
                            data-dz-thumbnail=""
                            height="100"
                            className="avatar-sm rounded bg-light"
                            alt={file.name}
                            src={
                              file.type === "application/pdf"
                                ? pdfLogo
                                : file.url
                            }
                            style={{
                              cursor: "pointer",
                              margin: "5px",
                              width: "8rem",
                              height: "8rem",
                            }}
                            onClick={() => {
                              if (!job?.areas[areaId]?.isLocked) {
                                setCurrentSelect({
                                  parentId: mainList.id,
                                  itemId: item.id,
                                  fileId: file.id,
                                });
                                if (canUpload && canEdit) {
                                  toast.info(
                                    "Click on image or pen icon to edit image ",
                                    {
                                      autoClose: 3000,
                                    }
                                  );
                                }
                                setSelectedFile(file);
                                setSelectedFileType("attachment");
                              }
                              setImages(item.attachments.map(file => file.url));
                              setisGallery(true);
                              setphotoIndex(idx);
                            }}
                          />
                          <p style={{ maxWidth: "8rem" }}>{file?.userName}</p>
                          <p>{createdDate}</p>
                          {canUpload &&
                            canEdit &&
                            !job?.areas[areaId]?.isLocked && (
                              <i
                                style={{ color: "red" }}
                                className="dripicons-cross"
                                onClick={() => {
                                  setCurrentSelect({
                                    parentId: mainList.id,
                                    itemId: item.id,
                                    fileId: file.id,
                                  });
                                  setConfirmAlert(true);
                                }}
                              />
                            )}
                        </span>
                      );
                    })}
                  </td>
                </tr>
              )}

              {!job?.areas[areaId]?.isLocked && (
                <div className="uploadButton">
                  {item?.attachment
                    ? (canUpload || canEditViewAndSign) && (
                      <Button
                        color="primary"
                        size="md"
                        onClick={() => {
                          setCurrentSelect({
                            parentId: mainList.id,
                            itemId: item.id,
                          });
                          setShowModal(true);
                        }}
                      >
                        UPLOAD PHOTO
                      </Button>
                    )
                    : (canUpload || canEditViewAndSign) && (
                      <Button
                        color="link"
                        size="md"
                        onClick={() => {
                          setCurrentSelect({
                            parentId: mainList.id,
                            itemId: item.id,
                          });
                          setShowModal(true);
                        }}
                      >
                        UPLOAD PHOTO IF REQUIRED
                      </Button>
                    )}
                </div>
              )}
            </td>
          </tr>
        </React.Fragment>
      );
    } else if (
      item.type === "digitalsign" ||
      item?.signature ||
      item.holdPoint
    ) {
      return (
        <>
          <tr key={item.id}>
            {!job?.areas[areaId]?.isLocked && (
              <td>
                {item?.holdPoint && (
                  <img
                    src={holdPointGif}
                    style={{
                      height: "30px",
                      width: "30px",
                      transform: "translate(0, 5px)",
                      cursor: "pointer",
                    }}
                  />
                )}
              </td>
            )}
            <td>{`${mainIndex + 1}.${itemIndex + 1}`}</td>
            <td>
              <h5 className="font-size-14">{item.content}</h5>
              {item?.signatures?.length > 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td style={{ padding: "10px 5px", textAlign: "center" }}>
                    {item?.signatures?.map((file, idx) => {
                      const seconds = file?.date?.seconds;
                      const nanoseconds = file?.date?.nanoseconds;
                      const milliseconds =
                        seconds * 1000 + Math.round(nanoseconds / 1000000);
                      const date = new Date(milliseconds);
                      const createdDate = format(date, "dd LLL yy hh:mm aaa");
                      return (
                        <span
                          style={{
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                          key={idx}
                        >
                          <img
                            style={{
                              cursor: "pointer",
                              width: "8rem",
                              height: "8rem",
                            }}
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={file.name}
                            src={file.url}
                            onClick={() => {
                              setImages(item.signatures.map(file => file.url));
                              setSelectedFileType("signature");
                              setisGallery(true);
                              setphotoIndex(idx);
                            }}
                          />
                          {canUpload && !job?.areas[areaId]?.isLocked && (
                            <i
                              style={{ color: "red" }}
                              className="dripicons-cross"
                              onClick={() => {
                                setCurrentSelect({
                                  parentId: mainList.id,
                                  itemId: item.id,
                                  fileId: file.id,
                                });
                                setConfirmSignAlert(true);
                              }}
                            />
                          )}
                          <p style={{ maxWidth: "8rem" }}>{file?.name}</p>
                          <p>{createdDate}</p>
                        </span>
                      );
                    })}
                  </td>
                </tr>
              )}
              <div className="uploadButton">
                {(canUpload || canEditViewAndSign) &&
                  !job?.areas[areaId]?.isLocked && (
                    <Button
                      disabled={disableSignature}
                      color="primary"
                      size="md"
                      onClick={() => {
                        if (currentUserProfile.signURL) {
                          setDisableSignature(true);
                          setIsConfirmAlertOpen(true);
                        } else {
                          setCurrentSelect({
                            itemId: item.id,
                            mainIndex,
                            itemIndex,
                          });
                          setShowSignature(true);
                          setIsConfirmAlertOpen(false);
                        }
                      }}
                    >
                      Sign
                    </Button>
                  )}
                {isConfirmAlertOpen ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmButtonText="Yes, delete it!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={async () => {
                      if (currentUserProfile.signURL) {
                        const areAllSubItemsChecked = mainList.subItems
                        .filter(el => el.type !== "digitalsign")  
                        .every(el => el.value === true);        
                      
                        if (areAllSubItemsChecked) {
                          const orgId = currentUserProfile.orgAccessId;     
                          const user =  currentUserProfile?.email;        
                          const holdPointDetails = {               
                            name: item.content,  
                            date: new Date()   
                          };
                  
                          await sendHoldPointEmailNotificationToUser(
                            orgId,
                            user,
                            holdPointDetails,
                          );

                        await addSignature(item.id, mainIndex, itemIndex);

                        }

                      } else {
                        setCurrentSelect({
                          itemId: item.id,
                          mainIndex,
                          itemIndex,
                        });

                        setShowSignature(true);
                      }
                      setIsConfirmAlertOpen(false);
                    }}
                    onCancel={() => {
                      setIsConfirmAlertOpen(false);
                      setDisableSignature(false);
                    }}
                  >
                    E-signature will be added.
                  </SweetAlert>
                ) : null}
              </div>
            </td>
          </tr>
        </>
      );
    } else {
      return null;
    }
  };
  const DisplayInspectionItem = ({ mainList, mainIndex, item, itemIndex }) => {
    const [isConfirmAlertOpen, setIsConfirmAlertOpen] = useState(false);
    const [disableSignature, setDisableSignature] = useState(false);
    if (item.type === "checkbox" || item?.checkBox) {
      return (
        <React.Fragment key={item.id}>
          <tr>
            <td>
              <>
                {item.value === "done" && (
                  <i
                    className="fa fa-check-square"
                    style={{ fontSize: "28px", color: "#50a5f1" }}
                  ></i>
                )}
                {item.value === "tick" && (
                  <i
                    className="fa fa-check-square"
                    style={{ fontSize: "28px", color: "green" }}
                  ></i>
                )}
                {item.value === "cross" && (
                  <i
                    className="fas fa-window-close fa-lg"
                    style={{ fontSize: "28px", color: "red" }}
                  ></i>
                )}
                {item.value === "N/A" && (
                  <p
                    style={{
                      fontSize: "10px",
                      display: "inline-block",
                      padding: "3px",
                      borderRadius: "3px",
                      color: "#ffffff",
                      backgroundColor: "#000000",
                      border: "2px solid black",
                    }}
                  >
                    N/A
                  </p>
                )}
                {item.value === "F/A" && (
                  <p
                    style={{
                      fontSize: "10px",
                      display: "inline-block",
                      padding: "3px",
                      borderRadius: "3px",
                      color: "#ffffff",
                      border: "2px solid #EEA800",
                      backgroundColor: "#EEA800",
                    }}
                  >
                    F/A
                  </p>
                )}
              </>
            </td>
            <td>{`${mainIndex + 1}.${itemIndex + 1}`}</td>
            <td>
              <h5 className="font-size-14">{item.content}</h5>

              {!job.areas[areaId]?.isLocked && (
                <Editable
                  ajax={null}
                  alwaysEditing={false}
                  className={null}
                  disabled={!canUpload}
                  editText="CLICK HERE TO ADD NOTES IF REQUIRED"
                  id={null}
                  initialValue={item.notes}
                  isValueClickable={!!item.notes}
                  label={null}
                  mode="inline"
                  onSubmit={value => {
                    if (value?.trim() !== item.notes) {
                      const isNotesExist = isNotesInfoAlreadyExists(
                        value?.trim()
                      );
                      if (
                        value?.trim().length > 0 &&
                        isNotesExist === undefined &&
                        !currentUserProfile?.disableNotes
                      ) {
                        setIsNotesUpdated(true);
                        setNotesInfo(value?.trim());
                      }
                      updateNotes(mainList.id, item.id, value?.trim());
                    }
                  }}
                  onValidated={null}
                  placement="top"
                  showText
                  type="textarea"
                  validate={null}
                />
              )}

              {item?.attachments?.length > 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td style={{ padding: "10px 5px" }}>
                    {item?.attachments?.map((file, idx) => {
                      let createdDate;
                      if (!!file?.date) {
                        const seconds = file?.date?.seconds;
                        const nanoseconds = file?.date?.nanoseconds;
                        const milliseconds =
                          seconds * 1000 + Math.round(nanoseconds / 1000000);
                        const date = new Date(milliseconds);
                        createdDate = format(date, "dd/MM/yy hh:mm a");
                      }

                      return (
                        <span style={{ display: "inline-block" }} key={file.id}>
                          <img
                            data-dz-thumbnail=""
                            height="100"
                            className="avatar-sm rounded bg-light"
                            alt={file.name}
                            src={
                              file.type === "application/pdf"
                                ? pdfLogo
                                : file.url
                            }
                            style={{
                              cursor: "pointer",
                              margin: "5px",
                              width: "8rem",
                              height: "8rem",
                            }}
                            onClick={() => {
                              if (!job?.areas[areaId]?.isLocked) {
                                setCurrentSelect({
                                  parentId: mainList.id,
                                  itemId: item.id,
                                  fileId: file.id,
                                });
                                if (canUpload && canEdit) {
                                  toast.info(
                                    "Click on image or pen icon to edit image ",
                                    {
                                      autoClose: 3000,
                                    }
                                  );
                                }
                                setSelectedFileType("attachment");
                              }
                              setisGallery(true);
                              setSelectedFile(file);
                              setImages(item.attachments.map(file => file.url));
                              setphotoIndex(idx);
                            }}
                          />
                          <p style={{ maxWidth: "8rem" }}>{file?.userName}</p>
                          <p>{createdDate}</p>
                          {canUpload &&
                            canEdit &&
                            !job?.areas[areaId]?.isLocked && (
                              <i
                                style={{ color: "red" }}
                                className="dripicons-cross"
                                onClick={() => {
                                  setCurrentSelect({
                                    parentId: mainList.id,
                                    itemId: item.id,
                                    fileId: file.id,
                                  });
                                  setConfirmAlert(true);
                                }}
                              />
                            )}
                        </span>
                      );
                    })}
                  </td>
                </tr>
              )}

              <div className="uploadButton">
                {item?.attachment
                  ? (canUpload || canEditViewAndSign) &&
                  !job?.areas[areaId]?.isLocked && (
                    <Button
                      color="primary"
                      size="md"
                      onClick={() => {
                        setCurrentSelect({
                          parentId: mainList.id,
                          itemId: item.id,
                        });
                        setShowModal(true);
                      }}
                    >
                      UPLOAD PHOTO
                    </Button>
                  )
                  : (canUpload || canEditViewAndSign) &&
                  !job?.areas[areaId]?.isLocked && (
                    <Button
                      color="link"
                      size="md"
                      onClick={() => {
                        setCurrentSelect({
                          parentId: mainList.id,
                          itemId: item.id,
                        });
                        setShowModal(true);
                      }}
                    >
                      UPLOAD PHOTO IF REQUIRED
                    </Button>
                  )}
              </div>
              {!job?.areas[areaId]?.isLocked && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "80%",
                  }}
                >
                  <Button
                    className="button-edit"
                    id={`${mainList.id}-${item.id}-done`}
                    style={{
                      marginRight: "10px",
                      fontSize: "14px",
                      color: "white",
                      background: "#50a5f1",
                      border: "white",
                      cursor: isUpdateDone ? "progress" : "pointer",
                    }}
                    disabled={
                      (!(typeof item.value === "undefined") &&
                        item.value !== "clear" &&
                        item.value !== false) ||
                      isUpdateDone
                    }
                    onClick={() => onCheckItem(mainList.id, item.id, "done")}
                  >
                    Done
                    <UncontrolledTooltip
                      target={`${mainList.id}-${item.id}-done`}
                      placement="bottom"
                    >
                      Done
                    </UncontrolledTooltip>
                  </Button>
                  <Button
                    className="button-edit"
                    id={`${mainList.id}-${item.id}-pass`}
                    disabled={
                      (!(typeof item.value === "undefined") &&
                        item.value !== "clear" &&
                        item.value !== false) ||
                      isUpdateDone
                    }
                    style={{
                      background: "green",
                      cursor: isUpdateDone ? "progress" : "pointer",
                    }}
                    onClick={() => onCheckItem(mainList.id, item.id, "tick")}
                  >
                    Pass
                    <UncontrolledTooltip
                      target={`${mainList.id}-${item.id}-pass`}
                      placement="bottom"
                    >
                      Pass
                    </UncontrolledTooltip>
                  </Button>
                  <Button
                    className="button-edit"
                    id={`${mainList.id}-${item.id}-fa`}
                    style={{
                      marginRight: "10px",
                      fontSize: "14px",
                      color: "white",
                      background: "#EEA800",
                      border: "white",
                      cursor: isUpdateDone ? "progress" : "pointer",
                    }}
                    disabled={
                      (!(typeof item.value === "undefined") &&
                        item.value !== "clear" &&
                        item.value !== false) ||
                      isUpdateDone
                    }
                    onClick={() => onCheckItem(mainList.id, item.id, "F/A")}
                  >
                    F/A
                    <UncontrolledTooltip
                      target={`${mainList.id}-${item.id}-fa`}
                      placement="bottom"
                    >
                      Further Action Required
                    </UncontrolledTooltip>
                  </Button>
                  <Button
                    className="button-edit"
                    id={`${mainList.id}-${item.id}-fail`}
                    disabled={
                      (!(typeof item.value === "undefined") &&
                        item.value !== "clear" &&
                        item.value !== false) ||
                      isUpdateDone
                    }
                    style={{
                      background: "red",
                      cursor: isUpdateDone ? "progress" : "pointer",
                    }}
                    onClick={() => onCheckItem(mainList.id, item.id, "cross")}
                  >
                    Fail
                  </Button>
                  <UncontrolledTooltip
                    target={`${mainList.id}-${item.id}-fail`}
                    placement="bottom"
                  >
                    Fail
                  </UncontrolledTooltip>

                  <Button
                    className="button-edit"
                    id={`${mainList.id}-${item.id}-na`}
                    style={{
                      fontSize: "14px",
                      color: "white",
                      marginRight: "10px",
                      background: "black",
                      border: "black",
                      cursor: isUpdateDone ? "progress" : "pointer",
                    }}
                    disabled={
                      (!(typeof item.value === "undefined") &&
                        item.value !== "clear" &&
                        item.value !== false) ||
                      isUpdateDone
                    }
                    onClick={() => onCheckItem(mainList.id, item.id, "N/A")}
                  >
                    N/A
                  </Button>
                  <UncontrolledTooltip
                    target={`${mainList.id}-${item.id}-na`}
                    placement="bottom"
                  >
                    Not Applicable
                  </UncontrolledTooltip>

                  <Button
                    className="button-edit"
                    style={{
                      fontSize: "12px",
                      background: "#fff",
                      border: "1px solid black",
                      fontWeight: "bold",
                      cursor: isUpdateDone ? "progress" : "pointer",
                    }}
                    color="black"
                    disabled={
                      typeof item.value === "undefined" ||
                      item.value === "clear" ||
                      isUpdateDone
                    }
                    onClick={() => onCheckItem(mainList.id, item.id, "clear")}
                  >
                    Clear
                  </Button>
                </div>
              )}
            </td>
          </tr>
        </React.Fragment>
      );
    } else if (
      item.type === "digitalsign" ||
      item?.signature ||
      item.holdPoint
    ) {
      return (
        <>
          <tr key={item.id}>
            <td>
              {item?.holdPoint && (
                <img
                  src={holdPointGif}
                  style={{
                    height: "30px",
                    width: "30px",
                    transform: "translate(0, 5px)",
                    cursor: "pointer",
                  }}
                />
              )}
            </td>
            <td>{`${mainIndex + 1}.${itemIndex + 1}`}</td>
            <td>
              <h5 className="font-size-14">{item.content}</h5>
              {item?.signatures?.length > 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td style={{ padding: "10px 5px", textAlign: "center" }}>
                    {item?.signatures?.map((file, idx) => {
                      const seconds = file?.date?.seconds;
                      const nanoseconds = file?.date?.nanoseconds;
                      const milliseconds =
                        seconds * 1000 + Math.round(nanoseconds / 1000000);
                      const date = new Date(milliseconds);
                      const createdDate = format(date, "dd LLL yy hh:mm aaa");
                      return (
                        <span
                          style={{
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                          key={idx}
                        >
                          <img
                            style={{
                              cursor: "pointer",
                              width: "8rem",
                              height: "8rem",
                            }}
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={file.name}
                            src={file.url}
                            onClick={() => {
                              setImages(item.signatures.map(file => file.url));
                              setSelectedFileType("signature");
                              setisGallery(true);
                              setphotoIndex(idx);
                            }}
                          />
                          {canUpload && !job?.areas[areaId]?.isLocked && (
                            <i
                              style={{ color: "red" }}
                              className="dripicons-cross"
                              onClick={() => {
                                setCurrentSelect({
                                  parentId: mainList.id,
                                  itemId: item.id,
                                  fileId: file.id,
                                });
                                setConfirmSignAlert(true);
                              }}
                            />
                          )}
                          <p style={{ maxWidth: "8rem" }}>{file?.name}</p>
                          <p>{createdDate}</p>
                        </span>
                      );
                    })}
                  </td>
                </tr>
              )}
              <div className="uploadButton">
                {(canUpload || canEditViewAndSign) &&
                  !job?.areas[areaId]?.isLocked && (
                    <Button
                      disabled={disableSignature}
                      color="primary"
                      size="md"
                      onClick={() => {
                        if (currentUserProfile.signURL) {
                          setDisableSignature(true);
                          setIsConfirmAlertOpen(true);
                        } else {
                          setCurrentSelect({
                            itemId: item.id,
                            mainIndex,
                            itemIndex,
                          });
                          setShowSignature(true);
                          setIsConfirmAlertOpen(false);
                        }
                      }}
                    >
                      Sign
                    </Button>
                  )}
                {isConfirmAlertOpen ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmButtonText="Yes, delete it!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={async () => {
                      if (currentUserProfile.signURL) {
                        addSignature(item.id, mainIndex, itemIndex);
                      } else {
                        setCurrentSelect({
                          itemId: item.id,
                          mainIndex,
                          itemIndex,
                        });

                        setShowSignature(true);
                      }
                      setIsConfirmAlertOpen(false);
                    }}
                    onCancel={() => {
                      setIsConfirmAlertOpen(false);
                      setDisableSignature(false);
                    }}
                  >
                    E-signature will be added.
                  </SweetAlert>
                ) : null}
              </div>
            </td>
          </tr>
        </>
      );
    } else {
      return null;
    }
  };

  const DisplayChecklistItems = ({ mainList, mainIndex }) => {
    {
      return mainList.subItems.map((item, itemIndex) => {
        if (collectionName === INSPECTION_DB_NAME)
          return (
            <DisplayInspectionItem
              mainList={mainList}
              mainIndex={mainIndex}
              item={item}
              itemIndex={itemIndex}
              key={item.id + mainIndex.toString() + itemIndex.toString()}
            />
          );
        if (collectionName === ACTIVE_JOBS_DB_NAME)
          return (
            <DisplayItem
              mainList={mainList}
              mainIndex={mainIndex}
              item={item}
              itemIndex={itemIndex}
              key={item.id + mainIndex.toString() + itemIndex.toString()}
            />
          );
      });
    }
  };

  const DisplayChecklist = ({ checklist }) => {
    return checklist.items.map((mainList, mainIndex) => {
      return (
        <Card key={mainList.id}>
          <CardBody>
            <CardTitle className="mb-4">{`${mainIndex + 1}. ${mainList.content
              }`}</CardTitle>
            <table className="table align-middle mb-0">
              <tbody>
                {mainList.subItems.length > 0 ? (
                  expanded && (
                    <DisplayChecklistItems
                      mainList={mainList}
                      mainIndex={mainIndex}
                    />
                  )
                ) : (
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      The section has no checklist items
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </CardBody>
        </Card>
      );
    });
  };

  const onDrop = useCallback(acceptedFiles => {
    setselectedFiles([]);
    handleAcceptedFiles(acceptedFiles);
  });

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [".jpeg", ".png", ".jpg", ".JPEG", ".PNG", ".JPG"],
    },
  });

  const downloadImage = DownloadImage();

  const getBlobFromFirebase = async fileRef => {
    try {
      const url = await fileRef.getDownloadURL();
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error downloading file: " + error);
      throw error;
    }
  };

  const getQuality = fileSizeInMB => {
    return fileSizeInMB > 2 ? 0.2 : fileSizeInMB > 1 ? 0.3 : 0.6;
  };

  const addAttachmentDetails = async ({
    job,
    checklistDocumentId,
    sectionId,
    itemId,
    files,
    collectionName,
  }) => {
    const checklistRef = fetchChecklistByDocumentId(
      collectionName,
      job.id,
      checklistDocumentId
    );
    try {
      await Promise.all(
        files?.map(file => {
          return new Promise(async resolve => {
            let fileRef = storageRef.child(
              `${job?.orgId}/documents/${file?.id}`
            );
            const blob = await getBlobFromFirebase(fileRef);
            const fileId = cuid();
            file.id = fileId;
            const filePath = `${job.orgId}/${job.jobNo}/images/${fileId}`;
            var fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
            new Compressor(blob, {
              quality: getQuality(fileSizeInMB),
              success(result) {
                const uploadTask = storageRef.child(filePath).put(result, {
                  contentType: result.type,
                });
                uploadTask.on(
                  "state_changed",
                  snap => {
                    const percentUploaded = Math.round(
                      (snap.bytesTransferred / snap.totalBytes) * 100
                    );
                    file.percent = percentUploaded;
                    setPercent(percentUploaded);
                  },
                  err => {
                    console.error(err);
                  },
                  () => {
                    uploadTask.snapshot.ref
                      .getDownloadURL()
                      .then(async downloadUrl => {
                        file.downloadUrl = downloadUrl;
                        resolve(downloadUrl);
                      })
                      .catch(err => {
                        console.error(err);
                      });
                  }
                );
              },
              error(err) {
                console.log(err.message);
              },
            });
          });
        })
      );

      await db.runTransaction(async txn => {
        const checklistDoc = await txn.get(checklistRef);
        const checklist = checklistDoc.data();
        if (checklist && checklist.items && checklist.items.length > 0) {
          const updatedChecklistItems = checklist.items.map(section => {
            if (section.id == sectionId) {
              const newSubItems = section.subItems.map(item => {
                if (item.id === itemId) {
                  files?.map(file => {
                    const resizedImagePath = `${job.orgId}/${job.jobNo}/images/${RESIZED_IMAGES_PATH}/${file.id}_${RESIZED_IMAGES_DIMENSIONS}`;
                    if (item.attachments) {
                      item.attachments.push({
                        id: file.id,
                        userId: currentUserProfile.id,
                        name: file.name,
                        type: file.type,
                        url: file.downloadUrl,
                        size: file.size,
                        userName: userName(currentUserProfile),
                        resizedImagePath: resizedImagePath,
                        date: new Date(),
                      });
                    } else {
                      item.attachments = [
                        {
                          id: file.id,
                          userId: currentUserProfile.id,
                          name: file.name,
                          type: file.type,
                          url: file.downloadUrl,
                          size: file.size,
                          userName: userName(currentUserProfile),
                          resizedImagePath: resizedImagePath,
                          date: new Date(),
                        },
                      ];
                    }
                  });
                }
                return item;
              });
              section.subItems = newSubItems;
            }
            return section;
          });
          txn.update(checklistRef, { items: updatedChecklistItems });
        }
      });
      setShowUploadIcon(true);
      toast.success("photo uploaded successfully !", {
        autoClose: 2000,
      });
    } catch (error) {
      console.log("Failed to upload photo:", error);
      toast.error("Failed to upload photo", {
        autoClose: 2000,
      });
    }
  };
  const DisplayDocumentList = ({ document, index, documentId }) => {
    return (
      <div key={index} className="w-100">
        {document?.type.includes("image") && (
          <Row className="align-items-center">
            <Col sm="6" className="d-flex align-items-center text-align-center">
              {showCheckBox && (
                <Checkbox
                  className="checked"
                  checked={document?.checked}
                  style={{
                    float: "left",
                    height: "30px",
                    marginRight: "5px",
                    color: "#65a2eb",
                  }}
                  onChange={() => {
                    const newChecklist = [...documentLibrary];
                    newChecklist.map(item => {
                      if (item.id === documentId) {
                        item?.attachments?.map(doc => {
                          if (doc.id === document?.id) {
                            doc.checked = !doc.checked;
                            if (doc?.checked) {
                              setSelectedDocuments([
                                ...selectedDocuments,
                                document,
                              ]);
                            } else {
                              setSelectedDocuments(
                                selectedDocuments.filter(
                                  doc => doc.id !== document.id
                                )
                              );
                            }
                          }
                        });
                      }
                    });
                    setDocumentLibrary(newChecklist);
                  }}
                />
              )}
              <img
                data-dz-thumbnail=""
                className="avatar-sm rounded bg-light img-thumbnail mb-1"
                alt={document.name}
                onClick={() => {
                  if (document.type !== "application/pdf") {
                    setImages([document.url]);
                    setisGallery(true);
                    setphotoIndex(0);
                    setSelectedFileType("image");
                  } else {
                    setPdf(true);
                  }
                }}
                src={
                  document.type === "application/pdf" ? pdfLogo : document.url
                }
                style={{ cursor: "pointer", fontSize: "1rem" }}
                name={document.name}
              />
              <p className="text-truncate m-1">{document?.name}</p>
            </Col>
            <Col sm="6">
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  color="link"
                  className="text-muted"
                  onClick={() => {
                    setSelectedDocument(document);
                    addAttachmentDetails({
                      files: [document],
                      job,
                      checklistDocumentId,
                      sectionId: currentSelect.parentId,
                      itemId: currentSelect.itemId,
                      collectionName: collectionName,
                    });
                    setShowUploadIcon(false);
                  }}
                  disabled={!showUploadIcon}
                >
                  <i className="display-6 text-muted bx bxs-cloud-upload" />
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  const DisplayDocument = ({ document, index }) => {
    return (
      <div className="d-flex" key={index}>
        <Card
          style={{
            width: "100%",
          }}
          className="mt-1 mb-0 shadow-md border  certificate-container dz-processing dz-image-preview dz-success dz-complete"
        >
          <CardBody>
            <Row>
              <Col sm="6">
                <CardTitle style={{ cursor: "pointer", fontSize: "1rem" }}>
                  {document?.name}
                </CardTitle>
              </Col>
            </Row>
            {document?.attachments?.map((attachment, index) => (
              <DisplayDocumentList
                document={attachment}
                index={index}
                key={index}
                documentId={document.id}
              />
            ))}
          </CardBody>
        </Card>
      </div>
    );
  };

  const DisplayDocuments = ({ documentLibrary }) => {
    return documentLibrary?.map((document, index) => (
      <DisplayDocument document={document} index={index} key={index} />
    ));
  };

  const handleLockArea = () => {
    // console.log(job?.createdByUserId);
    // console.log(currentUserProfile?.id);
    // console.log(job?.areas[areaId]?.isLocked);
    setIsAlertShown(true);
    // setIsAreaLocked(isAreaLocked ? false : true);
  };

  const handleConfirm = async () => {
    // setIsAreaLocked(isAreaLocked ? false : true);
    // db.runTransaction()
    await lockAreaChecklist();
    setIsAlertShown(false);
  };


  return (
    <>
      {isNotesUpdated && (
        <NotesConfirmationPrompt
          setIsNotesUpdated={setIsNotesUpdated}
          notesInfo={notesInfo}
        />
      )}
      {isGallery ? (
        <>
          {selectedFileType == "signature" && (
            <Lightbox
              reactModalStyle={{
                overlay: {
                  backgroundColor: "white",
                },
              }}
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              toolbarButtons={[
                <DownloadIcon
                  onClick={() => {
                    const imageUrl = images[photoIndex];
                    downloadImage(imageUrl, "image");
                  }}
                />,
              ]}
              onCloseRequest={() => {
                setisGallery(false);
                setImages([]);
              }}
              onMovePrevRequest={() => {
                setphotoIndex((photoIndex + images.length - 1) % images.length);
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % images.length);
              }}
            />
          )}
          {selectedFileType == "attachment" && canUpload && canEdit ? (
            <ImageEditor
              imageSrc={images[photoIndex]}
              isGallery={isGallery}
              setisGallery={setisGallery}
              Uploader={Uploader}
              job={job}
              checklistDocumentId={checklistDocumentId}
              sectionId={currentSelect.parentId}
              itemId={currentSelect.itemId}
              collectionName={collectionName}
              files={[selectedFile]}
            />
          ) : (
            <Lightbox
              reactModalStyle={{
                overlay: {
                  backgroundColor: "white",
                },
              }}
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              toolbarButtons={[
                <DownloadIcon
                  onClick={() => {
                    const imageUrl = images[photoIndex];
                    downloadImage(imageUrl, "image");
                  }}
                />,
              ]}
              onCloseRequest={() => {
                setisGallery(false);
                setImages([]);
              }}
              onMovePrevRequest={() => {
                setphotoIndex((photoIndex + images.length - 1) % images.length);
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % images.length);
              }}
            />
          )}
        </>
      ) : null}
      <Modal
        isOpen={showEditModal}
        size="xl"
        className="modal-fullscreen"
        style={{ top: "-14rem" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Edit Checklist
          </h5>
          <button
            type="button"
            onClick={onModalClose}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <CheckListPage
              checklistItems={checklistItems}
              onSave={onSaveChanges}
              onClose={onModalClose}
            />
          </Row>
        </div>
      </Modal>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            <span>
              <Button
                color="link"
                float="end"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? "Collapse" : "Expand"}
              </Button>
            </span>
            {canEdit && (
              <Button
                color="primary"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setShowEditModal(true);
                }}
                disabled={job?.areas?.[areaId]?.isLocked}
              >
                Edit Checklist
              </Button>
            )}
            {canEdit && (
              <span style={{ float: "right", paddingTop: 5 }}>
                <Button
                  // color="green"
                  style={{
                    backgroundColor: !job?.areas?.[areaId]?.isLocked
                      ? "#34c28f"
                      : "#f46a6a",
                    border: 0,
                  }}
                  className="btn btn-primary btn-sm"
                  onClick={handleLockArea}
                // disabled={job?.areas[areaId]?.isLocked}
                >
                  {job?.areas?.[areaId]?.isLocked ? "Unlock Area" : "Lock Area"}
                </Button>
              </span>
            )}
          </CardTitle>
          {isAlertShown && (
            <SweetAlert
              warning
              showCancel={false}
              showConfirm={false}
              onConfirm={() => { }}
            >
              <h2>
                {`Are you sure you want to
                  ${!job?.areas[areaId]?.isLocked ? "lock" : "unlock"}
                the area?`}
              </h2>
              <div
                style={{
                  padding: "1rem 0",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Button color="success" onClick={handleConfirm}>
                  YES
                </Button>
                <Button
                  style={{ backgroundColor: "#f46a6a", border: 0 }}
                  onClick={() => setIsAlertShown(false)}
                >
                  No
                </Button>
              </div>
            </SweetAlert>
          )}
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <div
                className="spinner-border text-primary"
                id="spinner"
                role="status"
              ></div>
              <div>Loading...</div>
            </div>
          ) : areaChecklist?.items?.length > 0 ? (
            areaChecklist?.items && (
              <div style={{ maxHeight: "700px", overflow: "auto" }}>
                {" "}
                <DisplayChecklist checklist={areaChecklist} />
              </div>
            )
          ) : (
            <p style={{ textAlign: "center" }}>The area has no sections</p>
          )}
        </CardBody>
      </Card>
      {isSignatureNotExist && (
        <SignatureDialog setIsSignatureNotExist={setIsSignatureNotExist} />
      )}
      <Modal
        isOpen={showSignature}
        size="xl"
        className="modal-fullscreen"
        style={{ top: "-14rem" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Signature
          </h5>
          <button
            type="button"
            onClick={() => setShowSignature(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <SignaturePad
            options={{ backgroundColor: "white" }}
            redrawOnResize={true}
            ref={signatureRef}
          />
        </div>
        <div className="modal-footer">
          <button
            disabled={saving}
            type="button"
            onClick={() => setShowSignature(false)}
            className="btn btn-secondary float-left"
            data-dismiss="modal"
          >
            <i className="dripicons-cross" />
            Close
          </button>
          <button
            disabled={saving}
            type="button"
            onClick={() => signatureRef.current.clear()}
            className="btn btn-primary "
            data-dismiss="modal"
          >
            <i style={{ fontSize: "1rem" }} className="bx bxs-eraser" /> Clear
          </button>
          <button
            disabled={saving}
            type="button"
            className="btn btn-success "
            onClick={onClickSignSave}
          >
            {saving ? (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            ) : (
              <i style={{ fontSize: "1rem" }} className="bx bx-save" />
            )}{" "}
            Save
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showModal}
        size={isMaximized ? "l" : "xl"}
        // className={classNames("d-flex align-items-center justify-content-center modal", { "modal-maximized": isMaximized })}
        // style={{ overflow: "hidden", top: isMaximized ? "0" : "-2rem", height: isMaximized ? "100vh" : "auto", width: isMaximized ? "100vw" : "auto" }}
        className="d-flex align-items-center justify-content-center modal"

        style={{ overflow: "hidden", top: "-2rem" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Upload Files
          </h5>
          <button
            type="button"
            onClick={() => {
              setShowModal(false);
              //setSelectedFileId(null);
              setCurrentSelect({});
              setselectedFiles([]);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            {/*   */}
            <span aria-hidden="true">&times;</span>
            <button type="button"
              onClick={(e) => {
                e.stopPropagation() // Prevent default behavior (page reload)
                setShowModal(true); // Ensure modal is open
                setIsMaximized(!isMaximized); // Toggle maximize/minimize state

              }
              } className="maximize-button">
              {'-'}
            </button>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-6 d-flex align-items-center justify-content-center">
              <Card>
                <CardBody>
                  <Form encType="multipart/form-data">
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        {isDragReject ? (
                          <h4>Sorry,Please upload valid image format.</h4>
                        ) : (
                          <>
                            <h4>Drop files here or click to upload.</h4>
                            <p style={{ fontSize: "12px" }}>
                              Only jpeg, jpg, png & gif formats supported.
                            </p>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.length > 0 && (
                        <Uploader
                          job={job}
                          checklistDocumentId={checklistDocumentId}
                          sectionId={currentSelect.parentId}
                          itemId={currentSelect.itemId}
                          files={selectedFiles}
                          collectionName={collectionName}
                        />
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col
              className="col-6"
              style={{ height: "calc(100vh - 150px)", overflow: "auto" }}
            >
              <Card>
                <CardBody>
                  <CardTitle>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classNames({
                            active: true,
                          })}
                        >
                          Saved Document Library
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardTitle>
                  <TabContent activeTab={"2"} className="p-3">
                    <TabPane className="saved-checklist-tab" tabId="2">
                      <div className="mb-2">
                        <div>
                          <Label className="saved-checklist-tab__label">
                            Search Saved Document Library
                          </Label>
                          <Button
                            size="md"
                            color="link"
                            className="saved-checklist-tab__select"
                            onClick={() => {
                              showCheckBox ? onCancel() : setShowCheckBox(true);
                              setShowUploadIcon(() => !showUploadIcon);
                            }}
                          >
                            {showCheckBox ? "Cancel" : "Select Multiple"}
                          </Button>
                          {selectedDocuments?.length > 0 && (
                            <Button
                              size="md"
                              color="link"
                              className="saved-checklist-tab__select"
                              onClick={() => {
                                addAttachmentDetails({
                                  files: selectedDocuments,
                                  job: job,
                                  checklistDocumentId: checklistDocumentId,
                                  sectionId: currentSelect.parentId,
                                  itemId: currentSelect.itemId,
                                  collectionName: collectionName,
                                });
                                setShowCheckBox(false);
                                setShowUploadIcon(false);
                                setSelectedDocuments([]);
                                onCancel();
                              }}
                            >
                              Upload
                            </Button>
                          )}
                          <Input
                            type="search"
                            placeholder="Enter keyword"
                            name="checklistSearch"
                            onChange={e => setChecklistSearch(e.target.value)}
                          ></Input>
                        </div>
                      </div>
                      <Row className="saved-checklist-tab__list">
                        <DisplayDocuments documentLibrary={documentLibrary} />
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {percent > 0 && (
            <ProgressLine
              percent={percent}
              setPercent={setPercent}
              files={[selectedDocument]}
            />
          )}
        </div>
      </Modal>
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            onDeletedAttachment(
              currentSelect.parentId,
              currentSelect.itemId,
              currentSelect.fileId
            );
            setCurrentSelect({});
            setConfirmAlert(false);
            setSuccessDlg(true);
            setDynamicTitle("Deleted");
            setDynamicDescription("Your file has been deleted.");
          }}
          onCancel={() => {
            setCurrentSelect({});
            setConfirmAlert(false);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {successDlg ? (
        <SweetAlert
          success
          title={dynamicTitle}
          onConfirm={() => {
            setSuccessDlg(false);
          }}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}
      {confirmSignAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            await deleteSignature(
              currentSelect.parentId,
              currentSelect.itemId,
              currentSelect.fileId
            );
            setCurrentSelect({});
            setConfirmSignAlert(false);
            setSuccessSignDlg(true);
            setDynamicSignTitle("Deleted");
            setDynamicSignDescription("Your signature has been removed.");
          }}
          onCancel={() => {
            setCurrentSelect({});
            setConfirmSignAlert(false);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {successSignDlg ? (
        <SweetAlert
          success
          title={dynamicSignTitle}
          onConfirm={() => {
            setSuccessSignDlg(false);
          }}
        >
          {dynamicSignDescription}
        </SweetAlert>
      ) : null}
    </>
  );
}

export function Uploader({
  job,
  checklistDocumentId,
  sectionId,
  itemId,
  files,
  collectionName,
}) {
  const storageRef = firebase.storage().ref();
  const db = firebase.firestore();
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState(null);
  const { currentUserProfile } = useSelector(state => state.profile);

  const addAttachmentDetails = async () => {
    const checklistRef = fetchChecklistByDocumentId(
      collectionName,
      job.id,
      checklistDocumentId
    );
    try {
      await db.runTransaction(async txn => {
        const checklistDoc = await txn.get(checklistRef);
        const checklist = checklistDoc.data();
        if (checklist && checklist.items && checklist.items.length > 0) {
          const updatedChecklistItems = checklist?.items?.map(section => {
            if (section.id == sectionId) {
              const newSubItems = section.subItems.map(item => {
                if (item.id === itemId) {
                  files?.map(file => {
                    const resizedImagePath = `${job.orgId}/${job.jobNo}/images/${RESIZED_IMAGES_PATH}/${file.id}_${RESIZED_IMAGES_DIMENSIONS}`;
                    if (item.attachments) {
                      item.attachments.push({
                        id: file.id,
                        name: file.name,
                        userId: currentUserProfile.id,
                        type: file.type,
                        url: file.downloadUrl,
                        resizedImagePath: resizedImagePath,
                        size: file.formattedSize,
                        userName: userName(currentUserProfile),
                        date: new Date(),
                      });
                    } else {
                      item.attachments = [
                        {
                          id: file.id,
                          name: file.name,
                          userId: currentUserProfile.id,
                          type: file.type,
                          url: file.downloadUrl,
                          resizedImagePath: resizedImagePath,
                          size: file.formattedSize,
                          userName: userName(currentUserProfile),
                          date: new Date(),
                        },
                      ];
                    }
                  });
                }
                return item;
              });
              section.subItems = newSubItems;
            }
            return section;
          });
          txn.update(checklistRef, { items: updatedChecklistItems });
          console.log("checklist updated successfully!");
        }
      });
    } catch (error) {
      console.log("checklist updation failed:", error);
    }
  };

  useEffect(() => {
    uploadToFirebase();
  }, []);
  const getQuality = fileSizeInMB => {
    return fileSizeInMB > 2 ? 0.2 : fileSizeInMB > 1 ? 0.3 : 0.6;
  };

  const uploadToFirebase = async () => {
    await Promise.all(
      files?.map(file => {
        return new Promise(resolve => {
          const fileId = cuid();
          file.id = fileId;
          const filePath = `${job.orgId}/${job.jobNo}/images/${fileId}`;
          var fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);

          new Compressor(file, {
            quality: getQuality(fileSizeInMB),
            success(result) {
              const uploadTask = storageRef.child(filePath).put(result, {
                contentType: result.type,
              });
              uploadTask.on(
                "state_changed",
                snap => {
                  const percentUploaded = Math.round(
                    (snap.bytesTransferred / snap.totalBytes) * 100
                  );
                  file.percent = percentUploaded;
                  setPercent(percentUploaded);
                },
                err => {
                  console.error(err);
                  setError(error);
                },
                () => {
                  uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then(async downloadUrl => {
                      file.downloadUrl = downloadUrl;
                      resolve(downloadUrl);
                    })
                    .catch(err => {
                      console.error(err);
                      setError(err);
                    });
                }
              );
            },
            error(err) {
              console.log(err.message);
            },
          });
        });
      })
    );
    await addAttachmentDetails();
  };

  const Message = ({ children }) => {
    const [show, setShow] = useState(true);

    // On componentDidMount set the timer
    useEffect(() => {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setPercent(-1);
        setShow(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    }, []);

    // If show is false the component will return null and stop here
    if (!show) {
      return null;
    }

    // If show is true this will be returned
    return <>{children}</>;
  };

  const FileInfo = () => {
    return files.map(file => {
      return (
        <div key={file.id}>
          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
            <div className="p-2">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <img
                    data-dz-thumbnail=""
                    height="80"
                    className="avatar-sm rounded bg-light"
                    alt={file.name}
                    src={
                      file.type === "application/pdf" ? pdfLogo : file.preview
                    }
                  />
                </Col>
                <Col>
                  <div to="#" className="text-muted font-weight-bold">
                    {file.name}
                  </div>
                  <p className="mb-0">
                    <strong>{file.formattedSize}</strong>
                  </p>
                </Col>
              </Row>
            </div>
            <div className="p-1">
              {file.percent === 100 ? (
                <Progress
                  className="progress-sm"
                  striped
                  animated
                  color="success"
                  value={file.percent}
                />
              ) : (
                <Progress
                  className="progress-sm"
                  striped
                  animated
                  color="danger"
                  value={file.percent}
                />
              )}
            </div>
          </Card>
        </div>
      );
    });
  };

  if (percent === -1) {
    return null;
  } else if (percent === 100) {
    return (
      <Message>
        <FileInfo />
      </Message>
    );
  } else {
    return <FileInfo />;
  }
}
