export const CREATE_JOB = "CREATE_JOB";
export const UPDATE_JOB = "UPDATE_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE";
export const FETCH_DELETED_SUCCESS = "FETCH_DELETED_SUCCESS";
export const FETCH_ARCHIVED_SUCCESS = "FETCH_ARCHIVED_SUCCESS";
export const FETCH_DRAFT_SUCCESS = "FETCH_DRAFT_SUCCESS";
export const FETCH_DRAFT_FAILURE = "FETCH_DRAFT_FAILURE";
export const FETCH_SINGLE_DRAFT_SUCCESS = "FETCH_SINGLE_DRAFT_SUCCESS";
export const IS_CLONING = "IS_CLONING";
