// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-card-container {
  background-color: #B9E5FF2E;
  border-radius: 8px;
  border-width: 1px;
  border-color: #B9E5FFFF;
  border-style: solid; }

.job-name-header {
  color: #15abff; }

.job-address-header {
  color: #323743FF !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  overflow: hidden; }

.remaining-count {
  background-color: #ff4500;
  /* Set the background color for the count */
  color: #ffffff;
  /* Set the text color for the count */
  border-radius: 50%;
  /* Make it a circle */
  padding: 4px 8px;
  /* Adjust padding as needed */
  font-size: 12px;
  /* Adjust font size as needed */
  margin-left: 5px;
  /* Adjust the spacing between the remaining count and avatars */ }

.count-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 4px;
  font-size: 12px; }
`, "",{"version":3,"sources":["webpack://./src/components/jobs-ui/CardStyles.scss"],"names":[],"mappings":"AACA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB,EAAA;;AAErB;EACE,cAAa,EAAA;;AAEf;EACE,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB,EAAA;;AAElB;EACE,yBAAyB;EAAE,2CAAA;EAC3B,cAAc;EAAE,qCAAA;EAChB,kBAAkB;EAAE,qBAAA;EACpB,gBAAgB;EAAE,6BAAA;EAClB,eAAe;EAAE,+BAAA;EACjB,gBAAgB;EAAE,+DAAA,EAAgE;;AAEpF;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,0CAA0C;EAC1C,YAAY;EACZ,eAAe,EAAA","sourcesContent":[" \n.job-card-container{\n  background-color: #B9E5FF2E; \n  border-radius: 8px; \n  border-width: 1px; \n  border-color: #B9E5FFFF; \n  border-style: solid; \n}\n.job-name-header{\n  color:#15abff;\n}\n.job-address-header{\n  color: #323743FF !important;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 22px;\n  overflow: hidden;\n}\n.remaining-count {\n  background-color: #ff4500; /* Set the background color for the count */\n  color: #ffffff; /* Set the text color for the count */\n  border-radius: 50%; /* Make it a circle */\n  padding: 4px 8px; /* Adjust padding as needed */\n  font-size: 12px; /* Adjust font size as needed */\n  margin-left: 5px; /* Adjust the spacing between the remaining count and avatars */\n}\n.count-overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  background-color: rgba(255, 255, 255, 0.7);\n  padding: 4px;\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
