// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-date-picker {
  border: none;
  color: #50a5f1;
  margin-bottom: 0.5rem; }

.custom-job-date-picker {
  border: none;
  color: #50a5f1;
  margin-bottom: 0.5rem;
  font-size: 15px;
  cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/jobDetails/DaterangePicker.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,qBAAqB,EAAA;;AAGvB;EACE,YAAY;EACZ,cAAc;EACd,qBAAqB;EACrB,eAAe;EACf,eAAe,EAAA","sourcesContent":[".custom-date-picker {\n  border: none;\n  color: #50a5f1;\n  margin-bottom: 0.5rem;\n}\n\n.custom-job-date-picker {\n  border: none;\n  color: #50a5f1;\n  margin-bottom: 0.5rem;\n  font-size: 15px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
