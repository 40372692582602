import { PENDING, PLATFORM_OWNER } from "common/constants";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
//i18n
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { isValidOrgUser } from "routes/allRoutes";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
import organisationStatus from "utils/organisationStatus";
import { PlatfromOwnerSidebar } from "../SideBar/PlatfromOwnerSidebar";
import { OrgOwnerSidebar } from "../SideBar/OrgOwnerSidebar";

const SidebarContent = props => {
  const ref = useRef();
  const { currentUserProfile } = useSelector(state => state.profile);
  

  useEffect(() => {
    ref.current.recalculate();
  });
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {currentUserProfile &&
            currentUserProfile.type === PLATFORM_OWNER ? (
              <PlatfromOwnerSidebar props={props}/>
            ) : (
              <OrgOwnerSidebar currentUserProfile={currentUserProfile} props={props}/>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));



