import React from "react";
import { UncontrolledTooltip, Tooltip } from "reactstrap";
import "./CardStyles.scss";
import { AVATAR_URL } from "common/constants";

const ParticipantsUiInCard = ({ participants }) => {
  const getMaxParticipants = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      return 2;
    } else {
      return 4;
    }
  };

  const MAX_PARTICIPANTS = getMaxParticipants();

  const renderAvatar = (member, index) => {
    const { displayName, id, photoURL } = JSON.parse(member);
    return (
      <div className="avatar-group-item" key={index.toString()}>
        <div className="team-member d-inline-block" id={"member" + id}>
          <img
            src={
              photoURL ||
              `${AVATAR_URL}&name=${displayName}`
            }
            className="rounded-circle avatar-xs"
            alt=""
          />
          <UncontrolledTooltip placement="top" target={"member" + id}>
            {displayName}
          </UncontrolledTooltip>
        </div>
      </div>
    );
  };

  const renderRemainingParticipants = count => {
    return (
      <div className="avatar-group-item" key="remaining">
        <div className="team-member d-inline-block">
          <img
            src={`${AVATAR_URL}&name=%2B${count}`}
            className="rounded-circle avatar-xs"
            alt=""
          />
        </div>
      </div>
    );
  };

  const displayedParticipants = participants.slice(0, MAX_PARTICIPANTS);
  const remainingParticipantsCount = participants.length - MAX_PARTICIPANTS;

  return (
    <div className="avatar-group">
      {displayedParticipants.map((member, index) =>
        renderAvatar(member, index)
      )}
      {remainingParticipantsCount > 0 &&
        renderRemainingParticipants(remainingParticipantsCount)}
    </div>
  );
};
export default ParticipantsUiInCard;
