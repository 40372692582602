import React from "react";
import { Badge, UncontrolledTooltip } from "reactstrap";
import {
  INTERNAL,
  EXTERNAL,
  BELOW_GROUND,
  RESIDENTIAL_CATEGORY,
  COMMERCIAL_CATEGORY,
  INFRASTRUCTURE_CATEGORY,
} from "../../../common/constants";

const getLabel = type => {
  switch (type) {
    case INTERNAL:
      return RESIDENTIAL_CATEGORY;
    case EXTERNAL:
      return COMMERCIAL_CATEGORY;
    case BELOW_GROUND:
      return INFRASTRUCTURE_CATEGORY;
  }
};

const getLabelColor = type => {
  switch (type) {
    case INTERNAL:
      return "bg-success";
    case EXTERNAL:
      return "bg-warning";
    case BELOW_GROUND:
      return "bg-primary";
  }
};

const getType = type => {
  switch (type) {
    case INTERNAL:
      return "internal";
    case EXTERNAL:
      return "external";
    case BELOW_GROUND:
      return "belowground";
  }
};

const Category = ({ type, job, isList }) => {
  return (
    <>
      <span id={`${type}-${job?.id}`} className="category-label">
        <Badge className={`text-muted ${getLabelColor(type)}`}>{getLabel(type)}</Badge>{" "}
        {!isList && job && (
          <Badge className={`${getLabelColor(type)} mr-5`}>
            {`${job[getType(type)]?.rooms?.reduce((acc, floor) => {
              return acc + floor.subItems.length;
            }, 0)}`}
          </Badge>
        )}
      </span>
      {job && (
        <UncontrolledTooltip
          modifiers={[
            {
              name: "flip",
              options: {
                fallbackPlacements: ["bottom"],
              },
            },
          ]}
          className="catergory-tooltip"
          placement="bottom"
          innerClassName="classes-tooltip"
          target={`${type}-${job.id}`}
        >
          {job[getType(type)]?.buildingClass
            ?.filter(c => c.checked)
            .map(c => c.value)
            .join(", ")}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default Category;
