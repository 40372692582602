
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { COMPLAINCE, INSPECTION, JOB_REPORT } from "common/constants";
import { memo } from "react";
const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  docDetails: {
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "baseline",
    marginBottom: 10,
  },
  logo: {
    width: 72,
    height: 72,
  },
  logoHeader: {
    width: "100%",
    height: 200,
    marginTop: 10,
  },
  headerData: {
    flexDirection: "cloumn",
    marginLeft: 4,
  },
  headerLabel: {
    fontSize: 16,
    fontWeight: 600,
    alignSelf: "flex-end",
    color: "#004F6B",
    fontFamily: "Roboto",
  },
  headerContent: {
    fontSize: 14,
    fontWeight: 400,
    color: "#072535",
    alignSelf: "flex-end",
    fontFamily: "Roboto",
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 24,
    color: "#ffffff",
  },
  title: {
    fontSize: 18,
    textTransform: "capitalize",
    fontWeight: 600,
    color: "white",
    fontFamily: "Roboto",
  },
  line: {
    borderBottom: 1,

    borderBottomColor: "#1F4E68",
    marginTop: 5,
  },
  horizontalLine: {
    borderBottom: 2,
    borderBottomColor: "#1F4E68",
  },
  container: {
   textAlign:"left",
  },
  text: {
    padding:3,
    color: "#ffffff",
    fontSize: 20,
    border: "2 solid #1F4E68",
    backgroundColor: "#1F4E68",
  },
  docnameValue: {
    color: "#1F4E68",
    fontSize: 28,
    fontWeight: 600,
    alignSelf: "flex-start",
    fontFamily: "Roboto",
  },
});
const Header = ({ certificate }) => {
  const { orgInfo, docNo, certificateDate } = certificate;
  return (
    <View>
      <View style={styles.headerContainer}>
        <View style={styles.headerData}>
          {orgInfo?.photoURL && (
            <Image style={styles.logo} src={orgInfo?.photoURL} />
          )}
        </View>
        <View style={styles.docDetails}>
          <Text style={styles.headerLabel}> {orgInfo.name}</Text>
          <Text style={styles.headerContent}>Business/Company Number: {orgInfo.abn}</Text>
          <Text style={{ ...styles.headerContent }}>
            {orgInfo.address} | {orgInfo.phone}
          </Text>
        </View>
      </View>
      <View style={styles.horizontalLine} />
     
      {certificate?.docName?.trim() && (
          <View style={styles.container}>
          <Text style={styles.text}>{certificate?.docName}</Text>
        </View>
      )}
    
      {certificate?.jobPhotoUrl && (
        <Image style={styles.logoHeader} src={certificate?.jobPhotoUrl} />
      )}
    </View>
  );
};
export default memo(Header);
