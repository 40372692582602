import {
  FETCH_NOTES,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAILURE,
} from "./actionTypes";

const initialState = {
  notes: [],
};

export default function notesReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_NOTES:
      return {
        ...state,
      };
    case FETCH_NOTES_SUCCESS:
      return {
        ...state,
        notes: payload,
      };
    case FETCH_NOTES_FAILURE:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}
