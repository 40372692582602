import { useEffect, useState } from "react";
import drippyGreen from "assets/images/drippy/icons8-checked.gif";
import drippyRed from "assets/images/drippy/icons8-error.gif";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import JobLeavingGuard from "pages/jobs/jobCreation/JobLeavingGuard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import firebase from "config/firebase";
import cuid from "cuid";
import ProgressBar from "searchuicomponents/ProgressBar";
import {
  addAdminNotificationsToDb,
  addJobToDb,
  deleteDraftJobInDb,
  getOrgFromDb,
  fetchDraftRefFromDb,
  getUser,
  dataFromSnapshot,
  getOrgOwnerDetails,
  getAllUsers,
} from "services";
import {
  moveChecklistsSubCollection,
  updateParticipants,
} from "services/jobService";
import { createJob } from "store/job/actions";
import "./Finalize.scss";
import {
  DRAFTS_DB_NAME,
  ACTIVE_JOBS_DB_NAME,
  INSPECTION_DB_NAME,
  RESIDENTIAL_CATEGORY,
  COMMERCIAL_CATEGORY,
  INFRASTRUCTURE_CATEGORY,
  WORKING_DATES_COLLECTION,
  CHEKCLIST_COLLECTION,
  PENDING,
} from "common/constants";
import { sendEmailToInvitedUserOfJob } from "./utils/user";
import generator from "generate-password-browser";
import { addInspectionJobToDb } from "services/inspectionService";
import { createNewUser } from "./createNewUser";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
export default function Finzliase({
  newJob: draftJob,
  prev,
  activeTab,
  collectionName,
  validateParticipants
}) {
  const [canCreateInternal, setCanCreateInternal] = useState(false);
  const [canCreateExternal, setCanCreateExternal] = useState(false);
  const [canCreateBelow, setCanCreateBelow] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finalised, setFinal] = useState(false);
  const [newJobId, setNewJobId] = useState("/dashboard");
  const [newJobNo, setNewJobNo] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [categoryTab, setCategoryTab] = useState("0");
  const { currentUserProfile } = useSelector(state => state.profile);
  const [areasWithChecklist, setAreasWithChecklist] = useState({});
  const [hasInternal, setHasInternal] = useState(draftJob.jobType.internal);
  const [hasExternal, setHasExternal] = useState(draftJob.jobType.external);
  const [hasBelow, setHasBelow] = useState(draftJob.jobType.belowground);


  const [role, setRole] = useState({
    label: "Installer",
    value: "installer",
  });
  const [access, setAccess] = useState({ label: "Full", value: "full" });
  const [error, setError] = useState(null);
  const [orgOwnerDetails, setOrgOwnerDetails] = useState({});
  const [options, setOptions] = useState([]);
  const [installers, setInstallers] = useState([]);
  
  useFirestoreCollection({
    query: () => getOrgOwnerDetails(currentUserProfile.orgAccessId),
    onStart: () => () => setLoading(true),
    onSuccess: data => {
      setOrgOwnerDetails(data[0]);
      setLoading(false);
    },
    onError: error => setError(error),
    deps: [],
  });
  
  useEffect(() => {
    const getOptions = async () => {
      const allUsers = await getAllUsers(currentUserProfile?.orgAccessId);
      const updatedOptions = allUsers?.map(user => {
        const isPendingUser = user.orgStatus && user.orgStatus === PENDING;
        return { ...user, disabled: isPendingUser };
      });
      setOptions(updatedOptions || []);
    };
    getOptions();
    if (isUserAndOrgOwnerNotParticipents()) {
      setInstallers([
        ...draftJob.participants,
        getCurrentProfile(),
        getOrgOwner(),
      ]);
    } else if (isUserPariticiepentNotOrgOwner()) {
      setInstallers([...draftJob.participants, getOrgOwner()]);
    } else if (isOrgOwnerPariticiepentNotUser()) {
      setInstallers([...draftJob.participants, getCurrentProfile()]);
    } else if (isUserAndOrgOwnerSameAndNotParticipent()) {
      setInstallers([
        ...draftJob.participants,
        {
          ...getCurrentProfile(),
          role: { label: "Org Owner", value: "Org Owner" },
        },
      ]);
    } else {
      setInstallers(draftJob.participants);
    }
    validateParticipants(draftJob?.participants);
  }, [draftJob.participants, orgOwnerDetails]);

  const isUserAndOrgOwnerSameAndNotParticipent = () => {
    return (
      orgOwnerDetails.id === currentUserProfile.id &&
      !draftJob.participants.find(user => user.id === currentUserProfile.id)
    );
  };
  const isUserPariticiepentNotOrgOwner = () => {
    return (
      Object.keys(orgOwnerDetails).length > 0 &&
      orgOwnerDetails.id !== currentUserProfile.id &&
      !draftJob.participants.find(user => user.id === orgOwnerDetails.id) &&
      draftJob.participants.find(user => user.id === currentUserProfile.id)
    );
  };
  const isOrgOwnerPariticiepentNotUser = () => {
    return (
      Object.keys(orgOwnerDetails).length > 0 &&
      orgOwnerDetails.id !== currentUserProfile.id &&
      draftJob.participants.find(user => user.id === orgOwnerDetails.id) &&
      !draftJob.participants.find(user => user.id === currentUserProfile.id)
    );
  };
  const isUserAndOrgOwnerNotParticipents = () => {
    return (
      Object.keys(orgOwnerDetails).length > 0 &&
      orgOwnerDetails.id !== currentUserProfile.id &&
      !draftJob.participants.find(user => user.id === orgOwnerDetails.id) &&
      !draftJob.participants.find(user => user.id === currentUserProfile.id)
    );
  };
  const getOrgOwner = () => {
    return {
      displayName: orgOwnerDetails?.displayName,
      email: orgOwnerDetails.email,
      mobile: orgOwnerDetails?.mobile || null,
      id: orgOwnerDetails.id,
      photoURL: orgOwnerDetails?.photoURL,
      registered: true,
      role: { label: "Org Owner", value: "Org Owner" },
      access: access,
      type: orgOwnerDetails.type,
      internal: draftJob.jobType.internal,
      external: draftJob.jobType.external,
      below: draftJob.jobType.belowground,
    };
  };
  const getCurrentProfile = () => {
    return {
      displayName: currentUserProfile?.displayName,
      email: currentUserProfile.email,
      mobile: currentUserProfile?.mobile || null,
      id: currentUserProfile.id,
      photoURL: currentUserProfile?.photoURL,
      type: currentUserProfile.type,
      registered: true,
      role: role,
      access: access,
      internal: draftJob.jobType.internal,
      external: draftJob.jobType.external,
      below: draftJob.jobType.belowground,
    };
  };

  useEffect(() => {
    setHasBelow(draftJob.jobType.belowground);
    setHasExternal(draftJob.jobType.external);
    setHasInternal(draftJob.jobType.internal);
  }, [draftJob.jobType]);

  useEffect(async () => {
    const docRef = fetchDraftRefFromDb(draftJob.id, collectionName);
    docRef.onSnapshot(doc => {
      if (!doc.exists) return;
      setAreasWithChecklist(doc.data()?.areas);
    });
  }, []);

  useEffect(() => {
    if (draftJob.jobType.internal) {
      toggle("1");
    } else if (draftJob.jobType.external) {
      toggle("2");
    } else if (draftJob.jobType.belowground) {
      toggle("3");
    }
  }, [draftJob.jobType]);

  const toggle = tab => {
    if (categoryTab !== tab) {
      setCategoryTab(tab);
    }
  };

  useEffect(() => {
    if (
      draftJob &&
      // draftJob?.client?.displayName &&
      draftJob?.jobType?.internal &&
      draftJob?.jobCategory?.internal !== "none" &&
      draftJob?.buildingClass?.internal?.length > 0 &&
      // draftJob?.participants?.length > 0 &&
      draftJob?.rooms?.internal?.length > 0 &&
      hasCheckList(draftJob?.rooms?.internal) &&
      draftJob?.documents?.length > 0
    ) {
      setCanCreateInternal(true);
    } else {
      setCanCreateInternal(false);
    }
  }, [draftJob, draftJob.rooms, draftJob.checklist]);

  useEffect(() => {
    if (
      draftJob &&
      // draftJob?.client?.displayName &&
      draftJob?.jobType?.external &&
      draftJob?.jobCategory?.external !== "none" &&
      draftJob?.buildingClass?.external?.length > 0 &&
      // draftJob?.participants?.length > 0 &&
      draftJob?.rooms?.external?.length > 0 &&
      hasCheckList(draftJob?.rooms?.external) &&
      draftJob?.documents?.length > 0
    ) {
      setCanCreateExternal(true);
    } else {
      setCanCreateExternal(false);
    }
  }, [draftJob]);

  useEffect(() => {
    if (
      draftJob &&
      // draftJob?.client?.displayName &&
      draftJob?.jobType?.belowground &&
      draftJob?.jobCategory?.belowground !== "none" &&
      draftJob?.buildingClass?.belowground?.length > 0 &&
      // draftJob?.participants?.length > 0 &&
      draftJob?.rooms?.belowground?.length > 0 &&
      hasCheckList(draftJob?.rooms?.belowground) &&
      draftJob?.documents?.length > 0
    ) {
      setCanCreateBelow(true);
    } else {
      setCanCreateBelow(false);
    }
  }, [draftJob]);
  useEffect(() => {
    if (draftJob && draftJob?.jobType.internal && !canCreateInternal) {
      setCanCreate(false);
      return;
    }
    if (draftJob && draftJob?.jobType.belowground && !canCreateBelow) {
      setCanCreate(false);
      return;
    }
    if (draftJob && draftJob?.jobType.external && !canCreateExternal) {
      setCanCreate(false);
      return;
    }
    setCanCreate(true);
  }, [canCreateBelow, canCreateExternal, canCreateInternal, draftJob]);

  const onCreate = async () => {
    setLoading(true);
    let internalData = {};
    let externalData = {};
    let belowData = {};
    const orgId = currentUserProfile.orgAccessId;
    const snapshot = await getOrgFromDb(orgId);
    const orgInfo = snapshot.data();

    let newJobDb = {
      // jobNo: nextJobNumber,
      jobAddress: draftJob.jobAddress,
      client: draftJob.client,
      jobType: draftJob.jobType,
      documents: draftJob.documents,
      orgId: orgId,
      orgIdentityCode: orgInfo?.orgIdentityCode ?? "",
      areas: areasWithChecklist,
      participants: installers,
      participantIds: installers.map(user => user.id),
      accessIds: installers.map(user => user.id),
      jobName: draftJob.jobName,
      customjobno: draftJob.customjobno,
      postCode:draftJob.postCode
    };

    newJobDb.participants = installers.map(user =>
      user.invited || user.isNewlyAdded ? { ...user, isEmailSent: true } : user
    );

    if (draftJob.jobType.internal) {
      internalData = {
        jobCategory: draftJob.jobCategory.internal,
        buildingClass: draftJob.buildingClass.internal,
        rooms: draftJob.rooms.internal,
      };
      newJobDb.internal = internalData;
    }
    if (draftJob.jobType.external) {
      externalData = {
        jobCategory: draftJob.jobCategory.external,
        buildingClass: draftJob.buildingClass.external,
        rooms: draftJob.rooms.external,
      };
      newJobDb.external = externalData;
    }
    if (draftJob.jobType.belowground) {
      belowData = {
        jobCategory: draftJob.jobCategory.belowground,
        buildingClass: draftJob.buildingClass.belowground,
        rooms: draftJob.rooms.belowground,
      };
      newJobDb.belowground = belowData;
    }
    try {
      if (collectionName === INSPECTION_DB_NAME) {
        const savedJob = await addInspectionJobToDb(newJobDb, draftJob.id);
        handleJobCreation(newJobDb, savedJob);
        localStorage.removeItem("checkedJobs");
      } else {
        const savedJob = await addJobToDb(newJobDb);
        await moveChecklistsSubCollection(
          CHEKCLIST_COLLECTION,
          DRAFTS_DB_NAME,
          ACTIVE_JOBS_DB_NAME,
          draftJob.id,
          savedJob.id
        );
        await deleteDraftJobInDb(draftJob.id, DRAFTS_DB_NAME);
        handleJobCreation(newJobDb, savedJob);
        localStorage.removeItem("checkedJobs");
      }
    } catch (err) {
      console.log("Error on job create:", err);
    }
  };
  const handleJobCreation = async (newJobDb, savedJob) => {
    const { client, jobNo, jobAddress } = savedJob;
    const orgId = currentUserProfile.orgAccessId;
    const snapshot = await getOrgFromDb(orgId);
    const orgInfo = snapshot.data();
    const { name: orgName, orgIdentityCode } = orgInfo;
    if (savedJob?.status && savedJob?.status === "jobs") {
      setNewJobId(`/inspectionactive/${savedJob.id}`);
    } else {
      setNewJobId(`/active/${savedJob.id}`);
    }
    const newJob = { ...newJobDb, id: savedJob.id, orgIdentityCode };
    dispatch(createJob({ ...newJobDb, id: savedJob.id, orgIdentityCode }));
    setNewJobNo(jobNo);
    setLoading(false);
    setFinal(true);
    updateParticipantsInDb(
      newJob,
      currentUserProfile,
      savedJob,
      orgName,
      collectionName
    );
  };

  const hasCheckList = data => {
    data?.forEach(item =>
      item?.subItems?.forEach(area => {
        if (!areasWithChecklist[area.id]?.isChecklistExists) {
          return false;
        }
      })
    );
    return true;
  };
  const Header = () => {
    return (
      <>
        <Nav tabs>
          {hasInternal && (
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: categoryTab === "1",
                })}
                onClick={() => {
                  toggle("1");
                }}
              >
                {RESIDENTIAL_CATEGORY}
                <span>
                  <img
                    src={canCreateInternal ? drippyGreen : drippyRed}
                    className="status"
                  />
                </span>
              </NavLink>
            </NavItem>
          )}
          {hasExternal && (
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: categoryTab === "2",
                })}
                onClick={() => {
                  toggle("2");
                }}
              >
                {COMMERCIAL_CATEGORY}
                <span>
                  <img
                    src={canCreateExternal ? drippyGreen : drippyRed}
                    className="status"
                  />
                </span>
              </NavLink>
            </NavItem>
          )}
          {hasBelow && (
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: categoryTab === "3",
                })}
                onClick={() => {
                  toggle("3");
                }}
              >
                {INFRASTRUCTURE_CATEGORY}
                <span>
                  <img
                    src={canCreateBelow ? drippyGreen : drippyRed}
                    className="status"
                  />
                </span>
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </>
    );
  };

  const InternalStepsStatus = () => {
    return (
      <TabPane tabId="1">
        {" "}
        <Row>
          <Col md="6" xl="6" lg="6">
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.client?.displayName ? drippyGreen : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Client Details</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.jobCategory.internal !== "none"
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Job Category</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob.buildingClass.internal.reduce((acc, obj) => {
                        return acc || obj.checked;
                      }, false)
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Building Class</h5>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.participants.length > 0
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Participants</h5>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.rooms?.internal?.length > 0
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>{"Floor & Area(s)"}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      hasCheckList(draftJob?.rooms?.internal)
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Checklist</h5>
              </Col>
            </Row>
          </Col>
        </Row>
      </TabPane>
    );
  };

  const ExternalStepsStatus = () => {
    return (
      <TabPane tabId="2">
        {" "}
        <Row>
          <Col md="6" xl="6" lg="6">
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.client?.displayName ? drippyGreen : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Client Details</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.jobCategory.external !== "none"
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Job Category</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.buildingClass?.external.reduce((acc, obj) => {
                        return acc || obj.checked;
                      }, false)
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Building Class</h5>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.participants?.length > 0
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Participants</h5>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.rooms?.external?.length > 0
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>{"Floor & Area(s)"}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      hasCheckList(draftJob?.rooms?.external)
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Checklist</h5>
              </Col>
            </Row>
          </Col>
        </Row>
      </TabPane>
    );
  };

  const BelowGroundStepsStatus = () => {
    return (
      <TabPane tabId="3">
        {" "}
        <Row>
          <Col md="6" xl="6" lg="6">
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.client?.displayName ? drippyGreen : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Client Details</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.jobCategory.belowground !== "none"
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Job Category</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob.buildingClass.belowground.reduce((acc, obj) => {
                        return acc || obj.checked;
                      }, false)
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Building Class</h5>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.participants.length > 0
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Participants</h5>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      draftJob?.rooms?.belowground?.length > 0
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>{"Floor & Area(s)"}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  <img
                    src={
                      hasCheckList(draftJob?.rooms?.belowground)
                        ? drippyGreen
                        : drippyRed
                    }
                    className="status"
                  />
                </span>
                <h5>Checklist</h5>
              </Col>
            </Row>
          </Col>
        </Row>
      </TabPane>
    );
  };

  const JobCreationStepsStatus = () => {
    return (
      <>
        <Header />
        {!finalised && (
          <>
            <TabContent activeTab={categoryTab} className="p-3 text-muted">
              <InternalStepsStatus />
              <ExternalStepsStatus />
              <BelowGroundStepsStatus />
            </TabContent>
          </>
        )}
      </>
    );
  };

  const JobCreationSuccessMessage = () => {
    return (
      <SweetAlert
        success
        title={"Job Created Successfully"}
        timeout={4000}
        showConfirm={false}
        onConfirm={() => {
          history.push({ pathname: newJobId, state: "newJob" });
        }}
      >
        Job No: {newJobNo}
      </SweetAlert>
    );
  };

  const JobCreationProgress = () => {
    return (
      <SweetAlert
        title={"Job Creation is in Progress..."}
        timeout={5000}
        showConfirm={false}
        onConfirm={() => {}}
      >
        <ProgressBar />
      </SweetAlert>
    );
  };

  return (
    <>
      <JobLeavingGuard
        when={!finalised && activeTab === 7}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return !finalised && activeTab === 7;
        }}
      />
      <Card>
        <CardBody style={{ margin: "20px" }}>
          <JobCreationStepsStatus />
        </CardBody>
      </Card>

      {finalised && <JobCreationSuccessMessage />}
      {loading && <JobCreationProgress />}

      <Row className="clearfix" style={{ marginBottom: "20px" }}>
        <div className="text-end">
          <button
            className="btn btn-primary w-md mtb-2"
            onClick={() => prev()}
            type="button"
          >
            Previous
          </button>
          <button
            disabled={!canCreate || loading}
            className="btn btn-success w-md ml-20 mtb-2"
            type="button"
            onClick={() => onCreate()}
          >
            Create
          </button>
        </div>
      </Row>
    </>
  );
}
export const getUserInfo = async email => {
  const snapshot = await getUser(email);
  if (!snapshot.empty) {
    const docs = snapshot.docs.map(doc => dataFromSnapshot(doc));
    return docs[0];
  }
  return null;
};
async function updateParticipantsInDb(
  newJob,
  currentUserProfile,
  savedJob,
  orgName,
  collectionName
) {
  const updatedParticipants = [];
  const promises = [];
  const notificationDocs = [];
  for (const participant of newJob.participants || []) {
    if (participant.id === currentUserProfile.id) {
      updatedParticipants.push(participant);

      continue;
    } else {
      const invitationType = participant.invited ? "invited" : "added";
      const userInfo = await getUserInfo(participant.email);
      const password = userInfo
        ? null
        : generator.generate({
            length: 10,
            numbers: true,
          });
      const userId =
        userInfo?.id || (await createNewUser(participant, password));
      const updatedParticipant = { ...participant, id: userId };
      updatedParticipants.push(updatedParticipant);

      promises.push(
        sendEmailToInvitedUserOfJob(
          orgName,
          {
            email: participant.email,
            role: participant.role.label,
            password,
          },
          {
            displayName: currentUserProfile.displayName,
            email: currentUserProfile.email,
          },
          {
            jobNo: savedJob?.jobNo,
            email: savedJob?.client.email,
            jobAddress: savedJob?.jobAddress,
            phone: savedJob?.client.mobile,
            inviteType: invitationType,
          }
        )
      );
      const notification = {
        id: cuid(),
        jobId: savedJob.id,
        title: savedJob?.jobNo,
        isRead: false,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        type: "admin",
        description: `You have been ${invitationType} to Job ${savedJob?.jobNo} belonging to ${orgName} as a ${participant.role.label}.`,
        recipientId: userId,
        actionType: "added-to-job",
      };
      if (collectionName === INSPECTION_DB_NAME) {
        notification.isInspectionJob = true;
      }
      notificationDocs.push(notification);
    }
  }
  await updateParticipants(savedJob.id, updatedParticipants, collectionName);
  await addAdminNotificationsToDb(notificationDocs);
  await Promise.all(promises);
}
