import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { AVATAR_URL, ORG_ERROR_MESSAGE } from "common/constants";
import AdressAutoComplete from "components/AddressAutoComplete/AddressAutoComplete";
import MuiPhoneNumber from "mui-phone-number";
import React, { useEffect, useState } from "react";
import Editable from "react-bootstrap-editable";
import MetaTags from "react-meta-tags";
// Redux
import { useDispatch, useSelector } from "react-redux";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import {
  fetchAllOrgFromDb,
  getOrgFromDb,
  updateOrgDetailsInDB,
} from "./../../services/index";
import "./styles.css";
import UploadAvatarMoadl from "./UploadAvatar";
import { organisationorgIdentityCodeUpdateCheck } from "pages/platformManagement/org-creation/organisationHandler";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { fetchOrgs, fetchOrgsError, fetchOrgsSuccess } from "store/actions";
import { orgIdentityCodeUpdationForAllUsers } from "pages/platformManagement/org-creation/orgService";

export default function UserProfile() {
  const { currentUserProfile } = useSelector(state => state.profile);
  const { orgs } = useSelector(state => state.org);
  const [companyProfile, setCompanyProfile] = useState({});
  const dispatch = useDispatch();
  const [editAvatar, setEditAvatar] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editAdreess, setEditAddress] = useState(false);
  const [phone, setPhone] = useState(companyProfile?.phone || "");
  const [address, setAddress] = useState("");
  const [nameError, setNameError] = useState(false);
  const [alwaysEditingCompanyName, setAlwaysEditingCompanyName] =
    useState(false);
  useFirestoreCollection({
    query: () => fetchAllOrgFromDb(),
    onStart: () => dispatch(fetchOrgs()),
    onSuccess: docs => dispatch(fetchOrgsSuccess(docs)),
    onError: error => dispatch(fetchOrgsError(error)),
    deps: [dispatch],
  });

  useEffect(() => {
    const fetchProfile = async orgAccessId => {
      const profile = await getOrgFromDb(orgAccessId);
      const comapnyData = profile.data();
      setCompanyProfile(comapnyData);
      setAddress(comapnyData?.address);
      setPhone(comapnyData?.phone);
    };
    if (currentUserProfile.orgAccessId) {
      fetchProfile(currentUserProfile.orgAccessId);
    }
  }, [currentUserProfile]);

  const updateCompanyProfile = async profile => {
    if (currentUserProfile.orgAccessId) {
      const checkForUniqness =
        companyProfile.name.toLowerCase() !== profile.name.toLowerCase();
      const updatedResult = await updateOrgDetailsInDB(
        profile,
        checkForUniqness,
        currentUserProfile.orgAccessId
      );
      if (updatedResult === ORG_ERROR_MESSAGE) {
        setNameError(true);
        setAlwaysEditingCompanyName(true);
      } else {
        setCompanyProfile(profile);
        setAlwaysEditingCompanyName(false);
      }
    }
  };
  async function updateOrgIdentityInfo(companyProfile, value) {
    updateCompanyProfile({
      ...companyProfile,
      orgIdentityCode: value || "",
    });
    await orgIdentityCodeUpdationForAllUsers(
      currentUserProfile?.orgAccessId,
      value
    );
  }
  const [error, setError] = useState(false);
  const handlePhoneNumberChange = (value, dialCode) => {
    if (
      value?.replace(`+${dialCode}`, "")?.length <= 10 &&
      value?.replace(`+${dialCode}`, "")?.length > 3
    ) {
      setError(false);
    } else {
      setError(true);
    }
    setPhone(value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Company Profile | Industry Best</title>
        </MetaTags>
        {/* Render Breadcrumb */}
        <Breadcrumb title="Industry Best" breadcrumbItem="Company Profile" />
        <UserHeader profile={companyProfile} />
        <UploadAvatarMoadl
          editAvatar={editAvatar}
          setEditAvatar={setEditAvatar}
          companyProfile={companyProfile}
          updateCompanyProfile={updateCompanyProfile}
        />
        <Container
          className="mt--7"
          fluid
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            marginTop: "-6rem",
          }}
        >
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          setEditAvatar(true);
                        }}
                      >
                        <img
                          alt="..."
                          className="rounded-circle"
                          style={{
                            maxWidth: 180,
                            borderRadius: ".375rem",
                            transform: "translate(-50%,-30%)",
                            position: "absolute",
                            left: "50%",
                            transition: "all .15s ease",
                          }}
                          src={
                            companyProfile?.photoURL ||
                            `${AVATAR_URL}&name=${companyProfile?.name}`
                          }
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader
                  className="text-center pt-8 pt-md-4 pb-0 pb-md-4"
                  style={{
                    borderRadius: "calc(.375rem - 1px) calc(.375rem - 1px) 0 0",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Button
                      className="mr-4"
                      color="link"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setEditAvatar(true);
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <div className="text-center margin-top-avatar">
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      {companyProfile?.name}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="shadow ">
                <CardHeader
                  className="bg-white"
                  style={{
                    borderRadius: "calc(.375rem - 1px) calc(.375rem - 1px) 0 0",
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Company Details</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-display-name"
                          >
                            Name
                          </label>
                          <Editable
                            ajax={null}
                            alwaysEditing={alwaysEditingCompanyName}
                            className={null}
                            disabled={false}
                            editText="Edit"
                            id={null}
                            initialValue={companyProfile?.name}
                            isValueClickable={!!companyProfile?.name}
                            mode="inline"
                            onSubmit={value => {
                              if (value) {
                                setNameError(false);
                                updateCompanyProfile({
                                  ...companyProfile,
                                  name: value,
                                });
                              }
                            }}
                            onValidated={null}
                            options={null}
                            renderCancelElement={null}
                            renderConfirmElement={null}
                            showText
                            type="textfield"
                            validate={null}
                          />
                          {nameError && (
                            <small className="requiredError">
                              {ORG_ERROR_MESSAGE}
                            </small>
                          )}
                        </Col>
                        <Col lg="6" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-display-name"
                          >
                            Company/Organisation Unique Code
                          </label>
                          <div>{currentUserProfile.orgAccessId}</div>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="8" lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-mobile"
                          >
                            Phone
                          </label>
                          {editPhone ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "280px",
                              }}
                            >
                              <MuiPhoneNumber
                                name="phone"
                                label="Phone"
                                defaultCountry={"au"}
                                variant="outlined"
                                className="phoneNumber"
                                value={companyProfile?.phone || ""}
                                size="small"
                                fullWidth
                                onChange={(text, country) =>
                                  handlePhoneNumberChange(
                                    text,
                                    country?.dialCode
                                  )
                                }
                                autoFocus
                                autoFormat={false}
                                error={error}
                              />

                              <CheckIcon
                                className="saveOrCancelButton"
                                style={{
                                  backgroundColor: "#34c38f",
                                }}
                                onClick={() => {
                                  if (error === false) {
                                    updateCompanyProfile({
                                      ...companyProfile,
                                      phone: phone === "+" ? "" : phone,
                                    });
                                    setEditPhone(false);
                                  }
                                }}
                              />

                              <CloseIcon
                                className="saveOrCancelButton"
                                style={{
                                  backgroundColor: "#f46a6a",
                                }}
                                onClick={() => {
                                  setPhone("");
                                  setEditPhone(false);
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <label
                                style={{
                                  color: "#50a5f1",
                                }}
                                onClick={() => setEditPhone(!editPhone)}
                              >
                                {companyProfile?.phone || "Edit"}
                              </label>
                            </div>
                          )}
                          {!phone || phone === "+" || phone?.length < 4 ? (
                            <small className="requiredError">
                              {"*Required"}
                            </small>
                          ) : (
                            error && (
                              <small className="requiredError">
                                {"*Invalid Phone number"}
                              </small>
                            )
                          )}
                        </Col>
                        <Col md="8" lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-user-abn"
                          >
                            Company/Organisation Identification Code
                          </label>
                          {companyProfile?.orgIdentityCode ? (
                            <div>{companyProfile?.orgIdentityCode}</div>
                          ) : (
                            <Editable
                              ajax={null}
                              alwaysEditing={false}
                              className={null}
                              disabled={false}
                              editText="Edit"
                              id={null}
                              initialValue={companyProfile?.orgIdentityCode}
                              isValueClickable={
                                !!companyProfile?.orgIdentityCode
                              }
                              mode="inline"
                              onSubmit={value => {
                                if (value && value?.length === 5) {
                                  updateOrgIdentityInfo(companyProfile, value);

                                  console.log("value: ", value);
                                }
                              }}
                              onValidated={() => {}}
                              options={null}
                              renderCancelElement={null}
                              renderConfirmElement={null}
                              showText
                              type="textfield"
                              validate={value => {
                                if (value?.length > 5) {
                                  return "This code cannot be longer than 5 letters";
                                }
                                const isorgIdentityCodeUpdated =
                                  organisationorgIdentityCodeUpdateCheck(
                                    orgs,
                                    companyProfile?.id,
                                    value
                                  );
                                if (!isorgIdentityCodeUpdated) {
                                  return "The organisation code is already taken. Please choose a different code.";
                                }
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="pl-lg-4 mt-3">
                      <FormGroup>
                        <label>Address</label>
                        {editAdreess ? (
                          <AdressAutoComplete
                            address={address}
                            setAddress={setAddress}
                            initialAddressValue={companyProfile?.address}
                            setEditAddress={setEditAddress}
                            onSaveClickHandler={async () => {
                              await updateCompanyProfile({
                                ...companyProfile,
                                address,
                              });
                              setEditAddress(false);
                            }}
                          />
                        ) : (
                          <div>
                            <label
                              style={{
                                color: "#50a5f1",
                              }}
                              onClick={() => setEditAddress(!editAdreess)}
                            >
                              {companyProfile?.address || "Edit"}
                            </label>
                          </div>
                        )}
                      </FormGroup>
                    </div>
                    <br />
                    <Row>
                      <Col lg="6" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-user-abn"
                        >
                          Business/Company Number
                        </label>
                        <Editable
                          ajax={null}
                          alwaysEditing={false}
                          className={null}
                          disabled={false}
                          editText="Edit"
                          id={null}
                          initialValue={companyProfile?.abn}
                          isValueClickable={!!companyProfile?.abn}
                          mode="inline"
                          onSubmit={value => {
                            if (value && value.length === 11) {
                              updateCompanyProfile({
                                ...companyProfile,
                                abn: value || "",
                              });
                            }
                          }}
                          onValidated={null}
                          options={null}
                          renderCancelElement={null}
                          renderConfirmElement={null}
                          showText
                          type="textfield"
                          validate={value => {
                            if (value.length !== 11) {
                              return "Please enter your 11 digit Business/Company Number";
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

const UserHeader = ({ profile }) => {
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "400px",
          backgroundImage: `url(${profile?.photoURL})`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
          position: "relative",
        }}
      >
        {/* Mask */}
        <span
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            transition: "all .15s ease",
            opacity: "0.9",
            background: "linear-gradient(87deg,#172b4d,#1a174d)",
          }}
        />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h3 className="display-4 text-white">{profile?.name}</h3>
              <p className="text-white mt-0 mb-5"></p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
