import axios from "axios";
import { CLOUD_FUNCTION_URL } from "common/constants";
import { deleteJob } from "services";

export const onDeleteJob = async (job,collectionName) => {
  try {
    await axios.post(`${CLOUD_FUNCTION_URL}/deleteJobFiles`, {
      jobNo: job.jobNo,
      orgId: job.orgId,
    });
    await deleteJob(collectionName,job.id);
  } catch (error) {
    console.log("Job deletion error: ", error);
  }
};
