import React, { useEffect } from "react";
import classNames from "classnames";

import { Handle, Remove } from "components/dnd";

import "./item.css";

export const Item = React.memo(
  React.forwardRef(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
        })
      ) : (
        <li
          className={classNames(
            "Wrapper",
            fadeIn && "fadeIn",
            dragOverlay && "dragWrapperOverlay"
          )}
          style={{
            ...wrapperStyle,
            transition,
            "--translate-x": transform
              ? `${Math.round(transform.x)}px`
              : undefined,
            "--translate-y": transform
              ? `${Math.round(transform.y)}px`
              : undefined,
            "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
            "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
            "--index": index,
            "--color": color,
          }}
          ref={ref}
        >
          <div
            className={classNames(
              "Item",
              dragging && "dragging",
              handle && "withHandle",
              dragOverlay && "dragOverlay",
              disabled && "disabled",
              color && "color"
            )}
            style={style}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            {value}
            <span className={"Actions"}>
              <p />

              {onRemove ? (
                <Remove className={"Remove"} onClick={onRemove} />
              ) : null}
              {handle ? <Handle {...listeners} /> : null}
            </span>
          </div>
        </li>
      );
    }
  )
);
