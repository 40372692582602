import React from "react";
import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Form,
  Container,
} from "reactstrap";

import JobPhotoUploader from "pages/userProfile/JobPhotoUploader";
import { AVATAR_URL } from "common/constants";

export default function ParticipantDetail({ isOpen, setIsOpen, obj }) {
  return (
    <Modal isOpen={isOpen} size="lg">
      <div className="modal-header">
        <h3 className="modal-title mt-0" id="myModalLabel">
          Participants Detail
        </h3>
        <button
          type="button"
          onClick={() => setIsOpen(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-4">
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Form>
                    <Row className="d-flex mt-2 align-items-center">
                      <Col md={2}>
                        <img
                          src={
                            obj?.photoURL ||
                            `${AVATAR_URL}&name=${obj?.displayName}`
                          }
                          className="rounded-circle avatar-md"
                          alt=""
                        />
                      </Col>
                      <Col md={10}>
                        <h4 className="mb-0">{obj?.displayName}</h4>
                        <p className="text-muted">{obj?.email}</p>
                      </Col>
                    </Row>
                    <Row className="d-flex mt-4">
                      <Col md={6}>
                        <div className="mb-3">
                          <strong>Mobile</strong>: {obj?.mobile ? obj?.mobile : "NA"}
                        </div>
                        <div className="mb-3">
                          <strong>Role</strong>:{" "}
                          <p className="badge bg-primary bg-soft text-primary font-size-11 me-1">
                            {obj.role?.label}
                          </p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <strong>Access</strong>:{" "}
                          <p className="badge bg-primary bg-soft text-primary font-size-11 me-1">
                            {obj?.access?.label}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Modal>
  );
}