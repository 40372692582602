import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Alert, Input } from "reactstrap";
import { addNotesToDb } from "services/notesService";
import { NotesPermission } from "./NotesPermission";

export const NotesConfirmationPrompt = ({ notesInfo, setIsNotesUpdated }) => {
  const { notes } = useSelector(state => state.notes);
  const [notesName, setNotesName] = useState("");
  const [notesNameError, setNotesNameError] = useState(false);
  const { currentUserProfile } = useSelector(state => state.profile);
  const [isNotesNameExists, setIsNotesNameExists] = useState(false);
  const [notesPermission, setNotesPermission] = useState(false);
  const isNotesNameAlreadyExists = notesName => {
    return notes?.find(item => {
      return item?.notesName?.toLowerCase() === notesName?.toLowerCase();
    });
  };
  const onDeleteConfirm = async () => {
    if (notesName.trim()?.length === 0) {
      setNotesNameError(true);
      return;
    }
    const isNotesExist = isNotesNameAlreadyExists(notesName);
    if (isNotesExist !== undefined) {
      setIsNotesNameExists(true);
      return;
    }
    setNotesNameError(false);
    setIsNotesUpdated(false);

    try {
      await addNotesToDb(
        currentUserProfile?.id,
        notesName[0].toUpperCase() + notesName.slice(1).toLowerCase(),
        notesInfo
      );
      toast.success("Notes added to db successfully!!", {
        autoClose: 2000,
      });
    } catch (err) {
      toast.error("Failed to addthe notes", {
        autoClose: 2000,
      });
    }
  };
  return (
    <>
      {notesPermission ? (
        <NotesPermission
          setNotesPermission={setNotesPermission}
          label={
            <p>
              {" "}
              If you want to enable the popup, please go to{" "}
              <b style={{fontWeight:"bold"}}>Notes Library</b>
            </p>
          }
        />
      ) : (
        <SweetAlert
          title="Do you want to save this to Saved notes library?"
          warning
          showConfirm
          showCancel
          showCloseButton
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="No"
          customButtons={
            <>
              <button
                onClick={onDeleteConfirm}
                style={{
                  margin: "0 10px",
                  padding: "10px 20px",
                  backgroundColor: "green",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              >
                Yes
              </button>

              <button
                onClick={() => {
                  setIsNotesUpdated(false);
                }}
                style={{
                  margin: "0 10px",
                  padding: "10px 20px",
                  backgroundColor: "red",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              >
                No
              </button>
              <button
                onClick={() => {
                  setNotesPermission(true);
                }}
                style={{
                  margin: "0 10px",
                  padding: "10px 20px",
                  backgroundColor: "#50a5f1",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              >
                {currentUserProfile?.disableNotes
                  ? "Enable Popup"
                  : "Disable Popup"}
              </button>
            </>
          }
        >
          <Input
            name="clientName"
            placeholder="Enter the Notes name"
            value={notesName || ""}
            onChange={e => {
              setNotesName(e.target.value);
              setNotesNameError(false);
              setIsNotesNameExists(false);
            }}
            style={{ margin: "5px" }}
          />
          {notesNameError && (
            <Alert
              severity="error"
              style={{ padding: "0px 5px", margin: "5px" }}
            >
              Please enter the Notes Name...
            </Alert>
          )}
          {isNotesNameExists && (
            <Alert
              severity="error"
              style={{ padding: "0px 5px", margin: "5px" }}
            >
              Notes Name ALready Exists, Try another name...
            </Alert>
          )}
        </SweetAlert>
      )}
    </>
  );
};

export default NotesConfirmationPrompt;
