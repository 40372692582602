// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-verification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  width: max-content;
  font-family: sans-serif;
  font-size: medium; }

.email-verification h1, .email-verification p {
  text-align: center;
  font-family: sans-serif; }

#logo {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -70%);
  display: flex;
  align-items: center;
  width: max-content; }

.warning {
  display: flex;
  align-items: baseline; }
`, "",{"version":3,"sources":["webpack://./src/pages/authentication/EmailVerification.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,2CAA2C;EAC3C,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,uBAAuB;EACvB,iBAAiB,EAAA;;AAEnB;EACE,kBAAkB;EAClB,uBAAuB,EAAA;;AAGzB;EACE,eAAe;EACb,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,mBAAmB;EACnB,kBAAkB,EAAA;;AAEtB;EACE,aAAa;EACb,qBAAqB,EAAA","sourcesContent":[".email-verification {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  border-radius: 10px;\n  padding: 20px;\n  background-color: #fff;\n  width: max-content;\n  font-family: sans-serif;\n  font-size: medium;\n}\n.email-verification h1 , .email-verification p {\n  text-align: center;\n  font-family: sans-serif;\n  \n}\n#logo{\n  position: fixed;\n    top: 30%;\n    left: 50%;\n    transform: translate(-50%, -70%);\n    display: flex;\n    align-items: center;\n    width: max-content;\n}\n.warning{\n  display: flex;\n  align-items: baseline;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
