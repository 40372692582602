import { PENDING } from "common/constants";
import React from "react";
import { Card, CardBody, Col, Media } from "reactstrap";

export default function CardSubscription({ currentUserProfile }) {
  return (
    <React.Fragment>
      <Col xl="3" md="6">
        <Card className="plan-box">
          <CardBody className="p-4">
            <Media>
              <Media body>
                <h5>
                  {"You have been successfully added to the Organisation"}
                </h5>

                {currentUserProfile.orgStatus === PENDING && (
                  <p>
                    Please wait for organisation owner to approve your request
                  </p>
                )}
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}
