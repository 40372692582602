import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  APPROVED,
  MOBILE_USER,
  ORGANISATION_OWNER,
  PENDING,
  PLATFORM_OWNER,
  TYPE_ASSIGNMENT_SUBSCRIPTION_PLAN_LIMIT_EXCEEDED,
  WEB_USER,
} from "common/constants";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Toast,
} from "reactstrap";
import {
  updateUserInDb,
  getOrgFromDb,
  addAdminNotificationsToDb,
  revokeActiveJobAccess,
  revokeArchivedJobAccess,
  revokeDeletedJobAccess,
  orgAccessEmailNotificationToUser,
  updateActiveJobAccess,
  updateArchivedJobAccess,
  updateDeletedJobAccess,
} from "services";
import "../../common/styles.css";
import firebase from "config/firebase";
import cuid from "cuid";
import {
  revokeInspectionJobAccess,
  updateInspectionJobAccess,
} from "services/inspectionService";
import { toast } from "react-toastify";
export default function EditUser({
  showEditUser,
  setShowEditUser,
  user,
  setUser,
  subscription,
}) {
  const [isValidForm, setIsValidForm] = useState(true);
  const { orgUsers } = useSelector(state => state.orgUsers);
  const [activeWebUsersCount, setActiveWebUsersCount] = useState(0);
  const [activeMobileUsersCount, setActiveMobileUsersCount] = useState(0);
  const [firstName, setFirstName] = useState(user?.firstName?.trim() || "");
  const [lastName, setLastName] = useState(user?.lastName?.trim() || "");
  const [error, setError] = useState(null);
  const { currentUserProfile } = useSelector(state => state.profile);

  useEffect(() => {
    const webUsersCount = orgUsers.filter(
      orgUser =>
        (orgUser.type === WEB_USER && orgUser.orgStatus === APPROVED) ||
        orgUser.type === ORGANISATION_OWNER
    )?.length;
    setActiveWebUsersCount(webUsersCount);

    const mobileUsersCount = orgUsers.filter(
      orgUser => orgUser.type === MOBILE_USER && orgUser.orgStatus === APPROVED
    )?.length;
    setActiveMobileUsersCount(mobileUsersCount);
  }, [orgUsers]);

  const isWebUsersLimitExceeded = () => {
    return (
      user.type === WEB_USER &&
      user.orgStatus === APPROVED &&
      subscription.webUsersCount < activeWebUsersCount + 1
    );
  };

  const isMobileUsersLimitExceeded = () => {
    return (
      user.type === MOBILE_USER &&
      user.orgStatus === APPROVED &&
      subscription.mobileUsersCount < activeMobileUsersCount + 1
    );
  };

  const onSave = async () => {
    if (isWebUsersLimitExceeded() || isMobileUsersLimitExceeded()) {
      return setError(TYPE_ASSIGNMENT_SUBSCRIPTION_PLAN_LIMIT_EXCEEDED);
    }
    await updateUserInDb({
      ...user,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      type: user?.orgStatus === PENDING ? null : user.type,
    });

    const promises = [];
    if (user.orgStatus === APPROVED) {
      updateActiveJobAccess(user.id, currentUserProfile.orgAccessId);
      updateArchivedJobAccess(user.id, currentUserProfile.orgAccessId);
      updateDeletedJobAccess(user.id, currentUserProfile.orgAccessId);
      updateInspectionJobAccess(user.id, currentUserProfile.orgAccessId);
      const snapshot = await getOrgFromDb(user.orgAccessId);
      const { name: orgName } = snapshot.data();
      const notification = {
        id: cuid(),
        jobId: null,
        title: orgName,
        isRead: false,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        type: "admin",
        description: `Admin has approved your request to join ${orgName}.`,
        recipientId: user.id,
        actionType: "approved-join-request",
      };
      addAdminNotificationsToDb([notification]);

      promises.push(
        orgAccessEmailNotificationToUser(
          orgName,
          {
            email: user.email,
            displayName: user.displayName || user.firstName + user.lastName,
            type: user.type === WEB_USER ? "Web" : "Mobile",
          },
          {
            email: currentUserProfile?.email,
            displayName:
              currentUserProfile?.displayName ||
              currentUserProfile?.firstName + currentUserProfile?.lastName,
          }
        )
      );
    }
    if (user.orgStatus === PENDING) {
      revokeActiveJobAccess(user.id, currentUserProfile.orgAccessId);
      revokeArchivedJobAccess(user.id, currentUserProfile.orgAccessId);
      revokeDeletedJobAccess(user.id, currentUserProfile.orgAccessId);
      revokeInspectionJobAccess(user.id, currentUserProfile.orgAccessId);
      const snapshot = await getOrgFromDb(user.orgAccessId);

      const { name: orgName } = snapshot.data();

      const notification = {
        id: cuid(),
        jobId: null,
        title: orgName,
        isRead: false,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        type: "admin",
        description: `Admin has revoked organisation access.`,
        recipientId: user.id,
        actionType: "revoke-access",
      };
      addAdminNotificationsToDb([notification]);
      promises.push(
        orgAccessEmailNotificationToUser(
          orgName,
          {
            email: user.email,
            displayName: user.displayName || user.firstName + user.lastName,
            type: "access-revoked",
          },
          {
            email: currentUserProfile?.email,
            displayName:
              currentUserProfile?.displayName ||
              currentUserProfile?.firstName + currentUserProfile?.lastName,
          }
        )
      );
    }
    setShowEditUser(false);
    await Promise.all(promises);
  };

  return (
    <Modal isOpen={showEditUser} toggle={() => setShowEditUser(!showEditUser)}>
      <ModalHeader toggle={() => setShowEditUser(!showEditUser)} tag="h4">
        {"User Details"}
      </ModalHeader>

      <ModalBody>
        <Row form>
          <Col xs={12}>
            <div style={{ display: "flex" }}>
              <div
                className="mb-3"
                style={{ width: "50%", marginRight: "10px" }}
              >
                <Label>First Name</Label>
                <Input
                  data-testid="first-name-input"
                  name="name"
                  type="text"
                  value={user?.firstName?.trim() || firstName || ""}
                  onChange={e => {
                    setFirstName(e.target.value);
                  }}
                  disabled={user?.firstName?.trim()}
                />
              </div>
              <div className="mb-3" style={{ width: "50%" }}>
                <Label>Last Name</Label>
                <Input
                  data-testid="last-name-input"
                  name="name"
                  type="text"
                  value={user?.lastName?.trim() || lastName || ""}
                  onChange={e => {
                    setLastName(e.target.value);
                  }}
                  disabled={user?.lastName?.trim()}
                />
              </div>
            </div>
            <div className="mb-3">
              <Label>Email</Label>
              <Input
                data-testid="email-input"
                name="email"
                type="email"
                value={user?.email || ""}
                disabled={true}
              />
            </div>

            <div className="mb-3">
              <Label>Type</Label>
              <FormControl fullWidth size="small">
                <InputLabel>Type</InputLabel>
                <Select
                  data-testid="type-select"
                  defaultValue={user?.type}
                  value={user.type}
                  label="Type"
                  onChange={event => {
                    if (user.type === PLATFORM_OWNER) {
                      setError("Can't add owner");
                      return;
                    }
                    setError("");
                    setUser({
                      ...user,
                      type: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={WEB_USER}>Web</MenuItem>
                  <MenuItem value={MOBILE_USER}>Mobile</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="mb-3">
              <Label>Onboard Status</Label>
              <FormControl fullWidth size="small">
                <InputLabel>Orboard Status</InputLabel>
                <Select
                  data-testid="onboardStatus-select"
                  defaultValue={user?.onboardStatus}
                  value={user?.orgStatus}
                  label="Onboard Status"
                  onChange={event => {
                    setError("");
                    setUser({
                      ...user,
                      orgStatus: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={APPROVED} data-testid="active-option">
                    Active
                  </MenuItem>
                  <MenuItem value={PENDING} data-testid="Inactive-option">
                    Inactive
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            {error && <Alert severity="error">{error}</Alert>}

            <div className="mb-3"></div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-success save-user"
                disabled={
                  !(
                    (user.type !== PLATFORM_OWNER || user.type === null) &&
                    user.orgStatus &&
                    firstName &&
                    lastName
                  )
                }
                onClick={onSave}
              >
                Save
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
