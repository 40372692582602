import {
  FETCH_CLIENTS,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_ERROR,
} from "./actionTypes";

export function fetchClients() {
  return {
    type: FETCH_CLIENTS,
  };
}

export function fetchClientsSuccess(clients) {
  return {
    type: FETCH_CLIENTS_SUCCESS,
    payload: clients,
  };
}

export function fetchClientsError(error) {
  console.log(error);
  return {
    type: FETCH_CLIENTS_ERROR,
    payload: error,
  };
}
