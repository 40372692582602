export function getStartingJobNumberErrorMessage(startingJobNumber) {
  if (!startingJobNumber) {
    return "*Required";
  }
  if (startingJobNumber < 0) {
    return "Job Number Start Preference not allowed to be less than 0";
  }
  if (startingJobNumber >= 1000000) {
    return "Job Number Start Preference not allowed to be more than 6 digits";
  }
  return null;
}
export function getorgIdentityCodeErrorMessage(orgIdentityCode, orgIdentityCodeError) {
  const code = orgIdentityCode?.trim();
  if (!code) {
    return "*Required";
  }
  if (code?.length>5) {
    return "This code cannot be longer than 5 letters";
  }
  if (orgIdentityCodeError) {
    return "The organisation code is already taken. Please choose a different code.";
  }
  return null;
}


export function getDocumentNumberErrorMessage(documentPreferenceNumber) {
  if (!documentPreferenceNumber) {
    return "*Required";
  }
  if (documentPreferenceNumber < 0) {
    return "Document Number Preference not allowed to be less than 0";
  }
  if (documentPreferenceNumber >= 1000000) {
    return "Document Number Preference not allowed to be more than 6 digits";
  }
  return null;
}
export function getOrganizationNameErrorMessage(name, orgError) {
  if (!name) {
    return "*Required";
  }
  if (orgError) {
    return "Organisation with same name already exists";
  }
  return null;
}

export function getPhoneNumberErrorMessage(phoneNumber, error) {
  const isPhoneNumberInvalid =
    !phoneNumber || phoneNumber === "+" || phoneNumber.length < 4;
  if (isPhoneNumberInvalid) {
    return "*Required";
  }
  if (error) {
    return "*Invalid Mobile number";
  }
  return null;
}
export function phoneNumberHandler(setError, setOrg, organisation) {
  return (value,dialCode) => {
    if (
      value?.replace(`+${dialCode}`, '')?.length <= 10 &&
      value?.replace(`+${dialCode}`, '')?.length > 4
    ) {
      setError(false);
    } else {
      setError(true);
    }
    setOrg({
      ...organisation,
      phone: value,
      countryCode: dialCode
    });
  };
}
