import React from "react";
import { Container, Row, Table } from "reactstrap";
import "../../../src/searchuicomponents/customStyles.scss";
import "../../../src/searchuicomponents/jobsCustomStyles.scss";
import NotFound from "components/NotFound";
import { useSelector } from "react-redux";
import { getChatNotificationOfJob, notFoundMessage } from "services";
import { ListView } from "./ListView";
import { InspectionListView } from "pages/jobs/jobCreation/jobLinkage/InspectionListView";

const ListLayout = ({
  results,
  isLoading,
  setSearchTerm,
  resultSearchTerm,
  pathname,
  setJobUpdate,
  isLinkEnabled,
  collectionName,
  isNewJob
}) => {
  const { jobs } = useSelector(state => state.job);
  const { notifications } = useSelector(state => state.notifications);
  const { currentUserProfile } = useSelector(state => state.profile);

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          {results.length > 0 ? (
            <Table className="table-search-ui project-list-table table-nowrap align-middle table-borderless">
              <thead>
                <tr>
                  <th scope="col" style={{ textIndent: "30px" }}>
                    IB No.
                  </th>
                  <th scope="col">Custom no.</th>
                  <th scope="col">Job Name</th>
                  <th scope="col">Client</th>
                  <th scope="col">Site Address</th>
                  <th scope="col">Category</th>
                  <th scope="col">Participants</th>
                  <th scope="col">Action</th>
                  <th scope="col">Progress</th>
                </tr>
              </thead>
              {results.map(result => {
                const job = jobs.find(job => job.id === result.id.raw);
                const notification = getChatNotificationOfJob(
                  notifications,
                  currentUserProfile?.id,
                  result.id.raw
                );
                if(isNewJob){
                  return(
                    job && <InspectionListView
                    key={result.id.raw}
                    result={result}
                    job={job}
                    setSearchTerm={setSearchTerm}
                    notification={notification}
                    resultSearchTerm={resultSearchTerm}
                    pathname={pathname}
                    setJobUpdate ={setJobUpdate}
                    isLinkEnabled={isLinkEnabled}
                    collectionName={collectionName}
                    />
                  )
                }
                return (
                  job && (
                    <ListView
                    key={result.id.raw}
                    result={result}
                    job={job}
                    setSearchTerm={setSearchTerm}
                    notification={notification}
                    resultSearchTerm={resultSearchTerm}
                    pathname={pathname}
                    setJobUpdate ={setJobUpdate}
                    isLinkEnabled={isLinkEnabled}
                    collectionName={collectionName}
                    />
                  )
                );
              })}
            </Table>
          ) : isLoading ? (
            <div className="w-100">
              <Table className="table-search-ui project-list-table table-nowrap align-middle table-borderless">
                <thead>
                  <tr>
                    <th scope="col" style={{ textIndent: "30px" }}>
                      IB No.
                    </th>
                    <th scope="col">Custom No.</th>
                    <th scope="col">Client</th>
                    <th scope="col">Job Site Address</th>
                    <th scope="col">Type</th>
                    <th scope="col">Participants</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody align="center">
                  <tr>
                    <td>
                      {" "}
                      <div
                        className="spinner-border text-primary"
                        id="spinner"
                        role="status"
                      ></div>
                      <div>Loading...</div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : (
            <NotFound
              message={
                notFoundMessage(pathname)
              }
            />
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ListLayout;