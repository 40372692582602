import React, { useState, useEffect } from "react";
import cuid from "cuid";
import firebase from "../../config/firebase";
import { Row, Col, Card, Progress } from "reactstrap";
// Redux
import { useSelector } from "react-redux";
import pdfLogo from "./../../assets/images/pdflogo.png";
import { Link } from "react-router-dom";
import { updateJobProfileById } from "services";

export default function JobPhotoUploader({
  file,
  jobId,
  goClear,
  collectionName,
}) {
  const storageRef = firebase.storage().ref();
  const { currentUserProfile } = useSelector(state => state.profile);
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    uploadToFirebase(file);
  }, [file]);
  const uploadToFirebase = file => {
    if (!file) return;
    const filePath = `${currentUserProfile.id}/photos/${cuid()}`;
    const uploadTask = storageRef.child(filePath).put(file);
    uploadTask.on(
      "state_changed",
      snap => {
        const percentUploaded = Math.round(
          (snap.bytesTransferred / snap.totalBytes) * 100
        );
        setPercent(percentUploaded);
      },
      err => {
        console.error(err);
        setError(error);
      },
      () => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(async downloadUrl => {
            await updateJobProfileById({
              id: jobId,
              photoURL: downloadUrl,
              collectionName: collectionName,
            });
            goClear();
          })
          .catch(err => {
            console.error(err);
            setError(error);
          });
      }
    );
  };

  const Message = ({ children }) => {
    const [show, setShow] = useState(true);
    useEffect(() => {
      const timeId = setTimeout(() => {
        setPercent(-1);
        setShow(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    }, []);
    if (!show) {
      return null;
    }
    return <>{children}</>;
  };

  const FileInfo = () => (
    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
      <div className="p-2">
        <Row className="align-items-center">
          <Col className="col-auto">
            <img
              data-dz-thumbnail=""
              height="80"
              className="avatar-sm rounded bg-light"
              alt={file?.name}
              src={file.type === "application/pdf" ? pdfLogo : file.preview}
            />
          </Col>
          <Col>
            <Link to="#" className="text-muted font-weight-bold">
              {file?.name}
            </Link>
            <p className="mb-0">
              <strong>{file.formattedSize}</strong>
            </p>
          </Col>
        </Row>
      </div>
      <div className="p-1">
        {percent === 100 ? (
          <Progress
            className="progress-sm"
            striped
            animated
            color="success"
            value={percent}
          />
        ) : (
          <Progress
            className="progress-sm"
            striped
            animated
            color="danger"
            value={percent}
          />
        )}
      </div>
    </Card>
  );

  if (percent === -1) {
    return null;
  } else if (percent === 100) {
    return (
      <Message>
        <FileInfo />
      </Message>
    );
  } else {
    return <FileInfo />;
  }
}
