import cuid from "cuid";

export const documentCategory = [
  {
    id: cuid(),
    content: "Building Plans",
  },
  {
    id: cuid(),
    content: "Amendments",
  },
  {
    id: cuid(),
    content: "Performance Solutions",
  },
  {
    id: cuid(),
    content: "Technical Data Sheet(TDS)",
  },
  {
    id: cuid(),
    content: "Material Safety Data Sheet (MSDS)",
  },
  {
    id: cuid(),
    content: "Safe Work Methods Statements (SWMS)",
  },
];
