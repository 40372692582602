// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checklist-item-unit {
  background: #ffffff;
  border: 0.5px solid #65a2eb;
  border-radius: 2px;
  display: flex;
  padding: 6px;
  justify-content: space-evenly;
  align-items: center; }

.checklist-item-actions {
  width: 15%;
  margin-bottom: -12px;
  display: flex;
  justify-content: flex-end; }

.checklist-item-unit {
  margin-bottom: 8px; }

.cheklist-item-unit-name textarea,
.cheklist-item-unit-index {
  font-family: Poppins;
  font-style: normal;
  color: #65a2eb;
  font-size: 14px;
  line-height: 20px; }

.cheklist-item-unit-index {
  margin-right: 10px; }

.MuiFormHelperText-root {
  color: #f46a6a !important;
  position: absolute;
  bottom: -14px;
  font-family: Poppins, sans-serif !important;
  font-size: 10.4px !important;
  font-weight: 400 !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/jobDetails/rooms/checklist/ChecklistUnit.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,6BAA6B;EAC7B,mBAAmB,EAAA;;AAErB;EACE,UAAU;EACV,oBAAoB;EACpB,aAAa;EACb,yBAAyB,EAAA;;AAE3B;EACE,kBAAkB,EAAA;;AAGpB;;EAEE,oBAAoB;EACpB,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,iBAAiB,EAAA;;AAEnB;EACE,kBAAkB,EAAA;;AAEpB;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,2CAA2C;EAC3C,4BAA4B;EAC5B,2BAA2B,EAAA","sourcesContent":[".checklist-item-unit {\n  background: #ffffff;\n  border: 0.5px solid #65a2eb;\n  border-radius: 2px;\n  display: flex;\n  padding: 6px;\n  justify-content: space-evenly;\n  align-items: center;\n}\n.checklist-item-actions {\n  width: 15%;\n  margin-bottom: -12px;\n  display: flex;\n  justify-content: flex-end;\n}\n.checklist-item-unit {\n  margin-bottom: 8px;\n}\n\n.cheklist-item-unit-name textarea,\n.cheklist-item-unit-index {\n  font-family: Poppins;\n  font-style: normal;\n  color: #65a2eb;\n  font-size: 14px;\n  line-height: 20px;\n}\n.cheklist-item-unit-index {\n  margin-right: 10px;\n}\n.MuiFormHelperText-root {\n  color: #f46a6a !important;\n  position: absolute;\n  bottom: -14px;\n  font-family: Poppins, sans-serif !important;\n  font-size: 10.4px !important;\n  font-weight: 400 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
