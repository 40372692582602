import React, { useEffect, useState } from "react";
import { currentUserHasFullAccess } from "common/utils";
import { map } from "lodash";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Media, UncontrolledTooltip } from "reactstrap";
import { Circle } from "@mui/icons-material";
import { AVATAR_URL, BELOW_GROUND, EXTERNAL, INTERNAL } from "common/constants";
import ProgressBar from "pages/jobstracker/HorizontalProgressBar";
import { totalPercentage } from "../../jobTypes/active/percentageCalculation";
import { MenuType } from "components/jobs-ui/MenuType";
import Category from "../../jobTypes/Category";
import { useLinkJobContext } from "context/LinkjobContext";
import { FormControlLabel } from "@mui/material";

const CardView = ({
  result,
  job,
  setSearchTerm,
  notification,
  resultSearchTerm,
  pathname,
  setJobUpdate,
  isLinkEnabled,
  collectionName,
}) => {
  const { currentUserProfile } = useSelector(state => state.profile);
  const { jobs } = useSelector(state => state.job);
  const {
    internal: isInternal,
    external: isExternal,
    belowground: isBelowground,
  } = JSON.parse(result.jobtype.raw);
  const [percentage, setPercentage] = useState(0);
  const { checkedItems, toggleCheckbox } = useLinkJobContext();
  const isChecked = checkedItems.some(
    object => object.id === job.id && object.jobNo === job.jobNo
  );
  const handleCheckboxChange = () => {
    toggleCheckbox({ id: job.id, jobNo: job.jobNo });
  };
  useEffect(() => {
    const nonMatchingjobIds = checkedItems?.filter(
      checkedJob => !jobs.some(job => job.id === checkedJob.id)
    );
    nonMatchingjobIds?.map(checkedJob => {
      toggleCheckbox({ id: checkedJob.id, jobNo: checkedJob.jobNo });
    });
  }, [jobs]);
  useEffect(() => {
    const calculateTotalPercentage = async () => {
      const result = await totalPercentage(job);
      setPercentage(result);
    };
    calculateTotalPercentage();
  }, [job]);
  return (
    <React.Fragment>
      <Col xl="4" lg="6" md="6" sm="12" key={job?.id}>
        <FormControlLabel
          control={
            <Card>
              <CardBody>
                {currentUserHasFullAccess(job, currentUserProfile?.id) && (
                  <MenuType
                    pathname={pathname}
                    setSearchTerm={setSearchTerm}
                    job={job}
                    setJobUpdate={setJobUpdate}
                    isLinkEnabled={isLinkEnabled}
                    collectionName={collectionName}
                  />
                )}
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <Media>
                  <div className="avatar-md me-4">
                    <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                      <img
                        src={`${AVATAR_URL}&name=${result.client__displayname.raw}`}
                        className="rounded-circle avatar-sm"
                        alt=""
                      />
                    </span>
                  </div>
                  <Media className="overflow-hidden" body>
                    <>
                      {isLinkEnabled ? (
                        <h5 className="text-truncate font-size-15">
                          <Link
                            to={{
                              pathname: `${pathname}/${result.id.raw}`,
                              state: {
                                pageNo: location?.search,
                                query: resultSearchTerm,
                                type: pathname,
                              },
                            }}
                            className="text-dark"
                          >
                            {`${result?.orgidentitycode?.raw} ${result.jobno.raw}`}
                            {notification !== undefined ? (
                              <Circle
                                className="mr-4 text-wrap font-size-16"
                                style={{
                                  fill: "#50A5F1",
                                  paddingBottom: "5px",
                                }}
                              />
                            ) : null}
                          </Link>
                        </h5>
                      ) : (
                        <h5 className="text-truncate font-size-15">
                          {`${result?.orgidentitycode?.raw} ${result.jobno.raw}`}
                        </h5>
                      )}
                    </>
                    {isLinkEnabled ? (
                      <h5 className="text-truncate font-size-13">
                        <Link
                          to={{
                            pathname: `${pathname}/${result.id.raw}`,
                            state: {
                              pageNo: location?.search,
                              query: resultSearchTerm,
                              type: pathname,
                            },
                          }}
                          className="text-dark"
                        >
                          {result.jobname?.raw}
                        </Link>
                      </h5>
                    ) : (
                      <h5 className="text-truncate font-size-13">
                        {result.jobname?.raw}
                      </h5>
                    )}
                    {isLinkEnabled ? (
                      <h5 className="text-truncate font-size-13">
                        <Link
                          to={{
                            pathname: `${pathname}/${result.id.raw}`,
                            state: {
                              pageNo: location?.search,
                              query: resultSearchTerm,
                              type: pathname,
                            },
                          }}
                          className="text-dark"
                        >
                          {result.client__displayname.raw}
                        </Link>
                      </h5>
                    ) : (
                      <h5 className="text-truncate font-size-13">
                        {" "}
                        {result.client__displayname.raw}
                      </h5>
                    )}
                    <p className="text-muted mb-4"> {result.jobaddress.raw}</p>

                    <div className="avatar-group">
                      {map(result.participants.raw, (member, index) => {
                        const { displayName, id, photoURL } =
                          JSON.parse(member);
                        return (
                          <div
                            className="avatar-group-item"
                            key={index.toString()}
                          >
                            <div
                              className="team-member d-inline-block"
                              id={"member" + id}
                            >
                              <img
                                src={
                                  photoURL ||
                                  `${AVATAR_URL}&name=${displayName}`
                                }
                                className="rounded-circle avatar-xs"
                                alt=""
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={"member" + id}
                              >
                                {displayName}
                              </UncontrolledTooltip>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Media>

                  <div className="avatar-md me-4">
                    <ProgressBar percentage={percentage} />
                  </div>
                </Media>
              </CardBody>
              <div className="px-4 py-3 border-top">
                <ul className="list-inline mb-0 flex-start-center">
                  <li className="list-inline-item me-3">
                    {isInternal && <Category type={INTERNAL} job={job} />}
                  </li>
                  <li className="list-inline-item me-3">
                    {isExternal && <Category type={EXTERNAL} job={job} />}
                  </li>
                  <li className="list-inline-item me-3">
                    {isBelowground && (
                      <Category type={BELOW_GROUND} job={job} />
                    )}
                  </li>
                </ul>
              </div>
            </Card>
          }
        />
      </Col>
    </React.Fragment>
  );
};

CardView.propTypes = {
  projects: PropTypes.array,
};
export default CardView;
