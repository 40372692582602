import ActiveMenu from "../../pages/jobs/jobTypes/active/Menu";
import ArchivedMenu from "../../pages/jobs/jobTypes/archived/Menu";
import DeletedMenu from "../../pages/jobs/jobTypes/deleted/Menu";

export function MenuType({ pathname, setSearchTerm, job, setJobUpdate,collectionName}) {
  if (pathname.includes("active")) {
    return (
      <ActiveMenu
        setSearchTerm={setSearchTerm}
        job={job}
        setJobUpdate={setJobUpdate}
        collectionName={collectionName}
      />
    );
  }
  if (pathname.includes("archived")) {
    return (
      <ArchivedMenu
        setSearchTerm={setSearchTerm}
        job={job}
        setJobUpdate={setJobUpdate}
        collectionName={collectionName}
      />
    );
  }

  if (pathname.includes("deleted")) {
    return (
      <DeletedMenu
        setSearchTerm={setSearchTerm}
        job={job}
        setJobUpdate={setJobUpdate}
        collectionName={collectionName}
      />
    );
  }
  return null;
}