import React, { useEffect, useRef, useState, memo, useCallback } from "react";
import classnames from "classnames";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import { ContentCopy, Upload, Add } from "@mui/icons-material";
import cuid from "cuid";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import CloseIcon from "@mui/icons-material/Close";
import SweetAlert from "react-bootstrap-sweetalert";
import { ExcelRenderer } from "react-excel-renderer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  Input,
  Alert,
  Label,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  addNewChecklistToDB,
  addNewListItemToDB,
  fetchChecklistsFromDB,
  fetchDefaultListItemsFromDb,
  fetchListItemsFromDb,
  updateChecklistInDb,
  getChecklist,
  deleteMultipleSelectedChecklist,
  fetchChecklist,
} from "services";
import { DRAFTS_DB_NAME, INSPECTION_DB_NAME } from "common/constants";
import {
  fetchCustomListItems,
  fetchCustomListItemsError,
  fetchCustomListItemsSuccess,
  fetchDefaultListItems,
  fetchDefaultListItemsError,
  fetchDefaultListItemsSuccess,
  fetchSavedChecklist,
  fetchSavedChecklistError,
  fetchSavedChecklistSuccess,
} from "store/actions";
import {
  getListContainerStyle,
  Reorder,
} from "../../../../common/checklistUtils/dndStyleUtil";
import SavedCheckList from "../../../checklistLibrary/CheckListSavedUnit";
import AddSection from "./AddSection";
import defaultCheckList from "./ChecklistDeafaultData";
import ChecklistItemSection from "./ChecklistItemSection";
import "./CheckListPage.scss";
import CheckListParser from "./CheckListParser";
import DeleteConfirmationPromptWithCheckBox from "components/Common/DeleteConfirmationPromptWIthCheckBox";
import {
  CLONED_CHECKLIST_TEXT,
  IS_FLOOR_CLONING_ENABLED,
} from "../../../../common/constants";
import { convertTextToEllipsis } from "pages/jobs/utils";
import { useLocation } from "react-router-dom";
import { CheckListOverWriteChoice } from "./CheckListOverWriteChoice";
import { ReplicateConfirmationDialog } from "./ReplicateConfirmationDialog.1";
import { duplicateItemsInCheckList } from "./duplicateItemsInCheckList";
import { CheckListOverWriteDialog } from "./CheckListItemsOverWriteDialog";
import Loader from "components/Loader";

function CheckListPage({
  checklist = {},
  roomId: areaId = "",
  roomName = "",
  type = "",
  jobDocId = "",
  replicateChecklist = () => {},

  onSave = () => {},
  onClear = () => {},
  setIsDirty = () => {},

  setFlaggedRooms = () => {},
}) {
  const location = useLocation();
  const isInspectionJob = location.pathname.includes("newinspectionjob");
  const collectionName = isInspectionJob ? INSPECTION_DB_NAME : DRAFTS_DB_NAME;
  const dispatch = useDispatch();
  const { customListItems, savedChecklists } = useSelector(
    state => state.setup
  );
  const { currentUserProfile } = useSelector(state => state.profile);
  const [checklistName, setChecklistName] = useState(""); // <-
  const [checklistItemName, setChecklistItemName] = useState();
  const [addNewModal, setAddNewModal] = useState(false);
  const [activeTab, setactiveTab] = useState("1");
  const [itemSearch, setItemSearch] = useState("");
  const [checklistSearch, setChecklistSearch] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [isChecklistChanged, setIsChecklistChanged] = useState(false);
  const [currentChecklist, setCurrentChecklist] = useState(null); // <-
  const [checklistItems, setChecklistItems] = useState([]); // <-
  const [allListItems, setAllListItems] = useState([]);
  const [myListItems, setMyListItems] = useState([]);
  const [myCheckLists, setMyCheckLists] = useState(defaultCheckList);
  const [selectedListItem, setSelectedListItem] = useState(null);
  const [isDirty, setDirty] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const fileInput = useRef(null);
  const [isValidChecklist, setIsValidChecklist] = useState(true);
  const [showOverrideAlert, setShowOverrideAlert] = useState(false);
  const [showOverrideChoiceAlert, setShowOverrideChoiceAlert] = useState(false);
  const [isReplicationConfirmation, setIsReplicationConfirmation] =
    useState(false);
  const [isReplicating, setIsReplicating] = useState(false);
  const [duplicateItem, setDuplicateItem] = useState("");
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showReplaceAlert, setShowReplaceAlert] = useState(false);
  const [mainListItems, setMainListItems] = useState([]);
  const [changeCheckListName, setChangeCheckListName] = useState(false);
  const [checklistId, setChecklistId] = useState("");
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [isChecklistReordered, setIsChecklistReordered] = useState(false);
  const [state, setState] = useState({});
  useFirestoreCollection({
    query: () => fetchDefaultListItemsFromDb(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchDefaultListItems()),
    onSuccess: docs => dispatch(fetchDefaultListItemsSuccess(docs)),
    onError: error => dispatch(fetchDefaultListItemsError(error)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchChecklistsFromDB(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchSavedChecklist()),
    onSuccess: docs => dispatch(fetchSavedChecklistSuccess(docs)),
    onError: error => dispatch(fetchSavedChecklistError(error)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchListItemsFromDb(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchCustomListItems()),
    onSuccess: docs => dispatch(fetchCustomListItemsSuccess(docs)),
    onError: error => dispatch(fetchCustomListItemsError(error)),
    deps: [dispatch],
  });

  const addAsFlaggedRoom = useCallback(() => {
    setFlaggedRooms(flaggedRooms => {
      if (flaggedRooms.find(id => id === areaId)) return flaggedRooms;
      return [...flaggedRooms, areaId];
    });
  }, []);
  const removeAsFlaggedRoom = useCallback(() => {
    setFlaggedRooms(flaggedRooms => {
      return flaggedRooms.filter(_roomId => _roomId !== areaId);
    });
  }, []);

  useEffect(async () => {
    let isCancelled = false;
    const areaChecklist = await fetchChecklist(
      collectionName,
      jobDocId,
      areaId
    );
    if (isCancelled) return;
    if (!areaChecklist) {
      // CHECKLIST:TODO: delete this if condition
      setChecklistItems(checklist?.items || []);
      setCurrentChecklist({ ...checklist, templateId: checklist.id });
      setChecklistName(checklist?.title || "");
      if (checklist?.items?.length > 0) {
        removeAsFlaggedRoom();
      }
    }
    if (!areaChecklist) {
      return;
    }
    setChecklistItems(areaChecklist?.items || []);
    setCurrentChecklist(areaChecklist);
    setChecklistName(areaChecklist?.title || "");
    removeAsFlaggedRoom();
    return () => {
      isCancelled = true;
      setState({});
    };
  }, []);

  useEffect(() => {
    setIsSaveDisabled(
      !checklistName ||
        checklistItems.length === 0 ||
        !isDirty ||
        !isValidChecklist
    );
  }, [checklistName, checklistItems.length, isDirty, isValidChecklist]);

  useEffect(() => {
    if (!currentChecklist) {
      return;
    }
    if (isSaveDisabled && checklistItems.length > 0 && checklistName) {
      removeAsFlaggedRoom();
      return;
    }
    addAsFlaggedRoom();
  }, [isSaveDisabled, checklistItems.length, currentChecklist]);

  useEffect(() => {
    let tempList = [...customListItems];
    tempList.sort((a, b) => {
      const aStr = a.title || a.content;
      const bStr = b.title || b.content;
      return aStr.localeCompare(bStr);
    });
    setAllListItems(tempList);
  }, [customListItems]);

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const isDuplicate = useCallback(
    value => {
      if (customListItems.length <= 0) {
        return false;
      }
      let findOne = customListItems.filter(
        item =>
          item.title?.toLowerCase() === value.toLowerCase() ||
          item.content?.toLowerCase() === value.toLowerCase()
      );
      return findOne && findOne.length > 0;
    },
    [customListItems]
  );

  const fileHandler = useCallback(
    event => {
      if (event.target.files.length) {
        let fileObj = event.target.files[0];
        let fileName = fileObj.name;

        //check for file extension and pass only if it is .xlsx and display error message otherwise
        if (fileName.slice(fileName.lastIndexOf(".") + 1) === "xlsx") {
          // this.setState({
          //   uploadedFileName: fileName,
          //   isFormInvalid: false,
          // });
          renderFile(fileObj);
          setDirty(true);
          setIsDirty(true);
        } else {
          console.log("Error, file type not supported");
        }
      }
    },
    [checklistItems]
  );

  const renderFile = fileObj => {
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setSelectedChecklist(currentChecklist);
        const mainList = CheckListParser(resp);
        if (
          checklistItems.length > 0 &&
          fileObj.name.split(".")[0] !== checklistName
        ) {
          setMainListItems(mainList);
          setShowReplaceAlert(true);
          setChangeCheckListName(fileObj.name.split(".")[0]);
          setDirty(false);
          setIsDirty(false);
          return;
        }
        setChecklistItems(mainList);
        setChecklistName(fileObj.name.split(".")[0]);
      }
    });
  };
  const CheckListReplaceSweetAlert = ({
    setShowReplaceAlert,
    mainList,
    changeCheckListName,
  }) => {
    const closeModal = () => {
      if (selectedChecklist) setCurrentChecklist(selectedChecklist);
      setShowReplaceAlert(false);
    };

    const handleAppend = () => {
      if (checklistId)
        setCurrentChecklist({
          ...currentChecklist,
          templateId: checklistId,
          id: checklistId,
        });

      setChecklistItems([...checklistItems, ...mainList]);
      setShowReplaceAlert(false);
      setIsChecklistChanged(true);
      setDirty(true);
      setIsDirty(true);
    };

    const handleReplace = () => {
      setCurrentChecklist({
        ...currentChecklist,
        title: changeCheckListName,
      });
      setChecklistItems(mainList);
      setChecklistName(changeCheckListName);
      setShowReplaceAlert(false);
      setDirty(true);
      setIsDirty(true);
    };
    return (
      <SweetAlert
        warning
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside
        title="Do you want to append or replace this checklist? "
        onCancel={closeModal}
      >
        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            backgroundColor: "white",
            top: "1rem",
            right: "1rem",
            border: "none",
          }}
        >
          <CloseIcon
            style={{
              color: "gray",
            }}
          />
        </button>
        <div
          style={{
            padding: "1rem 0",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            color="warning"
            style={{ ...buttonStyle, color: "white" }}
            onClick={handleAppend}
          >
            Add
          </Button>
          <Button color="success" style={buttonStyle} onClick={handleReplace}>
            Replace
          </Button>
        </div>
      </SweetAlert>
    );
  };

  const openFileBrowser = useCallback(() => {
    fileInput.current.click();
  }, []);

  const openModalDlg = useCallback(() => {
    setAddNewModal(true);
    setSelectedIds([]);
  }, []);

  const closeModalDlg = useCallback(() => {
    setAddNewModal(false);
    setSelectedIds([]);
    setSelectedListItem(null);
  }, []);

  const removeId = useCallback(
    remId => {
      setSelectedIds([...selectedIds.filter(id => id !== remId)]);
      setDirty(true);
      setIsDirty(true);
    },
    [selectedIds]
  );

  const addId = useCallback(
    id => {
      setSelectedIds([...selectedIds, id]);
      setDirty(true);
      setIsDirty(true);
    },
    [selectedIds]
  );

  const getItemById = useCallback(
    id => {
      const [res] = myListItems.filter(item => item.id === id);
      return res;
    },
    [myListItems]
  );

  useEffect(() => {
    if (itemSearch.length >= 1) {
      const newList = allListItems.filter(item => {
        const title = item?.title || item.content;
        return title?.toLowerCase().includes(itemSearch.toLowerCase());
      });
      setMyListItems([...newList]);
    } else {
      setMyListItems([]);
    }
  }, [itemSearch, allListItems]);

  useEffect(() => {
    let newList = savedChecklists.filter(item => {
      const title = item?.title || item.content;
      return title?.toLowerCase().includes(checklistSearch.toLowerCase());
    });

    newList.sort((a, b) => {
      const aStr = a.title || a.content;
      const bStr = b.title || b.content;
      return aStr.localeCompare(bStr);
    });
    setMyCheckLists([...newList]);
  }, [checklistSearch, savedChecklists]);

  const onSaveNewChecklist = async () => {
    setDirty(false);
    if (isChecklistReordered) {
      onSaveList({
        ...currentChecklist,
        items: checklistItems,
      });
      return;
    }
    const checklistSnapshot = await getChecklist(
      currentUserProfile?.orgAccessId
    );
    const checklistData = checklistSnapshot.docs.map(doc => doc.data());
    const checkListWithSameName = checklistData.find(
      checkList => checkList.title.toLowerCase() === checklistName.toLowerCase()
    );

    if (
      checkListWithSameName &&
      checkListWithSameName?.id !== currentChecklist?.templateId
    ) {
      setShowOverrideAlert(true);
      setDirty(true);
      return;
    }
    let totalDuplicateListObjects = [];
    checklistItems.forEach(item => {
      const data = duplicateItemsInCheckList(item.subItems);
      if (data.length) totalDuplicateListObjects.push(data);
      item.subItems = item.subItems.map(listItem => {
        if (
          listItem.uploaded &&
          !isDuplicate(listItem.title || listItem.content)
        ) {
          addNewListItemToDB(
            { ...listItem, id: cuid() },
            currentUserProfile?.orgAccessId
          );
        }
        return { ...listItem, uploaded: false };
      });
    });
    if (
      !currentChecklist ||
      !currentChecklist?.templateId ||
      currentChecklist?.title.toLowerCase() !== checklistName.toLowerCase()
    ) {
      createNewChecklistTemplate(checklistName);
      return;
    }
    const isClonedChecklist = currentChecklist.templateId?.startsWith(
      CLONED_CHECKLIST_TEXT
    );
    if (isClonedChecklist) {
      onSaveList({
        id: currentChecklist?.templateId,
        title: checklistName,
        items: checklistItems,
      });
      return;
    }
    if (isChecklistChanged) {
      setShowOverrideChoiceAlert(true);
      return;
    }
    updateExistingChecklistTemplate();
  };

  const updateExistingChecklistTemplate = useCallback(() => {
    const updatedCheckList = {
      id: currentChecklist?.templateId,
      title: checklistName,
      items: checklistItems,
    };
    setCurrentChecklist({
      ...currentChecklist,
      title: checklistName,
      items: checklistItems,
    });
    updateChecklistInDb(updatedCheckList, currentUserProfile?.orgAccessId);
    onSaveList(updatedCheckList);
    setShowOverrideChoiceAlert(false);
    setIsChecklistChanged(false);
    setIsSaveDisabled(true);
  }, [checklistItems, checklistName, currentUserProfile, currentChecklist]);

  const createNewChecklistTemplate = useCallback(
    templateName => {
      const newCheckList = {
        id: cuid(),
        title: templateName,
        items: checklistItems,
      };
      setCurrentChecklist({
        templateId: newCheckList.id,
        title: checklistName,
        items: checklistItems,
      });
      addNewChecklistToDB(newCheckList, currentUserProfile?.orgAccessId);
      console.log("created checklist template");
      onSaveList(newCheckList);
      setShowOverrideChoiceAlert(false);
      setIsChecklistChanged(false);
      setIsSaveDisabled(true);
    },
    [checklistItems, checklistName, currentUserProfile]
  );

  const onSaveList = useCallback(
    async checkList => {
      try {
        onSave(checkList, areaId);
        toast.success("Checklist saved", {
          autoClose: 2000,
        });
      } catch (err) {
        console.log("onSaveList:", err);
        toast.error("Failed to update the checklist", {
          autoClose: 2000,
        });
      } finally {
        // setIsSaveDisabled(false);
      }
    },
    [areaId]
  );

  const onChangeTextHeader = useCallback(
    (text, id) => {
      console.log("text", text, id,checklistItems);
      const newItems = checklistItems?.map(item => {
        if (item.id === id) {
          const newItem = { ...item, content: text };
          return newItem;
        }
        return item;
      });
     
      setChecklistItems(newItems);
      setDirty(true);
      setIsDirty(true);
    },
    [checklistItems]
  );

  const onChangeItemData = useCallback(
    (data, id, parent) => {
      setIsChecklistChanged(true);
      const newItems = checklistItems?.map(item => {
        if (item.id === parent) {
          const newItem = {
            ...item,
            subItems: item.subItems?.map(sub => {
              if (sub.id === id) {
                const newSub = { ...sub, ...data };
                return newSub;
              }
              return sub;
            }),
          };
          return newItem;
        }
        return item;
      });
      setChecklistItems(newItems);
      setDirty(true);
      setIsDirty(true);
    },
    [checklistItems]
  );
  function isNewItemsOldItemsEqual(checklistItems, newItems) {
    if (checklistItems?.length !== newItems?.length) {
      return false;
    }

    const sortAndStringify = items =>
      JSON.stringify(
        items
          .map(item =>
            Object.keys(item)
              .sort()
              .reduce((acc, id) => ({ ...acc, [id]: item[id] }), {})
          )
          .sort()
      );

    const checklistItemsInOrder = sortAndStringify(checklistItems);
    const newItemsInOrder = sortAndStringify(newItems);

    return checklistItemsInOrder === newItemsInOrder;
  }

  const executeByFifteen = async (arr, obj) => {
    if (arr.length) {
      console.log('Updating checklist items:', checklistItems);
      await new Promise(resolve => setTimeout(resolve, 1000)); 
  
      setChecklistItems(prevState => {
        if (prevState.length === 0) {
          return [{...obj, subItems: arr }];
        } else {
          const updatedItem = prevState[0];
          updatedItem.subItems = [...updatedItem?.subItems,...arr];
          return [...prevState, updatedItem];
        }
      });
    }
  };

  console.log(checklistItems,'checklistItems')
  const [chunkdetail,setchunkdetail] = useState({
    index:0,
    complete:false
  })
function processSubItemsChunk(subItem,index,obj) {
    return new Promise((resolve, reject) => {
        // Simulate asynchronous processing
        setTimeout(() => {
          setChecklistItems(prevState => {
            if (prevState.length === 0) {
              return [{...obj, subItems: subItem }];
            } else {
              const updatedItem = prevState[0];
              updatedItem.subItems = [...updatedItem?.subItems,...subItem];
              return [...prevState, updatedItem];
            }
          });
          // setChecklistItems([...checklistItems,])
            // console.log("Processing chunk:", chunk);
            // Process each subItem in the chunk (you can add your logic here)
            // chunk.forEach(subItem => {
            //     console.log("Processing subItem:", subItem);
            //     // Example processing (you can modify as per your logic)
            //     // For demonstration, we are just logging the content of each subItem
            //     console.log("Content:", subItem.content);
            // });
            // Resolve the promise once processing is done
            resolve(index);
        }, 1000); // Simulated delay of 1 second
    });
}

// Function to process each item and its subItems in chunks
async function processItems(items) {
  console.log('==============itemsitems======================');
  console.log(items);
  console.log('====================================');
  if(!items){
    return
  }
  for (const [index, item] of items.entries()) {
    console.log(`Processing item ${index}:`, item);
    // setChunksIndex(index)
    // Process subItems in chunks of size 2
    for (let i = 0; i < item?.subItems.length; i += 50) {
      const chunk = item?.subItems.slice(i, i + 50);
      await processSubItemsChunk(chunk,index,item);
    }
  }
}
  const  onDragEnd = async result => {
    setIsChecklistChanged(false);
    // dropped outside the list
    if (!result.destination) {
      if (result.source.droppableId === "checklistitemsonly") {
        const subItem = getItemById(result.draggableId);
        const newSubItem = {
          ...subItem,
          id: cuid(),
          content: subItem.title || subItem.content,
          uploaded: false,
        };
        const newItem = {
          id: cuid(),
          subItems: [],
          content: "",
          type: "list",
          subItems: [newSubItem],
        };
        setChecklistItems([...checklistItems, newItem]);
      }
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (result.type === "droppableItem") {
      // if source droppableid is droppable
      if (
        result.source.droppableId === result.destination.droppableId &&
        (result.source.droppableId === "checklistitemsonly" ||
          result.source.droppableId === "checklistonly")
      ) {
        // do nothing
      } else if (result.source.droppableId === result.destination.droppableId) {
        const itemsNew = Reorder(checklistItems, sourceIndex, destIndex);
        if (!isNewItemsOldItemsEqual(checklistItems, itemsNew)) {
          setChecklistItems(itemsNew);
          setIsDirty(true);
          setDirty(true);
          setIsChecklistReordered(true);
        }
      } else {
        // get the list checklit
        const id = result.draggableId;
        const [selectedChecklist] = savedChecklists.filter(
          item => id === item.id
        );
        setSelectedChecklist(currentChecklist);
        if (
          checklistItems.length > 0 &&
          selectedChecklist?.title !== checklistName
        ) {
          setShowReplaceAlert(true);
          setChecklistId(currentChecklist?.templateId || currentChecklist?.id);
          setCurrentChecklist({
            ...selectedChecklist,
            templateId: selectedChecklist?.id,
            title: currentChecklist?.title,
          });
          setMainListItems(selectedChecklist.items);
          setChangeCheckListName(selectedChecklist?.title);
          setDirty(false);
          setIsDirty(false);
          return;
        }
        setDirty(true);
        setIsDirty(true);
        setCurrentChecklist({
          ...selectedChecklist,
          templateId: selectedChecklist?.id,
        });
        setChecklistItems(selectedChecklist.items);
    //     if (selectedChecklist.items[0]?.subItems.length > 15) {
    //       // const subItems = selectedChecklist.items[0].subItems;
    //       // let startIndex = 0;
    //       // let endIndex = 15;
  
    //       // while (endIndex <= subItems.length) {
    //       //   await executeByFifteen(subItems.slice(startIndex, endIndex), selectedChecklist.items[0]);
    //       //   startIndex += 15;
    //       //   endIndex += 15;
    //       // }
    //       console.log('===============selectedChecklist=====================');
    //       console.log(selectedChecklist.items);
    //       console.log('====================================');
    //       // processItems(selectedChecklist.items)
    // // .then((res) => {
    // //     console.log("Processing complete.",res);
    // // })
    // // .catch(error => {
    // //     console.error("Error occurred during processing:", error);
    // // });
    //     } else {
    //       setChecklistItems(selectedChecklist.items);
    //     }
      
     
       
        // setChecklistItems(selectedChecklist.items);
        setChecklistName(selectedChecklist.title);
      }
    } else if (result.type === "droppableSubItem") {
      setIsChecklistChanged(true);
      if (
        (result.destination.droppableId === "checklistitemsonly" ||
          result.destination.droppableId === "checklistspecialitemsonly") &&
        (result.source.droppableId === "checklistitemsonly" ||
          result.source.droppableId === "checklistspecialitemsonly")
      ) {
        // Do nothing
      } else {
        const itemSubItemMap = checklistItems.reduce((acc, item) => {
          acc[item.id] = item.subItems;
          return acc;
        }, {});

        const sourceParentId = result.source.droppableId;
        const destParentId = result.destination.droppableId;

        const destSubItems = itemSubItemMap[destParentId];
        let newItems = [...checklistItems];

        if (result.source.droppableId === "checklistitemsonly") {
          const subItem = getItemById(result.draggableId);
          const newSubItem = {
            ...subItem,
            id: cuid(),
            content: subItem.title || subItem.content,
            uploaded: false,
            type: "checkbox",
            isValidItem: true,
          };
          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, newSubItem);
          newItems = newItems.map(item => {
            if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setChecklistItems(newItems);
          setDirty(true);
          setIsDirty(true);
          return;
        }

        if (result.source.droppableId === "checklistspecialitemsonly") {
          const newSubItem = {
            id: cuid(),
            checkBox: false,
            checked: false,
            documents: [],
            comment: false,
            comments: [],
            attachment: false,
            attachments: [],
            signature: true,
            signatures: [],
            holdPoint: true,
            helpPoint: false,
            locked: false,
            content: "Hold point",
            uploaded: false,
            type: "digitalsign",
            isValidItem: true,
          };
          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, newSubItem);
          newItems = newItems.map(item => {
            if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setChecklistItems(newItems);
          setDirty(true);
          setIsDirty(true);
          return;
        }

        const sourceSubItems = itemSubItemMap[sourceParentId];

        /** In this case subItems are reOrdered inside same Parent */
        if (sourceParentId === destParentId) {
          const reorderedSubItems = Reorder(
            sourceSubItems,
            sourceIndex,
            destIndex
          );
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = reorderedSubItems;
            }
            return item;
          });
          setChecklistItems(newItems);
        } else {
          let newSourceSubItems = [...sourceSubItems];
          const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

          let newDestSubItems = [...destSubItems];
          newDestSubItems.splice(destIndex, 0, draggedItem);
          newItems = newItems.map(item => {
            if (item.id === sourceParentId) {
              item.subItems = newSourceSubItems;
            } else if (item.id === destParentId) {
              item.subItems = newDestSubItems;
            }
            return item;
          });
          setChecklistItems(newItems);
          setDirty(true);
          setIsDirty(true);
        }
      }
    }
  };

  const onRemoveCheckListGroup = useCallback(
    id => {
      let newItems = [...checklistItems.filter(item => item.id !== id)];
      setChecklistItems(newItems);
      setDirty(true);
      setIsDirty(true);
    },
    [checklistItems]
  );

  const onRemoveCheckListItem = useCallback(
    id => {
      setIsChecklistChanged(true);
      setChecklistItems([
        ...checklistItems?.map(item => {
          return {
            ...item,
            subItems: item.subItems.filter(subItem => subItem.id !== id),
          };
        }),
      ]);
      setDirty(true);
      setIsDirty(true);
    },
    [checklistItems]
  );

  const addNewChecklist = useCallback(() => {
    toast.success("Section added successfully", {
      autoClose: 2000,
    });
    const newItem = {
      id: cuid(),
      subItems: [],
      content: "New Checklist Heading",
      type: "list",
    };
    setChecklistItems([...checklistItems, newItem]);
    setDirty(true);
    setIsChecklistChanged(true);
    setIsDirty(true);
  }, [checklistItems]);

  const deleteSelectedChecklist = useCallback(async () => {
    const newChecklists = [
      ...myCheckLists.filter(item => item.checked === true),
    ];
    const checklistIds = newChecklists.map(item => item.id);
    const toastMessage =
      checklistIds.length === 1
        ? "The checklist is deleted!"
        : "The checklists are deleted!";

    setShowDeleteAlert(false);
    await deleteMultipleSelectedChecklist(
      checklistIds,
      currentUserProfile?.orgAccessId
    )
      .then(() => {
        toast.success(toastMessage, {
          autoClose: 2000,
        });
      })
      .catch(() => {
        toast.error("Failed while deleting the checklist", {
          autoClose: 2000,
        });
      });
  }, [myCheckLists]);

  const onCancel = useCallback(() => {
    [...myCheckLists].map(item => {
      item.checked = false;
    });
    setMyCheckLists([...myCheckLists]);
    setShowCheckBox(false);
  }, [myCheckLists]);

  const checkItemIsChecked = [...myCheckLists].find(item => item.checked);

  useEffect(() => {
    const isValid = checklistItems.reduce((isSectionValid, item) => {
      return (
        isSectionValid &&
        item?.subItems?.reduce(
          (isItemsValid, subItem) =>
            subItem.isValidItem &&
            isItemsValid &&
            (subItem.content || subItem.title).trim().length != 0,
          true
        )
      );
    }, true);
    setIsValidChecklist(isValid);
  }, [JSON.stringify(checklistItems)]); // for checking items deep equality

  const onReplicate = useCallback(async () => {
    setIsReplicating(true);
    try {
      replicateChecklist(
        checklistItems,
        roomName,
        checklistName,
        type,
        areaId,
        currentChecklist?.templateId
      );
    } catch (err) {
      console.error("Error@onReplicate:", err);
    } finally {
      setIsReplicating(false);
      setIsReplicationConfirmation(false);
    }
  }, [
    checklistItems,
    roomName,
    checklistName,
    type,
    areaId,
    currentChecklist,
    currentUserProfile,
  ]);

  const onAddNewItem = () => {
    setIsChecklistChanged(true);
    setChecklistItems(
      checklistItems?.map(item => {
        if (selectedIds.includes(item.id)) {
          item.subItems = [
            ...item.subItems,
            { ...selectedListItem, id: cuid(), uploaded: false },
          ];
        }
        return item;
      })
    );
    closeModalDlg();
    toast.success("New CheckList Item added Successfully", {
      autoClose: 2000,
    });
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (checklistItems?.length > 30) {

      const timer = setTimeout(() => {
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    } else {

      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, []);
  return (
    <React.Fragment>
      <Modal isOpen={addNewModal} size="md">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {"New Checklist Item"}
          </h5>
          <button
            type="button"
            onClick={closeModalDlg}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>
            Would you like to add this newly created item to your current
            checklist?
          </h5>
          <p>{selectedListItem?.title}</p>
          {checklistItems?.map(item => {
            return (
              <div key={item.id}>
                <Input
                  type="checkbox"
                  onChange={e => {
                    if (!e.target.checked) {
                      removeId(item.id);
                    } else {
                      addId(item.id);
                    }
                  }}
                ></Input>
                {"  "}
                {item.content}
              </div>
            );
          })}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={onAddNewItem}
            className="btn btn-success float-left"
            data-dismiss="modal"
            disabled={selectedIds.length > 0 ? false : true}
          >
            Add
          </button>
          <button
            type="button"
            onClick={closeModalDlg}
            className="btn btn-secondary float-left"
            data-dismiss="modal"
          >
            No
          </button>
        </div>
      </Modal>
      <Container fluid>
        <Row>
          <DragDropContext onDragEnd={onDragEnd}>
            <Col lg="7" md="7" sm="12" xs="12">
              <div className="checklist-name mb-2">
                <Label className="checklist-name__label">Checklist Name</Label>
                <Input
                  name="checklistName"
                  className="checklist-name__input"
                  placeholder="Type here to name checklist"
                  value={checklistName}
                  onChange={e => setChecklistName(e.target.value)}
                ></Input>
              </div>

              {IS_FLOOR_CLONING_ENABLED && roomName && (
                <Row>
                  <Col>
                    <Label
                      className={`builder-actions__label replicate-button
                        ${
                          (!isSaveDisabled ||
                            !checklistName ||
                            checklistItems.length === 0) &&
                          "builder-actions__label--disabled"
                        }
                      `}
                      onClick={() => {
                        if (!isSaveDisabled || checklistItems.length === 0)
                          return;
                        setIsReplicationConfirmation(true);
                      }}
                    >
                      <ContentCopy fontSize="small" />
                      <span className="text-with-icon">
                        Replicate in all{" "}
                        <span style={{ fontWeight: "600" }}>
                          '{convertTextToEllipsis(roomName, 30)}'
                        </span>
                        s
                      </span>
                    </Label>
                  </Col>
                </Row>
              )}

              <Row className="builder-actions">
                <Label className="builder-actions__name">
                  ITP - Inspection Test Plan Checklist
                </Label>
                <Label
                  className="builder-actions__label"
                  onClick={openFileBrowser}
                >
                  <Upload fontSize="small" />
                  <span className="text-with-icon">Upload Checklist</span>
                </Label>
                <input
                  type="file"
                  hidden
                  onChange={fileHandler}
                  ref={fileInput}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onClick={event => {
                    event.target.value = null;
                  }}
                  style={{ padding: "10px" }}
                />

                <Label
                  className="builder-actions__label"
                  onClick={addNewChecklist}
                >
                  <Add fontSize="small" />
                  <span className="text-with-icon">Add Section</span>
                </Label>
              </Row>

              {!currentChecklist && areaId !== "" ? (
                <div className="checklist-loader-container">
                  <p>Loading...</p>
                </div>
              ) : (
                <Droppable droppableId="droppable" type="droppableItem">
                  {(provided, snapshot) => (
                    <div
                      className="dropzone dropzone-checklist mb-2 scrollable-checklist-content"
                      ref={provided.innerRef}
                      style={getListContainerStyle(snapshot.isDraggingOver)}
                    >
                      {checklistItems.length === 0 ?(
                        <div className="dz-message dz-checklist-message mt-2">
                          <h4>
                            {`Create Your Checklist Here `}
                          </h4>
                        </div>
                      ):
                      <div>
                        {loading?<Loader/> :checklistItems?.map((item, index) => (<>
                          <AddSection
                            item={item}
                            index={index}
                            setItems={setChecklistItems}
                            items={checklistItems}
                            onChangeTextHeader={onChangeTextHeader}
                            onRemoveCheckListGroup={onRemoveCheckListGroup}
                            setDirty={setDirty}
                            onRemoveCheckListItem={onRemoveCheckListItem}
                            onChangeItemData={onChangeItemData}
                            isValidChecklist={isValidChecklist}
                            setIsValidChecklist={setIsValidChecklist}
                            setIsChecklistChanged={setIsChecklistChanged}
                          />
                        

                      </>  ))}
                        {provided.placeholder}
                      </div>
}
                    </div>
                  )}
                </Droppable>
              )}
              <Col className="dz-actions">
                <Button
                  outline
                  color="primary"
                  size="sm"
                  className="mb-4 ml-2 mr-2"
                  onClick={() => {
                    setChecklistItems([]);
                    setChecklistName("");
                    setCurrentChecklist([]);
                    setIsChecklistChanged(false);
                    onClear(areaId);
                  }}
                >
                  Clear
                </Button>{" "}
                <Button
                  disabled={isSaveDisabled}
                  outline
                  color="primary"
                  size="sm"
                  className="mb-4 mr-2"
                  onClick={onSaveNewChecklist}
                >
                  Save Checklist
                </Button>
              </Col>
            </Col>
            <Col lg="5" md="5" sm="5" xs="5">
              <>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        borderRadius: "4px 4px 0 0 ",
                      }}
                      className={classnames({
                        active: activeTab === "1",
                        "builder-actions__label": activeTab !== "1",
                      })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Checklist Items
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                        "builder-actions__label": activeTab !== "2",
                      })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Saved Checklist
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                    <ChecklistItemSection
                      profile={currentUserProfile}
                      checklistItemName={checklistItemName}
                      setChecklistItemName={setChecklistItemName}
                      isDuplicate={isDuplicate}
                      items={checklistItems}
                      setSelectedListItem={setSelectedListItem}
                      openModalDlg={openModalDlg}
                      setItemSearch={setItemSearch}
                      itemSearch={itemSearch}
                      myListItems={myListItems}
                    />
                  </TabPane>
                  <TabPane className="saved-checklist-tab" tabId="2">
                    <div className="mb-2">
                      <div>
                        <Label className="saved-checklist-tab__label">
                          Search saved checklist
                        </Label>
                        {showCheckBox && checkItemIsChecked && (
                          <Button
                            size="md"
                            color="link"
                            className="saved-checklist-tab__button"
                            onClick={() => {
                              setShowDeleteAlert(true);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                        )}
                        <Button
                          size="md"
                          color="link"
                          className="saved-checklist-tab__select"
                          onClick={() => {
                            showCheckBox ? onCancel() : setShowCheckBox(true);
                          }}
                        >
                          {showCheckBox ? "Cancel" : "Select Multiple"}
                        </Button>
                      </div>
                      <Input
                        type="search"
                        placeholder="Enter keyword"
                        name="checklistSearch"
                        onChange={e => setChecklistSearch(e.target.value)}
                      ></Input>
                    </div>
                    <Row className="saved-checklist-tab__list">
                      <SavedCheckList
                        showCheckBox={showCheckBox}
                        myChecklist={myCheckLists}
                        setMyChecklist={setMyCheckLists}
                        checklistSearch={checklistSearch}
                      />
                    </Row>
                  </TabPane>
                </TabContent>
              </>
            </Col>
          </DragDropContext>
        </Row>
      </Container>
      {showDeleteAlert && (
        <DeleteConfirmationPromptWithCheckBox
          label={
            "I understand that deleting is permanent and cannot be undone."
          }
          onCancel={() => {
            setShowDeleteAlert(false);
          }}
          onConfirm={deleteSelectedChecklist}
        />
      )}
      {showOverrideAlert && (
        <CheckListOverWriteDialog setShowOverrideAlert={setShowOverrideAlert} />
      )}
      {showOverrideChoiceAlert && (
        <CheckListOverWriteChoice
          setIsSaveDisabled={setIsSaveDisabled}
          setVisibility={setShowOverrideChoiceAlert}
          onUpdate={updateExistingChecklistTemplate}
          onCreateNew={createNewChecklistTemplate}
          setNewName={setChecklistName}
        />
      )}
      {isReplicationConfirmation && (
        <ReplicateConfirmationDialog
          roomName={roomName}
          isReplicating={isReplicating}
          onConfirm={onReplicate}
          closeModal={() => setIsReplicationConfirmation(false)}
        />
      )}
      {showReplaceAlert && (
        <CheckListReplaceSweetAlert
          setShowReplaceAlert={setShowReplaceAlert}
          mainList={mainListItems}
          changeCheckListName={changeCheckListName}
        />
      )}
    </React.Fragment>
  );
}

export default memo(CheckListPage);

export const buttonStyle = {
  padding: "0.6rem",
  width: "7rem",
};


