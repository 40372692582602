import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
} from "./actionTypes";

export function fetchNotifications() {
  return {
    type: FETCH_NOTIFICATIONS,
  };
}

export function fetchNotificationsSuccess(notifications) {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: notifications,
  };
}

export function fetchNotificationsError(error) {
  console.log(error);
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    payload: error,
  };
}
