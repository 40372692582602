import { StyleSheet, Text, View } from "@react-pdf/renderer";
import {
  firstTableColHeaderStyle,
  firstTableColStyle,
  tableCellHeaderStyle,
  tableCellStyle,
  tableColHeaderStyle,
  tableColStyle,
  tableRowStyle,
  tableStyle,
} from "../../tableStyles";
import { INSPECTION_DB_NAME } from "common/constants";
import { memo } from "react";
const Participants = ({ participants, collectionName }) => {
  const styles = StyleSheet.create({
    container: {
      paddingTop: 8,
      paddingBottom: 30,
    },
    clientInfo: {
      marginBottom: 12,
      flexDirection: "row",
      alignSelf: "left",
      fontSize: 20,
      fontWeight: 600,
      color: "#004F6B",
    },
  });

  const getParticiapantDetails = collectionName => {
    if (collectionName === INSPECTION_DB_NAME) {
      return "Inspection Details";
    }
    return "Participant Details";
  };
  const getJobDoneBy = collectionName => {
    if (collectionName === INSPECTION_DB_NAME) {
      return "Inspected By";
    }
    return "Participant";
  };
  const TableHeader = () => {
    return (
      <View style={tableRowStyle} fixed>
        <View style={{ ...firstTableColHeaderStyle, width: "30%" }}>
          <Text style={tableCellHeaderStyle} wrap={false}>
            {getJobDoneBy(collectionName)}
          </Text>
        </View>
        <View style={{ ...tableColHeaderStyle, width: "20%" }}>
          <Text style={tableCellHeaderStyle}>Role</Text>
        </View>
        <View style={{ ...tableColHeaderStyle, width: "30%" }}>
          <Text style={tableCellHeaderStyle}>Email</Text>
        </View>
        <View style={{ ...tableColHeaderStyle, width: "20%" }}>
          <Text style={tableCellHeaderStyle}>Phone</Text>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.clientInfo}>
        {getParticiapantDetails(collectionName)}
      </Text>
      <View style={tableStyle}>
        <TableHeader />
        {participants.map((participant, index) => {
          return (
            <View key={index} style={tableRowStyle} wrap={false}>
              <View style={{ ...firstTableColStyle, width: "30%" }}>
                <Text style={tableCellStyle}>{participant.displayName}</Text>
              </View>
              <View style={{ ...tableColStyle, width: "20%" }}>
                <Text style={tableCellStyle}>{participant.role.label}</Text>
              </View>
              <View style={{ ...tableColStyle, width: "30%" }}>
                <Text style={tableCellStyle}>{participant.email}</Text>
              </View>
              <View style={{ ...tableColStyle, width: "20%" }}>
                <Text style={tableCellStyle}>{participant.mobile}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};
export default memo(Participants);
