import React from "react";
import { Link } from "react-router-dom";

export function InspectionJobCreationSidebar({ props }) {
  return (
    <>
      <li>
        <Link
          to="/newinspectionjob"
          className={
            props.location.pathname.includes("/newinspectionjob")
              ? "mm-active"
              : ""
          }
        >
          <i className="fa fa-search fa-lg"></i>
          <span>{props.t("Create New Inspection")}</span>
        </Link>
      </li>{" "}
      <li>
        <Link
          to="/inspectiondraft"
          className={
            props.location.pathname.includes("/inspectiondraft")
              ? "mm-active"
              : ""
          }
        >
          <i className="bx bx-box"></i>
          <span>{props.t("Inspection Drafts")}</span>
        </Link>
      </li>
    </>
  );
}

export function JobCreationSidebar({props}){
  return(
    <>
    <li>
        <Link
          to="/newjob"
          className={
            props.location.pathname.includes("/newjob") ? "mm-active" : ""
          }
        >
          <i className="bx bx-edit-alt"></i>
          <span>{props.t("Create New Job")}</span>
        </Link>
      </li>{" "}
      <li>
        <Link
          to="/draft"
          className={
            props.location.pathname.includes("/draft") ? "mm-active" : ""
          }
        >
          <i className="bx bx-box"></i>
          <span>{props.t("Drafts")}</span>
        </Link>
      </li>
    </>
  )
}
export function SetupSidebar({props}) {
  return (
    <>
      <li className="menu-title" style={{color:"black",fontSize:"14px"}}>{props.t("Setup")} </li>

      <li>
        <Link
          to="/clients"
          className={
            props.location.pathname.includes("/clients") ? "mm-active" : ""
          }
        >
          <i className="bx bx bx-user-plus"></i>
          <span>{props.t("Clients")}</span>
        </Link>
      </li>
      <li>
        <Link
          to="/checklist"
          className={
            props.location.pathname.includes("/checklist") ? "mm-active" : ""
          }
        >
          <i className="bx bx-list-check"></i>
          <span>{props.t("Checklist Library")}</span>
        </Link>
      </li>
      <li>
        <Link
          to="/documentList"
          className={
            props.location.pathname.includes("/documentList") ? "mm-active" : ""
          }
        >
          <i className='bx bx-list-plus'></i>
          <span>{props.t("Documents Library")}</span>
        </Link>
      </li>
    </>
  );
}
