import React from "react";
import "./confirmModal.css";

export const ConfirmModal = ({
  onConfirm,
  onDeny,
  children,
}) => (
  <div className={"ConfirmModal"}>
    <h1>{children}</h1>
    <div>
      <button onClick={onConfirm}>Yes</button>
      <button onClick={onDeny}>No</button>
    </div>
  </div>
);
