import { dataFromSnapshot } from "services";
import firebase from "../config/firebase";
const db = firebase.firestore();
export async function getUser(email) {
  return db.collection("users").where("email", "==", email?.toLowerCase()).get();
}
export function getUserById(id) {
  return db.collection("users").doc(id);
}
export async function getAllUsers(orgId) {
  const snapshot = await db
    .collection("users")
    .where("orgAccessId", "==", orgId)
    .get();
  const users = snapshot.docs.map(doc => dataFromSnapshot(doc));
  return users;
}
export async function updateAllUsers(users) {
  const batch = db.batch();
  users.forEach(user => {
    batch.update(db.collection("users").doc(user.id), user);
  });
  return batch.commit().catch(err => console.error(err));
}
export function getAllOrgUsers(orgId) {
  let query = db.collection("users").where("orgAccessId", "==", orgId);
  
  return query;
}

export async function getUsersDetails(userIds) {
  const snapshot = await db.collection("users")
    .where('id', 'in', userIds).get();
  const users = snapshot.docs.map(doc => dataFromSnapshot(doc));
  return users;
}
