import { useEffect } from "react";
import { dataFromSnapshot } from "../../services/index";

export default function useFirestoreCollection({
  query,
  onStart,
  onSuccess,
  onError,
  deps,
}) {
  useEffect(() => {
    onStart();
    const unsubscribe = query().onSnapshot(
      snapshot => {
        const docs = snapshot.docs.map(doc => dataFromSnapshot(doc));
        onSuccess(docs);
      },
      error => {
        console.log(error);
        onError(error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, deps);
}
