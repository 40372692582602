import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../services/layoutService.js";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../Common/RightSidebar";
import SignaturePad from "components/Modal/SignaturePad.jsx";

export default function Layout({ children }) {
  const {
    layoutType,
    layoutWidth,
    leftSideBarTheme,
    leftSideBarThemeImage,
    leftSideBarType,
    topbarTheme,
    isPreloader,
    showRightSidebar,
    showSidebar,
    leftMenu,
  } = useSelector(state => state.layout);
  const [isMobile] = useState(
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  );

  const capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }

    // Scroll Top to 0
    window.scrollTo(0, 0);
    // let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

    // document.title =
    //   currentage + " | Industry Best Digital"
    if (leftSideBarTheme) {
      changeSidebarTheme(leftSideBarTheme);
    }
    if (leftSideBarThemeImage) {
      changeSidebarThemeImage(leftSideBarThemeImage);
    }

    if (layoutWidth) {
      changeLayoutWidth(layoutWidth);
    }

    if (leftSideBarType) {
      changeSidebarType(leftSideBarType);
    }
    if (topbarTheme) {
      changeTopbarTheme(topbarTheme);
    }
  }, []);

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      changeSidebarType("condensed", isMobile);
    } else if (leftSideBarType === "condensed") {
      changeSidebarType("default", isMobile);
    }
  };

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <SignaturePad
          options={{ backgroundColor: "white" }}
          redrawOnResize={true}
        />
        <div className="main-content">{children}</div>
        <Footer />
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  );
}
